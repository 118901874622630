import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import timeZones from 'utils/timeZones';
import actions from 'store/actions/UserProfile';
import InputMask from 'react-input-mask';

import IboxTools from 'components/common/IboxTools';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import { Row } from 'react-bootstrap';

const Profile = props => {
  const {
    update,
    passwordInput,
    updateProfile,
    setProfileValue,
    setInputValue,
    submitPass,
    setProfile,
    isPswUpdating,
  } = props;

  useEffect(() => {
    setProfile();
  }, []);

  const isDisable = () => {
    if (
      !update.firstName.trim().length ||
      !update.lastName.trim().length ||
      !update.description ||
      !update.phoneNumber.trim().length ||
      !update.timeZone.trim().length
    ) {
      return true;
    }
    return false;
  };
  const isDisablePsw = () => {
    if (
      !passwordInput.oldPassword ||
      !passwordInput.oldPassword.trim().length ||
      !passwordInput.password ||
      !passwordInput.password.trim().length
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h5>Update profile</h5>
          <IboxTools />
        </div>
        <div className="ibox-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <form className="m-t" role="form" onSubmit={updateProfile}>
                  <Row>
                    <div className="form-group has-feedback col-md-6">
                      <Input
                        onChange={e => setProfileValue(e, 'firstName')}
                        value={update.firstName || ''}
                        label="First name"
                        required
                        testSelector="user-profile_profile_first-name_input"
                      />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                      <Input
                        onChange={e => setProfileValue(e, 'lastName')}
                        value={update.lastName || ''}
                        label="Last name"
                        required
                        testSelector="user-profile_profile_last-name_input"
                      />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                      <Input
                        onChange={e => setProfileValue(e, 'description')}
                        value={update.description || ''}
                        label="Description"
                        required
                        testSelector="user-profile_profile_description_input"
                      />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                      <InputMask
                        mask="999999999999"
                        maskChar={null}
                        value={update.phoneNumber}
                        onChange={e => setProfileValue(e, 'phoneNumber')}
                        label="Phone number"
                        testSelector="user-profile_profile_phone_input">
                        {inputProps => (
                          <Input {...inputProps} type="text" required />
                        )}
                      </InputMask>
                    </div>
                    <div
                      className="form-group has-feedback col-md-6"
                      data-testid="profile_update_input_time_zone">
                      {update.timeZone.length > 0 && (
                        <Select
                          placeholder="Time Zone"
                          options={timeZones}
                          onChange={item =>
                            setProfileValue(item.value, 'timeZone')
                          }
                          value={
                            timeZones.find(
                              zone => zone.value === update.timeZone,
                            ) || null
                          }
                          required
                          testSelector="user-profile_profile_timezone_select"
                        />
                      )}
                    </div>
                  </Row>
                  <div className="form-group has-feedback col-md-12 update-profile-footer">
                    <Button
                      type="submit"
                      children="Save"
                      testSelector="user-profile_profile_save_btn"
                      disabled={isDisable()}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ibox float-e-margins">
        {isPswUpdating ? (
          <Loader />
        ) : (
          [
            <div className="ibox-title">
              <h5>Change password</h5>
              <IboxTools />
            </div>,
            <div className="ibox-content padding-left padding-right">
              <form className="m-t add-forms" onSubmit={submitPass}>
                <div className="form-group">
                  <Input
                    onChange={e => setInputValue(e, 'oldPassword')}
                    value={passwordInput.oldPassword || ''}
                    type="password"
                    label="Current Password"
                    testSelector="user-profile_profile_current-password_input"
                    placeholder="Enter Current Password"
                    required
                  />
                </div>
                <div className="form-group">
                  <Input
                    onChange={e => setInputValue(e, 'password')}
                    value={passwordInput.password || ''}
                    type="password"
                    label="New Password"
                    testSelector="user-profile_profile_new-password_input"
                    placeholder="Enter New Password’"
                    required
                  />
                </div>
                <Button
                  type="submit"
                  theme="full-width"
                  disabled={isDisablePsw()}>
                  Change Password
                </Button>
              </form>
            </div>,
          ]
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  update: state.userProfile.update,
  passwordInput: state.userProfile.password,
  isPswUpdating: state.userProfile.isPswUpdating,
});

const mapDispatchToProps = dispatch => ({
  setProfile: () => dispatch(actions.setProfile()),
  setProfileValue: (e, name) =>
    dispatch(actions.setProfileValue(targetValue(e), name)),
  updateProfile: e => {
    e.preventDefault();
    dispatch(actions.updateProfile());
  },
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  submitPass: e => {
    e.preventDefault();
    dispatch(actions.submitPass());
  },
});

export const ProfileTest = Profile; // for tests
// can we do it like this or better somehow to mock store inside test file?

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
