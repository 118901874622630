import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Export';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';

const Preview = ({ turnModal, modalPreview, init }) => {
  useEffect(() => {
    init();
  }, []);
  const { data, format, dataReady, openModal } = modalPreview;
  return (
    <Modal className="modal-container" show={openModal} onHide={turnModal}>
      <Modal.Header>
        <h4 className="modal-title">Preview</h4>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'scroll' }}>
        {dataReady &&
          (format == 'json' ? (
            <div>
              <pre>
                <code>{data}</code>
              </pre>
            </div>
          ) : (
            <table className="table table--striped table--hover">
              <tbody>
                {data.map(x => (
                  <tr key={`tr${x}`}>
                    {x.map((y, i) => (
                      <td key={`td${y}-${i}`}>{y}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={turnModal}
          colour="white"
          children="Close"
          testSelector="export_orders_ptrview_close_btn"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalPreview: state.exportPage.modalPreview,
});

const mapDispatchToProps = dispatch => ({
  turnModal: () => dispatch(actions.turnPreviewModal()),
  init: () => dispatch(actions.initPreviewModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preview);
