import React from 'react';

const AcidFacility = () =>
  <svg
    viewBox="10 8 18 18"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    className="icon--fill"
  >
    <path d="M 21.771 13.168 L 18.518 13.168 L 17.704 13.168 C 17.253 13.168 16.889 13.532 16.889 13.981 C 16.889 14.432 17.253 14.796 17.704 14.796 L 17.704 18.171 C 17.704 18.472 17.619 18.77 17.462 19.024 L 14.711 23.498 C 14.541 23.777 14.449 24.094 14.449 24.423 C 14.449 25.396 15.24 26.187 16.213 26.187 L 24.076 26.187 C 25.049 26.187 25.84 25.396 25.84 24.423 C 25.84 24.097 25.749 23.777 25.578 23.498 L 22.827 19.027 C 22.67 18.77 22.586 18.475 22.586 18.175 L 22.586 14.796 C 23.036 14.796 23.4 14.432 23.4 13.981 C 23.4 13.532 23.036 13.168 22.586 13.168 L 21.771 13.168 Z M 19.331 18.171 L 19.331 14.796 L 20.957 14.796 L 20.957 18.171 C 20.957 18.776 21.126 19.364 21.442 19.878 L 22.319 21.305 L 17.971 21.305 L 18.847 19.878 C 19.163 19.364 19.331 18.776 19.331 18.171 Z" />
  </svg>

export default AcidFacility;
