import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

const LineGraph = props => {
  const { graphData } = props;

  const transformedData = {
    labels: graphData?.actual.map(item => item.x),
    datasets: [
      {
        label: 'Actual',
        borderColor: 'rgba(66, 147, 33, 1)',
        data: graphData?.actual.map(item => item.y),
      },
      {
        label: 'Initial',
        borderColor: 'rgba(128, 128, 128)',
        data: graphData?.initial.map(item => item.y),
      },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: 'rgba(255, 255, 255, 0.7)',
          },
          scaleLabel: {
            display: true,
            labelString: 'DAY',
            fontColor: 'rgba(255, 255, 255, 1)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: 'rgba(255, 255, 255, 0.7)',
          },
          scaleLabel: {
            display: true,
            labelString: 'TONS',
            fontColor: 'rgba(255, 255, 255, 1)',
          },
        },
      ],
    },
  };

  return (
    <div>
      <Line data={transformedData} height={122} options={options} />
    </div>
  );
};

export default LineGraph;
