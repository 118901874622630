import React, { useState } from 'react';

import Header from 'components/common/Header';
import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';

import Icon from 'components/shared/Icon';
import AddCarrier from './AddCarrierModal';
import SendNotification from './SendNotification';

const CarrierHeader = () => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  return (
    <Header title="Carrier Profile">
      <Authorize
        abilityPermissions={[
          { resource: 'Carriers', permissions: ['create'] },
        ]}>
        <Button
          onClick={() => setShowModalAdd(true)}
          testSelector="admin-carriers_add_btn"
          inverse
          className="float-end">
          <Icon className="icon--margin-right" icon="plus-square" />
          Add Carrier
        </Button>
      </Authorize>
      <Authorize
        abilityPermissions={[
          { resource: 'Carriers', permissions: ['update'] },
        ]}>
        <Button
          onClick={() => setShowNotificationModal(true)}
          testSelector="admin-carriers_send-notification_btn"
          inverse
          className="float-end">
          Send Notification
        </Button>
      </Authorize>

      {showModalAdd && (
        <AddCarrier
          showModalAdd={showModalAdd}
          closeAdd={() => setShowModalAdd(false)}
        />
      )}
      <SendNotification
        showModal={showNotificationModal}
        close={() => setShowNotificationModal(false)}
      />
    </Header>
  );
};
export default CarrierHeader;
