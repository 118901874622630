import { Constants } from './constants';

const initialState = {
  searchValue: '',
  searchResult: '',
};

const topHeader = (state = initialState, action) => {
  switch (action.type) {
    case Constants.TOP_HEADER_SAVE_SEARCH:
      return {
        ...state,
        searchResult: action.searchResult,
      };
    case Constants.TOP_HEADER_SET_VALUE:
      return {
        ...state,
        searchValue: action.value,
      };
    case Constants.TOP_HEADER_CLEAR_STATE:
      return {
        ...state,
        searchValue: '',
      };
    default:
      return state;
  }
};

export default topHeader;
