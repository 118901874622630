export const resourceConstants = {
  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  GET_JOB_MANAGERS_REQUEST: 'GET_JOB_MANAGERS_REQUEST',
  GET_JOB_MANAGERS_SUCCESS: 'GET_JOB_MANAGERS_SUCCESS',
  GET_JOB_MANAGERS_FAILURE: 'GET_JOB_MANAGERS_FAILURE',

  GET_CARRIERS_REQUEST: 'GET_CARRIERS_REQUEST',
  GET_CARRIERS_SUCCESS: 'GET_CARRIERS_SUCCESS',
  GET_CARRIERS_FAILURE: 'GET_CARRIERS_FAILURE',

  GET_JOBS_REQUEST: 'GET_JOBS_REQUEST',
  GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
  GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',

  GET_DEMURRAGE_REASONS_REQUEST: 'GET_DEMURRAGE_REASONS_REQUEST',
  GET_DEMURRAGE_REASONS_SUCCESS: 'GET_DEMURRAGE_REASONS_SUCCESS',
  GET_DEMURRAGE_REASONS_FAILURE: 'GET_DEMURRAGE_REASONS_FAILURE',

  GET_ALL_DISTRICTS_REQUEST: 'GET_ALL_DISTRICTS_REQUEST',
  GET_ALL_DISTRICTS_SUCCESS: 'GET_ALL_DISTRICTS_SUCCESS',
  GET_ALL_DISTRICTS_FAILURE: 'GET_ALL_DISTRICTS_FAILURE',

  GET_SAND_VENDORS_REQUEST: 'GET_SAND_VENDORS_REQUEST',
  GET_SAND_VENDORS_SUCCESS: 'GET_SAND_VENDORS_SUCCESS',
  GET_SAND_VENDORS_FAILURE: 'GET_SAND_VENDORS_FAILURE',

  GET_CERTIFICATES_REQUEST: 'GET_CERTIFICATES_REQUEST',
  GET_CERTIFICATES_SUCCESS: 'GET_CERTIFICATES_SUCCESS',
  GET_CERTIFICATES_FAILURE: 'GET_CERTIFICATES_FAILURE',

  GET_CONTAINER_VENDORS_REQUEST: 'GET_CONTAINER_VENDORS_REQUEST',
  GET_CONTAINER_VENDORS_SUCCESS: 'GET_CONTAINER_VENDORS_SUCCESS',
  GET_CONTAINER_VENDORS_FAILURE: 'GET_CONTAINER_VENDORS_FAILURE',

  GET_EQUIPMENT_REQUEST: 'GET_EQUIPMENT_REQUEST',
  GET_EQUIPMENT_SUCCESS: 'GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_FAILURE: 'GET_EQUIPMENT_FAILURE',

  CLEAR_STORE_EQUIPMENT: 'CLEAR_STORE_EQUIPMENT',

  GET_CARRIER_DOCS_REQUEST: 'GET_CARRIER_DOCS_REQUEST',
  GET_CARRIER_DOCS_SUCCESS: 'GET_CARRIER_DOCS_SUCCESS',
  GET_CARRIER_DOCS_FAILURE: 'GET_CARRIER_DOCS_FAILURE',

  SET_EQUIPMENT_FILTER_NUMBER: 'SET_EQUIPMENT_FILTER_NUMBER',

  GET_DISTRICT_TO_CARRIER_REQUEST: 'GET_DISTRICT_TO_CARRIER_REQUEST',
  GET_DISTRICT_TO_CARRIER_SUCCESS: 'GET_DISTRICT_TO_CARRIER_SUCCESS',
  GET_DISTRICT_TO_CARRIER_FAILURE: 'GET_DISTRICT_TO_CARRIER_FAILURE',
};
