import axios from 'axios';
import FormData from 'form-data';

export const jobs = {
  get() {
    return axios.get('v2/jobs', {});
  },
  uploadDocuments(params) {
    const data = new FormData();
    data.append('jobId', params.jobId);
    data.append('documents', params.file);
    data.append('name', params.name);

    return axios.post('v2/documents/upload', data, {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    });
  },
  add(data) {
    return axios.post('v2/job', data);
  },
  delete(id) {
    return axios.post(`v2/job/${id}/delete`, {});
  },
  complete(id) {
    return axios.post(`v2/job/${id}`, {});
  },
  uncomplete(id) {
    return axios.post(`v2/job/${id}/uncomplete`, {});
  },
  details(id) {
    return axios.get(`v2/job/${id}/details`, {});
  },
  update(data) {
    return axios.put(`v2/job/${data.id}`, data);
  },
  certificates(data) {
    return axios.get(`v2/jobs/${data.id}/certificates`, data);
  },
  report(jobId) {
    return axios.post(`v2/job/${jobId}/report`, {});
  },
  addFacilityId(data) {
    return axios.put(`v2/job/${data.id}/vine-facility-id`, data);
  },
  validateCustomerJobId(data) {
    return axios.post(`/v2/job/customer/${data.customerId}/validate`, {
      customerId: data.customerId,
      customerJobId: data.customerJobId,
    });
  },
  getJobPo(jobId) {
    return axios.get(`v2/job/${jobId}/available-po`, {});
  },
  certificate: {
    get(data) {
      return axios.get(`v2/jobs/${data.id}/certificates`, data);
    },
    add(data) {
      return axios.post(
        `v2/jobs/${data.jobId}/certificates/${data.certId}`,
        data,
      );
    },
    delete(data) {
      return axios.delete(
        `v2/jobs/${data.jobId}/certificates/${data.certId}`,
        {},
      );
    },
  },
  onsiteStorageTypes: {
    get() {
      return axios.get('v2/job/on-site-storage', {});
    },
  },
  stage: {
    get(id) {
      return axios.get(`v2/job/${id}/stages`, {});
    },
    alertEmails: {
      get(jobId) {
        return axios.get(`v2/job/${jobId}/stage/report/emails`, {});
      },
      put(params) {
        const { jobId, emails } = params;
        return axios.put(`v2/job/${jobId}/stage/report/emails`, { emails });
      },
      post(params) {
        const { jobId, email } = params;
        return axios.post(`v2/job/${jobId}/stage/report/emails`, { email });
      },
      delete(params) {
        const { jobId, id } = params;
        return axios.delete(`v2/job/${jobId}/stage/report/emails/${id}`, {});
      },
    },
  },
  document: {
    get(params) {
      const { jobId, selectedDocumentId } = params;
      return axios({
        method: 'get',
        url: `v2/job/${jobId}/documents/${selectedDocumentId}`,
        headers: { 'Content-Type': 'image/*' },
        responseType: 'arraybuffer',
      });
    },
  },
  operators: {
    add(params) {
      const { jobId, customerId, data } = params;
      return axios.post(`v2/job/${jobId}/operators/companies/${customerId}`, {
        entityData: data,
      });
    },
    delete(params, payload) {
      const { jobId, customerId } = params;
      return axios.put(
        `v2/job/${jobId}/operators/companies/${customerId}`,
        payload,
      );
    },
    update(params, payload) {
      const { jobId, customerId } = params;
      return axios.patch(
        `v2/job/${jobId}/operators/companies/${customerId}`,
        { entityData: payload }
      );
    },
    getShared(jobId) {
      return axios.get(`v2/job/${jobId}/operators/companies`, {});
    },
  },
  linkQbId(data) {
    const { jobId, link } = data;
    return axios.put(`/v2/job/${jobId}/link-qb`, {
      link,
    });
  },
};
