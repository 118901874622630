import axios from 'axios';

export const jobManager = {
  home() {
    return axios.get('v2/home');
  },
  get() {
    return axios.get('v2/jobs', {});
  },
  // Unused??
  addSandCoordinator({ address, phone, email, name }) {
    return axios.post('sandCoordinatorSignUp', {
      address,
      contactNo: phone,
      email,
      name,
    });
  },
  // Unused??
  assignSandCoordinator(jobId, sandCoordinatorId) {
    return axios.post('assignSandCoordinator', {
      jobId,
      sandCoordinatorId,
    });
  },
  deleteSandCoordinator(sandCoordinatorId) {
    return axios.delete(`v2/sand-coordinators/${sandCoordinatorId}`, {
      data: { sandCoordinator: sandCoordinatorId },
    });
  },
  editSandCoordinator({ address, phone, email, id, name }) {
    return axios.post('v2/updateSandCoordinator', {
      address,
      contactNumber: phone,
      email,
      id,
      name,
    });
  },
  // Unused??
  freeSandCoordinator(jobId, sandCoordinatorId) {
    return axios.post('freeSandCoordinator', {
      jobId,
      sandCoordinatorId,
    });
  },
  getJobDetails(id, param) {
    return axios.get(`v2/job/${id}/details?include=documents`, param);
  },
  getJobEvents(id) {
    return axios.get(`v2/job/${id}/comments`, {});
  },
  sendComment(comment, jobId) {
    return axios.post(`v2/job/${jobId}/comments`, { comment });
  },
  getJobManagers() {
    return axios.get('v2/job-managers', {});
  },
  // Unused??
  deleteJobManager(id) {
    return axios.post('deleteJobManager', {
      jobManager: id,
    });
  },
  editJobManager(data, id) {
    return axios.post(`v2/job-manager/${id}/edit`, {
      address: data.address,
      cellPhone: data.cellPhone,
      dateOfBirth: data.birthDate.toDate(),
      email: data.email,
      homePhone: data.homePhone,
      name: data.name,
      payPeriod: data.payPeriod,
      sex: data.sex,
      socialSecurityNumber: data.socialSecurityNumber,
    });
  },
  makeSuperAdmin(id) {
    return axios.post('v2/makeSuperAdmin', {
      jobManager: id,
    });
  },
  addJobManager(data) {
    return axios.post('v2/job-managers', {
      address: data.address,
      cellPhone: data.cellPhone,
      dateOfBirth: data.birthDate.toDate(),
      email: data.email,
      homePhone: data.homePhone,
      name: data.name,
      payPeriod: data.payPeriod,
      sex: data.sex,
      socialSecurityNumber: data.socialSecurityNumber,
    });
  },
  getStatusedOrderPayments() {
    return axios.get('v2/job-manager/statused-order-payments', {
      status: [1, 2, 4, 7],
    });
  },
  markOrderAsAccepted(orderId) {
    return axios.post('v2/markOrderAsAccepted', {
      orderId,
    });
  },
  declineOrderBilling(comment, orderId) {
    return axios.post('v2/declineOrderBilling', {
      comment,
      orderId,
    });
  },
  getSettings() {
    return axios.get('v2/settings', {});
  },
  applySetting(data) {
    return axios.put('v2/settings/set', data);
  },
  setSettingQueue(queueDistanceMark, queueTimingMark) {
    return axios.put('v2/settings/queue/set', {
      queueDistanceMark,
      queueTimingMark,
    });
  },
  getCurrentPrice() {
    return axios.get('v2/fuel/newest', {});
  },
  getFuelBreakdown() {
    return axios.get('v2/settings/fuel', {});
  },
  // Unused??
  getInvoiceJobManager(customerId, endDate, startDate) {
    return axios.post('getInvoiceJobManager', {
      customerId,
      endDate,
      startDate,
    });
  },
  // Unused??
  changePassword(oldPassword, password) {
    return axios.post('changePasswordJobManager', {
      oldPassword,
      password,
    });
  },
  getJobManagerProfile() {
    return axios.get('v2/job-managers/profile', {});
  },
  updateProfileJobManager(data) {
    return axios.patch('v2/job-managers/profile', {
      address: data.address,
      cellPhone: data.cellPhone,
      homePhone: data.homePhone,
      name: data.name,
      payPeriod: data.payPeriod,
    });
  },
  orders: {
    get(param) {
      return axios.post(`v2/order/search`, param);
    },
  },
  updateAutoOrder(jobId, autoOrder) {
    return axios.put(`v2/job/${jobId}/silo/auto-order`, {
      autoOrder,
    });
  },
  updateNotificationInfo(data) {
    return axios.put(`v2/job/${data.jobId}/silo/emails`, { ...data });
  },
  getDemurrageReason() {
    return axios.get(`v2/job-manager/demurrage-reason`);
  },
  getJobAlerts(jobId) {
    return axios.get(`v2/job/${jobId}/job-alerts`);
  },
};
