import React from 'react';
import { connect } from 'react-redux';
import Input from 'components/shared/Input';

const Settings = ({ settings }) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-lg-12">
        <div
          className="form-group has-feedback col-md-4"
          style={{ paddingTop: '20px' }}>
          <div className="row" style={{ margin: 0 }}>
            <Input
              type="checkbox"
              testSelector="user-profile_settings_allow-notifications_input"
              defaultChecked={Boolean(settings.allowNotifications)}
              disabled
              label="Allow Notifications"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  settings: state.lookUp.user.settings,
});

export const SettingsTest = Settings; // for tests

export default connect(
  mapStateToProps,
  null,
)(Settings);
