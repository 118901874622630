import axios from 'axios';

export const notarizations = {
  getReport(orderId) {
    return axios.get(`v2/order/${orderId}/notarization/report`, {});
  },
  setSettings(data) {
    return axios.put('v2/notarizationSettings', data);
  },
  getSettings() {
    return axios.get('v2/notarizationSettings', {});
  },
  setCustomerSettings(params) {
    const { data, customerId } = params;
    return axios.put(`v2/customer/${customerId}/notarization/settings`, data);
  },
  getCustomerSettings(customerId) {
    return axios.get(`v2/customer/${customerId}/notarization/settings`, {});
  },
};
