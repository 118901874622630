import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import EventsSidebar from './components/EventsSidebar';
import actions from './actions';

const Events = ({ init, events, enqueueSnackbar, closeSnackbar }) => {
  useEffect(() => {
    init({
      addNotification: enqueueSnackbar,
      removeNotification: closeSnackbar,
    });
  }, [closeSnackbar, enqueueSnackbar, init]);

  const { openEvents, eventsList, activeTab } = events;
  let filteredEvents = eventsList;

  switch (activeTab) {
    case 'completed':
      filteredEvents = eventsList.filter(
        item => item.type === 'order_completed',
      );
      break;
    case 'tracking':
      filteredEvents = eventsList.filter(
        item =>
          item.type !== 'order_completed' &&
          item.type !== 'new_order_message' &&
          item.type !== 'new_driver_message' &&
          item.type !== 'notarization_failed',
      );
      break;
    case 'alerts':
      filteredEvents = eventsList.filter(
        item =>
          item.type === 'notarization_failed',
      );
      break;
    case 'chat':
      filteredEvents = eventsList.filter(
        item =>
          item.type === 'new_order_message' ||
          item.type === 'new_driver_message',
      );
      break;
    default:
      break;
  }

  return <EventsSidebar filteredEvents={filteredEvents} open={openEvents} />;
};

const mapStateToProps = state => ({
  events: state.events,
});

const mapDispatchToProps = dispatch => ({
  init: props => dispatch(actions.init(props)),
});

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Events),
);
