const customersDefaultState = {
  collection: [],
  apiIsLoading: false,
  lastApiCallSucceeded: true,
};

export default (state = customersDefaultState, data) => {
  switch (data.type) {
    case 'CUSTOMERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'CUSTOMERS_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: data.customers,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'CUSTOMERS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    default:
      return state;
  }
};
