import React, { useState, useMemo, useEffect } from 'react';
import swal from 'bootstrap-sweetalert';

import Modal from 'components/shared/Modal';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';

import {
  useAddRuleMutation,
  useEditRuleMutation,
} from 'features/api/slsApiSlice';
import { useJobDetails } from 'api/v2/jobs';

const ruleTypeTitles = {
  commodity: 'Commodity',
  unit: 'Unit',
};
const ruleTypes = ['commodity', 'unit'];
const ruleTypesOptions = ruleTypes.map(name => ({
  value: name,
  label: `${ruleTypeTitles[name]} Rule`,
}));

const AddRuleModal = ({
  sandTypes,
  unitGroups,
  closeModal,
  open,
  jobId,
  rule,
  modify,
}) => {
  const [addRule] = useAddRuleMutation();
  const [editRule] = useEditRuleMutation();
  const [ruleSubject, setRuleSubject] = useState({});
  const [lowThresholdQuantity, setLowThresholdQuantity] = useState(0);
  const [alertEmail, setAlertEmail] = useState('');
  const [targetQuantity, setTargetQuantity] = useState(0);
  const [ruleType, setRuleType] = useState('commodity');

  const {
    data: { jobDesign },
  } = useJobDetails({ jobId });

  useEffect(() => {
    const clearState = () => {
      setRuleSubject({});
      setLowThresholdQuantity('');
      setAlertEmail('');
      setTargetQuantity('');
      setRuleType('commodity');
    };

    if (rule) {
      setRuleSubject({
        label: rule.ruleSubject.name,
        value: rule.ruleSubject,
      });
      setLowThresholdQuantity(rule.lowThresholdQuantity);
      setAlertEmail(rule.alertEmail);
      setTargetQuantity(rule.targetQuantity);
      setRuleType(rule.ruleType);
    } else {
      clearState();
    }
  }, [rule, open]);

  const handleSubmit = async () => {
    try {
      if (modify) {
        await editRule({
          jobId,
          ruleType,
          targetQuantity,
          alertEmail,
          lowThresholdQuantity,
          ruleSubject: ruleSubject.value,
        }).unwrap();
      } else {
        await addRule({
          jobId,
          ruleType,
          targetQuantity,
          alertEmail,
          lowThresholdQuantity,
          ruleSubject: ruleSubject.value,
        }).unwrap();
      }
      swal('Success!', '', 'success');
      return closeModal();
    } catch (err) {
      if (err.status === 400 && err.data.validationMessages) {
        return swal(err.data.validationMessages.join('\n'), '', '');
      }
      return swal(err.data.message || 'Something went wrong.', '', '');
    }
  };

  const unitOptions = useMemo(
    () =>
      unitGroups.reduce((acc, group) => {
        acc.push(
          ...group.units.map(unit => ({
            value: {
              externalId: group.externalId,
              externalProvider: group.externalProvider,
              id: unit.id,
              name: `${group.externalId} ${unit.id}`,
            },
            label: `${group.externalId} ${unit.id}`,
          })),
        );

        return acc;
      }, []),
    [unitGroups],
  );

  const commodityTypeOptions = useMemo(
    () =>
      sandTypes.map(sandType => ({
        value: { name: sandType.name, id: sandType.id },
        label: sandType.name,
      })),
    [sandTypes],
  );

  const loadWeight = jobDesign.find(
    i => i.sandTypeId === Number(ruleSubject.value?.id),
  )?.loadWeight;

  const warnDifference = useMemo(() => {
    if (ruleType === 'commodity') {
      if (targetQuantity - lowThresholdQuantity < loadWeight) {
        return true;
      }
    }
    return false;
  }, [lowThresholdQuantity, targetQuantity, ruleType, loadWeight]);

  const ruleSubjectOptions = useMemo(() => {
    switch (ruleType) {
      case 'commodity':
        return commodityTypeOptions;
      case 'unit':
        return unitOptions;
      default:
        return commodityTypeOptions;
    }
  }, [ruleType, commodityTypeOptions, unitOptions]);

  const body = (
    <>
      <Select
        inverse
        icon="down"
        options={ruleTypesOptions}
        onChange={item => {
          setRuleSubject({});
          setRuleType(item.value);
        }}
        placeholder="Rule Type:"
        value={{
          value: ruleType,
          label: `${ruleTypeTitles[ruleType]} Rule`,
        }}
        required
        isDisabled={modify}
      />
      <Select
        inverse
        icon="down"
        options={ruleSubjectOptions}
        onChange={item => setRuleSubject(item)}
        placeholder={
          ruleType === 'commodity' ? 'Select Commodity' : 'Select Unit'
        }
        value={ruleSubject}
        required
        isDisabled={modify}
      />
      <Input
        type="number"
        id="lowThresholdQuantity"
        value={lowThresholdQuantity}
        onChange={event => setLowThresholdQuantity(event.target.value)}
        label="Low Threshold Quantity:"
        required
      />
      <Input
        type="email"
        id="alertEmail"
        value={alertEmail}
        onChange={event => setAlertEmail(event.target.value)}
        label="Alert Email:"
        required
      />
      <Input
        type="number"
        id="targetQuantity"
        value={targetQuantity}
        onChange={event => setTargetQuantity(event.target.value)}
        label="Target Quantity:"
        required
      />
      {warnDifference && (
        <span style={{ color: 'red' }}>
          <strong>
            Difference between low and target threshold is below the commodity
            quantity of {Number(loadWeight).toLocaleString()} lbs.
          </strong>
        </span>
      )}
    </>
  );
  const footer = (
    <span>
      <Button onClick={handleSubmit}>{modify ? 'Save' : 'Add'}</Button>{' '}
      <Button
        onClick={() => {
          closeModal();
        }}>
        Cancel
      </Button>
    </span>
  );

  return (
    <Modal
      dialogClassName="modify-modal"
      title={modify ? 'Edit Rule' : 'Add Rule'}
      onClose={closeModal}
      open={open}
      body={body}
      footer={footer}
    />
  );
};
export default AddRuleModal;
