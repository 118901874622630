import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import {
	get,
	remove,
	restore,
	edit,
	add,
	notification,
	setNewPass
} from './queries';

export const useUsers = () =>
	useQuery({
		queryKey: ['users'],
		queryFn: get,
		placeHolderData: []
	});

export const useRemoveUser = () => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (id) => remove(id),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users'] });
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
}

export const useRestoreUser = () => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (id) => restore(id),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users'] });
			swal('Success', 'User restored successfully', 'success');
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
}

export const useEditUser = () => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (data) => edit(data),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users'] });
			swal('Success', 'User updated successfully', 'success');
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
};

export const useAddUser = () => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (data) => add(data),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users'] });
			swal('Success', 'User added successfully', 'success');
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
}

export const useSendNotification = () => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (value) => notification(value),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users'] });
			swal('Success', 'Notification sent successfully', 'success');
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
}

export const useSetNewPass = (userId) => {
	const client = useQueryClient();

	return useMutation({
		mutationFn: (data) => setNewPass(data),
		onSuccess: () => {
			client.invalidateQueries({ queryKey: ['users', userId] });
			swal('Success', 'Password updated successfully', 'success');
		},
		onError: (error) => {
			swal('Error', error.response?.data?.message, 'error');
		}
	});
}
