export const selectAll = (
  setChecked,
  setIds,
  setAllLabel,
  selectAllLabel,
  array,
  select,
) => {
  const checked = [];
  const selectedArray = [];
  const data = array;
  for (let i = 0; i < data.length; i++) {
    checked[data[i][select]] = !selectAllLabel;
    if (checked[data[i][select]] === true) {
      selectedArray.push(data[i][select]);
    }
    if (checked[data[i][select]] === false) {
      const index = selectedArray.indexOf(data[i][select]);
      selectedArray.slice(index, 1);
    }
  }
  setChecked(checked);
  setAllLabel(!selectAllLabel);
  setIds(selectedArray);
};

export const selectOne = (setChecked, checked, ids, setIds, id) => {
  const checkedd = checked;
  let selectedArray = ids.slice();
  checkedd[id] = !checkedd[id];

  if (checkedd[id] === true && selectedArray.indexOf(id) === -1) {
    selectedArray.push(id);
  }
  if (checkedd[id] === false && selectedArray.indexOf(id) > -1) {
    selectedArray = selectedArray.filter(item => item !== id);
  }
  setChecked(checkedd);
  setIds(selectedArray);
};

export const filterOptions = (
  filterArray,
  filterKey,
  filterValue,
  idKey,
  labelKey,
) => {
  const id = idKey || 'id';
  const label = labelKey || 'name';
  let filteredArray = [];
  if (filterKey && filterValue !== null) {
    filteredArray = filterArray
      .filter(item => item[filterKey] === filterValue)
      .map(filteredItem => ({
        value: filteredItem[id],
        label: filteredItem[label],
        disabled: filteredItem.disabled || false,
      }));
  } else {
    filteredArray = filterArray.map(item => ({
      value: item[id],
      label: item[label],
      disabled: item.disabled || false,
    }));
  }
  return filteredArray;
};
