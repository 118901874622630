import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

const OnsiteStorageType = ({
  index,
  item,
  setArrayValue,
  deleteArrayValue,
  commodityOptions,
  storageTypeOptions,
}) => (
  <Row key={`storage-${index}`}>
    <Col lg={3} md={4} className="form-group has-feedback no-padding">
      <Select
        label="Storage Type"
        onChange={setArrayValue('storageType', index)}
        value={item.storageType}
        options={storageTypeOptions}
        required
        testSelector="jobs_add_onsite-storage_sane-type_select"
      />
    </Col>
    <Col md={3} className="form-group has-feedback">
      <Input
        type="text"
        onChange={setArrayValue('name', index)}
        value={item.name || ''}
        label="Name"
        required
        testSelector="jobs_add_storage_name_input"
      />
    </Col>
    {item.storageType?.isLive ? (
      <Col md={5} className="form-group has-feedback no-padding">
        <Input
          onChange={e =>
            setArrayValue('storageId', index)(
              e.target.value.replace(/[^\w]|_/g, ''),
            )
          }
          value={item?.storageId ? item.storageId.trim() : ''}
          label="Storage ID"
          required
          min="0"
          testSelector="jobs_add_onsite-storageId_input"
        />
        <small>
          <span className="input__text__label--required"> *</span> Live storage
          will appear once data has been fetched from the source.
        </small>
      </Col>
    ) : (
      <>
        <Col md={2} className="form-group has-feedback no-padding">
          <Input
            type="number"
            onChange={setArrayValue('capacity', index)}
            value={item.capacity || ''}
            label="Capacity"
            required
            min="0"
            testSelector="jobs_add_storage_capacity_input"
          />
        </Col>
        <Col
          md={3}
          className="form-group has-feedback"
          style={{ paddingRight: 0 }}>
          <Select
            label="Commodity"
            onChange={setArrayValue('commodity', index)}
            value={item.commodity}
            options={commodityOptions}
            required
            testSelector="jobs_add_storage_commodity_input"
          />
          {!!item.commodity && (
            <div style={{ paddingLeft: 10 }}>
              <b>UOM:</b> {item.commodity?.unitOfMeasureName}
            </div>
          )}
        </Col>
      </>
    )}
    <div className="form-group has-feedback col-md-1">
      <Button
        theme="square"
        colour="white"
        testSelector="jobs-list_onsite-storage-type_delete_btn"
        onClick={deleteArrayValue(index, 'storageConfig')}>
        <Icon icon="trash" />
      </Button>
    </div>
  </Row>
);

export default OnsiteStorageType;
