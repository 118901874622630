import React from 'react';
import { Modal, Row } from 'react-bootstrap';

import Select from 'components/shared/Select';
import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { carrierCompanyTypes } from 'components/globalInfo/companyTypes';

import { useCreateCarrier } from 'api/v2/carriers';
import { useDistricts } from 'api/v2/districts';
import authService from 'services/auth/service';
import multiSelectOptions from 'utils/multiSelectOptions';
import { ROLES } from 'config/constants';
import { useCarrierForm } from './hooks';

const AddCarrier = ({ showModalAdd, closeAdd }) => {
  const user = authService.getUser();
  const { data: formData, handlers, isValid } = useCarrierForm();

  const { data: districts } = useDistricts();
  const { mutate: createCarrier, isPending } = useCreateCarrier();

  const handleSubmit = e => {
    e.preventDefault();
    createCarrier(
      {
        ...formData,
        selectedDistricts: formData.selectedDistricts.map(i => ({
          id: i.value,
        })),
      },
      { onSuccess: closeAdd },
    );
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModalAdd} onHide={closeAdd}>
        <form
          onSubmit={handleSubmit}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <Modal.Title as="h3">Add Carrier</Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => handlers.valueChange('name', e.target.value)}
                  value={formData.name}
                  label="Name"
                  required
                  testSelector="admin-carriers_add_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  value={formData.email}
                  onChange={e => handlers.valueChange('email', e.target.value)}
                  type="email"
                  label="Email"
                  testSelector="admin-carriers_add_email_input"
                  required
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="tel"
                  value={formData.phone}
                  onChange={e => handlers.valueChange('phone', e)}
                  label="Contact number"
                  required
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('fleetSize', e.target.value)
                  }
                  value={formData.fleetSize}
                  label="Fleet size"
                  required
                  testSelector="admin-carriers_add_fleet-size_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('address', e.target.value)
                  }
                  value={formData.address}
                  label="Address"
                  required
                  testSelector="admin-carriers_add_address_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => handlers.valueChange('city', e.target.value)}
                  value={formData.city}
                  label="City"
                  required
                  testSelector="admin-carriers_add_city_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => handlers.valueChange('state', e.target.value)}
                  value={formData.state}
                  label="State"
                  required
                  testSelector="admin-carriers_add_state_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => handlers.valueChange('zip', e.target.value)}
                  value={formData.zip}
                  label="ZIP"
                  required
                  testSelector="admin-carriers_add_zip_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('numberMC', e.target.value)
                  }
                  value={formData.numberMC}
                  label="MC number"
                  required
                  testSelector="admin-carriers_add_mc-number_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('numberEIN', e.target.value)
                  }
                  label="EIN/SS number"
                  required
                  testSelector="admin-carriers_add_ein-number_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('numberUS', e.target.value)
                  }
                  value={formData.numberUS}
                  label="US DOT number"
                  required
                  testSelector="admin-carriers_add_us-dot_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="file"
                  accept="image/*"
                  label="Upload logo"
                  onChange={e =>
                    handlers.valueChange('file', e.target.files[0])
                  }
                  required
                  testSelector="admin-carriers_add_logo_file-input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selected => handlers.districtChange(selected)}
                  options={multiSelectOptions(districts, 'id', 'name')}
                  placeholder="Select districts"
                  value={formData.selectedDistricts}
                  required
                  testSelector="admin-carriers_add_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  onChange={selected => {
                    if (selected.value) {
                      handlers.valueChange('companyType', selected.value);
                    }
                  }}
                  options={
                    user.companyType === '3' || user.companyType === '2'
                      ? carrierCompanyTypes.filter(i => i.value === 4)
                      : carrierCompanyTypes
                  }
                  placeholder="Select Company Type"
                  required
                  testSelector="admin-carriers_add_company-type_select"
                />
              </div>
            </Row>
            <Authorize
              abilityPermissions={[
                { resource: 'Carrier-documents', permissions: ['create'] },
              ]}>
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={e =>
                      handlers.valueChange('qbCarrierId', e.target.value)
                    }
                    value={formData.qbCarrierId}
                    label="QuickBooks ID"
                    type="number"
                    testSelector="admin-carriers_add_qb_carrier_id_input"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="number"
                    onChange={e =>
                      handlers.valueChange('factorViewId', e.target.value)
                    }
                    value={formData.factorViewId}
                    label="FactorView ClientID"
                    min="0"
                    max="999999"
                    disabled={
                      user.role === ROLES.CARRIER ||
                      user.role === ROLES.CUSTOMER
                    }
                    testSelector="admin-carriers_add_factor_view_id_input"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={e =>
                      handlers.valueChange('bcSync', e.target.checked)
                    }
                    value={formData.bcSync}
                    label="BC Sync"
                    type="checkbox"
                    testSelector="admin-carriers_add_bc_sync_input"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    testSelector="admin-carriers_add_factorview_is_purchasable"
                    onChange={e =>
                      handlers.valueChange('isNonPurchasable', e.target.checked)
                    }
                    isChecked={formData.isNonPurchasable}
                    disabled={
                      user.role === ROLES.CARRIER ||
                      user.role === ROLES.CUSTOMER
                    }
                    label="FactorView Non-Purchasable"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={e =>
                      handlers.valueChange(
                        'interstateCertified',
                        e.target.checked,
                      )
                    }
                    value={formData.interstateCertified}
                    label="Interstate Certified"
                    type="checkbox"
                    testSelector="admin-carriers_add_interstate_certified_input"
                  />
                </div>
              </Row>
            </Authorize>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={closeAdd}
              colour="white"
              testSelector="admin-carriers_add_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={!isValid || isPending}
              testSelector="admin-carriers_add_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
export default AddCarrier;
