import { combineReducers } from '@reduxjs/toolkit';
import moment from 'moment';

const init = {};

const order = (state = init, action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_SET_ORDER':
      return {
        ...state,
        ...action.order,
      };
    case 'ORDER_ACTIONS_CLEAR_ORDER':
      return {};
    default:
      return state;
  }
};

const initDivert = {
  openModal: false,
  jobs: [],
  input: {
    jobId: '',
    additionalMileage: '',
    description: '',
  },
};

const divert = (state = initDivert, action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_DIVERT_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'ORDER_ACTIONS_DIVERT_INIT_JOBS':
      return {
        ...state,
        jobs: action.jobs,
      };
    case 'ORDER_ACTIONS_DIVERT_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_DIVERT_SUCCESS':
      return initDivert;
    case 'ORDER_ACTIONS_DIVERT_CLEAR':
      return initDivert;
    default:
      return state;
  }
};

const initRequeue = {
  openModal: false,
  input: {},
  pos: [],
  isLoaded: false,
};

const requeue = (state = initRequeue, action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_REQUEUE_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'ORDER_ACTIONS_REQUEUE_INIT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_ACTIONS_REQUEUE_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_REQUEUE_REQUEST':
      return {
        ...state,
        isLoaded: !state.isLoaded,
      };
    case 'ORDER_ACTIONS_REQUEUE_SUCCESS':
      return initRequeue;
    case 'ORDER_ACTIONS_REQUEUE_CLEAR_STATE':
      return initRequeue;
    default:
      return state;
  }
};

const initTransfer = {
  openModal: false,
  drivers: [],
  input: {
    percentage: 0,
    date: moment(),
    newDriverId: '',
    filterValue: '',
    comment: '',
  },
};

const transfer = (state = initTransfer, action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_TRANSFER_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'ORDER_ACTIONS_TRANSFER_INIT_DRIVER':
      return {
        ...state,
        drivers: action.drivers,
      };
    case 'ORDER_ACTIONS_TRANSFER_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_TRANSFER_SUCCESS':
      return initTransfer;
    default:
      return state;
  }
};

const initUploadST = {
  openModal: false,
  input: {
    sandTicketNo: '',
    weight: '',
    driverId: '',
    orderId: '',
    sandTicketType: '',
    containerIds: [],
    url: null,
    crop: {
      x: 10,
      y: 10,
    },
  },
  init: {
    containers: [],
    sandTicketsTypes: [],
    containersPerTruck: 1,
  },
  isLoaded: false,
};

const uploadST = (state = Object.assign({}, initUploadST), action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_UPLOAD_ST_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_INPUT':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_INIT_DATA':
      return {
        ...state,
        init: {
          ...state.init,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_REQUEST':
      return {
        ...state,
        isLoaded: !state.isLoaded,
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS':
      return initUploadST;
    default:
      return state;
  }
};

const initModify = {
  openModal: false,
  input: {},
  notificationInfo: {},
  stages: [],
  pos: [],
  isLoaded: false,
  orderTickets: {},
};

const modify = (state = initModify, action) => {
  switch (action.type) {
    case 'ORDER_ACTIONS_MODIFY_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'ORDER_ACTIONS_MODIFY_INIT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_ACTIONS_MODIFY_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ORDER_ACTIONS_MODIFY_REQUEST':
      return {
        ...state,
        isLoaded: !state.isLoaded,
      };
    case 'ORDER_ACTIONS_MODIFY_SUCCESS':
      return initModify;
    case 'ORDER_ACTIONS_MODIFY_CLEAR_STATE':
      return initModify;
    default:
      return state;
  }
};

const initDemurrageReason = {
	openModal: false,
	dataDemurrage: {},
	input: {
		wellsiteReason: '',
		sandSiteReason: ''
	}
};

const demurrageReason = (state = initDemurrageReason, action) => {
	switch (action.type) {
		case 'ORDER_ACTIONS_DEMURRAGE_TURN':
			return {
				...state,
				openModal: !state.openModal
			};
		case 'ORDER_ACTIONS_DEMURRAGE_INIT_VALUES':
			return {
				...state,
				dataDemurrage: action.data
			};
		case 'ORDER_ACTIONS_DEMURRAGE_SET_INPUT_VALUE':
			return {
				...state,
				input: {
					...state.input,
					[action.name]: action.value
				}
			};
		case 'ORDER_ACTIONS_SET_REASON_SUCCESS':
			return initDemurrageReason;
		default:
			return state;
	}
};

export default combineReducers({
  modify,
  uploadST,
  divert,
  transfer,
  order,
  requeue,
  demurrageReason,
});
