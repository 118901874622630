import axios from 'axios';

export const queue = {
  getAvailableDrivers() {
    return axios.post('v2/job-manager/get-available-drivers', {});
  },
  getOrders() {
    return axios.post('v2/order/search', {
      isInQueue: true,
      statuses: [0, 1],
    });
  },
};
