import axios from 'axios';

export const wellSite = {
  get() {
    return axios.get('v2/locations/wellsites');
  },
  getWellsitesForCustomer(customerId) {
    return axios.get('v2/locations/wellsites', { params: { customerId } });
  },
  deleteWell(wellId) {
    return axios.delete(`v2/jobs/wellsite/${wellId}`, {
      data: { wellId },
    });
  },
  addWell(data) {
    return axios.post('v2/locations/wellsites', data);
  },
  editWell(data, wellId) {
    return axios.patch(`v2/locations/wellsites`, { ...data, wellId: wellId });
  },
  getWellSitesDetails(wellSiteId) {
    return axios.get(`v2/wellsite/${wellSiteId}/details`, {});
  },
};
