// SET_ORDER_ID
export const setJobId = jobId => ({
  type: 'SET_JOB_ID',
  jobId,
});

// SET_LOCATION_ID
export const setLocationId = locationId => ({
  type: 'SET_LOCATION_ID',
  locationId,
});

// SET_DISTRICT_ID
export const setDistrictId = districtId => ({
  type: 'SET_DISTRICT_ID',
  districtId,
});

// SET_DRIVER_ID
export const setDriverId = driverId => ({
  type: 'SET_DRIVER_ID',
  driverId,
});

// SET_CARRIER_ID
export const setCarrierId = carrierId => ({
  type: 'SET_CARRIER_ID',
  carrierId,
});

// SET_JOB_TYPE
export const setJobType = jobType => ({
  type: 'SET_JOB_TYPE',
  jobType,
});

// SET_DRIVER_STATUS
export const setDriverType = driverType => ({
  type: 'SET_DRIVER_STATUS',
  driverType,
});

// SET_SAND_SITES
export const setSandSites = sandSites => ({
  type: 'SET_SAND_SITES',
  sandSites,
});

// SET_WELL_SITES
export const setWellSites = wellSites => ({
  type: 'SET_WELL_SITES',
  wellSites,
});

// SET_DRIVERS
export const setDrivers = drivers => ({
  type: 'SET_DRIVERS',
  drivers,
});

// SET_WATER_DISPOSAL
export const setWaterDisposal = waterDisposal => ({
  type: 'SET_WATER_DISPOSAL',
  waterDisposal,
});

// SET_WATER_PRODUCTION
export const setWaterProduction = waterProduction => ({
  type: 'SET_WATER_PRODUCTION',
  waterProduction,
});
