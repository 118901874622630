import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import actions from 'store/actions/ManageDatabase';
import AddEditModal from './AddEditModal';

const Reasons = ({
  init,
  reasons,
  isLoaded,
  remove,
  setModalAddValue,
  setModalEditValue,
}) => {
  const [showUpdate, turnShowUpdate] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const openUpdate = (status, value) => {
    switch (status) {
      case 'add':
        setModalAddValue(value || {});
        break;
      case 'edit':
        setModalEditValue(value);
        break;
    }
    turnShowUpdate(true);
  };

  return (
    <div>
      {showUpdate && (
        <AddEditModal
          {...{ showUpdate }}
          closeUpdate={() => turnShowUpdate(false)}
        />
      )}
      <div className="wrapper-content">
        <div className="row">
          <div>
            {isLoaded ? (
              <div className="ibox">
                <div className="ibox-content">
                  <Authorize
                    {...{
                      forbidden: ['CARRIER'],
                      abilityPermissions: [
                        {
                          resource: 'Database',
                          permissions: ['create']
                        }
                      ]
                    }}
                  >
                    <Button
                      onClick={() => openUpdate('add')}
                      inverse
                      className="float-end"
                      testSelector="database_reasons_add_btn">
                      <Icon className="icon--margin-right" icon="plus-square" />
                      Add Demurrage
                    </Button>
                  </Authorize>
                  {reasons.map(item => (
                    <div key={item.name}>
                      <div className="tree-node tree-node-content">
                        <span
                          className="tree-node-content-name"
                          style={{ color: '#ffffff' }}>
                          <Icon icon="angle-right" />
                          <strong>{item.name} </strong>
                        </span>
                        <Authorize {...{ forbidden: ['CARRIER'] }}>
                          <span>
                            <Authorize
                              {...{
                                abilityPermissions: [
                                  {
                                    resource: 'Database',
                                    permissions: ['update']
                                  }
                                ]
                              }}
                            >
                              <Button
                                onClick={() => openUpdate('edit', item)}
                                theme="small"
                                testSelector="database_reasons_edit_btn">
                                <Icon icon="pencil" />
                              </Button>
                            </Authorize>
                            <Authorize
                              {...{
                                abilityPermissions: [
                                  {
                                    resource: 'Database',
                                    permissions: ['delete']
                                  }
                                ]
                              }}
                            >
                              <Button
                                onClick={() => confirmAlert(remove, item.id)}
                                theme="small"
                                testSelector="database_reasons_delete_btn">
                                <Icon icon="trash" />
                              </Button>
                            </Authorize>
                            <Authorize
                              {...{
                                abilityPermissions: [
                                  {
                                    resource: 'Database',
                                    permissions: ['create']
                                  }
                                ]
                              }}
                            >
                              <Button
                                onClick={() => openUpdate('add', item)}
                                theme="small"
                                testSelector="database_reasons_add-children_btn">
                                <Icon icon="plus" />
                              </Button>
                            </Authorize>
                          </span>
                        </Authorize>
                      </div>

                      <ol>
                        {item.children.map(r => (
                          <div
                            key={`key${r.id}`}
                            className="tree-node tree-node-content">
                            <span className="tree-node-content-name">
                              <Icon icon="angle-right" />
                              <span>{r.name} </span>
                            </span>
                            <Authorize {...{ forbidden: ['CARRIER'] }}>
                              <span>
                                <Authorize
                                  {...{
                                    abilityPermissions: [
                                      {
                                        resource: 'Database',
                                        permissions: ['update']
                                      }
                                    ]
                                  }}
                                >
                                  <Button
                                    onClick={() => openUpdate('edit', r)}
                                    theme="small"
                                    testSelector="database_reasons_edit-children_btn">
                                    <Icon icon="pencil" />
                                  </Button>
                                </Authorize>
                                <Authorize
                                  {...{
                                    abilityPermissions: [
                                      {
                                        resource: 'Database',
                                        permissions: ['delete']
                                      }
                                    ]
                                  }}
                                >
                                  <Button
                                    onClick={() => confirmAlert(remove, r.id)}
                                    theme="small"
                                    testSelector="database_reasons_delete-children_btn">
                                    <Icon icon="trash" />
                                  </Button>
                                </Authorize>
                              </span>
                            </Authorize>
                          </div>
                        ))}
                      </ol>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="ibox ">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  reasons: state.database.reasons.reasons,
  isLoaded: state.database.reasons.isLoaded,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.getReasons()),
  remove: id => dispatch(actions.removeReason(id)),
  setModalAddValue: value => dispatch(actions.setModalAddValueReason(value)),
  setModalEditValue: value => dispatch(actions.setModalEditValueReason(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reasons);
