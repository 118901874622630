import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import {
  getDemurrageReasons,
  editDemurrageReason,
} from 'store/actions/Demurrage';
import { connect } from 'react-redux';
import { dropDownWithHierarchy } from 'utils/dropDownWithHierarchy';

const EditDemurrageReason = ({
  open,
  onClose,
  orderId,
  price,
  getDemurrageReasonsFunc,
  editDemurrageReasonFunc,
  sandSiteDemurrageReasonId,
  wellSiteDemurrageReasonId,
}) => {
  const [demurrageReasons, setDemurrageReasons] = useState([]);
  const [currentReason, setCurrentReason] = useState(null);

  useEffect(() => {
    if (open) {
      getDemurrageReasonsFunc().then(item => {
        const demurrageReasonsList = dropDownWithHierarchy(
          item.data.data.demurageReasons,
        ).map(reason => ({
          value: reason.id,
          label: `${reason.symbol || ''} ${reason.name}`,
          disabled: reason.isDisabled,
        }));
        setDemurrageReasons(demurrageReasonsList);
        setCurrentReason(price.demurrageReasonId);
      });
    }
  }, [open]);

  const handleSaveAndClose = (
    orderId,
    type,
    demurrageReasonId,
    sandSiteDemurrageReasonId,
    wellSiteDemurrageReasonId,
  ) => {
    editDemurrageReasonFunc(
      orderId,
      type,
      demurrageReasonId,
      sandSiteDemurrageReasonId,
      wellSiteDemurrageReasonId,
    );
    setCurrentReason(null);
    onClose();
  };

  const body = (
    <div className="contest-modal">
      <Select
        onChange={item => setCurrentReason(item.value)}
        options={demurrageReasons}
        placeholder="Choose Reason"
        required
        value={demurrageReasons.find(
          demurrageReason => demurrageReason.value === currentReason,
        )}
        testSelector="reconcile_order-details_edit-demrr_reason_reason_select"
      />
    </div>
  );

  const footer = (
    <>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="reconcile_order-details_edit-demurr_close_btn">
        Close
      </Button>
      <Button
        onClick={() =>
          handleSaveAndClose(
            orderId,
            price.type,
            currentReason,
            sandSiteDemurrageReasonId,
            wellSiteDemurrageReasonId,
          )
        }
        testSelector="reconcile_order-details_edit-demurr_save_btn">
        Save
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Reason for Demurrage"
      body={body}
      footer={footer}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  getDemurrageReasonsFunc: () => dispatch(getDemurrageReasons()),
  editDemurrageReasonFunc: (
    orderId,
    type,
    demurrageReason,
    sandSiteDemurrageReasonId,
    wellSiteDemurrageReasonId,
  ) =>
    dispatch(
      editDemurrageReason(
        orderId,
        type,
        demurrageReason,
        sandSiteDemurrageReasonId,
        wellSiteDemurrageReasonId,
      ),
    ),
});

export default connect(
  null,
  mapDispatchToProps,
)(EditDemurrageReason);
