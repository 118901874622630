import { useReducer, useState } from 'react';

/**
 * @typedef {{
 *   contest: boolean;
 *   history: boolean;
 *   uploadTicket: boolean;
 *   modify: boolean;
 *   invoice: boolean;
 * }} OrderModals
 */

const initialOrderModals = {
  contest: false,
  history: false,
  uploadTicket: false,
  modify: false,
  invoice: false,
};

/**
 *
 * @param {OrderModals} state
 * @returns {OrderModals}
 */
const OrderModalsReducer = (state, action) => {
  switch (action.type) {
    case 'toggle_modal':
      return {
        ...state,
        [action.name]: !state[action.name],
      };
    case 'clear_state':
      return initialOrderModals;
    default:
      return state;
  }
};

export const useOrderModals = () => {
  /** @type {[OrderModals, () => {}]} */
  const [data, dispatch] = useReducer(OrderModalsReducer, initialOrderModals);

  const toggleModal = name => () => dispatch({ type: 'toggle_modal', name });

  return {
    openModals: data,
    toggleModal,
  };
};

export const useDemurrageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();

  const toggleModal = (demurrageData) => {
      setData(demurrageData);  
      setShowModal(value => !value);
    };

  return {
    data,
    show: showModal,
    toggle: toggleModal,
  }
}
