import { connect } from 'react-redux';
import actions from './actions';
import Navigation from './component';
import layoutActions from '../../../store/modules/actions/layoutActions';

const mapStateToProps = state => ({
  user: state.lookUp.user,
  navIsHidden: state.layout.navIsHidden,
});

const mapDispatchToProps = dispatch => ({
  switchRole: role => dispatch(actions.switchRole(role)),
  toggleNavigation: () => dispatch(layoutActions.toggleNavigationShownOrHidden()),
  unLogin: () => dispatch(actions.unLogin()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
