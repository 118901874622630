import axios from 'axios';

export const customer = {
  get() { // order list init, resourceReducer, init add job, reconcile, invoices
    return axios.get('v2/customer');
  },
  details(id) { // needed for login / authenticate
    return axios.get(`v2/customer/${id || 0}/details`, {});
  },
  edit(info) { // only mocked
    return axios.put(`v2/customer/${info.id}`, info);
  },
  rateTable: {
    get(customerId) { // needed for select customer on add job
      return axios.get(`v2/customer/${customerId}/rate-tables`, {});
    },
  },
  demurrage: {
    get(customerId) { // needed for select customer on add job
      return axios.get(`v2/customer/${customerId}/demurrage-table`, {});
    },
  },
  billing: {
    payments: {
      get(id, status) { // needed for select customer on add job
        const numStatus = Number(status);
        const isValid = !Number.isNaN(numStatus);
        const queryItem = isValid ? numStatus : 1;
        return axios.get(
          `v2/billing/customer/${id}/payments?active=${queryItem}`,
        );
      },
    },
    profiles: {
      get(id, status) { // needed for select customer on add job
        const numStatus = Number(status);
        const isValid = !Number.isNaN(numStatus);
        const queryItem = isValid ? numStatus : 1;
        return axios.get(
          `v2/billing/customer/${id}/profiles?active=${queryItem}`,
        );
      },
    },
  },
  address: {
    edit(data) { // only mocked
      return axios.put(`v2/customer/${data.id}/address`, data);
    },
  },
  billingTerms: {
    edit(data) { // only mocked
      return axios.put(`v2/customer/${data.id}/billing-terms`, data);
    },
  },
  logo: {
    edit(data, id) { // only mocked
      return axios.post(`v2/customer/${id}/logo`, data, {
        headers: {
          dataType: 'json',
          processData: false,
          contentType: false,
        },
      });
    },
  },
  container: {
    get(data) { // lots of refs via get job details
      data.customerId = data.customerId || localStorage.getItem('customerId');
      return axios.get(`v2/customer/${data.customerId}/containers`, {
        params: {
          wellsiteId: data.wellsiteId,
          type: data.type,
          sandsiteId: data.sandsiteId,
          jobId: data.jobId,
        },
      });
    },
  },
};
