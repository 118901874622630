import moment from 'moment';
import { combineReducers } from '@reduxjs/toolkit';

const initInfo = {
  orders: [],
  totalPages: 1,
  isLoaded: false
};

const info = (state = initInfo, action) => {
  switch (action.type) {
    case 'ORDER_LIST_SAVE_ORDERS':
      return {
        ...state,
        totalPages: action.totalPages,
        orders: action.orders,
        isLoaded: true,
      };
    case 'ORDER_LIST_REFRESH_ORDER':
      return {
        ...state,
        orders: action.orders,
      };
    case 'ORDER_LIST_REFRESH_COUNT':
      return {
        ...state,
        count: action.count,
      };
    case 'ORDER_LIST_START_GET_ORDERS':
      return {
        ...state,
        isLoaded: false,
      };
    case 'ORDER_LIST_CLEAR':
      return initInfo;
    default:
      return state;
  }
};

const initFilter = {
    info: {
      customers: [],
      jobs: [],
      carriers: [],
      districts: [],
    },
    input: {
      order_id: '',
      stage: '',
      sort: '',
      truck: '',
      statuses: [],
      carrierIds: [],
      customerIds: [],
      districtIds: [],
      jobIds: [],
      startDate: moment().subtract(3, 'day'),
      endDate: moment(),
      origin: '',
      show: {},
    },
  };
  
  const filters = (state = initFilter, action) => {
    switch (action.type) {
      case 'ORDER_REQUEST_CANCEL_TOKEN':
        return {
          ...state,
          cancelToken: action.cancelToken
        }
      case 'ORDER_LIST_INIT_FILTER':
        return {
          ...state,
          input: {
            ...action.filters,
          },
        };
      case 'ORDER_LIST_SET_FILTER_VALUE':
        return {
          ...state,
          input: {
            ...state.input,
            [action.name]: action.value,
          },
        };
      case 'ORDER_LIST_INIT_FILTER_INFO':
        return {
          ...state,
          info: {
            ...state.info,
            [action.name]: action.value,
          },
        };
      case 'ORDER_LIST_CLEAR':
        return initFilter;
      default:
        return state;
    }
  };

  const initModal = {
    openModal: false,
    key: 1,
    order: {},
    logs: {
      isLoaded: false,
      comments: [],
      comment: '',
    },
    prices: [{}],
  };
  
  const modalOrderInfo = (state = initModal, action) => {
    switch (action.type) {
      case 'ORDER_LIST_TURN_MODAL_INFO':
        return {
          ...state,
          openModal: !state.openModal,
        };
      case 'ORDER_LIST_CHANGE_TAB_KEY':
        return {
          ...state,
          key: action.value,
        };
      case 'ORDER_LIST_INIT_ORDER_MODAL':
        return {
          ...state,
          order: action.order,
        };
      case 'ORDER_LIST_INIT_PRICES':
        return {
          ...state,
          prices: action.prices,
        };
      case 'ORDER_LIST_INIT_COMMENT':
        return {
          ...state,
          logs: {
            ...state.logs,
            isLoaded: true,
            comments: action.comments,
          },
        };
      case 'ORDER_LIST_SET_ORDER_COMMENT':
        return {
          ...state,
          logs: {
            ...state.logs,
            comment: action.comment,
          },
        };
      case 'ORDER_LIST_CLEAR_STATE':
        return initModal;
      default:
        return state;
    }
  };

export default combineReducers({
  filters,
  info,
  modalOrderInfo,
});
