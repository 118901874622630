const init = {
  input: {
    companyInfo: {
      name: '',
      email: '',
      districts: [],
      companyType: '',
      qbId: '',
      driverTimeout: '5',
      bcSync: false,
    },
    billingAddress: {
      city: '',
      address1: '',
      phoneNumber: '',
      email: '',
      state: '',
      address2: '',
      zip: '',
    },
    configuration: {
      // Temporary commented, it may to bring back later
      // sandco: 1,
      fuelSurchargeEnabled: false,
      useQRCode: false,
      // sandcoPrice: 0,
    },
  },
  step: 1,
  districts: [],
};

export default (state = { ...init }, action) => {
  switch (action.type) {
    case 'CUSTOMER_ADD_CUSTOMER_SET_COMPANY':
      return {
        ...state,
        input: {
          ...state.input,
          companyInfo: {
            ...state.input.companyInfo,
            [action.name]: action.value,
          },
        },
      };
    case 'CUSTOMER_ADD_CUSTOMER_SET_BILL':
      return {
        ...state,
        input: {
          ...state.input,
          billingAddress: {
            ...state.input.billingAddress,
            [action.name]: action.value,
          },
        },
      };
    case 'CUSTOMER_ADD_CUSTOMER_SET_CONFIG':
      return {
        ...state,
        input: {
          ...state.input,
          configuration: {
            ...state.input.configuration,
            [action.name]: action.value,
          },
        },
      };
    case 'CUSTOMER_ADD_CUSTOMER_SET_STEP':
      return {
        ...state,
        step: action.step,
      };
    case 'CUSTOMER_ADD_CUSTOMER_SAVE_INIT':
      return {
        ...state,
        districts: action.districts,
      };
    case 'CUSTOMER_ADD_CUSTOMER_CLEAR':
      return init;
    default:
      return state;
  }
};
