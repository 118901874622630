import UpdatePO from '../constants/UpdatePO';

const init = {
  editPO: {
    total: '',
    approved_mileage: '',
    travel_time_minutes: '',
    priority: '',
    po_reference: '',
    sand_vendor_id: '',
    sand_site_id: '',
    dailyLimit: '',
    enabled: '',
    carrier_id: '',
  },
  carriers: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case UpdatePO.VIEW_PO_UPDATE_MODAL_INIT:
      return {
        ...state,
        editPO: action.data,
        carriers: action.carriers,
      };
    case UpdatePO.VIEW_PO_UPDATE_MODAL_SET_VALUE:
      return {
        ...state,
        editPO: {
          ...state.editPO,
          [action.name]: action.value,
        },
      };
    case UpdatePO.VIEW_PO_UPDATE_MODAL_CLEAR:
      return init;
    default:
      return state;
  }
};
