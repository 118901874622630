export const locationPermission = {
  0: {
    title:
      'User has not granted authorization to use their location at any time',
    text: 'Pending',
    label: 'warning',
    icon: 'location_pending',
  },
  1: {
    title:
      'Most probably driver disabled location for automatize application in settings.',
    text: 'Restricted',
    label: 'danger',
    icon: 'location_rejected',
  },
  2: {
    title:
      'User has explicitly denied authorization for this application, or location services are disabled in Settings',
    text: 'Rejected',
    label: 'danger',
    icon: 'location_rejected',
  },
  3: {
    title: 'User has granted authorization to use their location at any time',
    text: 'Always',
    label: 'primary',
    icon: 'location_always',
  },
  4: {
    title:
      'User has limited authorization to use their location',
    text: 'When in use',
    label: 'warning',
    icon: 'location_tracking',
  },
};
