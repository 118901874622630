const auditSearch = {
    audits: [],
    error: '',
    isLoaded: false,
    isLoading: false,
    numPages: ''
}

export default (state = auditSearch, action) => {
    switch (action.type) {
        case 'AUDITS_NUM_PAGES_SUCCESS':
            return { 
                ...state,
                numPages: action.numPages,
            }
        case 'AUDITS_NUM_PAGES_FAILURE':
            return {
                ...state,
                error: action.error
            }
        case 'AUDITS_SEARCH_REQUEST':
            return {
                ...state, 
                isLoaded: false,
                isLoading: true,
            };
        case 'AUDITS_SEARCH_SUCCESS':
            return { 
                ...state,
                audits: action.audits,
                isLoaded: true,
                isLoading: false,
                error: null
            }
        case 'AUDITS_SEARCH_FAILURE': 
            return  {
                ...state,
                audits: [],
                isLoaded: true,
                isLoading: false,
                error: action.error
            }
        default: 
            return state    

    }
}
