export default {
  '0': {
    title: 'Call to Well',
    color: 'info',
  },
  '1': {
    title: 'Awaiting feedback from driver',
    color: 'info',
  },
  '2': {
    title: 'Driver is en route',
    color: 'info',
  },
  '3': {
    title: 'Driver has declined',
    color: 'warning',
  },
};
