import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Icon from 'components/shared/Icon';
import jobManagerAvatar from 'assets/jobManager_avatar.png';
import authService from 'services/auth/service';
import UserWidgetDropdown from './UserWidgetDropdown';
import { position } from './helpers';

const UserWidget = ({ user, logout }) => {
  const [dropdownIsVisible, turnDropdownIsVisible] = useState(false);
  const userLoggedIn = authService.getUser();

  const token = authService.getToken();

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('roles'));
    if (!roles) {
      logout();
    }
  }, [logout]);

  const handleClick = () => {
    turnDropdownIsVisible(!dropdownIsVisible);
  };

  return (
    <div
      className="user-widget"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}>
      <div className="user-widget__user-image-wrapper">
        <img
          className="user-widget__user-image"
          alt="user"
          src={
            user && user.user && user.user.profileUrl
              ? `${user.user.profileUrl}?token=${token}`
              : jobManagerAvatar
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = jobManagerAvatar;
          }}
        />
      </div>

      <div className="user-widget__user-info">
        <div className="user-widget__user-info--name">
          {user.user.firstName} {user.user.lastName}
        </div>
        <div className="user-widget__user-info--position">
          {position()} ({userLoggedIn.currentRole.roleTypeName})
        </div>

        <UserWidgetDropdown open={dropdownIsVisible} {...{ userLoggedIn }} />
        <Icon icon="down" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.lookUp.user,
});

export default connect(mapStateToProps)(UserWidget);
