import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from 'components/shared/Loader';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Link from 'components/common/Link';
import { convertTimeWithSeconds } from 'utils/convertTime';

const getDivertedInfo = orderId =>
  axios.get(`v2/order/${orderId}/diversion-info`);

const DivertedModal = ({ showDiverted, setDivertedStatus, orderId }) => {
  const [diversions, setDiversions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getDivertedInfo(orderId).then(data => {
      setDiversions(data.data.data.diversions);
      setLoading(false);
    });
  }, []);

  const footer = () => (
    <Button
      children="Close"
      testSelector="order-details_order-header_close_btn"
      onClick={() => setDivertedStatus(false)}
    />
  );

  const body = () =>
    (isLoading && <Loader />) ||
    diversions.map((diversion, key) => (
      <div key={key} className="diversion">
        <div className="row">
          <div className="col-md-6">
            <span className="diversion__title">Old Job:</span>{' '}
            <Link {...{ id: diversion.oldJobId, to: 'job-details' }}>
              #{diversion.oldJobId}
            </Link>{' '}
            | {diversion.oldJobName}
          </div>
          <div className="col-md-6">
            <span className="diversion__title">Diversion Date:</span>{' '}
            {convertTimeWithSeconds(diversion.diversionDate)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="diversion__title">New Job:</span>{' '}
            <Link {...{ id: diversion.newJobId, to: 'job-details' }}>
              #{diversion.newJobId}
            </Link>{' '}
            | {diversion.newJobName}
          </div>
          <div className="col-md-6">
            <span className="diversion__title">Description:</span>{' '}
            {diversion.description}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="diversion__title">Lat:</span> {diversion.lat}
          </div>
          <div className="col-md-6">
            <span className="diversion__title">Additional Mileage:</span>{' '}
            {diversion.additionalMileage}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="diversion__title">Long:</span> {diversion.lon}
          </div>
        </div>
      </div>
    ));

  return (
    <Modal
      open={showDiverted}
      onClose={() => setDivertedStatus(false)}
      title="Divert Info"
      body={body()}
      footer={footer()}
    />
  );
};

export default DivertedModal;
