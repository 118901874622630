import React from 'react';
import { connect } from 'react-redux';
import Modal, { ModalForm, FullWidth } from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import {
  setModalValue as setModalValueAction,
  changePercentage as changePercentageAction,
} from 'store/actions/Drivers';
import targetValue from 'utils/targetValue';

const ChangePercentage = ({
  showModalPercentage,
  closePercentage,
  percentage,
  changePercentage,
  setModalValue,
}) => {
  const header = <h3>Edit Pay Percentage</h3>;
  const body = (
    <>
      <ModalForm>
        <FullWidth>
          <Input
            type="number"
            onChange={e => setModalValue(e, 'pay_percentage')}
            value={percentage}
            label="Percentage"
            required
            testSelector="drivers_change-percentage_percentage_input"
          />
        </FullWidth>
      </ModalForm>
    </>
  );
  const footer = (
    <>
      <Button
        onClick={closePercentage}
        colour="white"
        testSelector="drivers-list_change-percentage_close_btn">
        Close
      </Button>
      <Button
        onClick={changePercentage}
        disabled={!percentage}
        testSelector="drivers-list_change-percentage_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalPercentage}
      onClose={closePercentage}
    />
  );
};

const mapStateToProps = state => ({
  percentage: state.driversNew.selectedDriver.pay_percentage,
});

const mapDispatchToProps = dispatch => ({
  setModalValue: (e, name) =>
    dispatch(setModalValueAction(targetValue(e), name)),
  changePercentage: e => {
    e.preventDefault();
    dispatch(changePercentageAction());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePercentage);
