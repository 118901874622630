import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import { useCarrierDetails, useCarrierTruckAnalytics } from 'api/v2/carriers';
import DriverAnalytics from './DriversAnalyticsModal';

const Stats = ({ carrierId }) => {
  const [showModal, setShowModal] = useState(false);

  const {
    data: analytics,
    isLoading: isLoadingAnalytics,
  } = useCarrierTruckAnalytics(carrierId);
  const { data: carrier, isLoading: isLoadingCarrier } = useCarrierDetails(
    carrierId,
  );

  const { freeTrucks, busyTrucks, freeTruckActiveWithin12Hours } =
    analytics || {};
  const { driversCount, totalLoads } = carrier?.stats || {};

  if (isLoadingAnalytics || isLoadingCarrier) {
    return <Loader />;
  }

  return (
    <Row>
      <Col lg={4} className="stats-block-first">
        <div className="widget blue-bg">
          <Row>
            <Col lg={4} md={4} sm={4} className="text-center">
              <Icon icon="truck fa-4x" />
            </Col>
            <Col lg={8} md={8} sm={8}>
              <span className="text-right">
                <h2 style={{ width: '100%' }}>{driversCount || 0}</h2>
                <span style={{ width: '100%' }}>Total drivers</span>
              </span>
            </Col>
          </Row>
        </div>
      </Col>
      <Col lg={4}>
        <div className="widget blue-bg">
          <Row>
            <Col lg={4} md={4} sm={4} className="text-center">
              <Icon icon="cubes fa-4x" />
            </Col>
            <Col lg={8} md={8} sm={8}>
              <span className="text-right">
                <h2 style={{ width: '100%' }}>{totalLoads || 0}</h2>
                <span style={{ width: '100%' }}>Total loads</span>
              </span>
            </Col>
          </Row>
        </div>
      </Col>
      <Col
        lg={4}
        className="stats-block-last"
        onClick={() => setShowModal(true)}>
        <div className="widget blue-bg" style={{ height: 95 }}>
          <Row>
            <Col lg={4} md={4} sm={4} className="text-center">
              <h2 style={{ width: '100%' }}>{freeTrucks?.length || 0}</h2>
              <span style={{ width: '100%' }}>Free</span>
            </Col>
            <Col lg={4} md={4} sm={4} className="text-center">
              <h2 style={{ width: '100%' }}>{busyTrucks?.length || 0}</h2>
              <span style={{ width: '100%' }}>Busy</span>
            </Col>
            <Col lg={4} md={4} sm={4} className="text-center">
              <h2 style={{ width: '100%' }}>
                {freeTruckActiveWithin12Hours?.length || 0}
              </h2>
              <span style={{ width: '100%' }}>12 hrs</span>
            </Col>
          </Row>
        </div>
      </Col>

      <DriverAnalytics
        carrierId={carrierId}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Row>
  );
};
export default Stats;
