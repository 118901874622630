import React from 'react';

const CustomTaskListHeader = ({
  // headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
}) => (
  <div
    className="task-header"
    style={{
      fontFamily,
      fontSize,
    }}>
    <div className="header-row">
    <div
        className="header-item"
        style={{
          minWidth: rowWidth / 2,
        }}>
        District
      </div>
      <div
        className="header-item"
        style={{
          minWidth: rowWidth,
        }}>
        Job Name
      </div>
    </div>
  </div>
);
export default CustomTaskListHeader;
