export const UPDATE_ROLE_ACTIONS = {
  EDIT_ROLE: 'EDIT_ROLE',
  UPDATE_TITLE: 'UPDATE_TITLE',
  UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
};

export const updateRoleDetailsReducer = (state, action) => {
  const { type, data } = action;

  switch (type) {
    case UPDATE_ROLE_ACTIONS.EDIT_ROLE:
      return { ...data };
    case UPDATE_ROLE_ACTIONS.UPDATE_TITLE:
      return {
        ...state,
        role: data,
      };
    case UPDATE_ROLE_ACTIONS.UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: data,
      };
    default:
      return { ...state };
  }
};
