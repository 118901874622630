import moment from 'moment';

export const formatOrderSearchParams = input => {
  const {
    jobId,
    truck,
    statuses,
    stage,
    sort,
    order_id: orderId,
    districtIds,
    carrierIds,
    customerIds,
    jobIds,
    startDate,
    endDate,
    origin,
  } = input || {};

  return {
    jobId,
    truck,
    statuses: statuses.map(item => item.value),
    stage,
    sortBy: sort,
    orderIdLike: orderId,
    carrierIds: (carrierIds || []).map(item => item.value),
    customerIds: (customerIds || []).map(item => item.value),
    districtIds: (districtIds || []).map(item => item.value),
    jobIds: (jobIds || []).map(item => item.value),
    startDate: startDate || '',
    endDate: endDate
      ? moment(endDate)
          .add(1, 'day')
          .subtract(1, 'seconds')
      : '',
    origins: (origin || []).map(item => item.value),
  };
};
