import React, { useState } from 'react';
import { connect } from 'react-redux';
import { authorize } from 'components/common/Authorize';
import Button from 'components/shared/Button';
import ChangeDistrict from './ChangeDistrict';
import DistrictBox from './DistrictBox';

const Districts = ({ districts }) => {
  const [showModalAddDistrict, turnModalAddDistrict] = useState(false);

  return (
    <div>
      {showModalAddDistrict && (
        <ChangeDistrict
          showModalAddDistrict={showModalAddDistrict}
          closeModalAddDistrict={() => {
            turnModalAddDistrict(false);
          }}
        />
      )}
      {(authorize({
        access: ['JOB_MANAGER'],
        abilityPermissions: [
          {
            resource: 'Drivers',
            permissions: ['update'],
          },
        ],
      }) ||
        authorize({
          access: ['CARRIER'],
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        })) && (
        <Button
          onClick={() => turnModalAddDistrict(true)}
          testSelector="driver-details_change-district_btn"
          className="float-end">
          Change districts
        </Button>
      )}
      {(districts.length && (
        <div className="row districts-box-container">
          {districts.map(district => (
            <DistrictBox {...district} key={`dist-${district.id}`} />
          ))}
        </div>
      )) || <h4>You don't have districts yet</h4>}
    </div>
  );
};

const mapStateToProps = state => ({
  districts: state.driverProfile.driver.driverInf.districts,
  driver: state.driverProfile.driver.driverInf.driver,
});
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Districts);
