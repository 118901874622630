import React from 'react';

const Drag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon--fill"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <path
      fillRule="nonzero"
      d="M19.819 11.593l-2.284-2.284a.548.548 0 0 0-.402-.17.549.549 0 0 0-.401.17.55.55 0 0 0-.17.401v1.142h-3.426V7.426h1.142a.549.549 0 0 0 .402-.17.549.549 0 0 0 .17-.4.549.549 0 0 0-.17-.402L12.396 4.17a.548.548 0 0 0-.402-.17.549.549 0 0 0-.401.17L9.309 6.453a.549.549 0 0 0-.17.401c0 .155.057.289.17.402.113.113.246.17.401.17h1.142v3.425H7.426V9.71a.549.549 0 0 0-.17-.401.549.549 0 0 0-.401-.17.549.549 0 0 0-.401.17L4.17 11.593a.549.549 0 0 0-.17.401c0 .155.057.289.17.402l2.284 2.283a.55.55 0 0 0 .401.17.55.55 0 0 0 .402-.17.548.548 0 0 0 .17-.401v-1.142h3.425v3.426H9.71a.55.55 0 0 0-.401.17.549.549 0 0 0-.17.401.55.55 0 0 0 .17.402l2.284 2.284a.55.55 0 0 0 .401.169.549.549 0 0 0 .402-.17l2.284-2.283a.549.549 0 0 0 .17-.402.548.548 0 0 0-.17-.402.549.549 0 0 0-.402-.169h-1.142v-3.426h3.426v1.142c0 .155.057.289.17.402a.55.55 0 0 0 .401.17.55.55 0 0 0 .402-.17l2.284-2.284a.549.549 0 0 0 .17-.402.548.548 0 0 0-.17-.401z"
    />
  </svg>
);

export default Drag;
