import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  invoicesRequested,
  invoicesActionRequested,
  downloadMultiBulkInvoices,
  jobsRequested
} from 'store/actions/Invoices';
import { customersRequested } from 'store/actions/Customers';
import Invoices from './Invoices';

export const InvoicesContext = React.createContext();

const mapStateToProps = ({ invoices, customers }) => ({
  invoices: invoices.collection,
  customers: customers.collection,
  jobs: invoices.jobs.jobs,
  customersIsLoading: customers.apiIsLoading,
  isLoading: invoices.apiIsLoading,
  actionIsLoading: invoices.action.apiIsLoading,
});

const mapDispatchToProps = dispatch => ({
  getInvoices: params => dispatch(invoicesRequested(params)),
  getJobs: () => dispatch(jobsRequested()),
  getCustomers: () => dispatch(customersRequested()),
  markAsPaid: invoiceIds =>
    dispatch(invoicesActionRequested('PAID', invoiceIds)),
  markAsSent: invoiceIds =>
    dispatch(invoicesActionRequested('SENT', invoiceIds)),
  deleteInvoices: invoiceIds =>
    dispatch(invoicesActionRequested('DELETE', invoiceIds)),
  downloadMultipleBulkInvoices: (invoiceIds, role, carrierId) =>
    dispatch(downloadMultiBulkInvoices(invoiceIds, role, carrierId)),
});

const InvoicesWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invoices);

const InvoicesWithContext = () => {
  const [filters, setFilters] = useState([]);
  const [ordering, setOrdering] = useState({});
  const [pagination, setPagination] = useState({});

  return (
    <InvoicesContext.Provider
      value={{
        filters,
        setFilters,
        ordering,
        setOrdering,
        pagination,
        setPagination,
      }}>
      <InvoicesWithRedux />
    </InvoicesContext.Provider>
  );
};

export default InvoicesWithContext;
