import React, { useState } from 'react';
import ToolTip from 'components/shared/ToolTip';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import DropdownButton from 'components/shared/DropdownButton';
import PreviewInvoice from './PreviewInvoice';

const InvoiceBreakdownActionButtons = ({
  handleCarrierId,
  currentInvoice = null,
  getInvoicePreview,
  downloadOptions,
  carrierId,
  groupedOrders,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  return (
    <>
      <span className="action-buttons">
        <ToolTip title="Preview">
          <Button
            theme="small"
            className="button--small--square"
            onClick={() => setPreviewOpen(!previewOpen)}
            disabled={currentInvoice.is_deleted === 1}
            testSelector="invoice_details_actions_preview_btn"
          >
            <Icon icon="preview" />
          </Button>
        </ToolTip>
        <ToolTip title="Email">
          <Button
            theme="small"
            className="button--small--square"
            testSelector="invoice_details_actions_email_btn"
            onClick={() =>
              handleCarrierId(
                groupedOrders ? groupedOrders.carrierId : null,
                'email',
              )
            }
            disabled={currentInvoice.is_deleted === 1}>
            <Icon icon="email" />
          </Button>
        </ToolTip>
        <ToolTip title="Link">
          <Button
            theme="small"
            className="button--small--square"
            testSelector="invoice_details_actions_copy_btn"
            onClick={() =>
              handleCarrierId(
                groupedOrders ? groupedOrders.carrierId : carrierId,
                'copy',
              )
            }
          >
            <Icon icon="link" />
          </Button>
        </ToolTip>
        <ToolTip title="Download">
          <DropdownButton
            onClick={() => {}}
            icon="download"
            theme="small"
            className="button--small--square"
            testSelector="invoice_details_actions_download_dropdown-btn"
            options={downloadOptions}
            isDisabled={currentInvoice.is_deleted === 1}
          />
        </ToolTip>
      </span>
      {currentInvoice.is_deleted !== 1 && (
        <PreviewInvoice
          currentInvoice={currentInvoice}
          getInvoicePreview={getInvoicePreview}
          groupedOrders={groupedOrders}
          carrierId={carrierId}
          open={previewOpen}
        />
      )}
    </>
  );
};

export default InvoiceBreakdownActionButtons;
