import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/ResetPassword';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';

const ResetPassword = ({ init, resetPass, setInputValue, setPass }) => {
  const location = useLocation();
  const paramsString = location.search;
  const params = new URLSearchParams(paramsString);
  const { pass, confirmPass } = resetPass;

  useEffect(() => {
    init(decodeURIComponent(params.get('email')), params.get('key'));
  }, []);

  return (
    <div className="loginColumns animated fadeInDown">
      <div className="row text-center">
        <div
          className="login-page-container"
          style={{ width: 446, margin: '0 auto' }}>
          <div
            className="ibox-content ibox-contet-login"
            style={{ borderStyle: 'none', width: 443 }}>
            <h2>
              <strong>Set new Password </strong>
            </h2>
            <form onSubmit={setPass}>
              <Row>
                <div className="form-group has-feedback col-md-12">
                  <Input
                    type="password"
                    label="Password"
                    onChange={e => setInputValue(e, 'pass')}
                    value={pass}
                    required
                    testSelector="resset-psw_password_input"
                  />
                </div>
                <div className="form-group has-feedback col-md-12">
                  <Input
                    type="password"
                    label="Confirm Password"
                    onChange={e => setInputValue(e, 'confirmPass')}
                    value={confirmPass}
                    required
                    testSelector="resset-psw_confirm-password_input"
                  />
                </div>
                <Button type="submit" testSelector="resset-psw-modal_save_btn">
                  Save
                </Button>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  resetPass: state.resetPass,
});

const mapDispatchToProps = dispatch => ({
  init: (email, key) => dispatch(actions.init(email, key)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  setPass: e => {
    e.preventDefault();
    dispatch(actions.setPass());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
