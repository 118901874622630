import { combineReducers } from '@reduxjs/toolkit';

const initialStateWellSiteDetails = {
  isLoadedWellSiteDetails: false,
  wellSiteInf: [],
};
const wellSite = (state = initialStateWellSiteDetails, action) => {
  switch (action.type) {
    case 'GET_SAND_WELL_DETAILS_REQUEST':
      return Object.assign({}, state, {});
    case 'GET_SAND_WELL_DETAILS_SUCCESS':
      return Object.assign({}, state, {
        isLoadedWellSiteDetails: true,
        wellSiteInf: action.wellSiteInf,
      });
    case 'GET_SAND_WELL_DETAILS_FAILURE':
      return {};
    default:
      return state;
  }
};

export const wellSiteDetails = combineReducers({
  wellSite,
});
