import React from 'react';
import { Modal } from 'react-bootstrap';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
// import Select from 'components/shared/Select';

const Config = ({
  setStep,
  addSubmit,
  setConfigValue,
  addCustomer,
  closeAdd,
}) => {
  const {
    input: {
      configuration: { sandco, fuelSurchargeEnabled, useQRCode, sandcoPrice },
    },
  } = addCustomer;

  return (
    <form
      onSubmit={e => {
        addSubmit(e);
      }}
      className="wizard-big wizard">
      <Modal.Body className="form-group">
        <div className="step3">
          <h1 id="form-h-2" className="title">
            Configuration
          </h1>
          <fieldset id="form-p-2" role="tabpanel" className="body">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                {/* // Temporary commented, it may to bring back later */}
                {/* <div className="form-group">
                  <label>Need Job Coordinator?</label>
                  <div className="form-group">
                    <Select
                      value={booleanList.find(
                        item => item.value === parseInt(sandco),
                      )}
                      onChange={item => setConfigValue(item.value, 'sandco')}
                      options={booleanList}
                      required
                      testSelector="admin-customers_config_sand-co_select"
                    />
                  </div>
                </div> */}
                <div className="form-group">
                  <div className="row" style={{ margin: 0 }}>
                    <Input
                      type="checkbox"
                      testSelector="admin-customers_config_fuel-surcharge-enabled_input"
                      label="Fuel surcharge enabled"
                      onChange={e => setConfigValue(e, 'fuelSurchargeEnabled')}
                      isChecked={fuelSurchargeEnabled}
                      noMargin="true"
                    />
                  </div>
                </div>
                {/* <div className="form-group">
                  <div className="row" style={{ margin: 0 }}>
                    <Input
                      type="checkbox"
                      testSelector="admin-customers_config_use-qr-code_input"
                      label="Use QR Code"
                      onChange={e => setConfigValue(e, 'useQRCode')}
                      isChecked={useQRCode}
                      noMargin="true"
                    />
                  </div>
                </div> */}
              </div>
              {/* <div className="col-lg-6 col-md-6">
                {Boolean(Number(sandco)) && (
                  <>
                    <label>Job coordinator price</label>
                    <div className="form-group">
                      <Input
                        type="number"
                        onChange={e => setConfigValue(e, 'sandcoPrice')}
                        value={sandcoPrice}
                        label="Fill in a price"
                        required
                        testSelector="admin-customers_config_sandco-price_input"
                      />
                    </div>
                  </>
                )}
              </div> */}
            </div>
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeAdd} colour="white" children="Close" testSelector="admin-customers_config_close_btn" />
        <Button onClick={() => setStep(3)} children="Previous" testSelector="admin-customers_config_prev_btn" />
        <Button type="submit" children="Next" testSelector="admin-customers_config_next_btn" />
      </Modal.Footer>
    </form>
  );
};

export default Config;
