import React from 'react';

const LoggedOut = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
   <defs>
        <linearGradient id="a" x1="50%" x2="50%" y1=".808%" y2="98.186%">
            <stop offset="0%" stopColor="#91949D"/>
            <stop offset="100%" stopColor="#5C5F65"/>
        </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="url(#a)" fillRule="nonzero"/>
        <g fill="#FFF">
            <path d="M9.71 14.567a.232.232 0 0 1-.018-.12c.006-.033-.015-.06-.062-.08-.048-.021-.072-.038-.072-.05 0-.012-.034-.02-.102-.026l-.103-.01H6.57c-.392 0-.728-.139-1.008-.419-.28-.28-.419-.615-.419-1.008V6.573c0-.393.14-.729.42-1.008.279-.28.615-.42 1.008-.42h2.855a.27.27 0 0 0 .182-.057.296.296 0 0 0 .09-.175 1.501 1.501 0 0 0 .022-.446 4.103 4.103 0 0 1-.009-.178.275.275 0 0 0-.085-.2.275.275 0 0 0-.2-.085H6.569c-.707 0-1.313.25-1.815.753A2.475 2.475 0 0 0 4 6.573v6.281c0 .708.251 1.313.754 1.816a2.474 2.474 0 0 0 1.816.754h2.855a.27.27 0 0 0 .183-.058.296.296 0 0 0 .089-.174 1.504 1.504 0 0 0 .022-.446 4.01 4.01 0 0 1-.009-.179z"/>
            <path d="M17.82 9.312l-4.853-4.854a.55.55 0 0 0-.402-.17.55.55 0 0 0-.401.17.549.549 0 0 0-.17.402v2.57H7.997a.55.55 0 0 0-.401.17.549.549 0 0 0-.17.4v3.427c0 .154.056.288.17.401a.55.55 0 0 0 .401.17h3.997v2.57c0 .154.057.288.17.4.113.114.247.17.401.17a.549.549 0 0 0 .402-.17l4.853-4.853a.549.549 0 0 0 .17-.401.55.55 0 0 0-.17-.402z"/>
        </g>
    </g>
  </svg>
);

export default LoggedOut;