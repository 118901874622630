import demurrage from 'services/demurrage/service';
import { district } from 'services/district/service';
import { sandType } from 'services/sandType/service';
import { vendor } from 'services/vendor/service';
import { certificates } from 'services/certificate/service';
import { equipmentTypes } from 'services/equipmentTypes/service';
import { commodityTypes } from 'services/commodities/service';
import { tags } from 'services/tags/service';
import { lookUpActions } from 'store/actions/LookUp';
import swal from 'bootstrap-sweetalert';
import { document } from 'services/document/service';
import { aws } from 'services/aws/service';


// Reasons actions

const getReasons = () => dispatch => {
  const save = reasons => ({ type: 'DATABASE_SAVE_REASONS', reasons });
  demurrage
    .get()
    .then(msg => dispatch(save(msg.data.data.demurageReasons)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setModalAddValueReason = value => ({
  type: 'DATABASE_REASONS_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueReason = value => ({
  type: 'DATABASE_REASONS_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

const removeReason = id => dispatch => {
  demurrage
    .remove({ reasonId: id })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getReasons());
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

// Add/Edit reason actions
const addReason = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      reasons: {
        updateModal: { parentReason, name, description },
      },
    },
  } = state;
  const data = {
    description,
    reason: name,
  };
  if (parentReason) {
    data.parentReason = parentReason;
  }
  demurrage
    .add(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getReasons());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const editReason = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      reasons: {
        updateModal: { name, reasonId, description },
      },
    },
  } = state;
  const data = {
    name,
    reasonId,
    description,
  };
  demurrage
    .edit(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getReasons());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValueReason = (value, name) => ({
  type: 'DATABASE_REASONS_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearModalReason = () => ({
  type: 'DATABASE_REASONS_CLEAR_MODAL_UPDATE',
});

// Districts actions
const getDistricts = () => dispatch => {
  const save = districts => ({
    type: 'DATABASE_SAVE_DISTRICTS',
    districts,
  });
  district
    .get()
    .then(msg => {
      dispatch(save(msg.data.data.district));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setModalAddValueDistrict = value => ({
  type: 'DATABASE_DISTRICTS_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueDistrict = value => ({
  type: 'DATABASE_DISTRICTS_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

// Add/Edit district actions
const setInputValueDistrict = (value, name) => ({
  type: 'DATABASE_DISTRICTS_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearDistrictModal = () => ({
  type: 'DATABASE_DISTRICTS_CLEAR_MODAL_UPDATE',
});

// commodities actions
const setModalAddValueCommodity = value => ({
  type: 'DATABASE_COMMODITIES_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueCommodity = value => ({
  type: 'DATABASE_COMMODITIES_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

const removeCommodity = id => dispatch => {
  sandType
    .remove({ sandTypeId: id })
    .then(response => {
      dispatch(lookUpActions.deleteL(id, 'sandTypes'));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// add/edit commodity actions
const editCommodity = closeModal => (dispatch, getState) => {
  const {
    database: {
      commodities: {
        updateModal: {
          name,
          type,
          id,
          equipmentTypes,
          tags,
          documents,
          driverDisplay,
        },
      },
    },
  } = getState();
  const list = equipmentTypes.map(s => s.id || s);
  const tagslist = tags.map(t => t.id || t);

  sandType
    .edit({
      name,
      commodityType: type,
      id,
      equipmentTypes: list,
      tags: tagslist,
      documents,
      driverDisplay,
    })
    .then(response => {
      const data = {
        id,
        name,
        commodityType: Number(type),
        equipment: equipmentTypes,
        tags,
        documents,
        driverDisplay,
      };
      dispatch(lookUpActions.edit(data, id, 'sandTypes'));
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const addCommodity = closeModal => (dispatch, getState) => {
  const {
    database: {
      commodities: {
        updateModal: {
          name,
          type,
          equipmentTypes,
          tags,
          file,
          documents,
          driverDisplay,
          uom
        },
      },
    },
  } = getState();
  const list = equipmentTypes.map(s => s.id || s);
  const tagslist = tags.map(t => t.id || t);

  if (file) {
    document
      .getSignedUrl()
      .then(response => {
        const url = response.data.data.uploadUrl;
        const documentId = response.data.data.id;

        aws.uploadToS3(file, url).then(s3Response => {
          document.finishUpload(documentId).then(docResponse => {
            sandType
              .add({
                name,
                commodityType: type,
                equipmentTypes: list,
                tags: tagslist,
                documentId,
                filename: file.name,
                driverDisplay,
                uom,
              })
              .then(res => {
                const data = {
                  id: res.data.data.id,
                  name,
                  commodityType: Number(type),
                  equipment: list,
                  tags,
                  documents,
                  driverDisplay,
                };
                dispatch(lookUpActions.add(data, 'sandTypes'));
                swal('Success', '', 'success');
                closeModal();
              })
              .catch(error => {
                swal(error.res.data.message, '', 'error');
              });
          });
        });
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  } else {
    sandType
      .add({
        name,
        commodityType: type,
        equipmentTypes: list,
        tags,
        documents,
        driverDisplay,
        uom,
      })
      .then(response => {
        const data = {
          id: response.data.data.id,
          name,
          commodityType: Number(type),
          equipment: list,
          tags,
          documents,
          driverDisplay,
        };
        dispatch(lookUpActions.add(data, 'sandTypes'));
        swal('Success', '', 'success');
        closeModal();
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
};

const addDocumentCommodity = file => (dispatch, getState) => {
  const {
    database: {
      commodities: {
        updateModal: { documents },
      },
    },
  } = getState();

  if (file) {
    document
      .getSignedUrl()
      .then(response => {
        const url = response.data.data.uploadUrl;
        const documentId = response.data.data.id;

        aws.uploadToS3(file, url, file.type).then(s3Response => {
          document.finishUpload(documentId).then(docResponse => {
            const data = [...documents];
            data.push({ docId: documentId.toString(), docName: file.name });
            dispatch(setInputValueCommodity(data, 'documents'));
          });
        });
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
};

const deleteDocumentCommodity = docId => (dispatch, getState) => {
  const {
    database: {
      commodities: {
        updateModal: { documents },
      },
    },
  } = getState();
  const data = documents.filter(d => d.docId !== docId);
  dispatch(setInputValueCommodity(data, 'documents'));
};

const setInputValueCommodity = (value, name) => ({
  type: 'DATABASE_COMMODITIES_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearCommodityModal = () => ({
  type: 'DATABASE_COMMODITIES_CLEAR_MODAL_UPDATE',
});

// commodities Reference actions

const setInputValueCommodityReference = (value, name) => ({
  type: 'DATABASE_COMMODITIES_REFERENCE_SET_MODAL_VALUE',
  value,
  name,
});

const clearCommodityReference = () => ({
  type: 'DATABASE_COMMODITIES_CLEAR_MODAL_UPDATE',
});

const setModalEditValueCommodityReference = value => ({
  type: 'DATABASE_COMMODITIES_REFERENCE_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

const initCommodityReferenceModal = item => (dispatch, getState) => {
  if (item && item.id && item.name) {
    dispatch(setInputValueCommodityReference(item.id, 'id'));
    dispatch(setInputValueCommodityReference(item.name, 'name'));

    commodityTypes
      .getReference(item.id)
      .then(response => {
        dispatch(
          setInputValueCommodityReference(response.data.data.extRef, 'extRef'),
        );
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
};

const addCommodityReference = closeModal => (dispatch, getState) => {
  const {
    database: {
      commoditiesReference: {
        updateModal: { extRef },
      },
    },
  } = getState();

  commodityTypes
    .addReference({ extRef })
    .then(response => {
      swal('Success', '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.data.message, '', 'error');
    });
};

const editCommodityReference = closeModal => (dispatch, getState) => {
  const {
    database: {
      commoditiesReference: {
        updateModal: { extRef, id },
      },
    },
  } = getState();

  commodityTypes
    .addReference({ commodityId: id, extRef })
    .then(response => {
      const data = {
        commodityId: id,
        extRef,
      };
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.data.message, '', 'error');
    });
};

// vendors actions
const getVendors = () => dispatch => {
  const save = vendors => ({
    type: 'DATABASE_SAVE_VENDOR',
    vendors,
  });
  vendor
    .get()
    .then(msg => dispatch(save(msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getVendorTypes = () => dispatch => {
  const save = types => ({
    type: 'DATABASE_SAVE_VENDOR_TYPES',
    types,
  });
  vendor
    .getVendorTypes()
    .then(msg => dispatch(save(msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setModalAddValueVendor = value => ({
  type: 'DATABASE_VENDOR_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueVendor = value => ({
  type: 'DATABASE_VENDOR_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

const removeVendor = id => dispatch => {
  vendor
    .remove(id)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getVendors());
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const unDeleteVendor = id => dispatch => {
  vendor
    .unDeleteVendor(id)
    .then(response => {
      swal('Success!', '', 'success');
      dispatch(getVendors());
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setFilterValue = (value, name) => ({
  type: 'DATABASE_VENDOR_SET_FILTER_VALUE',
  value,
  name,
});

// add/edit vendor actions
const addVendor = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      vendors: {
        updateModal: { name, types },
      },
    },
  } = state;
  const data = {
    name,
    types: types.map(i => i.value),
  };
  vendor
    .add(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getVendors());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const editVendor = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      vendors: {
        updateModal: { name, id, types },
      },
    },
  } = state;
  const data = {
    name,
    id,
    types: types.map(i => i.value || i.id),
  };
  vendor
    .edit(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getVendors());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValueVendor = (value, name) => ({
  type: 'DATABASE_VENDOR_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearVendorModal = () => ({
  type: 'DATABASE_VENDOR_CLEAR_MODAL_UPDATE',
});

// certificates actions
const getCertificates = () => dispatch => {
  const save = certificates => ({
    type: 'DATABASE_SAVE_CERTIFICATES',
    certificates,
  });
  certificates
    .get()
    .then(msg => dispatch(save(msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setModalAddValueCertificate = value => ({
  type: 'DATABASE_CERTIFICATES_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueCertificate = value => ({
  type: 'DATABASE_CERTIFICATES_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

const removeCertificate = id => dispatch => {
  certificates
    .remove({ certificateId: id })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getCertificates());
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

// add/edit certificate actions
const addCertificate = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      certificates: {
        updateModal: { name },
      },
    },
  } = state;
  certificates
    .add({ name })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getCertificates());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const editCertificate = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      certificates: {
        updateModal: { name, id },
      },
    },
  } = state;
  certificates
    .edit({ name, id })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getCertificates());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValueCertificate = (value, name) => ({
  type: 'DATABASE_CERTIFICATES_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearCertificateModal = () => ({
  type: 'DATABASE_CERTIFICATES_CLEAR_MODAL_UPDATE',
});

const getCommodityTypes = () => (dispatch, getState) => {
  const save = commodityTypes => ({
    type: 'DATABASE_SAVE_COMMODITY_TYPES',
    commodityTypes,
  });
  const saveOptions = options => ({
    type: 'DATABASE_SAVE_COMMODITY_TYPES_SELECT',
    options,
  });
  return commodityTypes
    .get()
    .then(msg => {
      const cTypes = msg.data.data.commodityTypes;
      if (Array.isArray(cTypes) && cTypes.length) {
        const selectValues = cTypes.map(c => ({
          value: c.id,
          label: c.name,
        }));
        dispatch(saveOptions(selectValues));
        dispatch(save(cTypes));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getTags = () => (dispatch, getState) => {
  const save = tags => ({
    type: 'DATABASE_SAVE_TAGS',
    tags,
  });
  const saveOptions = options => ({
    type: 'DATABASE_SAVE_TAGS_SELECT',
    options,
  });
  return tags
    .getByType(1)
    .then(msg => {
      const tagList = msg.data.data;
      if (Array.isArray(tagList) && tagList.length) {
        const selectValues = tagList.map(t => ({
          value: t.id,
          label: t.name,
        }));
        dispatch(saveOptions(selectValues));
        dispatch(save(tagList));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

// equipmentTypes actions
const getEquipmentTypes = () => dispatch => {
  const save = equipmentTypes => ({
    type: 'DATABASE_SAVE_EQUIPMENT_TYPES',
    equipmentTypes,
  });
  equipmentTypes
    .get()
    .then(msg => dispatch(save(msg.data.data.equipmentTypes)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setModalAddValueEquipmentTypes = value => ({
  type: 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_ADD_VALUE',
  status: 'add',
  value,
});

const setModalEditValueEquipmentTypes = value => ({
  type: 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_EDIT_VALUE',
  status: 'edit',
  value,
});

// add/edit EquipmentTypes actions
const addEquipmentTypes = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      equipmentTypes: {
        updateModal: { name },
      },
    },
  } = state;
  equipmentTypes
    .add({ name })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getEquipmentTypes());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const editEquipmentTypes = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    database: {
      equipmentTypes: {
        updateModal: { name, id },
      },
    },
  } = state;
  equipmentTypes
    .edit({ name, id })
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getEquipmentTypes());
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValueEquipmentTypes = (value, name) => ({
  type: 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearEquipmentTypesModal = () => ({
  type: 'DATABASE_EQUIPMENT_TYPES_CLEAR_MODAL_UPDATE',
});

const actions = {
  // reasons actions
  getReasons,
  removeReason,
  setModalAddValueReason,
  setModalEditValueReason,
  // reasons modal
  addReason,
  editReason,
  setInputValueReason,
  clearModalReason,
  // districts actions
  getDistricts,
  setModalAddValueDistrict,
  setModalEditValueDistrict,
  // reasons modal
  setInputValueDistrict,
  clearDistrictModal,
  // commodities actions
  removeCommodity,
  setModalAddValueCommodity,
  setModalEditValueCommodity,
  // commodity modal
  addCommodity,
  editCommodity,
  setInputValueCommodity,
  clearCommodityModal,
  deleteDocumentCommodity,
  addDocumentCommodity,
  // commodity external reference id modal
  initCommodityReferenceModal,
  setInputValueCommodityReference,
  clearCommodityReference,
  setModalEditValueCommodityReference,
  addCommodityReference,
  editCommodityReference,
  // vendors actions
  getVendors,
  getVendorTypes,
  removeVendor,
  unDeleteVendor,
  setModalAddValueVendor,
  setModalEditValueVendor,
  setFilterValue,
  // vendors modal
  addVendor,
  editVendor,
  setInputValueVendor,
  clearVendorModal,
  // certificates actions
  getCertificates,
  removeCertificate,
  setModalAddValueCertificate,
  setModalEditValueCertificate,
  // certificates modal
  addCertificate,
  editCertificate,
  setInputValueCertificate,
  clearCertificateModal,
  // equipment types actions
  getEquipmentTypes,
  setModalAddValueEquipmentTypes,
  setModalEditValueEquipmentTypes,
  // Commodity types
  getCommodityTypes,
  // tags
  getTags,
  // EquipmentTypes modal
  addEquipmentTypes,
  editEquipmentTypes,
  setInputValueEquipmentTypes,
  clearEquipmentTypesModal,
};
export default actions;
