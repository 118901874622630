import axios from 'axios';
import { apiSlice } from 'features/api/apiSlice';
import store from 'store/store';
import FormData from 'form-data';

export const driver = {
  get() {
    return axios.get('v2/lookup/drivers');
  },
  getFreeDriversBySand(sandId) {
    return axios.get(`v2/driver/available?sandId=${sandId}`, {
      sandId: Number(sandId),
    });
  },
  getAvailableDrivers(params) {
    return axios.post('v2/job-manager/get-available-drivers', params);
  },
  async changeDistrict(district, driverId) {
    const result = await axios.put(
      `v2/driver/${driverId}/district/${district}`,
      {
        district,
        driverId,
      },
    );
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async changeDistricts(districtIds, driverId) {
    const result = await axios.post(`v2/driver/${driverId}/districts`, {
      districtIds,
    });
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async changeDriverRate(driverId, params) {
    const result = await axios.post(`v2/driver/${driverId}/rate`, params);
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async changePercentage(driverId, percents) {
    const result = await axios.post(`v2/driver/${driverId}/percentage`, {
      driverId,
      percents,
    });
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async logOutDriver(driverId) {
    const result = axios.post('v2/driverLogOut', {
      driverId,
    });
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async deleteDriver(driverId) {
    const result = await axios.delete(`v2/driver/${driverId}`, {
      data: { driverId },
    });
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  async restoreDriver(driverId) {
    const result = await axios.put(`v2/driver/reActivate/${driverId}`);
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  freeDriver(driverId) {
    return axios.put(`v2/driver/freeUpDriver/${driverId}`);
  },
  sendMessage(data) {
    return axios.post(`v2/drivers/${data.driversId}/send-push`, data);
  },
  pushNotification(data) {
    return axios.post('v2/sendPushToDriver', data);
  },
  async createDriver(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('password', data.password);
    formData.append('email', data.email);
    formData.append('carrierId', data.carrierId);
    formData.append('district', data.district);
    formData.append('homeDistrict', data.homeDistrict);
    formData.append('contactNo', data.contactNo);
    formData.append('vehicleNumber', data.vehicleNumber);
    formData.append('trailerNumber', data.trailerNumber);
    formData.append('signature', data.signature);
    formData.append('certificatePhotos', data.certificatePhotos);
    formData.append('equipment', data.equipment);
    formData.append('deviceType', '0');
    formData.append('deviceToken', '0');
    formData.append('lat', '0');
    formData.append('long', '0');

    const result = await axios.post('v2/createDriver', formData, {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    });
    store.dispatch(apiSlice.util.invalidateTags(['Driver']));
    return result;
  },
  getDriverProfile(id) {
    return axios.post('v2/getDriver', { id });
  },
  getDriverCertificates(id) {
    return axios.post(`v2/driver/${id}/certificates`, {});
  },
  async editDriver(params) {
    const result = await axios.post('v1/editDriver', params);
    store.dispatch(apiSlice.util.invalidateTags(['Driver']));
    return result;
  },
  async editTruck(driverId, params) {
    const result = await axios.post(`v2/driver/${driverId}/equipment`, params);
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  editAllDriver(params) {
    return axios.post('v2/editDriverData', params, {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    });
  },
  rejectDriverReport(driverId, params){
    return axios.get(`/driver/rejection/report/${driverId}`)
  }
};
