import React from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const CustomToggle = ({ children, eventKey, onClick }) => {
  const decoratedOnClick = useAccordionButton(eventKey, onClick);

  return (
    <span onClick={decoratedOnClick} style={{ cursor: 'pointer' }}>
      {children}
    </span>
  );
};
export default CustomToggle;
