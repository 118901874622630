import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { getValidationMessage } from 'helpers/error-handling';
import {
  getJobs,
  getPaginatedJobs,
  addJob,
  getJobDetails,
  getJobStages,
  upsertJobCarrierCommitments,
  editJobDesign,
  addJobOperators,
  deleteJob,
  completeJob,
  uncompleteJob,
  deleteJobOperator,
  getJobDocument,
  uploadJobDocument,
  updateContainerizedSettings,
  getCommoditiesByPo,
  getFreeDrivers,
  editJob,
  getTicketTypes,
  getOnsiteStorageTypes,
  getJobSharedOperators,
  getJobUnitsOfMeasure,
} from './queries';

export const useAddJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: newJob => addJob(newJob),
    onSuccess: () => {
      swal('Successfully Added Job', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
    onError: error => {
      swal('Unable to Add Job', getValidationMessage(error), 'error');
    },
  });
}

/**
 * @param {*} params
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} options
 * @returns
 */
export const useJobs = (params, options = {}) =>
  useQuery({
    ...options,
    queryKey: ['jobs', params],
    queryFn: () => getJobs(params),
  });

/*
 * @param {{
 * id: string;
 * name: string;
 * status: number;
 * type: number;
 * isDraft: number;
 * page: number;
 * pageSize: number;
 * }} params
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} [options]
 */
export const usePaginatedJobs = (params, options = {}) => {
  // delete empty string values
  Object.keys(params).forEach(key => params[key] === '' && delete params[key]);

  return useQuery({
    ...options,
    queryKey: ['jobs', params],
    queryFn: () => getPaginatedJobs(params),
  });
};

/**
 * @typedef {{
 *   jobId?: number;
 *   autoRefetch?: boolean;
 * }} GetJobDetailsParams
 */

const jobDetailsOptions = ({ jobId, autoRefetch }) =>
  queryOptions({
    queryKey: ['jobs', Number(jobId), 'details'],
    queryFn: () => getJobDetails(jobId),
    enabled: !!jobId,
    refetchInterval: autoRefetch && 30000,
  });

/** @param {GetJobDetailsParams} params */
export const useJobDetails = params => useQuery(jobDetailsOptions(params));
/** @param {GetJobDetailsParams} params */
export const useSuspenseJobDetails = params =>
  useSuspenseQuery(jobDetailsOptions(params));

export const useEditJob = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: newJob => editJob({ jobId, newJob }),
    onSuccess: () => {
      swal('Successfully Edited Job', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal(
        'Unable to Edit Job',
        getValidationMessage(error),
        'error',
      ),
  });
};

export const useEditJobDesign = jobId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: jobDesign => editJobDesign({ jobId, jobDesign }),
    onMutate: async newJobDesign => {
      // Optimistic update
      await queryClient.cancelQueries(['jobs', jobId]);
      const prevDetails = queryClient.getQueryData(['jobs', jobId]);
      queryClient.setQueryData(['jobs', jobId], oldDetails => {
        const newArr = newJobDesign.map(jd => ({
          name: 'Initial',
          stageDesignId: 0,
          sandTypeName: '',
          ...jd,
        }));
        return {
          ...oldDetails,
          jobDesign: newArr,
        };
      });
      return prevDetails;
    },
    onSuccess: () => {
      swal('Successfully Edited Job Design', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal('Unable to Edit Job Design', error.response?.data?.message, 'error'),
  });
};

export const useJobStages = jobId =>
  useQuery({
    queryKey: ['jobs', jobId, 'stages'],
    queryFn: () => getJobStages(jobId),
    enabled: !!jobId,
  });

export const useUpsertCarrierCommitments = (jobId, customerId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: carrierCommitments =>
      upsertJobCarrierCommitments({ jobId, carrierCommitments }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
      queryClient.invalidateQueries({ queryKey: ['jobs', { includeCarrierCommitments: true, customerId }] });
    }
  });
};

export const useDeleteJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: jobId => deleteJob(jobId),
    onSuccess: () => {
      swal('Successfully Deleted Job', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
    onError: error => {
      swal('Unable to Delete Job', error.response?.data?.message, 'error');
    },
  });
}

export const useAddJobOperators = ({ jobId, customerId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: entityData =>
      addJobOperators({ jobId, customerId, entityData }),
    onSuccess: () => {
      swal('Successfully Added Job Operators', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal(
        'Unable to Add Job Operators',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useDeleteJobOperator = ({ jobId, customerId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['jobs', jobId],
    mutationFn: entityData =>
      deleteJobOperator({ jobId, customerId, entityData }),
    onSuccess: () => {
      swal('Successfully Deleted Job Operator', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal(
        'Unable to Delete Job Operator',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useJobDocument = ({ jobId, documentId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'documents', documentId],
    queryFn: () => getJobDocument({ jobId, documentId }),
    enabled: !!(jobId && documentId),
    placeholderData: {},
  });

export const useUploadJobDocument = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file, name }) => uploadJobDocument({ jobId, file, name }),
    onSuccess: () => {
      swal('Successfully Uploaded Job Document', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal(
        'Unable to Upload Job Document',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useUpdateContainerizedSettings = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: settings => updateContainerizedSettings({ jobId, settings }),
    onSuccess: () => {
      swal('Successfully Updated Containerized Settings', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal(
        'Unable to Update Containerized Settings',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useCommoditiesByPo = ({ jobId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'po'],
    queryFn: () => getCommoditiesByPo({ jobId }),
    enabled: !!jobId,
    placeholderData: [],
  });

export const useFreeDrivers = ({ locationId }) =>
  useQuery({
    queryKey: ['drivers', 'free', locationId],
    queryFn: () => getFreeDrivers({ locationId }),
    enabled: !!locationId,
    placeholderData: [],
  });

export const useTicketTypes = () =>
  useQuery({
    queryKey: ['jobs', 'ticket-types'],
    queryFn: getTicketTypes,
    placeholderData: [],
  });

export const useOnsiteStorageTypes = () =>
  useQuery({
    queryKey: ['onsite-storage-types'],
    queryFn: getOnsiteStorageTypes,
    placeholderData: [],
  });

export const useJobSharedOperators = ({ jobId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'operators'],
    queryFn: () => getJobSharedOperators({ jobId }),
    enabled: !!jobId,
    placeholderData: [],
  });

export const useJobUnitsOfMeasure = ({ jobId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'units-of-measure'],
    queryFn: () => getJobUnitsOfMeasure({ jobId }),
    enabled: !!jobId,
    placeholderData: [],
  });

export const useCompleteJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (jobId) => completeJob(jobId),
    onSuccess: () => {
      swal('Successfully Completed Job', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
    onError: error => {
      swal(
        'Unable to Complete Job',
        error.response?.data?.message,
        'error',
      );
    },
  });
}

export const useUncompleteJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (jobId) => uncompleteJob(jobId),
    onSuccess: () => {
      swal('Successfully Completed Job', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
    onError: error => {
      swal(
        'Unable to Complete Job',
        error.response?.data?.message,
        'error',
      );
    },
  });
}
