const initState = {
  ready: false,
};
const firebase = (state = initState, action) => {
  switch (action.type) {
    case 'FIREBASE_INIT':
      return {
        ...state,
        ready: true,
      };
    default:
      return state;
  }
};

export default firebase;
