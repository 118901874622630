/* eslint-disable no-param-reassign */
import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import locationsActions from 'store/constants/Locations';
import { validateTimings } from 'components/views/Locations/Update/helpers';
import { sandSite } from '../../services/sandSite/service';

const setInputValue = (value, name) => ({
  type: locationsActions.LOCATIONS_TIMING_SET_INPUT_VALUE,
  value,
  name,
});

const setInputTiming = (value, index, day, name) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const timings = {...state.locations.modalTiming.updatetimings};
  const array = [...timings[day]];

  // if (name === 'open' || name === 'close') {
  //   value = moment(value).format('HH:mm:ss');
  // }
  array[index][name] = value;
  timings[day] = array;

  const inputValue = validateTimings(Object.values(timings).flat());

  dispatch(setInputValue(inputValue, 'updatetimings'));
};

const setTimeZone = timeZone => dispatch => {
  dispatch(setInputValue(timeZone, 'timeZone'));
};

const setExemptions = exemptions => dispatch => {
  dispatch(setInputValue(exemptions.map(i => i.value), 'exemptions'));
}

const addItemTiming = (name, day) => (dispatch, getState) => {
  const openDate = moment()
    .hours(10)
    .minutes(0)
    .seconds(0);
  const closeDate = moment()
    .hours(23)
    .minutes(59)
    .seconds(59);
  const content = {
    open: openDate,
    close: closeDate,
    closed: 0,
    day
  };

  const state = getState();
  const updateTimings = { ...state.locations.modalTiming[name] };
  updateTimings[day] = [...updateTimings[day] || [], content || {}]
  
  const inputValue = validateTimings(Object.values(updateTimings).flat());
  dispatch(setInputValue(inputValue, name));
};

const dellItemTiming = (index, day) => (dispatch, getState) => {
  const state = getState();
  const updateTimings = { ...state.locations.modalTiming.updatetimings }
  const array = [...updateTimings[day] ];
  array.splice(index, 1);
  updateTimings[day] = array;

  const inputValue = validateTimings(Object.values(updateTimings).flat());
  dispatch(setInputValue(inputValue, 'updatetimings'));
};

const update = closeModal => (dispatch, getState) => {
  const state = getState();
  const {
    locations: {
      modalTiming: { updatetimings, selectedLocation, timeZone, exemptions },
    },
  } = state;

  const joinedExemptions = exemptions.join(',');
  const flattenedTimings = Object.values(updatetimings).flat();

  flattenedTimings.forEach(item => {
    item.openTiming = moment(item.open).format('HH:mm:ss');
    item.closeTiming = moment(item.close).format('HH:mm:ss');
    item.timeZone = timeZone;
    item.exemptions = joinedExemptions;
  });

  const checkOpenCloseOrder = flattenedTimings.some(e => {
    if (moment(e.openTiming, 'HH:mm:ss').isAfter(moment(e.closeTiming, 'HH:mm:ss'))) {
      return true;
    }
    return false;
  });

  if (checkOpenCloseOrder) {
    swal('Close time should be later than Open time');
  } else {
    const data = {
      timings: flattenedTimings,
      selectedLocation,
    };
    sandSite.timing
      .update(data)
      .then(() => {
        swal('Success', '', 'success');
        closeModal();
      })
      .catch(error => swal(error.response.data.message, '', 'error'));
  }
};
const clear = () => ({
  type: locationsActions.LOCATIONS_TIMING_CLEAR_STATE,
});

export default {
  setInputTiming,
  setTimeZone,
  setExemptions,
  addItemTiming,
  dellItemTiming,
  update,
  clear,
};
