import React from 'react';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { connect } from 'react-redux';
import actions from '../actions';

const Header = ({
  changeAccessToNotification,
  allowNotification,
  changeEventsBarStatus,
}) => (
  <div className="events-sidebar__header">
    <h3>Latest Events</h3>

    <Button
      theme="borderless"
      colour="white"
      onClick={changeEventsBarStatus}
      className="events-sidebar__close"
      testSelector="events_latest-events_btn">
      <Icon icon="times" />
    </Button>

    <div>
      <span>Allow Notifications</span>
      <Button
        theme="borderless"
        colour={allowNotification ? 'green' : 'white'}
        onClick={changeAccessToNotification}
        className="notification-toggle"
        testSelector="events_toggle-notifications_btn">
        <Icon icon={allowNotification ? 'toggle-on' : 'toggle-off'} />
      </Button>
    </div>
  </div>
);

const mapStateToProps = state => ({
  allowNotification: state.lookUp.user.settings.allowNotifications,
});

const mapDispatchToProps = dispatch => ({
  changeAccessToNotification: () =>
    dispatch(actions.changeAccessToNotification()),
  changeEventsBarStatus: () => dispatch(actions.changeEventsBarStatus()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
