import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';

import { useCarriers } from 'api/v2/carriers';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import authService from 'services/auth/service';
import { addDriver as addDriverAction } from 'store/actions/Drivers';

const user = authService.getUser();

const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const defaultState = {
  name: '',
  email: '',
  carrierId: '',
  carrierName: '',
  vehicleNumber: '',
  district: '',
  homeDistrict: `${defaultOnBoardingDistrict} | On-boarding`,
  trailerNumber: '',
  contactNo: '',
  certificatePhotos: '',
  equipment: '',
};

const AddDriver = ({
  getDistricts,
  showModalAddDriver,
  closeAddDriver,
  districts,
  isLoadedDistrictsToCarrier,
  addDriver,
  requiredEquipment,
  isRequestSending,
}) => {
  const [currentState, setCurrentState] = useState(defaultState);
  const [carrierOptions, setCarrierOptions] = useState([]);

  const { data: carriers, isLoading } = useCarriers();

  useEffect(() => {
    if (!carriers?.length) {
      return;
    }

    if (carriers.length === 1 && carriers[0].id) {
      setCurrentState(cs => ({
        ...cs,
        carrierId: carriers[0].id,
        carrierName: carriers[0].name || '',
      }));
      getDistricts(carriers[0].id);
    }

    let carrierOpt = [];
    if (user.entityType === 3) {
      carrierOpt = carriers
        .filter(c => c.id === user.entityId)
        .map(item => ({
          value: item.id,
          name: item.name,
          label: `${item.id} | ${item.name}`,
        }));
      setCurrentState(cs => ({
        ...cs,
        carrierId: carrierOpt[0].value,
        carrierName: carrierOpt[0].name || '',
      }));
      getDistricts(carrierOpt[0].value);
    } else {
      carrierOpt = carriers.map(item => ({
        value: item.id,
        name: item.name,
        label: `${item.id} | ${item.name}`,
      }));
    }
    setCarrierOptions(carrierOpt);
  }, [carriers]);

  const isEmailValid = email => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isFormValid = () => {
    isEmailValid(currentState.email);
    if (
      currentState.name.trim().length > 0 &&
      currentState.email.trim().length > 0 &&
      currentState.contactNo.trim().length > 0 &&
      currentState.carrierId !== undefined &&
      currentState.district > 0 &&
      currentState.vehicleNumber.trim().length > 0 &&
      currentState.trailerNumber.trim().length > 0 &&
      currentState.equipment > 0 &&
      isValidPhoneNumber(currentState.contactNo) &&
      isEmailValid(currentState.email)
    ) {
      return false;
    }
    return true;
  };

  const districtOptions =
    districts && districts.length
      ? districts.map(item => ({
          value: item.id,
          label: `${item.id} | ${item.name}`,
        }))
      : [];

  if (!districtOptions.find(i => i.value === defaultOnBoardingDistrict)) {
    districtOptions.push({
      value: defaultOnBoardingDistrict,
      label: `${defaultOnBoardingDistrict} | On-boarding`,
    });
  }

  const equipmentOptions = requiredEquipment.map(item => ({
    value: item.id,
    label: item.title,
  }));

  if (isLoading) {
    return null;
  }

  return (
    <Modal
      className="modal-container"
      show={showModalAddDriver}
      onHide={closeAddDriver}>
      <Modal.Header>
        <Modal.Title as="h3">Add Driver</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => addDriver(e, currentState, closeAddDriver)}>
        <Modal.Body>
          {isRequestSending ? (
            <div data-testid="add-driver-modal-body-loader">
              <Loader />
            </div>
          ) : (
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={({ target: { value } }) => {
                    setCurrentState(cs => ({
                      ...cs,
                      name: value,
                    }));
                  }}
                  testSelector="drivers-list_add_name_input"
                  value={currentState.name}
                  label="Name"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  value={currentState.email}
                  onChange={({ target: { value } }) => {
                    setCurrentState(cs => ({
                      ...cs,
                      email: value,
                    }));
                  }}
                  testSelector="drivers-list_add_email_input"
                  required
                  label="Email"
                  type="email"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="tel"
                  onChange={e => {
                    setCurrentState(cs => ({
                      ...cs,
                      contactNo: e || '',
                    }));
                  }}
                  value={currentState.contactNo}
                  label="Country Code and Phone  Number"
                  required
                  testSelector="driver-details_edit_phone_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  onChange={item => {
                    setCurrentState(cs => ({
                      ...cs,
                      carrierId: item.value,
                      carrierName: item.name,
                      district: null,
                      homeDistrict: `${defaultOnBoardingDistrict} | On-boarding`,
                    }));
                    getDistricts(item.value);
                  }}
                  options={carrierOptions}
                  label="Carrier"
                  value={carrierOptions.find(
                    item => item.value === currentState.carrierId,
                  )}
                  required
                  accountForPadding={false}
                  testSelector="drivers_add_carrier_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  onChange={item => {
                    setCurrentState(cs => ({
                      ...cs,
                      district: item.value,
                    }));
                  }}
                  options={districtOptions}
                  value={
                    districtOptions.find(
                      item => item.value === currentState.district,
                    ) || null
                  }
                  label="District"
                  required
                  isDisabled={!isLoadedDistrictsToCarrier}
                  accountForPadding={false}
                  testSelector="drivers_add_district_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={({ target: { value } }) => {
                    setCurrentState(cs => ({
                      ...cs,
                      vehicleNumber: value,
                    }));
                  }}
                  value={currentState.vehicleNumber}
                  testSelector="drivers_add_truck-number_input"
                  label="Truck number"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={({ target: { value } }) => {
                    setCurrentState(cs => ({
                      ...cs,
                      trailerNumber: value,
                    }));
                  }}
                  value={currentState.trailerNumber}
                  testSelector="drivers_add_trailer-number_input"
                  label="Trailer number"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  value={equipmentOptions.find(
                    item => item.value === currentState.equipment,
                  )}
                  options={equipmentOptions}
                  onChange={item => {
                    setCurrentState(cs => ({
                      ...cs,
                      equipment: item.value,
                    }));
                  }}
                  label="Equipment"
                  accountForPadding={false}
                  required
                  testSelector="drivers_add_equipment_select"
                />
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeAddDriver}
            colour="white"
            testSelector="drivers-list_add-driver_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={isFormValid() || isRequestSending}
            testSelector="drivers-list_add-driver_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isLoadedCarriers: state.driversNew.isLoadedCarriers,
  districts: state.resourceReducer.districtToCarrierId.districts,
  isLoadedDistrictsToCarrier:
    state.resourceReducer.districtToCarrierId.isLoadedDistrictsToCarrier,
  requiredEquipment: state.lookUp.equipmentRequired,
  isRequestSending: state.driversNew.isRequestSending,
});

const mapDispatchToProps = dispatch => ({
  getDistricts: carrierId => {
    dispatch(resourceActions.getDistrictAccordingToCarrier(carrierId));
  },
  addDriver: (e, data, closeAddDriver) => {
    e.preventDefault();
    const tmpData = { ...data, contactNo: data.contactNo.replace(/\s+/g, '') };
    dispatch(addDriverAction(tmpData, closeAddDriver));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddDriver);
