import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ticketsRequested,
  ticketUpdateRequested,
} from 'store/actions/OrderTickets';
import actions from 'store/actions/JobPlans';
import Modal from 'components/shared/Modal';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import { Col, Row } from 'react-bootstrap';

// TODO: Share with components/views/OrderDetails/Tabs/SandTickets/EditSandTicket.js
const OrderInfoTicketEditModal = ({
  ticket,
  open,
  onClose,
  config,
  isLoading,
  updateTicket,
  getTickets,
  getOrderTickets,
}) => {
  const [ticketFields, setTicketFields] = useState({
    sandTicketNumber: ticket.ticketNumber || '',
    sandTicketType: ticket.ticketType,
  });
  const [invalidUpdate, setInvalidUpdate] = useState(true);
  const configTypes =
    config && config?.length
      ? config.map(({ ticketType, ticketName }) => ({
          value: ticketType,
          label: ticketName,
        }))
      : [];

  useEffect(() => {
    if (open) {
      setTicketFields({
        sandTicketNumber: ticket.ticketNumber || '',
        sandTicketType: ticket.ticketType,
      });
    }
  }, [open]);

  useEffect(() => {
    setInvalidUpdate(
      ticketFields.sandTicketNumber.trim() === '' ||
        (ticketFields.sandTicketNumber === ticket.ticketNumber &&
          ticketFields.sandTicketType === ticket.ticketType),
    );
  }, [ticketFields]);

  const onComplete = () => {
    onClose();
    getTickets();
    getOrderTickets();
  };

  const body = (
    <Row>
      <Col lg={6} md={6} className="form-group">
        <Input
          value={ticketFields.sandTicketNumber}
          label="Ticket Number"
          onChange={e =>
            setTicketFields({
              ...ticketFields,
              sandTicketNumber: e.target.value,
            })
          }
          required
          testSelector="reconcile_order-details_edit-ticket_number_input"
        />
      </Col>
      <Col lg={6} md={6} className="form-group">
        <Select
          label="Ticket Type"
          placeholder="Ticket Type"
          options={configTypes}
          value={configTypes.find(c => c.value === ticketFields.sandTicketType)}
          onChange={({ value }) =>
            setTicketFields({ ...ticketFields, sandTicketType: value })
          }
          testSelector="reconcile_order-details_edit_ticket_type_select"
        />
      </Col>
    </Row>
  );

  const footer = (isLoading && <Loader />) || (
    <>
      <Button
        disabled={invalidUpdate}
        onClick={() => updateTicket(ticketFields, onComplete)}
        testSelector="reconcile_order-details_info-ticket-edit_update_btn">
        Update
      </Button>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="reconcile_order-details_info-ticket-edit_close_btn">
        Close
      </Button>
    </>
  );

  return (
    <Modal
      title="Edit Ticket"
      open={open}
      onClose={onClose}
      body={body}
      footer={footer}
    />
  );
};

const mapStateToProps = ({ orderTickets: { config, actionApiIsLoading } }) => ({
  config,
  isLoading: actionApiIsLoading,
});

const mapDispatchToProps = (dispatch, { ticket, order }) => ({
  updateTicket: (fields, callback = null) =>
    dispatch(
      ticketUpdateRequested(
        { ...fields, orderId: order.order_id, sandTicketId: ticket.id },
        callback,
      ),
    ),
  getTickets: () => dispatch(ticketsRequested(order.order_id)),
  getOrderTickets: () => dispatch(actions.getOrderTickets(order.order_id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderInfoTicketEditModal);
