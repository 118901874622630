// DEPRECATED for new Billing flow
export const billingStatus = {
  '0': {
    text: 'New',
    class: 'yellow',
  },
  '1': {
    text: 'Declined',
    class: 'red',
  },
  '10': {
    text: 'Approved by job manager',
    class: 'green',
  },
  '11': {
    text: 'Approved by carrier',
    class: 'blue',
  },
  '20': {
    text: 'Ready to bill',
    class: 'green',
  },
  '21': {
    text: 'Billed',
    class: 'blue',
  },
  '22': {
    text: 'Paid',
    class: 'purple',
  },
  '23': {
    text: 'Settled',
    class: 'purple',
  },
  '24': {
    text: 'Non billable',
    class: '',
  },
};

export const formatBillingStatus = unformattedStatus => {
  const status = parseInt(unformattedStatus);
  let formattedStatus = {};
  switch (status) {
    case 0:
      formattedStatus = {
        value: 0,
        label: 'new',
      };
      break;
    case 1:
      formattedStatus = {
        value: 1,
        label: 'declined',
      };
      break;
    case 10:
      formattedStatus = {
        value: 10,
        label: 'approved by job manager',
      };
      break;
    case 11:
      formattedStatus = {
        value: 11,
        label: 'approved by job carrier',
      };
      break;
    case 20:
      formattedStatus = {
        value: 20,
        label: 'ready to bill',
      };
      break;
    case 21:
      formattedStatus = {
        value: 21,
        label: 'billed',
      };
      break;
    case 22:
      formattedStatus = {
        value: 22,
        label: 'paid',
      };
      break;
    case 23:
      formattedStatus = {
        value: 23,
        label: 'settled',
      };
      break;
    case 24:
      formattedStatus = {
        value: 24,
        label: 'non billable',
      };
      break;
    default:
      break;
  }
  return formattedStatus;
};
