const initialState = {
  count: 0,
  countAPI: 8,
  sandTypes: [],
  tags: [],
  sandSites: [],
  wellSites: [],
  locations: [],
  vendors: [],
  equipmentRequired: [],
  sessionLifetime: {},
  user: {},
};

export function lookUp(state = { ...initialState }, action) {
  switch (action.type) {
    case 'LOOKUP_RESPONSE_SUCCESS':
      return { ...state, count: state.count + 1, [action.array]: action.data };
    case 'LOOKUP_RESPONSE_FAILURE':
      return { ...state, count: state.count + 1 };
    case 'LOOKUP_EDIT':
      return {
        ...state,
        [action.arrayName]: state[action.arrayName].map(item => {
          if (item.id !== action.id) {
            return {
              ...item,
            };
          }
          return {
            ...action.newData,
          };
        }),
      };
    case 'LOOKUP_ADD':
      return {
        ...state,
        [action.arrayName]: [...state[action.arrayName], action.newData],
      };
    case 'LOOKUP_REFRESH_DRIVERS_SUCCESS':
      return { ...state, [action.arrayName]: action.newArray };
    case 'LOOKUP_DELETE':
      return {
        ...state,
        [action.arrayName]: state[action.arrayName].filter(
          i => i.id !== action.id,
        ),
      };
    case 'LOOKUP_EVENTS_CHANGE_ACCESS_NOTIFICATION':
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            allowNotifications: action.value,
          },
        },
      };
    case 'LOOKUP_EDIT_USER':
      return {
        ...state,
        user: action.newData,
      };
    case 'LOOKUP_CLEAR':
      return initialState;
    default:
      return state;
  }
}
