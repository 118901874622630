import axios from 'axios';

export default {
  audit: {
    get() {
      return axios.get('v2/order/audit-settings', {});
    },
    update(data) {
      return axios.post('v2/order/audit-settings/update', data);
    },
  },
  company: {
    get() {
      return axios.get('v2/company/credentials', {});
    },
    update(data) {
      return axios.post('v2/company/credentials', data);
    },
  },
  alerts: {
    update(data) {
      return axios.put('v2/alertSettings', data)
    }
  }
};
