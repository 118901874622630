import { carrier } from 'services/carrier/service';

export const carriersRequested = params => async dispatch => {
  dispatch({ type: 'CARRIERS_REQUESTED' });

  const carriers = await carrier.getCarriers(params).catch(error => {
    // TODO: Add Generic Failed Handler
    return dispatch({ type: 'CARRIERS_REQUEST_FAILED' });
  });

  return dispatch({
    carriers: carriers.data.data.carriers,
    type: 'CARRIERS_REQUEST_SUCCEEDED',
  });
};

export default {
  carriersRequested,
};
