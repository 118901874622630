import moment from 'moment';

const init = {
  addJob: {
    po: {
      poNo: '',
    },
    startDate: moment().add(1, 'minute'),
    loadDate: '',
    customerOrderId: '',
    count: '',
    stage: '',
    autoAssign: true,
    manualDispatchEnabled: false,
    driverId: '',
    wellSiteId: 10,
    stagingSite: ''
  },
  sandTypesByPo: [{}],
  stages: [{}],
  disableSubmit: false,
  freeDrivers: [{}],
};

export default (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_ADD_ORDER_GET_SAND_TYPE_PO_SUCCESS':
      return {
        ...state,
        sandTypesByPo: action.data,
      };
    case 'JOB_DETAILS_ADD_ORDER_GET_STAGE_SUCCESS':
      return {
        ...state,
        stages: action.data,
      };
    case 'JOB_DETAILS_ADD_ORDER_SET_INPUT_VALUE':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_ADD_ORDER_SET_CHECK_AUTOASSIGN_VALUE':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          autoAssign: !state.addJob.autoAssign,
        },
      };
    case 'JOB_DETAILS_ADD_ORDER_SET_CHECK_MANUAL_VALUE':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          manualDispatchEnabled: !state.addJob.manualDispatchEnabled,
        },
      };
    case 'JOB_DETAILS_ADD_ORDER_GET_DRIVERS_SUCCESS':
      return {
        ...state,
        freeDrivers: action.data,
      };
    case 'JOB_DETAILS_ADD_ORDER_ADD_REQUEST':
      return {
        ...state,
        disableSubmit: true,
      };
    case 'JOB_DETAILS_ADD_ORDER_ADD_FAIL':
      return {
        ...state,
        disableSubmit: false,
      };
    case 'JOB_DETAILS_ADD_ORDER_CLEAR_STORE':
      return init;

    default:
      return state;
  }
};
