import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';
import Select from 'components/shared/Select';
import DatePicker from 'components/shared/DatePicker';
import Pagination from 'components/shared/Pagination';
import Icon from 'components/shared/Icon';

import auditActions from 'store/actions/Audits/SearchAudits';

import AuditDetailsPanel from './AuditDetailsPanel';
import targetValue from '../../../../utils/targetValue';

const AuditSearch = ({
  isLoadingAudits,
  isLoadedAudits,
  getAudits,
  audits,
  getNumPages,
  numPages,
}) => {
  const subjects = [
    'Vendor',
    'Carrier',
    'Admin',
    'Chat',
    'Commodity',
    'Commodities',
    'Container',
    'Customer',
    'Database',
    'District',
    'Driver',
    'Export',
    'Invoice',
    'Job',
    'Location',
    'Manager',
    'Order',
    'Pricing',
    'Purchase Order',
    'Queue',
    'Reconcile',
    'Role',
    'Scada',
    'Trailer',
    'User',
    'Job Manager',
  ].map(item => ({ label: item, value: item.toLowerCase() }));
  const actions = ['Created', 'Updated', 'Deleted'].map(item => ({
    label: item,
    value: item.toLowerCase(),
  }));
  const roles = ['Vendor', 'Customer', 'Carrier', 'Job Manager', 'Admin'].map(
    item => ({ label: item, value: item.replace(' ', '') }),
  );
  const intitialDetailsPanelState = {
    showDetailsPanel: false,
    request: null,
    response: null,
    updateSummary: null,
  };
  const initialSortState = { sort: 'timestamp', direction: -1 };

  const [searchTerms, setSearchTerms] = useState({});
  const [page, setPage] = useState(1);
  const [oldSearchState, setOldSearchState] = useState({});
  const [detailsPanelState, updateDetailsPanelState] = useState(
    intitialDetailsPanelState,
  );
  const [sortState, updateSortState] = useState(initialSortState);
  const [afterDateValid, setAfterDateValid] = useState(true);
  const [beforeDateValid, setBeforeDateValid] = useState(true);

  const changeSorting = ({ sort }) => {
    let newDir;
    if (sortState.sort === sort) {
      updateSortState({ sort, direction: sortState.direction * -1 });
      newDir = sortState.direction * -1;
    } else {
      updateSortState({ sort, direction: -1 });
      newDir = -1;
    }

    setPage(1);
    getAudits({
      ...oldSearchState,
      sortBy: sort,
      sortDirection: newDir,
      pageNumber: 1,
    });
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col lg={2}>
            <Input
              onChange={input =>
                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  userName: targetValue(input),
                }))
              }
              type="search"
              placeholder="Name..."
              testSelector="name-search">
              Name
            </Input>
          </Col>
          <Col lg={2}>
            <Input
              onChange={input =>
                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  subjectId: targetValue(input),
                }))
              }
              type="search"
              placeholder="Subject ID..."
              testSelector="id-search">
              Subject Id
            </Input>
          </Col>
          <Col lg={1}>
            <Select
              isClearable
              options={actions}
              onChange={item =>
                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  action: item?.value,
                }))
              }
              placeholder="Action"
            />
          </Col>
          <Col lg={1}>
            <Select
              isClearable
              options={subjects}
              onChange={item =>
                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  subjectType: item?.value,
                }))
              }
              placeholder="Subject"
            />
          </Col>
          <Col lg={1}>
            <Select
              isClearable
              options={roles}
              onChange={item =>
                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  userRole: item?.value,
                }))
              }
              placeholder="Role"
            />
          </Col>
          <Col lg={2}>
            <DatePicker
              label="After Date"
              onChange={item => {
                if (typeof item === 'string') {
                  item = moment(item);
                }
                moment(item).isValid();

                setAfterDateValid(item.isValid());

                if (!afterDateValid) {
                  return;
                }

                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  afterDate: item.toISOString(),
                }));
              }}
            />
          </Col>
          <Col lg={2}>
            <DatePicker
              label="Before Date"
              onChange={item => {
                if (typeof item === 'string') {
                  item = moment(item);
                }

                setBeforeDateValid(item.isValid());

                if (!beforeDateValid) {
                  return;
                }

                setSearchTerms(previousSearchTerms => ({
                  ...previousSearchTerms,
                  beforeDate: item.toISOString(),
                }));
              }}
            />
          </Col>
          <Col lg={1}>
            <Button
              onClick={() => {
                updateSortState({ sort: 'timestamp', direction: -1 });
                setOldSearchState(searchTerms);
                setPage(1);
                getNumPages(searchTerms);
                getAudits({
                  ...searchTerms,
                  sortBy: 'timestamp',
                  sortDirection: -1,
                });
              }}
              disabled={!afterDateValid || !beforeDateValid}>
              Search
            </Button>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {isLoadedAudits && (
          <Pagination
            currentPage={page}
            totalPages={numPages}
            onPageChange={({ currentPage }) => {
              setPage(currentPage);
              getAudits({
                ...oldSearchState,
                pageNumber: currentPage,
                sortBy: sortState.sort,
                sortDirection: sortState.direction,
              });
            }}
          />
        )}
        {(isLoadedAudits && (
          <table className="table table--hover">
            <thead>
              <tr>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'timestamp' });
                    }}
                    className="table-list__header-item">
                    Timestamp
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'user.name' });
                    }}
                    className="table-list__header-item">
                    User
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'user.role' });
                    }}
                    className="table-list__header-item">
                    Role
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'action' });
                    }}
                    className="table-list__header-item">
                    Action
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'subject.type' });
                    }}
                    className="table-list__header-item">
                    Subject
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'subject.id' });
                    }}
                    className="table-list__header-item">
                    Subject Id
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => {
                      changeSorting({ sort: 'details.url' });
                    }}
                    className="table-list__header-item">
                    URL
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {audits.map(audit => (
                <tr>
                  <td>{`${new Date(audit.timestamp).toDateString()} ${new Date(
                    audit.timestamp,
                  ).toLocaleTimeString()}`}</td>
                  <td>{audit.user.name ?? '-'}</td>
                  <td>{audit.user.role ?? '-'}</td>
                  <td>{audit.action ?? '-'}</td>
                  <td>
                    {!(audit.subject.type === '' || !audit.subject.type)
                      ? audit.subject.type
                      : '-'}
                  </td>
                  <td>{audit.subject.id ?? '-'}</td>
                  <td>{audit.details.url ?? '-'}</td>
                  <td>
                    <Icon
                      icon="right"
                      onClick={() => {
                        updateDetailsPanelState({
                          showPanel: true,
                          request: audit.details,
                          response: audit.response,
                          updateSummary: audit.updateSummary,
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )) ||
          (isLoadingAudits && <Loader />)}
      </Card.Body>

      <AuditDetailsPanel
        className="audit-details"
        updateSummary={detailsPanelState.updateSummary}
        request={detailsPanelState.request}
        response={detailsPanelState.response}
        isVisible={detailsPanelState.showPanel}
        onClose={() =>
          updateDetailsPanelState({
            response: null,
            request: null,
            showPanel: false,
          })
        }
      />
    </Card>
  );
};

const mapStateToProps = state => ({
  audits: state.auditSearch.audits,
  isLoadedAudits: state.auditSearch.isLoaded,
  isLoadingAudits: state.auditSearch.isLoading,
  numPages: state.auditSearch.numPages,
});

const mapDispatchToProps = dispatch => ({
  getAudits: query => dispatch(auditActions.searchAudits(query)),
  getNumPages: query => dispatch(auditActions.getNumPages(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditSearch);
