import React from 'react';
import { Link } from 'react-router-dom';

const LinkCustom = ({ id, to, isNotActive, prop, target, children }) => {
  const locationNow = window.location.pathname.split('/')[1];

  if (isNotActive) {
    return <span style={{ color: '#6fc6d9' }}>{children}</span>;
  }

  return (
    <Link
      to={{ pathname: `/${locationNow}/${to}${id ? `/${id}` : ''}`, ...prop }}
      target={target}>
      {children}
    </Link>
  );
};

export default LinkCustom;
