export const driverLogsEvents = [
  { id: 0, title: 'order created', color: '' },
  { id: 1, title: 'order assigned', color: '' },
  { id: 2, title: 'order accepted', color: 'label-primary' },
  { id: 3, title: 'order rejected', color: 'label-danger' },
  { id: 4, title: 'order completed', color: 'label-primary' },
  { id: 5, title: 'order timeout', color: 'label-danger' },
  { id: 10, title: 'driver entering sandsite', color: 'label-warning' },
  { id: 11, title: 'driver departing sandsite', color: 'label-warning' },
  { id: 12, title: 'driver entering wellsite', color: 'label-warning' },
  { id: 13, title: 'driver invalid login', color: 'label-danger' },
  { id: 14, title: 'driver success login', color: 'label-primary' },
  { id: 15, title: 'is online', color: 'label-primary' },
  { id: 16, title: 'is offline', color: 'label-danger' },
  { id: 17, title: 'turn on online', color: 'label-primary' },
  { id: 18, title: 'turn off online', color: 'label-danger' },
  { id: 19, title: 'driver logout', color: 'label-danger' },
];
