import React from 'react';

const DistrictStats = ({
  district: {
    carriers, customers, jobs
  }
}) => (
  <div>
    <div style={{ textAlign: 'center' }}>
      <div className="ibox float-e-margins">
        <h2>Details</h2>
        <div className="ibox-content" style={{ paddingTop: '25px' }}>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-4"
              style={{ color: 'rgb(250,173,80)' }}>
              <h1 className="no-margins">{customers.length || 0}</h1>
              <div className="font-bold">Customers</div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4"
              style={{ color: 'rgb(240,83,98)' }}>
              <h1 className="no-margins">{carriers.length || 0}</h1>
              <div className="font-bold">Carriers</div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4"
              style={{ color: 'rgb(7,198,201)' }}>
              <h1 className="no-margins">{jobs.length || 0}</h1>
              <div className="font-bold">Jobs</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default DistrictStats;
