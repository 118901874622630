import React from 'react';
import PropTypes from 'prop-types';

import { Modal as BootstrapModal } from 'react-bootstrap';

const Modal = ({
  open,
  onClose = () => {},
  header,
  body,
  footer,
  title,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BootstrapModal show={open} onHide={onClose} className="modal-lg" {...props}>
    {(header || title) && (
      <BootstrapModal.Header>
        {header || <div className="modal-title">{title || ''}</div>}
      </BootstrapModal.Header>
    )}
    <BootstrapModal.Body>{body}</BootstrapModal.Body>
    {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
  </BootstrapModal>
);

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  header: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Modal;
