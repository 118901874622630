import { carrier } from 'services/carrier/service';
import authService from 'services/auth/service';

const turnAddPO = jobDetails => ({
  type: 'JOB_DETAILS_PO_ADD_TURN',
  jobDetails,
});
const turnUpdatePO = data => ({
  type: 'JOB_DETAILS_PO_UPDATE_TURN',
  data,
});
const turnImportPO = () => ({ type: 'JOB_DETAILS_IMPORT_PO_ADD_TURN' });

// add modal actions
const initAdd = () => dispatch => {
  const setInitValue = carriers => ({
    type: 'JOB_DETAILS_PO_ADD_INIT',
    carriers,
  });

  carrier
    .getCarriers()
    .then(msg => dispatch(setInitValue(msg.data.data.carriers)));
};

const setInputValueAdd = (value, name) => ({
  type: 'JOB_DETAILS_PO_ADD_SET_INPUT_VALUE',
  value,
  name,
});

const clearAdd = () => ({ type: 'JOB_DETAILS_PO_ADD_CLEAR' });

// update modal
const user = authService.getUser();

const initUpdate = () => dispatch => {
  const setInitValue = carriers => ({
    type: 'JOB_DETAILS_PO_UPDATE_INIT',
    carriers,
  });

  carrier
    .getCarriers()
    .then(msg => dispatch(setInitValue(msg.data.data.carriers)));
};

const setInputValueUpdate = (value, name) => ({
  type: 'JOB_DETAILS_PO_UPDATE_SET_INPUT_VALUE',
  value,
  name,
});

const setInputValue = (value, name) => ({
  type: 'JOB_DETAILS_IMPORT_PO_SET_INPUT_VALUE',
  value,
  name,
});

const setInput = (value, index, name) => (dispatch, getState) => {
  const {
    jobDetails: {
      poModals: {
        import: { posList },
      },
    },
  } = getState();
  const data = [...posList];
  data[index][name] = value;
  dispatch(setInputValue(data, 'posList'));
};

const setInputCarrier = (value, index, name) => (dispatch, getState) => {
  const {
    jobDetails: {
      poModals: {
        import: { logisticPo },
      },
    },
  } = getState();
  const data = [...logisticPo];
  data[index][name] = value;
  dispatch(setInputValue(data, 'logisticPo'));
};

const clear = () => ({ type: 'JOB_DETAILS_IMPORT_PO_CLEAR' });

export default {
  turnAddPO,
  turnUpdatePO,
  turnImportPO,

  // add modal actions
  initAdd,
  setInputValueAdd,
  clearAdd,

  // update modal
  initUpdate,
  setInputValueUpdate,

  // import modal
  setInputValue,
  setInput,
  clear,
  setInputCarrier,
};
