import { useEffect, useMemo, useReducer } from 'react';
import targetValue from 'utils/targetValue';
import moment from 'moment';
import { useJobStages } from 'api/v2/jobs';
import { filterOptions } from 'utils/selectUtils';
import { useJobPurchaseOrders } from 'api/v2/purchase-orders';
import { formatOrderInfo, formattedDate, formattedPos } from './helpers';

export const useOrderStages = jobId => {
  const { data: stages } = useJobStages(jobId);

  const stagesList = useMemo(() => {
    if (!stages) {
      return [];
    }

    const formattedStages = stages.map(item => {
      item.label = `${item.stageId}`;
      return item;
    });

    return filterOptions(formattedStages, null, null, 'stageId', 'label');
  }, [stages]);

  return stagesList;
};

export const useOrderPos = ({ jobId, operationType }) => {
  const { data: pos } = useJobPurchaseOrders(jobId);

  const posList = useMemo(() => {
    if (!pos || !operationType) {
      return [];
    }

    return filterOptions(
      formattedPos(pos ?? [], operationType),
      null,
      null,
      'value',
      'label',
    );
  }, [pos, operationType]);

  return posList;
};

/**
 * @typedef {{
 *  orderId: number;
 *  mileage: number;
 *  customerOrderId: number;
 *  sandTicketNo: string;
 *  orderAcceptedAt: string;
 *  loadArrivalTime: string;
 *  loadDepartureTime: string;
 *  stageArrivalTime: string;
 *  stageDepartureTime: string;
 *  wellArrivalTime: string;
 *  wellDepartureTime: string;
 *  driverWeight?: number;
 *  stage?: number;
 *  po: string;
 *  truck: string;
 *  trailerNumber: string;
 *  truckDown: boolean;
 *  isStageSiteOnOrder: boolean;
 *  orderStatus: number;
 *  kingComp?: string;
 *  sandSiteTime: string;
 *  wellsiteTime: string;
 *  createdAt: string;
 *  completedAt: string;
 *  updatedAt: string;
 *  shouldComplete: boolean;
 *  chatId: string;
 *  driverId: number;
 * }} Modify
 */

const initialModify = {};

/**
 *
 * @param {Modify} state
 * @returns {Modify}
 */
const ModifyReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialModify;
    default:
      return state;
  }
};

export const useModifyForm = ({ defaultValue, showModal }) => {
  /** @type {[Modify, () => {}]} */
  const [data, dispatch] = useReducer(ModifyReducer, initialModify);

  useEffect(() => {
    if (defaultValue && showModal) {
      const order = formatOrderInfo(defaultValue);

      if (defaultValue?.fromQr) {
        order.wellDepartureTime = moment();
      }

      dispatch({ type: 'init', data: order });
    }
  }, [defaultValue, showModal]);

  const isValid = useMemo(() => data.orderId && data.orderAcceptedAt, [data]);

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  const formattedData = useMemo(() => {
    const loadDepartTime = formattedDate(data.loadDepartureTime);
    const loadArriveTime = formattedDate(data.loadArrivalTime);
    const sandSiteTime = loadDepartTime.diff(loadArriveTime, 'minutes');

    const wellDepartTime = formattedDate(data.wellDepartureTime);
    const wellArriveTime = formattedDate(data.wellArrivalTime);
    const wellsiteTime = wellDepartTime.diff(wellArriveTime, 'minutes');

    return { ...data, sandSiteTime, wellsiteTime };
  }, [data]);

  return {
    data: formattedData,
    isValid,
    handlers,
  };
};
