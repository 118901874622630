import { notarizations } from '../../services/notarizations/service';
import swal from 'bootstrap-sweetalert';

const getReport = orderId => dispatch => {
  const success = report => ({
    type: 'NOTARIZATION_GET_REPORT_SUCCESS',
    report,
  });
  const fail = () => ({
    type: 'NOTARIZATION_GET_REPORT_FAIL',
  });
  dispatch({ type: 'NOTARIZATION_GET_REPORT_REQUEST' });
  notarizations
    .getReport(orderId)
    .then(response => {
      dispatch(success(response.data.data.notarizationReport));
    })
    .catch(error => {
      dispatch(fail(error));
      // Fail silently if notarization does not exist
      if (error.data.status !== 400) {
        swal(error.response.data.message, '', 'error');
      }
    });
};

const actions = {
  getReport,
};

export default actions;
