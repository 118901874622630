import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';

const LogoutModal = ({ close, showModal }) => {
  const [counter, setCounter] = useState(60);

  const counterFunction = () => {
    const myInterval = setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      }
      if (counter === 0) {
        clearTimeout(myInterval);
      }
    }, 1000);
    return () => {
      clearTimeout(myInterval);
    };
  };

  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = '/login';
  };

  useEffect(() => {
    counterFunction();
    if (!counter) {
      logoutAction();
    }
  }, [counter]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModal} onHide={close}>
        <Modal.Header>
          <h3 className="modal-title">Are you still here?</h3>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ textAlign: 'center', fontSize: 'large' }}>
            You will be logged out in {counter} sec
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            colour="white"
            onClick={() => close()}
            testSelector="carrier-details_add-docs_close_btn">
            Continue Session
          </Button>
          <Button
            onClick={() => logoutAction()}
            testSelector="carrier-details_add-docs_upload_btn">
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LogoutModal;
