import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import ServerPagination from 'components/shared/ServerPagination';
import TableList from 'components/shared/TableList';
import { convertTime } from 'utils/convertTime';
import statuses from 'utils/statuses';
import { findLongestWord } from 'utils/stringFunctions';
import { confirmAlert } from 'components/sweetalert';
import genericSorting from 'store/selectors/genericSorting';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import Badge from 'components/shared/Badge';
import ContestModal from 'components/shared/ContestModal';
import { approveOrder } from 'store/actions/Orders';
import Authorize from 'components/common/Authorize';
import CreateInvoiceModal from './CreateInvoiceModal';
import ReconcileSlidePanel from '../ReconcileSlidePanel';

const ReconcileOrderStatusList = ({
  orders,
  totalItems,
  approveOrderFunc,
  setOrdering,
  ordering,
  pagination,
  setPagination,
  user,
  equipmentRequired,
  getOrders
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [contestModal, setContestModal] = useState(false);
  const orderIds = checkedOrders.map(({ key }) => key);

  const sorting = key => {
    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const columns = [
    { key: 'order_id', value: 'ORDER' },
    { key: 'customer_name', value: 'CUSTOMER' },
    { key: 'customer_job_id', value: 'CUSTOMER JOB ID' },
    { key: 'job_name', value: 'JOB' },
    { key: 'carrier_name', value: 'CARRIER' },
    { key: 'driver_name', value: 'DRIVER' },
    { key: 'completed_at', value: 'COMPLETED' },
    { key: 'delivery_price', value: 'DELIVERY' },
    { key: 'additional_price', value: 'ADDITIONAL' },
    { key: 'totalPrice', value: 'TOTAL' },
    { key: 'billing_status', value: 'STATUS' },
  ];

  const csvHeaders = columns
    .concat([
      { key: 'formatted_status', value: 'STATUS DESC' }
    ])
    .map(c => {
      c.label = c.value;
      return c;
    });

  const csvOrders = orders?.length ? _.cloneDeep(orders).map(o => {
    if (
      o.billing_status < 20 &&
      o.billing.approvals.includes(user.company.roleId)
    ) {
      o.formatted_status = 'Approved';
    } else {
      o.formatted_status = statuses[
        o.billing_status >= 20
          ? o.billing_status
          : o.review_status
      ]
    }
    o.completed_at = convertTime(o.completed_at);
    o.customer_job_id = o.customer_job_id || o.po_reference
    return o;
  }) : [];

  const builtItems = orders.map(order => ({
    key: order.order_id,
    isSelected: selectedOrder && selectedOrder.order_id === order.order_id,
    onRowClick: () => setSelectedOrder(order),
    originalData: order,
    data: {
      order_id: () => `#${order.order_id}`,
      job_name: () => (
        <ToolTip
          title={findLongestWord(order.job_name) > 10 ? order.job_name : ''}
          placement="bottom">
          {`${findLongestWord(order.job_name) > 10
              ? order.job_name.substring(0, 10)
              : order.job_name
            }`}
          {findLongestWord(order.job_name) > 10 && '...'}
        </ToolTip>
      ),
      customer_name: () => (
        <ToolTip
          title={
            findLongestWord(order.customer_name) > 10 ? order.customer_name : ''
          }
          placement="bottom">
          {`${findLongestWord(order.customer_name) > 10
              ? order.customer_name.substring(0, 10)
              : order.customer_name
            }`}
          {findLongestWord(order.customer_name) > 10 && '...'}
        </ToolTip>
      ),
      customer_job_id: () => order.customer_job_id || order.po_reference,
      carrier_name: () => (
        <ToolTip
          title={
            findLongestWord(order.carrier_name) > 10 ? order.carrier_name : ''
          }
          placement="bottom">
          {`${findLongestWord(order.carrier_name) > 10
              ? order.carrier_name.substring(0, 10)
              : order.carrier_name
            }`}
          {findLongestWord(order.carrier_name) > 10 && '...'}
        </ToolTip>
      ),
      driver_name: () => order.driver_name,
      completed_at: () => convertTime(order.completed_at),
      delivery_price: () => order.delivery_price,
      additional_price: () => order.additional_price,
      totalPrice: () => order.totalPrice,
      billing_status: () => {
        if (
          order.billing_status < 20 &&
          order.billing.approvals.includes(user.company.roleId)
        ) {
          return <Badge className="badge badge--green">Approved</Badge>;
        }

        return (
          <Badge
            className={`badge badge--status-${order.billing_status >= 20
                ? order.billing_status
                : order.review_status
              }`}>
            {
              statuses[
              order.billing_status >= 20
                ? order.billing_status
                : order.review_status
              ]
            }
          </Badge>
        );
      },
    },
  }));

  const approveContest = param => {
    swal.close();
    setContestModal(param);
  };

  const actionOptions = [
    {
      name: 'approve',
      label: 'Approve',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(approveOrderFunc, orderIds, {
            title: `Are you sure you want to approve these ${orderIds.length} orders?`,
            text: ' ',
          });
        } else {
          approveOrderFunc(orderIds);
        }
      },
    },
    {
      name: 'contest',
      label: 'Contest',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(approveContest, true, {
            title: `Are you sure you want to contest these ${orderIds.length} orders?`,
            text: ' ',
          });
        } else {
          approveContest(true);
        }
      },
    },
    {
      name: 'create invoice',
      label: 'Create Invoice',
      onClick: () => setCreateInvoiceModal(true),
    },
  ];

  // const exportOptions = [
  //   {
  //     name: 'excel',
  //     label: 'Excel Workbook',
  //     onClick: () => console.log('Excel Workbook'),
  //     dropdownIcon: 'excel',
  //   },
  //   {
  //     name: 'csv',
  //     label: 'CSV File',
  //     onClick: () => console.log('CSV File'),
  //     dropdownIcon: 'csv',
  //   },
  //   {
  //     name: 'pdf',
  //     label: 'PDF Document',
  //     onClick: () => console.log('PDF Document'),
  //     dropdownIcon: 'pdf',
  //   },
  // ];

  return (
    <div>
      <div className="invoice-list__actions">
        <span>
          <strong>{checkedOrders.length}</strong> orders selected
        </span>
        <span className="invoice-list__actions--buttons">
          <CSVLink data={csvOrders} headers={csvHeaders} filename="order-status-list.csv">
            <Button
              theme="small"
              onClick={() => { }}>
              <Icon icon="download" />
            </Button>
          </CSVLink>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Reconcile',
                  permissions: ['update'],
                },
              ],
            }}>
            <DropdownButton
              theme="small"
              isDisabled={checkedOrders.length < 1}
              text="Actions"
              icon="down"
              options={actionOptions}
              noMargin
            />
          </Authorize>
          {/* TODO: Hidden until functionality is completed
          <ToolTip title="Export Selected..." placement="left">
            <DropdownButton
              onClick={() => {}}
              icon="export"
              className="button--small--square"
              options={exportOptions}
            />
          </ToolTip> */}
        </span>
      </div>
      <TableList
        onSort={sorting}
        checkboxColumn
        clickableTable
        onCheck={rows => setCheckedOrders(rows)}
        columns={columns}
        listItems={builtItems}
        sortedColumn="invoiceID"
        modifiers={['order-status']}
      />
      <ReconcileSlidePanel
        isVisible={!!selectedOrder}
        closePanel={() => setSelectedOrder(null)}
        page="orderStatus"
        selectedOrder={selectedOrder}
        equipmentRequired={equipmentRequired}
        getOrders={getOrders}
      />

      {orders && orders.length > 0 && (
        <ServerPagination
          pagination={pagination}
          onUpdate={setPagination}
          totalItems={totalItems}
        />
      )}
      <CreateInvoiceModal
        checkedOrders={checkedOrders}
        open={createInvoiceModal}
        onClose={() => setCreateInvoiceModal(false)}
        orderIds={orderIds}
      />
      <ContestModal
        open={contestModal}
        onClose={() => setContestModal(false)}
        orderIds={orderIds}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  orders: genericSorting(state.orders.collection, props),
  totalItems: state.orders.totalItems,
  user: state.lookUp.user,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  approveOrderFunc: orderId => dispatch(approveOrder(orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconcileOrderStatusList);
