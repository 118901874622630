import React, { useEffect,useState } from 'react';
import Tabs from 'components/shared/Tabs';
import Loader from 'components/shared/Loader';
import InvoiceBreakdown from './InvoiceBreakdown';
import InvoiceActivity from './InvoiceActivity';
import { authorize } from 'components/common/Authorize';

const InvoiceDetails = ({
  selectedInvoice,
  selectedInvoiceDetails,
  selectedInvoiceIsLoading,
  getInvoiceDetails,
  addComment,
}) => {
  const [currentTab, setCurrentTab] = useState('breakdown');
  useEffect(() => {
    if (selectedInvoice) {
      getInvoiceDetails(selectedInvoice.id);
    }
  }, [selectedInvoice]);

  const tabItems = [
    {
      defaultTab: currentTab === 'breakdown',
      label: 'Breakdown',
      content: selectedInvoice && (
        <InvoiceBreakdown
          setCurrentTab={setCurrentTab}
          customerInvoice={selectedInvoice}
          carrierInvoices={selectedInvoiceDetails}
        />
      ),
    },
  ];

  if (authorize({ access: ['JOB_MANAGER'] })) {
    tabItems.push({
      defaultTab: currentTab === 'activity',
      label: 'Activity',
      content: selectedInvoiceDetails && (
        <InvoiceActivity
          setCurrentTab={setCurrentTab}
          invoiceDetails={selectedInvoiceDetails}
          addComment={addComment}
        />
      ),
    });
  }

  return (selectedInvoiceIsLoading && <Loader />) || <Tabs tabs={tabItems} />;
};

export default InvoiceDetails;
