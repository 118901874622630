import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';

import Stats from './Stats';
import Tabs from './Tabs';

const Information = React.lazy(() => import('./Information'));

const CarrierDetails = () => {
  const params = useParams();
  const user = authService.getUser();

  // Carrier Id in params when not carrier, otherwise use user token
  const carrierId = user.role === ROLES.CARRIER ? user.entityId : params.carrierId;

  return (
    <div>
      <Header title="Carrier Details" />
      <Authorize
        abilityPermissions={[{ resource: 'Carriers', permissions: ['read'] }]}>
        <Stats carrierId={carrierId} />
        <Suspense fallback={<Loader />}>
          <Information carrierId={carrierId} />
        </Suspense>
        <Tabs carrierId={carrierId} />
      </Authorize>
    </div>
  );
};
export default CarrierDetails;
