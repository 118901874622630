import { combineReducers } from '@reduxjs/toolkit';

const jobPlansInit = {
  jobPlans: [],
  jobId: '',
  duration: '',
  tonsPerLoad: '',
  hoursPerShift: '',
  loadHistory: '',
  loadRefresh: '',
  jobPlanDetails: '',
  events: [],
  billableOrders: [],
  billableOrdersDetails: '',
  billingFrequencyDays: '',
  billingStartDate: '',
  billingTimeZone: '',
  isLoadingBillableOrders: false,
  isLoadingOrders: false,
  timeFrame: '',
  hidden: false,
};

const jobPlanInfo = (state = jobPlansInit, action) => {
  const eventPairs =
    action?.data?.turnData && action?.data?.turnData.length
      ? action.data.turnData.map(obj => ({
          start_status: obj.start_status,
          end_status: obj.end_status,
          step: obj.step,
        }))
      : [];

  switch (action.type) {
    case 'JOB_PLANS_RESPONSE_SUCCESS':
      return {
        ...state,
        jobPlans: action.data,
      };
    case 'JOB_PLANS_CLEAR':
      return {
        ...state,
        jobPlans: [],
      };
    case 'JOB_PLANS_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_PLAN_SET_HIDDEN':
      return {
        ...state,
        hidden: action.value,
      };
    case 'JOB_PLANS_CLEAR_MODAL':
      return {
        ...state,
        jobId: '',
        duration: '',
        tonsPerLoad: '',
        hoursPerShift: '',
        loadHistory: '',
        loadRefresh: '',
        billingFrequencyDays: '',
        billingStartDate: '',
        billingTimeZone: '',
        isLoadingBillableOrders: false,
        isLoadingOrders: false,
        timeFrame: '',
        hidden: false,
      };
    case 'JOB_PLANS_SET_DETAILS_VALUE':
      return {
        ...state,
        jobPlanDetails: action.data,
      };
    case 'JOB_PLANS_BILLABLE_ORDERS_SET':
      return {
        ...state,
        billableOrders: action.data,
      };
    case 'JOB_PLANS_BILLABLE_ORDERS_CALL':
      return {
        ...state,
        isLoadingBillableOrders: action.data,
      };
    case 'JOB_PLANS_ORDERS_CALL':
      return {
        ...state,
        isLoadingOrders: action.data,
      };
    case 'JOB_PLAN_SET_TIMEFRAME':
      return {
        ...state,
        timeFrame: action.value,
      };
    case 'JOB_PLANS_BILLABLE_ORDERS_DETAILS_SET':
      return {
        ...state,
        billableOrdersDetails: action.data,
      };
    case 'JOB_PLAN_CLEAR_DATA':
      return {
        ...state,
        jobPlanDetails: '',
      };

    default:
      return state;
  }
};
const jobPlansTurnTimeInit = {
  turnData: [],
  trucksRunningBreakdown: [],
  turnOrders: [],
  purchaseOrders: [],
  trucksDeficit: '',
  currentTrucksRunning: '',
  loadHistory: '',
  trucksNeededPerDay: '',
  historyLogs: {},
  turnsPerDay: '',
  totalTurnTime: '',
  refreshRate: '',
  turnFilterData: '',
  billableOrderCounts: {},
  posFilter: [],
  carrierFilter: [],
  driverFilter: [],
};

const jobPlanTurnTimeDetails = (state = jobPlansTurnTimeInit, action) => {
  switch (action.type) {
    case 'JOB_PLANS_TURN_ADD_DATA':
      return {
        ...state,
        turnData: action.data.turnData || [],
        trucksRunningBreakdown: action.data.trucksRunningBreakdown || [],
        turnOrders: action.data.turnOrders || [],
        purchaseOrders: action.data.purchaseOrders || [],
        trucksDeficit: action.data.trucksDeficit || '',
        currentTrucksRunning: action.data.currentTrucksRunning || '',
        loadHistory: action.data.loadHistory || '',
        trucksNeededPerDay: action.data.trucksNeededPerDay || '',
        historyLogs: action.data.historyLogs || {},
        turnsPerDay: action.data.turnsPerDay || '',
        totalTurnTime: action.data.totalTurnTime || '',
        refreshRate: action.data.refreshRate || '',
        turnFilterData: action.data.turnFilterData || '',
        billableOrderCounts: action.data.billableOrderCounts,
      };
    case 'JOB_PLAN_TURN_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_PLAN_TURN_CLEAR_DATA':
      return jobPlansTurnTimeInit;
    case 'JOB_PLAN_TURN_CLEAR_DATA_NOT_FILTERS':
      return {
        ...state,
        turnData: [],
        trucksRunningBreakdown: [],
        turnOrders: [],
        purchaseOrders: [],
        trucksDeficit: '',
        currentTrucksRunning: '',
        loadHistory: '',
        trucksNeededPerDay: '',
        historyLogs: {},
        turnsPerDay: '',
        totalTurnTime: '',
        refreshRate: '',
        turnFilterData: '',
      };
    default:
      return state;
  }
};

const jobPlansUpdateInit = {
  jobId: '',
  duration: '',
  tonsPerLoad: '',
  hoursPerShift: '',
  loadHistory: '',
  loadRefresh: '',
  targetStagesPerDay: '',
  tonsPerStage: '',
  tonsPerDay: '',
  events: [],
  billingFrequencyDays: '',
  billingStartDate: '',
  billingTimeZone: '',
};

const jobPlanUpdateInfo = (state = jobPlansUpdateInit, action) => {
  const eventPairs =
    action?.data?.turnData && action?.data?.turnData.length
      ? action.data.turnData.map(obj => ({
          start_status: obj.start_status,
          end_status: obj.end_status,
          step: obj.step,
        }))
      : [];

  switch (action.type) {
    case 'JOB_PLANS_UPDATE_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_PLANS_UPDATE_SET_DETAILS_VALUE':
      return {
        ...state,
        jobId: action?.data?.jobId || '',
        duration: Number(action?.data?.duration) || 0,
        tonsPerLoad: Number(action?.data?.tonsPerLoad) || 0,
        hoursPerShift: Number(action?.data?.hoursPerShift) || 0,
        loadHistory: Number(action?.data?.loadHistory) || 0,
        loadRefresh: Number(action?.data?.refreshRate) || 0, //loadRefresh
        targetStagesPerDay: Number(action?.data?.targetStagesPerDay) || '',
        tonsPerStage: Number(action?.data?.tonsPerStage) || '',
        tonsPerDay: Number(action?.data?.tonsPerDay) || '',
        events:
          action?.data?.turnData && action?.data?.turnData.length
            ? action.data.turnData.map(obj => ({
                startStatus: obj.start_status,
                endStatus: obj.end_status,
                step: obj.step,
              }))
            : [],
        billingFrequencyDays: Number(action?.data?.billingFrequencyDays) || 0,
        billingStartDate: action?.data?.billingStartDate || '',
        billingTimeZone: action?.data?.billingTimeZone || '',
      };
    case 'JOB_PLAN_UPDATE_CLEAR_DATA':
      return {
        ...state,
        jobId: '',
        duration: '',
        tonsPerLoad: '',
        hoursPerShift: '',
        loadHistory: '',
        loadRefresh: '',
        events: [],
        billingFrequencyDays: '',
        billingStartDate: '',
        billingTimeZone: '',
      };

    default:
      return state;
  }
};

export const jobPlans = combineReducers({
  jobPlanInfo,
  jobPlanUpdateInfo,
  jobPlanTurnTimeDetails,
});
