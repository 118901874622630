import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datetime';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import { connect } from 'react-redux';
import statuses from 'utils/statuses';
import Badge from 'components/shared/Badge';
import { newConvertTime } from 'utils/convertTime';
import { convertNumberToCurrency } from 'utils/convertNumberToCurrency';
import TableList from 'components/shared/TableList';
import { createInvoiceFromOrders } from 'store/actions/Orders';
import exportActions from 'store/actions/Export';
import moment from 'moment';
import Link from 'components/common/Link';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import { Modal, Row } from 'react-bootstrap';
import { modifyDueDate } from './helpers';

const CreateInvoiceModal = ({
  open,
  onClose,
  checkedOrders = [],
  setSortKey,
  sortKey,
  createInvoiceFromOrdersFunc,
  orderIds,
  user,
  templates = [],
  getLookUpTemplates,
  usedOutsideReconcile = false,
  customerId,
}) => {
  const [remitCheck, setRemitCheck] = useState(false);
  const [dueDate, setDueDate] = useState(moment());
  const [groupOrdersBy, setGroupOrdersBy] = useState(0);
  const [selectedTemplate, setTemplateId] = useState({
    label: 'Customer Default Template',
    value: 0,
  });
  const [isDateAlert, turnDateAlert] = useState(false);

  useEffect(() => {
    getLookUpTemplates();
  }, []);
  const sorting = key => setSortKey({ key, isAscending: !sortKey.isAscending });

  const allCustomerIds = checkedOrders.map(
    order => order.originalData.customer_id,
  );

  const handleCreateInvoice = () => {
    const templateId = selectedTemplate.value;
    createInvoiceFromOrdersFunc(
      orderIds,
      usedOutsideReconcile ? customerId : allCustomerIds[0],
      dueDate,
      remitCheck,
      groupOrdersBy,
      templateId,
      usedOutsideReconcile,
    );
    onClose();
  };

  // Creating ready & not-ready list of orders
  const readyForInvoice = [];
  const notReadyForInvoice = [];
  const allOrders = checkedOrders.map(order => {
    if (order.originalData.billing_status !== 20) {
      notReadyForInvoice.push(order);
    } else {
      readyForInvoice.push(order);
    }
    return order;
  });

  const columns = [
    { key: 'order_id', value: 'ORDER' },
    { key: 'date', value: 'DATE' },
    { key: 'job_name', value: 'JOB' },
    { key: 'customer_name', value: 'CUSTOMER' },
    { key: 'carrier_name', value: 'CARRIER' },
    { key: 'totalPrice', value: 'TOTAL' },
    { key: 'billing_status', value: 'STATUS' },
  ];

  const orderItemsNotReady = notReadyForInvoice.map(order => ({
    key: order.originalData.order_id,
    data: {
      order_id: () => (
        <span>
          #
          <Link id={order.originalData.order_id} to="order-details">
            {order.originalData.order_id}
          </Link>
        </span>
      ),
      date: () => newConvertTime(order.originalData.date),
      job_name: () => order.originalData.job_name,
      customer_name: () => order.originalData.customer_name,
      carrier_name: () => order.originalData.carrier_name,
      totalPrice: () => convertNumberToCurrency(order.originalData.totalPrice),
      billing_status: () => {
        if (
          order.originalData.billing_status < 20 &&
          order.originalData.billing.approvals.includes(user.company.roleId)
        ) {
          return <Badge className="badge badge--green">Approved</Badge>;
        }

        return (
          <Badge
            className={`badge badge--status-${order.originalData.billing_status >= 20
                ? order.originalData.billing_status
                : order.originalData.review_status
              }`}>
            {
              statuses[
              order.originalData.billing_status >= 20
                ? order.originalData.billing_status
                : order.originalData.review_status
              ]
            }
          </Badge>
        );
      },
    },
  }));

  const templateValue = templates.map(item => ({
    label: item.description,
    value: item.id,
  }));

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title as="h3">Create Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-invoice-modal">
          <div className="create-invoice-modal__form">
            <div className="create-invoice-modal__form__select">
              <Select
                label="Group By"
                onChange={item => setGroupOrdersBy(item.value)}
                options={[{ label: 'jobs', value: 1 }]}
                testSelector="reconcile_order-status_create-invoice_group-by_select"
              />
              <Select
                label="Template"
                onChange={item => setTemplateId(item)}
                options={templateValue}
                value={selectedTemplate}
                testSelector="reconcile_order-status_create-invoice_template_select"
              />
              {isDateAlert && (
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>* Incorrect date format</p>
                </div>
              )}
              <Row>
                <div
                  className="form-group has-feedback col-md-12"
                  style={{ padding: 0, marginBottom: 0 }}>
                  <label className="small font-noraml">Due Date</label>
                  <DatePicker
                    value={dueDate}
                    onChange={date => {
                      isDateFormatValid(date, turnDateAlert);
                      setDueDate(date);
                    }}
                    className="form-input__calendar"
                    timeFormat="hh:mm A"
                    placeholderText="Due Date"
                    data-testid="reconcile_order-status_create-invoice_due-date_dp"
                    id="reconcile_order-status_create-invoice_due-date_dp"
                    name="reconcile_order-status_create-invoice_due-date_dp"
                  />
                </div>
                <Button
                  style={{ marginLeft: 0 }}
                  onClick={() => {
                    setDueDate(modifyDueDate(dueDate, 'add', 30));
                  }}
                  testSelector="reconcile_order-status_create-invoice_plus30_btn">
                  +30
                </Button>
                <Button
                  onClick={() => {
                    setDueDate(modifyDueDate(dueDate, 'add', 15));
                  }}
                  testSelector="reconcile_order-status_create-invoice_plus15_btn">
                  +15
                </Button>
                <Button
                  onClick={() => {
                    setDueDate(modifyDueDate(dueDate, 'add', 1));
                  }}
                  testSelector="reconcile_order-status_create-invoice_plus1_btn">
                  +1
                </Button>
                <Button
                  onClick={() => {
                    setDueDate(modifyDueDate(dueDate, 'subtract', 1));
                  }}
                  testSelector="reconcile_order-status_create-invoice_minus1_btn">
                  -1
                </Button>
              </Row>
              <Input
                type="checkbox"
                testSelector="reconcile_order-status_create-invoice_remit-to_input"
                label="Enable remit to"
                onChange={() => setRemitCheck(!remitCheck)}
                isChecked={remitCheck}
              />
            </div>
          </div>
          {notReadyForInvoice.length > 0 ? (
            <>
              <p>
                <strong>{notReadyForInvoice.length}</strong> of{' '}
                <strong>
                  {usedOutsideReconcile
                    ? orderIds.length
                    : checkedOrders.length}
                </strong>{' '}
                invoice(s) not selected.
              </p>
              <TableList
                onSort={sorting}
                columns={columns}
                listItems={orderItemsNotReady}
                modifiers={['order-status']}
              />
            </>
          ) : (
            <p>
              <strong>
                {usedOutsideReconcile ? orderIds.length : checkedOrders.length}
              </strong>{' '}
              of{' '}
              <strong>
                {usedOutsideReconcile ? orderIds.length : checkedOrders.length}
              </strong>{' '}
              invoice(s) selected.
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onClose}
          testSelector="reconcile_order-status_create-invoice_cancel_btn">
          Cancel
        </Button>
        <Button
          inverse
          disabled={isDateAlert}
          testSelector="reconcile_order-status_create-invoice_create_btn"
          onClick={() => handleCreateInvoice()}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  user: state.lookUp.user,
  templates: [
    { description: 'Customer Default Template', id: 0 },
    ...state.exportPage.templates.lookUpTemplates,
  ],
});

const mapDispatchToProps = dispatch => ({
  getLookUpTemplates: () => dispatch(exportActions.getLookUpTemplates()),
  createInvoiceFromOrdersFunc: (
    orderIds,
    customerId,
    dueDate,
    enableRemitTo,
    groupOrdersBy,
    templateId,
    usedOutsideReconcile,
  ) =>
    dispatch(
      createInvoiceFromOrders(
        orderIds,
        customerId,
        dueDate,
        enableRemitTo,
        groupOrdersBy,
        templateId,
        usedOutsideReconcile,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateInvoiceModal);
