import moment from 'moment';
import { combineReducers } from '@reduxjs/toolkit';

const initCompleteStageModal = {
  openModal: false,
  editStage: [{}],
  stageId: '',
  completeStage: moment().add(1, 'minute'),
  stageStart: moment().add(1, 'minute'),
};

export const completeStage = (state = initCompleteStageModal, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_STAGES_COMPLETE_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_STAGES_INIT_COMPLETE':
      return {
        ...state,
        stageId: action.stage.stageId,
        completeStage: action.stage.completedAt
          ? moment(action.stage.completedAt)
          : moment().add(1, 'minute'),
        stageStart: action.stage.startedAt
          ? moment(action.stage.startedAt)
          : moment().add(1, 'minute'),
      };
    case 'JOB_DETAILS_STAGES_COMPLETE_GET_CONFIG_SUCCESS':
      return {
        ...state,
        editStage: action.data,
      };
    case 'JOB_DETAILS_STAGE_COMPLETE_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_DETAILS_STAGES_COMPLETE_ADD_SAND':
      return {
        ...state,
        editStage: action.array,
      };
    case 'JOB_DETAILS_STAGES_COMPLETE_SAVE_SUCCESS':
      return initCompleteStageModal;
    case 'JOB_DETAILS_STAGES_COMPLETE_CLEAR_MODAL':
      return initCompleteStageModal;
    default:
      return state;
  }
};

export const stages = combineReducers({
  completeStage,
});
