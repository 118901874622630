import React, { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';
import { ModalForm } from 'components/shared/Modal';
import { authorize } from 'components/common/Authorize';
import SlidePanel from 'components/shared/SlidePanel';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import { TextInput } from 'components/shared/Input';
import { deleteAlert } from 'components/sweetalert';
import AddEditRoleRow from 'components/views/Roles/AddEditRoleRow';
import {
  updateRoleDetailsReducer,
  UPDATE_ROLE_ACTIONS,
} from 'components/views/Roles/AddEditRole/helpers';
import { getSelectedGrants } from 'components/views/Roles/RolesPage/helpers';

import { deleteRoleAction, saveChangesAction } from 'store/actions/Roles';

// The same as the api to validate changes to
const inputDefaultValues = {
  role: '',
  permissions: [],
};

const AddEditRole = props => {
  const {
    panelOpen,
    setPanelOpen,
    editRole,
    setEditRole,
    permissionData: { permissions: permissionList },
    deleteRole,
    saveChanges,
  } = props;

  const [disabledSave, setDisabledSave] = useState(true);
  const [roleDetails, setRoleDetails] = useReducer(
    updateRoleDetailsReducer,
    inputDefaultValues,
  );

  const { role } = roleDetails;

  // Resets to initial data
  const resetToInitStates = () => {
    setEditRole(undefined);
    setRoleDetails(inputDefaultValues);
    setPanelOpen(false);
  };

  useEffect(() => {
    if (editRole) {
      // set role name/role as role
      const data = {
        rolePermId: editRole._id,
        rtpId: editRole.rtpId,
        roleId: editRole.roleId,
        ...editRole.role,
        role: editRole.role.role,
        permissions: editRole.permissions
          ? editRole.permissions.map(mDataPerm => {
              return {
                pid: mDataPerm.pid._id,
                grants: mDataPerm.grants.map(mDataGrant => mDataGrant._id),
              };
            })
          : [],
      };

      setRoleDetails({
        type: UPDATE_ROLE_ACTIONS.EDIT_ROLE,
        data,
      });
    }
  }, [editRole]);

  // Determines if save should be disabled
  // runs each time roleDetails and selectedPermissions is updated
  useEffect(() => {
    // TODO: new api broke this, save should be disabled if nothing has been changed
    if (editRole) {
      if (
        roleDetails.role === editRole.role.role &&
        _isEqual(roleDetails.permissions, editRole.permissions)
      ) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
    } else if (roleDetails.role === 0 || roleDetails.permissions.length === 0) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [roleDetails]);

  // TODO: api needs to be built to have suggested roles
  const dropdownOpts = [];

  // Add delete role if editing a role
  if (editRole) {
    dropdownOpts.push({
      name: 'opt2',
      label: `Delete ${editRole.role.role} role`,
      permissions: { Users: 'delete' },
      onClick: () => {
        deleteAlert(deleteRole, editRole, resetToInitStates, null, {
          title: `Delete ${editRole.role.role} role`,
          text: `Are you sure you want to delete the ${
            editRole.role.role
          } role`,
          confirmText: 'Proceed',
        });
      },
      alert: true,
    });
  }

  // Input Updates
  const changeRoleTitle = data => {
    const { value } = data.target;

    setRoleDetails({
      type: UPDATE_ROLE_ACTIONS.UPDATE_TITLE,
      data: value,
    });
  };

  // Checkbox Updates
  const checkboxUpdate = (selectedCheckboxes, permData) => {
    let tempSelectedPermissions = [...roleDetails.permissions];

    // If all are selected set to [5], else map through for values
    const selectedGrants = getSelectedGrants(
      selectedCheckboxes,
      permData.grants,
    );

    // Find if permission is already been selected
    const findSelectedPermission = tempSelectedPermissions.some(
      fData => fData.pid === permData._id,
    );

    // If permission is found in list
    if (findSelectedPermission) {
      if (selectedCheckboxes.length === 0) {
        // If permission selected grants are empty remove permission
        _remove(tempSelectedPermissions, rData => rData.pid === permData._id);
      } else {
        // Modify
        tempSelectedPermissions = roleDetails.permissions.map(mData => {
          if (mData.pid === permData._id) {
            return {
              ...mData,
              grants: selectedGrants,
            };
          }
          return mData;
        });
      }
    } else {
      // Permission not found, add to list
      tempSelectedPermissions.push({
        pid: permData._id,
        grants: selectedGrants,
      });
    }

    setRoleDetails({
      type: UPDATE_ROLE_ACTIONS.UPDATE_PERMISSIONS,
      data: tempSelectedPermissions,
    });
  };

  return (
    <SlidePanel isVisible={panelOpen} closePanel={resetToInitStates}>
      <div className="add-edit-role">
        <div
          className="add-edit-role__header"
          data-testid="add-edit-role-header">
          <div className="add-edit-role__header__title">
            {`${editRole ? 'Modify Role' : 'Add Role'}`}
          </div>
          {/* Dropdown with list of templates and option to delete, then they can modify from there */}
          {editRole &&
            authorize({
              abilityPermissions: [
                { resource: 'Roles', permissions: ['delete'] },
              ],
            }) && (
              <DropdownButton
                testSelector="roles_add-edit_roles_dropdown-btn"
                text="Options"
                options={dropdownOpts}
              />
            )}
        </div>
        <div className="add-edit-role__inputs">
          <ModalForm>
            <TextInput
              id="role"
              label="Role Title"
              onChange={changeRoleTitle}
              testSelector="add-edit-update-title"
              value={role}
              required
              noMargin
            />
          </ModalForm>
        </div>
        <div className="add-edit-role__body">
          <div className="add-edit-role__permissions">
            {permissionList &&
              permissionList.map(permData => (
                <AddEditRoleRow
                  checkboxUpdate={checkboxUpdate}
                  editRole={editRole}
                  grants={permData.grants}
                  key={permData.permission}
                  permData={permData}
                />
              ))}
          </div>
        </div>
        <div className="add-edit-role__footer">
          <Button
            testSelector="roles_add-edit_cancel_btn"
            onClick={resetToInitStates}>
            Cancel
          </Button>
          <Button
            inverse
            disabled={disabledSave}
            onClick={() =>
              saveChanges(editRole, roleDetails, resetToInitStates)
            }
            testSelector="roles_add-edit_save_btn">
            Save
          </Button>
        </div>
      </div>
    </SlidePanel>
  );
};

const mapDispatchToProps = dispatch => ({
  deleteRole: (role, resetToInitStates) =>
    dispatch(deleteRoleAction(role, resetToInitStates)),
  saveChanges: (editRole, roleDetails, resetToInitStates) =>
    dispatch(saveChangesAction(editRole, roleDetails, resetToInitStates)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddEditRole);
