import React from 'react';
import Link from 'components/common/Link';

const DriversTabContent = ({ district: info, tab }) => (
  <div className="col-md-12">
    {info[tab].length === 0 ? (
      <div className="row no-data-tab">
        <p>No active Drivers</p>
      </div>
    ) : (
      <table id="datatable_queu" className="table table--hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Carrier</th>
            <th>Name</th>
            <th>On Order</th>
          </tr>
        </thead>
        <tbody>
          {info[tab].map((data, index) => (
            <tr key={`review${data.order_id}_${index}`}>
              <td style={{ cursor: 'pointer' }}>
                <Link {...{ id: data.id, to: 'driver-details' }}>
                  {data.id}
                </Link>
              </td>
              <td>
                {
                  info.activeOrders.filter(e => e.driver_id == data.id)[0]
                    .name
                }
              </td>
              <td>{data.name}</td>
              <td>{data.order_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default DriversTabContent;
