import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import Authorize from 'components/common/Authorize';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Badge from 'components/shared/Badge';
import ContestModal from 'components/shared/ContestModal';
import { orderStatus } from 'components/globalInfo/orderStatus';
import { billingStatus as billingStatusMap } from 'components/globalInfo/billingStatus';
import ordersReviewStatus from 'components/globalInfo/ordersReviewStatus';
import OrderActions from 'components/views/OrderActions';
import { useJobDetails } from 'api/v2/jobs';
import ModalHistory from './HistoryModal';
import DivertedModal from './DivertedModal';

const OrderHeader = ({ order, approveOrder, refreshOrder, user }) => {
  const { data: jobDetails } = useJobDetails({ jobId: order.jobId });
  const details = useMemo(() => jobDetails?.details ?? {}, [jobDetails]);

  const [showHistory, turnHistory] = useState(false);
  const [showDiverted, setDivertedStatus] = useState(false);
  const [showContest, turnContest] = useState(false);
  const {
    order_id: orderId,
    status,
    manual_dispatch_enabled: manualDispatchEnabled,
    review_status: reviewStatus,
    stage,
    customer_order_id: customerOrderId,
    isTransferred,
    isDiverted,
    operation_type: operationType,
    billing_status: billingStatus,
    billing,
    is_deleted: isDeleted,
  } = order;

  const statusOptions = [
    {
      name: 'history',
      label: 'History',
      onClick: () => turnHistory(true),
    },
  ];

  if (
    billingStatus <= 20 &&
    billing.approvalsRequired.includes(user.company.roleId)
  ) {
    if (
      reviewStatus !== 2 &&
      billing.approvalsRequired.includes(user.company.roleId)
    ) {
      statusOptions.unshift({
        name: 'approve',
        label: 'Approve',
        onClick: () => {
          approveOrder(refreshOrder);
        },
      });
    }
    if (reviewStatus !== 4) {
      statusOptions.unshift({
        name: 'contest',
        label: 'Contest',
        onClick: () => turnContest(true),
      });
    }
  }

  return (
    <div className="flex space-between">
      {showHistory && (
        <ModalHistory
          showHistory={showHistory}
          closeHistory={() => turnHistory(false)}
        />
      )}
      {showDiverted && (
        <DivertedModal
          showDiverted={showDiverted}
          orderId={orderId}
          setDivertedStatus={setDivertedStatus}
        />
      )}
      {showContest && (
        <ContestModal
          open={showContest}
          onClose={() => turnContest(false)}
          orderIds={[orderId]}
          updateInit={refreshOrder}
        />
      )}

      <span className="col-lg-6">
        <span className="color-primary">
          # {orderId} / {customerOrderId || 'undefined'}
        </span>
        {operationType !== 12 && <span>- Stage {stage || 'unknown'}</span>}
        <Badge type="success">
          {orderStatus[status] ? orderStatus[status].name : ''}
        </Badge>
        {!!manualDispatchEnabled && <Badge type="primary">Manual</Badge>}
        {!!order.is_deleted && <Badge type="red">Deleted</Badge>}
        {order.truck_status === 1 && <Badge type="red">Truck Down</Badge>}
        {!!order.isAuto && <Badge type="info">Auto Order</Badge>}
        {order.billing_status === 24 && <Badge type="red">Non-Billable</Badge>}
      </span>
      <span className="flex space-between direction-column col-lg-6">
        <div className="ibox-tools margin5">
          {!!isTransferred && <Badge type="success">Transferred</Badge>}
          <Authorize
            abilityPermissions={[
              { resource: 'Orders', permissions: ['update'] },
            ]}>
            {!isDeleted && !!isDiverted && (
              <Button
                colour="orange"
                theme="small"
                className="button--no-margin-bottom"
                testSelector="order-details_order-header_diverted_btn"
                onClick={() => setDivertedStatus(true)}>
                Diverted
              </Button>
            )}
            <OrderActions
              details={details}
              order={order}
              exportComponent={{
                modals: true,
                actions: true,
              }}
            />
            {!isDeleted && status === 4 && (
              <DropdownButton
                theme="small"
                text={
                  billingStatus < 20
                    ? ordersReviewStatus[reviewStatus].title
                    : billingStatusMap[billingStatus].text
                }
                options={statusOptions}
                className={`btn-${
                  ordersReviewStatus[reviewStatus].btnColor
                } button--no-margin-bottom`}
                icon="down"
              />
            )}
          </Authorize>
          <Button
            onClick={refreshOrder}
            className="button--small--square button--no-margin-bottom"
            testSelector="order-details_order-header_refresh_btn">
            <Icon icon="refresh" />
          </Button>
        </div>
      </span>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.lookUp.user,
});

const mapDispatchToProps = dispatch => ({
  markAsReviewed: () => dispatch(actions.markAsReviewed()),
  approveOrder: () =>
    dispatch(actions.approveOrderMethod()).then(() =>
      dispatch(actions.refreshOrder()),
    ),
  refreshOrder: () => dispatch(actions.refreshOrder()),
  getTransferred: () => dispatch(actions.getTransferred()),
  clearTransferred: () => dispatch(actions.clearTransferred()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderHeader);
