import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import authService from 'services/auth/service';

export const useCustomerId = () => {
  const params = useParams();

  const customerId = useMemo(() => {
    if (params.customerId) {
      return Number(params.customerId);
    }

    const user = authService.getUser();

    if (user.role === 2) {
      return Number(user.entityId);
    }

    return null;
  }, [params]);

  return customerId;
}
