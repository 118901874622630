import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import Block from 'components/shared/Block';
import Loader from 'components/shared/Loader';
import { ReconcileContext } from '../index';
import ReconcileBaseFilter from '../ReconcileBaseFilter';
import ReconcileActionItemsList from './ReconcileActionItemsList';

const basePayload = {
  statuses: [4],
  excludeCustomerOperatedOrders: true,
  isInReconcile: true,
  requiredReview: true,
  sortBy: 'order_id DESC',
  page: 1,
  itemsPerPage: 10,
  billingStatuses: [0],
  startDate: moment()
    .subtract(3, 'M')
    .startOf('day')
    .utc()
    .format(),
  endDate: moment()
    .endOf('day')
    .utc()
    .format(),
};

const ReconcileActionItems = ({ getOrders, isLoading }) => {
  const {
    filters,
    setFilters,
    ordering,
    setOrdering,
    setPagination,
    pagination,
  } = useContext(ReconcileContext);

  const [requestPayload, setRequestPayload] = useState(null);

  const setRequestFilters = requestFilters => {
    setPagination({ page: 1, itemsPerPage: pagination.itemsPerPage });
    return setRequestPayload({
      ...basePayload,
      ...requestFilters,
      ...pagination,
      page: 1,
    });
  };

  const setRequestPagination = paginationData => {
    const match = Object.keys(pagination).every(key => {
      if (!paginationData[key]) {
        return false;
      }

      if (paginationData[key] === pagination[key]) {
        return true;
      }

      return false;
    });

    if (!match) {
      setPagination(paginationData);
      if (requestPayload) {
        return setRequestPayload({ ...requestPayload, ...paginationData });
      }

      return setRequestPayload({ ...basePayload, ...paginationData });
    }
  };

  useEffect(() => {
    setFilters([
      {
        key: 'from-completed_at',
        label: 'Completed From',
        onFilter: null,
        type: 'range',
        value: moment()
          .subtract(3, 'M')
          .startOf('day'),
      },
      {
        key: 'to-completed_at',
        label: 'Completed To',
        onFilter: null,
        type: 'range',
        value: moment()
          .endOf('day'),
      },
    ]);
    setOrdering({ key: 'order_id', isAscending: false });
    setPagination({ page: 1, itemsPerPage: 10 });
  }, []);

  useEffect(() => {
    getOrders(basePayload);
  }, []);

  useEffect(() => {
    if (requestPayload) {
      const formattedPayload = { ...requestPayload};
      if (moment(formattedPayload.startDate).isValid()) {
        formattedPayload.startDate = moment(formattedPayload.startDate)
          .utc()
          .format();
      }
      if (moment(formattedPayload.endDate).isValid()) {
        formattedPayload.endDate = moment(formattedPayload.endDate)
          .utc()
          .format();
      }
      getOrders(formattedPayload);
    }
  }, [requestPayload]);

  return (
    <Block theme="none" modifiers={['no-border']}>
      <ReconcileBaseFilter
        view="action_items"
        filters={filters}
        setFilters={setFilters}
        defaultFilters={[
          {
            key: 'from-completed_at',
            label: 'Completed From',
            onFilter: null,
            type: 'range',
            value: moment()
              .subtract(3, 'M')
              .startOf('day'),
          },
          {
            key: 'to-completed_at',
            label: 'Completed To',
            onFilter: null,
            type: 'range',
            value: moment()
              .endOf('day'),
          },
        ]}
        setRequestFilters={setRequestFilters}
      />
      {(isLoading && <Loader />) || (
        <ReconcileActionItemsList
          filters={filters}
          ordering={ordering}
          pagination={pagination}
          setOrdering={setOrdering}
          setPagination={setRequestPagination}
          getOrders={() =>
            getOrders(requestPayload ? requestPayload : basePayload)
          }
        />
      )}
    </Block>
  );
};

export default ReconcileActionItems;
