import React from 'react';

import Pagination from './Pagination';

const ServerPagination = ({
  onUpdate,
  pagination,
  totalItems,
  useLimit = false,
}) => (
    <Pagination
      currentPage={pagination.page}
      totalPages={
        Math.ceil(
          totalItems / (useLimit ? pagination.limit : pagination.itemsPerPage),
        ) || 1
      }
      onPageChange={({ currentPage }) =>
        onUpdate({
          page: currentPage,
          itemsPerPage: useLimit ? pagination.limit : pagination.itemsPerPage,
        })
      }
      onLimitChange={limit => onUpdate({ page: 1, itemsPerPage: limit })}
      currentLimit={useLimit ? pagination.limit : pagination.itemsPerPage}
      withLimit
    />
  );

export default ServerPagination;
