import React from 'react';
import moment from 'moment';
import Link from 'components/common/Link';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import topHeaderActions from 'components/common/Header/actions';
import EventItem from './EventItem';

const Item = ({ event }) => {
  const {
    carrierName,
    driverId,
    driverName,
    occuredAt,
    orderId,
    truckNumber,
    type,
    eventMessage,
  } = event;
  const time = moment(occuredAt).fromNow();
  const orderLink = (
    <Link {...{ id: orderId, to: `order-details` }}>{orderId}</Link>
  );
  const driverChatLink = (
    <Link {...{ id: driverId, to: `chat` }}>{driverName}</Link>
  );

  switch (type) {
    case 'order_created': {
      return (
        <EventItem
          badgeColor="purple"
          icon="star"
          badgeText="New Order"
          orderLink={orderLink}
          messageEnd="was created"
          time={time}
        />
      );
    }
    case 'new_order_message': {
      return (
        <EventItem
          badgeColor="orange"
          icon="star"
          badgeText="New message in order"
          orderLink={orderLink}
          messageEnd="added"
          time={time}
        />
      );
    }
    case 'new_driver_message': {
      return (
        <EventItem
          badgeColor="purple"
          icon="star"
          badgeText="New message in driver's chat"
          messageBegin={driverChatLink}
          messageEnd="added"
          time={time}
        />
      );
    }
    case 'order_assigned': {
      return (
        <EventItem
          badgeColor="blue"
          icon="dot-circle-o"
          badgeText="Assigned Order"
          orderLink={orderLink}
          messageBegin="was assigned to"
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'order_accepted': {
      return (
        <EventItem
          badgeColor="green"
          icon="thumbs-up"
          badgeText="Accepted Order"
          orderLink={orderLink}
          messageBegin="was accepted by"
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'order_rejected': {
      return (
        <EventItem
          badgeColor="red"
          icon="thumbs-down"
          badgeText="Rejected Order"
          orderLink={orderLink}
          messageBegin="was rejected by"
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'order_completed': {
      return (
        <EventItem
          badgeColor="green"
          icon="check"
          badgeText="Completed Order"
          orderLink={orderLink}
          messageBegin="was completed by"
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_entering_sandsite': {
      return (
        <EventItem
          badgeColor="orange"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver entered origin for order."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_call_failed': {
      return (
        <EventItem
          badgeColor="orange"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver has been called for being inactive and call failed."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_call_busy': {
      return (
        <EventItem
          badgeColor="orange"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver has been called for being inactive and call status is busy."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_call_completed': {
      return (
        <EventItem
          badgeColor="green"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver has been called for being inactive and call completed."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_departing_sandsite': {
      return (
        <EventItem
          badgeColor="blue"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver departed origin for order."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'driver_entering_wellsite': {
      return (
        <EventItem
          badgeColor="blue"
          icon="location-arrow"
          badgeText="Tracking"
          orderLink={orderLink}
          messageBegin="Driver entered origin for order."
          driver={`${driverName} (#${driverId}, ${carrierName}, ${truckNumber})`}
          time={time}
        />
      );
    }
    case 'carrier_notification_message': {
      return (
        <EventItem
          badgeColor="blue"
          icon="dot-circle-o"
          badgeText="Notification Alert"
          messageBegin={eventMessage}
          time={time}
        />
      );
    }
    case 'invoice_complete_message': {
      return (
        <EventItem
          badgeColor="blue"
          icon="dot-circle-o"
          badgeText="Notification Alert"
          messageBegin={eventMessage}
          time={time}
        />
      );
    }
    case 'invoice_failed_message': {
      return (
        <EventItem
          badgeColor="red"
          icon="dot-circle-o"
          badgeText="Notification Alert"
          messageBegin={eventMessage}
          time={time}
        />
      );
    }
    case 'notarization_failed': {
      return (
        <EventItem
          badgeColor="red"
          icon="dot-circle-o"
          badgeText="Notification Alert"
          messageBegin={eventMessage}
          orderLink={orderLink}
          time={time}
        />
      );
    }
    default:
      return null;
  }
};

const Content = ({ setSearch, filteredEvents }) => {
  const match = useRouteMatch();
  return filteredEvents.length !== 0 ? (
    filteredEvents.map(event => (
      <Item event={event} match={match} setSearch={setSearch} key={event.id} />
    ))
  ) : (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      You haven&apos;t notification yet
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setSearch: orderId => dispatch(topHeaderActions.setSearchValue(orderId)),
});

export default connect(
  () => ({}),
  mapDispatchToProps,
)(Content);
