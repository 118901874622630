import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import PropTypes from 'prop-types';
import noAvatar from '../../../../public/img/no_avatar.jpg';
import authService from 'services/auth/service'

const token = authService.getToken();

const DriverGridCard = ({
  id,
  icon,
  title,
  subTitle,
  badge,
  body,
  actions,
  bodyColumns,
  bodyTabletColumns,
  toggle,
  toggleBody,
  status,
}) => {
  const [showToggleBody, setShowToggleBody] = useState(!toggle);

  return (
    <div className="driver-grid-card">
      <div className="ibox-tools" style={{ float: 'right' }}>
        {actions && (
          <div
            className="driver-grid-card__container-wrapper__head__actions"
            data-testid={`driver-grid-card_head_actions-${id}`}>
            {actions}
          </div>
        )}
      </div>
      <div className="driver-grid-card__container-wrapper">
        <div className="driver-grid-card__container-wrapper__icon-container">
          <div className="icon-square">
            <img
              alt="profile-photo"
              className="driver-grid-card__container-wrapper__icon-container--icon"
              src={icon ? `${icon}?token=${token}` : noAvatar}
              onError={e => {
                e.target.onerror = null;
                e.target.src = noAvatar;
              }}
            />
            {status && (
              <span className="driver-grid-card__container-wrapper__icon-container--status">
                {status}
              </span>
            )}
          </div>
          {badge && (
            <div className="driver-grid-card__container-wrapper__badges">
              {badge}
            </div>
          )}
        </div>

        <div className="driver-grid-card__container-wrapper__head">
          <div className="driver-grid-card__container-wrapper__head__title">
            {title}
          </div>
          {subTitle && (
            <div className="driver-grid-card__container-wrapper__head__subtitle">
              {subTitle}
            </div>
          )}
        </div>
        <div className="driver-grid-card__container-wrapper__body">
          <div className="driver-grid-card__container-wrapper__body__content-wrapper">
            <div
              className={`driver-grid-card__container-wrapper__body__content 
              ${
                bodyTabletColumns
                  ? `driver-grid-card__container-wrapper__body__content--tablet-columns-${bodyTabletColumns}`
                  : ''
              }
              ${
                bodyColumns
                  ? `driver-grid-card__container-wrapper__body__content--columns-${bodyColumns}`
                  : ''
              }`}>
              {body}
            </div>
          </div>
        </div>
      </div>

      <div className="driver-grid-card__more">
        <Button
          theme="borderless"
          testSelector="driver-card_toggle-body_btn"
          colour="white"
          onClick={() => setShowToggleBody(!showToggleBody)}>
          <Icon icon="more" />
        </Button>
      </div>

      {toggle && (
        <div
          className={`driver-grid-card__toogle-body 
          ${
            showToggleBody
              ? 'driver-grid-card__toogle-body--toggle open'
              : 'driver-grid-card__toogle-body--toggle'
          }
        `}>
          <div className="driver-grid-card__toogle-body__content-wrapper">
            {toggle && <div className="card__spacer" />}
            <div className="driver-grid-card__toogle-body__content">
              {toggleBody}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DriverGridCard.propTypes = {
  /** String to show an icon on left side */
  icon: PropTypes.string,
  /** Boolean to show toggle (will turn off icon) */
  toggle: PropTypes.bool,
  /** Title area */
  title: PropTypes.node.isRequired,
  /** Subtitle area */
  subTitle: PropTypes.node,
  /** Badge area */
  badge: PropTypes.node,
  /** Body area */
  body: PropTypes.node.isRequired,
  /** Toggle body area */
  toggleBody: PropTypes.node,
  /** Actions area */
  actions: PropTypes.node,
  /** Status icon */
  status: PropTypes.node,
  /** Number of body columns on desktop view */
  bodyColumns: PropTypes.number,
  /** Number of body columns on Tablet view */
  bodyTabletColumns: PropTypes.number,
};

DriverGridCard.defaultProps = {
  icon: null,
  toggle: false,
  subTitle: null,
  badge: null,
  actions: null,
  status: null,
  bodyColumns: 2,
  bodyTabletColumns: 2,
};

export default DriverGridCard;
