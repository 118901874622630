import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components/shared/Input';
import Icon from 'components/shared/Icon';

const TableListHeader = ({
  columns,
  columnCount,
  selectAll,
  isChecked,
  checkboxColumn,
  className,
  onSort,
  sortedColumn,
}) => {

  const onItemSort = (key) => {
    if(onSort){
      onSort(key);
    }
  };

  return (
    columns.length > 0 && (
      <div
        className={`table-list__header-row ${(className &&
          `table-list__header-row--${className}`) ||
          ''}`}
        style={{
          gridTemplateColumns: `repeat(${columnCount}, calc(100% / ${columnCount}))`,
        }}>
        {(checkboxColumn && (
          <span className="table-list__header-item table-list__header-item--checkbox">
            <Checkbox
              noMargin
              name="checkboxColumn"
              onChange={selectAll}
              isChecked={isChecked}
              testSelector='table-list__header-item'
            />
          </span>
        )) ||
          ''}
        {columns.map(item => (
          <span
            onClick={() => onItemSort(item.key)}
            className={`table-list__header-item table-list__header-item--${
              item.key
          } ${(sortedColumn === item.key &&
              'table-list__header-item--sorted') ||
              ''}`}
            key={item.key}
          >
            {item.value} <Icon icon="sort_arrow" className="table-list__header-item__sort-arrow" />
          </span>
        ))}
      </div>
    )
  );
};

TableListHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  columnCount: PropTypes.number,
  selectAll: PropTypes.func,
  isChecked: PropTypes.bool,
  checkboxColumn: PropTypes.bool,
  className: PropTypes.string,
  sortedColumn: PropTypes.string,
};

TableListHeader.defaultProps = {
  columns: [],
  columnCount: 0,
  selectAll: () => {},
  isChecked: false,
  checkboxColumn: false,
  className: '',
  sortedColumn: '',
};

export default TableListHeader;
