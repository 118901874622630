import moment from 'moment';
import { order } from 'services/order/service';
import FileSaver from 'file-saver';
import { customer } from 'services/customer/service';
import { jobs } from 'services/jobs/service';
import { carrier } from 'services/carrier/service';
import { district } from 'services/district/service';
import { priceTypes } from 'components/globalInfo/priceTypes';
import axios from 'axios';
import swal from 'bootstrap-sweetalert';
import { queryClient } from 'api';

const init = filters => dispatch => {
  dispatch({ type: 'ORDER_LIST_INIT_FILTER', filters });
  dispatch(getOrders());
  const { show } = filters;
  const save = (value, name) => ({
    type: 'ORDER_LIST_INIT_FILTER_INFO',
    value,
    name,
  });

  if (show.customerIds) {
    customer
      .get()
      .then(response => {
        dispatch(save(response.data.data.customers, 'customers'));
      })
      .catch(error => {
        swal(error.response.data.error || 'Something went wrong.', '', 'error');
      });
  }
  if (show.districtIds) {
    district
      .get()
      .then(response => {
        dispatch(save(response.data.data.district, 'districts'));
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
  if (show.jobIds) {
    jobs
      .get()
      .then(response => {
        dispatch(save(response.data.data.jobs, 'jobs'));
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
  if (show.carrierIds) {
    carrier
      .getCarriers()
      .then(response => {
        dispatch(save(response.data.data.carriers, 'carriers'));
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
};

/**
 *
 * @param cancelToken {Object?} Optional cancel token to cancel request
 * @return {(function(*, *): void)|*}
 */
const getOrders = cancelToken => (dispatch, getState) => {
  dispatch({ type: 'ORDER_LIST_START_GET_ORDERS' });
  const {
    orderList,
    pagination: {
      pagination: { currentPage },
    },
  } = getState();

  // TODO: Here until mutations added
  queryClient.invalidateQueries({ queryKey: ['orders'] });

  const {
    filters: {
      input: {
        jobId,
        truck,
        statuses,
        stage,
        sort,
        order_id,
        districtIds,
        carrierIds,
        customerIds,
        jobIds,
        startDate,
        endDate,
        show,
        origin,
      },
    },
  } = orderList;

  const data = {
    jobId,
    truck,
    statuses: statuses.map(item => item.value),
    stage,
    sortBy: sort,
    orderIdLike: order_id,
    itemsPerPage: 10,
    page: currentPage,
    carrierIds: (carrierIds || []).map(item => item.value),
    customerIds: (customerIds || []).map(item => item.value),
    districtIds: (districtIds || []).map(item => item.value),
    jobIds: (jobIds || []).map(item => item.value),
    startDate: startDate || '',
    endDate: endDate
      ? moment(endDate)
        .add(1, 'day')
        .subtract(1, 'seconds')
      : '',
    origins: (origin || []).map(item => item.value),
  };

  order
    .get(data, cancelToken)
    .then(response => {
      dispatch({
        type: 'ORDER_LIST_SAVE_ORDERS',
        ...response.data.data,
      });
      if (show.count) {
        dispatch({
          type: 'ORDER_LIST_REFRESH_COUNT',
          count: response.data.data.count,
        });
      }
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

/**
 * Cancels a previous request to get orders whenever a filter changes
 * @return {(function(*, *): void)|*}
 */
const handleFilterChange = () => (dispatch, getState) => {
  const state = getState();

  if (state.orderList.filters.cancelToken) {
    state.orderList.filters.cancelToken.cancel(
      'Operation cancelled due to new request.',
    );
  }

  const cancelToken = axios.CancelToken.source();
  dispatch({ type: 'ORDER_REQUEST_CANCEL_TOKEN', cancelToken });
  dispatch(getOrders(cancelToken));
};

/**
 *
 * @param value
 * @param name
 * @return {(function(*): void)|*}
 */
const setFilterValue = (value, name) => dispatch => {
  dispatch({ type: 'ORDER_LIST_SET_FILTER_VALUE', value, name });
  dispatch(handleFilterChange());
};

/**
 *
 * @param value
 * @param name
 * @return {(function(*): void)|*}
 */
const setDate = (value, name) => dispatch => {
  dispatch({ type: 'ORDER_LIST_SET_FILTER_VALUE', value, name });
};

/**
 *
 * @param orderId
 * @return {(function(*, *): void)|*}
 */
const refreshOrder = orderId => (dispatch, getState) => {
  const success = orders => ({
    type: 'ORDER_LIST_REFRESH_ORDER',
    orders,
  });
  const state = getState();
  const orders = [...state.orderList.info.orders];

  // TODO: Here until mutations added
  queryClient.invalidateQueries({ queryKey: ['orders'] });

  order
    .get({ orderId })
    .then(response => {
      const refreshedOrder = response.data.data.orders[0];
      const orderList = orders.map(item =>
        item.order_id == refreshedOrder.order_id ? refreshedOrder : item,
      );
      dispatch(success(orderList));
    })
    .catch(error => {
      swal(error, '', 'error');
    });
};

/**
 *
 * @param order_id
 * @return {(function(*): void)|*}
 */
const downloadInvoicePdf = order_id => dispatch => {
  order
    .downloadInvoicePdf(order_id)
    .then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(file, `order-${order_id}.pdf`);
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const turnModalInfo = () => ({ type: 'ORDER_LIST_TURN_MODAL_INFO' });
const clear = () => ({ type: 'ORDER_LIST_CLEAR' });
const setPagination = () => ({ type: 'PAGINATION_SET_PAGE', value: 1 });
const initOrder = order => ({
  type: 'ORDER_LIST_INIT_ORDER_MODAL',
  order,
});

// Order info modal
const changeKey = value => ({
  type: 'ORDER_LIST_CHANGE_TAB_KEY',
  value,
});

const getPrices = orderId => dispatch => {
  const success = prices => ({
    type: 'ORDER_LIST_INIT_PRICES',
    prices,
  });
  order
    .pricebreakdown(orderId)
    .then(response => {
      const prices = response.data.data.price;
      const { total } = response.data.data;
      prices.forEach(item => {
        Object.assign(item, {
          title: priceTypes[item.type].title,
        });
      });
      dispatch(success({ total, prices }));
    })
    .catch(error => {
      if (error.response.status !== 403) {
        swal(error.response.data.message, '', 'error');
      }
    });
};
const clearState = () => ({ type: 'ORDER_LIST_CLEAR_STATE' });

// Comments
const initComments = () => (dispatch, getState) => {
  const save = comments => ({
    type: 'ORDER_LIST_INIT_COMMENT',
    comments,
  });
  const state = getState();
  const { order_id } = state.orderList.modalOrderInfo.order;
  order
    .getComments(order_id)
    .then(response => {
      dispatch(save(response.data.data.comments));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setComment = comment => ({
  type: 'ORDER_LIST_SET_ORDER_COMMENT',
  comment,
});

const postComment = () => (dispatch, getState) => {
  const state = getState();
  const { order_id } = state.orderList.modalOrderInfo.order;
  const { comment } = state.orderList.modalOrderInfo.logs;
  order
    .postComment(order_id, comment)
    .then(response => {
      dispatch(initComments());
      dispatch(setComment(''));
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};

const deleteComment = commentId => dispatch => {
  order
    .deleteComment(commentId)
    .then(response => {
      dispatch(initComments());
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

export default {
  init,
  setFilterValue,
  setDate,
  getOrders,
  downloadInvoicePdf,
  refreshOrder,
  turnModalInfo,
  initOrder,
  clear,
  handleFilterChange,
  setPagination,
  // order info
  changeKey,
  getPrices,
  clearState,
  // comments
  initComments,
  deleteComment,
  postComment,
  setComment,
};
