import React from 'react';
import Authorize from 'components/common/Authorize';
import CarrierHeader from './CarrierHeader';
import CarriersList from './CarriersList';

const AdminCarriers = () => (
  <div>
    <CarrierHeader />
    <Authorize
      abilityPermissions={[{ resource: 'Carriers', permissions: ['read'] }]}>
      <CarriersList />
    </Authorize>
  </div>
);

export default AdminCarriers;
