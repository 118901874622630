import React from 'react';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import Pagination from 'components/shared/Pagination';
import Loader from 'components/shared/Loader';
import {
  setDriverDetails as setDriverDetailsAction,
  closeEditModal as closeEditModalAction,
  deleteDriver as deleteDriverAction,
  logOutDriver as logOutDriverAction,
  freeDriver as freeDriverAction,
  restoreDriver as restoreDriverAction,
} from 'store/actions/Drivers';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import Driver from 'components/views/DriversList/Driver';
import DriverFilters from 'components/views/DriversList/DriverFilters';

const DriverListBlock = ({
  applyFilters,
  drivers,
  apiIsLoading,
  equipmentRequired,
  setDriverDetails,
  closeEditModal,
  deleteDriver,
  logOutDriver,
  freeDriver,
  restoreDriver,
  getDistrictAccordingToCarrier,
  setPagination,
  pagination,
  pageLimit,
  setPageLimit,
  totalPages,
}) => {
  const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    856: 1,
  };

  return (
    <div>
      <div className="ibox-content">
        <h3 className="text-white">Drivers</h3>
        <DriverFilters applyFilters={applyFilters} />
        <div className="wrapper-content animated fadeInRight">
          {apiIsLoading ? (
            <div data-testid="drivers-list-block-loading">
              <Loader />
            </div>
          ) : (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {drivers.map(item => (
                <Driver
                  key={item.id}
                  data={item}
                  {...{
                    equipmentRequired,
                    deleteDriver,
                    setDriverDetails,
                    closeEditModal,
                    logOutDriver,
                    freeDriver,
                    restoreDriver,
                    getDistrictAccordingToCarrier,
                  }}
                />
              ))}
            </Masonry>
          )}
        </div>
        <div>
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={totalPages ?? 0}
            onPageChange={setPagination}
            withLimit
            currentLimit={pageLimit}
            onLimitChange={e => {
              setPagination({ currentPage: 1 });
              setPageLimit(e);
              applyFilters();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setDriverDetails: driver => dispatch(setDriverDetailsAction(driver)),
  closeEditModal: () => dispatch(closeEditModalAction()),
  deleteDriver: id => dispatch(deleteDriverAction(id)),
  logOutDriver: driver => dispatch(logOutDriverAction(driver)),
  freeDriver: id => dispatch(freeDriverAction(id)),
  restoreDriver: id => dispatch(restoreDriverAction(id)),
  getDistrictAccordingToCarrier: carrierId =>
    dispatch(resourceActions.getDistrictAccordingToCarrier(carrierId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverListBlock);
