import { combineReducers } from '@reduxjs/toolkit';

const initAdd = {
  showAddModal: false,
  approvedMileage: '',
  travelTimeMinutes: '',
  jobId: '',
  customerId: '',
  volume: '1000000000',
  sandTypeId: '',
  sandSiteId: '',
  priority: '1',
  dollarsPerTon: '',
  comment: '-',
  poReference: '-',
  sandVendorId: '',
  carrierId: 0,
  commodityUnits: '3',
};

export const add = (state = { ...initAdd }, action) => {
  switch (action.type) {
    case 'ADD_UNIT_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ADD_UNIT_CLEAR':
      return initAdd;
    default:
      return state;
  }
};

const initUpdate = {
  showUpdateModal: false,
  poNo: '',
  priority: '',
  enabled: '',
  loading: false,
  filteredOrders: [],
  showPreview: false,
};

export const update = (state = initUpdate, action) => {
  switch (action.type) {
    case 'UPDATE_UNITR_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'UPDATE_UNITR_SET_INIT_VALUE':
      return {
        ...state,
        ...action.data,
      };
    case 'UPDATE_UNIT_TURN_PREVIEW':
      return {
        ...state,
        showPreview: !state.showPreview,
      };
    case 'UPDATE_UNIT_CLEAR':
      return initUpdate;
    default:
      return state;
  }
};

const initInfo = {
  isLoaded: false,
  suspended: '',
  isEnabled: '',
  jobDistricts: [{}],
  showPreview: false,
  filteredOrders: [],
  loading: false,
};

export const info = (state = { ...initInfo }, action) => {
  switch (action.type) {
    case 'UNIT_INFO_INIT':
      return {
        ...action.data,
        isLoaded: true,
        jobDistricts: action.districts,
      };
    case 'UNIT_INFO_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'UNIT_INFO_TURN_PREVIEW':
      return {
        ...state,
        showPreview: !state.showPreview,
      };
    case 'UNIT_INFO_CLEAR':
      return initInfo;
    default:
      return state;
  }
};

export const unitModals = combineReducers({
  add,
  update,
  info
});
