import React from 'react';
import { authorize } from 'components/common/Authorize';
import Link from 'components/common/Link';
import NotarizationReport from 'components/views/NotarizationsReport';
import ToolTip from 'components/shared/ToolTip';
import { commodityTypes, image } from 'components/globalInfo/commodityUnits';
import OrderInfoTicket from './OrderInfoTicket';

const OrderInfoColumns = ({ data = null, tickets, jobPlansdata }) => {
  const jobTypeIcon = [1, 2].includes(data.operation_type)
    ? 'sand'
    : 'waterdrop';

  const etaSplit = String(data.eta).split(' ');
  const ETA = etaSplit[1];

  return (
    <>
      <div className="order-info__info__row">
        <div className="info-column">
          <h3 className="order-info__heading info-column__title">Origin</h3>
          <span className="info-column__item">
            Origin:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Locations', permissions: ['read'] },
                ],
              }) ? (
                <Link to="location-details" id={data.loading_site_id || 0}>
                  {data.loading_site_id || 0}
                </Link>
              ) : (
                data.loading_site_id || 0
              )}
              {` | ${data.loading_site_name || 'Unavailable'}`}
            </span>
          </span>
          <span className="info-column__item">
            <ToolTip title={commodityTypes(data.commodityType).title}>
              {image(data.commodityType)}
            </ToolTip>
            Commodity:
            <span className="white">
              {` ${data.commodity_name || 'Unavailable'}`}
            </span>
          </span>
          <span className="info-column__item">
            PO:
            <span className="white">{` ${data.po || 'Unavailable'}`}</span>
          </span>
        </div>
        <div className="info-column">
          <h3 className="order-info__heading info-column__title">
            Destination
          </h3>
          <span className="info-column__item">
            Destination:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Locations', permissions: ['read'] },
                ],
              }) ? (
                <Link to="location-details" id={data.well_site_id || 0}>
                  {data.well_site_id || 0}
                </Link>
              ) : (
                data.well_site_id || 0
              )}
              {` | ${data.well_site_name || 'Unavailable'}`}
            </span>
          </span>
          <span className="info-column__item">
            Job:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) ? (
                <Link to="job-details" id={data.job_id || data.jobId || 0}>
                  {data.job_id || data.jobId || 0}
                </Link>
              ) : (
                data.job_id || data.jobId || 0
              )}
              {` | ${data.job_name || 'Unavailable'}`}
            </span>
          </span>
          <span className="info-column__item">
            Customer:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Customers', permissions: ['read'] },
                ],
              }) ? (
                <Link to="customer-details" id={data.customer_id || 0}>
                  {data.customer_id || 'Unavailable'}
                </Link>
              ) : (
                data.customer_id || 'Unavailable'
              )}
              {` | ${data.customer_name || 'Unavailable'}`}
            </span>
          </span>
        </div>
        <div className="info-column">
          <h3 className="order-info__heading info-column__title">Carrier</h3>

          <span className="info-column__item">
            Driver:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Drivers', permissions: ['read'] },
                ],
              }) ? (
                <Link to="driver-details" id={data.driver_id || 0}>
                  {data.driver_id || 'Unavailable'}
                </Link>
              ) : (
                data.driver_id || 'Unavailable'
              )}
              {` | ${data.driver_name || 'Unavailable'}`}
            </span>
          </span>

          <span className="info-column__item">
            Truck:
            <span className="white">{` ${data.truck || 'Unavailable'}`}</span>
          </span>

          <span className="info-column__item">
            Carrier:
            <span className="white">
              {` #`}
              {authorize({
                abilityPermissions: [
                  { resource: 'Carriers', permissions: ['read'] },
                ],
              }) ? (
                <Link to="carrier-details" id={data.carrier_id || 0}>
                  {data.carrier_id || 'Unavailable'}
                </Link>
              ) : (
                data.carrier_id || 'Unavailable'
              )}
              {` | ${data.carrier_name || 'Unavailable'}`}
            </span>
          </span>
        </div>
      </div>

      <div className="order-info__info__row">
        <div className="info-column">
          <h3 className="order-info__heading info-column__title">Load</h3>
          <NotarizationReport order={data} />
        </div>

        <div className="info-column">
          <div className="order-info__documents__tickets">
            {tickets
              .filter(item => item.ticketType === 1 || item.ticketType === 4)
              .map(
                ticket =>
                  !ticket.isDeleted && (
                    <OrderInfoTicket
                      key={ticket.id}
                      ticket={ticket}
                      order={data}
                      jobPlansdata={jobPlansdata}
                    />
                  ),
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfoColumns;
