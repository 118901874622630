import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import ServerPagination from 'components/shared/ServerPagination';
import TableList from 'components/shared/TableList';
import Link from 'components/common/Link';
import { newConvertTime } from 'utils/convertTime';
import { convertNumberToCurrencyFromAmount } from 'utils/convertNumberToCurrency';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import SlidePanel from 'components/shared/SlidePanel';
import genericFilter from 'store/selectors/genericFilter';
import genericSorting from 'store/selectors/genericSorting';
import ToolTip from 'components/shared/ToolTip';
import Badge from 'components/shared/Badge';
import Authorize, { authorize } from 'components/common/Authorize';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';
import swal from 'bootstrap-sweetalert';
import { creditMemoClear } from 'store/actions/Invoices';
import InvoiceDetails from './InvoiceDetails';
import CreditMemo from './CreditMemo';

const InvoicesList = ({
  invoices,
  ordering,
  setOrdering,
  markAsPaid,
  markAsSent,
  deleteInvoices,
  downloadMultipleBulkInvoices,
  pagination,
  setPagination,
  totalItems,
  clearCreditMemoModal,
}) => {
  const [selectedInvoice, selectNewInvoice] = useState(null);
  const [checkedInvoices, setCheckedInvoices] = useState([]);
  const [showModalCreditMemo, setShowModalCreditMemo] = useState(false);

  const user = authService.getUser();

  const closeCreditModal = () => {
    setShowModalCreditMemo(false);
    clearCreditMemoModal();
  };

  const columns = [
    { key: 'id', value: 'ID' },
    { key: 'customer_id', value: 'Customer' },
    { key: 'created_at', value: 'Date' },
    { key: 'due_date', value: 'Due' },
    { key: 'total_price', value: 'Total' },
    { key: 'status', value: 'Status' },
  ];

  const csvHeaders = columns
    .concat([
      { key: 'formatted_status', value: 'STATUS DESC' },
      { key: 'sent_at', value: 'SENT' },
    ])
    .map(c => {
      c.label = c.value;
      return c;
    });

  const csvInvoices = invoices?.length
    ? _.cloneDeep(invoices).map(item => {
        const status = [];
        if (item.is_deleted === 1) {
          status.push('Deleted');
        } else {
          switch (item.status) {
            case 0:
              status.push('New');
              break;
            case 1:
              status.push('Sent');
              break;
            case 2:
              status.push('Paid');
              break;
            default:
              status.push('unavailable');
              break;
          }
        }
        if (item.creditMemos && item.creditMemos.length) {
          status.push('Credited');
        }
        // Swap id with name for csv
        item.customer_id = item.customer_name;
        item.formatted_status = status.join(', ');
        item.created_at = newConvertTime(item.created_at);
        item.sent_at = item.sent_at ? newConvertTime(item.sent_at) : null;
        item.due_date = newConvertTime(item.due_date);
        return item;
      })
    : [];

  const sorting = key => {
    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const invoiceItems = invoices?.length
    ? invoices.map(item => ({
        key: item.id,
        isSelected: selectedInvoice && selectedInvoice.id === item.id,
        onRowClick: () => selectNewInvoice(item),
        originalData: item,
        data: {
          id: () => `#${item.id}`,
          customer_id: () => (
            <>
              <div>
                #
                {authorize({
                  abilityPermissions: [
                    {
                      resource: 'Customers',
                      permissions: ['read'],
                    },
                  ],
                }) ? (
                  <Link
                    {...{
                      id: item.customer_id,
                      to: 'customer-details',
                    }}>
                    {item.customer_id}
                  </Link>
                ) : (
                  item.customer_id
                )}
                <span> | {item.customer_name} </span>
              </div>
              <div>Total orders: {item.total_orders}</div>
            </>
          ),
          created_at: () => (
            <>
              <div>Created: {newConvertTime(item.created_at)}</div>
              {item.sent_at && <div>Sent: {newConvertTime(item.sent_at)}</div>}
            </>
          ),
          due_date: () => (
            <>
              <div>Due: {newConvertTime(item.due_date)}</div>
              {item.paid_at && <div>Paid: {newConvertTime(item.paid_at)}</div>}
            </>
          ),
          total_price: () => (
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Pricing',
                    permissions: ['read'],
                  },
                ],
              }}>
              {convertNumberToCurrencyFromAmount(item.total_price)}
            </Authorize>
          ),
          status: () => {
            let statusComponent = <></>;
            let memoComponent = <></>;
            let missingQbComponent = <></>;
            if (item.is_deleted === 1) {
              statusComponent = <Badge children="Deleted" />;
            } else {
              switch (item.status) {
                case 0:
                  statusComponent = <Badge type="yellow" children="New" />;
                  break;
                case 1:
                  statusComponent = <Badge type="orange" children="Sent" />;
                  break;
                case 2:
                  statusComponent = <Badge type="green" children="Paid" />;
                  break;
                default:
                  statusComponent = <Badge children="Status unavailable" />;
                  break;
              }
            }
            if (item.creditMemos && item.creditMemos.length) {
              const creditMemos = item.creditMemos.map(cm => cm.id).join(', ');
              memoComponent = (
                <ToolTip title={`Credit Memo IDs: ${creditMemos}`}>
                  <Badge type="red" children="Credited" />
                </ToolTip>
              );
            }
            if (item.isMissingQb && user.role !== ROLES.CARRIER) {
              missingQbComponent = <Badge type="red" children="Missing QB" />;
            }
            return (
              <span>
                {statusComponent}
                {memoComponent}
                {missingQbComponent}
              </span>
            );
          },
        },
      }))
    : [];

  // only send server request for changes that can be made
  let invoicesAlreadyInStatus = [];
  const checkIfInvoiceAlreadyInThatStatus = buttonType => {
    if (buttonType === 'createMemo') {
      checkedInvoices.map(item => {
        if (item.originalData.is_deleted === 1) {
          invoicesAlreadyInStatus.push(` #${item.originalData.id}`);
        }
      });
      if (invoicesAlreadyInStatus.length !== 0) {
        swal(
          'These invoices have already been deleted:',
          `${invoicesAlreadyInStatus}\n Please deselect them and try again`,
        );
      } else {
        deleteInvoices(checkedInvoices.map(data => data.key));
      }
    } else if (buttonType === 'delete') {
      checkedInvoices.map(item => {
        if (item.originalData.is_deleted === 1) {
          invoicesAlreadyInStatus.push(` #${item.originalData.id}`);
        }
      });
      if (invoicesAlreadyInStatus.length !== 0) {
        swal(
          'These invoices have already been deleted:',
          `${invoicesAlreadyInStatus}\n Please deselect them and try again`,
        );
      } else {
        deleteInvoices(checkedInvoices.map(data => data.key));
      }
    } else if (buttonType === 'sent') {
      checkedInvoices.map(item => {
        if (
          item.originalData.status === 1 &&
          item.originalData.is_deleted !== 1
        ) {
          invoicesAlreadyInStatus.push(` #${item.originalData.id}`);
        }
      });
      markAsSent(checkedInvoices.map(data => data.key));
    } else if (buttonType === 'paid') {
      checkedInvoices.map(item => {
        if (item.originalData.status === 2) {
          invoicesAlreadyInStatus.push(` #${item.originalData.id}`);
        }
      });
      if (invoicesAlreadyInStatus.length !== 0) {
        swal({
          title: 'These invoices have already been paid:',
          text: `${invoicesAlreadyInStatus}\n Please deselect them and try again`,
        });
      } else {
        markAsPaid(checkedInvoices.map(data => data.key));
      }
    } else if (buttonType === 'download') {
      const user = authService.getUser();
      let carrierId = null;

      if (user.entityType === 3) {
        carrierId = user.entityId;
      }
      downloadMultipleBulkInvoices(
        checkedInvoices.map(data => data.key),
        null,
        carrierId,
      );
    }
    invoicesAlreadyInStatus = [];
  };

  const actionOptions = () => {
    const options = [
      {
        name: 'download',
        label: 'Download Bulk Invoices',
        onClick: () => checkIfInvoiceAlreadyInThatStatus('download'),
      },
    ];

    if (
      authorize({
        abilityPermissions: [{ resource: 'Invoices', permissions: ['update'] }],
      })
    ) {
      options.push({
        name: 'paid',
        label: 'Mark as Paid',
        onClick: () => checkIfInvoiceAlreadyInThatStatus('paid'),
      });
      options.push({
        name: 'sent',
        label: 'Mark as Sent',
        onClick: () => checkIfInvoiceAlreadyInThatStatus('sent'),
      });
    }
    if (
      authorize({
        abilityPermissions: [{ resource: 'Invoices', permissions: ['delete'] }],
      }) &&
      checkedInvoices.length === 1 &&
      checkedInvoices[0].originalData.status === 1
    ) {
      options.push({
        name: 'createMemo',
        label: 'Create Credit Memo',
        onClick: () => setShowModalCreditMemo(true),
      });
    }
    if (
      (authorize({
        abilityPermissions: [{ resource: 'Invoices', permissions: ['delete'] }],
      }) &&
        (checkedInvoices.length === 1 &&
          checkedInvoices[0].originalData.status === 0)) ||
      (checkedInvoices.length > 1 &&
        !checkedInvoices.some(ci => [1, 2].includes(ci.originalData.status)))
    ) {
      options.push({
        name: 'delete',
        label: 'Delete',
        onClick: () => checkIfInvoiceAlreadyInThatStatus('delete'),
      });
    }

    return options;
  };

  return (
    <div className="invoice-list">
      {showModalCreditMemo && (
        <CreditMemo
          invoice={
            checkedInvoices.length ? checkedInvoices[0].originalData : []
          }
          showModal={showModalCreditMemo}
          closeModal={closeCreditModal}
        />
      )}
      <div className="invoice-list__actions">
        <span>
          <strong>{checkedInvoices.length}</strong> invoices selected
        </span>
        <span className="invoice-list__actions--buttons">
          <CSVLink
            data={csvInvoices}
            headers={csvHeaders}
            filename={'invoice-list.csv'}>
            <Button theme="small" onClick={() => {}}>
              <Icon icon="download" />
            </Button>
          </CSVLink>
          <DropdownButton
            theme="small"
            isDisabled={checkedInvoices.length < 1}
            text="Actions"
            icon="down"
            options={actionOptions()}
            noMargin
          />
          {/* TODO: Hidden until functionality is completed
            <ToolTip title="Export Selected..." placement="left">
              <DropdownButton
                onClick={() => {}}
                icon="export"
                className="button--small--square"
                options={exportOptions}
              />
            </ToolTip> */}
        </span>
      </div>
      <TableList
        onSort={sorting}
        checkboxColumn
        clickableTable
        onCheck={rows => setCheckedInvoices(rows)}
        columns={columns}
        listItems={invoiceItems}
      />

      <SlidePanel
        isVisible={!!selectedInvoice}
        closePanel={() => selectNewInvoice(null)}>
        <InvoiceDetails selectedInvoice={selectedInvoice} />
      </SlidePanel>

      {invoices && invoices.length > 0 && (
        <ServerPagination
          useLimit
          pagination={pagination}
          onUpdate={setPagination}
          totalItems={totalItems}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  invoices: genericSorting(state.invoices, props),
  totalItems: state.invoices.totalItems,
});

const mapDispatchToProps = dispatch => ({
  clearCreditMemoModal: () => dispatch(creditMemoClear()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoicesList);
