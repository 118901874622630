import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import CreateInvoiceModal from 'components/views/Reconcile/ReconcileOrderStatus/CreateInvoiceModal';

import actions from 'store/actions/JobPlans';
import modifyActions from 'store/actions/OrderActions';
import orderStoreActions from 'store/actions/Orders';
import { ticketsDownloadAllTickets } from 'store/actions/OrderTickets';
import { useBillableOrders } from 'api/v2/job-plans';
import Authorize from 'components/common/Authorize';
import BillableOrders from './BillableOrders';

const OrdersSlideout = ({
  jobId,
  jobName,
  jobPlanId,

  isVisible,
  // setSlideoutVisible,
  // billableTimeBand,

  timeFrame,
  allBillableTimeBands,
  setTimeFrameFilter,
  jobPlanDetails,
}) => {
  const { data: billableOrders } = useBillableOrders({
    jobPlanId,
    timeFrame: allBillableTimeBands[timeFrame],
  });

  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const timeBands = allBillableTimeBands.map((time, index) => ({
    label: `${moment.utc(time.start).format('MM-DD-YYYY')} to ${moment
      .utc(time.end)
      .format('MM-DD-YYYY')}`,
    value: index,
  }));

  useEffect(() => {
    if (allBillableTimeBands && allBillableTimeBands.length > 0) {
      setTimeFrameFilter(allBillableTimeBands.length - 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBillableTimeBands]);

  useEffect(() => {
    if (!isVisible) {
      setTimeFrameFilter(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleTimeChange = selection => {
    setTimeFrameFilter(selection.value);
  };

  const isAllReadyToInvoice = useMemo(
    () =>
      (billableOrders ?? []).every(item => item.billableStatus === 3) &&
      Boolean(billableOrders?.length),
    [billableOrders],
  );
  const orderIds = useMemo(() => (billableOrders ?? []).map(o => o.orderId), [
    billableOrders,
  ]);

  return (
    <div className="marginLeft15 marginBottom20 paddingBottom20">
      <div className="flex">
        <h2 className="marginBottom0">Billable Orders for</h2>
        <div className="width35 marginLR10">
          <Select
            placeholder="Pay period"
            value={timeBands.find(item => item.value === timeFrame)}
            options={timeBands}
            onChange={handleTimeChange}
          />
        </div>
      </div>
      <div>
        <strong>
          Job #{jobId}:<span style={{ color: '#18a689' }}> {jobName}</span>
        </strong>
      </div>
      <div>
        <strong>{`Job Plan # ${jobPlanId}`}</strong>
      </div>
      <BillableOrders
        jobPlanId={jobPlanId}
        timeFrame={allBillableTimeBands[timeFrame]}
      />
      <Authorize
        abilityPermissions={[
          { resource: 'Carrier-documents', permissions: ['update'] },
        ]}>
        <div className="paddingBottom20">
          <Button
            className="float-end"
            disabled={!isAllReadyToInvoice}
            onClick={() => setCreateInvoiceModal(true)}
            testSelector="job-plans_order-details_create-invoice_btn">
            Create invoice
          </Button>
        </div>
      </Authorize>

      {createInvoiceModal && (
        <CreateInvoiceModal
          open={createInvoiceModal}
          onClose={() => setCreateInvoiceModal(false)}
          orderIds={orderIds}
          usedOutsideReconcile
          customerId={jobPlanDetails.customerId}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  equipmentRequired: state.lookUp.equipmentRequired,
  orderActions: state.orderActions,
  billableOrders: state.jobPlans.jobPlanInfo.billableOrders,
  billableOrdersDetails: state.jobPlans.jobPlanInfo.billableOrdersDetails,
  jobPlanDetails: state.jobPlans.jobPlanInfo.jobPlanDetails,
  allBillableTimeBands:
    state.jobPlans.jobPlanInfo.jobPlanDetails.allBillableTimeBands,
  isLoadingBillableOrders: state.jobPlans.jobPlanInfo.isLoadingBillableOrders,
  isLoadingOrders: state.jobPlans.jobPlanInfo.isLoadingOrders,
  timeFrame: state.jobPlans.jobPlanInfo.timeFrame,
  showModalDemurrage: state.orderActions.demurrageReason.openModal,
});

const mapDispatchToProps = dispatch => ({
  getBillableOrders: data => dispatch(actions.getBillableOrders(data)),
  getBillableOrdersDetailsTickets: orderId =>
    dispatch(actions.getBillableOrdersDetailsTickets(orderId)),
  setTimeFrameFilter: timeFrame => {
    dispatch(actions.setTimeFrameFilter(timeFrame));
  },
  downloadAllTicketsFunc: orderId =>
    dispatch(ticketsDownloadAllTickets(orderId)),
  approveOrderFunc: (orderId, data) => {
    dispatch(orderStoreActions.jobPlansApproveOrder(orderId, data));
  },
  openModal: (modal, order) => {
    dispatch(modifyActions.openModal(modal, order));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersSlideout);
