import React, { useState } from 'react';

import ToolTip from 'components/shared/ToolTip';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import OnsiteStorageType from 'components/views/JobsList/AddJobModal/OnsiteStorageType';
import { useStorageTypes } from 'api/v2/storage-types';
import { useJobDesignCommodities, useJobDesignCommoditiesUOM } from '../AddJobModal/hooks';

/**
 *
 * @param {{
 *  storageConfig: any;
 *  handlers: import('./hooks').EditJobHandlers;
 *  customerId: number;
 * }} props
 */
const StorageConfig = ({ storageConfig, handlers, jobDesign, customerId }) => {
  const [visible = false, setVisible] = useState(false);

  const { data: storageTypes } = useStorageTypes();
  const commodityOptions = useJobDesignCommodities(jobDesign);
  const storageConfigWithUOM = useJobDesignCommoditiesUOM(jobDesign, storageConfig);

  const setArrayValue = (e, index, name, arrayName) =>
    handlers.arrayValueChange(index, name, arrayName)(e);

  const deleteArrayValue = (index, arrayName) =>
    handlers.arrayValueDelete(index, arrayName)();

  const setOnsiteStorageType = (selectedType, index) => {
    storageTypes.forEach(type => {
      if (type.id === selectedType.id) {
        setArrayValue(type.id, index, 'type', 'storageConfig');
        setArrayValue(type.isLive, index, 'isLive', 'storageConfig');
      }
    });
  };
  
  return (
    <div className="modify-ticket panel panel-default">
      <div className="modify-ticket__header">
        <span className="modify-ticket__header__text">
          <span className="white">Storage Configuration</span>
        </span>
        <div className="modify-ticket__header__buttons">
          <ToolTip title="Preview">
            <Button
              theme="small"
              className="button--small--square collapsed panel-title--with-arrow"
              testSelector="order-actions_modify_order-info-ticket_accordion_btn"
              data-bs-toggle="collapse"
              data-parent="#accordion"
              href="#onsite-storage-config"
              onClick={() => setVisible(!visible)}>
              <Icon icon="down" />
            </Button>
          </ToolTip>
        </div>
      </div>
      <div
        className="modify-ticket__preview panel-collapse collapse"
        id="onsite-storage-config">
        <div className="content" style={{ padding: '20px 10px' }}>
          <div className="step3">
            <fieldset id="form-p-2" role="tabpanel" className="body">
              {storageConfigWithUOM?.map((item, index) => (
                <OnsiteStorageType
                  filteredCommodityOptions={commodityOptions}
                  setArrayValue={setArrayValue}
                  deleteArrayValue={deleteArrayValue}
                  setOnsiteStorageType={setOnsiteStorageType}
                  item={item}
                  index={index}
                  key={index}
                  customerId={customerId}
                />
              ))}
            </fieldset>
            <Button
              theme="small"
              testSelector="jobs-list_add-modal_onsite-storage_add-item_btn"
              onClick={handlers.arrayValueAdd('storageConfig')}>
              <Icon icon="plus" /> Add Storage
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StorageConfig;
