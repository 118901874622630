import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Authorize from 'components/common/Authorize';
import multiSelectOptions from 'utils/multiSelectOptions';
import Select from 'components/shared/Select';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { useLocations } from 'api/v2/locations';
import { useEquipment } from 'api/v2/equipment';

const Job = ({
  closeAddJobModal,
  addJob,
  addJobInitData,
  selectedCustomer,
  setSelectedCustomer,
  formHandlers,
  userCustomer,
}) => {
  const {
    name,
    customerId,
    districtIds,
    wellSiteId,
    equipment,
    operatorList,
    containersPerTruck,
    billingProfileId,
    billingProfiles,
    wellSites,
    customerJobId,
    customerProjectId,
    navId,
    customer,
    stagingSite,
  } = addJob;
  const {
    customers,
    districts,
    companyArray,
  } = addJobInitData;
  const {
    setValue,
    setStep,
  } = formHandlers;

  const { data: locations } = useLocations();
  const locationsOptions = useMemo(
    () =>
      (locations || [])
        .filter(item => item.locationType === 4)
        .map(i => ({
          value: i.id,
          label: i.name,
        })),
    [locations],
  );

  const { data: allEquipment } = useEquipment();

  const [additionalOperatorsList, updateOperatorsList] = useState([]);

  const customersList = customers
    .filter(
      item => item.accessLevel === 'owner' || item.accessLevel === 'shared',
    )
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const wellsiteList = wellSites.map(item => ({
    value: item.id,
    label: item.name,
  }));
  const billingProfilesList = billingProfiles.map(item => ({
    value: item.id,
    label: item.name,
  }));
  const districtOptions = multiSelectOptions(districts, 'id', 'name');

  useEffect(() => {
    let array = multiSelectOptions(companyArray, 'id', 'name');
    array = array.filter(
      ({ value }) =>
        Number(value.substring(0, value.indexOf('/'))) !== customerId,
    );
    updateOperatorsList(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <form
      className="wizard-big wizard"
      onSubmit={(e) => {
        e.preventDefault();
        setStep(2);
      }}>
      <Modal.Body>
        <h4 className="title current">Job</h4>
        <fieldset id="form-p-0" role="tabpanel" className="body current">
          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={setValue('name')}
                value={name}
                label="Name"
                required
                testSelector="jobs_add_job_name_input"
              />
            </div>

            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Customer"
                onChange={e => {
                  setValue('customerId')(e.value);
                  setValue('customer')(e);
                  setSelectedCustomer(e.value);
                }}
                options={customersList}
                value={customer}
                isDisabled={userCustomer}
                required
                testSelector="jobs_add_customer_select"
              />
            </div>
          </Row>
          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Select
                isMulti
                placeholder="District"
                onChange={setValue('districtIds')}
                options={districtOptions}
                value={districtIds}
                required
                closeMenuOnSelect={false}
                testSelector="jobs_add_district_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Location"
                onChange={e => setValue('wellSiteId')(e.value)}
                value={
                  wellSiteId && wellsiteList.find(k => k.value === wellSiteId)
                }
                options={wellsiteList}
                isDisabled={!selectedCustomer}
                required
                testSelector="jobs_add_wellsite_select"
              />
            </div>
          </Row>
          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Billing Profile"
                onChange={e => setValue('billingProfileId')(Number(e.value))}
                value={
                  billingProfileId &&
                  billingProfilesList.find(k => k.value === billingProfileId)
                }
                options={billingProfilesList}
                isDisabled={!selectedCustomer}
                required
                testSelector="jobs_add_billing-profile_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={setValue('customerJobId')}
                value={customerJobId}
                label="Customer Job ID"
                testSelector="jobs_add_job_customer-job-id_input"
              />
            </div>
          </Row>

          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={setValue('equipment')}
                options={multiSelectOptions(allEquipment, 'id', 'title')}
                placeholder="Equipment"
                value={equipment}
                required
                testSelector="jobs_add_equipment_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Authorize access={['JOB_MANAGER', 'CUSTOMER']}>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setValue('operatorList')}
                  value={operatorList}
                  options={additionalOperatorsList}
                  placeholder="Additional Operators"
                  testSelector="jobs_add_operators_select"
                />
              </Authorize>
            </div>
          </Row>
          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Select
                closeMenuOnSelect={false}
                onChange={e => setValue('stagingSite')(e.value)}
                value={
                  stagingSite &&
                  locationsOptions.find(k => k.value === stagingSite)
                }
                options={locationsOptions}
                placeholder="Staging Site"
                testSelector="jobs_add_staging-site_select"
                isClearable
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={setValue('customerProjectId')}
                value={customerProjectId}
                label="Customer Project ID"
                testSelector="jobs_add_job_customer-project-id_input"
              />
            </div>
          </Row>

          {(equipment || []).map(
            item =>
              item.value === 3 && (
                <Row className="no-outer-gutter">
                  <div
                    className="form-group has-feedback col-md-6"
                    key={item.value}>
                    <Input
                      type="number"
                      onChange={setValue('containersPerTruck')}
                      value={containersPerTruck}
                      label="Containers per truck"
                      required
                      testSelector="jobs_add-job_job_containers-per-truck_input"
                    />
                  </div>
                </Row>
              ),
          )}

          <Row className="no-outer-gutter">
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e =>
                  setValue('navId')(e.target.value.replace(/\s/g, ''))
                }
                value={navId}
                label="Nav ID"
                testSelector="jobs_add_job_nav-id_input"
              />
            </div>
          </Row>

          {/* Temporary commented, it may to bring back later
          <div className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="jobs-list_add-job_job_all-in_input"
                  checked={allIn}
                  onChange={e => setValue(e, 'allIn')}
                  label="All In"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="jobs-list_add-job_job_use-commodity-price_input"
                  checked={useCommodityPrice}
                  onChange={e => setValue(e, 'useCommodityPrice')}
                  label="Use Commodity Price"
                />
              </div>
            </div> */}
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_job_close_btn">
          Close
        </Button>
        <Button
          type="submit"
          testSelector="jobs-list_add-modal_job_next_btn"
          disabled={!name || !customerId || !districtIds}>
          Next
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Job;
