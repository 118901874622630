import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ type = '', children, className = '' }) => (
  <span className={`badge badge--${type} ${className}`}>{children}</span>
);

Badge.propTypes = {
  /** Color/status of Badge string */
  type: PropTypes.oneOf([
    '',
    'green',
    'darkGreen',
    'red',
    'blue',
    'purple',
    'orange',
    'yellow',
    'margin-bottom',
    'primary',
    'success',
    'warning',
    'danger',
    'info',
    'inverse',
    'new-message',
    'status-0',
    'status-1',
    'status-2',
    'status-3',
    'status-4',
    'status-20',
    'status-21',
    'status-22',
    'status-23',
  ]),
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /** SCSS className string */
  className: PropTypes.string,
};

Badge.defaultProps = {
  type: '',
  className: '',
};

export default Badge;
