import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/Queue';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import { filterOptions } from 'utils/selectUtils';

const AssignDriver = props => {
  const {
    assign,
    drivers,
    turnModalAssign,
    setAssignValue,
    submitAssignDriver,
  } = props;
  const { openModal, driverId, order, isLoading } = assign;
  const { district, allowed_equipments, districts, interstateCertRequired } = order;
  const filteredDrivers = drivers.filter(
    driver =>
      driver.districts.length &&
      driver.districts.some(driverDistrict =>
        districts.find(d => d.id === driverDistrict.id),
      ) &&
      allowed_equipments.some(equipment => equipment === driver.equipment),
  );
  const formattedDrivers = filteredDrivers.map(driver => {
    driver.label = `${driver.id} | ${driver.name}`;
    return driver;
  });

  const driversList = filterOptions(
    formattedDrivers,
    (interstateCertRequired ? 'interstateCertified' : null),
    (interstateCertRequired ? 1 : null),
    null,
    'label',
  );
  return (
    <Modal
      className="modal-container"
      show={openModal}
      onHide={turnModalAssign}>
      <Modal.Header>
        <h3 className="modal-title">Assign driver</h3>
      </Modal.Header>
      <form onSubmit={e => submitAssignDriver(e, true)}>
        <Modal.Body>
          {!!interstateCertRequired && (
            <div className="alert-input-msg">
              Interstate certification is required for this order
            </div>
          )}
          <div className="form-group has-feedback col-md-12">
            <label className="small font-noraml">Drivers</label>
            <div className="form-group">
              <Select
                placeholder="Select driver"
                onChange={item => setAssignValue(item.value, 'driverId')}
                value={
                  driversList.find(driver => driver.value === driverId) || null
                }
                options={driversList}
                testSelector="queue_assign-driver_driver_select"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              turnModalAssign();
              setAssignValue(undefined, 'driverId')
            }}
            colour="white"
            children="Close"
            testSelector="queue_assign-driver_close_btn"
          />
          <Button
            type="submit"
            children="Save"
            disabled={!driverId || isLoading}
            testSelector="queue_assign-driver_save_btn"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  drivers: state.queue.drivers,
  assign: state.queue.assign,
});

const mapDispatchToProps = dispatch => ({
  turnModalAssign: () => dispatch(actions.turnModalAssign()),
  setAssignValue: (value, name) =>
    dispatch(actions.setAssignValue(value, name)),
  submitAssignDriver: (e, forceAssignment = false) => {
    e.preventDefault();
    dispatch(actions.submitAssignDriver(forceAssignment));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignDriver);
