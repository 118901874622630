import React from 'react';
import Collapse from './transitions';

const AnimatedMultiValue = WrappedComponent => {
  // strip transition props off before spreading onto actual component
  // props are inherited from MultiValue
  return ({ in: inProp, onExited, ...props }) => (
    <Collapse in={inProp} onExited={onExited}>
      <WrappedComponent cropWithEllipsis={inProp} {...props} />
    </Collapse>
  );
};

export default AnimatedMultiValue;
