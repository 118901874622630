import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import alerts from 'utils/alerts';
import statuses from 'utils/statuses';
import authService from 'services/auth/service';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import { ROLES } from 'config/constants';
import Filter, {
  FilterInput,
  FilterSelect,
  FilterDatePicker,
  FilterToggle,
} from 'components/shared/Filter';

const serverSideNaming = {
  order_id: 'orderId',
  job_id: 'jobId',
  customer_id: 'customerId',
  customer_job_id: 'customerJobId',
  id: 'carrierId',
  driver_id: 'driverId',
  'from-completed_at': 'startDate',
  'to-completed_at': 'endDate',
  billing_status: 'billingStatuses',
  review_status: 'reviewStatuses',
  alerts: 'alerts',
  demurrage: 'withDemurrage',
  approvedBy: 'approvedBy',
  districtIds: 'districtIds',
};

const ReconcileBaseFilter = ({
  jobs,
  carriers,
  customers,
  drivers,
  districts,
  view,
  setFilters,
  filters,
  setRequestFilters,
  defaultFilters = [],
}) => {
  const user = authService.getUser();
  const [dateAlert, toggleDateAlert] = useState(false);

  const updateFiltering = filterData => {
    setFilters(filterData);
    const convertedFiltering = filterData.reduce((obj, { key, value }) => {
      if (key === 'billing_status') {
        obj[serverSideNaming[key]] = value.filter(item => item >= 20);
        obj.reviewStatuses = value.filter(item => item < 20);
      } else if (key === 'demurrage') {
        obj[serverSideNaming[key]] = value === 2 ? 0 : 1;
      } else {
        obj[serverSideNaming[key]] = value;
      }
      if (obj.reviewStatuses && obj.reviewStatuses.length) {
        if (!obj || !obj.billingStatuses || !obj.billingStatuses.length) {
          obj.billingStatuses = [0];
        }
      }
      if (obj.orderId) {
        obj.billingStatuses = [];
        obj.reviewStatuses = [];
      }
      if (
        obj.jobId ||
        obj.carrierId ||
        obj.customerId ||
        obj.driverId ||
        obj.startDate ||
        obj.endDate
      ) {
        obj.billingStatuses = obj.billingStatuses || [];
      }
      if (obj.jobId && obj.jobId.length > 1) {
        obj.jobIds = obj.jobId;
        obj.jobId = null;
      }
      if (obj.carrierId && obj.carrierId.length) {
        obj.carrierIds = obj.carrierId;
        obj.carrierId = null;
      }
      if (
        obj.billingStatuses &&
        obj.billingStatuses.length &&
        !obj.billingStatuses.filter(d => d === 0).length
      ) {
        obj.mergeBillingAndReviewFilters = true;
      }
      return obj;
    }, {});
    setRequestFilters(convertedFiltering);
  };

  const initialFilters = filters.length > 0 ? filters : defaultFilters;
  return (
    <Filter
      manualUpdate
      onUpdate={updateFiltering}
      active={initialFilters}
      filterVisible>
      <FilterInput
        filterKey="order_id"
        label="Order Id"
        filterLabel="Order Id"
        type="number"
        testSelector="reconcile_filters_order-id_input"
      />
      <FilterSelect
        filterLabel="Job"
        filterKey="job_id"
        valueKey="id"
        labelKey="job_name"
        placeholder="Jobs"
        isMulti
        options={jobs}
      />
      {user.role === ROLES.CUSTOMER ? (
        customers.length && (
          <FilterInput
            disabled
            filterKey="customer_id"
            label="Customer"
            value={`${customers[0].name}`}
          />
        )
      ) : (
        <FilterSelect
          filterKey="customer_id"
          valueKey="id"
          labelKey="name"
          filterLabel="Customer"
          placeholder="Customers"
          options={customers}
        />
      )}
      <FilterInput
        filterKey="customer_job_id"
        label="Customer Job Id"
        filterLabel="Customer Job Id"
      />

      {user.role === ROLES.CARRIER ? (
        carriers.length && (
          <FilterInput
            disabled
            filterKey="id"
            label="Carrier"
            value={`${carriers[0].name}`}
          />
        )
      ) : (
        <FilterSelect
          filterKey="id"
          valueKey="id"
          labelKey="name"
          placeholder="Carriers"
          options={carriers}
          isMulti
          filterLabel="Carrier"
        />
      )}
      <FilterSelect
        filterLabel="Driver"
        filterKey="driver_id"
        valueKey="id"
        labelKey="name"
        placeholder="Drivers"
        options={drivers}
      />
      {districts && (
        <FilterSelect
          filterLabel="District"
          filterKey="districtIds"
          valueKey="id"
          labelKey="name"
          placeholder="Districts"
          isMulti
          options={districts}
        />
      )}
      <FilterDatePicker
        filterLabel="Completed From"
        filterKey="completed_at"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        range="from"
        label="From"
        timeFormat="hh:mm A"
        isDateTimePicker
        clearable
      />
      <FilterDatePicker
        filterLabel="Completed To"
        filterKey="completed_at"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        range="to"
        label="To"
        timeFormat="hh:mm A"
        isDateTimePicker
        clearable
      />
      {view === 'action_items' && (
        <>
          <FilterSelect
            filterLabel="Alert"
            type="array"
            exact
            filterKey="alerts"
            placeholder="Alerts"
            isMulti
            options={Object.keys(alerts).map(key => ({
              value: parseInt(key, 10),
              label: alerts[key].badge,
            }))}
          />
          <FilterSelect
            filterLabel="Status"
            filterKey="review_status"
            placeholder="Status"
            isMulti
            options={[
              { value: 0, label: 'Open' },
              { value: 4, label: 'Contested' },
            ]}
          />
          <FilterSelect
            filterLabel="approvedBy"
            type="array"
            exact
            filterKey="approvedBy"
            label="Approved By"
            isMulti
            options={[
              { value: 6, label: 'Manager' },
              { value: 3, label: 'Carrier' },
              { value: 2, label: 'Customer' },
            ]}
          />
        </>
      )}

      {view === 'order_status' && (
        <>
          <FilterSelect
            filterLabel="Status"
            filterKey="billing_status"
            placeholder="Status"
            isMulti
            options={Object.keys(statuses).map(key => ({
              value: parseInt(key, 10),
              label: statuses[key],
            }))}
            onFilter={(row, key, value) => {
              if (row[key] >= 20) {
                return value.some(multiVal => row[key] === multiVal);
              }
              return value.some(multiVal => row.review_status === multiVal);
            }}
          />
          <FilterToggle
            filterLabel="Demurrage"
            filterKey="demurrage"
            placeholder="Demurrage"
            onFilter={(row, key, value) => {
              if (value === 1) {
                return (
                  parseInt(row.sand_site_demmurage_price, 10) !== 0 &&
                  parseInt(row.well_site_demmurage_price, 10) !== 0
                );
              }

              return true;
            }}
          />
        </>
      )}
      {dateAlert && (
        <div
          className="nested-no-outer-gutter"
          style={{ color: 'red' }}>
          <p>
            * Date format should be &apos;YYYY-MM-DD hh:mm AM/PM&apos;
          </p>
        </div>
      )}
    </Filter>
  );
};

const mapStateToProps = ({ carriers, jobsNew, customers, driversNew }) => ({
  carriers: carriers.collection,
  jobs: jobsNew.collection,
  customers: customers.collection,
  drivers: driversNew.collection,
});

export default connect(mapStateToProps)(ReconcileBaseFilter);
