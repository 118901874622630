const jobsDefaultState = {
  collection: [],
  apiIsLoading: false,
  lastApiCallSucceeded: true,
  stages: {
    collection: [],
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
};

export default (state = jobsDefaultState, data) => {
  switch (data.type) {
    case 'JOBS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'JOBS_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: data.jobs,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'JOBS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'STAGES_REQUESTED':
      return {
        ...state,
        stages: {
          ...state.stages,
          apiIsLoading: true,
        },
      };
    case 'STAGE_REQUEST_SUCCEEDED':
      return {
        ...state,
        stages: {
          collection: data.stages,
          lastApiCallSucceeded: true,
          apiIsLoading: false,
        },
      };
    case 'STAGE_REQUEST_FAILED':
      return {
        ...state,
        stages: {
          ...state.stages,
          lastApiCallSucceeded: false,
          apiIsLoading: false,
        },
      };
    default:
      return state;
  }
};
