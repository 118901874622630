import { combineReducers } from '@reduxjs/toolkit';
import {
  center,
  zoomSelected,
  zoomDefault,
} from '../../components/globalInfo/mapConstants';
import addCustomer from './AddCustomer';

const initialStateFilterCustomers = {
  filter: '',
};

const filterCustomers = (state = initialStateFilterCustomers, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_NAME':
      return {
        filter: action.name,
      };
    case 'CLEAR_STORE_MANAGE_CUSTOMERS':
      return initialStateFilterCustomers;
    default:
      return state;
  }
};

const initialModalState = {
  name: '',
  contactPhone: '',
  address: '',
  city: '',
  state: '',
  startLatitude: center[1],
  startLongitude: center[0],
  latitude: '',
  longitude: '',
  customerId: '',
  specialInstructions: '',
  loadingCoordinates: false,
  loadingCoordinatesError: '',
  geofenceRange: '',
  email: '',
  zoom: zoomDefault,
};

const modalWellSite = (_state = initialModalState, action) => {
  switch (action.type) {
    case 'JOB_MANAGER_SET_WELL_SITE_VALUE':
      return {
        ..._state,
        [action.name]: action.value,
      };

    case 'JOB_MANAGER_SELECT_WELL_SITE_COORDINATES':
      return {
        ..._state,
        longitude: action.lng,
        latitude: action.lat,
      };
    case 'JOB_MANAGER_SET_WELL_SITE':
      const {
        address,
        city,
        name,
        state,
        latitude,
        longitude,
        loading_site_special_instruction: specialInstructions,
        contactPhone,
        id,
        customerId,
        geofenceRange,
        companyOwnerId,
        email,
      } = action.initWellSite;
      return {
        ..._state,
        zoom: zoomSelected,
        address,
        city,
        name,
        state,
        latitude,
        longitude,
        specialInstructions,
        contactPhone,
        startLatitude: latitude,
        startLongitude: longitude,
        id,
        customerId: companyOwnerId,
        geofenceRange,
        email
      };
    case 'JOB_MANAGER_CLEAR_MODAL_WELL_SITE':
      return initialModalState;
    default:
      return _state;
  }
};

export default combineReducers({
  filterCustomers,
  modalWellSite,
  addCustomer,
});
