import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';

import { useGetDriversQuery } from 'features/api/apiSlice';
import { useLocationDetails } from 'api/v2/locations';
import { useCommodities } from 'api/v2/commodities';

import { switchLocationType } from './helpers';
import SiteInfo from './SiteInfo';
import OrdersList from './OrdersList';
import LocationDetailsMap from './Map';
import Timing from './Timing';
import PO from './PO';
import Commodities from './Commodities';

const LocationDetails = () => {
  const { locationId } = useParams();
  const { data: location, isLoading } = useLocationDetails(locationId);
  const { data: drivers } = useGetDriversQuery();
  const { data: commodities } = useCommodities();

  const { sandSiteDetails, averageLoadTime, stats, timings, purchaseOrders } =
    location ?? {};

  const driverOptions = useMemo(() => {
    if (!drivers || !sandSiteDetails || !locationId) {
      return [];
    }

    return drivers.filter(item => {
      if (sandSiteDetails[0].locationType === 4) {
        return item.staging_site_id === Number(locationId);
      }
      return (
        item.sand_site_id === Number(locationId) ||
        item.well_site_id === Number(locationId)
      );
    })
      .map(driver => {
        const commodity = commodities.find(item => item.id === driver.sand_type);

        return {
          ...driver,
          commodity
        }
      });
  }, [drivers, sandSiteDetails, locationId, commodities]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ marginBottom: 60 }}>
      <Header
        title={`${switchLocationType(sandSiteDetails[0].locationType).title
          } Details`}
      />
      <Row>
        <SiteInfo
          sandSite={sandSiteDetails[0]}
          averageTimeLoad={averageLoadTime}
          totalOrders={stats.totalOrders[0].countOfOrders}
        />
      </Row>
      <Row>
        <Card>
          <Card.Header as="h5">Orders</Card.Header>
          <Card.Body>
            <Row>
              <OrdersList
                switchLocationType={switchLocationType}
                locationType={sandSiteDetails[0].locationType}
                drivers={driverOptions}
              />
              <LocationDetailsMap
                drivers={driverOptions}
                sandSite={sandSiteDetails[0]}
              />
            </Row>
          </Card.Body>
        </Card>
      </Row>
      {timings && (
        <Row>
          <Timing timings={timings} />
        </Row>
      )}
      {[1, 2, 5].includes(sandSiteDetails[0].locationType) && (
        <Row>
          <Col lg={6} key="po">
            <PO purchaseOrders={purchaseOrders} />
          </Col>
          <Col lg={6} key="SandTypes">
            <Commodities sandVolumes={stats.sandVolumes} />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default LocationDetails;
