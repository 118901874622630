import { client } from 'api/client';

/**
 * @returns {Promise<Array>}
 */
export const getStorageTypes = async () => {
  const { data } = await client.get('v2/storage-types');
  return data.data;
};

/**
 * @typedef {{
 *  id?: number;
 *  name: string;
 *  description: string;
 *  sourceId: number;
 * }} StorageType
 */

/**
 *
 * @param {StorageType} storageType
 */
export const addStorageType = async storageType => {
  const { data } = await client.post('v2/storage-types', storageType);
  return data.data;
};

/**
 *
 * @param {{
 *  storageTypeId: number;
 *  newStorageType: StorageType
 * }} params
 */
export const editStorageType = async ({ storageTypeId, newStorageType }) => {
  const { data } = await client.patch(
    `v2/storage-types/${storageTypeId}`,
    newStorageType,
  );
  return data.data;
};

/**
 *
 * @param {number} storageTypeId
 */
export const deleteStorageType = async storageTypeId => {
  const { data } = await client.delete(`v2/storage-types/${storageTypeId}`);
  return data.data;
};

export const getStorageSources = async () => {
  const { data } = await client.get('v2/storage-types/sources');
  return data.data;
};
