import React from 'react';
import { Col, Card, Row } from 'react-bootstrap';

const Jobs = ({ jobStats: { ongoing, completed } }) => (
  <Card>
    <Card.Header>
      <Card.Title>
        <h5>Jobs</h5>
      </Card.Title>
    </Card.Header>
    <Card.Body className="text-center" style={{ paddingTop: '25px' }}>
      <Row>
        <Col lg={6} md={6} sm={6} xs={6} style={{ color: 'rgb(250,173,80)' }}>
          <h1 className="no-margins">{ongoing}</h1>
          <div className="font-bold">Ongoing</div>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} style={{ color: 'rgb(240,83,98)' }}>
          <h1 className="no-margins">{completed}</h1>
          <div className="font-bold">Completed</div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);
export default Jobs;
