import React from 'react';
import PropTypes, { objectOf } from 'prop-types';
import Icon from 'components/shared/Icon';
import { getDistrictById } from './helpers';

const DriverInfoWindow = ({ order }) => {
  const {
    districts,
    jobDistricts,
    email,
    deviceVersion,
    contactNumber,
    rating,
    alerts,
    stage,
    orderId,
  } = order;

  let district = null;
  districts.forEach(driverDistrict => {
    district = getDistrictById(jobDistricts, driverDistrict.id);
  });

  return (
    <div className="driver-content">
      <div className="driver-content__email">
        <Icon icon="email" colour="white50" data-testid="driver-email-icon" />
        <span
          className="driver-content--white"
          data-testid="driver-email-content">
          {email || 'Unavailable'}
        </span>
      </div>
      <div className="driver-content__columns">
        <span className="driver-content__columns--item">
          <Icon
            icon="mobile"
            colour="white50"
            data-testid="driver-mobile-icon"
          />
          <span
            className="driver-content--white"
            data-testid="driver-mobile-content">
            {contactNumber || 'Unavailable'}
          </span>
        </span>
        <span className="driver-content__columns--item">
          <Icon icon="apple" colour="white50" data-testid="driver-apple-icon" />
          <span>
            Version:
            <span
              className="driver-content--white"
              data-testid="driver-version-content">
              {deviceVersion && deviceVersion !== 'unknown'
                ? ` ${deviceVersion}`
                : ' Unavailable'}
            </span>
          </span>
        </span>
        <span className="driver-content__columns--item">
          <Icon
            icon="district"
            colour="white50"
            data-testid="driver-district-icon"
          />
          <span
            className="driver-content--white"
            data-testid="driver-district-content">
            {district ? `#${district.id} | ${district.name}` : 'Unavailable'}
          </span>
        </span>
        <span className="driver-content__columns--item">
          <Icon icon="star" colour="white50" data-testid="driver-star-icon" />
          <span>
            Star rating:
            <span
              className="driver-content--white"
              data-testid="driver-star-content">
              {rating || ' Unavailable'}
            </span>
          </span>
        </span>
      </div>
      <div
        className={`${
          alerts && alerts.driverIsLate ? 'info-window__warning-container' : ''
        }`}>
        <div
          className={`${
            alerts && alerts.driverIsLate
              ? 'info-window__warning-container--message'
              : ''
          }`}>
          <Icon icon="driver" colour="white" data-testid="driver-driver-icon" />
          <span
            className={`${
              alerts && alerts.driverIsLate
                ? 'info-window__warning-container--text'
                : ''
            }`}>
            {`${alerts && alerts.driverIsLate ? 'Late Order: ' : 'Order'}`}
            <span className="white" data-testid="driver-order-content">
              <span> #</span>
              <a
                href={`order-details/${orderId}`}
                target="_blank"
                rel="noopener noreferrer">
                {orderId}
              </a>
              <span> | Stage </span>
              {stage || 'unavailable'}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

DriverInfoWindow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
};

export default DriverInfoWindow;
