import moment from 'moment';

export const convertTime = (time, format = 'YYYY-MM-DD hh:mm A') =>
  moment
    .utc(time)
    .local()
    .format(format);

export const convertTimeWithSeconds = (time, format = 'YYYY-MM-DD hh:mm:ss A') =>
  moment
    .utc(time)
    .local()
    .format(format);

export const newConvertTime = time =>
  moment
    .utc(time)
    .local()
    .format('D MMM YYYY H:mm');

export const convertTimeNoYear = time =>
  moment
    .utc(time)
    .local()
    .format('MM-DD hh:mm A');

export const convertTimeLocal = (time, format) =>
  moment(time)
    .local()
    .format(format);

export const toUTC = (time, format = 'YYYY-MM-DD HH:mm') =>
  moment(time, format)
    .utc()
    .format(format);

export const timeFromNow = time =>
  moment
    .utc(time)
    .local()
    .fromNow();

export const minDuration = time => {
  const z = moment.duration(Math.round(time), 'minutes');
  return `${z.hours()} h ${z.minutes()} m`;
};

export const convertTimeForTimeline = time =>
  moment
    .utc(time)
    .local()
    .format('DD/MM/YYYY HH:mm:ss');

export const convertTimeForProfiles = time =>
  moment
    .utc(time)
    .local()
    .format('MM/DD/YYYY hh:mm A');

export const convertDateToTimeString = date =>
  moment(date)
    .format('hh:mm a');

export const convert24HourTo12Hour = time =>
  moment(time, "HH:mm:ss")
    .format('hh:mm a');

export const convertTimeZone = (tzInput) => {
  const timeZoneMap = {
    'America/Los_Angeles': 'Pacific Standard',
    'America/Denver': 'Mountain Standard',
    'America/Chicago': 'Central Standard',
    'America/New_York': 'Eastern Standard',
  };

  const timeZone = timeZoneMap[tzInput];

  return timeZone || '';
}

export const convertIntToDay = (dayInput) => {
  const dayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  };

  const day = dayMap[dayInput] || '';

  return day;
}

export const convertTimeWithCheck = (time, format = 'YYYY-MM-DD hh:mm A') => {
  const convertedTime = moment
    .utc(time)
    .local()
    .format(format);
  if (convertedTime && convertedTime !== 'Invalid date') {
    return convertedTime;
  }
  return '';
}
