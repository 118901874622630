import React from 'react';
import { getEnvironment } from 'utils/getEnvironment';
import _ from 'lodash';

const EnvironmentBanner = () => {
  // eslint-disable-next-line no-underscore-dangle
  const env = getEnvironment(window._env_.API_BASE_URL);

  if (env === 'production'){
    return null;
  }

  return (<div style={{ flexGrow: 1, backgroundColor: env === 'development' ? '#23c6c8' : '#f8ac59', textAlign: 'center' }}>
    <h2 className="header__title--name">{_.startCase(env)} Environment</h2>
  </div>)
};
export default EnvironmentBanner;
