// import Chat from 'components/views/Chat';
import Roles from 'components/views/Roles';
import LocationDetails from 'components/views/LocationDetails';
import SandSiteSchedule from 'components/views/SandSiteSchedule';
import Users from 'components/views/Users';
import ViewPO from 'components/views/ViewPO';

import { authorize } from 'components/common/Authorize';

const navItems = [
  {
    title: 'HOME',
    path: '/home',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" class="icon--fill"><path d="M10.047,4.603h-4.85C4.537,4.603,4,5.14,4,5.8v4.246c0,0.661,0.536,1.197,1.197,1.197h4.85 c0.661,0,1.198-0.536,1.198-1.197V5.8C11.244,5.14,10.708,4.603,10.047,4.603z M18.618,4.603H13.77 c-0.662,0-1.199,0.536-1.199,1.197v4.246c0,0.661,0.537,1.197,1.199,1.197h4.851c0.661,0,1.196-0.536,1.196-1.197V5.8 C19.816,5.14,19.281,4.603,18.618,4.603L18.618,4.603z M10.047,12.572h-4.85C4.537,12.572,4,13.107,4,13.77v4.246 c0,0.66,0.536,1.196,1.197,1.196h4.85c0.661,0,1.198-0.536,1.198-1.196v-4.248C11.244,13.107,10.707,12.572,10.047,12.572z M18.618,12.572H13.77c-0.662,0-1.199,0.535-1.199,1.197v4.246c0,0.66,0.537,1.197,1.199,1.197h4.851 c0.661,0,1.196-0.537,1.196-1.197V13.77C19.816,13.107,19.28,12.572,18.618,12.572L18.618,12.572z"/></svg>',
    component: LocationDetails,
    children: [],
  },
  {
    title: 'SCHEDULE',
    path: '/schedule',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M9.786 12.812c0-.2-.166-.362-.371-.362H8.121a.367.367 0 0 0-.37.362v1.265c0 .2.165.362.37.362h1.294c.205 0 .37-.162.37-.362v-1.265zM13.02 12.812c0-.2-.166-.362-.37-.362h-1.295a.367.367 0 0 0-.37.362v1.265c0 .2.166.362.37.362h1.295c.204 0 .37-.162.37-.362v-1.265zM16.255 12.812c0-.2-.166-.362-.37-.362H14.59a.367.367 0 0 0-.37.362v1.265c0 .2.165.362.37.362h1.294c.205 0 .371-.162.371-.362v-1.265zM9.786 15.974c0-.2-.166-.363-.371-.363H8.121a.366.366 0 0 0-.37.363v1.265c0 .2.165.362.37.362h1.294c.205 0 .37-.162.37-.362v-1.265zM13.02 15.974c0-.2-.166-.363-.37-.363h-1.295a.366.366 0 0 0-.37.363v1.265c0 .2.166.362.37.362h1.295c.204 0 .37-.162.37-.362v-1.265zM16.255 15.974c0-.2-.166-.363-.37-.363H14.59a.367.367 0 0 0-.37.363v1.265c0 .2.165.362.37.362h1.294c.205 0 .371-.162.371-.362v-1.265z"/><path fill-rule="nonzero" d="M18.57 5.778v1.933c0 .873-.726 1.577-1.619 1.577h-1.02c-.894 0-1.628-.704-1.628-1.577V5.77h-4.6v1.94c0 .873-.735 1.577-1.628 1.577H7.054c-.893 0-1.618-.704-1.618-1.577V5.778c-.78.023-1.422.655-1.422 1.431v11.323c0 .79.655 1.44 1.464 1.44h13.049c.807 0 1.464-.65 1.464-1.44V7.21c0-.776-.641-1.408-1.422-1.43zm-.475 12.049a.626.626 0 0 1-.633.619H6.515a.626.626 0 0 1-.633-.619v-5.849c0-.342.284-.619.633-.619h10.947c.35 0 .633.277.633.62v5.848z"/><path d="M7.05 8.252h1.01a.549.549 0 0 0 .555-.542V4.542c0-.3-.249-.542-.555-.542H7.05a.549.549 0 0 0-.555.542V7.71c0 .3.249.542.555.542zM15.917 8.252h1.01a.548.548 0 0 0 .555-.542V4.542c0-.3-.249-.542-.555-.542h-1.01a.549.549 0 0 0-.554.542V7.71c0 .3.248.542.554.542z"/></svg>',
    component: SandSiteSchedule,
    children: [],
  },
  {
    title: 'Teams',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><g transform="translate(4 5.013)"><ellipse cx="13.499" cy="1.8" rx="1.5" ry="1.8"/><ellipse cx="2.5" cy="1.8" rx="1.5" ry="1.8"/><path d="M4 5.5c0-.682.143-1.32.379-1.9H2c-1.1 0-2 .9-2 2v2h4.474A4.978 4.978 0 0 1 4 5.5zM14 3.6h-2.545c.236.579.38 1.219.38 1.9a4.99 4.99 0 0 1-.474 2.1H16v-2c0-1.1-.9-2-2-2z"/><path d="M10.68 8.686A2.886 2.886 0 0 0 10 8.6h-.756c.939-.581 1.59-1.743 1.59-3.1 0-1.932-1.307-3.5-2.917-3.5C6.307 2 5 3.568 5 5.5c0 1.357.65 2.519 1.591 3.1H6c-.28 0-.547.052-.805.125C3.935 9.08 3 10.231 3 11.6v2h10v-2a3.006 3.006 0 0 0-2.32-2.914z"/></g></svg>',
    path: '/teams',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Roles',
        path: '/roles',
        component: Roles,
        children: [],
      },
    ],
  },
  {
    title: 'PO',
    path: '/po',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M4 4h4.328v4.328H4zM9.836 4h4.328v4.328H9.836zM15.672 4H20v4.328h-4.328zM15.672 9.836H20v4.328h-4.328zM15.672 15.672H20V20h-4.328zM4 15.672h4.328V20H4zM4 9.836h4.328v4.328H4zM9.836 15.672h4.328V20H9.836zM9.836 9.836h4.328v4.328H9.836z"/></svg>',
    component: ViewPO,
    children: [],
  },
  // {
  //   title: 'CHAT',
  //   path: '/chat',
  //   icon:
  //     '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M17.65 6.34a8.003 8.003 0 0 0-11.31 0 8 8 0 0 0-.335 10.95l.08.067c-.303.652-.822 1.457-1.647 1.863-.273.133-.21.539.09.586.901.14 2.195-.017 3.4-.928l.016.013a7.998 7.998 0 0 0 9.703-1.244A7.996 7.996 0 0 0 17.65 6.34z"/></svg>',
  //   component: Chat,
  //   children: [],
  // },
];

// add users to teams
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Users',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems
    .find(item => item.title === 'Teams')
    .children.push({
      title: 'Users',
      path: '/users',
      component: Users,
      children: [],
    });
}

export default navItems;
