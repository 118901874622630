import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import targetValue from 'utils/targetValue';
import actions from 'store/actions/DistrictDetails';

import { useAddDistrictUnit } from 'api/v2/districts';

const AddUnit = ({
  clear,
  sandTypes,
  sandSites,
  districtId,
  vendors,
  addUnit,
  showAddModal,
  closeAddModal,
}) => {
  useEffect(() => () => {
    clear();
  }, []);

  const { mutate: addDistrictUnit, isPending } = useAddDistrictUnit(districtId);
  const [inputValues, _setInputValues] = useState(addUnit ?? {});

  const setInputValue = (value, name) =>
    _setInputValues({ ...inputValues, [name]: targetValue(value) });

  const submitAdd = (e, closeModal) => {
    e.preventDefault();
    addDistrictUnit({ ...inputValues, id: districtId }, {
      onSuccess: closeModal
    });
  }

  const adding = isPending;

  const [saveDisabled, setSaveDisabled] = useState(true);

  const commodityOptions = sandTypes
    .filter(item => item.commodityType === 3)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const sandSiteOptions = sandSites
    .filter(item => item.locationType === 3)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const vendorOptions = vendors.map(item => ({
    value: item.id,
    label: item.name,
  }));

  // determine add disabled
  useEffect(() => {
    let disabled = false;
    if (
      !inputValues.sandVendorId ||
      !inputValues.dollarsPerTon ||
      !inputValues.sandSiteId ||
      !inputValues.priority ||
      !inputValues.sandTypeId
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  }, [inputValues]);

  return (
    <Modal
      className="modal-container"
      show={showAddModal}
      onHide={closeAddModal}>
      <Modal.Header>
        <Modal.Title as="h3">Add New Unit</Modal.Title>
      </Modal.Header>
      <form onSubmit={e => submitAdd(e, closeAddModal)}>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Please Select Commodity"
                onChange={item => setInputValue(item.value, 'sandTypeId')}
                options={commodityOptions}
                required
                testSelector="district-details_add-unit_commodity_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Please Select Disposal Unit"
                onChange={item => setInputValue(item.value, 'sandSiteId')}
                options={sandSiteOptions}
                value={sandSiteOptions.find(item => item.value === inputValues.sandSiteId)}
                required
                testSelector="district-details_add-unit_disposal-unit_select"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'dollarsPerTon')}
                value={inputValues.dollarsPerTon}
                label="Price per unit"
                required
                min="0"
                step="any"
                testSelector="district-details_add-unit_price-per-unit_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Please Select Commodity Vendor"
                onChange={item => setInputValue(item.value, 'sandVendorId')}
                options={vendorOptions}
                value={vendorOptions.find(item => item.value === inputValues.sandVendorId)}
                required
                testSelector="district-details_add-unit_commodity-vendor_select"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'priority')}
                value={inputValues.priority}
                label="Priority"
                min="0"
                required
                testSelector="district-details_add-unit_priority_input"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeAddModal}
            disabled={adding}
            colour="white"
            testSelector="district-details_add-unit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={adding || saveDisabled}
            testSelector="district-details_add-unit_save_btn">
            {adding ? 'Loading' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  addUnit: state.district.addUnit,
  vendors: state.lookUp.vendors,
  sandTypes: state.lookUp.sandTypes,
  sandSites: state.lookUp.sandSites,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(actions.clearAddUnit()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddUnit);
