import { connect } from 'react-redux';
import React, { useState } from 'react';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import AddLocation from 'components/views/Locations/AddLocationModal';
import locationsActions from 'store/actions/Locations';
import Header from 'components/common/Header';
import Icon from 'components/shared/Icon';

const LocationsHeader = ({ clearStore, clearModalState }) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const openAdd = () => {
    clearStore();
    clearModalState();
    setShowAddModal(true);
  };

  const closeAdd = () => {
    setShowAddModal(false);
  };

  return (
    <>
      <Header title="Locations Profile">
        <Authorize
          abilityPermissions={[
            { resource: 'Locations', permissions: ['create'] },
          ]}>
          <Button
            onClick={openAdd}
            testSelector="locations_header_add_btn"
            className='float-end'
            inverse>
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Location
          </Button>
        </Authorize>
      </Header>

      {showAddModal && (
        <AddLocation
          openModal={showAddModal}
          closeModal={closeAdd}
          action="add"
        />
      )}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  clearModalState: () => dispatch(locationsActions.clearModalState()),
  clearStore: () => dispatch(locationsActions.clearLocationStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsHeader);
