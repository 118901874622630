import moment from 'moment';

import { cloneDeep } from 'lodash'
import booleanToNumber from 'utils/booleanToNumber';

export const formatAddJobParams = (params) => {
  const formattedParams = cloneDeep(params);

  formattedParams.rateTableId = 0;
  formattedParams.demurrageTableId = 0;
  formattedParams.startDate = moment(formattedParams.startDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');
  formattedParams.endDate = moment(formattedParams.endDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');
  formattedParams.certificates = formattedParams?.certificates?.map(c => c.value) ?? undefined;
  formattedParams.operatorList = formattedParams?.operatorList?.map(o => o.value) ?? undefined;
  formattedParams.sandTicketDesign = formattedParams?.sandTicketDesign?.map(s => ({ sandTicketTypeId: s.value })) ?? undefined;
  formattedParams.districtIds = formattedParams.districtIds?.map(d => d.value) ?? undefined;
  formattedParams.sendCompletionCodeToDriver = booleanToNumber(formattedParams.sendCompletionCodeToDriver);

  if (formattedParams.jobOperationType === 12) {
    formattedParams.equipment = [5];
    formattedParams.useCommodityPrice = 0;
    formattedParams.numberOfStages = 1;
    formattedParams.pumpTime = 1;
    formattedParams.wirelineTime = 1;
    formattedParams.messagesAllowed = 0;
    delete formattedParams.storageConfig;
    delete formattedParams.jobDesign;
    delete formattedParams.phoneNumbers;
  } else {
    formattedParams.phoneNumbers = formattedParams.messagesAllowed
      ? formattedParams.phoneNumbers.map(p => `${p.code}${p.number}`)
      : undefined;
    formattedParams.useCommodityPrice = booleanToNumber(formattedParams.useCommodityPrice);
    formattedParams.messagesAllowed = booleanToNumber(formattedParams.messagesAllowed);
    formattedParams.equipment = formattedParams?.equipment?.map(e => e.value) ?? undefined;
  }

  formattedParams.billingProfileId = formattedParams?.billingProfileId ?? 0;
  formattedParams.allIn = booleanToNumber(formattedParams.allIn);

  if (!formattedParams.targetStagesPerDay) {
    delete formattedParams.targetStagesPerDay;
  }

  if (!formattedParams.totalWeightPerJob) {
    delete formattedParams.totalWeightPerJob;
  }

  return formattedParams;
};
