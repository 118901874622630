import { connect } from 'react-redux';
import { ordersRequested } from 'store/actions/Orders';
import ReconcileActionItems from './ReconcileActionItems';

const mapStateToProps = ({ orders }) => ({
  isLoading: orders.apiIsLoading,
});

const mapDispatchToProps = dispatch => ({
  getOrders: params => dispatch(ordersRequested(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconcileActionItems);
