import React from 'react';
import Select from 'components/shared/Select';
import { useLocations } from 'api/v2/locations';
import multiSelectOptions from 'utils/multiSelectOptions';

const LocationsSelect = ({ onChange, defaultValue }) => {
  const { data: locations } = useLocations();

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      onChange={onChange}
      options={multiSelectOptions(locations, 'id', 'name')}
      placeholder="Select Location"
      defaultValue={defaultValue}
      testSelector="order-list_filters_location_select"
    />
  );
};
export default LocationsSelect;
