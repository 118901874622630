import React, { useEffect, useState } from 'react';

import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';

import targetValue from 'utils/targetValue';
import { convertTime } from 'utils/convertTime';
import { useAddOrderComment, useOrderComments } from 'api/v2/orders';

const Comments = ({ order }) => {
  const { data: comments, isLoading } = useOrderComments({ orderId: order.order_id });
  const { mutate: postComment } = useAddOrderComment({
    orderId: order.order_id,
  });

  const [comment, setComment] = useState('');

  const scrollTop = () => {
    setTimeout(() => {
      const block = document.querySelector('.content-comments-order');
      if (block) {
        block.scrollTop = block.scrollHeight;
      }
    }, 0);
  };

  useEffect(() => {
    scrollTop();
  }, [comments]);

  const handleSubmit = e => {
    e.preventDefault();
    postComment(comment);
  };

  const renderComment = item => (
    <li key={item.comment_id} className="list-group-item">
      <h5 className="text-navy">@{item.character_type}</h5>
      <p>{item.comment}</p>
      <small className="text-muted">{convertTime(item.created_at)}</small>
    </li>
  );

  const renderCommentsList = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (!comments.length) {
      return <div>There are no comments on this order yet.</div>;
    }
    return (
      <div
        className="content-comments-order no-padding"
        style={{ minHeight: '300px' }}>
        <ul className="list-group">{comments.map(renderComment)}</ul>
      </div>
    );
  };

  return (
    <div>
      <h3>Comments for order</h3>
      {renderCommentsList()}
      <div className="order-comments-footer">
        <div className="form-group">
          <form role="form" aria-autocomplete="none" onSubmit={handleSubmit}>
            <div className="col-xs-12 no-padding">
              <Input
                onChange={e => setComment(targetValue(e))}
                value={comment}
                label="Write your comment here"
                required
                testSelector="orders_info_comments_text_input"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Comments;
