import { loginActions } from 'store/actions/Login';
import { history } from 'helpers/history';
import Constants from './constants';

const switchRole = role => dispatch => {
  const jwtTemp = localStorage.getItem('token');

  dispatch(
    loginActions.authenticate(
      jwtTemp,
      role.roleId,
      role.entityId,
      role.roleTypeId,
    ),
  );
};

const unLogin = () => dispatch => {
  localStorage.clear();
  history.push('/login');
  dispatch({ type: Constants.CLEAR });
};

export default {
  switchRole,
  unLogin,
};
