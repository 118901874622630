import React, { useEffect, useState } from 'react';
import LogoutModal from '../shared/LogoutModal';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

const AppLogout = ({ children, sessionLifetime }) => {
  const [showModal, turnShowModal] = useState(false);
  const timeOut = Number(sessionLifetime.value) * 60000;
  let timer;
  const init = () => {
    Object.values(events).forEach(item => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  };

  useEffect(() => {
    init();
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach(item => {
        window.removeEventListener(item, resetTimer);
      });
      turnShowModal(true);
    }, timeOut);
  };

  return (
    <>
      {showModal && (
        <LogoutModal
          showModal={showModal}
          close={() => {
            turnShowModal();
            init();
          }}
          handleTimer={handleTimer}
        />
      )}
      {children}
    </>
  );
};

export default AppLogout;
