import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/ManageDatabase';
import React from 'react';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

const Filter = ({ filter, setFilterValue, vendorTypes }) => {
  const { types, name } = filter;
  const vendorTypesOptions = vendorTypes.map(item => ({
    value: item.id,
    label: item.name,
  }));
  return (
    <div className="row">
      <div className="form-group has-feedback col-md-3">
        <Input
          onChange={e => setFilterValue(targetValue(e), 'name')}
          value={name}
          label="Name"
          testSelector="database_vendors_filter_name_input"
        />
      </div>
      <div className="form-group has-feedback col-md-3">
        <Select
          label="Vendor Type"
          options={vendorTypesOptions}
          onChange={selected => setFilterValue(selected, 'types')}
          value={types}
          accountForPadding={false}
          isMulti
          closeMenuOnSelect={false}
          testSelector="database_vendors_filter_types_select"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filter: state.database.vendors.filter,
});

const mapDispatchToProps = dispatch => ({
  setFilterValue: (e, name) => dispatch(actions.setFilterValue(e, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filter);
