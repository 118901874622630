import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import SearchInput from '../SearchInput';
import UserWidget from '../UserWidget';
import EnvironmentBanner from './EnvironmentBanner';

const Header = ({ clearState, toggleNavigation, changeEventsBarStatus }) => {
  const [showSearch, setShowSearch] = useState(false);

  useEffect(
    () => () => {
      clearState();
    },
    [clearState],
  );

  const showStyle = { display: 'block' };
  const hideStyle = { display: 'none' };

  const roles = JSON.parse(localStorage.getItem('roles'));
  const entityId = JSON.parse(localStorage.getItem('selectedEntity'));
  const selectedRoleType = JSON.parse(
    localStorage.getItem('selectedRoleTypeId'),
  );
  const selectedRoles = roles.filter(role => role.entityId === entityId);
  const selectedRole = selectedRoles.find(
    item => item.roleTypeId === selectedRoleType,
  );

  return (
    <header className="header-container" data-test="header">
      <div className="header-container__loading-bar">
        <LoadingBar maxProgress={95} className="lazur-bg" />
      </div>

      <div className="header-container__header header">
        <div className="header__nav-toggle">
          <Button
            onClick={toggleNavigation}
            testSelector="header_toggle-navigation_btn"
            theme="square"
            colour="white">
            <Icon icon="bars" />
          </Button>
        </div>

        <div className="header__title" style={showSearch ? hideStyle : {}}>
          <h2 className="header__title--name">
            {selectedRole && selectedRole.entityName
              ? selectedRole.entityName
              : 'Automatize Logistics Testing This, LLC'}
          </h2>
        </div>

        <div className="header__search">
          <div
            className="header__search--form"
            style={showSearch ? showStyle : {}}>
            <SearchInput testSelector="common_header_search_input" />
          </div>
          <div
            className="header__search--mobile-toggle"
            style={{ marginLeft: showSearch ? '5px' : 0 }}>
            <Button
              onClick={() => setShowSearch(!showSearch)}
              testSelector="header_search_btn"
              theme="square"
              colour="white">
              <Icon icon="search" />
            </Button>
          </div>
        </div>

        <div className="header__user-widget">
          <UserWidget />
        </div>

        <div className="header__event-toggle">
          <Button
            onClick={changeEventsBarStatus}
            testSelector="header_event-toggle_btn"
            inverse
            theme="square">
            <Icon icon="manage" />
          </Button>
        </div>
      </div>
      
      <EnvironmentBanner />
    </header>
  );
};

export default Header;
