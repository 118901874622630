import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import BarGraph from 'components/shared/BarGraph';
import { timeFix } from 'components/views/Home/WaterProductionInfoWindow/helpers';

const WellSiteInfoWindow = ({ order }) => {
  const {
    numberOfOrders,
    numberOfTrucks,
    trucksBehindSchedule,
    alerts,
    silos,
    jobId,
  } = order;

  return (
    <div className="wellsite-content">
      <div className="info-window__status-container">
        <div className="status-block trucks-vs-orders">
          <Icon icon="trucks_vs_orders" />
          <span className="status-block__text">
            Orders vs Trucks on Site:
            <strong
              data-testid="well-site-orders-trucks"
              className="status-block__text__values">
              {` ${numberOfOrders || '0'} / ${numberOfTrucks || '0'} `}
            </strong>
          </span>
        </div>
        <div className="status-block trucks-behind">
          <Icon icon="trucks_behind" />
          <span className="status-block__text">
            Trucks Behind Schedule:
            <strong
              data-testid="well-site-trucks-behind-schedule"
              className="status-block__text__values">
              {` ${trucksBehindSchedule || '0'} `}
            </strong>
          </span>
        </div>
      </div>
      {alerts && alerts.upcomingNPT ? (
        <div className="info-window__warning-container">
          <div className="info-window__warning-container--message">
            <Icon icon="clock" colour="white" />
            <span className="info-window__warning-container--text">
              Estimated time to NPT:
              <span
                data-testid="well-site-estimated-time-npt"
                className="white">
                {' '}
                {timeFix(alerts.upcomingNPT.hoursTo)}
              </span>
            </span>
          </div>
        </div>
      ) : null}
      {alerts && alerts.driverOnSite ? (
        <div className="info-window__warning-container">
          <div className="info-window__warning-container--message">
            <Icon icon="clock" colour="white" />
            <span className="info-window__warning-container--text">
              <span>Delay on site: </span>
              <span data-testid="well-site-average-wait-time" className="white">
                Average wait time
                {` ${alerts.driverOnSite.avgDelayedMinutesOnsite}`} min
              </span>
            </span>
          </div>
        </div>
      ) : null}
      {silos && silos.length > 0 ? (
        <>
          <BarGraph
            data={silos.slice(0, 6).map((silo, index) => ({
              silo: `Silo ${index + 1}`,
              value: Math.round(silo.level),
              color: `${index}`,
            }))}
            maxValue={100}
            type="wellsite"
          />
          {silos.length > 6 && (
            <Button theme="small" testSelector="home_wellsite-window_view-silos_btn">
              <a href={`job-details/${jobId}`}>View all Silos</a>
            </Button>
          )}
        </>
      ) : null}
    </div>
  );
};

WellSiteInfoWindow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

export default WellSiteInfoWindow;
