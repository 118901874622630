import { client } from 'api/client';

export const get = async (id, status) => {
	const numStatus = Number(status);
	const isValid = !Number.isNaN(Number(numStatus));
	const queryItem = isValid ? numStatus : 1;
	const { data } = await client.get(`v2/billing/customer/${id}/payments`, { params: { active: queryItem } });
	return data.data;
};

export const add = async ({ id, data }) => {
	const response = await client.put(`v2/billing/customer/${id}/payments`, data);
	return response.data.data;
}

export const voidPayment = async ({ data, id, customerId }) => {
	const response = await client.put(`v2/billing/customer/${customerId}/payments/${id}/void`, data);
	return response.data.data;
}
