import axios from 'axios';

const events = {
  get() {
    return axios.get('v2/dashboard', {});
  },
  update(id) {
    axios.defaults.baseURL = window._env_.API_BASE_URL;
    return axios.put(`v2/dashboard?lastId=${id}`, {});
  },
};

export default events;
