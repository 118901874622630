import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from 'components/shared/Button';

const TruckInfo = ({ info, isVisible, setIsVisible }) => {
  return (
    <div className="inmodal">
      <Modal
        style={{ top: '20%' }}
        className="modal-container"
        show={isVisible}
        onHide={() => setIsVisible(false)}>
        <div>
          <Modal.Header>
            <h3 className="modal-title">Current Trucks Info</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  flex: '1 0 50%',
                  display: 'flex',
                  borderBottom: '1px solid',
                  borderBottomColor: 'rgba(255, 255, 255, .1)',
                  width: '100%',
                  padding: '7px',
                }}>
                <strong
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  Commodity{' '}
                </strong>
                <strong
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  # of Trucks{' '}
                </strong>
              </div>

              {info.map((item, index) => (
                <div
                  key={`truckInfo${index}`}
                  style={{
                    flex: '1 0 50%',
                    display: 'flex',
                    padding: '5px',
                  }}>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {item?.commodity}{' '}
                  </strong>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {item?.count}{' '}
                  </strong>
                </div>
              ))}

              {!info?.length ? (
                <div className="font-bold text-danger textCenter">{`NO Logs`}</div>
              ) : (
                <div
                  style={{
                    flex: '1 0 50%',
                    display: 'flex',
                    width: '100%',
                    padding: '5px',
                  }}>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {' '}
                  </strong>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      borderTop: '1px solid',
                      borderTopColor: 'rgba(#ffffff, .1)',
                      paddingTop: '5px',
                    }}>
                    {info.reduce((accum, item) => {
                      return accum + item.count;
                    }, 0)}
                  </strong>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setIsVisible(false)}
              colour="white"
              children="Close"
              testSelector="truck-info-details_close_btn"
            />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  jobPlanDetails: state.jobPlans.jobPlanInfo.jobPlanDetails,
});

export default connect(
  mapStateToProps,
  null,
)(TruckInfo);
