import React from 'react';
import { connect } from 'react-redux';
import GoogleMap, { AutomatizeMarker } from 'components/shared/GoogleMap';
import Icon from 'components/shared/Icon';

const infoWindowTitle = {
  driver: item => (
    <>
      <Icon icon="driver" colour="white" />
      <span className="info-window__title--text">
        #
        <a
          href={`/${window.location.pathname.split('/')[1]}/driver-details/${
            item.id
          }`}>
          {item.id}
        </a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  wellsite: item => (
    <>
      <Icon icon="wellsite" colour="white" />
      <span className="info-window__title--text">
        <strong>#{item.id} </strong>
        {item.name}
      </span>
    </>
  ),
  sandsite: item => (
    <>
      <Icon
        icon={item.locationType === 2 ? 'sandsite' : 'water_disposal'}
        colour="white"
      />
      <span className="info-window__title--text">
        <strong>#{item.id} </strong>
        {item.name}
      </span>
    </>
  ),
};

const TrackDriversMap = props => {
  const { driver, sandPoints, wellPoints, drivers, zoom } = props;

  return (
    <div style={{ height: 650, position: 'relative' }}>
      <GoogleMap
        fitMarkers
        initialCenter={{ lat: 34.50885, lng: -104.245758 }}
        center={{ lat: driver.latitude, lng: driver.longitude }}
        zoom={zoom}
        setInitialCenter>
        {sandPoints.map(item => (
          <AutomatizeMarker
            position={{ lat: item.latitude, lng: item.longitude }}
            type={item.locationType === 2 ? 'sand_site' : 'water_disposal'}
            key={`${item.id}_sandsite`}
            infoWindow={{
              title: infoWindowTitle.sandsite(item),
              width: 380,
            }}
          />
        ))}
        {wellPoints.map(item => (
          <AutomatizeMarker
            position={{ lat: item.latitude, lng: item.longitude }}
            type="well_site"
            key={`${item.id}_wellsite`}
            infoWindow={{
              title: infoWindowTitle.wellsite(item),
              width: 380,
            }}
          />
        ))}
        {drivers.map(item => (
          <AutomatizeMarker
            position={{ lat: item.latitude, lng: item.longitude }}
            type="driver"
            key={`${item.id}_wellsite`}
            status={
              item.order_status === 3 ||
              item.order_status === 4 ||
              item.order_status === 6 ||
              item.order_status === 7 ||
              item.order_status === 8
                ? 'loaded'
                : 'empty'
            }
            infoWindow={{
              title: infoWindowTitle.driver(item),
              content: (
                <div className="driver-content">
                  <div className="driver-content__columns">
                    <span className="driver-content__columns--item">
                      <span>
                        Carrier:
                        <span className="driver-content--white">
                          {` ${item.carrier_name}` || ` Unavailable`}
                        </span>
                      </span>
                    </span>
                    <span className="driver-content__columns--item">
                      <span>
                        Truck №:
                        <span className="driver-content--white">
                          {` ${item.truck_no}` || ` Unavailable`}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              ),
              width: 380,
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = state => ({
  driver: state.trackDrivers.selectLocation.driver,
  zoom: state.trackDrivers.selectLocation.zoom,
});

export default connect(
  mapStateToProps,
  null,
)(
  React.memo(
    TrackDriversMap,
    // Only re-render on changed wellPoints, sandPoints, driver, or drivers
    (prevProps, nextProps) =>
      prevProps.wellPoints === nextProps.wellPoints &&
      prevProps.sandPoints === nextProps.sandPoints &&
      prevProps.driver === nextProps.driver &&
      prevProps.drivers === nextProps.drivers,
  ),
);
