import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'config/axios.config';
import { convertTime } from 'utils/convertTime';

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'BillingPayment',
    'BillingProfile',
    'Customer',
    'Customer-NotarizationSetting',
    'Customer-BillingPayment',
    'Customer-BillingProfile',
    'District',
    'Driver',
    'PurchaseOrder',
    'Tag',
    'Tag-Type',
  ],
  endpoints: builder => ({
    // Customers
    getCustomers: builder.query({
      query: () => ({
        url: 'v2/customer',
        method: 'GET',
      }),
      providesTags: ['Customer'],
      transformResponse: data => data.customers,
    }),

    getCustomerDetails: builder.query({
      query: customerId => ({
        url: `v2/customer/${customerId || 0}/details`,
        method: 'GET',
      }),
      providesTags: result => [{ type: 'Customer', id: result.id }],
    }),

    updateCustomer: builder.mutation({
      query: data => ({
        url: `v2/customer/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: 'Customer', id: arg.id },
      ],
    }),

    getCustomerNotarizationSettings: builder.query({
      query: customerId => ({
        url: `v2/customer/${customerId}/notarization/settings`,
        method: 'GET',
      }),
      transformResponse: data => data.notarizationSettings,
      providesTags: (_result, _err, arg) => [
        { type: 'Customer-NotarizationSetting', id: arg },
      ],
    }),

    updateCustomerNotarizationSettings: builder.mutation({
      query: ({ customerId, data }) => ({
        url: `v2/customer/${customerId}/notarization/settings`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, { customerId }) => [
        { type: 'Customer-NotarizationSetting', id: customerId },
      ],
    }),

    getCustomerBillingPayments: builder.query({
      query: ({ customerId, ...params }) => ({
        url: `v2/billing/customer/${customerId}/payments`,
        method: 'GET',
        params,
      }),
      providesTags: (_res, _err, data) => [
        { type: 'Customer-BillingPayment', id: data.customerId },
      ],
    }),

    addCustomerBillingPayment: builder.mutation({
      query: ({ customerId, data }) => ({
        url: `v2/billing/customer/${customerId}/payments`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingPayment', id: data.customerId },
        'BillingPayment',
      ],
    }),

    voidCustomerBillingPayment: builder.mutation({
      query: ({ customerId, paymentId, data }) => ({
        url: `v2/billing/customer/${customerId}/payments/${paymentId}/void`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingPayment', id: data.customerId },
        { type: 'BillingPayment', id: data.paymentId },
      ],
    }),

    getCustomerBillingProfiles: builder.query({
      query: ({ customerId, ...params }) => ({
        url: `v2/billing/customer/${customerId}/profiles`,
        method: 'GET',
        params,
      }),
      providesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
      ],
    }),

    getCustomerBillingProfileDetails: builder.query({
      query: ({ customerId, profileId }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}`,
        method: 'GET',
      }),
      providesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    getCustomerBillingProfileOrders: builder.query({
      query: ({ customerId, profileId, ...params }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}/orders`,
        method: 'GET',
        params,
      }),
      // transformResponse: data => data.profileOrders,
      providesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    reRateCustomerBillingProfileOrders: builder.mutation({
      query: ({ customerId, profileId, data }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}/orders/re-rate`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    addCustomerBillingProfile: builder.mutation({
      query: ({ customerId, data }) => ({
        url: `v2/billing/customer/${customerId}/profiles`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
      ],
    }),

    addCustomerPaymentToProfile: builder.mutation({
      query: ({ customerId, profileId, data }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    editCustomerPaymentInProfile: builder.mutation({
      query: ({ customerId, profileId, itemId, data }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}/item/${itemId}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    voidCustomerBillingProfile: builder.mutation({
      query: ({ customerId, profileId, data }) => ({
        url: `v2/billing/customer/${customerId}/profiles/${profileId}/active`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_res, _err, data) => [
        { type: 'Customer-BillingProfile', id: data.customerId },
        { type: 'BillingProfile', id: data.profileId },
      ],
    }),

    updateCustomerLogo: builder.mutation({
      query: ({ customerId, formData }) => ({
        url: `v2/customer/${customerId}/logo`,
        method: 'POST',
        data: formData,
        headers: {
          dataType: 'json',
          processData: false,
          contentType: false,
        },
        formData: true,
      }),
      invalidatesTags: (_result, _error, { customerId }) => [
        { type: 'Customer', id: customerId },
      ],
    }),

    updateCustomerAddress: builder.mutation({
      query: data => ({
        url: `v2/customer/${data.id}/address`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Customer', id: data.id },
      ],
    }),

    updateCustomerBillingTerms: builder.mutation({
      query: data => ({
        url: `v2/customer/${data.id}/billing-terms`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Customer', id: data.id },
      ],
    }),

    // Districts
    getDistricts: builder.query({
      query: () => ({
        url: 'v2/district',
        method: 'GET',
      }),
      transformResponse: data => data.district,
      providesTags: ['District'],
    }),

    // Drivers
    getDrivers: builder.query({
      /**
       *
       * @param {{
       *   carrierId? number;
       *   isDeleted? 1|0;
       *   driverId?: number;
       *   customerId?: number;
       *   status?: 1|0;
       * }} data
       */
      query: data => ({
        url: 'v2/driver/search',
        method: 'POST',
        data,
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Driver', id })), 'Driver']
          : ['Driver'],
      transformResponse: res => res.drivers,
    }),

    getDriversPages: builder.query({
      query: data => ({
        url: 'v2/driver/search',
        method: 'POST',
        data,
      }),
      providesTags: result =>
        result
          ? [
            ...result.drivers.map(({ id }) => ({ type: 'Driver', id })),
            'Driver',
          ]
          : ['Driver'],
    }),

    getDriversOsVersions: builder.query({
      query: () => ({ url: 'v2/driver/os-versions', method: 'GET' }),
      providesTags: ['DriverOsVersions'],
      transformResponse: res =>
        // Need shallow copy else returns undefined.
        ({ ...res }),
    }),

    getDriverAppVersions: builder.query({
      query: () => ({ url: 'v2/driver/app-versions', method: 'GET' }),
      providesTags: ['DriverAppVersions'],
      transformResponse: res =>
        // Don't need a shallow copy for lists for some reason.
        res,
    }),

    // For infinite scrolling
    getPaginatedDrivers: builder.query({
      /**
       * @param {{
       *   carrierId? number;
       *   isDeleted? 1|0;
       *   driverId?: number;
       *   customerId?: number;
       *   status?: 1|0;
       *   page: number;
       * }} data
       */
      query: data => ({
        url: 'v2/driver/search',
        method: 'POST',
        data,
      }),
      providesTags: ['PaginatedDriver'],
      transformResponse: res => res.drivers,
    }),

    lookupDrivers: builder.query({
      query: () => ({ url: 'v2/lookup/drivers' }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Driver', id })), 'Driver']
          : ['Driver'],
    }),
  }),
});

export const {
  useGetDistrictsQuery,

  useLookupDriversQuery,
  useGetDriversQuery,
  useGetDriversPagesQuery,
  useGetDriversOsVersionsQuery,
  useGetDriverAppVersionsQuery,
  useGetPaginatedDriversQuery,
} = apiSlice;

export const selectLookupDrivers = apiSlice.endpoints.lookupDrivers.select();
