import * as qs from 'query-string';
import FileSaver from 'file-saver';
import { invoice } from 'services/invoice/service';
import swal from 'bootstrap-sweetalert';

const init = search => dispatch => {
  const tokenStr = qs.parse(search).token;
  const token = tokenStr && JSON.parse(atob(tokenStr.split('.')[1]));
  const data = {};
  if (token && new Date().getTime() / 1000 < token.exp) {
    data.token = tokenStr;
    data.invoiceId = token.invoiceId;
    data.message = `Invoice #${token.invoiceId}`;
  }
  dispatch({ type: 'INVOICE_INIT_DATA', data });
};

const download = type => (dispatch, getState) => {
  const {
    invoice: { token, invoiceId },
  } = getState();
  swal(
    'Thanks',
    'Downloading started! This process may take a while.',
    'success',
  );
  invoice.download({ token, type }).then(msg => {
    const file = new Blob([msg.data], { type: 'application/zip' });
    const typeTxt = (() => {
      switch (type) {
        case 1:
          return 'zip';
        case 2:
          return 'pdf';
        case 3:
          return 'csv';
      }
    })();
    FileSaver.saveAs(file, `invoice_${invoiceId}.${typeTxt}`);
  });
};

const clear = () => ({ type: 'INVOICE_CLEAR' });

export default {
  init,
  download,
  clear,
};
