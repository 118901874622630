import React from 'react';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import targetValue from 'utils/targetValue';

const Filter = ({ filter, setFilter }) => (
  <div className="row">
    <div className="form-group has-feedback col-md-3">
      <Input
        onChange={e => setFilter(targetValue(e), 'id')}
        value={filter?.id ?? ''}
        label="ID"
        testSelector="locations_filter_id_input"
      />
    </div>
    <div className="form-group has-feedback col-md-3">
      <Input
        onChange={e => setFilter(targetValue(e), 'name')}
        value={filter?.name ?? ''}
        label="Name"
        testSelector="locations_filter_name_input"
      />
    </div>
    <div className="form-group has-feedback col-md-3">
      <Select
        onChange={e => setFilter(e?.value, 'type')}
        value={filter?.locationType ?? ''}
        label="Location Type"
        isClearable
        options={[
          { label: 'Well Site', value: '1' },
          { label: 'Sand Site', value: '2' },
          { label: 'Water Disposal', value: '3' },
          { label: 'Staging Site', value: '4' },
          { label: 'Acid Facility', value: '5' }
        ]}
      />
    </div>
    <div className="form-group has-feedback col-md-3">
      <div style={{ marginTop: '13px' }}>
        <Input
          onChange={e => setFilter(targetValue(e), 'includeDeleted')}
          type="checkbox"
          label="Show Deleted"
          isChecked={filter?.includeDeleted}
        />
      </div>
    </div>
  </div>
);

export default Filter;
