import React from 'react';
import Button from 'components/shared/Button';
import Overlay from 'components/shared/Overlay';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import recursiveMenuList from './RecursiveMenu';
import AutomatizeLogo from '../../../assets/automatize_icon.svg';

const Navigation = ({ toggleNavigation, navIsHidden }) => {
  const roles = JSON.parse(localStorage.getItem('roles'));
  const entityId = JSON.parse(localStorage.getItem('selectedEntity'));
  const selectedRoleType = JSON.parse(
    localStorage.getItem('selectedRoleTypeId'),
  );
  const selectedRoles = roles.filter(role => role.entityId === entityId);
  const selectedRole = selectedRoles.find(
    item => item.roleTypeId === selectedRoleType,
  );

  return (
    <div className={`nav-wrapper ${!navIsHidden ? '' : 'nav-wrapper--wide'}`}>
      <nav
        className={`navigation ${
          navIsHidden ? 'navigation--hidden' : 'navigation--narrow'
        }`}
        data-test="navigation"
        role="navigation">
        <div className="navigation__toggle-container">
          <Button
            theme="borderless--square"
            colour="white"
            className="navigation__toggle"
            testSelector="navigation_toggle_btn"
            onClick={toggleNavigation}>
            {navIsHidden ? (
              <Icon icon="left" />
            ) : (
              <>
                <Icon icon="close" />
                <Icon icon="bars" />
              </>
            )}
          </Button>
        </div>
        <div className="navigation__branding">
          <div className="navigation__branding--logo">
            <img
              src={
                selectedRole && selectedRole.entityLogo
                  ? selectedRole.entityLogo
                  : AutomatizeLogo
              }
              onError={e => {
                e.target.onerror = null;
                e.target.src = AutomatizeLogo;
              }}
              className="navigation__branding--logo-image"
              alt="Automatize logo icon"
            />
          </div>

          <div className="navigation__branding--title">
            <h2 className="navigation__branding--title-small">
              {selectedRole && selectedRole.entityName ? (
                <ToolTip
                  title={
                    selectedRole.entityName.length > 15
                      ? selectedRole.entityName
                      : ''
                  }
                  placement="bottom">
                  {`${selectedRole.entityName.substring(0, 15)}`}
                  {selectedRole.entityName.length > 15 && '...'}
                </ToolTip>
              ) : (
                'Automatize Logistics, LLC'
              )}
            </h2>
          </div>
        </div>

        <ul className="navigation__menu">{recursiveMenuList}</ul>
      </nav>
      <Overlay onClick={toggleNavigation} />
    </div>
  );
};

export default Navigation;
