import { combineReducers } from '@reduxjs/toolkit';

const init = {
  totalStats: [{}],
};

export const initTotalStats = (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TOTAL_STATS_INIT_DATA':
      return {
        ...state,
        totalStats: action.data,
      };
    default:
      return state;
  }
};

const initEdit = {
    editJobDesignModal: false,
    editData: [{}],
  };
  
  export const edit = (state = initEdit, action) => {
    switch (action.type) {
      case 'JOB_DETAILS_TOTAL_STATS_TURN':
        return {
          ...state,
          editJobDesignModal: !state.editJobDesignModal,
          editData: action.data,
        };
      case 'JOB_DETAILS_EDIT_TOTAL_STATS_SET_INPUT_VALUE':
        return {
          ...state,
          [action.name]: action.value,
        };
      case 'JOB_DETAILS_TOTAL_STATS_EDIT_SUCCESS':
        return initEdit;
      case 'JOB_DETAILS_TOTAL_STATS_CLEAR_STORE':
        return initEdit;
      case 'JOB_DETAILS_TOTAL_STATS_DELETE_ITEM_JOB':
        return {
          ...state,
          editData: action.array,
        };
      default:
        return state;
    }
  };

export const totalStats = combineReducers({
  initTotalStats,
  edit,
});
