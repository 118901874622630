import authService from 'services/auth/service';
import { ROLES, PERMISSIONS } from 'config/constants';
import { Ability, AbilityBuilder } from '@casl/ability';
import { useMemo } from 'react';

/** @typedef {'create' | 'read' | 'update' | 'delete'} Permission */
/** @typedef {string} Resource */

/**
 * @typedef {{
 *  permissions: Permission[],
 *  resource: Resource
 * }} AbilityPermission
 */

const user = authService.getUser();
/** @type {keyof ROLES} */
let userRole;
/** @type {keyof PERMISSIONS} */
let userPerm;
let ability;

if (user) {
  [userRole] = Object.keys(ROLES).filter(item => ROLES[item] === user.role);
  [userPerm] = Object.keys(PERMISSIONS).filter(
    item => PERMISSIONS[item] === user.permissions,
  );
  const userPerms = JSON.parse(user.userPermissions);
  const userAbilities = new AbilityBuilder(Ability);

  userPerms.forEach(perm => userAbilities.can(perm[0], perm[1]));

  ability = new Ability(userAbilities.rules);
}

export const sharedAuthChecker = ({ itemAccess = true }) =>
  user.entityType === 6 || itemAccess;

/**
 *
 * @param {Object} obj
 * @param {(keyof ROLES)[]} [obj.access]
 * @param {(keyof ROLES)[]} [obj.forbidden]
 * @param {(keyof PERMISSIONS)[]} [obj.permissions]
 * @param {number[]} [obj.itemAccessEntityType]
 * @param {number[]} [obj.itemAccessEntityId]
 * @param {AbilityPermission[]} [obj.abilityPermissions]
 * @param {boolean} [obj.itemAccessGranted]
 * @returns
 */
export const authorize = ({
  access = [],
  forbidden = [],
  permissions = [],
  itemAccessEntityType = [],
  itemAccessEntityId = [],
  abilityPermissions = [], // format = [{ permissions: ['create', 'reade'], resource: 'Jobs'}]
  itemAccessGranted = true,
}) => {
  let returnChildren = true;

  // Handle legacy permission checker
  if (
    (access.length && !access.includes(userRole)) ||
    (permissions.length && !permissions.includes(userPerm)) ||
    (forbidden.length && forbidden.includes(userRole))
  ) {
    returnChildren = false;
  }

  // Handle shared items checker for items in which we don't want owners to be able to access something created by SaaS
  // Example: We don't want an owner of a contract to be able to edit a contract created by a SaaS user
  if (itemAccessEntityType.length && itemAccessEntityId.length) {
    if (
      user.entityType !== 6 &&
      (!itemAccessEntityType.includes(user.entityType) ||
        !itemAccessEntityId.includes(user.entityId))
    ) {
      returnChildren = false;
    }
  }

  if (!user || (user.entityType !== 6 && !itemAccessGranted)) {
    returnChildren = false;
  }

  // Handle new permissions and roles
  if (user && abilityPermissions.length) {
    abilityPermissions.forEach(perm => {
      perm.permissions.forEach(p => {
        if (!ability.can(p, perm.resource)) {
          returnChildren = false;
        }
      });
    });
  }

  if (!itemAccessGranted) {
    returnChildren = false;
  }

  return returnChildren;
};

/**
 *
 * @param {Object} obj
 * @param {(keyof ROLES)[]} obj.access
 * @param {(keyof ROLES)[]} obj.forbidden
 * @param {(keyof PERMISSIONS)[]} obj.permissions
 * @param {number[]} obj.itemAccessEntityType
 * @param {number[]} obj.itemAccessEntityId
 * @param {AbilityPermission[]} obj.abilityPermissions
 * @param {boolean} obj.itemAccessGranted
 * @returns
 */
const Authorize = ({
  access = [],
  forbidden = [],
  permissions = [],
  itemAccessEntityType = [], // format = [1, 2, 78]
  itemAccessEntityId = [], // format = [1, 2, 78]
  abilityPermissions = [], // format = [{ permissions: ['create', 'read'], resource: 'Jobs'}]
  itemAccessGranted = true,
  children,
}) => {
  const returnChildren = useMemo(
    () =>
      authorize({
        access,
        forbidden,
        permissions,
        itemAccessEntityType,
        itemAccessEntityId,
        abilityPermissions,
        itemAccessGranted,
      }),
    [
      abilityPermissions,
      access,
      forbidden,
      itemAccessEntityId,
      itemAccessEntityType,
      itemAccessGranted,
      permissions,
    ],
  );

  if (!returnChildren) {
    return null;
  }
  return children;
};
export default Authorize;
