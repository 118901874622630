import React, { useState } from 'react';
import { timeZones } from 'components/globalInfo/timeZones';
import { sortList, formatList } from 'components/globalInfo/export';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import CardToggle from 'components/common/CardToggle';

const Export = ({
  exportSettings,
  isLoadedTemplates,
  templates,
  setFilterValue,
  download,
}) => {
  const { format, sortBy, timeZone, invoiceId, template } = exportSettings;
  const [selectedBtn, setSelectedBtn] = useState('');

  const timeZoneList =
    timeZones.map(data => ({
      value: data.value,
      label: data.name,
    })) || [];

  const templateList =
    (isLoadedTemplates &&
      templates.map((item, index) => ({
        value: index,
        label: item.name,
      }))) ||
    [];

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header as="h3">
          Export
          <CardToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form onSubmit={e => download(e, selectedBtn)}>
              <Row>
                <Col md={6} className="form-group has-feedback">
                  <Input
                    type="number"
                    onChange={e => setFilterValue(e, 'invoiceId')}
                    value={invoiceId}
                    label="Invoice ID"
                    testSelector="export_orders_invoice-id_input"
                  />
                </Col>
                <Col md={6} className="form-group has-feedback">
                  <Select
                    label="Sort by"
                    value={sortList.find(sortItem => sortItem.value === sortBy)}
                    onChange={item => setFilterValue(item.value, 'sortBy')}
                    options={sortList}
                    required
                    testSelector="export_orders_sort-by_select"
                  />
                </Col>
                <Col md={6} className="form-group has-feedback">
                  <Select
                    label="Time zone"
                    value={timeZoneList.find(zone => zone.value === timeZone)}
                    onChange={item => setFilterValue(item.value, 'timeZone')}
                    options={timeZoneList}
                    required
                    testSelector="export_orders_time-zone_select"
                  />
                </Col>
                <Col md={6} className="form-group has-feedback">
                  <Select
                    label="Format"
                    value={formatList.find(option => option.value === format)}
                    onChange={item => setFilterValue(item.value, 'format')}
                    options={formatList}
                    required
                    testSelector="export_orders_format_select"
                  />
                </Col>
                {['csv', 'json'].includes(format) && (
                  <Col md={6} className="form-group has-feedback">
                    <Select
                      label="Template"
                      name="template"
                      options={templateList}
                      placeholder="Please Select Template"
                      value={templateList.find(item => item.value === template)}
                      onChange={item => setFilterValue(item.value, 'template')}
                      required
                      testSelector="export_orders_template_select"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Button
                  type="submit"
                  onClick={() => setSelectedBtn('download')}
                  theme="small"
                  testSelector="export_orders_exoirt_download_btn">
                  Download
                </Button>
                {(format === 'csv' || format === 'json') && (
                  <Button
                    type="submit"
                    onClick={() => setSelectedBtn('preview')}
                    theme="small"
                    colour="green"
                    testSelector="export_orders_exoirt_preview_btn">
                    Preview
                  </Button>
                )}
              </Row>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default Export;
