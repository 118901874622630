import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Input from 'components/shared/Input';
import Button from 'components/shared/Button';

import targetValue from 'utils/targetValue';
import { useSendCarrierNotification } from 'api/v2/carriers';

const SendNotification = ({ showModal, close }) => {
  const [message, setMessage] = useState('');

  const { mutate: sendNotification, isPending } = useSendCarrierNotification();

  useEffect(() => {
    if (!showModal) {
      setMessage('');
    }
  }, [showModal]);

  const submit = useCallback(
    e => {
      e.preventDefault();
      sendNotification(
        {
          receiverCompanyType: 3,
          message,
        },
        { onSettled: close },
      );
    },
    [message, sendNotification, close],
  );

  return (
    <Modal className="modal-container" show={showModal} onHide={close}>
      <Modal.Header>
        <h3>Carrier Notification</h3>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body>
          <Input
            onChange={e => setMessage(targetValue(e))}
            value={message}
            label="Message"
            required
            testSelector="admin-carriers_send-notifications_message_input"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            colour="white"
            onClick={close}
            testSelector="admin-carriers_notifications_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={isPending || !message.trim().length}
            testSelector="admin-carriers_notifications_send_btn">
            Send
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SendNotification;
