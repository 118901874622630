import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import actions from 'store/actions/ManageDatabase';

const AddEditModal = ({
  showUpdate,
  closeUpdate,
  setInputValue,
  updateModal,
  add,
  edit,
  clear,
}) => {
  useEffect(
    () => () => {
      clear();
    },
    [],
  );

  const { name, status } = updateModal;
  const selectFunction = {
    add,
    edit,
  };
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineAddDisabled = () => {
    let disabled = false;
    if (!name.trim().length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [name]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => selectFunction[status](e, closeUpdate)}>
          <Modal.Header>
            <h3>{status == 'add' ? 'Add Certificate' : 'Edit Certificate'}</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="manage-database_certificates_add-edit_name_input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeUpdate}
              colour="white"
              testSelector="database_certificates_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="database_certificates_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  updateModal: state.database.certificates.updateModal,
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueCertificate(targetValue(e), name)),
  clear: () => dispatch(actions.clearCertificateModal()),
  add: (e, closeModal) => {
    e.preventDefault();
    dispatch(actions.addCertificate(closeModal));
  },
  edit: (e, closeModal) => {
    e.preventDefault();
    dispatch(actions.editCertificate(closeModal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditModal);
