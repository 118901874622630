import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import ToolTipCustom from 'components/shared/ToolTip';
import Slider from '@material-ui/core/Slider';

import Icon from './Icon';
import Button from './Button';

const AutomatizeSlider = withStyles({
  root: {
    color: '#22adcf',
    height: 4,
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#6fc6d9',
    marginTop: -12,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
})(Slider);

const ValueLabelComponent = ({ children, open, value }) => {
  const popperRef = useRef(null);
  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={`${value}%`}>
      {children}
    </Tooltip>
  );
};

const FileViewer = ({ image, title }) => {
  const [defaults, setDefaults] = useState({});
  const [current, setCurrent] = useState({});

  const setInitalValues = (height, width) => {
    const InitialSetup = {
      dimensions: { height, width },
      position: { x: 0, y: 0 },
      orientation: height > width ? 'p' : 'l',
      rotationScale: Math.min(width, height) / Math.max(width, height),
      rotation: 0,
      bounds: setBoundaries(height, width),
      zoom: 1,
      enableTransitionAnimation: true,
    };
    setDefaults(InitialSetup);
    setCurrent({
      ...InitialSetup,
      rotationScale: 1,
    });
  };

  const setBoundaries = (h = current.height, w = current.width) => {
    if (defaults.dimensions) {
      const horizontalBounds = Math.abs((w - defaults.dimensions.width) * 0.5);
      const verticalBounds = Math.abs((h - defaults.dimensions.height) * 0.5);
      return {
        top: verticalBounds * -1,
        left: horizontalBounds * -1,
        right: horizontalBounds,
        bottom: verticalBounds,
      };
    }

    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
  };

  const rotate = newRotation => {
    if (defaults.orientation === current.orientation) {
      const dimensions = {
        height: defaults.dimensions.width * defaults.rotationScale,
        width: defaults.dimensions.height * defaults.rotationScale,
      };

      return setCurrent({
        ...current,
        dimensions,
        position: { x: 0, y: 0 },
        orientation: current.orientation === 'p' ? 'l' : 'p',
        rotation: newRotation,
        zoom: defaults.rotationScale,
        bounds: setBoundaries(dimensions.height, dimensions.width),
      });
    }

    return setCurrent({
      ...defaults,
      rotation: newRotation,
    });
  };

  const rotateRight = () => rotate(current.rotation + 90);

  const zoom = zoom => {

    const dimensions = {};
    if (defaults.orientation === current.orientation) {
      dimensions.height = defaults.dimensions.height * zoom;
      dimensions.width = defaults.dimensions.width * zoom;
    } else {
      dimensions.height = defaults.dimensions.width * zoom;
      dimensions.width = defaults.dimensions.height * zoom;
    }

    return setCurrent({
      ...current,
      position: {
        x: 0,
        y: 0,
      },
      bounds: setBoundaries(dimensions.height, dimensions.width),
      zoom: zoom,
      enableTransitionAnimation: false,
    });
  };

  const onDrag = (_, { x, y }) =>
    setCurrent({
      ...current,
      position: { x, y },
      enableTransitionAnimation: false,
    });

  const onStop = () =>
    setCurrent({ ...current, enableTransitionAnimation: true });

  return (
    <div className="file-viewer">
      <div className="file-viewer__container">
        <ToolTipCustom title="Drag to reposition">
          <Draggable
            onDrag={onDrag}
            onStop={onStop}
            position={current.position}
            bounds={current.bounds}>
            <div
              className="file-viewer__draggable"
              style={{
                transition: current.enableTransitionAnimation
                  ? 'all .5s'
                  : 'none',
              }}>
              <img
                className="file-viewer__image"
                onLoad={({ target: img }) =>
                  setInitalValues(img.offsetHeight, img.offsetWidth)
                }
                style={{
                  transform: `rotate(${current.rotation}deg) scale(${
                    current.zoom
                    })`,
                  transition: current.enableTransitionAnimation
                    ? 'all .5s'
                    : 'none',
                }}
                src={image}
                alt={title}
              />
            </div>
          </Draggable>
        </ToolTipCustom>
      </div>
      <Button onClick={rotateRight} theme="borderless">
        <Icon icon="rotate" colour="white" /> Rotate photo
      </Button>
      <div className="file-viewer__slider">
        <Icon icon="zoom_out" colour="white" />
        <AutomatizeSlider
          min={100}
          max={400}
          valueLabelDisplay="auto"
          ValueLabelComponent={ValueLabelComponent}
          value={Math.round(current.zoom * 100)}
          onChange={(event, value) => {
            zoom(value / 100)}
          }
          onChangeCommitted={(event, value) => setCurrent({...current, enableTransitionAnimation: true})}
        />
        <Icon icon="zoom_in" colour="white" />
      </div>
    </div>
  );
};

export default FileViewer;
