import { client } from 'api/client';
import authService from 'services/auth/service';

/**
 *
 * @param {*} params
 * @returns
 */
export const getJobs = async params => {
  const { data } = await client.get('v2/jobs', { params });
  return data.data.jobs;
};

/*
 *
 * @param {{
 * id: string;
 * name: string;
 * status: number;
 * type: number;
 * isDraft: number;
 * page: number;
 * pageSize: number;
 * }} params
 */
export const getPaginatedJobs = async params => {
  const { data } = await client.get('v2/jobs', { params });
  return data.data;
};

/**
 *
 * @param {Object} newJob
 * @returns
 */
export const addJob = async newJob => {
  const { data } = await client.post('v2/jobs', newJob);
  const { jobId } = data.data;

  if (newJob.operatorList.length) {
    const entityData = newJob.operatorList
      .filter(ol => {
        const entityArray = ol.split('/');

        if (Number(entityArray[1]) === 2) {
          return Number(entityArray[0]) !== Number(newJob.customerId);
        }

        return true;
      })
      .map(item => {
        const entityArray = item.split('/');
        return {
          entityType: Number(entityArray[1]),
          entityId: Number(entityArray[0]),
        };
      });

    await client.post(
      `v2/job/${jobId}/operators/companies/${newJob.customerId}`,
      { entityData }
    );
  }

  return jobId;
};

/**
 *
 * @param {{
 *  jobId: number;
 *  newJob: any;
 * }} params
 * @returns
 */
export const editJob = async ({ jobId, newJob }) => {
  const { customerId } = newJob;
  const editAdditionalOperators =
    authService.getUser().role === 6 ||
    Number(authService.getCompany()) === Number(customerId);

  if (editAdditionalOperators) {
    const operatorList = newJob.additionalOperators;
    const filteredCompanies = operatorList.filter(ol => {
      const entityArray = ol.value.split('/');

      if (Number(entityArray[1]) === 2) {
        return Number(entityArray[0]) !== Number(customerId);
      }

      return true;
    });

    const payload = filteredCompanies.map(item => {
      const entityArray = item.value.split('/');
      return {
        entityType: Number(entityArray[1]),
        entityId: Number(entityArray[0]),
      };
    });

    await client.patch(`v2/job/${jobId}/operators/companies/${customerId}`, {
      entityData: payload,
    });
  }

  const { data } = await client.put(`v2/job/${jobId}`, newJob);
  return data;
};

/**
 *
 * @param {{
 *  jobId: number;
 *  jobDesign: Object;
 * }} params
 * @returns
 */
export const editJobDesign = async ({ jobId, jobDesign }) => {
  const { data } = await client.post(`v2/job/${jobId}/initial-stage-design`, {
    stageDesign: jobDesign,
  });
  return data.data;
};

/**
 *
 * @param {number} jobId
 * @returns
 */
export const deleteJob = async jobId => {
  const { data } = await client.post(`v2/job/${jobId}/delete`, {});
  return data;
};

/**
 *
 * @param {number} jobId
 * @returns
 */
export const completeJob = async jobId => {
  const { data } = await client.post(`v2/job/${jobId}`, {});
  return data;
};

/**
 *
 * @param {number} jobId
 * @returns
 */
export const uncompleteJob = async jobId => {
  const { data } = await client.post(`v2/job/${jobId}/uncomplete`, {});
  return data;
};

/**
 *
 * @param {number} jobId
 * @returns
 */
export const getJobDetails = async jobId => {
  const { data } = await client.get(
    `v2/job/${jobId}/details?include=documents`,
  );
  return {
    ...data.data,
    carrierCommitments: data.data.carrierCommitments.map(commitment => ({
      ...commitment,
      startDate: new Date(commitment.startDate),
      endDate: new Date(commitment.endDate),
    })),
  };
};

/**
 *
 * @param {{
 *  jobId: number;
 *  newJob: Object;
 * }} params
 */
export const updateJob = async ({ jobId, newJob }) => {
  const { data } = await client.put(`v2/job/${jobId}`, newJob);
  return data;
};

/**
 *
 * @param {*} jobId
 * @returns
 */
export const getJobStages = async jobId => {
  const { data } = await client.get(`v2/jobs/${jobId}/stage`);
  return data.data.stages;
};

export const upsertJobCarrierCommitments = async ({
  jobId,
  carrierCommitments: commitments,
}) => {
  const { data } = await client.patch(`v2/jobs/${jobId}/carrier-commitments`, {
    commitments,
  });
  return data.data;
};

export const addJobOperators = async ({ jobId, customerId, entityData }) => {
  const { data } = await client.post(
    `v2/job/${jobId}/operators/companies/${customerId}`,
    {
      entityData,
    },
  );

  return data;
};

export const deleteJobOperator = async ({ jobId, customerId, entityData }) => {
  const { data } = await client.put(
    `v2/job/${jobId}/operators/companies/${customerId}`,
    entityData,
  );
  return data;
};

export const getJobDocument = async ({ jobId, documentId }) => {
  const response = await client.get(`v2/job/${jobId}/documents/${documentId}`, {
    headers: { 'Content-Type': 'image/*' },
    responseType: 'arraybuffer',
  });
  const file = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  const image = URL.createObjectURL(file);
  return {
    image,
    contentType: response.headers['content-type'],
    nativeImg: response.data,
  };
};

export const uploadJobDocument = async ({ jobId, file, name }) => {
  const formData = new FormData();
  formData.append('jobId', jobId);
  formData.append('documents', file);
  formData.append('name', name);

  const { data } = await client.post('v2/documents/upload', formData, {
    headers: {
      dataType: 'json',
      processData: false,
      contentType: false,
    },
  });
  return data;
};

/**
 * @typedef {{
 *   autoDispatchEnabled: boolean;
 *   containersPerTruck: number;
 *   stageBuffer: number;
 *   stageDesignId: number;
 * }} ContainerSettings
 */

/**
 *
 * @param {{
 *  jobId: number;
 *  settings: ContainerSettings;
 * }} params
 */
export const updateContainerizedSettings = async ({ jobId, settings }) => {
  settings.autoDispatchEnabled = settings.autoDispatchEnabled ? 1 : 0;

  const { data } = await client.post(
    `v2/job/${jobId}/containerized-settings`,
    settings,
  );
  return data;
};

export const getCommoditiesByPo = async ({ jobId }) => {
  const { data } = await client.get(`v2/jobs/${jobId}/po`, { jobId });
  return data.data.po;
};

export const getFreeDrivers = async ({ locationId }) => {
  const { data } = await client.get(
    `v2/driver/available?sandId=${locationId}`,
    {
      sandId: locationId,
    },
  );
  return data.data.drivers;
};

export const getTicketTypes = async () => {
  const { data } = await client.get('v2/sand-ticket-types', {});
  return data.data.sandTicketTypes;
};

export const getOnsiteStorageTypes = async () => {
  const { data } = await client.get('v2/job/on-site-storage', {});
  return data.data;
};

export const getJobSharedOperators = async ({ jobId }) => {
  const { data } = await client.get(`v2/job/${jobId}/operators/companies`);
  return data.data.operators;
};

export const getJobUnitsOfMeasure = async ({ jobId }) => {
  const { data } = await client.get(`v2/jobs/${jobId}/units-of-measure`);
  return data.data;
}
