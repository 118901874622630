// importing dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loginActions } from 'store/actions/Login';
// importing components
import eventTarget from 'utils/dispatchWithFieldValue';
import LoginForm from './LoginForm';
import SelectRole from './SelectRole';

const Login = props => {
  useEffect(() => {
    const { checkToLogin, setCurrentUrl } = props;
    checkToLogin();
    setCurrentUrl(document.referrer);
  }, []);

  const { login, authenticate } = props;
  const { isAbleToSelectRole, roles, jwtTemp } = login;

  return (
    <div className="login-wrapper">
      <div className="login">
        {isAbleToSelectRole ? (
          <SelectRole {...{ roles, jwtTemp, authenticate }} />
        ) : (
          <LoginForm {...props} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  login: state.auth.login,
});

const mapDispatchToProps = dispatch => ({
  forgetPassword: (e, closeModal) => {
    e.preventDefault();
    dispatch(loginActions.forgetPassword(closeModal));
  },
  submitLogin: e => {
    e.preventDefault();
    dispatch(loginActions.login());
  },
  setLogin: eventTarget(loginActions.setLogin, dispatch),
  setPassword: eventTarget(loginActions.setPassword, dispatch),
  checkToLogin: () => dispatch(loginActions.checkToLogin()),
  authenticate: (code, roleId, entityId, roleTypeId) =>
    dispatch(loginActions.authenticate(code, roleId, entityId, roleTypeId)),
  setCurrentUrl: url => dispatch(loginActions.setCurrentUrl(url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
