import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Authorize from 'components/common/Authorize';
import Page from 'components/shared/Page';
import Block from 'components/shared/Block';
import CreditMemosFilter from '../CreditMemosFilter';
import CreditMemosList from '../CreditMemosList';
import { CreditMemosContext } from '../index';

import { creditMemosRequested } from 'store/actions/CreditMemos';

const basePayload = {
  page: 1,
  limit: 10,
};

const CreditMemos = ({
  getCreditMemos,
  isLoading,
}) => {
  const {
    filters,
    setFilters,
    ordering,
    setOrdering,
    setPagination,
    pagination,
  } = useContext(CreditMemosContext);

  const [requestPayload, setRequestPayload] = useState(null);

  const setRequestFilters = requestFilters => {
    setPagination({ page: 1, limit: pagination.limit });
    return setRequestPayload({
      ...basePayload,
      ...requestFilters,
      ...pagination,
      page: 1,
    });
  };

  const setRequestPagination = paginationData => {
    const newObj = {
      page: paginationData.page,
      limit: paginationData.itemsPerPage,
    };

    const match = Object.keys(pagination).every(key => {
      if (!newObj[key]) {
        return false;
      }

      if (newObj[key] === pagination[key]) {
        return true;
      }

      return false;
    });

    if (!match) {
      setPagination(newObj);
      if (requestPayload) {
        return setRequestPayload({ ...requestPayload, ...newObj });
      }

      return setRequestPayload({ ...basePayload, ...newObj });
    }
  };

  useEffect(() => {
    setOrdering({ key: 'created_at', isAscending: false });
    setPagination({ page: 1, limit: 10 });
  }, []);

  useEffect(() => {
    getCreditMemos(basePayload)
  }, []);

  useEffect(() => {
    if (requestPayload) {
      const formattedPayload = Object.assign({}, _.omit(requestPayload, ['createDate']));
      if (requestPayload.createdAt && moment(requestPayload.createdAt).isValid()) {
        formattedPayload.createdAt = moment(requestPayload.createdAt)
          .format('DD MMM YYYY');
      }

      getCreditMemos(formattedPayload);
    };
  }, [requestPayload]);

  return (
    <Page isLoading={isLoading} title="Credit Memos" className="invoices">
      <Block theme="none" modifiers={['no-border']}>
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Invoices',
                permissions: ['read']
              }
            ]
          }}
        >
          <CreditMemosFilter
            filters={filters}
            setFilters={setFilters}
            setRequestFilters={setRequestFilters}
          />
          <CreditMemosList
            filters={filters}
            ordering={ordering}
            setOrdering={setOrdering}
            pagination={pagination}
            setPagination={setRequestPagination}
          />
        </Authorize>
      </Block>
    </Page>
  );
};

const mapStateToProps = (state, props) => ({
  isLoading: state.creditMemos.creditMemos.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getCreditMemos: (params) => dispatch(creditMemosRequested(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemos);
