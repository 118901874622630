const initialStateLocationDetails = {
  isLoadedLocationDetails: false,
  location: [],
  locationId: 0,
};

const locationDetails = (
  state = initialStateLocationDetails,
  action,
) => {
  switch (action.type) {
    case 'LOCATION_DETAILS_INIT_SUCCESS':
      return {
        ...state,
        isLoadedLocationDetails: true,
        location: action.data,
        locationId: action.data.sandSiteDetails[0].id,
      };
    case 'LOCATION_DETAILS_INIT_FAILURE':
      return state;
    case 'LOCATION_INIT_CLEAR':
      return initialStateLocationDetails;
    default:
      return state;
  }
};

export default locationDetails;
