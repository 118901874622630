import React, { useMemo } from 'react';

import { useCustomerCommodities } from 'api/v2/commodities';

const StorageUnit = ({ name, unit, color, totalUnits, customerId }) => {
  const { data: commodities } = useCustomerCommodities(customerId);
  const commodity = useMemo(
    () => commodities?.find(c => c.id === unit.commodityTypeId) || {},
    [commodities, unit],
  );

  return (
    <>
      <div>
        <span style={{ color: '#928e88' }}>{`${name ?? 'Unit'}${totalUnits > 1 ? ` ${unit.id}` : ''
          }`}</span>
        <br />
        <span style={{ color: '#928e88' }}>
          {commodity.name ?? '(Unknown)'}
        </span>
      </div>
      <div
        style={{
          height: 200,
          border: color,
          borderRadius: 4,
          margin: 5,
        }}>
        <div
          className="emptyLevel"
          style={{
            width: '100%',
            height: `${100 - unit.levelPercentage}% `,
            background: '#e7eaec',
          }}>
          <p className="level-text level-text--low">
            {unit.currentWeight >= 0
              ? Math.round(unit.currentWeight).toLocaleString(
                navigator.language,
              )
              : 0}{' '}
            {
              commodity?.unitOfMeasureName ?? ''
            }
          </p>
        </div>
        {!!unit.currentWeight && (
          <div
            className="level"
            title={`Level, ${commodity?.unitOfMeasureName}`}
            style={{
              width: '100%',
              height: `${unit.levelPercentage}% `,
              background: color,
            }}
          />
        )}
      </div>
    </>
  );
};
export default StorageUnit;
