import { useStorageDetails } from 'api/sls/storage';

import { useJobUnitsOfMeasure, useJobDetails } from 'api/v2/jobs';
import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';
import { useMemo } from 'react';
import multiSelectOptions from 'utils/multiSelectOptions';

import { selectColor } from './helpers';

export const useUnitOfMeasureOptions = jobId => {
  const { data: unitsOfMeasure } = useJobUnitsOfMeasure({ jobId });

  const unitOfMeasureOptions = useMemo(() => {
    unitsOfMeasure.push({ id: null, name: 'View All' });
    return multiSelectOptions(unitsOfMeasure, 'id', 'name');
  }, [unitsOfMeasure]);

  return unitOfMeasureOptions;
};

export const useStorageGroups = (jobId, unitOfMeasureId, includeDeleted) => {
  const { data: storageGroups, isLoading } = useStorageDetails(jobId);
  const { data: jobDetails } = useJobDetails({ jobId });
  const customerId = jobDetails?.details?.customerId;
  const { data: commodities } = useCustomerCommodities(customerId);
  const filteredStorageGroups = useMemo(() => {
    if (storageGroups && jobDetails) {
      const storage = jobDetails.storage ?? [];
      const externalIds = storage.map(s => s.storageId);

      return storageGroups.unitGroups
        .map(unitGroup => {
          const unitGroupCommodities = (commodities || []).filter(commodity =>
            unitGroup.units.some(unit => unit.commodityTypeId === commodity.id),
          );
          const unitsOfMeasure = unitGroupCommodities.map(
            commodity => commodity.unitOfMeasureId,
          );
          return {
            ...unitGroup,
            unitsOfMeasure,
          };
        })
        .filter(unitGroup => {
          if (
            unitGroup.externalProvider === 'manual' &&
            unitGroup.details?.isDeleted &&
            !includeDeleted
          ) {
            return false;
          }

          if (
            unitGroup.externalProvider !== 'manual' &&
            !externalIds.includes(unitGroup.externalId)
          ) {
            return false;
          }

          if (
            unitOfMeasureId &&
            !unitGroup.unitsOfMeasure.includes(unitOfMeasureId)
          ) {
            return false;
          }

          return true;
        });
    }
    return [];
  }, [storageGroups, jobDetails, includeDeleted, commodities, unitOfMeasureId]);

  return {
    storageGroups: filteredStorageGroups,
    storageConfig: jobDetails?.storage ?? [],
    isLoading,
  };
};

/**
 * @param {import('.').StorageGroup[]} storageGroups
 * @returns {number[]}
 */
const getSiloCommodities = storageGroups => {
  const silos = storageGroups.flatMap(group => group.units);
  const uniqueCommodities = Array.from(
    new Set(silos.map(silo => silo.commodityTypeId)),
  );
  return uniqueCommodities;
};

/**
 * Get color mappings for unique commodities in storage groups
 * @param {import('.').StorageGroup[]} storageGroups
 * @param {any[]} commodities
 * @returns {{
 *  colors: Record<string, { color: string; colorAlpha: string; }>
 *  types: { id: number; name: string }[]
 * }}
 */
const getColorAndTypeMappings = (storageGroups, commodities) => {
  const uniqueCommodities = getSiloCommodities(storageGroups);

  return uniqueCommodities.reduce(
    (acc, commodityTypeId, index) => {
      const commodity = commodities.find(x => x.id === commodityTypeId);

      acc.colors[commodityTypeId] = {
        color: selectColor(commodityTypeId, 100),
        colorAlpha: selectColor(commodityTypeId, 50),
      };

      acc.types.push({
        id: commodityTypeId,
        name: commodity ? commodity.name : '',
      });

      return acc;
    },
    { colors: {}, types: [] },
  );
};

/**
 * @param {import('.').StorageGroup[]} storageGroups
 */
export const useStorageColors = storageGroups => {
  const { data: commodities } = useCommodities();
  const { colors, types } = useMemo(
    () => getColorAndTypeMappings(storageGroups, commodities),
    [storageGroups, commodities],
  );
  return {
    commodityColors: colors,
    jobCommodityTypes: types,
  };
};
