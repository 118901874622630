import swal from 'bootstrap-sweetalert';
import { Constants } from './constants';
import { history } from '../../../helpers/history';

const setSearchValue = value => ({
  type: Constants.TOP_HEADER_SET_VALUE,
  value,
});
const clearState = () => ({ type: Constants.TOP_HEADER_CLEAR_STATE });

const goToSearchResult = match => (dispatch, getState) => {
  const {
    Header: { searchValue },
  } = getState();
  if (searchValue.length < 2) {
    swal('Search request should contain at least 2 symbols', '', 'error');
    return;
  }
  const saveSearchValue = searchResult => ({
    type: Constants.TOP_HEADER_SAVE_SEARCH,
    searchResult,
  });
  dispatch(saveSearchValue(searchValue));
  history.push(`/${match.url.split('/')[1]}/search-result/${searchValue}`);
};

const actions = {
  goToSearchResult,
  setSearchValue,
  clearState,
};

export default actions;
