import swal from 'bootstrap-sweetalert';

export function deleteAlert(deleteFunction, id1, id2, param, customAttributes) {
  const title = customAttributes && customAttributes.title
    ? customAttributes.title
    : 'Are you sure?';
  const confirmText = customAttributes && customAttributes.confirmText
    ? customAttributes.confirmText
    : 'Yes, delete it!';
  const text = customAttributes && customAttributes.text
    ? customAttributes.text
    : 'If you do this, you will not be able to revert!';
  const cancelText = customAttributes && customAttributes.cancelText
    ? customAttributes.cancelText
    : 'Cancel';
  swal(
    {
      title,
      text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      closeOnConfirm: false,
      closeOnCancel: true,
      showLoaderOnConfirm: true,
    },
    (isConfirm) => {
      if (isConfirm) {
        deleteFunction(id1, id2, param);
      }
    },
  );
}

export function confirmAlert(confirmFunction, param, customAttributes) {
  const title = customAttributes && customAttributes.title
    ? customAttributes.title
    : 'Are you sure?';
  const text = customAttributes && customAttributes.text
    ? customAttributes.text
    : 'If you do it, you will not be able to recover!';
  swal(
    {
      title,
      text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'Cancel',
      closeOnConfirm: false,
      closeOnCancel: true,
      showLoaderOnConfirm: false,
    },
    (isConfirm) => {
      if (isConfirm) {
        confirmFunction(param);
      }
    },
  );
}
