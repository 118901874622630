import React from 'react';
import Badge from 'components/shared/Badge';
import ordersReviewStatus from 'components/globalInfo/ordersReviewStatus';
import { orderStatus } from 'components/globalInfo/orderStatus';
import { billingStatus } from 'components/globalInfo/billingStatus';
import { useEquipment } from 'api/v2/equipment';

const TagsList = ({ data = null }) => {
  const { data: equipment } = useEquipment();

  return (
    <div className="order-info__tags-list">
      <Badge type="green">{orderStatus[data.status].name}</Badge>
      {data.billing_status < 20 ? (
        <Badge
          type={String(
            ordersReviewStatus[String(data.review_status)].btnColor,
          )}>
          {String(ordersReviewStatus[String(data.review_status)].title)}
        </Badge>
      ) : (
        <Badge type={String(billingStatus[String(data.billing_status)].class)}>
          {String(billingStatus[String(data.billing_status)].text)}
        </Badge>
      )}
      {!!data.equipment && (
        <Badge>
          {equipment.map(item => item.id === data.equipment && item.title)}
        </Badge>
      )}
      {!!data.hasMissingSandTickets && <Badge type="red">Missing ticket</Badge>}
      {!!data.isTransferred && <Badge type="orange">Transferred</Badge>}
      {!!data.is_deleted && <Badge type="red">Deleted</Badge>}
    </div>
  );
};

export default TagsList;
