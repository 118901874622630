import { validateTimings } from 'components/views/Locations/Update/helpers';
import locationsActions from 'store/constants/Locations';
import _ from 'lodash';

const init = {
  selectedLocation: '',
  isLoaded: false,
  timing: [],
  updatetimings: [],
  timeZone: 'America/Chicago',
  exemptions: []
};

export default (state = { ...init }, action) => {
  switch (action.type) {
    case locationsActions.LOCATIONS_TIMING_SET_SELECTED:
      return {
        ...state,
        selectedLocation: action.id,
      };
    case locationsActions.LOCATIONS_TIMING_GET_LIST:
      return {
        ...state,
        timing: action.timing,
        updatetimings: validateTimings(_.sortBy(action.updatetimings, 'open')),
        isLoaded: true,
        exemptions: action.updatetimings[0]?.exemptions || []
      };
    case locationsActions.LOCATIONS_TIMING_SET_INPUT_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case locationsActions.LOCATIONS_TIMING_DELETE_ITEM:
      return {
        ...state,
        updatetimings: action.array,
      };
    case locationsActions.LOCATIONS_TIMING_CLEAR_STATE:
      return init;
    default:
      return state;
  }
};
