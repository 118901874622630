import React from 'react';
import { Pie } from 'react-chartjs-2';

const SandType = ({ sandTypes }) => {
  const data = {
    labels: sandTypes.map(item => item.name),
    datasets: [
      {
        data: sandTypes.map(item => item.weight),
        backgroundColor: [
          '#A3E1D4',
          '#FFD4B9',
          '#B5B7CF',
          '#EDFBB6',
          '#F4BC8E',
          '#9AACEF',
        ],
        hoverBackgroundColor: [
          '#A3E1D4',
          '#FFD4B9',
          '#B5B7CF',
          '#EDFBB6',
          '#F4BC8E',
          '#9AACEF',
        ],
      },
    ],
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h5>Commodity</h5>
          </div>
          <div className="ibox-content">
            <div className="col-md-7">
              <table className="table table--hover">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="text-center">Orders</th>
                    <th className="text-center">Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {sandTypes.map(st => (
                    <tr key={`sandType-${st.id}`}>
                      <td>
                        #{st.id} | {st.name}
                      </td>
                      <td className="text-center">{st.count}</td>
                      <td className="text-center">{st.weight} lbs</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-5">
              <Pie {...{ data }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SandType;
