import React, { useState, useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/Users';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import _ from 'lodash';
import authService from 'services/auth/service';
import { useCarriers } from 'api/v2/carriers';
import { useCustomers } from 'api/v2/customers';

const AddRole = ({
  getJobs,
  clear,
  showModalRole,
  closeModalRole,
  addRole,
  sandSites,
  getRoles,
  roles,
}) => {
  const [companySelected, setCompanySelected] = useState('');
  const [entitySelected, setEntitySelected] = useState('');
  const [roleSelected, setRoleSelected] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);

  const user = authService.getUser();

  const { data: carriers } = useCarriers();
  const { data: customers } = useCustomers();

  useEffect(() => {
    getJobs();

    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (roles && roles.length) {
      const tmpOptions = roles.map(item => ({
        value: item.id,
        label: _.startCase(item.name),
      }));
      setRoleOptions(tmpOptions);
    }
  }, [roles]);

  let companyTypeOptions = [];
  switch (user.role) {
    case 6:
      companyTypeOptions = [
        { value: 1, label: 'Automatize' },
        { value: 2, label: 'Carrier' },
        { value: 3, label: 'Customer' },
      ];
      break;
    case 2:
      companyTypeOptions = [{ value: 3, label: 'Customer' }];
      break;
    case 3:
      companyTypeOptions = [{ value: 2, label: 'Carrier' }];
      break;
    default:
      companyTypeOptions = [
        { value: 1, label: 'Automatize' },
        { value: 2, label: 'Carrier' },
        { value: 3, label: 'Customer' },
      ];
  }

  const entity = {
    Automatize: 'Automatize',
    Carrier: 'carriers',
    Customer: 'customers',
    Vendor: 'sandSites',
  };
  const entityList = ent => {
    switch (ent) {
      case 'customers': // customer
        if (customers?.length) {
          if (user.role === 6) {
            return customers.map(i => ({
              value: i.id,
              label: `${i.id} | ${i.name}`,
            }));
          }
          return customers
            .filter(c => c.id === user.entityId)
            .map(i => ({
              value: i.id,
              label: `${i.id} | ${i.name}`,
            }));
        }
        return [];
      case 'carriers': // carrier
        if (carriers?.length) {
          if (user.role === 6) {
            return carriers.map(i => ({
              value: i.id,
              label: `${i.id} | ${i.name}`,
            }));
          }
          return carriers
            .filter(c => c.id === user.entityId)
            .map(i => ({
              value: i.id,
              label: `${i.id} | ${i.name}`,
            }));
        }
        return [];
      case 'sandSites': // vendor
        return sandSites.map(i => ({
          value: i.id,
          label: `${i.id} | ${i.name}`,
        }));
      case 'Automatize': // Automatize
        return [{ value: 1, label: 'Job Manager | Automatize Logistics, LLC' }];
      default:
        return [];
    }
  };

  return (
    <Modal
      className="modal-container"
      show={showModalRole}
      onHide={closeModalRole}>
      <form
        onSubmit={e => {
          addRole(
            e,
            { companySelected, entitySelected, roleSelected },
            closeModalRole,
          );
        }}>
        <Modal.Header>
          <Modal.Title as="h4">Add Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-4">
              <Select
                placeholder="Company Type"
                options={companyTypeOptions}
                onChange={item => {
                  setCompanySelected(item.label);
                  setEntitySelected('');
                  setRoleSelected('');
                }}
                value={
                  companyTypeOptions.find(
                    type => type.label === companySelected,
                  ) || null
                }
                required
                testSelector="users_add-role_company-type_select"
              />
            </div>
            <div className="form-group has-feedback col-md-4">
              <Select
                placeholder="Select Entity"
                options={entityList(entity[companySelected])}
                isDisabled={!companySelected}
                onChange={item => {
                  setEntitySelected(item.value);
                  getRoles(companySelected.toLowerCase(), item.value);
                }}
                value={
                  (entitySelected &&
                    entityList(entity[companySelected]).find(
                      type => type.value === entitySelected,
                    )) || { value: '', label: '' }
                }
                required
                testSelector="users_add-role_entity_select"
              />
            </div>
            <div className="form-group has-feedback col-md-4">
              <Select
                placeholder="Select Role"
                options={roleOptions}
                isDisabled={!companySelected || !entitySelected}
                onChange={item => {
                  setRoleSelected(item.label);
                }}
                value={
                  roleOptions.find(type => type.label === roleSelected) || null
                }
                required
                testSelector="users_add-role_role_select"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModalRole}
            colour="white"
            testSelector="users_add-role_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            testSelector="users_add-role_save_btn"
            disabled={!companySelected || !entitySelected || !roleSelected}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  sandSites: state.lookUp.sandSites,
  input: state.users.addRole.input,
  customers: state.users.addRole.customers,
  carriers: state.users.addRole.carriers,
  jobs: state.users.addRole.jobs,
  roles: state.users.addRole.roles,
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueAddRole(targetValue(e), name)),
  saveSelectValue: (value, name) =>
    dispatch(actions.setInputValueAddRole(value, name)),
  getJobs: () => dispatch(actions.getJobs()),
  clear: () => dispatch(actions.clearAddRole()),
  addRole: (e, data, close) => {
    e.preventDefault();
    dispatch(actions.addRole(data, close));
  },
  getAutomatizeRoles: () => dispatch(actions.getAutomatizeRoles()),
  getRoles: (companyType, entityId) => {
    dispatch(actions.getRoles(companyType, entityId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRole);
