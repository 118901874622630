import React from 'react';
import Icon from 'components/shared/Icon';

const Comments = ({jobManagers, sandCoordinators}) => {
  return (
    <div className="comment-list">
      {jobManagers.length > 0 &&
        jobManagers.map(item => (
          <div key={item.id} className="comment-list__comment">
            <div className="comment-list__comment--details">
              <strong>Rated {item.rating} <Icon icon="star" /></strong>
              <small className="float-end">Posted by job manager<strong> {item.name}</strong></small>
            </div>
            <div className="comment-list__comment--comment">
              {item.comment}
            </div>
          </div>
        ))}
      {sandCoordinators.length > 0 &&
        sandCoordinators.map(item => (
          <div key={item.id} className="comment-list__comment">
            <div  className="comment-list__comment--details">
              <strong>Rated {item.rating} <Icon icon="star" /></strong>
              <small className="float-end">Posted by job coordinator<strong> {item.name}</strong></small>
              <div className="comment-list__comment--comment">
                {item.comment}
              </div>
            </div>
          </div>
        ))
      }
      {sandCoordinators.length === 0 && jobManagers.length === 0 && (
        <h4>No comments</h4>
      )}
    </div>
  );
};

export default Comments;
