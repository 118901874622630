import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

import {
  useDeleteHistoricalRecord,
  useHistoricalLevels,
} from 'api/sls/storage';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import { useJobPermission } from 'components/views/JobDetails/hooks';
import { useJobDetails } from "api/v2/jobs"
import { useGetCommodityUnits } from './hooks';

/**
 *
 * @param {{
 *  storageGroup: import('..').StorageGroup
 * }} param
 */
const HistoricalLevelsModal = ({ storageGroup, show, handleClose, jobId }) => {
  const isFullEdit = useJobPermission(storageGroup.jobId);
  const { data: storageHistory, isLoading } = useHistoricalLevels({
    jobId: storageGroup.jobId,
    storageId: storageGroup.storageId,
  });
  const {
    mutate: deleteHistoricalRecord,
    isPending,
    variables,
  } = useDeleteHistoricalRecord(storageGroup.jobId);
  const { data: jobDetails } = useJobDetails({ jobId })
  const { details: { customerId } } = jobDetails;
  const getCommodityUnits = useGetCommodityUnits(customerId);
  const historicalLevels = storageHistory;

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (historicalLevels.length === 0) {
      return <p>No historical levels available.</p>;
    }

    return (
      <div>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <strong>Date / Time</strong>
          </Col>
          <Col>
            <strong>User</strong>
          </Col>
          <Col>
            <strong>Quantity</strong>
          </Col>
          <Col />
        </Row>
        {historicalLevels.map((entry, index) =>
          entry.units.map(unit => (
            <Row
              key={`${entry.storageId}-${unit.id}-${index}`}
              style={{
                color: entry.deletedBy && 'orange',
                fontStyle: entry.deletedBy && 'italic',
                minHeight: 30,
              }}>
              <Col>
                {moment(entry.recordTimestamp).format('MM/DD/YY hh:mm A')}
              </Col>
              <Col>{entry.createdBy ?? 'System'}</Col>
              <Col>
                {Number(unit.currentWeight).toLocaleString()}{' '}
                {getCommodityUnits(unit.commodityTypeId)}
              </Col>
              <Col>
                {entry.deletedBy ? (
                  <span>Deleted by {entry.deletedBy}</span>
                ) : (
                  <Authorize
                    abilityPermissions={[
                      { resource: 'Scada', permissions: ['update'] },
                    ]}
                    itemAccessGranted={isFullEdit}>
                    <Button
                      // eslint-disable-next-line no-underscore-dangle
                      onClick={() => deleteHistoricalRecord(entry._id)}
                      theme="small"
                      // eslint-disable-next-line no-underscore-dangle
                      disabled={isPending && variables === entry._id}>
                      <Icon icon="trash-o" />
                    </Button>
                  </Authorize>
                )}
              </Col>
            </Row>
          )),
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title as="h3">
          Historical Levels for {storageGroup.externalId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const HistoricalLevelsWrapper = ({ storageGroup, jobId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      <Button onClick={handleShow}>Inventory History</Button>

      <HistoricalLevelsModal
        storageGroup={storageGroup}
        show={showModal}
        handleClose={handleClose}
        jobId={jobId}
      />
    </div>
  );
};

export default HistoricalLevelsWrapper;
