const driversDefaultState = {
  collection: [],
  iosArray: [],
  androidArray: [],
  apiIsLoading: false,
  lastApiCallSucceeded: true,
  carriers: [],
  isLoadedCarriers: false,
  filters: {
    id: '',
    name: '',
    email: '',
    contact_no: '',
    district: '',
    districts: [],
    areDistrictsLoaded: false,
    truck: '',
    carrier: '',
    location: '',
    android: '',
    ios: '',
    freeBusyStatus: '',
    deletedStatus: false,
  },
  selectedDriver: {},
  isRequestSending: false,
  notification: {
    id: '',
    name: '',
    district: '',
    truck: '',
    carrier: '',
    free: false,
    busy: false,
    selectAllLabel: false,
    message: '',
    ids: {},
  },
};

export default (state = driversDefaultState, data) => {
  switch (data.type) {
    case 'DRIVERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'DRIVER_LIST_REFRESH_REQUEST':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'DRIVER_LIST_REFRESH_SUCCESS':
      return {
        ...state,
        apiIsLoading: false,
      };
    case 'DRIVERS_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: data.drivers,
        iosArray: data.ios,
        androidArray: data.android,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'CARRIERS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'DRIVER_LIST_SET_CARRIERS':
      return {
        ...state,
        carriers: data.carriers,
        isLoadedCarriers: true,
      };
    case 'DRIVER_LIST_SET_FILTER_VALUE':
      return {
        ...state,
        filters: {
          ...state.filters,
          [data.name]: data.value,
        },
      };
    case 'DRIVER_LIST_SET_DRIVER_DISTRICTS':
      return {
        ...state,
        filters: {
          ...state.filters,
          districts: data.districts,
          areDistrictsLoaded: true,
        },
      };
    case 'DRIVER_LIST_DISTRICTS_NOT_LOADED':
      return {
        ...state,
        filters: {
          ...state.filters,
          areDistrictsLoaded: false,
        },
      };
    case 'DRIVER_LIST_SET_DRIVER_DETAILS':
      return {
        ...state,
        selectedDriver: data.driver,
      };
    case 'DRIVER_LIST_CLEAR_EDIT_MODAL':
      return {
        ...state,
        selectedDriver: {},
      };
    case 'DRIVER_LIST_SET_MODAL_VALUE':
      return {
        ...state,
        selectedDriver: {
          ...state.selectedDriver,
          [data.name]: data.value,
        },
      };
    case 'CLEAR_STORE_DRIVER_DETAILS':
      return driversDefaultState;
    case 'DRIVER_LIST_ADD_DRIVER_REQUEST':
      return {
        ...state,
        isRequestSending: true,
      };
    case 'DRIVER_LIST_ADD_DRIVER_CLEAR_STATE':
      return {
        ...state,
        isRequestSending: false,
      };
    case 'DRIVER_LIST_ADD_DRIVER_SUCCESS':
      return {
        ...state,
        filters: {
          ...state.filters,
          districts: [],
          areDistrictsLoaded: false,
        },
        isRequestSending: false,
      };
    case 'DRIVER_LIST_MODAL_NOTIFICATION_VALUE':
      return {
        ...state,
        notification: {
          ...state.notification,
          [data.name]: data.value,
        },
      };
    case 'DRIVER_LIST_SELECT_FREE_DRIVER':
      return {
        ...state,
        notification: {
          ...state.notification,
          free: !state.notification.free,
          busy: false,
        },
      };
    case 'DRIVER_LIST_SELECT_BUSY_DRIVER':
      return {
        ...state,
        notification: {
          ...state.notification,
          busy: !state.notification.busy,
          free: false,
        },
      };
    case 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_DRIVER':
      return {
        ...state,
        notification: {
          ...state.notification,
          ids: {
            ...state.notification.ids,
            [data.id]: data.status,
          },
        },
      };
    case 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_ALL_DRIVER':
      return {
        ...state,
        notification: {
          ...state.notification,
          ids: data.ids,
          selectAllLabel: data.status,
        },
      };
    case 'DRIVER_LIST_CLEAR_STORE_SEND_NOTIFICATION':
      return {
        ...state,
        notification: {
          id: '',
          name: '',
          district: '',
          truck: '',
          carrier: '',
          free: false,
          busy: false,
          selectAllLabel: false,
          message: '',
          ids: {},
        },
      };
    default:
      return state;
  }
};
