import React, { useState } from 'react';

import Filter, {
  FilterInput,
  FilterSelect,
  FilterDatePicker,
} from 'components/shared/Filter';
import { isDateFormatValid } from 'utils/datePickersFunctions';

const serverSideNaming = {
  id: 'invoiceId',
  customer_id: 'customerId',
  created_from: 'createdFrom',
  created_to: 'createdTo',
  due_from: 'dueFrom',
  due_to: 'dueTo',
  status: 'statuses',
  job_id: 'jobIds',
  customer_job_id: 'customerJobId',
  po_ref: 'poRef',
};

const statusOptions = [
  { value: 0, label: 'New' },
  { value: 1, label: 'Sent' },
  { value: 2, label: 'Paid' },
  { value: 3, label: 'Deleted' },
];

const InvoicesFilter = ({
  customerOptions,
  jobOptions,
  setFilters,
  filters,
  setRequestFilters,
}) => {
  const [dateAlert, toggleDateAlert] = useState(false);
  const updateFiltering = filterData => {
    setFilters(filterData);
    const convertedFiltering = filterData.reduce((obj, { key, value }) => {
      if (key === 'status') {
        obj[serverSideNaming[key]] = value.filter(item => item < 3);
        obj.ignoreRemoved = 1;
        if (value.includes(3)) {
          obj.ignoreRemoved = 0;
          if (value.length === 1) {
            obj.ignoreActive = 1;
          }
        }
      } else {
        obj[serverSideNaming[key]] = value;
      }
      return obj;
    }, {});
    setRequestFilters(convertedFiltering);
  };

  return (
    <Filter
      manualUpdate
      onUpdate={filtersData => updateFiltering(filtersData)}
      active={filters}
      filterVisible>
      <FilterInput
        filterKey="id"
        label="Invoice Id"
        filterLabel="Invoice Id"
        type="number"
        testSelector="invoice_filters_invoice-id_input"
      />
      <FilterSelect
        filterLabel="Customer"
        filterKey="customer_id"
        placeholder="Customer"
        options={customerOptions}
      />
      <FilterSelect
        filterLabel="Status"
        filterKey="status"
        placeholder="Status"
        isMulti
        options={statusOptions}
        onFilter={(row, key, value) => true}
      />
      <FilterSelect
        filterLabel="Job"
        filterKey="job_id"
        placeholder="Job"
        options={jobOptions}
        isMulti
      />
      <FilterInput
        filterKey="customer_job_id"
        label="Customer Job ID"
        filterLabel="Customer Job ID"
        testSelector="invoice_filters_customer-job-id_input"
      />
      <FilterInput
        filterKey="po_ref"
        label="PO Ref"
        filterLabel="PO Ref"
        testSelector="invoice_filters_po-ref_input"
      />
      <FilterDatePicker
        filterLabel="Created from"
        filterKey="created_from"
        label="Created from"
        timeFormat="hh:mm A"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        isDateTimePicker
        forceValue
        clearable
      />
      <FilterDatePicker
        filterLabel="Created to"
        filterKey="created_to"
        label="Created to"
        timeFormat="hh:mm A"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        isDateTimePicker
        forceValue
        clearable
      />
      <FilterDatePicker
        filterLabel="Due from"
        filterKey="due_from"
        label="Due from"
        timeFormat="hh:mm A"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        isDateTimePicker
        forceValue
        clearable
      />
      <FilterDatePicker
        filterLabel="Due to"
        filterKey="due_to"
        label="Due to"
        timeFormat="hh:mm A"
        checkFormat={e => isDateFormatValid(e, toggleDateAlert)}
        isDateTimePicker
        forceValue
        clearable
      />
      {dateAlert && (
        <div
          className="nested-no-outer-gutter"
          style={{ color: 'red' }}>
          <p>
            * Date format should be &apos;YYYY-MM-DD hh:mm AM/PM&apos;
          </p>
        </div>
      )}

    </Filter>
  );
};

export default InvoicesFilter;
