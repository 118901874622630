const init = {
  openModal: false,
  type: '',
  orders: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TODAY_INFO_PREVIEW_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_TODAY_INFO_PREVIEW_INIT':
      return {
        ...state,
        type: action.typeModal,
        orders: action.orders,
      };
    case 'JOB_DETAILS_TODAY_INFO_PREVIEW_CLEAR_STORE':
      return init;

    default:
      return state;
  }
};
