import React from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Queue';
import Link from 'components/common/Link';
import IboxTools from 'components/common/IboxTools';
import Authorize, { authorize } from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import ToolTip from 'components/shared/ToolTip';
import { convertTimeNoYear, timeFromNow } from 'utils/convertTime';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';

const OrdersList = props => {
  const {
    orders,
    caretOrderExpTime,
    caretJobName,
    caretOrderEquipment,
    caretOrderDistrict,
    sortOrdersData,
    equipmentRequired,
    turnModalAssign,
  } = props;
  const user = authService.getUser();

  return (
    <div className="ibox float-e-margins queue-table-list">
      <div className="ibox-title">
        <h3>Orders List</h3>
        <IboxTools />
      </div>
      <div
        className="ibox-content"
        style={{
          maxHeight: 500,
          overflowY: 'auto',
          marginBottom: 70,
          WebkitOverflowScrolling: 'touch',
        }}>
        <div className="table-responsive">
          <table id="datatable_table2" className="table">
            <thead>
              <tr>
                <th style={{ cursor: 'pointer' }}>
                  <span>Job Name </span>
                  {caretJobName && (
                    <Icon
                      onClick={() => sortOrdersData('job_name')}
                      icon="caret-up"
                    />
                  )}
                  {!caretJobName && (
                    <Icon
                      onClick={() => sortOrdersData('-job_name')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th style={{ cursor: 'pointer' }}>
                  <span>Times </span>
                  {caretOrderExpTime && (
                    <Icon
                      onClick={() => sortOrdersData('expected_pickup_time')}
                      icon="caret-up"
                    />
                  )}
                  {!caretOrderExpTime && (
                    <Icon
                      onClick={() => sortOrdersData('-expected_pickup_time')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>
                  <span>Districts </span>
                  {caretOrderDistrict && (
                    <Icon
                      onClick={() => sortOrdersData('district')}
                      icon="caret-up"
                    />
                  )}
                  {!caretOrderDistrict && (
                    <Icon
                      onClick={() => sortOrdersData('-district')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>
                  <span>Equipment </span>
                  {caretOrderEquipment && (
                    <Icon
                      onClick={() => sortOrdersData('equipment')}
                      icon="caret-up"
                    />
                  )}
                  {!caretOrderEquipment && (
                    <Icon
                      onClick={() => sortOrdersData('-equipment')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>Assign</th>
              </tr>
            </thead>

            <tbody>
              {orders.map(data => (
                <tr key={`order-${data.order_id}`} className={data.class}>
                  <td>
                    <ToolTip
                      title={
                        <>
                          <div>
                            Order Id:
                            <strong> {data.order_id}</strong>
                          </div>
                          <div>
                            Origin:
                            <strong>
                              {data.operation_type === 12
                                ? ` ${data.well_site_name}`
                                : ` ${data.loading_site_name}`}
                            </strong>
                          </div>
                          <div>
                            Destination:
                            <strong>
                              {data.operation_type === 12
                                ? ` ${data.loading_site_name}`
                                : ` ${data.well_site_name}`}
                            </strong>
                          </div>
                          <div>
                            Type:
                            <strong> {data.commodity_name}</strong>
                          </div>
                          {(data.job_has_npt_risk === 1 ||
                            data.job_has_shut_in_risk === 1) && (
                            <div>
                              <Icon icon="warning" /> Alert:
                              {data.job_has_npt_risk === 1 && (
                                <strong>
                                  {' '}
                                  Risk of NPT({data.hours_to_npt} h)
                                </strong>
                              )}
                              {data.job_has_npt_risk === 1 &&
                                data.job_has_shut_in_risk === 1 &&
                                ', '}
                              {data.job_has_shut_in_risk === 1 && (
                                <strong>
                                  {' '}
                                  Risk of shut-in({data.hours_to_shut_in} h)
                                </strong>
                              )}
                            </div>
                          )}
                          {Boolean(data.job_has_high_production_level) && (
                            <div>
                              <Icon icon="warning_yellow" /> Warning:{' '}
                              <strong>
                                Avg. bbls/hour: {data.bbl_rate_per_hour}
                              </strong>
                            </div>
                          )}
                          {Boolean(data.job_has_tanks_with_high_level) && (
                            <div>
                              <Icon icon="warning_orange" /> Warning:{' '}
                              <strong>
                                Tank level: {data.max_tank_alert_level} /{' '}
                                {data.high_tank_alert_level}
                              </strong>
                            </div>
                          )}
                        </>
                      }>
                      {Boolean(data.job_has_high_production_level) && (
                        <Icon icon="warning_yellow" />
                      )}
                      {(data.job_has_npt_risk === 1 ||
                        data.job_has_shut_in_risk === 1) && (
                        <Icon icon="warning" />
                      )}
                      {Boolean(data.job_has_tanks_with_high_level) && (
                        <Icon icon="warning_orange" />
                      )}

                      <Authorize {...{ forbidden: ['CARRIER'] }}>
                        {authorize({
                          abilityPermissions: [
                            { resource: 'Jobs', permissions: ['read'] },
                          ],
                        }) ? (
                          <Link
                            {...{
                              id: data.job_id,
                              to: 'job-details',
                            }}>
                            {data.job_name.length < 25
                              ? data.job_name
                              : `${data.job_name.substring(0, 25)}...`}
                          </Link>
                        ) : data.job_name.length < 25 ? (
                          data.job_name
                        ) : (
                          `${data.job_name.substring(0, 25)}...`
                        )}
                      </Authorize>
                      {data.isCarrierManager ? (
                        <>
                          {authorize({
                            abilityPermissions: [
                              { resource: 'Jobs', permissions: ['read'] },
                            ],
                          }) ? (
                            <Link
                              {...{
                                id: data.job_id,
                                to: 'job-details',
                              }}>
                              {data.job_name.length < 25
                                ? data.job_name
                                : `${data.job_name.substring(0, 25)}...`}
                            </Link>
                          ) : data.job_name.length < 25 ? (
                            data.job_name
                          ) : (
                            `${data.job_name.substring(0, 25)}...`
                          )}
                        </>
                      ) : (
                        <Authorize {...{ access: ['CARRIER'] }}>
                          <span>
                            {authorize({
                              abilityPermissions: [
                                { resource: 'Jobs', permissions: ['read'] },
                              ],
                            }) ? (
                              <Link
                                {...{
                                  id: data.job_id,
                                  to: 'job-details',
                                }}>
                                {data.job_name.length < 25
                                  ? data.job_name
                                  : `${data.job_name.substring(0, 25)}...`}
                              </Link>
                            ) : data.job_name.length < 25 ? (
                              data.job_name
                            ) : (
                              `${data.job_name.substring(0, 25)}...`
                            )}
                          </span>
                        </Authorize>
                      )}
                    </ToolTip>
                  </td>
                  <td>
                    <div>
                      <strong>Unload appt: </strong>
                      <span>
                        {convertTimeNoYear(data.unload_appt)} (
                        {timeFromNow(data.unload_appt)})
                      </span>
                    </div>
                    <div>
                      <strong>Pickup Time: </strong>
                      <span>
                        {convertTimeNoYear(data.expected_pickup_time)} (
                        {timeFromNow(data.expected_pickup_time)})
                      </span>
                    </div>
                  </td>
                  <td>
                    {data.districts
                      .map(item => `#${item.id} | ${item.name}`)
                      .join(', ')}
                  </td>
                  <td>
                    {equipmentRequired.map(
                      equipment =>
                        data.allowed_equipments.includes(equipment.id) && (
                          <span
                            key={equipment.id}
                            className="badge badge--warning">
                            {equipment.title}
                          </span>
                        ),
                    )}
                  </td>
                  <td>
                    {!data.driver_id && (user.role !== ROLES.CARRIER || (user.role === ROLES.CARRIER && data.isManualAssignEnabled)) && (
                      <Authorize
                        {...{
                          abilityPermissions: [
                            {
                              resource: 'Queue',
                              permissions: ['update'],
                            },
                          ],
                        }}>
                        <Button
                          theme="small"
                          className="button--small--square"
                          testSelector="queue_order-list_modal-assign_btn"
                          onClick={() => turnModalAssign(data)}>
                          <Icon icon="plus-square" />
                        </Button>
                      </Authorize>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  caretJobName: state.queue.sortOrdersList.job_name,
  caretOrderDistrict: state.queue.sortOrdersList.district,
  caretOrderEquipment: state.queue.sortOrdersList.equipment,
  caretOrderExpTime: state.queue.sortOrdersList.expected_pickup_time,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  sortOrdersData: value => {
    dispatch(actions.setSortOrdersData(value));
  },
  turnModalAssign: order => dispatch(actions.turnModalAssign(order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersList);
