import React from 'react';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';

const Lanes = ({ lanes, deleteLanes, openLanesModal }) => {
  return (
    <span>
      {lanes.length != 0 ? (
        <div className="row">
          <Button
            onClick={() => openLanesModal({ ...{ action: 'add' } })}
            children="Add Lanes"
            className="float-end"
            testSelector="sandsite-schedule_lanes_add_btn"
          />
        </div>
      ) : (
        <div className="row">
          <h3>No lanes have been added. </h3>
          <Button
            onClick={() => openLanesModal({ ...{ action: 'add' } })}
            children="Add Lanes"
            className="float-end"
            testSelector="sandsite-schedule_lanes_add-new_btn"
          />
        </div>
      )}
      <table className="table table--striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Facility</th>
            <th>Commodity</th>
            <th>Truck capacity per hour</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {lanes.map(item => (
            <tr key={'lane_'+ item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.sandSiteName}</td>
              <td>{item.sandTypeName}</td>
              <td>{item.truckCapacityPerHour}</td>
              <td>{item.notes}</td>
              <td>
                <Button
                  onClick={() =>
                    openLanesModal({ ...{ ...item, action: 'edit' } })
                  }
                  theme="small"
                  testSelector="sandsite-schedule_lanes_edit_btn"
                  colour="white">
                  <Icon icon="pencil" />
                </Button>
                <Button
                  onClick={() => deleteLanes(item)}
                  theme="small"
                  testSelector="sandsite-schedule_lanes_edit-new_btn"
                  colour="white">
                  <Icon icon="trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </span>
  );
};

export default Lanes;
