import { client } from 'api/client';

/**
 *
 * @param {*} params
 * @returns
 */
export const getStageConfig = async ({ jobId, stageId }) => {
  const { data } = await client.get(`v2/job/${jobId}/stage/${stageId}/configs`);

  data.data.stageConfigs.forEach(item => {
    item.volumeOld = item.volume;
    item.volumeNew = item.volume;
  });
  return data.data.stageConfigs;
};

export const completeStage = async ({
  stageId,
  jobId,
  completedAt,
  jobStageConfig,
  startedAt,
}) => {
  const { data } = await client.post('v2/order/complete-stage', {
    jobStageId: stageId,
    jobId,
    completedAt,
    jobStageConfig,
    startedAt,
  });
  return data;
};

export const addStageDesign = async ({ jobId, stageDesign }) => {
  const { data } = await client.post(
    `v2/job/${jobId}/stage-design`,
    stageDesign,
  );
  return data;
};

/**
 * @typedef {{
 *  jobId: number;
 *  unloadAppointment: string;
 *  stageDesign: { volume: number; sandTypeId: number; }[]
 * }} StageInfo
 */

/**
 *
 * @param {StageInfo} stageInfo
 * @returns
 */
export const getStagePreview = async stageInfo => {
  const { data } = await client.post('v2/order/preview-stage', stageInfo);
  return data.data.orders;
};

/**
 *
 * @param {StageInfo} stageInfo
 * @returns
 */
export const dispatchStage = async stageInfo => {
  const { data } = await client.post('v2/order/dispatch-stage', stageInfo);
  return data;
};

/**
 *
 * @param {{
 *  jobId: number;
 *  name: string
 * }} params
 */
export const deleteStageDesign = async ({ jobId, name }) => {
  const { data } = await client.delete(
    `v2/job/${jobId}/stage-design/${encodeURIComponent(name)}`,
  );
  return data;
};
