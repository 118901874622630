import React, { useEffect, useState } from 'react';
import Toggle from 'react-switch';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import ToolTip from 'components/shared/ToolTip';
import useHomeState from 'components/views/Home/hooks/useHomeState';
import {
  setJobId,
  setLocationId,
  setDistrictId,
  setDriverId,
  setCarrierId,
  setJobType,
  setDriverType,
  setSandSites,
  setWellSites,
  setDrivers,
  setWaterDisposal,
  setWaterProduction,
} from 'store/actions/OrdersFilters';
import formatString from 'utils/formatString';
import {
  FilterTypes,
  isValidFilterType,
} from 'components/views/Home/HomeFilters/helpers';

const HomeFilters = ({
  jobIdAndName,
  locationIdAndName,
  districtIdAndName,
  driverIdAndName,
  carrierIdAndName,
  jobTypes,
  ordersFilters,
  ...props
}) => {
  const { onlyAlerts, setOnlyAlerts } = useHomeState();

  const [filterVisibility, setFilterVisibility] = useState(false);

  const driverTypes = [
    {
      label: 'Loaded',
      value: 'loaded',
    },
    {
      label: 'Empty',
      value: 'empty',
    },
  ];

  // Initialize Filters based on Store state (Clear All button initialized as well)
  const initialFiltersStatus = {
    Job: ordersFilters.jobId,
    Location: ordersFilters.locationId,
    District: ordersFilters.districtId,
    Driver: ordersFilters.driverId,
    Carrier: ordersFilters.carrierId,
    'Job Type': ordersFilters.jobType,
    'Driver Status': ordersFilters.status,
  };

  const [activeFilters, setActiveFilters] = useState(
    Object.keys(initialFiltersStatus).filter(key => initialFiltersStatus[key]),
  );

  // Set All checkboxes as checked on page load
  const [checkbox, setCheckbox] = useState({
    sand_sites: true,
    well_sites: true,
    drivers: true,
    disposal_site: true,
    production_site: true,
  });

  useEffect(() => {
    props.setSandSites(true);
    props.setWellSites(true);
    props.setDrivers(true);
    props.setWaterDisposal(true);
    props.setWaterProduction(true);
  }, []);

  const filterMethods = {
    Job: value => props.setJobId(value),
    Location: value => props.setLocationId(value),
    District: value => props.setDistrictId(value),
    Driver: value => props.setDriverId(value),
    Carrier: value => props.setCarrierId(value),
    'Job Type': value => props.setJobType(value),
    'Driver Status': value => props.setDriverType(value),
    sand_sites: value => props.setSandSites(!value),
    well_sites: value => props.setWellSites(!value),
    drivers: value => props.setDrivers(!value),
    disposal_site: value => props.setWaterDisposal(!value),
    production_site: value => props.setWaterProduction(!value),
  };

  const removeFilter = type => {
    if (isValidFilterType(type)) {
      setCheckbox({
        ...checkbox,
        [type]: !checkbox[type],
      });
      setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
      filterMethods[type](checkbox[type]);
    } else {
      setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
      filterMethods[type]('');
    }
  };

  const removeCheckboxFilter = type => {
    setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
    filterMethods[type](checkbox[type]);
  };

  const addFilter = (type, value) => {
    setActiveFilters([
      ...activeFilters.filter(filter => filter !== type),
      type,
    ]);
    if (isValidFilterType(type)) {
      return filterMethods[type](value);
    }
    return filterMethods[type](value.value);
  };

  const handleCheckBoxFilters = type => {
    setCheckbox({
      ...checkbox,
      [type]: !checkbox[type],
    });
    if (checkbox[type]) {
      addFilter(type, checkbox[type]);
    } else {
      removeCheckboxFilter(type);
    }
  };

  const clearAllFilters = () => {
    activeFilters.forEach(type => {
      if (!isValidFilterType(type)) {
        removeFilter(type);
        return;
      }
      removeCheckboxFilter(type);
      setCheckbox({
        sand_sites: true,
        well_sites: true,
        production_site: true,
        disposal_site: true,
        drivers: true,
      });
    });
    setActiveFilters([]);
  };

  return (
    <>
      <div className="toggle-container">
        <ToolTip
          title={`${filterVisibility ? 'Hide' : 'Show'} Filters`}
          placement="left">
          <Button
            theme="small"
            className={`filter-toggle button--small--square ${(filterVisibility &&
              'active') ||
              ''}`}
            onClick={() => setFilterVisibility(!filterVisibility)}
            inverse={filterVisibility}
            testSelector="home_filters_toggle_btn">
            <Icon icon="filter" data-testid="filter-icon" />
          </Button>
        </ToolTip>
      </div>

      <div className="home-filters">
        {activeFilters.length > 0 && (
          <div className="filters__active">
            {activeFilters.map(filter => (
              <span className="label label--filter" key={filter}>
                {formatString(filter, 't')}
                <Icon icon="close" onClick={() => removeFilter(filter)} />
              </span>
            ))}
            <Button
              theme="small"
              onClick={() => clearAllFilters()}
              testSelector="home_filters_clear-all_btn">
              Clear All
            </Button>
          </div>
        )}

        <div
          data-testid="home-main-filter-container"
          className={`home-filters-container ${
            filterVisibility ? 'open' : ''
          }`}>
          <div className="home-filters-container__top-row">
            <span className="home-filters-container__top-row--show">Show</span>
            <Input
              type="checkbox"
              testSelector="home_filters_sand-sites_input"
              label="Sand sites"
              onChange={() => handleCheckBoxFilters(FilterTypes.SAND_SITES)}
              isChecked={checkbox.sand_sites}
            />
            <Input
              type="checkbox"
              label="Well sites"
              onChange={() => handleCheckBoxFilters(FilterTypes.WELL_SITES)}
              isChecked={checkbox.well_sites}
              testSelector="home_filters_well-sites_input"
            />
            <Input
              type="checkbox"
              label="Disposal Sites"
              onChange={() => handleCheckBoxFilters(FilterTypes.DISPOSAL_SITE)}
              isChecked={checkbox.disposal_site}
              testSelector="home_filters_disposal-sites_input"
            />
            <Input
              type="checkbox"
              label="Production Sites"
              onChange={() =>
                handleCheckBoxFilters(FilterTypes.PRODUCTION_SITE)
              }
              isChecked={checkbox.production_site}
              testSelector="home_filters_production-sites_input"
            />
            <Input
              type="checkbox"
              label="Drivers"
              onChange={() => handleCheckBoxFilters(FilterTypes.DRIVERS)}
              isChecked={checkbox.drivers}
              testSelector="home_filters_drivers_input"
            />
            <span className="filter-toggle">
              <span className="filter-toggle__label">All</span>
              <Toggle
                checked={onlyAlerts}
                onChange={() => setOnlyAlerts(!onlyAlerts)}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                onColor="#6fc6d9"
                offColor="#5c5f65"
                height={20}
                width={40}
              />
              <span className="filter-toggle__label">Alerted</span>
            </span>
          </div>
          <div
            className="home-filters-container__bottom-row"
            data-testid="filters-container">
            <Select
              placeholder="Job ID / Name"
              options={jobIdAndName}
              onChange={item => addFilter('Job', item)}
              value={
                jobIdAndName.find(job => ordersFilters.jobId === job.value) ||
                null
              }
              testSelector="home_filters_job_select"
            />
            <Select
              placeholder="Location ID / Name"
              options={locationIdAndName}
              onChange={item => addFilter('Location', item)}
              value={
                locationIdAndName.find(
                  location => ordersFilters.locationId === location.value,
                ) || null
              }
              testSelector="home_filters_location_select"
            />
            <Select
              placeholder="District ID / Name"
              options={districtIdAndName}
              onChange={item => addFilter('District', item)}
              value={
                districtIdAndName.find(
                  district => ordersFilters.districtId === district.value,
                ) || null
              }
              testSelector="home_filters_district_select"
            />
            <Select
              placeholder="Driver ID / Name"
              options={driverIdAndName}
              onChange={item => addFilter('Driver', item)}
              value={
                driverIdAndName.find(
                  driver => ordersFilters.driverId === driver.value,
                ) || null
              }
              testSelector="home_filters_driver_select"
            />
            <Select
              placeholder="Carrier ID / Name"
              options={carrierIdAndName}
              onChange={item => addFilter('Carrier', item)}
              value={
                carrierIdAndName.find(
                  carrier => ordersFilters.carrierId === carrier.value,
                ) || null
              }
              testSelector="home_filters_carrier_select"
            />
            <Select
              placeholder="Job Type"
              options={jobTypes}
              onChange={item => addFilter('Job Type', item)}
              value={
                jobTypes.find(
                  jobType => ordersFilters.jobType === jobType.value,
                ) || null
              }
              testSelector="home_filters_job-type_select"
            />
            {checkbox.drivers && (
              <Select
                placeholder="Driver Status"
                options={driverTypes}
                onChange={item => addFilter('Driver Status', item)}
                value={
                  driverTypes.find(
                    driverType => ordersFilters.status === driverType.value,
                  ) || null
                }
                testSelector="home_filters_driver-status_select"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ordersFilters: state.ordersFilters,
});

const mapDispatchToProps = dispatch => ({
  setJobId: jobId => dispatch(setJobId(jobId)),
  setLocationId: locationId => dispatch(setLocationId(locationId)),
  setDistrictId: districtId => dispatch(setDistrictId(districtId)),
  setDriverId: driverId => dispatch(setDriverId(driverId)),
  setCarrierId: carrierId => dispatch(setCarrierId(carrierId)),
  setJobType: jobType => dispatch(setJobType(jobType)),
  setDriverType: driverStatus => dispatch(setDriverType(driverStatus)),
  setSandSites: sandSites => dispatch(setSandSites(sandSites)),
  setWellSites: wellSite => dispatch(setWellSites(wellSite)),
  setDrivers: drivers => dispatch(setDrivers(drivers)),
  setWaterProduction: waterDisposal =>
    dispatch(setWaterProduction(waterDisposal)),
  setWaterDisposal: waterProduction =>
    dispatch(setWaterDisposal(waterProduction)),
});

HomeFilters.propTypes = {
  // original
  jobIdAndName: PropTypes.instanceOf(Array).isRequired,
  locationIdAndName: PropTypes.instanceOf(Array).isRequired,
  districtIdAndName: PropTypes.instanceOf(Array).isRequired,
  driverIdAndName: PropTypes.instanceOf(Array).isRequired,
  carrierIdAndName: PropTypes.instanceOf(Array).isRequired,
  jobTypes: PropTypes.instanceOf(Array).isRequired,
  // redux
  ordersFilters: PropTypes.instanceOf(Object).isRequired,
  setJobId: PropTypes.func.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setDistrictId: PropTypes.func.isRequired,
  setDriverId: PropTypes.func.isRequired,
  setCarrierId: PropTypes.func.isRequired,
  setJobType: PropTypes.func.isRequired,
  setSandSites: PropTypes.func.isRequired,
  setWellSites: PropTypes.func.isRequired,
  setDrivers: PropTypes.func.isRequired,
  setWaterDisposal: PropTypes.func.isRequired,
  setWaterProduction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeFilters);
