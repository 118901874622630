import React, { useState, useEffect } from 'react';
import swal from 'bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import actions from 'store/actions/ManageDatabase';
import Authorize from 'components/common/Authorize';

import { useEditDistrict, useAddDistrict } from 'api/v2/districts';

const AddEditModal = ({
  showUpdate,
  closeUpdate,
  setInputValue,
  updateModal,
  clear,
}) => {
  useEffect(
    () => () => {
      clear();
    },
    [],
  );


  const {
    name,
    districtDetails,
    status,
    isCarrierRatioEnabled,
    isManualDispatchEnabled,
    activeTrucks,
    accessLevel,
  } = updateModal;

  const { mutate: editDistrict } = useEditDistrict(updateModal.id);
  const { mutate: addDistrict } = useAddDistrict();

  const selectFunction = {
    add: (e, closeModal) => {
      e.preventDefault();
      addDistrict(
        {
          ...updateModal,
          district: updateModal.name,
          isCarrierRatioEnabled: Number(updateModal.isCarrierRatioEnabled),
          isManualDispatchEnabled: Number(updateModal.isManualDispatchEnabled)
        },
        {
          onSuccess: () => {
            swal('Success!', '', 'success');
            closeModal();
          }
        });
    },
    edit: (e, closeModal) => {
      e.preventDefault();
      editDistrict(
        {
          ...updateModal,
          isCarrierRatioEnabled: Number(updateModal.isCarrierRatioEnabled),
          isManualDispatchEnabled: Number(updateModal.isManualDispatchEnabled)
        },
        {
          onSuccess: () => {
            swal('Success!', '', 'success');
            closeModal();
          }
        }
      );
    }
  };

  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    let disabled = false;

    if (!name.trim().length || !districtDetails.trim().length) {
      disabled = true;
    }

    setSaveDisabled(disabled);
  }, [name, districtDetails, isCarrierRatioEnabled, setSaveDisabled]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => selectFunction[status](e, closeUpdate)}>
          <Modal.Header>
            <h3>{status === 'add' ? 'Add District' : 'Edit District'}</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="manage-database_districts_add-edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'districtDetails')}
                  value={districtDetails}
                  label="District details"
                  required
                  testSelector="manage-database_districts_add-edit_details_input"
                />
              </div>
            </Row>
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Districts',
                    permissions: status === 'add' ? ['create'] : ['update'],
                  },
                ],
                itemAccessGranted:
                  status === 'add' ? true : accessLevel === 'owner',
              }}>
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    testSelector="manage-database_districts_add-edit_carrier-ratio_input"
                    isChecked={isCarrierRatioEnabled}
                    onChange={e => setInputValue(e, 'isCarrierRatioEnabled')}
                    label="Carrier Ratio"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    testSelector="manage-database_districts_add-edit_manual-dispatch_input"
                    isChecked={isManualDispatchEnabled}
                    onChange={e => setInputValue(e, 'isManualDispatchEnabled')}
                    label="Manual Dispatch"
                  />
                </div>
              </Row>
            </Authorize>
            {isCarrierRatioEnabled && status === 'edit' && (
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="number"
                    onChange={e => setInputValue(e, 'activeTrucks')}
                    value={activeTrucks}
                    label="Active Trucks"
                    min={0}
                    max={100}
                    step={1}
                    testSelector="manage-database_districts_add-edit_activeTrucks_input"
                    disabled
                  />
                </div>
                <div className="form-group has-feedback col-md-6" />
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeUpdate}
              colour="white"
              testSelector="database_districts_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="database_districts_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  updateModal: state.database.districts.updateModal,
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueDistrict(targetValue(e), name)),
  clear: () => dispatch(actions.clearDistrictModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditModal);
