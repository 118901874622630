import _ from 'lodash';
import { roles } from './endpoints';
import swal from 'bootstrap-sweetalert';
import { Ability, AbilityBuilder } from '@casl/ability';
import { packRules } from '@casl/ability/extra';

// Get all roles, permissions, and grants
export const getAllRolesAndPermissions = async companyTypeId => {
  try {
    const roleTypesResponse = await roles.getRoleTypes();
    const roleTypes = roleTypesResponse.data.data;
    const rolesResponse = await roles.getRoles(companyTypeId);
    const rolesData = rolesResponse.data.data;
    const parsedRolesResponse = rolesData.map(r => {
      r.name = roleTypes.find(type => type.id === r.roleTypeId).name;
      r.permissions = JSON.parse(r.permissions || []).reduce((accum, perm) => {
        let resourceName = perm[1]
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        // This formatting is temporary and will be fixed on the backend
        if (resourceName === 'Manage-database') {
          resourceName = 'Database';
        }
        if (resourceName === 'Manage-locations') {
          resourceName = 'Locations';
        }
        if (resourceName === 'Manage-districts') {
          resourceName = 'Districts';
        }
        if (resourceName === 'Equipment-trailers') {
          resourceName = 'Trailers';
        }
        if (resourceName === 'Equipment-containers') {
          resourceName = 'Containers';
        }
        if (resourceName === 'Po') {
          resourceName = 'Purchase-orders';
        }
        if (resourceName === 'Admin-settings') {
          resourceName = 'Admin';
        }
        if (resourceName === 'User-roles') {
          resourceName = 'Roles';
        }
        let existingGrant = accum.find(a => {
          const matchedResource = a.resource.find(r => r === resourceName);
          if (matchedResource) {
            return true;
          }
          return false;
        });
        if (perm[0] !== 'execute') {
          if (!existingGrant) {
            accum.push({
              resource: [resourceName],
              action: [perm[0]],
              attribute: ['*'],
            });
          } else {
            existingGrant.action.push(perm[0]);
            const allPerms = _.intersection(existingGrant.action, [
              'create',
              'read',
              'update',
              'delete',
            ]);
            if (allPerms.length === 4) {
              existingGrant.action = ['*'];
            }
          }
        }

        return accum;
      }, []);
      return r;
    });

    const allPermissions = await roles.getPermissions();
    const permissionsData = allPermissions.data.data;
    const mappedPermissions = permissionsData.map(p => {
      return {
        resource: p.subject
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        grants: p.actions,
      };
    });

    return {
      data: {
        roles: parsedRolesResponse,
        permissions: mappedPermissions,
      },
    };
  } catch (error) {
    swal(error.response.data.error || 'Something went wrong.', '', 'error');
  }
};

// Update a single roles data
export const updateRole = async data => {
  const builder = new AbilityBuilder(Ability);
  const permissionData = await roles.getPermissions();
  const validPermissions = permissionData.data.data.reduce((acc, li) => {
    acc[li.subject] = li.actions;
    return acc;
  }, {});

  data.permissions.map(perm => {
    const subject = perm.pid.toLowerCase();

    // Remove duplicates since the checkbox state stuff is all wacky
    perm.grants = Array.from(new Set(perm.grants));

    if (perm.grants.includes('*')) {
      perm.grants = validPermissions[subject] ?? [
        'create',
        'read',
        'update',
        'delete',
      ];
    }

    if (subject !== 'personal-info' && !validPermissions[subject]) {
      return;
    }

    if (subject !== 'personal-info') {
      perm.grants = perm.grants.filter(grant =>
        validPermissions[subject].includes(grant),
      );
    }

    if (validPermissions[subject] || subject === 'personal-info') {
      perm.grants.map(grant => builder.can(grant, subject));
    }
  });

  const permissions = JSON.stringify(packRules(builder.rules));

  const roleDetails = {
    name: data.role,
    permissions,
  };

  await roles.updateRole(data.roleId, roleDetails);
};

// Add new role
export const addRole = async (data, companyTypeId) => {
  const builder = new AbilityBuilder(Ability);
  const permissionData = await roles.getPermissions();
  const validSubjects = new Set(
    permissionData.data.data.map(rule => rule.subject),
  );

  data.permissions.map(perm => {
    if (perm.grants[0] === '*') {
      perm.grants = ['create', 'read', 'update', 'delete'];
    }

    perm.grants.map(grant => builder.can(grant, perm.pid.toLowerCase()));

    const subject = perm.pid.toLowerCase();

    if (validSubjects.has(subject)) {
      perm.grants.map(grant => builder.can(grant, perm.pid.toLowerCase()));
    }
  });

  const permissions = JSON.stringify(packRules(builder.rules));

  const newRoleData = {
    name: data.role,
    companyTypeId,
    permissions,
  };

  await roles.createRole(newRoleData);
};

// Delete role
export const deleteRole = async data => {
  await roles.deleteRole(data.roleId);
};

// Update multiple roles on main grid
export const updateMultipleRoles = data => {
  return Promise.all(
    data.map(async roleData => {
      const params = {
        rtpId: roleData.rtpId,
        role: roleData.role,
        permissions: roleData.permissions,
      };

      await updateRole(params);
    }),
  );
};
