import { operationTypes } from 'components/globalInfo/jobOperationType';

export const billableOptions = billableOperationTypeStatus => {
  const options = Object.keys(operationTypes)
    .filter(item => operationTypes[item].value !== 0)
    .map(item => {
      const data = {
        value: operationTypes[item].value,
        label: operationTypes[item].label,
        isChecked: false,
      };
      billableOperationTypeStatus.forEach(type => {
        if (type.operationType === operationTypes[item].value) {
          data.isChecked = type.billableStatus;
        }
      });
      return data;
    });
  return options;
};
