import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  addStageDesign,
  completeStage,
  deleteStageDesign,
  dispatchStage,
  getStageConfig,
  getStagePreview,
} from './queries';

/**
 *
 * @param {*} params
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} options
 * @returns
 */
export const useStageConfigs = ({ jobId, stageId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'stage', stageId],
    queryFn: () => getStageConfig({ jobId, stageId }),
    enabled: !!(jobId && stageId),
    placeholderData: [],
  });

/**
 *
 * @param {import('./queries').StageInfo} stageInfo
 * @returns
 */
export const useStagePreview = stageInfo =>
  useQuery({
    queryKey: ['jobs', stageInfo.jobId, 'stage-preview', stageInfo],
    queryFn: () => getStagePreview(stageInfo),
    enabled: !!stageInfo,
    retry: () => false,
    staleTime: 0,
  });

export const useCompleteStage = ({ jobId, stageId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: params => completeStage({ jobId, stageId, ...params }),
    onSuccess: () => {
      swal('Successfully Completed Stage', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs-stage', jobId, stageId] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => swal('Unable to Create Carrier', error.message, 'error'),
  });
};

export const useAddStageDesign = jobId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: stageDesign => addStageDesign({ jobId, stageDesign }),
    onSuccess: () => {
      swal('Successfully Added Stage Design', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs-stageDesign', jobId] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal(
        'Unable to Add Stage Design',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useDispatchStage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: dispatchStage,
    onSuccess: (_data, { jobId }) => {
      swal('Successfully Dispatched Stage', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs-stageDesign'] });
      queryClient.invalidateQueries({ queryKey: ['jobs', Number(jobId), 'details'] })
    },
    onError: error => swal('Unable to Dispatch Stage', error.message, 'error'),
  });
};

export const useDeleteStageDesign = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: name => deleteStageDesign({ jobId, name }),
    onSuccess: () => {
      swal('Successfully Deleted Stage Design', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs-stageDesign', jobId] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal('Unable to Delete Stage Design', error.message, 'error'),
  });
};
