import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import swal from 'bootstrap-sweetalert';
import Select from 'components/shared/Select';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Authorize, { authorize } from 'components/common/Authorize';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';
import multiSelectOptions from 'utils/multiSelectOptions';
import targetValue from 'utils/targetValue';
import cancelSwal from 'utils/cancelSwal';
import {
  useEditCustomer
} from 'api/v2/customers';
import {
  useGetDistrictsQuery,
} from 'features/api/apiSlice';
import Loader from 'components/shared/Loader';

const defaultOnBoardingDistrict = Number(
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const initEditInfo = {
  customerName: '',
  email: '',
  phoneNumber: '',
  sandCoordinatorRequired: '',
  fuelSurchargeEnabled: '',
  displayRateToDriver: '',
  districts: [],
  useQRCode: '',
  price: '',
  selectedDistricts: [],
  qbId: '',
};

const EditCustomer = ({ details, closeEdit, showEdit }) => {
  const { data: districts, isLoading } = useGetDistrictsQuery();
  const { mutate: updateCustomer } = useEditCustomer(details?.id);

  const [info, setInfo] = useState(initEditInfo);

  const user = authService.getUser();

  useEffect(() => {
    if (details) {
      setInfo({
        ...details,
        districts: (details.districts || []).map(item => ({
          value: item.id || item.value,
          label: item.name || item.label,
        })),
      });
    } else {
      setInfo(initEditInfo);
    }
  }, [details, showEdit]);

  const setValue = (e, name) => {
    const value = targetValue(e);
    setInfo(state => ({
      ...state,
      [name]: value,
    }));
  };

  const saveEdit = async e => {
    e.preventDefault();

    const data = {
      ...info,
      sandCoordinatorRequired: Number(info.sandCoordinatorRequired),
      fuelSurchargeEnabled: Number(info.fuelSurchargeEnabled),
      displayRateToDriver: Number(info.displayRateToDriver),
      useQRCode: Boolean(info.useQRCode),
      name: info.customerName,
      phoneNo: info.phoneNumber,
      districts: info.districts.map(i => i.value),
    };

    if (info.qbId) {
      data.qbId = info.qbId.toString();
    } else {
      data.qbId = undefined;
    }

    updateCustomer(data,
      {
        onSuccess: () => {
          swal('Success!', 'Successfully updated customer.', 'success');
          closeEdit();
        },
        onError: (error) => swal('Error updating customer.', error?.response?.data?.message, 'error')
      });
  };

  const companyOptions = useMemo(() => {
    if (
      authorize({
        access: ['JOB_MANAGER'],
      })
    ) {
      return [
        { value: 2, label: 'Managed Customer' },
        { value: 3, label: 'SaaS Customer' },
      ];
    }
    // if not admin
    return [{ value: 2, label: 'Managed Customer' }];
  }, []);

  const saveDisabled = useMemo(() => {
    if (
      !info.customerName ||
      !info.email ||
      !info.phoneNumber ||
      !info.companyType ||
      info.districts.length < 1 ||
      !info.driverTimeout
    ) {
      return true;
    }
    return false;
  }, [info]);

  const handleDistrictChange = (name, value) => {
    const hasDefault = value.some(
      option => option.value === defaultOnBoardingDistrict,
    );
    if (hasDefault) {
      setValue(value, name)
    } else {
      setValue([...value, { value: defaultOnBoardingDistrict, label: 'On-boarding' }], name)
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeEdit}>
        <form
          onSubmit={e => saveEdit(e, closeEdit)}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Customer</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'customerName')}
                  value={info.customerName}
                  label="Customer name"
                  required
                  testSelector="customer-details_info_edit-customer_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'email')}
                  value={info.email}
                  label="Email"
                  type="email"
                  testSelector="customer-details_info_edit-customer_email_input"
                  required
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="tel"
                  value={info.phoneNumber}
                  onChange={e => setValue(e, 'phoneNumber')}
                  label="Phone number"
                  testSelector="customer-details_info_edit-customer_phone_input"
                  required
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                {districts && (
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={selected =>
                      handleDistrictChange('districts', selected)
                    }
                    options={multiSelectOptions(districts, 'id', 'name')}
                    placeholder="Select Districts"
                    defaultValue={info.districts}
                    required
                    testSelector="customer-details_info_edit-customer_district_select"
                  />
                )}
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Authorize
                  {...{
                    access: ['JOB_MANAGER'],
                  }}>
                  <Input
                    onChange={e => setValue(e, 'qbId')}
                    value={info.qbId}
                    label="QuickBooks ID"
                    testSelector="customer-details_info_edit-customer_quickbook-id_input"
                  />
                </Authorize>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Authorize
                  {...{
                    access: ['JOB_MANAGER'],
                  }}>
                  <Select
                    onChange={selected => {
                      cancelSwal(
                        'Are you sure you want to change the company type?',
                        () => setValue(selected.value, 'companyType'),
                        () => setValue(info.companyType, 'companyType'),
                      );
                    }}
                    options={companyOptions}
                    placeholder="Select Company Type"
                    value={
                      info.companyType &&
                      companyOptions.find(e => e.value === info.companyType)
                    }
                    required
                    testSelector="customer-details_info_edit-customer_company-type_select"
                  />
                </Authorize>
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'driverTimeout')}
                  value={info.driverTimeout}
                  type="number"
                  label="Driver Timeout (minutes)"
                  testSelector="customer-details_info_edit-customer_driver-timeout_input"
                  required
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setValue(e, 'factorViewId')}
                  value={info.factorViewId}
                  label="FactorView ClientID"
                  min="0"
                  max="999999"
                  step={1}
                  production-qty="true"
                  disabled={
                    user.role === ROLES.CARRIER || user.role === ROLES.CUSTOMER
                  }
                  testSelector="customer-details_edit_factor-view-id_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="customer-details_edit_is-non-purchasable_input"
                  onChange={e => setValue(e, 'isNonPurchasable')}
                  isChecked={info.isNonPurchasable}
                  disabled={
                    user.role === ROLES.CARRIER || user.role === ROLES.CUSTOMER
                  }
                  label="FactorView Non-Purchasable"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="customer-details_info_edit-customer_fuel-surcharge_input"
                  isChecked={Boolean(info.fuelSurchargeEnabled)}
                  onChange={e => setValue(e, 'fuelSurchargeEnabled')}
                  label="Fuel surcharge enabled"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEdit}
              colour="white"
              testSelector="customer-details_info_edit-customer_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="customer-details_info_edit-customer_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
export default EditCustomer;
