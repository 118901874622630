import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import { get, update } from './queries';

export const useCustomerNotarizationSettings = (customerId) =>
  useQuery({
    queryFn: () => get(customerId),
    queryKey: ['customers', customerId, 'notarization-settings'],
  });

export const useUpdateCustomerNotarizationSettings = (customerId) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => update({ customerId, data }),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['customers', customerId, 'notarization-settings'] });
      swal('Success', 'Notarization settings updated', 'success');
    },
  });
}
