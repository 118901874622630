import React, { useEffect, useState } from 'react';
import Loader from 'components/shared/Loader';
import { connect } from 'react-redux';
import authService from 'services/auth/service';
import {
  ticketsRequested,
  ticketsDownloadAllTickets,
} from 'store/actions/OrderTickets';
import Icon from 'components/shared/Icon';
import DropdownButton from 'components/shared/DropdownButton';
import Link from 'components/common/Link';
import Authorize, { authorize } from 'components/common/Authorize';
import Button from 'components/shared/Button';
import { approveOrder } from 'store/actions/Orders';
import ContestModal from 'components/shared/ContestModal';
import HistoryModal from 'components/views/OrderDetails/Header/HistoryModal';
import OrderInfoTicket from './OrderInfoTicket';
import ModifyModal from './ModifyModal';
import TagsList from './OrderInfoTagsList';
import OrderInfoColumns from './OrderInfoColumns';
import Timeline from './Timeline';
import UploadTicket from './UploadTicket';
import Price from './Price';

const OrderInfo = ({
  setCurrentTab,
  data,
  page,
  getTickets,
  tickets,
  config,
  isLoading,
  equipmentRequired,
  approveOrderFunc,
  downloadAllTicketsFunc,
  downloadAllLoading,
  getOrders,
}) => {
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [contestModal, setContestModal] = useState(false);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [showHistory, turnHistory] = useState(false);
  const [showUploadTicket, turnUploadTicket] = useState(false);
  const [missingTicketsArray, setMissingTickets] = useState([]);

  useEffect(() => {
    if (config) {
      const missingTickets = [];
      config.forEach(item => {
        if (item.isRequired) {
          const isExist = tickets.some(
            ticket => ticket.ticketType == item.ticketType,
          );
          if (!isExist) {
            missingTickets.push(item.ticketName);
          }
        }
      });
      setMissingTickets(missingTickets);
    }
  }, [config]);

  useEffect(() => {
    // if (data.order_id !== currentOrderId) {
    setCurrentTab('orderInfo');
    getTickets();
    setCurrentOrderId(data.order_id);
    // }
  }, [data]);

  const jobTypeIcon = [1, 2].includes(data.operation_type)
    ? 'sand'
    : 'waterdrop';
  const user = authService.getUser();
  const actionOptions = [];
  if (
    data.billing &&
    data.billing.approvalsRequired.includes(user.role) &&
    data.billing_status <= 20
  ) {
    if (data.review_status !== 2) {
      actionOptions.push({
        name: 'approve',
        label: 'Approve',
        onClick: () => approveOrderFunc([data.order_id]),
      });
    }
    if (data.review_status !== 4) {
      actionOptions.push({
        name: 'contest',
        label: 'Contest',
        onClick: () => setContestModal(true),
      });
    }
  }
  if (data.status === 4) {
    actionOptions.push({
      name: 'history',
      label: 'History',
      onClick: () => turnHistory(true),
    });
  }
  if (
    data.status === 2 ||
    data.status === 3 ||
    data.status === 4 ||
    data.status === 5 ||
    data.status === 6
  ) {
    actionOptions.push({
      name: 'uploadTicket',
      label: 'Upload Ticket',
      onClick: () => turnUploadTicket(true),
    });
  }

  return (
    (isLoading && <Loader />) || (
      <div className="order-info">
        {showHistory && (
          <HistoryModal
            showHistory={showHistory}
            closeHistory={() => turnHistory(false)}
            outsideOrderId={data.order_id}
          />
        )}
        {showUploadTicket && (
          <UploadTicket
            {...{
              openModal: showUploadTicket,
              turnModal: turnUploadTicket,
              order: data,
            }}
          />
        )}
        <div className="order-info__header">
          <h3 className="order-info__title">
            <Icon icon={jobTypeIcon} colour="white" />
            {data.order_id && (
              <span className="order-info__title__text">
                Order #
                {authorize({
                  abilityPermissions: [
                    { resource: 'Orders', permissions: ['read'] },
                  ],
                }) ? (
                  <Link to="order-details" id={data.order_id} target="_blank">
                    {data.order_id}
                  </Link>
                ) : (
                  data.order_id
                )}
              </span>
            )}
          </h3>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Orders',
                  permissions: ['update'],
                },
              ],
            }}>
            <Button
              theme="small"
              inverse={modifyModalVisible}
              onClick={() => setModifyModalVisible(!modifyModalVisible)}
              testSelector="reconcile_order-details_info_modify_btn">
              Modify
            </Button>
          </Authorize>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Reconcile',
                  permissions: ['update'],
                },
              ],
            }}>
            <DropdownButton
              theme="small"
              text="Actions"
              icon="down"
              options={actionOptions}
              noMargin
              testSelector="reconcile_order-details_info_actions_dropdown-btn"
            />
          </Authorize>
        </div>

        <div className="order-info__tags">
          <h3 className="order-info__heading">Tags</h3>
          <TagsList data={data} equipmentRequired={equipmentRequired} />
        </div>

        <div className="order-info__info">
          <OrderInfoColumns data={data} tickets={tickets} />
        </div>

        <div className="order-info__price">
          <Price data={data} page={page} />
        </div>

        <div className="order-info__documents-timeline">
          <div className="order-info__documents">
            <div className="order-info__documents__header">
              <h3 className="order-info__documents__title">Documents</h3>
              <Button
                onClick={() => downloadAllTicketsFunc(data.order_id)}
                theme="small"
                disabled={downloadAllLoading}
                children={(downloadAllLoading && <Loader />) || 'Download All'}
                testSelector="reconcile_order-details_info_download-all_btn"
              />
            </div>

            <div className="order-info__documents__tickets">
              {tickets
                .filter(item => item.ticketType !== 1 && item.ticketType !== 4)
                .map(
                  ticket =>
                    !ticket.isDeleted && (
                      <OrderInfoTicket
                        key={ticket.id}
                        ticket={ticket}
                        order={data}
                      />
                    ),
                )}
            </div>
            {missingTicketsArray.length > 0 && (
              <>
                <br />
                <h4 style={{ color: 'red' }}>Missing Documents</h4>
                <div className="order-info__documents__tickets">
                  {missingTicketsArray.map(ticket => (
                    <div
                      className="ticket"
                      style={{ color: 'red', borderColor: 'red' }}
                      key={ticket}>
                      <div className="ticket__header">
                        <span className="ticket__header__text">{ticket}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="order-info__timeline">
            <h3 className="order-info__heading">Timeline</h3>
            <Timeline data={data} />
          </div>
        </div>

        <ContestModal
          open={contestModal}
          onClose={() => setContestModal(false)}
          orderIds={[data.order_id]}
        />
        <ModifyModal
          open={modifyModalVisible}
          onClose={() => setModifyModalVisible(false)}
          order={data}
          getOrders={getOrders}
        />
      </div>
    )
  );
};

const mapStateToProps = ({
  orderTickets: { tickets, apiIsLoading, downloadAllTickets, config },
}) => ({
  tickets,
  config,
  isLoading: apiIsLoading,
  downloadAllLoading: downloadAllTickets.apiIsLoading,
});

const mapDispatchToProps = (dispatch, { data }) => ({
  approveOrderFunc: orderId => dispatch(approveOrder(orderId)),
  getTickets: () => dispatch(ticketsRequested(data.order_id)),
  downloadAllTicketsFunc: orderId =>
    dispatch(ticketsDownloadAllTickets(orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderInfo);
