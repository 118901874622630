export const eventPropGetter = (event, schedule) => {
    const style = {
      backgroundColor: '#23293a',
      color: 'white',
      borderLeftWidth: '10px',
      borderRadius: '6px',
      overlapping: 0,
    };
    switch (event.eventType) {
      case 'confirmed':
        style.borderColor = '#5c9925';
        break;
      case 'unconfirmed':
        style.borderColor = '#ffa106';
        break;
      case 'maintenance':
        style.borderColor = '#91949d';
        break;
      case 'external':
        style.borderColor = '#22adcf';
        break;
    }
    if (event.status != 2 && event.status != 5 && event.status) {
      style.backgroundColor = '#adadad';
    }
    if (event.loadindEta > event.appointmentTime) {
      style.color = '#ff0000';
    }
    if (schedule.selectedOrder == event.id) {
      style.backgroundColor = '#feff00';
    }
    return {
      className: '',
      style,
    };
  };