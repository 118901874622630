import React from 'react';
import { Col, Card, Row } from 'react-bootstrap';

const Containers = ({
  details: {
    containers: { total, staged, unloading, empty },
  },
}) => (
  <Card>
    <Card.Header>
      <Card.Title>
        <h5>Containers</h5>
      </Card.Title>
    </Card.Header>
    <Card.Body className="text-center" style={{ paddingTop: '25px' }}>
      <Row>
        <Col lg={3} md={3} sm={3} xs={3} style={{ color: '#1ab394' }}>
          <h1 className="no-margins">{total || 0}</h1>
          <div className="font-bold">Total</div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} style={{ color: 'rgb(240,83,98)' }}>
          <h1 className="no-margins">{staged || 0}</h1>
          <div className="font-bold">Staged</div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} style={{ color: 'rgb(250,173,80)' }}>
          <h1 className="no-margins">{unloading || 0}</h1>
          <div className="font-bold">Unloading</div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} style={{ color: 'rgb(7,198,201)' }}>
          <h1 className="no-margins">{empty || 0}</h1>
          <div className="font-bold">Empty</div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default Containers;
