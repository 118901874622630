import React from 'react';
import { Checkbox } from 'components/shared/Input';
import {
  checkPermissionHasGid,
  checkRoleHasPid,
} from 'components/views/Roles/AddEditRoleCheckboxes/helpers';

const AddEditRoleCheckboxes = ({ permData, grants, editRole }) => {
  const { pid } = permData;
  const getRolePermission = checkRoleHasPid(editRole, pid);

  return (
    <>
      {grants.map(grantData => {
        const { gid, _id, grant } = grantData;
        if (gid === 5) {
          return null;
        }

        const defaultChecked = checkPermissionHasGid(getRolePermission, gid);

        return (
          <div key={gid} className="checkbox-wrapper">
            <Checkbox
              noMargin
              value={_id}
              defaultChecked={defaultChecked}
              label={grant}
            />
          </div>
        );
      })}
    </>
  );
};

export default AddEditRoleCheckboxes;
