export const freeBusyStatus = [
  {
    value: 0,
    label: 'Free',
  },
  {
    value: 1,
    label: 'Busy',
  },
];
