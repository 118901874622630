import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Row } from 'react-bootstrap';

import { authorize } from 'components/common/Authorize';
import Loader from 'components/shared/Loader';

import actions from 'store/actions/JobDetails/Tabs';

const JobDocuments = React.lazy(() => import('./Documents'));
const Events = React.lazy(() => import('./Events'));
const Tanks = React.lazy(() => import('./Tanks'));
const PO = React.lazy(() => import('./PO'));
const StagesTab = React.lazy(() => import('./Stages'));
const OnsiteStorageLive = React.lazy(() => import('./OnsiteStorageLive'));
const Containerized = React.lazy(() => import('./Containerized'));
const DefaultRates = React.lazy(() => import('./DefaultRates'));
const Units = React.lazy(() => import('./Units'));
const Carriers = React.lazy(() => import('./Carriers'));
const Operators = React.lazy(() => import('./Operators'));
const AutoOrder = React.lazy(() => import('./AutoOrder'));
const JobInfo = React.lazy(() => import('./JobInfo'));

const JobDetailsTab = ({
  details,
  clearState,
  initFilters,
}) => {
  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { operationType, equipments, isFullEdit, accessLevel } = details;

  const blockStyle = { paddingTop: '15px', minHeight: '300px' };

  return (
    <div>
      <div className="wrapper-content animated fadeInUp">
        <div className="ibox">
          <div className="ibox-content">
            <Tabs>
              <Tab eventKey="details" title="Details">
                <Row style={blockStyle}>
                  <Suspense fallback={<Loader />}>
                    <JobInfo jobId={details.jobId} />
                  </Suspense>
                </Row>
              </Tab>
              {authorize({
                forbidden: ['JOB_COORDINATOR', 'CARRIER'],
                abilityPermissions: [
                  { resource: 'Carriers', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="carriers" title="Carriers">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <Carriers jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {operationType === 12 && (
                <Tab eventKey="units" title="Units">
                  <Row style={blockStyle}>
                    <Suspense fallback={<Loader />}>
                      <Units details={details} />
                    </Suspense>
                  </Row>
                </Tab>
              )}
              {operationType !== 12 &&
                !!isFullEdit &&
                authorize({
                  forbidden: ['JOB_COORDINATOR'],
                  abilityPermissions: [
                    { resource: 'Purchase-orders', permissions: ['read'] },
                  ],
                }) && (
                  <Tab eventKey="po" title="PO">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <PO jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {authorize({
                forbidden: ['JOB_COORDINATOR'],
                abilityPermissions: [
                  { resource: 'Pricing', permissions: ['read'] },
                ],
              }) &&
                (accessLevel === 'owner' ||
                  accessLevel === 'shared_creator') && (
                  <Tab eventKey="pricing" title="Pricing">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <DefaultRates jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) &&
                operationType !== 12 && (
                  <Tab eventKey="stages" title="Stages">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <StagesTab
                          details={details}
                          initFilters={initFilters}
                        />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {operationType !== 12 &&
                authorize({
                  abilityPermissions: [
                    { resource: 'Scada', permissions: ['read'] },
                  ],
                }) && (
                  <Tab eventKey="onsite-storage" title="Storage">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <OnsiteStorageLive jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {/* {operationType !== 12 &&
                !details.storageEnabled &&
                authorize({
                  abilityPermissions: [
                    { resource: 'Scada', permissions: ['read'] },
                  ],
                }) && (
                  <Tab eventKey="onsite-storage" title="Onsite Storage">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <OnsiteStorage jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )} */}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) &&
                operationType !== 12 &&
                details.storageEnabled && (
                  <Tab eventKey="auto-order" title="Auto Order">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <AutoOrder
                          jobId={details.jobId}
                          initFilters={initFilters}
                        />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {authorize({ forbidden: ['CARRIER'] }) && equipments.includes(3) && (
                <Tab eventKey="containers" title="Containers">
                  <Row style={blockStyle}>
                    <Suspense fallback={<Loader />}>
                      <Containerized
                        jobId={details.jobId}
                      />
                    </Suspense>
                  </Row>
                </Tab>
              )}
              {operationType === 12 && (
                <Tab eventKey="cygnet" title="Cygnet">
                  <Row style={blockStyle}>
                    <Suspense fallback={<Loader />}>
                      <Tanks />
                    </Suspense>
                  </Row>
                </Tab>
              )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="documents" title="Documents">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <JobDocuments details={details} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="events" title="Events">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <Events jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) &&
                !!isFullEdit && (
                  <Tab eventKey="coordinators" title="Coordinators">
                    <Row style={blockStyle}>
                      <Suspense fallback={<Loader />}>
                        <Operators jobId={details.jobId} />
                      </Suspense>
                    </Row>
                  </Tab>
                )}
              {/* {authorize({ forbidden: ['CARRIER'] }) && (
                <Tab eventKey="job-alerts" title="Job Alerts">
                  <Row style={blockStyle}>
                  <Suspense fallback={<Loader />}>
                    <JobAlerts jobId={details.jobId} messages={messages} />
                  </Suspense>
                    </Row>
                </Tab>
              )}
              {authorize({ access: ['JOB_MANAGER', 'CUSTOMER'] }) && (
                <Tab eventKey="alert-emails" title="Alert Emails">
                  <Row style={blockStyle}>
                  <Suspense fallback={<Loader />}>
                    <AlertEmails messages={messages} />
                  </Suspense>
                    </Row>
                </Tab>
              )} */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  clearState: () => dispatch(actions.clearState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetailsTab);
