import React, { useEffect, useContext, useState } from 'react';
import Block from 'components/shared/Block';
import Loader from 'components/shared/Loader';
import moment from 'moment';

import { ReconcileContext } from '../index';
import ReconcileBaseFilter from '../ReconcileBaseFilter';
import ReconcileOrderStatusList from './ReconcileOrderStatusList';

const basePayload = {
  statuses: [4],
  excludeCustomerOperatedOrders: true,
  isInReconcile: true,
  sortBy: 'order_id DESC',
  page: 1,
  itemsPerPage: 10,
  billingStatuses: [20],
  startDate: moment()
    .subtract(3, 'M')
    .startOf('day')
    .utc()
    .format(),
  endDate: moment()
    .endOf('day')
    .utc()
    .format(),
  districtIds: [],
};

const ReconcileOrderStatus = ({
  getOrders,
  getDistricts,
  isLoading,
  districts,
}) => {
  const {
    filters,
    setFilters,
    ordering,
    setOrdering,
    setPagination,
    pagination,
  } = useContext(ReconcileContext);

  const [requestPayload, setRequestPayload] = useState(null);
  const [formattedFilters, setFormattedFilters] = useState(requestPayload);

  const setRequestFilters = requestFilters => {
    setPagination({ page: 1, itemsPerPage: pagination.itemsPerPage });
    return setRequestPayload({
      ...basePayload,
      ...requestFilters,
      ...pagination,
      page: 1,
    });
  };

  const setRequestPagination = paginationData => {
    const match = Object.keys(pagination).every(key => {
      if (!paginationData[key]) {
        return false;
      }

      if (paginationData[key] === pagination[key]) {
        return true;
      }

      return false;
    });

    if (!match) {
      setPagination(paginationData);
      if (requestPayload) {
        return setRequestPayload({ ...requestPayload, ...paginationData });
      }

      return setRequestPayload({ ...basePayload, ...paginationData });
    }
  };

  useEffect(() => {
    setFilters([
      {
        key: 'billing_status',
        label: 'Status',
        type: 'multipleSome',
        value: [20],
      },
      {
        key: 'from-completed_at',
        label: 'Completed From',
        onFilter: null,
        type: 'range',
        value: moment()
          .subtract(3, 'M')
          .startOf('day'),
      },
      {
        key: 'to-completed_at',
        label: 'Completed To',
        onFilter: null,
        type: 'range',
        value: moment().endOf('day'),
      },
    ]);
    setOrdering({ key: 'order_id', isAscending: false });
    setPagination({ page: 1, itemsPerPage: 10 });
  }, []);

  useEffect(() => {
    getOrders(basePayload);
    getDistricts();
  }, []);


  useEffect(() => {
    if (requestPayload) {
      const formattedPayload = { ...requestPayload };
      if (moment(formattedPayload.startDate).isValid()) {
        formattedPayload.startDate = moment(formattedPayload.startDate)
          .utc()
          .format();
      }
      if (moment(formattedPayload.endDate).isValid()) {
        formattedPayload.endDate = moment(formattedPayload.endDate)
          .utc()
          .format();
      }
      setFormattedFilters(formattedPayload)
      getOrders(formattedPayload);
    }
  }, [requestPayload]);

  return (
    <Block theme="none" modifiers={['no-border']}>
      {districts && (
        <ReconcileBaseFilter
          districts={districts}
          view="order_status"
          filters={filters}
          defaultFilters={[
            {
              key: 'billing_status',
              label: 'Status',
              type: 'multipleSome',
              value: [20],
            },
            {
              key: 'from-completed_at',
              label: 'Completed From',
              onFilter: null,
              type: 'range',
              value: moment()
                .subtract(3, 'M')
                .startOf('day'),
            },
            {
              key: 'to-completed_at',
              label: 'Completed To',
              onFilter: null,
              type: 'range',
              value: moment().endOf('day'),
            },
          ]}
          setFilters={setFilters}
          setRequestFilters={setRequestFilters}
        />
      )}
      {(isLoading && <Loader />) || (
        <ReconcileOrderStatusList
            filters={filters}
            ordering={ordering}
            setOrdering={setOrdering}
            pagination={pagination}
            setPagination={setRequestPagination}
            getOrders={() =>
              getOrders(formattedFilters || basePayload)
            }
          />
      )}
    </Block>
  );
};

export default ReconcileOrderStatus;
