import { client } from 'api/client';

/**
 * @param {number} id 
 * @returns {Promise<NotarizationSettings>}
 */
export const get = async (id) => {
  const { data } = await client.get(`v2/customer/${id}/notarization/settings`);
  return data.data.notarizationSettings;
};

/**
 * @param {{
 *  customerId: number;
 *  data: NotarizationSettings;
 *  }}
 */
export const update = async ({ customerId, data }) => {
  const response = await client.put(`v2/customer/${customerId}/notarization/settings`, data);
  return response.data.data;
}

/** @typedef {{
 *  originArrivalThresholdMinutes: number;
 *  originDepartureThresholdMinutes: number;
 *  destinationArrivalThresholdMinutes: number;
 *  destinationDepartureThresholdMinutes: number;
 *  weightDifferencePercentage: number;
 *  priceDifferencePercentage: number;
 *  mileageDifferencePercentage: number;
 *  isNotarizedOrderReadyToBill: boolean
 *  steps: Array<NotarizationStep>
 * }} NotarizationSettings
 */

/** @typedef {{
 * stepType: number;
 * stepName: string;
 * description: string;
 * }} NotarizationStep
*/
