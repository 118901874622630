import demurrage from 'services/demurrage/service';
import swal from 'bootstrap-sweetalert';
import jobPlansActions from 'store/actions/JobPlans';
import { getPriceBreakdown } from './Orders';

export const getDemurrageReasons = () => async dispatch => {
  dispatch({ type: 'DEMURRAGE_REASONS_REQUESTED' });

  const demurrageReasons = await demurrage.get().catch(error => dispatch({ type: 'DEMURRAGE_REASONS_SUCCEEDED' }));

  dispatch({
    type: 'DEMURRAGE_REASONS__FAILED',
  });
  return demurrageReasons;
};

export const editDemurrageReason = (
  orderId,
  type,
  selectedDemurrageReasonId,
  sandSiteDemurrageReasonId,
  wellSiteDemurrageReasonId,
) => async dispatch => {
  dispatch({ type: 'EDIT_DEMURRAGE_REASON_REQUESTED' });

  if (type === 3) {
    await demurrage
      .editDemurrageReason({
        orderId,
        wellsiteDemurrageReasonId: selectedDemurrageReasonId,
        sandsiteDemurrageReasonId: sandSiteDemurrageReasonId,
      })
      .then(() => {
        swal('Demurrage Reason updated successfully!', '', 'success');
        dispatch(jobPlansActions.getBillableOrdersDetailsTickets(orderId));
      })
      .catch(error => {
        swal('Demurrage Reason update failed!', error?.response?.data?.data ?? '', 'error');
        return dispatch({ type: 'EDIT_DEMURRAGE_REASON_FAILED' });
      });
  } else {
    await demurrage
      .editDemurrageReason({
        orderId,
        wellsiteDemurrageReasonId: wellSiteDemurrageReasonId,
        sandsiteDemurrageReasonId: selectedDemurrageReasonId,
      })
      .then(() => {
        swal('Demurrage Reason updated successfully!', '', 'success');
        dispatch(jobPlansActions.getBillableOrdersDetailsTickets(orderId, null));
      })
      .catch(error => {
        swal('Demurrage Reason update failed!', error?.response?.data?.data?.message ?? '', 'error');
        dispatch({ type: 'EDIT_DEMURRAGE_REASON_FAILED' })
      });
  }

  dispatch({ type: 'EDIT_DEMURRAGE_REASON_SUCCEEDED' });
  return dispatch(getPriceBreakdown(orderId));
};

export default {
  getDemurrageReasons,
  editDemurrageReason,
};
