import React, { useState } from 'react';
import { connect } from 'react-redux';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import actions from 'store/actions/DriverDetails';
import authService from 'services/auth/service';
import { Col, Image, Row } from 'react-bootstrap';
import noPhoto from '../../../../public/img/no_avatar.jpg';
import appleGreenImg from '../../../../public/img/apple_green.svg';
import appleRedImg from '../../../../public/img/apple_red.svg';
import androidGreenImg from '../../../../public/img/android_green.svg';
import androidRedImg from '../../../../public/img/android_red.svg';
import apple from '../../../../public/img/apple.svg';
import android from '../../../../public/img/android.svg';

import EditDriver from './EditDriver';

import DriverDetailsMap from './Map';

const token = authService.getToken();

const DriverInf = ({
  driverInf,
  driverDistricts,
  setDriverData,
  orders,
}) => {
  const [showEditDriver, turnEditDriver] = useState(false);

  const formattedDistricts = driverDistricts
    .map(district => district.name)
    .join(', ');

  return (
    <Col lg={4} md={4} sm={4} style={{ paddingLeft: 0 }}>
      <div className="project-manager text-center">
        <Image
          src={
            driverInf.profile_photo
              ? `${driverInf.profile_photo}?token=${token}`
              : noPhoto
          }
          onError={e => {
            e.target.onerror = null;
            e.target.src = noPhoto;
          }}
          rounded
          className="img-responsive driver-profile-photo"
          style={{ maxHeight: 300 }}
        />

        <div>
          <span className="name-driver">
            <strong>
              #{driverInf.id} | {driverInf.name}{' '}
            </strong>
          </span>
          {Number(driverInf.is_logged_in) !== 0 && (
            <span>
              {Number(driverInf.deviceType) === 1 &&
                Number(driverInf.online_status) === 1 && (
                  <Image
                    alt="device"
                    src={appleGreenImg}
                    width="20"
                    height="20"
                    style={{ marginTop: -10 }}
                  />
                )}
              {Number(driverInf.deviceType) === 0 &&
                Number(driverInf.online_status) === 1 && (
                  <Image
                    alt="device"
                    src={androidGreenImg}
                    width="20"
                    height="20"
                    style={{ marginTop: -10 }}
                    data-testid="driver-details-info-device"
                  />
                )}
              {Number(driverInf.deviceType) === 1 &&
                Number(driverInf.online_status) === 0 && (
                  <Image
                    alt="device"
                    src={appleRedImg}
                    width="20"
                    height="20"
                    style={{ marginTop: -10 }}
                  />
                )}
              {Number(driverInf.deviceType) === 0 &&
                Number(driverInf.online_status) === 0 && (
                  <Image
                    alt="device"
                    src={androidRedImg}
                    width="20"
                    height="20"
                    style={{ marginTop: -10 }}
                  />
                )}
            </span>
          )}
          {Number(driverInf.is_logged_in) === 0 && (
            <span>
              {Number(driverInf.deviceType) === 1 && (
                <Image
                  alt="device"
                  src={apple}
                  width="20"
                  height="20"
                  style={{ marginTop: -10 }}
                />
              )}
              {Number(driverInf.deviceType) === 0 && (
                <Image
                  alt="device"
                  src={android}
                  width="20"
                  height="20"
                  style={{ marginTop: -10 }}
                />
              )}
            </span>
          )}
        </div>

        <p>
          <strong>
            <Icon icon="envelope" /> {driverInf.email}
          </strong>
          <br />
          <strong>
            <Icon icon="phone" /> {driverInf.contact_no}
          </strong>
          <br />
          <strong>
            <Icon icon="map-marker" />
            {formattedDistricts.length && formattedDistricts.length < 30
              ? formattedDistricts
              : `${formattedDistricts.slice(0, 28)}...`}
          </strong>
          {/* Soft-removal of home district * <br />
          <strong>
            <Icon icon="home" />
            {driverInf.home_district !== null
              ? driverInf.home_district.replace(/^.+(\s\|\s)/, '')
              : 'Unassigned'}
          </strong>{' '}
          */}
        </p>

        <div className="text-center m-t-md m-b-md">
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Drivers',
                  permissions: ['update'],
                },
              ],
            }}>
            <Button
              theme="small"
              colour="orange"
              testSelector="driver-details_info_edit-driver_btn"
              onClick={() => {
                turnEditDriver(true);
                setDriverData(driverInf);
              }}>
              Edit driver
            </Button>
          </Authorize>
        </div>
        <DriverDetailsMap driver={driverInf} orders={orders} />
      </div>

      {showEditDriver && (
        <EditDriver
          driverDistricts={driverDistricts}
          showEditDriver={showEditDriver}
          closeEditDriver={() => turnEditDriver(false)}
        />
      )}
    </Col>
  );
};

const mapDispatchToProps = dispatch => ({
  setDriverData: data => dispatch(actions.setDriverData(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(DriverInf);
