import React, { useEffect, useMemo, useState } from 'react';
import { Gantt, ViewMode } from 'gantt-task-react';
import moment from 'moment';
import { uniqBy } from 'lodash';

import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';

import { Row } from 'react-bootstrap';
import CustomTaskListHeader from './CustomTaskListHeader';
import CustomTaskListTable from './CustomTaskListTable';
import CalendarFilters from './CalendarFilters';
import {
  mapJobTasks,
  jobTaskFilter,
  carrierTaskFilter,
  selectColor,
} from './helpers';

const JobCalendar = ({ jobs, onClick }) => {
  const [tasks, setTasks] = useState([]);
  const [view, setView] = React.useState(ViewMode.Week);
  const [isChecked, setIsChecked] = useState(true);
  const [filters, setFilters] = useState({
    startDate: moment().startOf('year'),
  });

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    if (!jobs) return;

    const jobTasks = mapJobTasks(jobs);
    setTasks(jobTasks);
  }, [jobs]);

  const handleExpanderClick = task => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  const taskList = useMemo(
    () =>
      tasks
        .filter((task, _index, arr) => jobTaskFilter(task, filters, arr))
        .filter((task, _index, arr) => carrierTaskFilter(task, filters, arr)),
    [tasks, filters],
  );

  const tagList = useMemo(
    () =>
      uniqBy(
        tasks.flatMap(i => {
          if (i.job) {
            return i.job.tags;
          }
          return [];
        }),
        'id',
      ),
    [tasks],
  );

  const renderLegendItem = item => (
    <div key={`legend-${item.id}`}>
      <h4>
        <Icon icon="square" style={{ color: selectColor(item.id) }} />
        {item.name}
      </h4>
    </div>
  );

  return (
    <div>
      <Row>
        <CalendarFilters
          filters={filters}
          applyFilters={setFilters}
          viewSwitcherProps={{
            view,
            onViewModeChange: viewMode => setView(viewMode),
            onViewListChange: value => setIsChecked(value),
            isChecked,
          }}
        />
      </Row>
      <Row>
        <div className="calendar col-md-11">
          {taskList.length ? (
            <Gantt
              tasks={taskList}
              viewMode={view}
              onClick={onClick}
              rowHeight={40}
              headerHeight={50}
              columnWidth={columnWidth}
              listCellWidth={isChecked ? 250 : ''}
              onExpanderClick={handleExpanderClick}
              TaskListHeader={CustomTaskListHeader}
              TaskListTable={CustomTaskListTable}
              TooltipContent={() => null}
            />
          ) : (
            <h3>No Jobs found.</h3>
          )}
        </div>
        <div className="legend col-md-1">
          <h4>
            Job Tags:{' '}
            <ToolTip
              title="Billing tag added to a job."
              className="marginLeft5">
              <Icon
                icon="info-circle"
                className="text-navy"
                style={{ cursor: 'pointer' }}
              />
            </ToolTip>
            <hr />
          </h4>
          {tagList.map(renderLegendItem)}
          <h4>
            Carrier Commitments:{' '}
            <ToolTip
              title="Carriers that have committed trucks to a job."
              className="marginLeft5">
              <Icon
                icon="info-circle"
                className="text-navy"
                style={{ cursor: 'pointer' }}
              />
            </ToolTip>
            <hr />
          </h4>
          <div>
            <h4>
              <Icon icon="square" style={{ color: '#cdcdced9' }} />
              Carrier Commitment
            </h4>
          </div>
          <div>
            <h4>
              <Icon icon="square" style={{ color: '#ffa106d9' }} />
              Inactive Carrier
            </h4>
          </div>
          <div>
            <h4>
              <Icon icon="square" style={{ color: '#f7245bd9' }} />
              On-Hold Carrier
            </h4>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default JobCalendar;
