import React from 'react';
import { Modal } from 'react-bootstrap';
import { orderStatus } from 'components/globalInfo/orderStatus';
import Link from 'components/common/Link';
import Button from 'components/shared/Button';
import Label from 'components/shared/Label';

const PreviewOrders = props => {
  const { turnPreview, location, reroute } = props;
  const { filteredOrders } = location;

  const renderOrders = data => (
    <div key={data.order_id} className="ibox ibox-order">
      <div
        className="ibox-title"
        style={{
          borderColor: '#f8ac59',
          borderWidth: '1px 0 0',
        }}>
        <div style={{ clear: 'both' }}>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                cursor: 'pointer',
              }}>
              <Link
                {...{
                  id: data.order_id,
                  to: 'order-details',
                }}>
                Order #{data.order_id}
              </Link>
              <span
                style={{
                  cursor: 'default',
                  height: '20px',
                  margin: '-5px 0 0 10px',
                }}
                className={`button button--small btn-${
                  orderStatus[data.status].class
                }`}>
                {orderStatus[data.status].name}
              </span>
              {data.errorReroute && (
                <Label className="float-end" type="danger">
                  {data.errorReroute}
                </Label>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ibox-content-order">
        <div className="row">
          <div className="col-md-6 col-xs-6">
            <ul className="list-unstyled">
              <li>
                <strong>Truck Number:</strong>
                {data.truck}
              </li>
              <li>
                <strong>Job: </strong>
                {data.job_name}
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-6">
            <ul className="list-unstyled">
              <li>
                <strong>Driver:</strong>#{data.driver_id} |{data.driver_name}
              </li>
              <li>
                <strong>Driver number:</strong>
                {data.driver_contact_number}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <form className="wizard-big wizard" onSubmit={reroute}>
      <Modal.Body>
        <div className="content" style={{ padding: '10px' }}>
          {filteredOrders.map(renderOrders)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={turnPreview}
          colour="white"
          testSelector="locations_preview-orders_back_btn">
          Back
        </Button>
        <Button
          type="submit"
          testSelector="locations_preview-orders_reroute_btn">
          Reroute orders
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default PreviewOrders;
