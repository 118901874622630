import { connect } from 'react-redux';
import Header from './component';
import actions from './actions';
import eventsActions from '../Events/actions';
import layoutActions from '../../../store/modules/actions/layoutActions';

const mapStateToProps = state => ({
  searchValue: state.Header.searchValue,
});

const mapDispatchToProps = dispatch => ({
  changeEventsBarStatus: () => dispatch(eventsActions.changeEventsBarStatus()),
  toggleNavigation: () => dispatch(layoutActions.toggleNavigationShownOrHidden()),
  clearState: () => dispatch(actions.clearState()),
});

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header);
