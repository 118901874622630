import React, { useEffect, useState } from 'react';
import authService from 'services/auth/service';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import { priceTypes } from 'components/globalInfo/priceTypes';
import { getPriceBreakdown, downloadInvoicePdf } from 'store/actions/Orders';
import { connect } from 'react-redux';
import {
  convertNumberToCurrencyFromAmount,
  getFormattedCustomPrice,
  getFormattedCarrierPrice,
} from 'utils/convertNumberToCurrency';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import {
  filterBreakdown,
} from 'utils/paymentItemCalculations';
import AddPriceModal from './AddPriceModal';
import RemovePriceModal from './RemovePriceModal';
import EditDemurrageReason from './EditDemurrageReason';


const Price = ({
  data,
  getPriceBreakdownFunc,
  isLoading,
  priceBreakdown,
  downloadInvoicePdfFunc,
}) => {
  const [filteredBreakdown, setFilteredBreakdown] = useState({});
  const [addPriceModal, setAddPriceModal] = useState(false);
  const [removePriceModal, setRemovePriceModal] = useState({
    visible: false,
    priceId: null,
  });
  const [demurrageReasonModal, setDemurrageReasonModal] = useState({
    visible: false,
    price: null,
  });
  const user = authService.getUser();
  const isPricePermission = user.entityType === 3
    ? user.entityId === data.carrier_id
    : true;
  useEffect(() => {
    getPriceBreakdownFunc(data.order_id);
  }, [data.order_id]);
  useEffect(() => {
    if (priceBreakdown && priceBreakdown.price) {
      const price = filterBreakdown(priceBreakdown.price);
      const newBreakdown = {
        price,
      }
      setFilteredBreakdown(newBreakdown);
    }
  }, [priceBreakdown])

  const wellsiteDemurrageReasonId = filteredBreakdown.price?.find(price => price.type === 3)?.demurrageReasonId;
  const sandsiteDemurrageReasonId = filteredBreakdown.price?.find(price => price.type === 2)?.demurrageReasonId;

  return (
    (isLoading && <Loader />) ||
    (data && data.status === 4 && (
      <div className="price">
        <h3 className="order-info__heading">Price</h3>
        {(filteredBreakdown.price && filteredBreakdown.price.length > 0 && isPricePermission && (
          <>
            <div className="price__actions">
              {!data.invoice_id && (
                <Authorize
                  {...{
                    forbidden: ['CARRIER'],
                    abilityPermissions: [
                      {
                        resource: 'Pricing',
                        permissions: ['create'],
                      },
                    ],
                  }}>
                  <ToolTip title="Add Price">
                    <Button
                      theme="small--square"
                      testSelector="reconcile_order-details_price_add_btn"
                      onClick={() => setAddPriceModal(!addPriceModal)}>
                      <Icon icon="add" />
                    </Button>
                  </ToolTip>
                </Authorize>
              )}
              {data.invoice_id && (
                <ToolTip title="Download Packet Invoice">
                  <Button
                    theme="small--square"
                    testSelector="reconcile_order-details_price_dowload_btn"
                    onClick={() => downloadInvoicePdfFunc(data.order_id)}>
                    <Icon icon="download" />
                  </Button>
                </ToolTip>
              )}
            </div>
            <div className="price__breakdown-list">
              {filteredBreakdown.price.map(item => (
                <div className="price__breakdown-list__item" key={item.id}>
                  <div className="price__breakdown-list__info">
                    <h4 className="price__heading">
                      <span>{priceTypes[item.type].title}</span>

                      <Authorize {...{ access: ['JOB_MANAGER', 'CUSTOMER'] }}>
                        <span className="white">
                          {getFormattedCustomPrice(item, data)}
                          <span className="blue">
                            {getFormattedCarrierPrice(item, data)}
                          </span>
                        </span>
                      </Authorize>

                      <Authorize
                        {...{ access: ['CARRIER'] }}>
                        <span className="white">
                          {convertNumberToCurrencyFromAmount(
                            item.carrierPrice || item.price,
                          )}
                        </span>
                      </Authorize>
                    </h4>

                    <span className="price__description">
                      Description:
                      <span className="white">
                        {` ${item.comment}`}
                        {[2, 3].includes(item.type) &&
                          item.demurrageReasonName && (
                            <span className="blue">
                              {` - ${item.demurrageReasonName}`}
                            </span>
                          )}
                      </span>
                    </span>
                  </div>

                  <div className="price__breakdown-list__buttons">
                    <Authorize
                      {...{
                        access: ['JOB_MANAGER', 'CUSTOMER'],
                        abilityPermissions: [
                          {
                            resource: 'Pricing',
                            permissions: ['update'],
                          },
                        ],
                      }}>
                      {[2, 3].includes(item.type) && (
                        <ToolTip title="Set Price Reason">
                          <Button
                            theme="small--square"
                            testSelector="reconcile_order-details_price_edit_btn"
                            onClick={() =>
                              setDemurrageReasonModal({
                                visible: !demurrageReasonModal.visible,
                                price: item,
                              })
                            }>
                            <Icon icon="edit" />
                          </Button>
                        </ToolTip>
                      )}

                      {item.type > 6 && (
                        <ToolTip title="Delete Price">
                          <Button
                            theme="small--square"
                            testSelector="reconcile_order-details_price_delete_btn"
                            onClick={() =>
                              setRemovePriceModal({
                                visible: !removePriceModal.visible,
                                priceId: item.id,
                              })
                            }>
                            <Icon icon="trash" />
                          </Button>
                        </ToolTip>
                      )}

                    </Authorize>
                  </div>
                </div>
              ))}
            </div>

            <h4 className="price__subtotal">
              Subtotal
              <span className="white">
                {convertNumberToCurrencyFromAmount(priceBreakdown.total.total)}
                <Authorize {...{ access: ['JOB_MANAGER', 'CUSTOMER'] }}>
                  <span className="blue">
                    (
                    {convertNumberToCurrencyFromAmount(
                      priceBreakdown.total.carrierTotal,
                    )}
                    )
                  </span>
                </Authorize>
              </span>
            </h4>
            <h4 className="price__total">
              Total
              <span className="white">
                {convertNumberToCurrencyFromAmount(priceBreakdown.total.total)}
                <Authorize {...{ access: ['JOB_MANAGER', 'CUSTOMER'] }}>
                  <span className="blue">
                    (
                    {convertNumberToCurrencyFromAmount(
                      priceBreakdown.total.carrierTotal,
                    )}
                    )
                  </span>
                </Authorize>
              </span>
            </h4>
            <AddPriceModal
              open={addPriceModal}
              onClose={() => setAddPriceModal(false)}
              orderId={data.order_id}
            />
            <RemovePriceModal
              open={removePriceModal.visible}
              onClose={() =>
                setRemovePriceModal({ visible: false, priceId: null })
              }
              orderId={data.order_id}
              priceId={removePriceModal.priceId}
            />
            <EditDemurrageReason
              open={demurrageReasonModal.visible}
              onClose={() =>
                setDemurrageReasonModal({ visible: false, price: {} })
              }
              orderId={data.order_id}
              price={demurrageReasonModal.price}
              sandSiteDemurrageReasonId={sandsiteDemurrageReasonId}
              wellSiteDemurrageReasonId={wellsiteDemurrageReasonId}
            />
          </>
        )) || (
            <h4 className="order-info__heading">
              No pricing information available.
            </h4>
          )}
      </div>
    ))
  );
};

const mapDispatchToProps = dispatch => ({
  getPriceBreakdownFunc: orderId => dispatch(getPriceBreakdown(orderId)),
  downloadInvoicePdfFunc: orderId => dispatch(downloadInvoicePdf(orderId)),
});

const mapStateToProps = state => ({
  isLoading: state.orders.priceBreakdown.apiIsLoading,
  priceBreakdown: state.orders.priceBreakdown.data,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Price);
