/** @typedef {typeof ROLES[keyof typeof ROLES]} Roles */

export const ROLES = Object.freeze({
  VENDOR: 1,
  CUSTOMER: 2,
  CARRIER: 3,
  JOB_COORDINATOR: 7,
  JOB_MANAGER: 6,
});

/** @typedef {typeof PERMISSIONS[keyof typeof PERMISSIONS]} Permissions */

export const PERMISSIONS = Object.freeze({
  ADMIN: 1,
  SUPPORT: 4,
});

export const jobOrderStatusLabels = Object.freeze({
  0: 'Ideal',
  1: 'Driver Assigned But Job Not Accepted',
  2: 'Job Accepted',
  3: 'Departing Loading Site',
  4: 'Completed',
  5: 'Entering Loading Site',
  6: 'Entering WellSite',
  7: 'En Route To Stage',
  8: 'Arrived At Stage',
});

export const jobOrderStatus = Object.freeze({
  Ideal: 0,
  DriverAssignedButJobNotAccepted: 1,
  JobAccepted: 2,
  DepartLoadingSite: 3,
  DepartWellSite: 4,
  EnteringLoadingSite: 5,
  EnteringWellSite: 6,
  Completed: 4,
  EnRouteToStage: 7,
  ArrivedAtStage: 8
});

export const orderStatusMap = Object.freeze({
  0: 'En Route to Origin',//shouldnt need
  1: 'En Route to Origin', //shouldnt need
  2: 'En Route to Origin',
  3: 'En Route to Destination',//NOTE: if staging ? En Route To Stage : En Route to Destination
  4: 'Completed',
  5: 'En Route to Origin',
  6: 'En Route to Destination',
  7: 'En Route To Stage',
  8: 'En Route to Destination',
});
