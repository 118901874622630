export const UPDATE_ROLE_ACTIONS = {
  ADD_TO_MODIFIED_ROLES: 'ADD_TO_MODIFIED_ROLES',
  REMOVE_FROM_MODIFIED_ROLES: 'REMOVE_FROM_MODIFIED_ROLES',
  UPDATE_MODIFIED_ROLES: 'UPDATE_MODIFIED_ROLES',
  CLEAR_MODIFIED_ROLES: 'CLEAR_MODIFIED_ROLES',
};

export const updateRolesReducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case UPDATE_ROLE_ACTIONS.ADD_TO_MODIFIED_ROLES: {
      return [...state, data];
    }

    case UPDATE_ROLE_ACTIONS.REMOVE_FROM_MODIFIED_ROLES: {
      return state.filter(fData => fData.rolePermId !== data.rolePermId);
    }

    case UPDATE_ROLE_ACTIONS.UPDATE_MODIFIED_ROLES: {
      const findRole = state.indexOf(
        fData => fData.rolePermId === data.rolePermId,
      );
      return [
        ...state.slice(0, findRole),
        data,
        ...state.slice(findRole, findRole.length - 1),
      ];
    }

    case UPDATE_ROLE_ACTIONS.CLEAR_MODIFIED_ROLES:
      return data;

    default:
      return state;
  }
};

export const getSelectedGrants = (selectedCheckboxes, grants) =>
  selectedCheckboxes.length === 4
    ? [grants.find(fData => fData.gid === 5)._id]
    : selectedCheckboxes.map(target => target.value);
