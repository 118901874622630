import React, { useState, useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/SandSiteSchedule';

const AddEditLane = props => {
  const {
    closeLanesModal,
    lanesModal,
    sandTypes,
    showLanesModal,
    addLane,
    editLane,
    setValue,
  } = props;
  const { sandTypeId, action, truckCapacityPerHour, name, notes } = lanesModal;
  const [saveDisabled, setSaveDisabled] = useState(true);

  const sandTypesList = sandTypes.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      !truckCapacityPerHour ||
      !name?.trim().length ||
      !notes?.trim().length ||
      !sandTypeId
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [lanesModal]);

  return (
    <Modal
      className="modal-container"
      {...{ show: showLanesModal, onHide: closeLanesModal }}>
      <form onSubmit={action === 'add' ? addLane : editLane}>
        <Modal.Header>
          <h3>{action === 'edit' ? 'Edit Lane' : 'Add Lane'}</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                onChange={item => setValue(item.value, 'sandTypeId')}
                value={sandTypesList.find(item => item.value === sandTypeId)}
                options={sandTypesList}
                placeholder="Select commodity"
                required
                testSelector="sandsite-schedule_commodity_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={e => setValue(e, 'truckCapacityPerHour')}
                value={truckCapacityPerHour}
                label="Truck capacity per hour"
                required
                testSelector="sandsite-schedule_lanes-modal_truck-capacity_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setValue(e, 'name')}
                value={name}
                label="Name"
                required
                testSelector="sandsite-schedule_lanes-modal_name_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setValue(e, 'notes')}
                value={notes}
                label="Notes"
                required
                testSelector="sandsite-schedule_lanes-modal_notes_input"
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeLanesModal}
            colour="white"
            testSelector="sandsite-schedule_lanes-modal_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="sandsite-schedule_lanes-modal_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setValue: (value, name) =>
    dispatch(actions.setModalValue(targetValue(value), name)),
  editLane: e => {
    e.preventDefault();
    dispatch(actions.editLane(ownProps.closeLanesModal));
  },
  addLane: e => {
    e.preventDefault();
    dispatch(actions.addLane(ownProps.closeLanesModal));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(AddEditLane);
