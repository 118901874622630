import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import swal from 'bootstrap-sweetalert';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { useEditCustomerLogo } from 'api/v2/customers';
import targetValue from 'utils/targetValue';

const EditLogo = ({ closeEdit, showEdit, details }) => {
  const { mutate: updateLogo, isPending } = useEditCustomerLogo(details?.id);
  const [logo, setLogo] = useState();

  const selectFile = e => {
    setLogo(targetValue(e));
  };

  const saveLogo = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('logo', logo);
    updateLogo(
      formData,
      {
        onSuccess: closeEdit,
        onError: (error) => {
          swal('Error updating customer logo.', error?.response?.data?.message, 'error');
        }
      }
    );
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeEdit}>
        <form
          onSubmit={e => saveLogo(e, closeEdit)}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Logo</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="file"
                  label="Upload logo"
                  onChange={e => selectFile(e)}
                  required
                  accept="image/*"
                  testSelector="customer-details_info_edit-billing-address_logo_file-input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setLogo();
                closeEdit();
              }}
              colour="white"
              testSelector="customer-details_info_edit-bill-logo_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={!logo || isPending}
              testSelector="customer-details_info_edit-bill-logo_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
export default EditLogo;
