import React, { useContext } from 'react';
import Icon from 'components/shared/Icon';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

const CardToggle = ({ eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <a
      className="collapse-link float-end"
      style={{ cursor: 'pointer', fontSize: 16 }}
      onClick={decoratedOnClick}>
      <Icon icon={isCurrentEventKey ? 'chevron-up' : 'chevron-down'} />
    </a>
  );
};
export default CardToggle;
