import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Link from 'components/common/Link';
import { deleteAlert } from 'components/sweetalert';
import locationsType from 'components/globalInfo/locationsType';
import Button from 'components/shared/Button';
import AutomatizeLogo from 'components/shared/AutomatizeLogo';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import Label from 'components/shared/Label';
import ToolTip from 'components/shared/ToolTip';
import Timing from 'components/views/Locations/TimingModal';
import QRTemplate from 'components/views/Locations/QRTemplate';
import AddLocation from 'components/views/Locations/AddLocationModal';
import Loader from 'components/shared/Loader';

import locationsActions from 'store/actions/Locations';
import { useDeleteLocation } from 'api/v2/locations';

const LocationsList = ({
  selectLocation,
  getTiming,
  setLocation,
  clearState,
  initLocation,
  locations,
  isLoading,
}) => {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalTiming, setShowModalTiming] = useState(false);
  const [showModalQRTemplate, setShowModalQRTemplate] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { mutateAsync: deleteLocation } = useDeleteLocation();

  const openModalTiming = id => {
    setShowModalTiming(true);
    getTiming(id);
  };

  const closeModalTiming = () => setShowModalTiming(false);

  const openEdit = location => {
    setLocation(location);
    setShowModalEdit(true);
  };

  const closeEdit = () => setShowModalEdit(false);

  const openModalQRTemplate = location => {
    initLocation(location);
    setShowModalQRTemplate(true);
    setSelectedLocation(location);
  };

  const closeModalQRTemplate = () => {
    setShowModalQRTemplate(false);
    clearState();
  };

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      // console.log('Failed to copy!');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const getAddress = ({ city, state }) => {
    const address = [city, state].filter(item => item?.length).join(', ');
    return address.length ? address : 'N/A';
  }

  const renderLocationItem = data => (
    <div key={data.id} className="vote-item" style={{ padding: '5px 5px' }}>
      <Row>
        <Link
          id={data.id}
          to="location-details"
          isNotActive={data.is_deleted ? true : undefined}>
          <strong
            style={{
              cursor: 'pointer',
              fontSize: 16,
            }}>
            #{data.id} {data.name}
            {data.companyOwnerId === 1 && data.companyOwnerType === 6 && (
              <AutomatizeLogo margin="5" />
            )}
            {data.companyOwnerName && (
              <ToolTip title={data.companyOwnerName} placement="top">
                <Label type="success">SaaS</Label>
              </ToolTip>
            )}
            <span
              className={`label label-${locationsType[data.locationType || 0].class
                }`}
              style={{ margin: '10px' }}>
              {locationsType[data.locationType || 0].label}
            </span>
            {!!data.suspended && (
              <Label style={{ margin: '3px' }} type="info">
                Suspended
              </Label>
            )}
          </strong>
          <br />
        </Link>
      </Row>
      <Row>
        <Col>
          <Icon icon="envelope" /> {data.email?.length ? data.email : 'N/A'}
          <br />
          <Icon icon="phone" /> {data.contact_no?.length ? data.contact_no : 'N/A'}
          <br />
        </Col>
        <Col>
          ({data.latitude}, {data.longitude})
          <Button
            theme="small"
            colour="white"
            testSelector={`locations_list_copy_${data.id}_btn`}
            onClick={() =>
              copyToClipBoard(`${data.latitude}, ${data.longitude}`)
            }>
            Copy
          </Button>
          <br />
        </Col>
      </Row>
      <Row className="footerSandSite">
        <Col>
          <Icon icon="map-marker" /> {getAddress(data)}
          <br />
          Address: {data.address?.length ? data.address : 'N/A'}
          <br />
          Special Instructions: {data.loading_site_special_instruction}
        </Col>
        <Col>
          <Authorize
            {...{
              itemAccessGranted:
                data.accessLevel === 'owner' || data.accessLevel === 'shared',
            }}>
            <Button
              key="timing"
              onClick={() => openModalTiming(data.id)}
              theme="small"
              colour="white"
              testSelector={`locations_list_timing_${data.id}_btn`}>
              Timing
            </Button>
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Locations',
                    permissions: ['delete'],
                  },
                ],
              }}>
              {!data.is_deleted ? (
                <Button
                  key="delete"
                  onClick={() => deleteAlert(deleteLocation, data.id)}
                  theme="small"
                  colour="white"
                  testSelector={`locations_list_delete_${data.id}_btn`}>
                  <Icon icon="trash" />
                </Button>
              ) : (
                <Button
                  colour="red"
                  theme="small"
                  testSelector={`locations_list_deleted-label_${data.id}_btn`}
                // onClick={() => {
                //   confirmAlert(unDeleteSandite, data.id, {//TODO:
                //     title:
                //       'Are you sure you want to UnDelete the location?',
                //     text: ' ',
                //   });
                // }}
                >
                  Deleted
                </Button>
              )}
            </Authorize>
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Locations',
                    permissions: ['update'],
                  },
                ],
              }}>
              <Button
                key="edit"
                onClick={() => openEdit(data)}
                theme="small"
                colour="white"
                testSelector={`locations_list_edit_${data.id}_btn`}>
                <Icon icon="pencil" />
              </Button>
            </Authorize>
          </Authorize>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Locations',
                  permissions: ['update'],
                },
              ],
              itemAccessGranted:
                data.accessLevel === 'owner' || data.accessLevel === 'shared',
            }}>
            <Button
              key="qr_template"
              onClick={() => openModalQRTemplate(data.id)}
              theme="small"
              colour="white"
              testSelector={`locations_list_qrtemplate_${data.id}_btn`}>
              QR Template
            </Button>
          </Authorize>
          <Button
            key="show_on_map"
            onClick={() => selectLocation(data)}
            theme="small"
            colour="white"
            testSelector={`locations_list_show-map_${data.id}_btn`}>
            Show on map
          </Button>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <AddLocation
        openModal={showModalEdit}
        closeModal={closeEdit}
        action="edit"
      />
      <Timing openModal={showModalTiming} closeModal={closeModalTiming} />
      {selectedLocation && (
        <QRTemplate
          open={showModalQRTemplate}
          onClose={closeModalQRTemplate}
          selectedLocation={selectedLocation}
        />
      )}
      {locations.map(renderLocationItem)}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  deleteLocation: id => dispatch(locationsActions.deleteLocation(id)),
  selectLocation: site => dispatch(locationsActions.selectLocation(site)),
  setLocation: location => dispatch(locationsActions.setLocation(location)),
  initLocation: id => dispatch(locationsActions.getQRTemplate(id)),
  getTiming: id => dispatch(locationsActions.getTiming(id)),
  clearState: () => dispatch(locationsActions.clearQRState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsList);
