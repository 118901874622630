import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import RolesListCheckboxes from 'components/views/Roles/RolesListCheckboxes';

const RolesListRow = ({ permData, roles, grants, permissionUpdate }) => {
  const [expandRow, setExpandRow] = useState(false);

  return (
    <tr
      className={`roles-list-row expanded-row--${
        expandRow ? 'visible' : 'hidden'
      }`}>
      <td className="permission-column">
        <table className="nested-table">
          <tbody>
            <tr className="primary-row">
              <td>
                <div
                  data-testid={`roles-list-row-expand-${permData.pid}`}
                  className="roles-list-row__permission-title"
                  onClick={() => setExpandRow(!expandRow)}>
                  <Icon
                    icon="down"
                    className={`roles-list-row__arrow roles-list-row__arrow--${
                      expandRow ? `open` : `hidden`
                    }`}
                  />
                  {permData.permission}
                </div>
              </td>
            </tr>
            {grants.map(grantData => {
              if (grantData.gid === 5) {
                return null;
              }
              return (
                <tr
                  key={`grant${grantData.gid}permId${permData.pid}`}
                  className="secondary-row">
                  <td>
                    <span className="roles-list-row__grant-title">
                      {grantData.grant}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>

      {/* Map roles checkboxes */}
      {roles.map(roleData => {
        // Don't show direct role
        if (roleData.role.role === 'direct') {
          return null;
        }

        return (
          <RolesListCheckboxes
            key={roleData.role.rid}
            roleData={roleData}
            permData={permData}
            grants={permData.grants}
            expandRow={expandRow}
            permissionUpdate={permissionUpdate}
          />
        );
      })}

      {/* Takes up extra space and keeps lines going all the way across */}
      <td className="roles-list-row__spacer">
        <table className="nested-table">
          <tbody>
            <tr className="primary-row">
              <td className="roles-list-row__spacer" />
            </tr>
            {grants.map(grantData => {
              if (grantData.gid === 5) {
                return null;
              }

              return (
                <tr key={grantData.gid} className="secondary-row">
                  <td className="roles-list-row__spacer" />
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default RolesListRow;
