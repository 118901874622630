import React from 'react';
import PropTypes from 'prop-types';

const Overlay = ({ onClick = () => {} }) => (
  <div onClick={onClick} className="overlay" />
);

Overlay.propTypes = {
  onClick: PropTypes.func,
};

export default Overlay;
