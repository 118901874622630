import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getComments, addOrderComment } from 'store/actions/Orders';
import Loader from 'components/shared/Loader';
import moment from 'moment';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import AutomatizeLogo from '../../../../assets/automatize_icon.svg';
import authService from 'services/auth/service';

const Activity = ({
  setCurrentTab,
  comments,
  commentsApiLoading,
  data,
  initComments,
  addComment,
  commentSendLoading,
}) => {
  const [comment, setComment] = useState('');
  const token = authService.getToken();

  const scrollToBottom = () => {
    const block = document.querySelector('.reconcile-activity');
    block.scrollTop = block.scrollHeight;
  };

  useEffect(() => {
    setCurrentTab('activity');
    initComments(data.order_id, scrollToBottom);
  }, [data]);

  comments.map((activity, index) => {
    if (activity.created_at) {
      return (
        (comments[index].created_date = moment(activity.created_at).format(
          'DD MMM YYYY',
        )),
        (comments[index].created_time = moment(activity.created_at).format(
          'HH:mm',
        ))
      );
    }
  });

  const sortedComments = _.groupBy(comments, 'created_date');

  return (
    <>
      <div className="reconcile-activity">
        {commentsApiLoading || commentSendLoading ? (
          <Loader />
        ) : (
          Object.keys(sortedComments).length > 0 && (
            <div className="reconcile-activity__timeline">
              {Object.keys(sortedComments).map((date, index) => (
                <div key={`${index}-${date}`} className="date-block">
                  <div className="date-block__title">{date}</div>
                  <ul className="date-block__reconcile-activity-list">
                    {sortedComments[date].map((activity, index) => (
                      <li
                        key={`${activity.id}-${index}`}
                        className="date-block__reconcile-activity-item">
                        <div className="reconcile-activity-item__dot">
                          <span className="reconcile-activity-item__dot--inner" />
                        </div>
                        <div className="reconcile-activity-item__time">
                          {activity.created_time}
                        </div>
                        <div className="reconcile-activity-item__message">
                          <div
                            className={`reconcile-activity-item__message--image ${(activity.is_system && !activity.profile_url &&
                              'system') ||
                              ''}`}>
                            <img
                              className="image"
                              src={
                                (activity.profile_url && `${activity.profile_url}?token=${token}`) ||
                                AutomatizeLogo
                              }
                              alt={
                                (activity.is_system && 'System logo') ||
                                `${activity.first_name} ${activity.last_name}`
                              }
                            />
                          </div>

                          <div className="reconcile-activity-item__message--details">
                            <span className="intro">
                              {activity.character_type}
                            </span>
                            <div>
                              <small className="content">
                                {activity.comment}
                              </small>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )
        )}
      </div>
      <div className="activity-comment-form">
        <Input
          label="Add comment..."
          value={comment}
          onChange={event => setComment(event.target.value)}
          testSelector='reconcile_order-details_activity_input'
        />
        <Button
          disabled={!comment}
          testSelector="reconcile_order-details_activity_add-comment_btn"
          onClick={() => {
            addComment(data.order_id, comment);
            setComment('');
          }}>
          Add Comment
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = ({ orders }) => ({
  comments: orders.comments,
  commentsApiLoading: orders.commentsApiLoading,
  commentSendLoading: orders.commentSendLoading,
});

const mapDispatchToProps = dispatch => ({
  initComments: (id, callback) => dispatch(getComments(id, callback)),
  addComment: (id, comment) => dispatch(addOrderComment(id, comment)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activity);
