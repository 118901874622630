import React from 'react';
import PropTypes from 'prop-types';

const Label = ({
  type,
  children,
  className,
  onClick,
  style,
  testSelector,
  key,
  title,
  size,
}) => (
  <span
    onClick={onClick}
    style={style}
    data-testid={testSelector}
    className={`label
      ${type && `label--${type}`}
      ${className && `${className}`}
      ${size && `label--${size}`}
    `}
    key={key}
    title={title}>
    {children}
  </span>
);

Label.propTypes = {
  /** Type string */
  type: PropTypes.string,
  /** className string */
  className: PropTypes.string,
  /** onClick */
  onClick: PropTypes.func,
  /** Deprecated: Inline Style */
  style: PropTypes.objectOf(PropTypes.string),
};

export default Label;
