import React from 'react';
import Link from 'components/common/Link';

const DefaultTabContent = ({ district: info, tab }) => {
  const link = tab.substring(0, tab.length - 1);

  return (
    <div className="col-md-12">
      {info[tab].length === 0 ? (
        <div className="row no-data-tab">
          <p>No {tab}</p>
        </div>
      ) : (
        <table id="datatable_queu" className="table table--hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {info[tab].map((data, index) => (
              <tr key={`review${data.order_id}_${index}`}>
                <td style={{ cursor: 'pointer' }}>
                  <Link {...{ id: data.id, to: `${link}-details` }}>
                    {data.id}
                  </Link>
                </td>
                <td>{data.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DefaultTabContent;
