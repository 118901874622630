import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import TableList from 'components/shared/TableList';
import { columnsAndroid, columnsIOS } from 'components/globalInfo/devicesInfo';
import { androidItems, IOSItems, devicesPercents } from './helpers';

const DeviceStatistics = ({ drivers }) => {
  const [visible, setVisible] = useState(false);
  const { androidPercent, iosPercent } = devicesPercents(drivers);

  return (
    <div className="device-stats__body">
      <div className="device-stats__body__header">
        <h3 className="text-white">Devices stats</h3>
        <span
          data-testid="device-stats-toggle-btn"
          className={`device-stats__body__header__text float-end ${visible &&
            'down'}`}
          onClick={() => setVisible(!visible)}>
          <Icon icon="down" />
        </span>
      </div>
      {visible && (
        <div className="device-stats__body__content">
          <div className="row device-stats__body__content__progress-content">
            <div className="device-stats__body__content__progress-labels">
              <span>
                <div
                  className="device-stats__body__content__progress-labels--android"
                  data-testid="device-stats-android"
                />
                Android
              </span>
              <span>
                <div
                  className="device-stats__body__content__progress-labels--ios"
                  data-testid="device-stats-ios"
                />
                iOS
              </span>
            </div>
            <div className="progress">
              <div
                className="progress-bar android-color"
                role="progressbar"
                style={{ width: `${androidPercent}%` }}
                aria-valuenow={androidPercent}
                aria-valuemin="0"
                aria-valuemax="100"
              />
              <div
                className="progress-bar ios-color"
                role="progressbar"
                style={{ width: `${iosPercent}%` }}
                aria-valuenow={iosPercent}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
          <div className="row" style={{ maxHeight: 230, overflowY: 'scroll' }}>
            <div className="col-md-12 col-lg-6">
              <TableList
                columns={columnsAndroid}
                listItems={androidItems(drivers)}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <TableList columns={columnsIOS} listItems={IOSItems(drivers)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DeviceStatistics);
