import React from 'react';
import Badge from 'components/shared/Badge';
import Link from 'components/common/Link';
import { orderStatus } from 'components/globalInfo/orderStatus';

const OrdersTabContent = ({ district: info, tab }) => (
  <div className="col-md-12">
    {info[tab].length === 0 ? (
      <div className="row no-data-tab">
        <p>No active Orders</p>
      </div>
    ) : (
      <table id="datatable_queu" className="table table--hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Driver ID</th>
            <th>Job ID</th>
          </tr>
        </thead>
        <tbody>
          {info[tab].map((data, index) => (
            <tr key={`review${data.order_id}_${index}`}>
              <td style={{ cursor: 'pointer' }}>
                <Link {...{ id: data.order_id, to: 'order-details' }}>
                  {data.order_id}
                </Link>
              </td>
              <td>
                <Badge
                  type="success"
                  children={
                    orderStatus[data.status]
                      ? orderStatus[data.status].name
                      : ''
                  }
                />
              </td>
              <td>{data.driver_id}</td>
              <td>
                <Link {...{ id: data.job_id, to: 'job-details' }}>
                  {data.job_id}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default OrdersTabContent;
