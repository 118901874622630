// importing dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// importing actions
import { lookUpActions } from 'store/actions/LookUp';
import Loader from 'components/shared/Loader';

const LookUp = ({ init }) => {
  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div data-testid="home-page-loading" className="page__loader">
      <Loader size={100} />
    </div>
  );
};

const mapStateToProps = state => ({
  count: state.count,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(lookUpActions.init()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LookUp);
