import React from 'react';

const OrderColumn = ({ header, title, value }) => (
  <div>
    <h4>{header}:</h4>
    <br />
    <ul className="unstyled">
      {title.map((item, i) => (
        <li key={item}>
          <strong>{item}: </strong>
          <span>{value[i]}</span>
        </li>
      ))}
    </ul>
  </div>
);
export default OrderColumn;
