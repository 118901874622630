import { useReducer, useEffect } from 'react';

import targetValue from 'utils/targetValue';

const initialState = {
  input: {
    companyInfo: {
      name: '',
      email: '',
      districts: [],
      companyType: '',
      qbId: '',
      driverTimeout: '5',
      bcSync: false,
    },
    billingAddress: {
      city: '',
      address1: '',
      phoneNumber: '',
      email: '',
      state: '',
      address2: '',
      zip: '',
    },
    configuration: {
      // Temporary commented, it may to bring back later
      // sandco: 1,
      fuelSurchargeEnabled: false,
      useQRCode: false,
      // sandcoPrice: 0,
    },
    billingTerms: {
      dueDays: '',
      billingFrequency: '',
      bulkInvoiceEnabled: false,
      packetInvoiceEnabled: false,
      csvInvoiceEnabled: false,
      nonBillable: false,
      billTo: '',
      approvalsRequired: [],
      bulkInvoiceTemplateId: 1,
      billableOperationTypeStatus: [],
    }
  },
  step: 1,
};

const AddCustomerFormReducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return action.data
    }
    case 'set_value': {
      return {
        ...state,
        [action.name]: action.value
      }
    }
    case 'set_company_value': {
      return {
        ...state,
        input: {
          ...state.input,
          companyInfo: {
            ...state.input.companyInfo,
            [action.name]: action.value
          }
        }
      };
    }
    case 'set_config_value': {
      return {
        ...state,
        input: {
          ...state.input,
          configuration: {
            ...state.input.configuration,
            [action.name]: action.value
          }
        }
      };
    }
    case 'set_bill_value': {
      return {
        ...state,
        input: {
          ...state.input,
          billingAddress: {
            ...state.input.billingAddress,
            [action.name]: action.value
          }
        }
      };
    }
    case 'set_billing_terms': {
      return {
        ...state,
        input: {
          ...state.input,
          billingTerms: {
            ...state.input.companyInfo,
            [action.name]: action.value
          }
        }
      }
    }
    case 'clear': {
      return {};
    }
    default: {
      return state;
    }
  }
};

export const useAddCustomerForm = () => {
  const [formState, dispatch] = useReducer(AddCustomerFormReducer, initialState);

  const handlers = {
    setStep: (step) => dispatch({ type: 'set_value', name: 'step', value: step }),
    setBillValue: (e, name) => dispatch({ type: 'set_bill_value', name, value: targetValue(e) }),
    setConfigValue: (e, name) => dispatch({ type: 'set_config_value', name, value: targetValue(e) }),
    setCompanyValue: (e, name) => dispatch({ type: 'set_company_value', name, value: targetValue(e) }),
    setBillingTermsValue: (e, name) => dispatch({ type: 'set_billing_terms', name, value: targetValue(e) }),
    clear: () => dispatch({ type: 'clear' }),
  };

  return {
    handlers,
    formState
  }
};

