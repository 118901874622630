import { client } from 'api/client';

/**
 *
 * @param {number} carrierId
 * @returns
 */
export const getCarrierGeotab = async carrierId => {
  const { data } = await client.get(
    `v2/external/geotab/settings/carrier/${carrierId}`,
  );
  return data.data.settings;
};

/**
 *
 * @param {{
 *  carrierId: number;
 *  geotab: { active: boolean; defaultDistrictId: number }
 * }} params
 * @returns
 */
export const updateCarrierGeotab = async ({ carrierId, geotab }) => {
  const { data } = await client.post(
    `v2/external/geotab/settings/carrier/${carrierId}`,
    geotab,
  );
  return data;
};
