import React, { useState } from 'react';
import Modal from 'components/shared/Modal';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { connect } from 'react-redux';
import { emailSendInvoice } from 'store/actions/Invoices';

const InvoiceEmailModal = ({
  carrierId,
  open,
  onClose,
  customerInvoice,
  emailSendInvoiceFunc,
}) => {
  const [fields, setFields] = useState([{ value: null }]);

  const handleChange = (i, event) => {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  };

  const handleAdd = () => {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  };

  const handleRemove = i => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const emails = fields.map(({ value }) => value);

  const handleCloseAndSendEmail = () => {
    if (!carrierId) {
      onClose();
      emailSendInvoiceFunc(customerInvoice.id, emails, undefined);
    } else {
      onClose();
      emailSendInvoiceFunc(customerInvoice.id, emails, carrierId);
    }
  };

  const body = (
    <div className="modal-form">
      {fields.map((field, index) => {
        return (
          <div key={index} className="modal-form__input">
            <Input
              value={field.value || ''}
              label="Email"
              onChange={e => handleChange(index, e)}
              required
              type="email"
              testSelector="invoice_details_email-modal_email_input"
            />
            {index > 0 && (
              <Icon icon="trash" onClick={() => handleRemove(index)} />
            )}
          </div>
        );
      })}
      <Button
        colour="green"
        onClick={() => handleAdd()}
        testSelector="invoice_details_email_add_btn">
        Add Email
      </Button>
    </div>
  );

  const footer = (
    <>
      <Button
        onClick={() => handleCloseAndSendEmail()}
        testSelector="invoice_details_email_send_btn">
        Send
      </Button>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="invoice_details_email_close_btn">
        Close
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Send Invoice to Email"
      body={body}
      footer={footer}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  emailSendInvoiceFunc: (id, emails, carrierId) =>
    dispatch(emailSendInvoice(emails, id, carrierId)),
});

export default connect(
  null,
  mapDispatchToProps,
)(InvoiceEmailModal);
