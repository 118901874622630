export default {
  '0': {
    label: 'Unknown',
    class: 'default',
  },
  '1': {
    label: 'Wellsite',
    class: 'success',
  },
  '2': {
    label: 'Sand site',
    class: 'info',
  },
  '3': {
    label: 'Water disposal unit',
    class: 'primary',
  },
  '4': {
    label: 'Staging site',
    class: 'warning',
  },
  '5': {
    label: 'Acid Facility',
    class: '-darkGreen'
  }
};
