import React from 'react';
import Link from 'components/common/Link';
import { authorize } from 'components/common/Authorize';

const LinkPermission = ({ id, name, link, resource }) => (
  <span>
    {!authorize({
      abilityPermissions: [
        {
          resource,
          permissions: ['read'],
        },
      ],
    }) ? (
      <div>{`#${id || 'unknown'} | ${name || 'unknown'}`}</div>
    ) : (
      <Link id={id} to={link}>
        {`#${id || 'unknown'} | ${name || 'unknown'}`}
      </Link>
    )}
  </span>
);
export default LinkPermission;
