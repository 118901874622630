import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import ImageViewer from 'components/shared/ImageViewer';
import Icon from 'components/shared/Icon';
import Loader from 'components/shared/Loader';

import targetValue from 'utils/targetValue';
import { authorize } from 'components/common/Authorize';
import { useUnitsOfMeasure } from 'api/v2/units-of-measure';
import { useTags } from 'api/v2/tags';
import { useEquipment } from 'api/v2/equipment';
import {
  useCommoditiesTypes,
  useAddCommodity,
  addDocumentCommodity,
  useEditCommodity,
} from 'api/v2/commodities';

const initialData = {
  commodityType: '',
  documents: [],
  driverDisplay: '',
  equipmentTypes: [],
  name: '',
  tags: [],
  uom: '',
};

const AddEditModal = ({ showUpdate, closeUpdate, status, item }) => {
  const [formData, setFormData] = useState(initialData);
  const {
    mutate: createCommodity,
    isPending: isAddPending,
  } = useAddCommodity();
  const { mutate: editCommodity, isPending: isEditPending } = useEditCommodity(
    item?.id,
  );

  const handleChange = (e, field) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: targetValue(e),
    }));
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];
    if (file) {
      try {
        const updatedDocuments = await addDocumentCommodity(
          file,
          formData.documents,
        );
        setFormData(prevFormData => ({
          ...prevFormData,
          documents: updatedDocuments,
        }));
      } catch (error) {
        console.error('Error adding document:', error);
      }
    }
  };

  const handleDocumentDelete = id => {
    const updatedDocs = formData.documents.filter(d => d.docId !== id);
    setFormData(prevFormData => ({
      ...prevFormData,
      documents: updatedDocs,
    }));
  };

  const handleClose = () => {
    setFormData(initialData);
    closeUpdate();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (status === 'edit') {
      editCommodity(formData, { onSuccess: handleClose });
    } else {
      createCommodity(formData, { onSuccess: handleClose });
    }
  };

  useEffect(() => {
    if (showUpdate) {
      if (item && status === 'edit') {
        setFormData({
          commodityType: item?.commodityType || '',
          documents: item?.documents || [],
          driverDisplay: item?.driverDisplay || '',
          equipmentTypes: (item?.equipment || []).map(e => e.id) || [],
          name: item?.name || '',
          tags: (item?.tags || []).map(t => t.id) || [],
          uom: item?.unitOfMeasureId || '',
        });
      } else if (status === 'add') {
        setFormData(initialData);
      }
    }
  }, [showUpdate, item, status]);

  const [showImageViewer, setShowImageViewer] = useState(false);
  const [docNumber, setDocNumber] = useState(undefined);
  const [docName, setDocName] = useState('');
  const { data: tagData, isLoading: tagIsLoading } = useTags({ type: 1 });
  const { data: equipment, isLoading: equipmentIsLoading } = useEquipment();
  const { data: unitsOfMeasure, isLoading: uomIsLoading } = useUnitsOfMeasure();
  const { data: commodityTypes } = useCommoditiesTypes();

  const equipmentTypesOptions = equipment.map(e => ({
    value: e.id,
    label: e.title,
  }));

  const commodityTypesList = commodityTypes.map(c => ({
    value: c.id,
    label: c.name,
  }));

  const tagOptions = tagData.map(c => ({
    value: c.id,
    label: c.name,
  }));

  const saveDisabled = useMemo(
    () =>
      !formData.name.trim().length ||
      !formData.commodityType ||
      !formData.equipmentTypes.length ||
      (authorize({ access: ['JOB_MANAGER'] }) && !formData.uom),
    [formData],
  );

  return (
    <div className="inmodal">
      {showImageViewer && (
        <ImageViewer
          visibleToggle={setShowImageViewer}
          docId={docNumber}
          docName={docName}
          visible={showImageViewer}
        />
      )}
      <Modal className="modal-container" show={showUpdate} onHide={handleClose}>
        <form className="m-t" role="form" onSubmit={handleSubmit}>
          <Modal.Header>
            <h3>{status === 'add' ? 'Add Commodity' : 'Edit Commodity'}</h3>
          </Modal.Header>

          {uomIsLoading || equipmentIsLoading || tagIsLoading ? (
            <Loader />
          ) : (
            <Modal.Body className="form-group">
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={e => handleChange(e, 'name')}
                    value={formData.name}
                    label="Name"
                    required
                    testSelector="manage-database_commodities_add-edit_name_input"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    onChange={e => handleChange(e.value, 'commodityType')}
                    value={commodityTypesList.find(
                      commodity => commodity.value === formData?.commodityType,
                    )}
                    options={commodityTypesList}
                    placeholder="Select Commodity Type"
                    required
                    testSelector="database_commodities_add-edit_type_select"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    placeholder="Please Select Equipment Types"
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={values => {
                      handleChange(values.map(i => i.value), 'equipmentTypes');
                    }}
                    value={equipmentTypesOptions.filter(option =>
                      formData?.equipmentTypes.includes(option.value),
                    )}
                    options={equipmentTypesOptions}
                    testSelector="database_commodities_add-edit_equipment-types_select"
                    required
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    placeholder="Select Tags"
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={values => {
                      handleChange(values.map(i => i.value), 'tags');
                    }}
                    options={tagOptions}
                    value={tagOptions.filter(option =>
                      formData?.tags.includes(option.value),
                    )}
                    testSelector="database_commodities_add-edit_tags_select"
                  />
                </div>
                <div className="form-group has-feedback col-md-12">
                  {formData?.documents &&
                    formData?.documents.length > 0 &&
                    formData?.documents.map((doc, index) => (
                      <div key={`documents-${doc.docName}-${index}`}>
                        <Button
                          onClick={() => {
                            setShowImageViewer(x => !x);
                            setDocNumber(Number(doc.docId));
                            setDocName(doc.docName);
                          }}
                          theme="small"
                          colour="green"
                          testSelector="database_commodity_documents_preview_btn">
                          {`${doc.docName}`}
                        </Button>
                        <Button
                          onClick={() => {
                            handleDocumentDelete(doc.docId);
                          }}
                          testSelector="database_commodity_documents_delete_btn"
                          theme="small">
                          <Icon icon="trash" />
                        </Button>
                      </div>
                    ))}
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={e => handleChange(e, 'driverDisplay')}
                    value={formData.driverDisplay}
                    label="Driver Display Name"
                    testSelector="manage-database_commodities_add-edit_name_input"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    onChange={value => {
                      handleChange(value.id, 'uom');
                    }}
                    value={unitsOfMeasure
                      .map(e => ({ id: e.id, label: e.name }))
                      .find(m => m.id === formData?.uom)}
                    options={unitsOfMeasure.map(e => ({
                      id: e.id,
                      label: e.name,
                    }))}
                    placeholder="Select (Default) Unit of Measure"
                    isDisabled={!authorize({ access: ['JOB_MANAGER'] })}
                    required={authorize({ access: ['JOB_MANAGER'] })}
                    testSelector="database_commodities_add-edit_type_select_uom"
                  />
                </div>
                <div className="form-group has-feedback col-md-12">
                  <Input
                    type="file"
                    accept="image/*,.pdf"
                    name="image1"
                    label="Upload Document"
                    onChange={e => {
                      handleFileChange(e);
                    }}
                    testSelector="commodity_add_file-input"
                  />
                </div>
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="database_commodities_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled || isAddPending || isEditPending}
              testSelector="database_commodities_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
export default AddEditModal;
