import { jobs } from 'services/jobs/service';

export const jobsRequested = params => async dispatch => {
  dispatch({ type: 'JOBS_REQUESTED' });

  const jobResults = await jobs.get(params).catch(error => {
    // TODO: Add Generic Failed Handler
    return dispatch({ type: 'JOBS_REQUEST_FAILED' });
  });

  return dispatch({
    jobs: jobResults.data.data.jobs,
    type: 'JOBS_REQUEST_SUCCEEDED',
  });
};

export const stagesRequested = jobId => async dispatch => {
  dispatch({ type: 'STAGES_REQUESTED' });

  const stageResults = await jobs.stage.get(jobId).catch(error => {
    return dispatch({ type: 'STAGE_REQUEST_FAILED' });
  });

  return dispatch({
    stages: stageResults.data.data.stages,
    type: 'STAGE_REQUEST_SUCCEEDED',
  });
};

export default {
  jobsRequested,
  stagesRequested,
};
