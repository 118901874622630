import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';

import AuditSearch from './AuditSearch';

const Settings = () => (
  <div>
    <Header title="Audit Log" smallTitle="Audit Log" />
    <Authorize
      abilityPermissions={[{ resource: 'Admin', permissions: ['read'] }]}>
      <div className="tabs-container">
        <Tabs>
          <Tab eventKey="audits" title="Search Audits">
            <AuditSearch audits={[]} />
          </Tab>
        </Tabs>
      </div>
    </Authorize>
  </div>
);

export default Settings;
