import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDistrictUnits } from 'api/v2/districts';

import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';

import EditUnit from './EditUnitModal';

const UnitTabContent = ({ district: info, triggerAddModal }) => {
  const { districtId } = useParams();

  const { data, isLoading } = useDistrictUnits(districtId);
  const loaded = !isLoading && data;

  const [showEditModal, setEditModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(false);
  const triggerEditModal = () => setEditModal(!showEditModal);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <div className="col-md-12">
      {data.length === 0 ? (
        <div className="row no-data-tab">
          <p>No units</p>
        </div>
      ) : (
        <table id="datatable_queu" className="table table--hover">
          {showEditModal && (
            <EditUnit
              {...{
                unit: selectedUnit,
                districtId,
                showEditModal,
                closeEditModal: triggerEditModal,
              }}
            />
          )}
          <thead>
            <tr>
              <th>Unit</th>
              <th>Customer</th>
              <th>Price</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((el, index) => (
              <tr key={`review${el.sand_site_id}_${el.customer_id}_${index}`}>
                <td>
                  {el.sand_site_id} | {el.sand_site}
                </td>
                <td>
                  {el.customer_id} | {el.customer_name}
                </td>
                <td>{el.dollarsPerTon}</td>
                <td>{el.priority}</td>
                <td> </td>
                <Authorize
                  {...{
                    abilityPermissions: [
                      {
                        resource: 'Locations',
                        permissions: ['update'],
                      },
                    ],
                  }}>
                  <td style={{ cursor: 'pointer' }}>
                    <Icon
                      icon="pencil"
                      onClick={() => {
                        setSelectedUnit(el);
                        triggerEditModal();
                      }}
                    />
                  </td>
                </Authorize>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="form-group m-md">
        <Authorize
          {...{
            forbidden: ['CUSTOMER'],
            abilityPermissions: [
              {
                resource: 'Locations',
                permissions: ['create'],
              },
            ],
          }}>
          {info.jobs && info.jobs.length ? (
            <Button
              onClick={triggerAddModal}
              testSelector="district-details_content_add-unit-jobs_btn">
              Add New Unit
            </Button>
          ) : (
            <Button
              onClick={() =>
                swal({
                  title:
                    'In order to add a Disposal unit you must first have a job with this district.',
                })
              }
              testSelector="district-details_content_add-unit_btn">
              Add New Unit
            </Button>
          )}
        </Authorize>
      </div>
    </div>
  );
};

export default UnitTabContent;
