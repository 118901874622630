import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/DriverDetails';
import Button from 'components/shared/Button';
import Label from 'components/shared/Label';
import Select from 'components/shared/Select';
import multiSelectOptions from 'utils/multiSelectOptions';

const ChangeDistrict = ({
  init,
  closeModalAddDistrict,
  showModalAddDistrict,
  setValue,
  submitSave,
  districts,
}) => {
  useEffect(() => {
    init();
  }, []);

  const { carrierDistricts, selectedDistricts } = districts;
  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showModalAddDistrict}
        onHide={closeModalAddDistrict}>
        <Modal.Header closeButton>
          <h3>Change districts</h3>
        </Modal.Header>
        <form onSubmit={e => submitSave(e, closeModalAddDistrict)}>
          <Modal.Body>
            {carrierDistricts && carrierDistricts.length ? (
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={setValue}
                options={multiSelectOptions(carrierDistricts, 'id', 'name')}
                placeholder="Select district"
                value={selectedDistricts}
                testSelector="driver-details_change-district_districts_select"
              />
            ) : (
              <Label type="warning">Carrier has no districts</Label>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeModalAddDistrict}
              colour="white"
              children="Close"
              testSelector="driver-details_change-district_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              testSelector="driver-details_change-district_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  districts: state.driverProfile.districts,
});

const mapDispatchToProps = dispatch => ({
  setValue: value => dispatch(actions.setValue(value)),
  init: () => dispatch(actions.initChangeDistricts()),
  submitSave: (e, closeModal) => {
    e.preventDefault();
    dispatch(actions.submitSave(closeModal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeDistrict);
