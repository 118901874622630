import { useMemo } from 'react';
import { useCarrierTruckAnalytics } from 'api/v2/carriers';

export const useAnalyticsList = carrierId => {
  const { data: analytics } = useCarrierTruckAnalytics(carrierId);

  const viewArray = useMemo(
    () => [
      {
        data: analytics?.busyTrucks,
        header: 'Busy driver',
      },
      {
        data: analytics?.freeTrucks,
        header: 'Free driver',
      },
      {
        data: analytics?.freeTruckActiveWithin12Hours,
        header: 'Free driver active within 12 hours',
      },
    ],
    [analytics],
  );
  return viewArray;
};
