import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';

const NumberInput = ({
  className,
  disabled = false,
  label,
  noMargin = false,
  onChange,
  required = false,
  testSelector,
  value,
  min,
  max,
  step = 'any',
  noPadding,
}) => (
  <InputWrapper
    inputType="number"
    id={testSelector}
    className={className}
    noMargin={noMargin}
    disabled={disabled}>
    <input
      type="number"
      className={`input__text__input ${noPadding ? 'padding0' : ''}`}
      disabled={disabled}
      name={testSelector}
      id={testSelector}
      placeholder=" "
      value={value ?? ''}
      onChange={onChange}
      data-testid={testSelector}
      min={min}
      max={max}
      step={String(step)}
    />
    <span className="input__text__label">
      {label}
      {required && <span className="input__text__label--required"> *</span>}
    </span>
  </InputWrapper>
);

NumberInput.propTypes = {
  /** Custom Class Name */
  className: PropTypes.string,
  /** Disabled boolean */
  disabled: PropTypes.bool,
  /** Label for input */
  label: PropTypes.string,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Function to call when changing the input */
  onChange: PropTypes.func.isRequired,
  /** Boolean to make input required and add * to label */
  required: PropTypes.bool,
  /** HTML data attribute for test selecting element  */
  testSelector: PropTypes.string,
  /** Value of input */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Value of step */
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NumberInput;
