import { client } from 'api/client';

/**
 * @typedef {{
 *   wellsiteId: number;
 *   type: number;
 *   sandsiteId: number;
 *   jobId: number;
 * }} CustomerContainerParams
 */

/**
 *
 * @param {{
 *  customerId: number;
 *  params: CustomerContainerParams
 * }} params
 * @returns
 */
export const getCustomerContainers = async ({ customerId, params }) => {
  const { data } = await client.get(`v2/customer/${customerId}/containers`, {
    params,
  });
  return data.data.equipments;
};

/**
 * @typedef {{
 *   id: number;
 *   jobId: number;
 *   sandTypeId: number;
 *   sandVendorId: number;
 *   status: number;
 *   weight: number;
 * }} UpdateContainerData
 */

/**
 *
 * @param {{
 *  customerId: number;
 *  containerId: number;
 *  container: UpdateContainerData;
 * }} params
 */
export const updateCustomerContainerStatus = async ({
  customerId,
  container,
}) => {
  const { data } = await client.put(
    `v2/customer/${customerId}/containers/${container.id}/status`,
    container,
  );
  return data.data;
};
