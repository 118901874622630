import axios from 'axios';

const auditService = {
    get(query) {
        return axios.get('v2/audits', { params: query });
    },
    
    getNumPages(query) {
        return axios.get('v2/audits/num-pages', { params: query });
    }
};

export default auditService;
