import { combineReducers } from '@reduxjs/toolkit';

const initInfo = {
  tabKey: 1,
};

const tab = (state = initInfo, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_CHANGE_TAB_KEY':
      return {
        ...state,
        tabKey: action.value,
      };
    case 'JOB_DETAILS_TAB_CLEAR_STATE':
      return initInfo;
    default:
      return state;
  }
};

export const tabs = combineReducers({
  tab,
});
