import React from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/ManageCustomers';
import GoogleMap, {
  AutomatizeMarker,
  polyCoords,
  milesToMeters,
} from 'components/shared/GoogleMap';
import { Polygon } from 'google-maps-react';
import Icon from 'components/shared/Icon';
import { center } from 'components/globalInfo/mapConstants';

const MapAddWellsite = ({ selectCoordinates, location }) => {
  const { zoom, longitude, latitude } = location;
  let newLatitude = latitude || 0;
  let newLongitude = longitude || 0;
  if (latitude > 90) newLatitude = 90;
  if (latitude < -90) newLatitude = -90;
  if (longitude > 180) newLongitude = 180;
  if (longitude < -180) newLongitude = -180;

  const initialCenter = {
    lng: newLongitude || center[0],
    lat: newLatitude || center[1],
  };

  const onMapClicked = (mapProps, map, clickEvent) => {
    selectCoordinates(clickEvent.latLng.lng(), clickEvent.latLng.lat());
  };

  return (
    <div className="locations-map" data-testid="custom-map">
      <GoogleMap
        fitMarkers
        initialCenter={initialCenter}
        onClick={onMapClicked}
        zoom={zoom}
        center={initialCenter}>
        {!isNaN(Number.parseFloat(latitude)) &&
          !isNaN(Number.parseFloat(longitude)) &&
          !isNaN(Number.parseFloat(location.geofenceRange)) && (
            <Polygon
              paths={polyCoords(
                milesToMeters(location.geofenceRange),
                30,
                Number.parseFloat(latitude),
                Number.parseFloat(longitude),
              )}
              strokeColor="rgb(153,217,233)"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="rgb(153,217,233)"
              fillOpacity={0}
            />
          )}
        {!isNaN(Number.parseFloat(latitude)) &&
          !isNaN(Number.parseFloat(longitude)) && (
            <AutomatizeMarker
              position={{
                lng: Number.parseFloat(longitude),
                lat: Number.parseFloat(latitude),
              }}
              type="well_site"
              infoWindow={{
                title: (
                  <>
                    <Icon icon="wellsite" colour="white" />
                    <span className="info-window__title--text">
                      #{location.id} {location.name}
                    </span>
                  </>
                ),
                width: 380,
              }}
            />
          )}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = state => ({
  location: state.manageCustomers.modalWellSite,
});

const mapDispatchToProps = dispatch => ({
  selectCoordinates: (lng, lat) =>
    dispatch(actions.selectCoordinates(lng, lat)),
});

export const MapAddWellsiteTest = MapAddWellsite; // for Tests

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapAddWellsite);
