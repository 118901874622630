import React, { useContext, useEffect } from 'react';
import { Map } from 'google-maps-react';

import { GoogleMapContext } from './GoogleMapContext';
import MapControls from './MapControls';

import MapStyles from './mapStyles.json';

const GoogleMap = ({
  google,
  onReady,
  onMapUpdate,
  children,
  initialCenter,
  setInitialCenter,
  fitMarkers,
  zoom,
  createRoute,
  pointData,
  onClick,
  center,
}) => {
  const { setMap, map } = useContext(GoogleMapContext);

    const fitBounds = (children, newMap) => {
    if (Array.isArray(children) && newMap && fitMarkers) {
      if (children.length > 0) {
        if (!setInitialCenter) {
          const bounds = new google.maps.LatLngBounds();
          children.forEach(marker => {
            if (!marker.props) return;
            if (!marker.props.position) return;
            bounds.extend(marker.props.position);
          });
          newMap.fitBounds(bounds);
        }
        newMap.setZoom(zoom);
      } else {
        newMap.setZoom(3);
      }

      if (onMapUpdate) {
        onMapUpdate(newMap);
      }
    }
  };

  useEffect(() => {
    fitBounds(children, map);
  }, [children]);

  const onMapReady = (_, map) => {
    setMap(map);
    fitBounds(children, map);

    if (onReady) {
      if (createRoute) {
        onReady(pointData, google, map);
      } else {
        onReady(map);
      }
    }
  };

  return (
    <>
      {map && (
        <div className="home-page__map-controls">
          <MapControls map={map} />
        </div>
      )}
      <Map
        google={google}
        disableDefaultUI
        onReady={onMapReady}
        initialCenter={initialCenter}
        styles={MapStyles}
        zoom={zoom}
        minZoom={3}
        onClick={onClick}
        center={center}>
        {children}
      </Map>
    </>
  );
};

export default GoogleMap;
