export const underscoreToSpaces = string => string.split('_').join(' ');

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const titleize = string =>
  underscoreToSpaces(string)
    .split(' ')
    .map(word => capitalize(word))
    .join(' ');

const legend = {
  u: string => underscoreToSpaces(string),
  c: string => capitalize(string),
  t: string => titleize(string),
};

export default (string, format) =>
  format
    .split('')
    .reduce(
      (combinedString, formatLetter) => legend[formatLetter](combinedString),
      string,
    );
