import { createSelector } from 'reselect';

const getCustomers = state => state.resourceReducer.customers.customers;
const getFilter = state => state.manageCustomers.filterCustomers.filter;
const getWell = state => state.lookUp.wellSites;

export const getModalCustomer = state => state.manageCustomers.modalCustomer;

export const filteredCustomers = createSelector(
  [getCustomers, getFilter],
  (customers, filterName) => {
    if (filterName !== '') {
      return customers.filter(item =>
        item.name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return customers;
  },
);

export const filteredWell = createSelector(
  [getWell, getFilter],
  (well, filterName) => {
    if (filterName !== '') {
      return well.filter(item =>
        item.name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return well;
  },
);

export const assembleAddress = (
  address = '',
  city = '',
  state = '',
  zip = '',
) => `${address ?? ''} ${city ?? ''} ${state ?? ''} ${zip ?? ''}`;
