import React from 'react';
import swal from 'bootstrap-sweetalert';

import Link from 'components/common/Link';
import { deleteAlert, confirmAlert } from 'components/sweetalert';
import jobOpType from 'components/globalInfo/jobOperationType';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import Label from 'components/shared/Label';
import ToolTip from 'components/shared/ToolTip';
import Button from 'components/shared/Button';

import { useEquipment } from 'api/v2/equipment';
import {
  useCompleteJob,
  useUncompleteJob,
  useDeleteJob
} from 'api/v2/jobs';

import { convertTimeWithCheck } from 'utils/convertTime';
import Loader from 'components/shared/Loader';
import { addCreatedUserName } from './helpers';


const displayJobApi = jobApi => {
  const message = jobApi || 'This Job has no token';
  swal('Api Token', message, 'info');
};

const Jobs = ({
  jobs,
  isLoading,
  openEditJobModal,
  openCertificates,
  users,
}) => {
  const { data: equipmentRequired } = useEquipment();
  const { mutate: completeJob } = useCompleteJob();
  const { mutate: uncompleteJob } = useUncompleteJob();
  const { mutate: deleteJob } = useDeleteJob();

  if (isLoading) {
    return <Loader />;
  }

  return jobs.map(data => (
    <div className="ibox ibox-order" key={data.id}>
      <div className="ibox-title" style={{ padding: '15px 0 0 0' }}>
        <div className="jobs-view-header">
          <div className="jobs-view-header__name">
            <Link id={data.id} to="job-details" isNotActive={data.isDraft}>
              <h4>
                Job #{data.id}:
                <span style={{ color: '#18a689' }}> {data.job_name}</span>
              </h4>
            </Link>
            {data.isSharedCreator ? (
              <ToolTip title={data.companySharedName} placement="top">
                <Label type="success">SaaS</Label>
              </ToolTip>
            ) : null}
          </div>
          <div className="jobs-view-header__status">
            {!!data.status && (
              <Label
                className="float-end"
                type="danger"
                testSelector="jobslist-status-completed">
                Completed
              </Label>
            )}
            {!data.status && (
              <Label
                className="float-end"
                type="warning"
                testSelector="jobslist-status-pending">
                Pending
              </Label>
            )}
            {Boolean(data.isDraft) && (
              <Label
                className="float-end"
                type="info"
                testSelector="jobslist-status-draft">
                Draft
              </Label>
            )}
            {equipmentRequired.map(item => (
              <span key={item.id}>
                {data.equipments.includes(item.id) && (
                  <Label
                    className="float-end"
                    type="primary"
                    testSelector="jobslist-job-equipment">
                    {item.title}
                  </Label>
                )}
              </span>
            ))}
            <Label
              className="float-end"
              type="success"
              testSelector="jobslist-job-operation-type">
              {jobOpType(data.operation_type).label}
            </Label>
            <Authorize forbidden={['CUSTOMER', 'JOB_MANAGER']}>
              {!data.isFullEdit && (
                <Label className="float-end" type="info">
                  View only
                </Label>
              )}
            </Authorize>
          </div>

          <div className="jobs-view-header__actions">
            <div>
              <Button
                onClick={() => displayJobApi(data.api_token)}
                theme="small"
                testSelector="jobslist-job-api-label">
                <Icon icon="credit-card" />
              </Button>
              <Authorize
                {...{
                  abilityPermissions: [
                    {
                      resource: 'Jobs',
                      permissions: ['update'],
                    },
                  ],
                  itemAccessGranted:
                    data.accessLevel === 'shared' ||
                    data.accessLevel === 'owner' ||
                    data.accessLevel === 'shared_creator',
                }}>
                <Button
                  onClick={() => openEditJobModal(data.id)}
                  theme="small"
                  testSelector="jobslist-edit-job-btn">
                  <Icon icon="pencil" />
                </Button>
              </Authorize>
              <Authorize
                {...{
                  forbidden: ['CARRIER', 'CUSTOMER'],
                  abilityPermissions: [
                    {
                      resource: 'Jobs',
                      permissions: ['delete'],
                    },
                  ],
                }}>
                <Button
                  theme="small"
                  onClick={() => deleteAlert(deleteJob, data.id)}
                  testSelector="jobslist-delete-job-btn">
                  <Icon style={{ color: '#6fc6d9' }} icon="trash del-icon" />
                </Button>
              </Authorize>
              {!data.isDraft &&
                (!data.status ? (
                  <Authorize
                    {...{
                      abilityPermissions: [
                        {
                          resource: 'Jobs',
                          permissions: ['update'],
                        },
                      ],
                    }}>
                    <Label
                      style={{
                        cursor: 'pointer',
                        float: 'none',
                      }}
                      onClick={() => confirmAlert(
                        completeJob,
                        data.id,
                        { text: 'Are you sure you want to complete this job?' }
                      )}
                      type="success">
                      Complete
                    </Label>
                  </Authorize>
                ) : (
                  <Authorize
                    {...{
                      access: ['JOB_MANAGER', 'CUSTOMER'],
                      abilityPermissions: [
                        {
                          resource: 'Jobs',
                          permissions: ['update'],
                        },
                      ],
                    }}>
                    <Label
                      style={{
                        cursor: 'pointer',
                        float: 'none',
                      }}
                      onClick={() => confirmAlert(
                        uncompleteJob,
                        data.id,
                        { text: 'Are you sure you want to uncomplete this job?' }
                      )}
                      type="danger">
                      Uncomplete
                    </Label>
                  </Authorize>
                ))}
              <Authorize
                abilityPermissions={[
                  { resource: 'Jobs', permissions: ['read'] },
                ]}>
                <Label
                  style={{
                    cursor: 'pointer',
                    float: 'none',
                  }}
                  onClick={() => openCertificates(data)}
                  type="info"
                  testSelector="jobslist-certificates-label">
                  Certificates
                </Label>
              </Authorize>
              {/* removed for now perhaps add back later for admin only */}
              {/* <Link
                {...{
                  id: data.id,
                  to: 'job-stats',
                }}>
                <Label
                  style={{
                    cursor: 'pointer',
                    float: 'none',
                  }}
                  type="info">
                  Stats
                </Label>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="ibox-content-order">
        <div className="row">
          <div className="col-md-6 col-xs-6">
            <ul className="list-unstyled">
              <li>
                <strong>Created by: </strong>
                {addCreatedUserName(data, users)}
              </li>
              <li>
                <strong>Customer #{data.customer_id}:</strong>{' '}
                {data.customer_name}
              </li>
              <li>
                <strong>Districts: </strong>
                {data.districts
                  .filter(item => Boolean(item.id) && Boolean(item.name))
                  .map(item => `#${item.id} | ${item.name}`)
                  .join(', ')}
              </li>
              {data.start_date !== 'Invalid date' && (
                <li style={{ cursor: 'pointer' }}>
                  <strong>Start Time:</strong>{' '}
                  {convertTimeWithCheck(data.start_date)}
                </li>
              )}
              {data.end_date !== 'Invalid date' && (
                <li style={{ cursor: 'pointer' }}>
                  <strong>End Time:</strong>{' '}
                  {convertTimeWithCheck(data.end_date)}
                </li>
              )}
            </ul>
          </div>
          <div className="col-md-6 col-xs-6">
            <ul className="list-unstyled">
              <li style={{ cursor: 'pointer' }}>
                <strong>Location #{data.well_site}:</strong> {data.well_name}
              </li>
              {data.customer_specified_project_id && (
                <li>
                  <strong>Project ID:</strong>{' '}
                  {data.customer_specified_project_id}
                </li>
              )}
              <li
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}>
                <strong>Directions: </strong>
                {data.directions}
              </li>
              {data.operation_type !== 12 && (
                <>
                  <li>
                    <strong>Pump Hours: </strong>
                    {data.pump_time}
                  </li>
                  <li>
                    <strong>Wireline Hours: </strong>
                    {data.wireline_time}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ));
};
export default Jobs;
