import React from 'react';
import swal from 'bootstrap-sweetalert';
import { Modal } from 'react-bootstrap';
import authService from 'services/auth/service';
import { cloneDeep } from 'lodash';

import { useAddCustomer, useShareCustomer } from 'api/v2/customers';

import { selectAction } from './helpers';
import { useAddCustomerForm } from './hooks';

const AddCustomer = ({
  showModalAdd,
  closeAdd,
}) => {
  const { mutate: addCustomer } = useAddCustomer();
  const { mutate: shareCustomer } = useShareCustomer();

  const {
    handlers: { setStep, setBillValue, setConfigValue, setCompanyValue, setBillingTermsValue },
    formState,
  } = useAddCustomerForm();

  const submitAdd = e => {
    e.preventDefault();
    const customerInfo = cloneDeep(formState.input);
    customerInfo.companyInfo.districts = !Number.isNaN(Number(customerInfo.companyInfo.districts[0]))
      ? customerInfo.companyInfo.districts
      : customerInfo.companyInfo.districts.map(item => item.value);
    customerInfo.companyInfo.bcSync = Boolean(customerInfo.companyInfo.bcSync);
    customerInfo.configuration = {
      useQRCode: customerInfo.configuration.useQRCode ? '1' : '0',
      fuelSurchargeEnabled: customerInfo.configuration.fuelSurchargeEnabled ? '1' : '0',
    };
    addCustomer(
      customerInfo,
      {
        onSuccess: ({ customerId }) => {
          setBillingTermsValue(customerId, 'customerId');
          setStep(5);
        },
        onError: (err) => {
          swal('Error', err.response.data.message, 'error');
        }
      }
    );
  }

  const shareCustomerWithCompany = (closeModal, customerId) => {
    const user = authService.getUser();
    const params = {
      data: {
        companyType: user.entityType,
        companyId: user.entityId,
      },
      customerId,
    };
    shareCustomer(params);
  }


  return (
    <Modal className="modal-container" show={showModalAdd} onHide={closeAdd}>
      <div className="ibox">
        <Modal.Header>
          <h3 className="modal-title">Add Customer</h3>
        </Modal.Header>
        <div className="ibox-content ibox-content-no-shade">
          <span className="wizard-big wizard">
            <div className="steps">
              <ul role="tablist">
                <li
                  role="tab"
                  className={formState.step === 1 ? 'current' : 'disabled'}
                  onClick={() => setStep(2)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">1.</span> Search
                  </a>
                </li>
                <li
                  role="tab"
                  className={formState.step === 2 ? 'current' : 'disabled'}
                  onClick={() => setStep(3)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">2.</span> Company Info
                  </a>
                </li>
                <li
                  role="tab"
                  className={formState.step === 3 ? 'current' : 'disabled'}
                  onClick={() => setStep(4)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">3.</span> Billing Address
                  </a>
                </li>
                <li
                  role="tab"
                  className={formState.step === 4 ? 'current' : 'disabled'}
                  onClick={() => setStep(5)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">4.</span> Configuration
                  </a>
                </li>
                <li
                  role="tab"
                  className={formState.step === 5 ? 'current' : 'disabled'}
                  onClick={() => setStep(6)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">5.</span> Billing Terms
                  </a>
                </li>
              </ul>
            </div>
            {selectAction(
              setStep,
              submitAdd,
              setCompanyValue,
              setBillValue,
              setConfigValue,
              formState,
              closeAdd,
              shareCustomerWithCompany,
              setBillingTermsValue
            )}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export const AddCustomerTest = AddCustomer; // for Tests

export default AddCustomer;
