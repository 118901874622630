import { combineReducers } from '@reduxjs/toolkit';

const initialStateSettings = {
  settings: [],
  isLoadedSettings: false,
};

const currentSettings = (state = initialStateSettings, action) => {
  switch (action.type) {
    case 'ADMIN_SETTINGS_GET_REQUEST':
      return { ...state };
    case 'ADMIN_SETTINGS_GET_SUCCESS':
      return { ...state, isLoadedSettings: true, settings: action.settings };
    case 'ADMIN_SETTINGS_GET_FAILURE':
      return {};
    case 'SETTINGS_CURRENT_CLEAR_STATE':
      return initialStateSettings;
    default:
      return state;
  }
};

const initialStateFuelPrice = {
  isLoadedCurrentFuelPrice: false,
  currentPrice: [],
};

const currentFuelPrice = (state = initialStateFuelPrice, action) => {
  switch (action.type) {
    case 'ADMIN_SETTINGS_FUEL_PRICE_GET_REQUEST':
      return { ...state };
    case 'ADMIN_SETTINGS_FUEL_PRICE_GET_SUCCESS':
      return {
        ...state,
        isLoadedCurrentFuelPrice: true,
        currentPrice: action.currentPrice,
      };
    case 'ADMIN_SETTINGS_FUEL_PRICE_GET_FAILURE':
      return {};
    default:
      return state;
  }
};

const initialStateFuelBreakdown = {
  isLoadedFuelBreakdown: false,
  fuelList: [],
};

const fuelBreakdown = (state = initialStateFuelBreakdown, action) => {
  switch (action.type) {
    case 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_REQUEST':
      return { ...state };
    case 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_SUCCESS':
      return {
        ...state,
        isLoadedFuelBreakdown: true,
        fuelList: action.fuelList,
      };
    case 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_FAILURE':
      return {};
    default:
      return state;
  }
};

const initialCompanyAddress = {
  isLoadedCompanyAddress: false,
  companyAddress: {
    name: '',
    phoneNumber: '',
    city: '',
    state: '',
    address: '',
    addressSecond: '',
    taxId: '',
    email: '',
    zipCode: '',
    departmentId: '',
  },
};

const companyAddress = (state = initialCompanyAddress, action) => {
  switch (action.type) {
    case 'ADMIN_SETTINGS_INIT_COMPANY_ADDRESS':
      return {
        ...state,
        isLoadedCompanyAddress: true,
        companyAddress: action.data,
      };
    case 'SETTINGS_SET_COMPANY_ADDRESS_VALUE':
      return {
        ...state,
        companyAddress: {
          ...state.companyAddress,
          [action.name]: action.value,
        },
      };
    case 'SETTINGS_COMPANY_ADDRESS_CLEAR_STATE':
      return initialCompanyAddress;
    default:
      return state;
  }
};

const initialAuditSettings = {
  isLoadedAuditSettings: false,
  auditSettings: {},
};

const auditSettings = (state = initialAuditSettings, action) => {
  switch (action.type) {
    case 'ADMIN_SETTINGS_INIT_AUDIT_SETTINGS':
      return {
        ...state,
        auditSettings: {
          ...action.data,
          duplicateSandTicketNumbersRuleEnabled: Boolean(
            Number(action.data.duplicateSandTicketNumbersRuleEnabled),
          ),
          minimumMinutesForCompletionRuleEnabled: Boolean(
            Number(action.data.minimumMinutesForCompletionRuleEnabled),
          ),
          noTimestampRuleEnabled: Boolean(
            Number(action.data.noTimestampRuleEnabled),
          ),
          openMoreThan24HoursRuleEnabled: Boolean(
            Number(action.data.openMoreThan24HoursRuleEnabled),
          ),
        },
        isLoadedAuditSettings: true,
      };
    case 'SETTINGS_SET_AUDIT_SETTINGS_VALUE':
      return {
        ...state,
        auditSettings: {
          ...state.auditSettings,
          [action.name]: action.value,
        },
      };
      case 'SETTINGS_AUDIT_SETTINGS_CLEAR_STATE':
        return initialAuditSettings;
    default:
      return state;
  }
};

export default combineReducers({
  currentSettings,
  currentFuelPrice,
  fuelBreakdown,
  companyAddress,
  auditSettings,
});
