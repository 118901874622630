import { createSelector } from 'reselect';
import { dynamicSort } from 'utils/dynamicSort';

const getOrders = state => state.queue.orders;
const getDrivers = state => state.queue.drivers;
const getState = state => state.queue;
const getFilters = state => state.queue.filter;
const getEquipments = state => state.lookUp.equipmentRequired;

export const filterOrders = createSelector(
  getOrders,
  getFilters,
  (orders, filters) => {
    const regexDistrict = new RegExp(filters.districtId || '', 'i');
    const regexEquipment = new RegExp(filters.equipmentId || '', 'i');

    return orders
      .filter(
        item =>
          item.districts.filter(district =>
            filters.districtId
              ? district.id === filters.districtId
              : district.id,
          ).length > 0 &&
          item.allowed_equipments.toString().search(regexEquipment) != -1,
      )
      .sort(dynamicSort(filters.sortOrder));
  },
);

export const filterDrivers = createSelector(
  getDrivers,
  getFilters,
  (drivers, filters) => {
    const regexEquipment = new RegExp(filters.equipmentId || '', 'i');
    return drivers
      .filter(
        item =>
          item.districts.filter(district =>
            filters.districtId
              ? district.id === filters.districtId
              : district.id,
          ).length > 0 &&
          item.equipment.toString().search(regexEquipment) != -1,
      )
      .sort(dynamicSort(filters.sortDriver));
  },
);

export const uniqueDataFilter = createSelector(
  getState,
  getEquipments,
  (state, equipmentRequired) => {
    const equipment = {};
    equipmentRequired.map(item => {
      equipment[item.id] = item.title;
    });

    const filterUniqueSelect = {
      district: [],
      equipment: [],
    };

    const ordersDistrict = [];
    state.orders.forEach(order => {
      order.districts.forEach(district => {
        ordersDistrict.push({
          id: district.id,
          name: district.name,
        });
      });
    });

    const driversDistrict = [];
    state.drivers.forEach(driver => {
      if (driver.districts && driver.districts.length > 0) {
        driver.districts.forEach(district => {
          driversDistrict.push({
            id: district.id,
            name: district.name,
          });
        });
      } else {
        driversDistrict.push({
          id: driver.district,
          name: driver.districtName,
        });
      }
    });
    filterUniqueSelect.district = ordersDistrict.concat(driversDistrict);

    let ordersEquipment = [];
    if (equipment) {
      const allowedEquipment = [
        ...state.orders.map(item => item.allowed_equipments),
      ];
      if (allowedEquipment.length) {
        ordersEquipment = allowedEquipment
          .reduce((acc, cur) => acc.concat(cur))
          .map(data => ({
            id: Number(data),
            name: equipment[data],
          }));
      }

      const driversEquipment = [
        ...state.drivers.map(item => item.equipment),
      ].map(data => ({
        id: Number(data),
        name: equipment[data],
      }));

      filterUniqueSelect.equipment = ordersEquipment.concat(driversEquipment);
    }

    filterUniqueSelect.district = Object.values(
      filterUniqueSelect.district.reduce(
        (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
        {},
      ),
    );
    filterUniqueSelect.equipment = Object.values(
      filterUniqueSelect.equipment.reduce(
        (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
        {},
      ),
    );

    return filterUniqueSelect;
  },
);
