import { combineReducers } from '@reduxjs/toolkit';

const initState = {
  // countApi: 6,
  countApi: 4,
  count: 0,
  details: {},
  filterStatus: 0,
  paymentStatus: 1,
  payments: [],
  profileStatus: 1,
  profiles: [],
  notarizations: [],
  id: '',
  customerWellsites: [],
  filteredLocs: [],
};

const customer = (state = initState, data) => {
  switch (data.type) {
    case 'CUSTOMER_DETAILS_START':
      return { ...state };
    case 'CUSTOMER_DETAILS_SAVE_INIT_DATA':
      return {
        ...state,
        [data.name]: data.value,
        count: state.count + 1,
      };
    case 'CUSTOMER_DETAILS_SAVE_VOIDED_DATA':
      return {
        ...state,
        [data.name]: data.value,
      };
    case 'CUSTOMER_DETAILS_JOB_LIST_SET_FILTER_STATUS':
      return {
        ...state,
        filterStatus: data.value,
      };
    case 'CUSTOMER_DETAILS_PAYMENTS_STATUS':
      return {
        ...state,
        paymentStatus: data.value,
      };
    case 'CUSTOMER_DETAILS_PROFILES_STATUS':
      return {
        ...state,
        profileStatus: data.value,
      };
    case 'CUSTOMER_DETAILS_LOCATIONS_SET_FILTERED_LOCATIONS':
      return {
        ...state,
        filteredLocs: data.locations,
      };
    case 'CUSTOMER_DETAILS_CLEAR':
      return initState;
    default:
      return state;
  }
};

const initEditInfo = {
  allDistricts: [],
  info: {
    customerName: '',
    qbId: '',
    email: '',
    phoneNumber: '',
    sandCoordinatorRequired: '',
    fuelSurchargeEnabled: '',
    displayRateToDriver: '',
    districts: [],
    useQRCode: '',
    price: '',
    selectedDistricts: [],
    companyType: '',
    factorViewId: '',
    isNonPurchasable: false,
    bcSync: false,
  },
};

const editInfo = (state = initEditInfo, action) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_INIT_MODAL':
      return {
        ...state,
        info: action.details,
      };
    case 'CUSTOMER_DETAILS_GET_DISTRICTS':
      return {
        ...state,
        allDistricts: action.districts,
      };
    case 'CUSTOMER_DETAILS_SET_VALUE':
      return {
        ...state,
        info: {
          ...state.info,
          [action.name]: action.value,
        },
      };
    case 'CUSTOMER_DETAILS_CLEAR_EDIT':
      return initEditInfo;
    default:
      return state;
  }
};

const initEditBillingAddress = {
  city: '',
  state: '',
  address: '',
  name: '',
  addressSecond: '',
  zipCode: '',
  phoneNumber: '',
  email: '',
  departmentId: '',
};

const editBillingAddress = (state = initEditBillingAddress, action) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_INIT_BILLING_ADDRESS':
      return {
        ...state,
        ...action.address,
      };
    case 'CUSTOMER_DETAILS_BILLING_ADDRESS_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'CUSTOMER_DETAILS_BILLING_ADDRESS_CLEAR':
      return initEditBillingAddress;
    default:
      return state;
  }
};

const initEditBillingTerms = {
  dueDays: '',
  billingFrequency: '',
  bulkInvoiceEnabled: false,
  packetInvoiceEnabled: false,
  csvInvoiceEnabled: false,
  nonBillable: false,
  billTo: '',
  approvalsRequired: [],
  bulkInvoiceTemplateId: 1,
  billableOperationTypeStatus: [],
};

const editBillingTerms = (state = initEditBillingTerms, action) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_INIT_BILLING_TERMS':
      return {
        ...state,
        ...action.terms,
      };
    case 'CUSTOMER_DETAILS_BILLING_TERMS_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'CUSTOMER_DETAILS_BILLING_TERMS_UPDATE_JOB_OP_VALUE':
      const index = state.billableOperationTypeStatus.findIndex(
        type => type.operationType === action.data.operationType,
      );
      return {
        ...state,
        billableOperationTypeStatus: [
          ...state.billableOperationTypeStatus.slice(0, index),
          {
            ...state.billableOperationTypeStatus[index],
            billableStatus: action.data.billableStatus ? 1 : 0,
          },
          ...state.billableOperationTypeStatus.slice(index + 1),
        ],
      };
    case 'CUSTOMER_DETAILS_BILLING_TERMS_CLEAR':
      return initEditBillingTerms;
    default:
      return state;
  }
};

const initLogo = {
  logo: '',
};

const editLogo = (state = initLogo, action) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_EDIT_LOGO_SET_INPUT':
      return {
        ...state,
        logo: action.value,
      };
    case 'CUSTOMER_DETAILS_EDIT_LOGO_CLEAR':
      return initLogo;
    default:
      return state;
  }
};

const initNotarizations = {
  notarizationsList: [],
  isLoaded: false,
};

const notarizationSettingsModal = (
  state = { ...initNotarizations },
  action,
) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_NOTARIZATIONS_INIT_DATA':
      return {
        ...state,
        notarizationsList: action.data,
        isLoaded: true,
      };
    case 'CUSTOMER_DETAILS_NOTARIZATIONS_SET_INPUT_VALUE':
      return {
        ...state,
        notarizationsList: {
          ...state.notarizationsList,
          [action.name]: action.value,
        },
      };
    case 'CUSTOMER_DETAILS_NOTARIZATIONS_CLEAR_MODAL':
      return initNotarizations;
    default:
      return state;
  }
};

const initAddEditLocation = {
  isLoaded: false,
  locationData: {
    name: '',
    contactPhone: '',
    address: '',
    city: '',
    state: '',
    latitude: '',
    longitude: '',
    specialInstructions: '',
    geofenceRange: '',
    locationType: 0,
    customerId: '',
    email: '',
  },
  customers: [],
};

const addEditLocation = (state = initAddEditLocation, action) => {
  switch (action.type) {
    case 'CUSTOMER_DETAILS_LOCATIONS_EDIT_INIT_MODAL':
      return {
        ...state,
        locationData: action.location,
        customers: action.customers,
        isLoaded: true,
      };
    case 'CUSTOMER_DETAILS_LOCATIONS_ADD_INIT_CUSTOMERS_MODAL':
      return {
        ...state,
        customers: action.customers,
        locationData: {
          ...state.locationData,
          customerId: action.id,
        },
        isLoaded: true,
      };
    case 'CUSTOMER_DETAILS_LOCATIONS_SET_LOCATION_COORDINATS':
      return {
        ...state,
        locationData: {
          ...state.locationData,
          latitude: action.lat,
          longitude: action.long,
        },
      };
    case 'CUSTOMER_DETAILS_LOCATIONS_SET_INPUT_VALUE':
      return {
        ...state,
        locationData: {
          ...state.locationData,
          [action.name]: action.value,
        },
      };
    case 'CUSTOMER_DETAILS_LOCATIONS_CLEAR_MODAL_ADD_LOCATION':
      return initAddEditLocation;
    default:
      return state;
  }
};

const initPaymentItems = {
  items: [],
  jobs: [],
  isLoaded: false,
};

const paymentItems = (state = { ...initPaymentItems }, action) => {
  switch (action.type) {
    case 'BILLING_PROFILE_GET_ITEMS':
      return {
        ...state,
        items: action.paymentItems,
        jobs: action.jobs,
        isLoaded: true,
      };
    case 'BILLING_PROFILE_ITEMS_CLEAR':
      return { ...initPaymentItems };
    default:
      return state;
  }
};

const initAddProfile = {
  name: '',
  notes: '',
};

const addProfile = (state = { ...initAddProfile }, action) => {
  switch (action.type) {
    case 'MODAL_ADD_CLEAR_PROFILE_VALUE':
      return { ...initAddProfile };
    case 'MODAL_ADD_PROFILE_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };

    default:
      return state;
  }
};

const initAddPaymentsIntoProfile = {
  payment: {
    paymentItemId: '',
    startDate: '',
    endDate: '',
    carrierIds: [],
    equipmentIds: [],
    districtIds: [],
    locationIds: [],
    overridePaymentItemId: 0,
    defaultPaymentItem: {},
    defaultOverride: {},
    id: '',
    rateSchedule: {},
  },
  init: {
    profileId: '',
    carriers: [],
    equipments: [],
    districts: [],
    locations: [],
    isLoaded: false,
  },
  initRatesSchedule: {
    name: '',
    timeZone: '',
    startDate: '',
    endDate: '',
    rates: {
      Monday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
      Tuesday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
      Wednesday: [
        { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
      ],
      Thursday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
      Friday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
      Saturday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
      Sunday: [{ startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' }],
    },
  },
};
const addPaymentsIntoProfile = (
  state = { ...initAddPaymentsIntoProfile },
  action,
) => {
  switch (action.type) {
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_CLEAR':
      return { ...initAddPaymentsIntoProfile };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_SET_VALUE':
      return {
        ...state,
        payment: {
          ...state.payment,
          [action.name]: action.value,
        },
      };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_SET_EDIT':
      return {
        ...state,
        payment: action.itemEdit,
      };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_INIT':
      return {
        ...state,
        init: {
          profileId: action.profileId,
          carriers: action.carriers,
          equipments: action.equipments,
          districts: action.districts,
          locations: action.locations,
          isLoaded: true,
        },
      };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_UPDATE_RATES':
      return {
        ...state,
        initRatesSchedule: {
          ...state.initRatesSchedule,
          rates: action.rates,
        },
      };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_RATES_VALUE':
      return {
        ...state,
        initRatesSchedule: {
          ...state.initRatesSchedule,
          [action.name]: action.value,
        },
      };
    case 'MODAL_ADD_PAYMENT_INTO_PROFILE_CLEAR_RATES':
      return {
        ...state,
        payment: {
          ...state.payment,
          paymentItemId: '',
        },
        initRatesSchedule: {
          timeZone: '',
          startDate: '',
          endDate: '',
          rates: {
            Monday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Tuesday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Wednesday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Thursday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Friday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Saturday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
            Sunday: [
              { startTime: '12:00 am', endTime: '11:59 pm', paymentId: '' },
            ],
          },
        },
      };
    default:
      return state;
  }
};

const initAddPayment = {
  addPayment: {
    name: '',
    notes: '',
    type: undefined,
    isCalculated: 0,
    conditionalValue: {
      field: 0,
      modifyValue: false,
      modifiers: [
        {
          type: '',
          value: '',
        },
      ],
    },
    billablelValue: {
      field: 0,
      sameAsConditional: true,
      modifyValue: false,
      modifiers: [
        {
          type: '',
          value: '',
        },
      ],
    },
    price: {
      rangeType: 0,
      modifyPriceValue: false,
      ranges: [
        {
          min: '',
          max: '',
          customerPrice: '',
          carrierPrice: '',
          percentage: '',
        },
      ],
      modifiersPrice: [
        {
          type: '',
          value: '',
        },
      ],
    },
  },
  addPaymentInitData: {
    step: 1,
    template: undefined,
    openAdvanced: false,
  },
};

const modalAddPayment = (state = initAddPayment, action) => {
  switch (action.type) {
    case 'MODAL_ADD_CLEAR_PAYMENT_VALUE':
      return { ...initAddPayment };
    case 'MODAL_ADD_PAYMENT_SET_STEP':
      return {
        ...state,
        addPaymentInitData: {
          ...state.addPaymentInitData,
          step: action.step,
        },
      };
    case 'MODAL_ADD_PAYMENT_INIT_CLONE':
      return {
        ...state,
        addPayment: action.clone,
      };
    case 'MODAL_ADD_PAYMENT_TURN_ADVANCED_MODE':
      return {
        ...state,
        addPaymentInitData: {
          ...state.addPaymentInitData,
          openAdvanced: !state.addPaymentInitData.openAdvanced,
        },
      };
    case 'MODAL_ADD_PAYMENT_SET_VALUE':
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          [action.name]: action.value,
        },
      };
    case 'MODAL_ADD_PAYMENT_SET_TEMPLATE':
      return {
        ...state,
        addPaymentInitData: {
          ...state.addPaymentInitData,
          template: action.value,
        },
      };
    case 'MODAL_ADD_SET_CONDITIONAL_VALUE':
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          conditionalValue: {
            ...state.addPayment.conditionalValue,
            [action.name]: action.value,
          },
        },
      };
    case 'MODAL_ADD_SET_BILLABLE_VALUE':
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          billablelValue: {
            ...state.addPayment.billablelValue,
            [action.name]: action.value,
          },
        },
      };
    case 'MODAL_ADD_SET_PRICE_VALUE':
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          price: {
            ...state.addPayment.price,
            [action.name]: action.value,
          },
        },
      };
    case 'MODAL_ADD_SET_ARRAY_VALUE':
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          [action.arrayName]: {
            ...state.addPayment[action.arrayName],
            [action.name]: action.value,
          },
        },
      };

    default:
      return state;
  }
};

const initCustProfileSlideout = {
  allOrders: [],
  ordersFiltered: [],
};

const custProfileSlideout = (state = initCustProfileSlideout, action) => {
  switch (action.type) {
    case 'CUSTOMER_PROFILE_INIT_SLIDEOUT':
      return {
        ...state,
        allOrders: action.value,
        isLoaded: true
      };
    case 'CUSTOMER_PROFILE_BILLING_ORDERSFILTERED_SET_VALUE':
      return {
        ...state,
        ordersFiltered: action.value,
        isLoaded: true
      };
    case 'CUSTOMER_PROFILE_BILLING_ADDRESS_CLEAR':
      return initCustProfileSlideout;
    default:
      return state;
  }
};

export default combineReducers({
  customer,
  editInfo,
  editBillingAddress,
  editBillingTerms,
  editLogo,
  notarizationSettingsModal,
  addEditLocation,
  paymentItems,
  addProfile,
  addPaymentsIntoProfile,
  modalAddPayment,
  custProfileSlideout,
});
