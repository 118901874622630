import { useLocations } from 'api/v2/locations';

export const useLocationOptions = action => {
  const { data: locations } = useLocations();
  if (action === 'add') {
    return (locations || []).map(l => ({
      value: l.id,
      label: l.name,
    }));
  }
  return [];
};
