import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { getCarrierGeotab, updateCarrierGeotab } from './queries';

/**
 *
 * @param {number} carrierId
 */
export const useCarrierGeotab = carrierId =>
  useQuery({
    queryKey: ['carriers-geotab', carrierId],
    queryFn: () => getCarrierGeotab(carrierId),
    enabled: !!carrierId,
  });

/**
 *
 * @param {number} carrierId
 */
export const useUpdateCarrierGeotab = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers-geotab', carrierId],
    mutationFn: geotab => updateCarrierGeotab({ carrierId, geotab }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Geotab', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', carrierId] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Geotab', error.message, 'error'),
  });
};
