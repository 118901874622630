import React, { useCallback, useMemo, useState } from 'react';
import { groupBy, sortBy } from 'lodash';

import { confirmAlert } from 'components/sweetalert';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';

import { useTags, useDeleteTag } from 'api/v2/tags';
import AddEditModal from './AddEditModal';

const Tags = () => {
  const { data: tags, isLoading } = useTags();
  const { mutate: deleteTag } = useDeleteTag();
  const [showUpdate, turnShowUpdate] = useState(false);
  const [modalValue, setModalValue] = useState({ status: 'add' });

  const removeTag = useCallback(
    async id => {
      deleteTag({ id, isDeleted: true });
    },
    [deleteTag],
  );

  const openUpdate = (status, value) => {
    setModalValue({
      ...(value || { status: 'add' }),
      status,
    });
    turnShowUpdate(true);
  };

  const groupedTags = useMemo(() => groupBy(sortBy(tags, 'type'), 'type'), [
    tags,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  const renderTagItem = item => (
    <div key={`key${item.id}`} className="tree-node tree-node-content">
      <span className="tree-node-content-name" style={{ color: '#ffffff' }}>
        <strong>{` ${item.id} | ${item.name} `}</strong>
        <span>
          <Authorize
            abilityPermissions={[
              { resource: 'Database', permissions: ['update'] },
            ]}>
            <Button
              onClick={() => openUpdate('edit', item)}
              theme="small"
              testSelector="database_tags_edit_btn">
              <Icon icon="pencil" />
            </Button>
          </Authorize>
          <Authorize
            abilityPermissions={[
              { resource: 'Database', permissions: ['delete'] },
            ]}>
            <Button
              onClick={() => confirmAlert(removeTag, item.id)}
              theme="small"
              testSelector="database_tags_delete_btn">
              <Icon icon="trash" />
            </Button>
          </Authorize>
        </span>
      </span>
      <div>{` ${item.description} `}</div>
    </div>
  );

  const renderTagType = type => (
    <div>
      <h4>{type} Tags</h4>
      {groupedTags[type].map(renderTagItem)}
    </div>
  );

  return (
    <div className="panel-body">
      <div className="wrapper-content">
        <div className="row">
          <div>
            <div className="ibox">
              <div className="ibox-content">
                <Authorize
                  abilityPermissions={[
                    { resource: 'Database', permissions: ['update'] },
                  ]}>
                  <Button
                    onClick={() => openUpdate('add')}
                    inverse
                    className="float-end"
                    testSelector="database_tags_add_btn">
                    <Icon className="icon--margin-right" icon="plus-square" />
                    Add Tag
                  </Button>
                </Authorize>
                {Object.keys(groupedTags).map(renderTagType)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEditModal
        showUpdate={showUpdate}
        modalValue={modalValue}
        closeUpdate={() => turnShowUpdate(false)}
      />
    </div>
  );
};

export default Tags;
