import React, { useState } from 'react';
import Link from 'components/common/Link';
import { connect } from 'react-redux';
import Icon from 'components/shared/Icon';
import { loginActions } from 'store/actions/Login';
import SwitchRoleModal from '../SwitchRole';

const UserWidgetDropdown = ({ open, logout, userLoggedIn }) => {
  const [roleSwitchingModalOpen, setRoleSwitchingModalOpen] = useState(false);

  const changeRoleSwitchingModalState = modalState =>
    setRoleSwitchingModalOpen(modalState);

  return (
    <>
      <ul
        className={`user-widget__dropdown ${(!open &&
          'user-widget__dropdown--hidden') ||
          ''}`}>
        <li className="user-widget__dropdown--item">
          <span
            onClick={() => changeRoleSwitchingModalState(true)}
            className="user-widget__dropdown--item-link">
            <Icon icon="users" /> SWITCH ROLE
          </span>
        </li>
        <li className="user-widget__dropdown--item">
          <Link
            to="update-profile"
            className="user-widget__dropdown--item-link">
            <Icon icon="user" /> UPDATE PROFILE
          </Link>
        </li>
        <li className="user-widget__dropdown--item">
          <a
            href="https://help.portal.atmz.io/Automatize_Help_Center.htm"
            target="_blank"
            title="help link">
            <Icon className="icon--widget" icon="help" /> HELP
          </a>
        </li>
        <li className="user-widget__dropdown--item">
          <span onClick={logout} className="user-widget__dropdown--item-link">
            <Icon icon="sign-out" /> LOG OUT
          </span>
        </li>
      </ul>
      <SwitchRoleModal
        open={roleSwitchingModalOpen}
        onClose={() => changeRoleSwitchingModalState(false)}
        {...{ userLoggedIn }}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loginActions.logout()),
});

export default connect(
  null,
  mapDispatchToProps,
)(UserWidgetDropdown);
