/**
 * Returns a list that disables the list parent items and adds a -- infront of the children
 */

export const dropDownWithHierarchy = list => {
  const dataDemurrage = [];
  list.forEach(item => {
    dataDemurrage.push({
      id: item.id,
      name: item.name,
      margin: '',
      isDisabled: true,
    });
    item.children.forEach(child => {
      dataDemurrage.push({
        id: child.id,
        name: child.name,
        symbol: '--',
        isDisabled: false,
      });
    });
  });
  return dataDemurrage;
};
