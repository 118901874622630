import React from 'react';
import { convertTime, timeFromNow } from 'utils/convertTime';
import Link from 'components/common/Link';
import Authorize from 'components/common/Authorize';
import ToolTip from 'components/shared/ToolTip';
import { commodityTypes, image } from 'components/globalInfo/commodityUnits';

const linkPermission = (id, name) => (
  <span>
    <Authorize forbidden={['CUSTOMER']}>
      <Link id={id} to="location-details">
        {`#${id || 'unknown'} | ${name || 'unknown'}`}
      </Link>
    </Authorize>
    <Authorize
      forbidden={['VENDOR', 'CARRIER', 'JOB_COORDINATOR', 'JOB_MANAGER']}>
      {`#${id || 'unknown'} | ${name || 'unknown'}`}
    </Authorize>
  </span>
);

const formatLocationInfo = order => {
  if (
    order.operation_type === 12 &&
    !order.driver_id &&
    (order.status === 0 || order.status === 1)
  ) {
    return 'Unassigned';
  }
  return linkPermission(order.loading_site_id, order.loading_site_name);
};

const switchTitle = data => {
  switch (data) {
    case 1:
      return 'Origin';
    case 3:
      return 'Destination';
    default:
      return 'Origin';
  }
};

const OrderContent = ({ order }) => {
  const {
    status,
    driver_id: driverId,
    commodity_name: commodityName,
    po,
    mileage,
    unload_appt: unloadAppt,
    weight,
    driver_weight: driverWeight,
    carrier_id: carrierId,
    carrier_name: carrierName,
    driver_name: driverName,
    driver_contact_number: driverContactNumber,
    equipment,
    truck,
    job_name: jobName,
    container_number: containerNumber,
    commodityType,
    expected_pickup_time: expectedPickupTime,
    eta,
    sand_ticket_no: sandTicketNo,
    well_arrival: wellArrival,
    operation_type: operationType,
    staging_enter_time: stagingEnterTime,
  } = order;

  // const equipments = (details.details && details.details.equipments) || [];
  const changeWeightsValue = data => data;
  const convertedExpectedPickupTime = convertTime(expectedPickupTime);
  const convertedUnloadApptTime = convertTime(unloadAppt);
  const convertedEta = convertTime(eta);
  const etaTimeFromNow = timeFromNow(eta);
  const convertedWellArrival = convertTime(wellArrival);
  return (
    <div className="order-item__details">
      <div className="order-item__details--info">
        <ToolTip title={commodityTypes(commodityType)?.title}>
          <span style={{ margin: '0 5px' }}>{image(commodityType)}</span>
        </ToolTip>
        <strong>Commodity type:</strong> {commodityName}
      </div>
      <div className="order-item__details--info">
        <span>
          <strong style={{ cursor: 'pointer' }}>
            {`${switchTitle(commodityType)}: `}
          </strong>
          {formatLocationInfo(order)}
        </span>
      </div>
      <div className="order-item__details--info">
        <strong>PO: </strong>
        {operationType !== 12 && po}
      </div>
      <div className="order-item__details--info">
        <strong>Job: </strong>
        {jobName}
      </div>
      <div className="order-item__details--info">
        <strong>Ticket #: </strong>
        {sandTicketNo && sandTicketNo}
      </div>
      <div className="order-item__details--info">
        <strong>Mileage: </strong>
        {mileage && mileage}
      </div>
      <div className="order-item__details--info">
        <strong>Quantity: </strong>
        <span>
          {changeWeightsValue(weight)}
          {Boolean(driverWeight) && (
            <span>/ {changeWeightsValue(driverWeight)}</span>
          )}
        </span>
      </div>
      <div className="order-item__details--info">
        <strong>Carrier: </strong>
        {Boolean(status) &&
          Boolean(driverId) &&
          `#${carrierId} | ${carrierName || ''}`}
      </div>
      <div className="order-item__details--info" key="driver_id">
        <strong>Driver: </strong>
        {Boolean(driverId) && `#${driverId} | ${driverName || ''}`}
      </div>
      <Authorize forbidden={['CARRIER', 'CUSTOMER']}>
        <div className="order-item__details--info" key="driver_contact_number">
          <strong>Driver number: </strong>
          {Boolean(driverId) && driverContactNumber}
        </div>
      </Authorize>
      <div className="order-item__details--info" key="truck">
        <strong>Truck: </strong>
        {Boolean(driverId) && truck && `#${truck}`}
      </div>
      {stagingEnterTime ? (
        <div className="order-item__details--info">
          <strong>Stage arr: </strong>
          {convertTime(stagingEnterTime)}
        </div>
      ) : (
        <div className="order-item__details--info">
          <strong>Load appt: </strong>
          {convertedExpectedPickupTime !== 'Invalid date' &&
            convertedExpectedPickupTime}
        </div>
      )}
      <div className="order-item__details--info">
        <strong>Unload appt: </strong>
        {convertedUnloadApptTime !== 'Invalid date' && convertedUnloadApptTime}
      </div>
      <div className="order-item__details--info">
        <span
          data-bs-toggle="tooltip"
          title={etaTimeFromNow !== 'Invalid date' ? etaTimeFromNow : ''}>
          <strong>ETA: </strong>
          {etaTimeFromNow !== 'Invalid date' && convertedEta}
        </span>
      </div>
      <div className="order-item__details--info">
        <strong>Destination arr.: </strong>
        {convertedWellArrival !== 'Invalid date' && convertedWellArrival}
      </div>
      {equipment === 3 && (
        <div className="order-item__details--info" key="Container">
          <strong>Container number: </strong>
          {Boolean(driverId) && containerNumber && containerNumber}
        </div>
      )}
    </div>
  );
};

export default OrderContent;
