import React from 'react';
import PropTypes from 'prop-types';
import AutomatizeLogoSVG from '../../../assets/automatize_icon.svg';

const AutomatizeLogo = ({ size, onClick, margin, align }) => (
  <img
    src={AutomatizeLogoSVG}
    alt="Automatize logo icon"
    onClick={onClick}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      verticalAlign: `${align}`,
      margin: `${margin}px`,
    }}
  />
);

AutomatizeLogo.propTypes = {
  size: PropTypes.number,
  onClick: PropTypes.func,
  margin: PropTypes.number,
  align: PropTypes.string,
};

AutomatizeLogo.defaultProps = {
  size: 25,
  onClick: () => {},
  margin: 0,
  align: 'middle',
};

export default AutomatizeLogo;
