import axios from 'axios';
import { order } from 'services/order/service';
import { driver } from 'services/driver/service';
import actionsJobPlans from 'store/actions/JobPlans';
import moment from 'moment';
import authService from 'services/auth/service';
import sandTiket from 'services/sandTicket/service';
import swal from 'bootstrap-sweetalert';
import FileSaver from 'file-saver';
import { ticketsRequested } from 'store/actions/OrderTickets';
import { queryClient } from 'api';

export const ordersRequested = params => async dispatch => {
  dispatch({ type: 'ORDERS_REQUESTED' });

  const orders = await order.get(params).catch(error =>
    // TODO: Add Generic Failed Handler
    dispatch({ type: 'ORDERS_REQUEST_FAILED' }),
  );

  return dispatch({
    orders: orders.data.data.orders,
    totalItems: orders.data.data.count,
    lastParams: params,
    type: 'ORDERS_REQUEST_SUCCEEDED',
  });
};

export const approveOrder = (orderIds, dataLoad = true) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: 'APPROVE_ORDERS_REQUESTED' });
  const state = getState();

  await order.billing
    .approve({ orderIds })
    .then(() => {
      swal('Order(s) approved successfully!', '', 'success');
    })
    .catch(error => {
      // TODO: Add Generic Failed Handler
      swal(`Failed to approve order(s). Reason: ${error.data.message}`);
      return dispatch({ type: 'APPROVE_ORDERS_FAILED' });
    });

  dispatch({ type: 'APPROVE_ORDERS_SUCCEEDED' });
  if (dataLoad) return dispatch(ordersRequested(state.orders.lastParams));
};
const jobPlansApproveOrder = (orderIds, data) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: 'APPROVE_ORDERS_REQUESTED' });

  await order.billing
    .approve({ orderIds })
    .then(() => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      swal('Order(s) approved successfully!', '', 'success');
    })
    .catch(error => {
      swal(`Failed to approve order(s). Reason: ${error.data.message}`);
      return dispatch({ type: 'APPROVE_ORDERS_FAILED' });
    });

  dispatch({ type: 'APPROVE_ORDERS_SUCCEEDED' });

  dispatch(actionsJobPlans.getBillableOrders(data));
};

export const contestOrder = (
  orderIds,
  comment,
  disputeReasonId,
  updateInit,
) => async (dispatch, getState) => {
  dispatch({ type: 'CONTEST_ORDERS_REQUESTED' });
  const state = getState();
  await order.billing
    .dispute({ orderIds, comment, disputeReasonId })
    .then(() => {
      swal('Order(s) contested successfully!', '', 'success');
    })
    .catch(error => {
      // TODO: Add Generic Failed Handler
      swal(`Failed to contest order(s). Reason: ${error.data.message}`);
      return dispatch({ type: 'CONTEST_ORDERS_FAILED' });
    });

  dispatch({ type: 'CONTEST_ORDERS_SUCCEEDED' });
  if (updateInit) {
    return updateInit();
  }

  return dispatch(ordersRequested(state.orders.lastParams));
};

export const jobPlansContestOrder = (
  orderIds,
  comment,
  disputeReasonId,
  data,
) => async (dispatch, getState) => {
  dispatch({ type: 'CONTEST_ORDERS_REQUESTED' });
  const state = getState();
  await order.billing
    .dispute({ orderIds, comment, disputeReasonId })
    .then(() => {
      swal('Order(s) contested successfully!', '', 'success');
    })
    .catch(error => {
      // TODO: Add Generic Failed Handler
      swal(`Failed to contest order(s). Reason: ${error.data.message}`);
      return dispatch({ type: 'CONTEST_ORDERS_FAILED' });
    });

  dispatch({ type: 'CONTEST_ORDERS_SUCCEEDED' });
  dispatch(actionsJobPlans.getBillableOrders(data));
};

export const getDisputeReasons = () => async dispatch => {
  dispatch({ type: 'ORDER_DISPUTE_REASONS_REQUESTED' });

  const disputeReasons = await order.billing
    .getDisputeReasons()
    .catch(error => dispatch({ type: 'ORDER_DISPUTE_REASONS_SUCCEEDED' }));

  dispatch({
    type: 'ORDER_DISPUTE_REASONS_FAILED',
  });
  return disputeReasons;
};

export const createInvoiceFromOrders = (
  orderIds,
  customerId,
  dueDate,
  enableRemitTo,
  groupOrdersBy,
  templateId,
  usedOutsideReconcile,
) => async (dispatch, getState) => {
  dispatch({ type: 'CREATE_INVOICE_REQUESTED' });
  const state = getState();
  // eslint-disable-next-line no-underscore-dangle
  axios.defaults.baseURL = window._env_.SERVERLESS_API_URL;
  await order.billing
    .createInvoiceFromOrders({
      orders: orderIds,
      customerId,
      dueDate,
      enableRemitTo,
      groupOrdersBy,
      templateId,
    })
    .then(() => {
      // eslint-disable-next-line no-underscore-dangle
      axios.defaults.baseURL = window._env_.API_BASE_URL;
      swal(
        'Your invoice(s) are being created. You will be notified when they are complete.',
        '',
        'success',
      );
    })
    .catch(error => {
      axios.defaults.baseURL = window._env_.API_BASE_URL;
      if (
        error.response &&
        error.response.status &&
        error.response.status !== 504
      ) {
        const errorMessage = error.response
          ? error.response.data
            ? error.response.data.message
            : 'Something went wrong.'
          : 'Something went wrong.';
        swal(
          `There was an error creating your invoice: ${errorMessage}`,
          '',
          'error',
        );
      }
      return dispatch({ type: 'CREATE_INVOICE_SUCCEEDED' });
    });
  dispatch({ type: 'CREATE_INVOICE_FAILED' });
  if (usedOutsideReconcile) {
    return;
  }
  return dispatch(ordersRequested(state.orders.lastParams));
};

export const getComments = (orderId, callback = null) => dispatch => {
  dispatch({ type: 'ORDER_GET_COMMENTS_REQUESTED' });
  order
    .getComments(orderId)
    .then(response => {
      dispatch({
        type: 'ORDER_GET_COMMENTS_SUCCESS',
        comments: response.data.data.comments,
      });
      if (callback) {
        callback();
      }
    })
    .catch(error => {
      swal('Unable to load activity.', '', 'error');
      dispatch({ type: 'ORDER_GET_COMMENTS_FAILED' });
    });
};

export const addOrderComment = (orderId, comment) => async dispatch => {
  dispatch({ type: 'ADD_COMMENT_REQUESTED' });
  if (comment.trim().length > 0) {
    await order.postComment(orderId, comment).catch(error => {
      // TODO: Add Generic Failed Handler
      swal('Unable to post message.', '', 'error');
      return dispatch({ type: 'ADD_COMMENT_FAILED' });
    });

    dispatch({
      type: 'ADD_COMMENT_SUCCEEDED',
    });

    return dispatch(getComments(orderId));
  }
};

export const getPriceBreakdown = orderId => async dispatch => {
  dispatch({ type: 'ORDER_PRICE_BREAKDOWN_REQUESTED' });

  const priceBreakdown = await order
    .priceBreakdownV2(orderId)
    .catch(error => dispatch({ type: 'ORDER_PRICE_BREAKDOWN_FAILED' }));
  return dispatch({
    type: 'ORDER_PRICE_BREAKDOWN_SUCCEEDED',
    priceBreakdown: priceBreakdown.data.data,
  });
};

export const downloadInvoicePdf = orderId => async dispatch => {
  dispatch({ type: 'DOWNLOAD_INVOICE_PDF_REQUESTED' });
  order
    .downloadInvoicePdf(orderId)
    .then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(file, `order-${orderId}.pdf`);
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
      dispatch({ type: 'DOWNLOAD_INVOICE_PDF_FAILED' });
    });

  dispatch({
    type: 'DOWNLOAD_INVOICE_PDF_SUCCEEDED',
  });
};

export const addPrice = (orderId, price, comment, close) => async dispatch => {
  dispatch({ type: 'ADD_PRICE_REQUESTED' });

  await order
    .addAdditionalPrice({ id: orderId, price: Number(price), comment })
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => dispatch({ type: 'ADD_PRICE_FAILED' }));

  dispatch({
    type: 'ADD_PRICE_SUCCEEDED',
  });
  dispatch(getPriceBreakdown(orderId));
  close();
};

export const removePrice = (orderId, priceId, close) => async dispatch => {
  dispatch({ type: 'REMOVE_PRICE_REQUESTED' });
  await order
    .deleteAdditionalPrice(orderId, priceId)
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
      return dispatch({ type: 'REMOVE_PRICE_FAILED' });
    });

  dispatch({ type: 'REMOVE_PRICE_SUCCEEDED' });
  dispatch(getPriceBreakdown(orderId));
  close();
};

export const updateOrder = (
  params,
  originalOrder,
  callback = null,
) => async dispatch => {
  dispatch({ type: 'UPDATE_ORDER_REQUESTED' });
  const customerIdChanged =
    params.customerOrderId !== originalOrder.customer_order_id;
  const data = { ...params };
  data.lastUpdatedAt = data.updatedAt;
  const updateTime = time => {
    if (typeof time === 'string') {
      return moment(time.replaceAll('-', '/'))
        .utc()
        .toISOString();
    } if (moment.isMoment(time)) {
      return time.utc().toISOString();
    }
    console.error('Invalid time format');
    return moment()
      .utc()
      .toISOString();
  };

  data.orderAcceptedAt = updateTime(data.orderAcceptedAt);
  data.loadArrivalTime = updateTime(data.loadArrivalTime);
  data.loadDepartureTime = updateTime(data.loadDepartureTime);
  data.wellArrivalTime = updateTime(data.wellArrivalTime);
  data.wellDepartureTime = updateTime(data.wellDepartureTime);
  data.stageArrivalTime = updateTime(data.stageArrivalTime);
  data.stageDepartureTime = updateTime(data.stageDepartureTime);

  if(!data.stageArrivalTime){
    delete data.stageArrivalTime
  }
  if(!data.stageDepartureTime){
    delete data.stageDepartureTime
  }

  if (__checkTime(data, originalOrder)) {
    try {
      order
        .modify({ ...data, mileage: Math.floor(data.mileage).toString() })
        .then(msg => {
          dispatch({ type: 'UPDATE_ORDER_REQUEST_SUCCEEDED' });
          if (customerIdChanged) {
            const text = `Customer Order #'${data.customerOrderId
              }' updated for order #${data.orderId}`;
            driver
              .pushNotification({
                driverId: originalOrder.driver_id,
                message: text,
              })
              .then(() => { });
            const user = authService.getUser();
            const message = {
              senderId: String(user.id),
              senderName: user.name,
              text,
              role: user.role,
              date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            };
          }

          if (callback) {
            callback(data);
          }
        })
        .catch(error => {
          dispatch({ type: 'UPDATE_ORDER_REQUEST_FAILED' });
          return swal(error.response.data.message, '', 'error');
        });
    } catch (err) {
      dispatch({ type: 'UPDATE_ORDER_REQUEST_FAILED' });
      return swal(err.response.data.message, '', 'error');
    }
  } else {
    return dispatch({ type: 'UPDATE_ORDER_REQUEST_FAILED' });
  }
};

export const getHistory = orderId => async dispatch => {
  dispatch({ type: 'ORDER_HISTORY_REQUESTED' });

  await order
    .getHistory(orderId)
    .then(res => {
      dispatch({
        type: 'ORDER_HISTORY_SUCCEEDED',
        history: res.data.data.logs,
      });
    })
    .catch(err => {
      dispatch({ type: 'ORDER_HISTORY_FAILED' });
      swal(err.response.data.message, '', 'error');
    });
};

export const clearHistory = () => dispatch =>
  dispatch({ type: 'ORDER_HISTORY_CLEAR' });

// upload ST actions
const pageLocation = window.location.pathname.split('/')[2];

const setInputValueST = (value, name) => ({
  type: 'ORDER_ACTIONS_UPLOAD_ST_SET_INPUT',
  value,
  name,
});

const selectFileST = e => dispatch => {
  if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    const file = e.target.files[0];
    dispatch(setInputValueST(file.name, 'fileName'));
    reader.addEventListener(
      'load',
      () => dispatch(setInputValueST(reader.result, 'url')),
      false,
    );
    reader.readAsDataURL(file);
  }
};

const generateImg = (url, pixelCrop) => {
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    const img = new Image();
    pixelCrop.width
      ? (img.onload = () => {
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
        resolve();
      })
      : (img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve();
      });
    img.src = url;
  }).then(
    success =>
      new Promise((resolve, reject) => {
        canvas.toBlob(file => {
          file.name = 'sandTiket';
          resolve(file);
        }, 'image/jpeg');
      }),
  );
};

const saveST = order => (dispatch, getState) => {
  const request = () => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_REQUEST',
  });
  const success = () => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS',
  });
  const state = getState();
  const { uploadTicket } = state.orders;
  const { input, init } = uploadTicket;
  const {
    sandTicketNo,
    weight,
    sandTicketType,
    containerIds,
    url,
    pixelCrop,
    crop,
    fileName,
    king,
    compartment,
  } = input;
  const { order_id, driver_id, equipment } = order;
  const size = pixelCrop || crop;
  dispatch(request());

  generateImg(url, size).then(img => {
    const formData = new FormData();
    const file = new File([img], fileName);
    formData.append('orderId', order_id);
    formData.append('driverId', driver_id);
    formData.append('sandTicketNo', sandTicketNo);
    formData.append('weight', weight);
    formData.append('bol', file);
    formData.append('king', king);
    formData.append('compartment', compartment);
    if (sandTicketType) {
      formData.append('sandTicketType', sandTicketType);
    }
    if (equipment == 3) {
      const selectedContainer = containerIds.map(item => item.value);
      if (selectedContainer.length) {
        formData.append('containerIds', `[${selectedContainer}]`);
      }
    }
    sandTiket
      .post(formData)
      .then(msg => {
        swal('Success!', '', 'success');
        // dispatch(success());
        dispatch(ticketsRequested(order_id));
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
        dispatch(request());
      });
  });
};

const initST = data => dispatch => {
  const setInitValue = (name, value) => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_INIT_DATA',
    name,
    value,
  });
  order
    .containers(data.order_id)
    .then(msg =>
      dispatch(setInitValue('containers', msg.data.data.containers)),
    );
  order.getSandTicketTypesDesign(data.order_id).then(msg => {
    const requiredTickets = msg.data.data.sandTicketTypes.filter(
      item => item.isRequired,
    );
    dispatch(setInitValue('sandTicketsTypes', requiredTickets));
  });

  dispatch(setInitValue('containersPerTruck', data.containersPerTruck));
};

const clearStateST = () => ({
  type: 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS',
});

export default {
  ordersRequested,
  approveOrder,
  jobPlansApproveOrder,
  contestOrder,
  getDisputeReasons,
  createInvoiceFromOrders,
  getComments,
  addOrderComment,
  getPriceBreakdown,
  downloadInvoicePdf,
  addPrice,
  removePrice,
  updateOrder,
  clearHistory,
  getHistory,
  // st actions
  selectFileST,
  setInputValueST,
  initST,
  saveST,
  clearStateST,
};

// PRIVATE METHODS

// eslint-disable-next-line no-underscore-dangle
const __checkTime = (data, order) => {
  const isStageSiteOnOrder =  order?.staging_site && order?.staging_stop_type

  if (data.shouldComplete || data.status === 4) {
    const validationDate = (date1, date2) =>
      !(Date.parse(date2) < Date.parse(date1));
    const sandSiteTime = moment(data.loadDepartureTime).diff(
      moment(data.loadArrivalTime),
      'minutes',
    );
    const wellsiteTime = moment(data.wellDepartureTime).diff(
      moment(data.wellArrivalTime),
      'minutes',
    );

    if (
      !validationDate(
        moment(data.createdAt).format('YYYY/MM/DD hh:mm A'),
        data.orderAcceptedAt,
      )
    ) {
      swal(
        `Order Accepted Time must be later than Order Created (${moment(
          data.createdAt,
        ).format('YYYY-MM-DD hh:mm A')})`,
        '',
        'error',
      );
    } else if (!validationDate(data.orderAcceptedAt, data.loadArrivalTime)) {
      swal(
        'Origin Arrival Time must be later than Order Accepted Time',
        '',
        'error',
      );
    } else if (!validationDate(data.loadArrivalTime, data.loadDepartureTime)) {
      swal(
        'Origin Departure Time must be later than Origin Arrival Time',
        '',
        'error',
      );
    } else if (
      isStageSiteOnOrder &&
      !validationDate(data.loadDepartureTime, data.stageArrivalTime)
    ) {
      swal(
        'Stage Arrival Time must be later than Origin Departure Time',
        '',
        'error',
      );
    } else if (
      isStageSiteOnOrder &&
      !validationDate(data.stageArrivalTime, data.stageDepartureTime)
    ) {
      swal(
        'Stage Departure time must be later than Stage Arrival Time',
        '',
        'error',
      );
    } else if (isStageSiteOnOrder && !validationDate(data.stageDepartureTime, data.wellArrivalTime)) {
      swal(
        'Destination Arrival Time must be later than Stage Departure Time',
        '',
        'error',
      );
    } else if (!validationDate(data.wellArrivalTime, data.wellDepartureTime)) {
      swal(
        'Destination Departure time must be later than Destination Arrival Time',
        '',
        'error',
      );
    } else if (sandSiteTime < 5) {
      swal('Time at origin must be more than 4 minutes', '', 'error');
    } else if (wellsiteTime < 16) {
      swal('Time at destination must be more than 15 minutes', '', 'error');
    } else if (!validationDate(data.loadDepartureTime, data.wellArrivalTime)) {
      swal(
        `Origin Departure Time must be earlier than Destination Arrival Time`,
        '',
        'error',
      );
    } else {
      return true;
    }
    return false;
  }
  return true;
};
