const initState = {
  pagination: {
    currentPage: 1,
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'PAGINATION_CLEAR_STATE':
      return initState;
    case 'PAGINATION_SET_PAGE':
      return {
        ...state,
        pagination: {
          currentPage: action.value,
        },
      };
    default:
      return state;
  }
};
