import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../ToolTip';
import Button from '../Button';
import Icon from '../Icon';

const SlidePanel = ({ children, closePanel, isVisible, className, width }) => (
  <div
    className={`slide-panel ${
      isVisible ? 'slide-panel--visible' : ''
    } ${className}`}
    style={{ width }}>
    <div className="slide-panel__content">{children}</div>
    <span className="slide-panel__close">
      <ToolTip title="Close Panel">
        <Button
          theme="small"
          onClick={closePanel}
          className="button--small--square">
          <Icon icon="right" />
        </Button>
      </ToolTip>
    </span>
  </div>
);

SlidePanel.propTypes = {
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  /** closePanel */
  closePanel: PropTypes.func.isRequired,
  /** isVisible */
  isVisible: PropTypes.bool,
};

SlidePanel.defaultProps = {
  isVisible: false,
};

export default SlidePanel;
