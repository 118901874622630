import { connect } from 'react-redux';
import { ordersRequested } from 'store/actions/Orders';
import { fullDistrictsListRequested } from 'store/actions/Districts';
import ReconcileOrderStatus from './ReconcileOrderStatus';

const mapStateToProps = state => ({
  isLoading: state.orders.apiIsLoading,
  districts: state.districts.fullDistrictsList.fullDistrictsList,
});

const mapDispatchToProps = dispatch => ({
  getOrders: params => dispatch(ordersRequested(params)),
  getDistricts: () => dispatch(fullDistrictsListRequested()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconcileOrderStatus);
