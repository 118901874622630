import JobDetails from 'components/views/JobDetails';
import DriversList from 'components/views/DriversList';
import TrackDrivers from 'components/views/TrackDrivers';
import Database from 'components/views/ManageDatabase';
import Locations from 'components/views/Locations';
// import Chat from 'components/views/Chat';
import ScanQr from 'components/views/ScanQR';
import Queue from 'components/views/Queue';

export default [
  {
    title: 'CURRENT JOB',
    path: '/job-details',
    icon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" class="icon--fill"><path d="M10.047,4.603h-4.85C4.537,4.603,4,5.14,4,5.8v4.246c0,0.661,0.536,1.197,1.197,1.197h4.85 c0.661,0,1.198-0.536,1.198-1.197V5.8C11.244,5.14,10.708,4.603,10.047,4.603z M18.618,4.603H13.77 c-0.662,0-1.199,0.536-1.199,1.197v4.246c0,0.661,0.537,1.197,1.199,1.197h4.851c0.661,0,1.196-0.536,1.196-1.197V5.8 C19.816,5.14,19.281,4.603,18.618,4.603L18.618,4.603z M10.047,12.572h-4.85C4.537,12.572,4,13.107,4,13.77v4.246 c0,0.66,0.536,1.196,1.197,1.196h4.85c0.661,0,1.198-0.536,1.198-1.196v-4.248C11.244,13.107,10.707,12.572,10.047,12.572z M18.618,12.572H13.77c-0.662,0-1.199,0.535-1.199,1.197v4.246c0,0.66,0.537,1.197,1.199,1.197h4.851 c0.661,0,1.196-0.537,1.196-1.197V13.77C19.816,13.107,19.28,12.572,18.618,12.572L18.618,12.572z"/></svg>',
    component: JobDetails,
    children: [],
  },
  {
    title: 'MANAGE',
    path: '/manage',
    icon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16 4.75c0-.45-.2-.75-.5-.75h-11c-.3 0-.5.3-.5.75v1.5c0 .45.2.75.5.75h11c.3 0 .5-.3.5-.75v-1.5zM20 11.75c0-.45-.267-.75-.667-.75H4.667c-.4 0-.667.3-.667.75v1.5c0 .45.267.75.667.75h14.666c.4 0 .667-.3.667-.75v-1.5zM12 18.75c0-.45-.133-.75-.333-.75H4.333c-.2 0-.333.3-.333.75v1.5c0 .45.133.75.333.75h7.334c.2 0 .333-.3.333-.75v-1.5z"/></svg>',
    downIcon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Database',
        path: '/database',
        component: Database,
        children: [],
      },
      {
        title: 'Locations',
        path: '/locations',
        component: Locations,
        children: [],
      },
    ],
  },
  // {
  //   title: 'CHAT',
  //   path: '/chat',
  //   icon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M17.65 6.34a8.003 8.003 0 0 0-11.31 0 8 8 0 0 0-.335 10.95l.08.067c-.303.652-.822 1.457-1.647 1.863-.273.133-.21.539.09.586.901.14 2.195-.017 3.4-.928l.016.013a7.998 7.998 0 0 0 9.703-1.244A7.996 7.996 0 0 0 17.65 6.34z"/></svg>',
  //   component: Chat,
  //   children: [],
  // },
  {
    title: 'QUEUE',
    path: '/queue',
    icon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M6.462 13.035h6.787a.55.55 0 0 0 .55-.55V6.557a.55.55 0 0 0-.55-.549h-2.055a.55.55 0 0 0-.55.55v.503c0 .1-.081.183-.182.183H9.25a.183.183 0 0 1-.183-.183v-.504a.55.55 0 0 0-.55-.549H6.462a.55.55 0 0 0-.55.55v5.927c0 .303.247.55.55.55zM9.856 8.74l1.194 1.347h-.623v1.679H9.285v-1.679H8.66L9.856 8.74zM15.397 10.199h2.013v.417c0 .172.118.262.235.262.043 0 .109-.012.178-.07l1.472-1.242a.307.307 0 0 0 .11-.234c0-.09-.04-.175-.11-.235l-1.472-1.241a.275.275 0 0 0-.179-.07c-.116 0-.234.09-.234.262v.417h-2.013a.33.33 0 0 0-.33.33v1.074c0 .182.148.33.33.33zM17.819 14.516H6.146A2.149 2.149 0 0 0 4 16.662c0 1.183.963 2.146 2.146 2.146H17.82a2.149 2.149 0 0 0 2.146-2.146 2.149 2.149 0 0 0-2.146-2.146zM6.573 17.76a1.098 1.098 0 1 1 0-2.196 1.098 1.098 0 0 1 0 2.196zm3.606 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197zm3.607 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197zm3.606 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197z"/></svg>',
    component: Queue,
    children: [],
  },
  {
    title: 'DRIVERS',
    icon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M19.109 10.588a14.168 14.168 0 0 1-.071-.092c-.282-.371-.51-.789-.71-1.158-.197-.36-.383-.702-.586-.938-.453-.527-.88-.783-1.304-.783h-2.093c-.002 0-.202.003-.443.003h-.341a.534.534 0 0 0-.518.403l-.003.01v4.623l.001.01c0 .002.005.069-.047.125-.057.063-.168.097-.32.097H4.52c-.287 0-.52.22-.52.49v1.682c0 .27.233.49.52.49h1.791a2.103 2.103 0 0 0 2.093 1.916 2.103 2.103 0 0 0 2.092-1.916h2.997a2.103 2.103 0 0 0 2.092 1.916 2.103 2.103 0 0 0 2.092-1.916h1.363c.516 0 .936-.42.936-.937v-1.607c0-1.293-.522-1.97-.867-2.418zm-2.74 4.777a.784.784 0 0 1-1.567 0 .784.784 0 0 1 1.567 0zm1.8-4.077c-.016.027-.062.039-.133.034h-3.6V8.387h1.557c.418 0 .715.182 1.024.629.205.297.748 1.232.776 1.28l.06.114c.156.3.393.752.317.878zm-9.765 3.294a.784.784 0 0 1 0 1.566.784.784 0 0 1 0-1.566z"/><path d="M5.097 12.143h2.467c.25 0 .454-.203.454-.453V9.875a.454.454 0 0 0-.454-.453H5.097a.454.454 0 0 0-.454.453v1.815c0 .25.204.453.454.453zM9.197 12.143h2.467c.25 0 .453-.203.453-.453V9.875a.454.454 0 0 0-.453-.453H9.197a.454.454 0 0 0-.454.453v1.815c0 .25.204.453.454.453zM5.097 8.733h2.467c.25 0 .454-.204.454-.454V6.465a.454.454 0 0 0-.454-.453H5.097a.454.454 0 0 0-.454.453v1.814c0 .25.204.454.454.454zM9.197 8.733h2.467c.25 0 .453-.204.453-.454V6.465a.454.454 0 0 0-.453-.453H9.197a.454.454 0 0 0-.454.453v1.814c0 .25.204.454.454.454z"/></svg>',
    path: '/drivers',
    downIcon: '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Driver details',
        path: '/driver-details',
        component: DriversList,
        children: [],
      },
      {
        title: 'Track drivers',
        path: '/track-drivers',
        component: TrackDrivers,
        children: [],
      },
    ],
  },
  {
    title: 'SCAN QR',
    path: '/scan-qr',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" baseProfile="tiny" id="Layer_1" class="icon--fill" viewBox="0 0 24 24"><path d="M8,21H4a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H8a1,1,0,0,0,0-2Zm14-6a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H16a1,1,0,0,0,0,2h4a3,3,0,0,0,3-3V16A1,1,0,0,0,22,15ZM20,1H16a1,1,0,0,0,0,2h4a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V4A3,3,0,0,0,20,1ZM2,9A1,1,0,0,0,3,8V4A1,1,0,0,1,4,3H8A1,1,0,0,0,8,1H4A3,3,0,0,0,1,4V8A1,1,0,0,0,2,9Zm8-4H6A1,1,0,0,0,5,6v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V6A1,1,0,0,0,10,5ZM9,9H7V7H9Zm5,2h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v4A1,1,0,0,0,14,11Zm1-4h2V9H15Zm-5,6H6a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,17H7V15H9Zm5-1a1,1,0,0,0,1-1,1,1,0,0,0,0-2H14a1,1,0,0,0-1,1v1A1,1,0,0,0,14,16Zm4-3a1,1,0,0,0-1,1v3a1,1,0,0,0,0,2h1a1,1,0,0,0,1-1V14A1,1,0,0,0,18,13Zm-4,4a1,1,0,1,0,1,1A1,1,0,0,0,14,17Z"/></svg>',
    component: ScanQr,
    children: [],
  },
];
