import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  ticketPreviewRequested,
  ticketDownloadSingleTicket,
  ticketsRequested,
  ticketRemovalRequested,
} from 'store/actions/OrderTickets';

import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import { deleteAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import FileViewer from 'components/shared/FileViewer';
import actions from 'store/actions/JobPlans';

import OrderInfoTicketEditModal from './OrderInfoTicketEditModal';

const OrderInfoTicket = ({
  ticket,
  order,
  config,
  getTicketPreview,
  downloadSingleTicket,
  downloadLoading,
  getTickets,
  removeTicket,
  getOrderTickets,
  jobPlansdata
}) => {
  const [image, setimage] = useState(null);
  const [visible, setVisible] = useState(true);

  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  useEffect(() => {
    if (visible && !image) {
      getTicketPreview().then(data => setimage(data));
    }
  }, [visible]);

  const configTicket = (config || []).find(c => c.ticketType === ticket.ticketType);

  const setTicketInfo = ()=>{
    getTickets();
    getOrderTickets();
  };

  const handleDelete = (order, jobPlansdata) => {
    if(jobPlansdata){
      deleteAlert(() => removeTicket(order.order_id, ticket.id, setTicketInfo));
    }else{
      deleteAlert(() => removeTicket(order.order_id, ticket.id, getTickets));
    }
  };

  return (
    <>
      <div className="ticket">
        <div className="ticket__header">
          <span className="ticket__header__text">
            {configTicket ? configTicket.ticketName : 'Unknown'}:
            <span className="white"> #{ticket.ticketNumber}</span>
          </span>
          <div className="ticket__header__buttons">
            <ToolTip title="Preview">
              <Button
                theme="small"
                className="button--small--square"
                testSelector="reconcile_order-details_info-ticket_preview_btn"
                onClick={() => setVisible(!visible)}
                inverse={visible}
                disabled={ticket.isDeleted === 1}>
                <Icon icon="preview" />
              </Button>
            </ToolTip>
            {/* TODO add back when API exists*/}
            {/*<ToolTip title="Email">*/}
              {/*<Button*/}
                {/*theme="small"*/}
                {/*className="button--small--square"*/}
                {/* testSelector="reconcile_order-details_info-ticket_email_btn" */}
                {/*onClick={() => {}}*/}
                {/*disabled*/}
              {/*>*/}
                {/*<Icon icon="email" />*/}
              {/*</Button>*/}
            {/*</ToolTip>*/}
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Orders',
                    permissions: ['update']
                  }
                ]
              }}
            >
              <ToolTip title="Edit">
                <Button
                  theme="small"
                  className="button--small--square"
                  testSelector="reconcile_order-details_info-ticket_edit_btn"
                  onClick={() => setEditVisible(true)}
                  inverse={editVisible}
                  disabled={ticket.isDeleted === 1}>
                  <Icon icon="edit" />
                </Button>
              </ToolTip>
            </Authorize>
            <ToolTip title="Download">
              <Button
                theme="small"
                className="button--small--square"
                testSelector="reconcile_order-details_info-ticket_download_btn"
                onClick={() => downloadSingleTicket(order.order_id, ticket.id)}
                disabled={ticket.isDeleted === 1 || downloadLoading}>
                {(downloadLoading && <Loader />) || <Icon icon="download" />}
              </Button>
            </ToolTip>
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Orders',
                    permissions: ['update']
                  }
                ]
              }}
            >
              <ToolTip title="Delete">
                <Button
                  theme="small"
                  className="button--small--square"
                  testSelector="reconcile_order-details_info-ticket_delete_btn"
                  onClick={() => handleDelete(order, jobPlansdata)}
                  inverse={deleteVisible}
                  disabled={ticket.isDeleted === 1}>
                  <Icon icon="trash" />
                </Button>
              </ToolTip>
            </Authorize>
          </div>
        </div>
        {visible && (
          <div className="ticket__preview">
            {!image ? (
              <Loader />
            ) : (
              <FileViewer
                image={image}
                title={`${
                  configTicket ? configTicket.ticketName : 'Unknown'
                }: #${ticket.ticketNumber}`}
              />
            )}
          </div>
        )}
      </div>
      {ticket.isDeleted !== 1 && (
        <>
          <OrderInfoTicketEditModal
            ticket={ticket}
            order={order}
            open={editVisible}
            onClose={() => setEditVisible(false)}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  orderTickets: { config, downloadSingleTicket },
}) => ({ config, downloadLoading: downloadSingleTicket.apiIsLoading });

const mapDispatchToProps = (dispatch, { ticket, order }) => ({
  getTicketPreview: () =>
    dispatch(ticketPreviewRequested(order.order_id, ticket.id)),
  downloadSingleTicket: (orderId, ticketId) =>
    dispatch(ticketDownloadSingleTicket(orderId, ticketId)),
  removeTicket: (orderId, ticketId, callback) =>
    dispatch(ticketRemovalRequested(orderId, ticketId, callback)),
  jobPlansjobPlansRemoveTicket: (orderId, ticketId, data, callback) =>
    dispatch(jobPlansTicketRemovalRequested(orderId, ticketId, data, callback)),
  getTickets: () => dispatch(ticketsRequested(order.order_id)),
  getOrderTickets: () => dispatch(actions.getOrderTickets(order.order_id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderInfoTicket);
