const init = {
  sending: false,
  message: '',
};

export default (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_SEND_NOTIFICATION_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_DETAILS_SEND_NOTIFICATION_CLEAR':
      return init;
    default:
      return state;
  }
};
