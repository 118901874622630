const initialState = {
  searchData: {},
  isLoaded: false,
  pathname: '',
  query: '',
  itemsPerPage: 10
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_RESULT_GET_DATA_REQUEST':
      return {
        ...state
      };
    case 'SEARCH_RESULT_GET_DATA_SUCCESS':
      return {
        ...state,
        searchData: action.data,
        isLoaded: true,
        pathname: action.pathname
      };
    case 'SEARCH_RESULT_GET_DATA_FAILURE':
      return {
        ...state,
        searchData: {}
      };
    case 'SEARCH_RESULT_SET_QUERY':
      return {
        ...state,
        query: action.query
      };
    case 'SEARCH_RESULT_CLEAR_STATE':
      return initialState;
    default:
      return state;
  }
};

export default search;
