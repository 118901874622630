import React, { useState, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';

import { useCustomers } from 'api/v2/customers';
import { useLocations } from 'api/v2/locations';

import targetValue from 'utils/targetValue';
import AdminCustomerHeader from './AdminCustomerHeader';
import CustomerList from './CustomerList';
import WellSiteList from './WellsiteList';

const ManageCustomers = () => {
  const [selectedCategory, setSelectedCategory] = useState(true);
  const { data: customerList, isLoading: isCustomersLoading } = useCustomers();
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ type: 1 });
  const [filterName, setFilterName] = useState('');

  const filteredCustomers = useMemo(() =>
    customerList.filter(
      customer => customer.name.toLowerCase().includes(filterName.toLowerCase())
    ),
    [customerList, filterName]
  );

  const filteredLocations = useMemo(() =>
    locations.filter(
      location => location.name.toLowerCase().includes(filterName.toLowerCase())),
    [locations, filterName]
  );
  const isLoaded = !isCustomersLoading && !isLocationsLoading && customerList && locations;

  return (
    <div>
      <AdminCustomerHeader customers={filteredCustomers} />
      <Authorize
        abilityPermissions={[{ resource: 'Customers', permissions: ['read'] }]}>
        <Card>
          <Card.Header>
            <Row>
              <Col md={3} className="form-group has-feedback">
                <Input
                  onChange={e => setFilterName(targetValue(e))}
                  label="Search name"
                  testSelector="admin-customers_manage_search-name_input"
                  value={filterName}
                />
              </Col>
              <Col md={9}>
                <span className="text-label float-end">
                  All customers
                  <Icon
                    onClick={() => {
                      setSelectedCategory(s => !s);
                    }}
                    data-testid="wellsite-toggle"
                    icon={
                      selectedCategory
                        ? 'toggle-off inactive'
                        : 'toggle-on active'
                    }
                  />
                  All Locations
                </span>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {selectedCategory ? (
              isLoaded && (
                <CustomerList
                  customers={filteredCustomers}
                  wellSites={filteredLocations}
                />
              )
            ) : (
              <Authorize
                {...{
                  abilityPermissions: [
                    {
                      resource: 'Locations',
                      permissions: ['read'],
                    },
                  ],
                }}>
                <WellSiteList customers={filteredCustomers} wellSites={filteredLocations} />
              </Authorize>
            )}
          </Card.Body>
        </Card>
      </Authorize>
    </div>
  );
};

export default ManageCustomers;
