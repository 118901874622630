import React from 'react';
import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';

import Input from 'components/shared/Input';
import Button from 'components/shared/Button';

const ViewSwitcher = ({
  view,
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => (
  <div className="row">
    <Button
      onClick={() => onViewModeChange(ViewMode.Day)}
      inverse={view === ViewMode.Day}>
      Day
    </Button>
    <Button
      onClick={() => onViewModeChange(ViewMode.Week)}
      inverse={view === ViewMode.Week}>
      Week
    </Button>
    <Button
      onClick={() => onViewModeChange(ViewMode.Month)}
      inverse={view === ViewMode.Month}>
      Month
    </Button>
    <Button
      onClick={() => onViewModeChange(ViewMode.Year)}
      inverse={view === ViewMode.Year}>
      Year
    </Button>
    <Input
      type="checkbox"
      label="Show Headers"
      defaultChecked={isChecked}
      onChange={() => onViewListChange(!isChecked)}
      testSelector="customer-details_calendar_view-show_headers"
    />
  </div>
);
export default ViewSwitcher;
