import { useMemo, useState } from 'react';

export const useInsuranceModal = () => {
  const [showModalInsurance, setShowModalInsurance] = useState(false);
  const [insureDoc, setInsureDoc] = useState();

  const handlers = useMemo(
    () => ({
      openInsurance: item => {
        setShowModalInsurance(true);
        setInsureDoc(item);
      },
      closeInsurance: () => {
        setShowModalInsurance(false);
      },
    }),
    [],
  );

  return [{ showModalInsurance, insureDoc }, handlers];
};
