/* eslint-disable no-underscore-dangle */
import { client } from 'api/client';
import { aws } from 'services/aws/service';

const getSignedUrl = async () => {
  const { data } = await client.get('v2/documents/url');
  return {
    url: data.data.uploadUrl,
    documentId: data.data.id,
  };
};

const finishUpload = async documentId => {
  const { data } = await client.post(`v2/documents/${documentId}/verify`);
  return data;
};

/**
 *
 * @param {*} file
 * @returns {Promise<string>}
 */
export const uploadDocument = async file => {
  const { url, documentId } = await getSignedUrl();
  await aws.uploadToS3(file, url);
  await finishUpload(documentId);

  const apiBase = `${window._env_.API_BASE_URL}${
    window._env_.API_BASE_URL.endsWith('/') ? '' : '/'
  }`;
  return `${apiBase}v2/documents/${documentId}`;
};
