import React from 'react';
import { GoogleApiWrapper } from 'google-maps-react';

import GoogleMap from './GoogleMap';
import AutomatizeMarkerComponent from './AutomatizeMarker';
import { GoogleMapProvider } from './GoogleMapContext';

const MapController = ({ fitMarkers, ...props }) => (
    <GoogleMapProvider fitMarkers={props.fitMarkers}>
      <GoogleMap {...props} />
    </GoogleMapProvider>
  );

MapController.defaultProps = {
  initialCenter: {
    lat: 32.768799,
    lng: -97.309341,
  },
  fitMarkers: false,
  onReady: null,
  onMapUpdate: null,
};

export const AutomatizeMarker = AutomatizeMarkerComponent;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyApTE5fGGyJCPs2XaO6uEcEhTVrI9kJlQA',
})(MapController);

export const toRadians = angle => angle * (Math.PI / 180);
export const milesToMeters = miles => 1609.344 * miles;

export const polyCoords = (radius, sides, lat, lng) => {
  // radius in meters, creates coords for circle
  const coords = [];
 
  const angle = (2 * Math.PI) / sides;
  for (let i = 0; i < sides; i++) {
    const y =
      Number.parseFloat(radius * Math.sin(i * angle)).toFixed(15) / 111111 +
      lat;
    const x =
      Number.parseFloat(radius * Math.cos(i * angle)).toFixed(15) /
        (111111 * Math.cos(toRadians(lat))) +
      lng;
    coords.push({ lat: y, lng: x });
  }

  return coords;
};