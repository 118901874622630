import React, { useState } from 'react';
import { Checkbox, CheckboxGroup } from 'components/shared/Input';
import Icon from 'components/shared/Icon';
import AddEditRoleCheckboxes from 'components/views/Roles/AddEditRoleCheckboxes';

const AddEditRoleRow = ({ permData, checkboxUpdate, editRole }) => {
  const [expandRow, setExpandRow] = useState(false);
  const { pid, permission, grants } = permData;

  return (
    <CheckboxGroup
      parentId={`addRole${pid}`}
      onChange={data => checkboxUpdate(data, permData)}>
      <div
        className={`add-edit-role-row expanded-row--${
          expandRow ? 'visible' : 'hidden'
        }`}>
        <Icon
          icon="down"
          className={`add-edit-role-row__arrow add-edit-role-row__arrow--${
            expandRow ? `open` : `hidden`
          }`}
          onClick={() => setExpandRow(!expandRow)}
          data-testid={`add-edit-role-arrow-${pid}`}
        />
        <div className="add-edit-role-row__parent-checkbox">
          <Checkbox
            noMargin
            id={`addRole${pid}`}
            testSelector={`add-edit-role-checkbox-${pid}`}
          />
        </div>
        <div
          className="add-edit-role-row__permission-title"
          onClick={() => setExpandRow(!expandRow)}
          data-testid={`add-edit-role-title-${pid}`}>
          {permission}
        </div>

        <div
          className={`add-edit-role-row__checkboxes-container add-edit-role-row__checkboxes-container--${
            expandRow ? 'visible' : 'hidden'
          }`}>
          <AddEditRoleCheckboxes
            permData={permData}
            grants={grants}
            editRole={editRole}
          />
        </div>
      </div>
    </CheckboxGroup>
  );
};

export default AddEditRoleRow;
