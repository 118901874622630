import { client } from 'api/client';
import { priceTypes } from 'components/globalInfo/priceTypes';
import FileSaver from 'file-saver';

export const getOrders = async ({ params, page, pageSize }) => {
  const { data } = await client.post('v2/order/search', {
    ...params,
    page,
    itemsPerPage: pageSize,
  });
  return data.data;
};

/**
 * @typedef {{
 *   info: any;
 *   commodityInfo: any;
 *   details: any;
 *   sandTicketIds: any[];
 *   transfers: any[];
 * }} OrderResponse
 */

/**
 *
 * @param {number} orderId
 * @returns {Promise<OrderResponse>}
 */
export const getOrder = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}`);
  return data.data;
};

/**
 * @typedef {{
 *  purchaseOrderId: number;
 *  originId: number;
 *  destinationId: number;
 *  sandTypeId: number;
 *  stage: string;
 *  unloadAppointment: string;
 *  loadAppointment: string;
 *  customerId: number;
 *  autoAssign: boolean;
 *  driverId: number;
 *  manualDispatchEnabled: boolean;
 *  stagingSite?: number;
 * }} AddOrderInfo
 */

/**
 *
 * @param {{
 *  jobId: number;
 *  orderInfo: AddOrderInfo
 * }} params
 */
export const addOrder = async ({ jobId, orderInfo }) => {
  const { data } = await client.post(`v2/order`, {
    jobId,
    ...orderInfo,
  });
  return data;
};

export const modifyOrder = async ({ orderId, orderInfo }) => {
  const { data } = await client.put(`/v2/order/${orderId}`, orderInfo);
  return data;
};

/**
 *
 * @param {number} orderId
 * @returns {Promise<{ tickets: any[], config: any[] }>}
 */
export const getOrderTickets = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/tickets`);
  const tickets = data.data?.tickets || [];
  const config = data.data?.config || [];
  const filteredTickets = tickets.filter(ot => !ot.isDeleted);
  const formattedTickets = {
    tickets: filteredTickets,
    config,
  };

  return formattedTickets;
};

export const getDemurrageReasons = async () => {
  const { data } = await client.get(`v2/job-manager/demurrage-reason`);
  return data.data.demurageReasons;
};

export const saveDemurrageReason = async ({ orderId, reason }) => {
  const { data } = await client.put(
    `v2/order/${orderId}/demurrage-reason`,
    reason,
  );
  return data.data;
};

export const getOrderPrices = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/price-breakdown`);
  const { price, total } = data.data;
  return {
    total,
    prices: price.map(p => ({ ...p, title: priceTypes[p.type].title })),
  };
};

export const getOrderComments = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/comment`);
  return data.data.comments;
};

export const addOrderComment = async ({ orderId, comment }) => {
  const { data } = await client.post(`v2/order/${orderId}/comment`, {
    comment,
  });
  return data.data;
};

export const getOrderContainers = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/containers`);
  return data.data.containers;
};

export const getOrderTicketTypes = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/sand-ticket-types`);
  const types = data.data.sandTicketTypes;
  types.unshift({
    isRequired: 0,
    name: 'Unknown',
    sandTicketTypeId: 0,
  });
  return types;
};

/**
 *
 * @param {number} orderId
 * @returns {Promise<{
 *   price: any[];
 *   total: {
 *     total: number;
 *     carrierTotal: number;
 *   };
 * }>}
 */
export const getOrderPriceBreakdown = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/price-breakdown`);
  return data.data;
};

export const getOrderBreadcrumbs = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/bread-crumb`);
  return data.data;
}

/**
 *
 * @param {number} orderId
 */
export const downloadInvoicePdf = async orderId => {
  const { data } = await client.post(
    `v2/order/${orderId}/invoice.pdf`,
    {},
    {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'arraybuffer',
    },
  );
  const file = new Blob([data], { type: 'application/pdf' });
  FileSaver.saveAs(file, `order-${orderId}.pdf`);
};

export const getJobsForDiversion = async params => {
  const { data } = await client.get(
    `v2/order/${params.orderId}/jobs-for-diversion`,
    { params },
  );
  return data.data.jobs;
};

/**
 * @param {{
 *  orderId: number;
 * }} data
 */
export const divert = async data => {
  const response = await client.put(
    `v2/order/${data.orderId}/divert-order`,
    data,
  );
  return response.data.data;
};
