import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import initOrders from 'store/actions/OrdersList';
import { stageDesign } from 'services/stageDesign/service';
import initActions from './JobDetails';

const turnAddStageDesign = () => (dispatch, getState) => {
  const turn = () => ({
    type: 'JOB_DETAILS_STAGE_DESIGN_ADD_TURN',
  });
  const state = getState();
  const stageConfig = state.jobDetails.details.stageDesignConfig;
  stageConfig
    ? dispatch(turn())
    : swal('Create a PO before adding a stage design', '', 'error');
};

const removeStage = item => (dispatch, getState) => {
  const state = getState();
  const data = {};
  data.jobId = state.jobDetails.details.details.jobId;
  data.name = item;

  stageDesign
    .delete(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(initActions.getDetails(data.jobId));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const dispatchStage = item => dispatch => {
  const success = item => ({
    type: 'JOB_DETAILS_STAGE_DESIGN_SET_DISPATCH_ITEM',
    item,
  });

  dispatch(success(item));
};

// add modal actions
const submitAdd = () => (dispatch, getState) => {
  const success = () => ({
    type: 'JOB_DETAILS_STAGE_DESIGN_ADD_SUCCESS',
  });

  const state = getState();
  const data = {
    ...state.jobDetails.stageDesign.add.stages,
  };
  data.jobId = state.jobDetails.details.details.jobId;

  stageDesign
    .add(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(success());
      dispatch(initActions.getDetails(data.jobId));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValue = (value, name) => ({
  type: 'JOB_DETAILS_STAGE_DESIGN_ADD_SET_INPUT_VALUE',
  value,
  name,
});
const setInputStage = (value, index, name, arrayName) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const array = state.jobDetails.stageDesign.add.stages.stageDesign;
  array[index][name] = value;
  dispatch(setInputValue(array, 'stageDesign'));
};
const addItemStage = (name, content) => (dispatch, getState) => {
  const state = getState();
  const array = [
    ...state.jobDetails.stageDesign.add.stages[name],
    content || {},
  ];
  dispatch(setInputValue(array, name));
};

const dellItemStage = index => (dispatch, getState) => {
  const removeItemStage = array => ({
    type: 'JOB_DETAILS_STAGE_DESIGN_DELETE_ITEM_STAGE',
    array,
  });
  const state = getState();
  const array = {
    ...state.jobDetails.stageDesign.add.stages,
  };
  array.stageDesign.splice(index, 1);

  dispatch(removeItemStage(array.stageDesign));
};

const clearModalStore = () => ({
  type: 'JOB_DETAILS_STAGE_DESIGN_CLEAR_STORE',
});

// dispatch modal actions

const initModalDispatch = stages => ({
  type: 'MODAL_DISPATCH_STAGE_INIT',
  stages,
});

const setStepValue = step => ({
  type: 'MODAL_DISPATCH_STAGE_SET_STEP',
  step,
});
const setModalValue = (value, name) => dispatch => {
  dispatch({ type: 'MODAL_DISPATCH_STAGE_SET_VALUE', value, name });
};

const setInputDispatch = (value, index, name) => (dispatch, getState) => {
  const state = getState();
  const array = [...state.jobDetails.stageDesign.dispatch.stages];
  array[index][name] = value;
  dispatch(setModalValue(array, 'stages'));
};

const clearState = () => ({ type: 'MODAL_DISPATCH_STAGE_CLEAR_STATE' });

const getPrivewStage = () => dispatch => {
  const request = () => ({
    type: 'MODAL_DISPATCH_STAGE_GET_PREVIEW_REQUEST',
  });
  const success = previewOrders => ({
    type: 'MODAL_DISPATCH_STAGE_GET_PREVIEW_SUCCESS',
    previewOrders,
  });
  const failure = error => ({
    type: 'MODAL_DISPATCH_STAGE_GET_PREVIEW_FAILURE',
    error,
  });

  dispatch(request());
  const data = dispatch(setParamData());
  if (data) {
    dispatch(setStepValue(2));
  } else {
    dispatch(setStepValue(1));
  }
  if (data) {
    stageDesign
      .getPreviewStage(data)
      .then(msg => {
        dispatch(success(msg.data.data.orders));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  }
};
const setParamData = () => (dispatch, getState) => {
  const state = getState();
  const dispatchModal = state.jobDetails.stageDesign.dispatch;
  const { antTrailOrdering, stages, startDate, isChecked } = dispatchModal;
  const data = {};
  data.jobId = state.jobDetails.details.details.jobId;
  data.antTrailOrdering = isChecked ? antTrailOrdering : 0;
  data.stageDesign = stages.map(stage => {
    stage.sandTypeId =
      typeof stage.sandTypeId === 'object'
        ? stage.sandTypeId.value
        : stage.sandTypeId;
    if (stage.poNo) {
      stage.poNo =
        typeof stage.poNo === 'object' ? stage.poNo.value : stage.poNo;
    }
    return stage;
  });
  if (moment(startDate) < moment()) {
    swal('Past dates not allowed!', '', 'error');
  } else {
    data.unloadAppointment = moment(startDate)
      .utc()
      .format('YYYY-MM-DD HH:mm');
    return data;
  }
};
const dispatchStageModal = (turnModal, filters) => (dispatch, getState) => {
  const success = () => ({
    type: 'MODAL_DISPATCH_STAGE_DISPATCHED_SUCCESS',
  });
  const failure = error => ({
    type: 'MODAL_DISPATCH_STAGE_DISPATCHED_FAILURE',
    error,
  });
  const state = getState();
  const { jobId } = state.jobDetails.details.details;
  const data = dispatch(setParamData());
  if (data) {
    stageDesign
      .dispatchStage(data)
      .then(msg => {
        swal('Success!', '', 'success');
        turnModal();
        dispatch(clearState());
        dispatch(initActions.getDetails(jobId));
        dispatch(initOrders.init(filters));
      })
      .catch(error => {
        turnModal();
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  }
};

export default {
  turnAddStageDesign,
  removeStage,
  dispatchStage,
  // add modal
  setInputValue,
  submitAdd,
  setInputStage,
  addItemStage,
  dellItemStage,
  clearModalStore,
  // dispatch modal
  initModalDispatch,
  setStepValue,
  setModalValue,
  setInputDispatch,
  clearState,
  getPrivewStage,
  dispatchStageModal,
};
