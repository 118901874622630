import React from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import { deleteAlert } from 'components/sweetalert';
import Button from 'components/shared/Button';

import CardToggle from 'components/common/CardToggle';
import {
  useAddJobCertificate,
  useDeleteJobCertificate,
  useJobCertificates,
} from 'api/v2/jobs';

const CertificatesModal = ({ jobId, showModal, closeModal, isFullEdit }) => {
  const { data: certificates, isLoading } = useJobCertificates({ jobId });
  const { mutate: addCertificate } = useAddJobCertificate({ jobId });
  const { mutate: deleteCertificate } = useDeleteJobCertificate({ jobId });

  const renderAppliedCert = item => (
    <tr key={`cert-${item.id}`}>
      <td>{item.name}</td>
      <td>
        {!!isFullEdit && (
          <Button
            onClick={() => deleteAlert(deleteCertificate, item.id)}
            testSelector="jobs-list_certificates_delete_btn"
            theme="small"
            colour="red">
            Remove Certificate
          </Button>
        )}
      </td>
    </tr>
  );

  const renderAvailableCert = cert => (
    <tr key={`cert-${cert.id}`}>
      <td>{cert.name}</td>
      {!!isFullEdit && (
        <td>
          <Button
            onClick={() => addCertificate(cert.id)}
            theme="small"
            colour="green">
            Add Certificate
          </Button>
        </td>
      )}
    </tr>
  );

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Applied Certificates</Card.Title>
                <CardToggle eventKey="0" />
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table table--striped table--hover">
                      <thead>
                        <tr>
                          <th>Certificates</th>
                          {!!isFullEdit && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {certificates.certOn.map(renderAppliedCert)}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion defaultActiveKey="1">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Available Certificates</Card.Title>
                <CardToggle eventKey="1" />
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table table--striped table--hover">
                      <thead>
                        <tr>
                          <th>Certificates</th>
                          {!!isFullEdit && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {certificates.certOff.map(renderAvailableCert)}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button onClick={closeModal} colour="white">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CertificatesModal;
