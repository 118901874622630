export const initSettings = settings => {
  return {
    alertDelayMinutes: settings.filter(item => item.type == 22)[0].value,
    driverOnsiteDelayMinutes: settings.filter(item => item.type == 23)[0].value,
    jobNPTAlertThresholdHours: settings.filter(item => item.type == 24)[0]
      .value,
    waterJobShutInAlertThresholdHours: settings.filter(
      item => item.type == 37,
    )[0].value,
    waterJobBBLPerHoursRate: settings.filter(item => item.type == 38)[0].value,
  };
};
