import React from 'react';
import moment from 'moment';
import Link from 'components/common/Link';
import Icon from 'components/shared/Icon';
import { dynamicSort } from 'utils/dynamicSort';
import { currency } from 'utils/calculateTotal';

const AcceptedOrders = ({ orders }) => (
    <div>
      <div className="row header-order-list">
        <div className="col-sm-2" style={{ paddingLeft: 0 }}>
          <strong>Order #</strong>
        </div>
        <div className="col-sm-2">
          <strong>
            <Icon icon="truck" />
            Truck
          </strong>
        </div>
        <div className="col-sm-3">
          <strong>
            <Icon icon="location-arrow" />
            Origin
          </strong>
        </div>
        <div className="col-sm-3">
          <strong>
            <Icon icon="calendar" />
            Date
          </strong>
        </div>
        <div className="col-sm-2" style={{ paddingRight: 0 }}>
          <strong>
            <Icon icon="usd" />
            Price
          </strong>
        </div>
      </div>
      {orders.length > 0 ? (
        <div className="block-list-orders">
          {orders.sort(dynamicSort('-order_id')).map((order, i) => (
            <div key={i} className="row item-order-list">
              <div className="col-sm-2" style={{ paddingLeft: 0 }}>
                <Link
                  {...{
                    id: order.order_id,
                    to: 'order-details',
                  }}>
                  #{order.order_id}
                </Link>
              </div>
              <div className="col-sm-2">{order.truck}</div>
              <div className="col-sm-3">{order?.loading_site_name || 'N/A'}</div>
              <div className="col-sm-3">
                {moment(order.well_depart).format('YYYY-MM-DD hh:mm A') !==
                  'Invalid date' && (
                  <span>
                    {moment(order.well_depart).format('YYYY-MM-DD hh:mm A')}
                  </span>
                )}
                {moment(order.well_depart).format('YYYY-MM-DD hh:mm A') ===
                  'Invalid date' && <span> - </span>}
              </div>
              <div className="col-sm-2" style={{ paddingRight: 0 }}>
                <span className="text-navy">{currency.format(order.totalCarrierPrice)}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="block-list-orders" style={{ paddingTop: 10 }}>
          <p>No accepted orders</p>
        </div>
      )}
    </div>
  );

export default AcceptedOrders;
