import { ROLES } from 'config/constants';
import authService from 'services/auth/service';

import jobManagerNavigation from './jobManager';
import jobCoordinator from './jobCoordinator';
import carrierNavigation from './carrier';
import customerNavigation from './customer';
import vendorNavigation from './vendor';

export const routes = () => {
  const user = authService.getUser();
  if (!user) return [];

  switch (user.role) {
    case ROLES.VENDOR:
      return vendorNavigation;
    case ROLES.JOB_MANAGER:
      return jobManagerNavigation;
    case ROLES.JOB_COORDINATOR:
      return jobCoordinator;
    case ROLES.CARRIER:
      return carrierNavigation;
    case ROLES.CUSTOMER:
      return customerNavigation;
    default:
      return [];
  }
};
