import { invoice } from 'services/invoice/service';
import { jobs } from 'services/jobs/service';
import authService from 'services/auth/service';
import swal from 'bootstrap-sweetalert';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

const actionTypes = {
  PAID: invoiceId => invoice.markAsPaid(invoiceId),
  SENT: invoiceId => invoice.markAsSent(invoiceId),
  DELETE: invoiceId => invoice.deleteInvoice(invoiceId),
  PREVIEW: invoiceId => invoice.getBulkInvoice({ id: invoiceId }),
  GENERATE_LINK: invoiceId => invoice.deleteInvoice(invoiceId),
};

export const invoicesRequested = params => async dispatch => {
  dispatch({ type: 'INVOICES_REQUESTED' });

  const invoices = await invoice.getInvoices(params).catch(error =>
    // TODO: Add Generic Failed Handler
    dispatch({ type: 'INVOICES_REQUEST_FAILED' })
  );
  const user = authService.getUser();
  // Filter out invoices that were created by carriers if the user is an admin
  const filteredInvoices = invoices.data.data.invoices.filter(i => {
    if (user && user.entityType === 6) {
      if (i.companySharedType && i.companySharedType === 3) {
        return false;
      }
    }
    return true;
  });

  return dispatch({
    invoices: filteredInvoices,
    totalItems: invoices.data.data.count,
    lastParams: params,
    type: 'INVOICES_REQUEST_SUCCEEDED',
  });
};

export const invoicesActionRequested = (type, invoiceIds) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: 'ACTION_REQUESTED' });
  const state = getState();

  const actionsRequested = invoiceIds.map(id =>
    actionTypes[type](id).catch(e => ({
      error: {
        id,
        message: e.data.message,
      },
    })),
  );
  const response = await Promise.all(actionsRequested).catch(error => {
    swal(`Failed to complete action. Reason: ${error.data.message}`);
    return dispatch({ type: 'ACTION_REQUEST_FAILED' });
  });

  if (response[0].error) {
    swal(`Failed to complete action. Reason: ${response[0].error.message}`);
    return dispatch({ type: 'ACTION_REQUEST_FAILED' });
  }

  if (response.every(item => item.error)) {
    // TODO: Add Generic Failed Handler
    return dispatch({ type: 'ACTION_REQUEST_FAILED' });
  }

  if (response.some(item => item.error)) {
    // TODO: Add Failed Handler to display which invoices failed and why
  }

  if (type === 'SENT' || type === 'PAID') {
    swal(`Invoice(s) successfully marked as ${type}`, '', 'success');
  } else {
    swal('Invoice successfully deleted', '', 'success');
  }
  dispatch({ type: 'ACTION_REQUEST_SUCCEEDED' });
  return dispatch(invoicesRequested(state.invoices.lastParams));
};

export const invoiceDetailsRequested = invoiceId => async dispatch => {
  dispatch({ type: 'INVOICE_DETAILS_REQUESTED' });

  const invoiceDetails = await invoice
    .getInvoiceDetails(invoiceId)
    .catch(error =>
      // TODO: Add Generic Failed Handler
      dispatch({ type: 'INVOICE_DETAILS_REQUEST_FAILED' })
    );

  return dispatch({
    details: invoiceDetails.data.data,
    type: 'INVOICE_DETAILS_REQUEST_SUCCEEDED',
  });
};

export const invoiceActionRequested = (type, invoiceId) => async dispatch => {
  dispatch({ type: 'ACTION_REQUESTED' });

  const actionData = actionTypes[type](invoiceId).catch(error =>
    // TODO: Add Generic Failed Handler
    dispatch({ type: 'ACTION_REQUEST_FAILED' })
  );

  switch (type) {
    case 'PREVIEW':
      return dispatch({
        type: 'ACTION_REQUEST_SUCCEEDED',
        data: { id: invoiceId, content: actionData },
      });
    case 'GENERATE_LINK':
      return dispatch({
        type: 'ACTION_REQUEST_SUCCEEDED',
        data: { id: invoiceId, content: actionData.data.downloadLink },
      });
    default:
      break;
  }
};
// TODO create own alerts for success/failure
export const emailSendInvoice = (emails, id, carrierId) => async dispatch => {
  dispatch({ type: 'ACTION_SEND_EMAIL_REQUESTED' });
  await invoice
    .sendInvoiceToEmail({ emails, id, carrierId })
    .then(() => {
      swal('Success!', '', 'success');
    })
    .catch(err => {
      swal('Failed to send Email');
      return dispatch({ type: 'ACTION_REQUEST_SEND_EMAIL_FAILED' });
    });

  return dispatch({
    type: 'ACTION_REQUEST_SEND_EMAIL_SUCCEEDED',
  });
};

export const downloadLinkRequested = (
  invoiceId,
  carrierId,
) => async dispatch => {
  dispatch({ type: 'GENERATED_DOWNLOAD_LINK_REQUESTED' });

  const generatedLink = await invoice
    .generateLink({ invoiceId, carrierId })
    .catch(err => {
    });

  dispatch({
    type: 'GENERATED_DOWNLOAD_LINK_SUCCEEDED',
  });
  return generatedLink;
};

export const downloadInvoiceFile = (
  invoiceId,
  type,
  role,
  carrierId,
) => async dispatch => {
  dispatch({ type: 'DOWNLOAD_INVOICE_FILE_REQUESTED' });
  const params = {
    type,
    id: invoiceId,
    contentType: '',
    carrierId,
  };

  switch (type) {
    case 'packetinvoice':
      params.contentType = 'zip';
      break;
    case 'bulkinvoice':
      params.contentType = 'pdf';
      break;
    case 'csvinvoice':
      params.contentType = 'csv';
      break;
    default:
      break;
  }

  const fileName =
    role === 'customer'
      ? `${params.type}_ATMZ_${params.id}.${params.contentType}`
      : `${params.type}_ATMZ_${params.id}_${params.carrierId}.${params.contentType}`;

  invoice
    .getBillingInvoice(params)
    .then(response => {
      const file = new Blob([response.data], {
        type: `application/${params.contentType}`,
      });

      FileSaver.saveAs(file, fileName);
    })
    .catch(error => swal(error.message, '', 'error'));

  dispatch({
    type: 'GENERATED_DOWNLOAD_LINK_SUCCEEDED',
  });
};

export const downloadMultiBulkInvoices = (
  invoiceIds,
  role,
  carrierId,
) => async (dispatch, getState) => {
  dispatch({ type: 'ACTION_REQUESTED' });
  const state = getState();

  const response = await invoice.getMultipleBulkInvoices({
    invoiceIds,
    carrierId
  });
  const invoices = response.data;
  const file = new Blob([invoices], {
    type: `application/zip`,
  });

  FileSaver.saveAs(file, 'Bulk_Invoice.zip');

  dispatch({ type: 'ACTION_REQUEST_SUCCEEDED' });
  return dispatch(invoicesRequested(state.invoices.lastParams));
}

export const invoicePreviewRequested = (
  invoiceId,
  carrierId = null,
) => async dispatch => {
  dispatch({ type: 'INVOICE_PREVIEW_REQUESTED' });

  const response = await invoice
    .getBulkInvoice({ id: invoiceId, carrierId })
    .catch(err => dispatch({ type: 'INVOICE_PREVIEW_REQUEST_FAILED' }));
  const file = new Blob([response.data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  dispatch({
    type: 'INVOICE_PREVIEW_REQUEST_SUCCEEDED',
  });
  return fileURL;
};

export const addCommentRequested = (id, comment) => async dispatch => {
  dispatch({ type: 'ADD_COMMENT_REQUESTED' });

  await invoice.comments
    .add({ selectedInvoice: id, newComment: comment })
    .catch(error =>
      // TODO: Add Generic Failed Handler
      dispatch({ type: 'ADD_COMMENT_FAILED' })
    );

  dispatch({
    type: 'ADD_COMMENT_SUCCEEDED',
  });

  return dispatch(invoiceDetailsRequested(id));
};

export const getCreditMemoOrders = (id) => async dispatch => {
  dispatch({ type: 'CREDIT_MEMO_REQUEST_ORDERS' });

  const response = await invoice
    .getCreditMemoOrders(id)
    .catch(error => {
      swal(`Error getting orders for invoice: ${error}`, '', 'error');
      return dispatch({ type: 'CREDIT_MEMO_REQUEST_FAILED' });
    });

  let { orders } = response.data.data;

  orders = orders.map(o => {
    o.isChecked = false;
    return o;
  })

  return dispatch({
    orders,
    type: 'CREDIT_MEMO_SET_ORDERS',
  });
}

export const creditMemoOrdersSetValue = (isChecked, price, index) => async dispatch => dispatch({
  isChecked,
  price,
  index,
  type: 'CREDIT_MEMO_UPDATE',
})

export const creditMemoSetReason = (reason) => dispatch => dispatch({
  reason,
  type: 'CREDIT_MEMO_UPDATE_REASON',
})

export const creditMemoClear = () => async dispatch => dispatch({ type: 'CREDIT_MEMO_CLEAR' })

export const createCreditMemo = (id, closeModal) => async (dispatch, getState) => {
  const state = getState();
  const { orders } = state.invoices.creditMemo;
  const { reason } = state.invoices.creditMemo;
  const orderIds = orders.filter(o => o.isChecked).map(o => o.orderId);
  const params = {
    orderIds,
    reason,
  }
  dispatch({ type: 'CREDIT_MEMO_REQUEST_ORDERS' });

  await invoice
    .createCreditMemo(id, params)
    .catch(error => {
      swal(`Error creating credit memo: ${error}`, '', 'error');
      return dispatch({ type: 'CREDIT_MEMO_REQUEST_FAILED' });
    })
  closeModal();
  swal('Successfully created credit memo', '', 'success');
  dispatch(invoicesRequested(state.invoices.lastParams))
  return dispatch({ type: 'CREDIT_MEMO_CLEAR' })
}

export const jobsRequested = () => async dispatch => {
  dispatch({ type: 'ACTION_JOBS_REQUESTED' });

  const jobResults = await jobs.get({}).catch(error => dispatch({ type: 'JOBS_REQUEST_FAILED' }));

  return dispatch({
    jobs: jobResults.data.data.jobs,
    type: 'ACTION_JOBS_REQUEST_SUCCEEDED',
  });
};

export default {
  invoicesRequested,
  invoicesActionRequested,
  addCommentRequested,
  invoicePreviewRequested,
  emailSendInvoice,
  invoiceActionRequested,
  downloadInvoiceFile,
  getCreditMemoOrders,
  creditMemoOrdersSetValue,
  creditMemoSetReason,
  creditMemoClear,
  createCreditMemo,
  jobsRequested,
}; 
