import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressBars = ({ data }) => (
  <ProgressBar style={{ cursor: 'pointer', marginTop: '30px' }}>
    <ProgressBar
      now={data.deliveredPercentage}
      variant="success"
      label={data.deliveredPercentage > 6 ? 'delivered' : ''}
    />
    <ProgressBar
      now={data.loadedPercentage}
      variant="info"
      label={data.loadedPercentage > 6 ? 'loaded' : ''}
    />
    <ProgressBar
      now={data.shceduledPercentage}
      variant="danger"
      label={data.shceduledPercentage > 6 ? 'scheduled' : ''}
    />
    <ProgressBar
      now={data.leftPercentage}
      variant="warning"
      label={data.leftPercentage > 6 ? 'remaining' : ''}
    />
  </ProgressBar>
);

export default ProgressBars;
