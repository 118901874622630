import React, { useState } from 'react';

import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Badge from 'components/shared/Badge';
import { orderStatus } from 'components/globalInfo/orderStatus';

import { useBillableOrders } from 'api/v2/job-plans';
import CustomToggle from 'components/common/CustomToggle';
import { Accordion } from 'react-bootstrap';
import { billableStatusColors } from '../helpers';
import BillableOrderDetails from './BillableOrderDetails';

const BillableOrders = ({ jobPlanId, timeFrame }) => {
  const [selectedOrderId, setSelectedOrderId] = useState();
  const { data: billableOrders, isLoading } = useBillableOrders({
    jobPlanId,
    timeFrame,
  });

  const toggleAccordion = orderId => {
    setSelectedOrderId(orderId);
  };

  const renderOrderItem = data => {
    const key = `billableOrders-${data.orderId}`;

    return (
      <div key={key}>
        <div className="panel">
          <div
            className="panel-heading"
            style={{ padding: 0, borderBottom: 'none' }}>
            <span className="panel-title" style={{ marginTop: 0 }}>
              <CustomToggle
                eventKey={key}
                onClick={() => toggleAccordion(data.orderId)}
                style={{ textDecoration: 'none', color: 'inherit' }}>
                <Icon icon="false" className="transform00" />
                {/* //Note: these two lines needed for styling */}
                <Icon icon="true" className="transform00" />
                <div
                  className={`rowContainer ${
                    data?.billableStatus
                      ? `rowContainer--${
                          billableStatusColors[(data?.billableStatus)]
                        }`
                      : ''
                  }`}>
                  <div>
                    <strong>Order Id# </strong>
                    <div>{data.orderId}</div>
                  </div>
                  <div className="subcomponentCenter">
                    <div>
                      <Badge type={orderStatus[data.status].class}>
                        {orderStatus[data.status]
                          ? orderStatus[data.status].name
                          : ''}
                      </Badge>
                    </div>
                  </div>
                  <div>
                    <strong>Carrier Id# </strong>
                    <div>
                      {data.carrierId} {data.carrierName}
                    </div>
                  </div>
                  <div>
                    <strong>Driver Id# </strong>
                    <div>
                      {data.driverId} {data.driverName}
                    </div>
                  </div>
                </div>
              </CustomToggle>
            </span>
          </div>
          <Accordion.Collapse
            id={`collapse-${data.orderId}`}
            eventKey={key}
            className="panel-collapse">
            <div className="panel-body" style={{ paddingTop: 0 }}>
              <BillableOrderDetails
                orderId={selectedOrderId}
                billableOrder={data}
                jobPlanId={jobPlanId}
                timeFrame={timeFrame}
              />
            </div>
          </Accordion.Collapse>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!billableOrders?.length) {
    return (
      <div className="font-bold text-danger textCenter">No Billable Orders</div>
    );
  }

  return <Accordion flush>{billableOrders.map(renderOrderItem)}</Accordion>;
};
export default BillableOrders;
