import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import swal from 'bootstrap-sweetalert';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import targetValue from 'utils/targetValue';

import { useEditCustomerAddress } from 'api/v2/customers';

const initBillingAddress = {
  city: '',
  state: '',
  address: '',
  name: '',
  addressSecond: '',
  zipCode: '',
  phoneNumber: '',
  email: '',
  departmentId: '',
};

const EditBillingAddress = ({ closeEdit, showEdit, details }) => {
  const { mutate: updateBillingAddress } = useEditCustomerAddress(details?.id);
  const [billingAddress, setBillingAddress] = useState(initBillingAddress);

  useEffect(() => {
    if (details?.address?.[0]) {
      const addressObj = { ...details.address[0] };
      Object.keys(addressObj).forEach(item => {
        if (!addressObj[item]) {
          addressObj[item] = ''; // Fix React warning when input value == null
        }
      });
      setBillingAddress(addressObj);
    } else {
      setBillingAddress(initBillingAddress);
    }
  }, [details, showEdit]);

  const setValue = (e, name) => {
    const value = targetValue(e);
    setBillingAddress(state => ({
      ...state,
      [name]: value,
    }));
  };

  const saveEdit = async e => {
    e.preventDefault();

    const customerId = details.id;
    const data = {
      ...billingAddress,
      id: customerId,
    };

    updateBillingAddress(data, {
      onSuccess: closeEdit,
      onError: (error) => {
        swal('Error updating customer address.', error?.response?.data?.message, 'error')
      }
    });
  };

  const saveDisabled = useMemo(() => {
    if (
      !billingAddress.city?.trim().length ||
      !billingAddress.state?.trim().length ||
      !billingAddress.address?.trim().length ||
      !billingAddress.phoneNumber?.trim().length ||
      !billingAddress.email?.trim().length ||
      !billingAddress.zipCode?.trim().length
    ) {
      return true;
    }
    return false;
  }, [billingAddress]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeEdit}>
        <form
          onSubmit={e => saveEdit(e, closeEdit)}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Billing Address</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'city')}
                  value={billingAddress.city}
                  label="City"
                  required
                  testSelector="customer-details_info_edit-billing-address_city_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'state')}
                  value={billingAddress.state}
                  label="State"
                  required
                  testSelector="customer-details_info_edit-billing-address_state_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'address')}
                  value={billingAddress.address}
                  label="Address 1"
                  required
                  testSelector="customer-details_info_edit-billing-address_address-1_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'addressSecond')}
                  value={billingAddress.addressSecond}
                  label="Address 2"
                  testSelector="customer-details_info_edit-billing-address_address-2_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="tel"
                  onChange={e => setValue(e, 'phoneNumber')}
                  value={billingAddress.phoneNumber}
                  label="Phone"
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  value={billingAddress.email}
                  onChange={e => setValue(e, 'email')}
                  testSelector="customer-details_info_edit-billing-address_email_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'zipCode')}
                  value={billingAddress.zipCode}
                  label="Zip"
                  required
                  testSelector="customer-details_info_edit-billing-address_zip_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'departmentId')}
                  value={billingAddress.departmentId}
                  label="Department ID"
                  testSelector="customer-details_info_edit-billing-address_department_input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEdit}
              colour="white"
              testSelector="customer-details_info_edit-bill-addr_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="customer-details_info_edit-bill-addr_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditBillingAddress;
