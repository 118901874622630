import React from 'react';
import { Bar } from '@nivo/bar';
import PropTypes from 'prop-types';

const BarGraph = ({
  type,
  width,
  height,
  margin,
  data,
  indexBy,
  padding,
  labelTextColor,
  labelSkipWidth,
  labelSkipHeight,
  maxValue,
  borderRadius,
  isInteractive,
  markers,
}) => {
  let keys = [];
  if (type === 'productionsite') {
    keys = ['value', 'min'];
  } else {
    keys = ['value'];
  }

  if (type === 'productionsite') {
    markers = [
      {
        axis: 'y',
        value: 0,
        lineStyle: {
          stroke: 'rgba(255, 255, 255, 0.1)',
          strokeWidth: 1,
          strokeOpacity: 1,
        },
        textStyle: {
          fill: 'rgba(255, 255, 255, 0.5)',
        },
        legend: 'Tank level',
        legendOrientation: 'horizontal',
        legendPosition: 'top-left',
        legendOffsetY: 20,
        legendOffsetX: -40,
      },
      {
        axis: 'y',
        value: 0,
        lineStyle: {
          strokeOpacity: 0,
        },
        textStyle: {
          fill: 'rgba(255, 255, 255, 0.5)',
        },
        legend: 'Min level',
        legendOrientation: 'horizontal',
        legendPosition: 'bottom-left',
        legendOffsetY: 20,
        legendOffsetX: -40,
      },
    ];
  }

  const defs = [
    {
      id: 'blueGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#22adcf' },
        { offset: 100, color: '#6fc6d9' },
      ],
    },
    {
      id: 'redGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#c81b74' },
        { offset: 100, color: '#ea3737' },
      ],
    },
    {
      id: 'purpleGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#5732b0' },
        { offset: 100, color: '#a64aea' },
      ],
    },
    {
      id: 'greenGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#429321' },
        { offset: 100, color: '#b4ec51' },
      ],
    },
    {
      id: 'orangeGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#dc9c14' },
        { offset: 100, color: '#f0e33a' },
      ],
    },
    {
      id: 'pinkGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: '#c027c8' },
        { offset: 100, color: '#f46cb3' },
      ],
    },
    {
      id: 'greyGradient',
      type: 'linearGradient',
      colors: [
        { offset: 100, color: '#5c5f65' },
        { offset: 0, color: '#91949d' },
      ],
    },
  ];

  let fill = [];
  if (type === 'wellsite') {
    fill = [
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '0',
        id: 'blueGradient',
      },
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '1',
        id: 'purpleGradient',
      },
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '2',
        id: 'orangeGradient',
      },
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '3',
        id: 'pinkGradient',
      },
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '4',
        id: 'greenGradient',
      },
      {
        match: ({
          data: {
            data: { color },
          },
        }) => color === '5',
        id: 'redGradient',
      },
    ];
  } else {
    fill = [
      {
        match: ({
          data: {
            id,
            data: { alert },
          },
        }) => id === 'value' && !alert,
        id: 'blueGradient',
      },
      {
        match: ({
          data: {
            id,
            data: { alert },
          },
        }) => id === 'value' && alert,
        id: 'redGradient',
      },
      { match: { id: 'min' }, id: 'greyGradient' },
    ];
  }

  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: '14px',
          fill: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
  };

  function positionLabel(label) {
    if (label < 0) {
      return 50;
    }
    return -10;
  }

  function formatLabel(string) {
    const label = string.value;
    if (type === 'productionsite') {
      return `${label}`;
    }
    return `${label}%`;
  }

  if (type === 'productionsite') {
    margin = { top: 14, right: 0, bottom: 56, left: 50 };
  } else {
    margin = { top: 14, right: 0, bottom: 14, left: 0 };
  }

  switch (data.length) {
    case 1:
      padding = 0.96;
      break;
    case 2:
      padding = 0.94;
      break;
    case 3:
      padding = 0.93;
      break;
    case 4:
      padding = 0.91;
      break;
    case 5:
      padding = 0.88;
      break;
    case 6:
      padding = 0.85;
      break;
    default:
      padding = 0.85;
  }
  return (
    <Bar
      width={width}
      height={height}
      margin={margin}
      data={data}
      indexBy={
        (type === 'productionsite' && 'tank') ||
        (type === 'wellsite' && 'silo') ||
        indexBy
      }
      keys={keys}
      padding={padding}
      labelTextColor={labelTextColor}
      labelSkipWidth={labelSkipWidth}
      labelSkipHeight={labelSkipHeight}
      axisTop={null}
      axisRight={null}
      borderRadius={borderRadius}
      defs={defs}
      fill={fill}
      colorBy="id"
      enableGridY={false}
      axisBottom={{
        tickSize: 0,
        tickPadding: (type === 'productionsite' && 28) || 1,
        tickRotation: 0,
      }}
      theme={theme}
      axisLeft={null}
      maxValue={
        (type === 'productionsite' && maxValue) ||
        (type === 'wellsite' && 110) ||
        maxValue
      }
      minValue={(type === 'productionsite' && -50) || 0}
      isInteractive={isInteractive}
      labelFormat={d => (
        <tspan y={positionLabel(d)} fontWeight="bold" fontSize="14px">
          {d}
        </tspan>
      )}
      label={d => formatLabel(d)}
      markers={markers}
    />
  );
};

BarGraph.propTypes = {
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.objectOf(PropTypes.number),
  data: PropTypes.arrayOf(PropTypes.object),
  padding: PropTypes.number,
  labelTextColor: PropTypes.string,
  labelSkipWidth: PropTypes.number,
  labelSkipHeight: PropTypes.number,
  borderRadius: PropTypes.number,
  isInteractive: PropTypes.bool,
  indexBy: PropTypes.string,
  maxValue: PropTypes.number,
  markers: PropTypes.arrayOf(PropTypes.object),
};

BarGraph.defaultProps = {
  type: '',
  width: 360,
  height: 250,
  margin: {},
  data: [],
  padding: 0.85,
  labelTextColor: '#ffffff',
  labelSkipWidth: 0,
  labelSkipHeight: 0,
  borderRadius: 4,
  isInteractive: false,
  indexBy: '',
  maxValue: 0,
  markers: [],
};

export default BarGraph;
