import { convertTimeWithSeconds } from 'utils/convertTime';

export const timelineActual = order => {
  let array = [
    {
      title: 'Accepted order',
      date: checkTime(order.order_accepted_at),
      icon: 'list-alt',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    },
    {
      title: 'Arrived to Origin',
      date: checkTime(order.load_arrival),
      icon: 'map-marker',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    },
    {
      title: 'Upload ticket',
      date: checkTime(order.sand_ticket_uploaded_at),
      icon: 'list-alt',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    },
    {
      title: 'Departure from Origin',
      date: checkTime(order.load_depart),
      icon: 'truck',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    },
    {
      title: 'Arrived to Destination',
      date: checkTime(order.well_arrival),
      icon: 'map-marker',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    },
    {
      title: 'Departure from Destination',
      date: checkTime(order.well_depart),
      icon: 'truck',
      isAlerted:
        order.driver_time_well_departure &&
        checkTime(order.driver_time_well_departure) !== 'unknown',
      alternateTitle: `Driver Triggered Exit Geofence: ${checkTime(
        order.driver_time_well_departure,
      )}`,
      alternateDate: checkTime(order.driver_time_well_departure),
    },
  ];
  if (order.enter_stage) {
    array.splice(4, 0, {
      title: 'Arrived to Stage',
      date: checkTime(order.enter_stage),
      icon: 'map-marker',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    });
  }
  if (order.depart_stage) {
    array.splice(5, 0, {
      title: 'Departure from Stage',
      date: checkTime(order.depart_stage),
      icon: 'map-marker',
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    });
  }

  return array;
};

export const checkTime = time =>
  convertTimeWithSeconds(time) != 'Invalid date'
    ? convertTimeWithSeconds(time)
    : 'unknown';
