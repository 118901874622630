import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { priceTypes } from 'components/globalInfo/priceTypes';
import Button from 'components/shared/Button';
import actions from 'store/actions/OrderDetails';
import EditPriceInfo from './EditPriceInfo';

const PriceInfo = ({ info, showModalPriceInfo, closeInfo, clearAddPrice }) => {
  const [showEdit, turnEdit] = useState(false);
  const { pricebreakdown } = info;
  const distancePriceType = pricebreakdown.find(p => p.type === 1);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${20}%` }}
        className="modal-container"
        show={showModalPriceInfo}
        onHide={closeInfo}>
        {!showEdit && (
          <div>
            <Modal.Header>
              <h3 className="modal-title">Price Info</h3>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group has-feedback col-md-12">
                <span>
                  <strong className="stats-label">Mileage: </strong>
                  <span className="text-navy">
                    {distancePriceType && distancePriceType?.mileage
                      ? `${distancePriceType?.mileage} mi`
                      : 'N/A'}
                  </span>
                </span>
              </div>
              <div className="form-group has-feedback col-md-12">
                <span>
                  <strong className="stats-label">Order Time: </strong>
                  <span className="text-navy">
                    {distancePriceType && distancePriceType?.endtoEndTime
                      ? `${distancePriceType?.endtoEndTime} minutes`
                      : 'N/A'}
                  </span>
                </span>
              </div>
              {pricebreakdown.map((item, index) => (
                <Row key={`price${index}`}>
                  <div
                    className="form-group has-feedback col-md-12"
                    style={
                      pricebreakdown?.length > 1
                        ? { borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }
                        : {}
                    }>
                    <span>
                      <strong className="stats-label">
                        {priceTypes[item.type].title}:{' '}
                      </strong>
                      <span className="text-navy">${item.price}</span>
                      <strong className="stats-label"> - </strong>
                      <span className="stats-label">{item.comment}</span>
                    </span>
                  </div>
                </Row>
              ))}
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => {
                  clearAddPrice();
                  closeInfo();
                }}
                colour="white"
                testSelector="order-details_pricing_info-modal_close_btn">
                Close
              </Button>
              {/* <Authorize
                {...{
                  forbidden: ['CARRIER'],
                  abilityPermissions: [
                    {
                      resource: 'Pricing',
                      permissions: ['create'],
                    },
                  ],
                }}>
                <Button
                  onClick={() => turnEdit(true)}
                  children="Edit"
                  testSelector="order-details_pricing_info-modal_edit_btn"
                />
              </Authorize> */}
            </Modal.Footer>
          </div>
        )}

        {showEdit && <EditPriceInfo {...{ closeInfo }} />}
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  info: state.orderDetails.info,
  priceInfo: state.orderDetails.priceInfo,
});

const mapDispatchToProps = dispatch => ({
  clearAddPrice: () => dispatch(actions.clearAddPrice())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceInfo);
