import Constants from '../constants/layoutConstants';

const initialState = {
  navIsHidden: true,
};

const layout = (state = initialState, action) => {
  if (action.type === Constants.NAV_TOGGLE_HIDDEN_OR_VISIBLE) {
    return {
      ...state,
      navIsHidden: !state.navIsHidden,
    };
  }
  return state;
};

export default layout;
