import { createSelector } from 'reselect';

export const setTitle = status => {
  switch (status) {
    case 0:
    case 1:
      return 'Reschedule';

    case 2:
    case 5:
      return 'Reroute';
    default:
      return '';
  }
};

const getDrivers = state => state.orderActions.transfer.drivers;
const getFilter = state => state.orderActions.transfer.input.filterValue;

export const getDriver = createSelector(
  [getDrivers, getFilter],
  (drivers, filter) =>
    drivers.filter(
      item =>
        item.id.toString().search(new RegExp(filter || '', 'i')) != -1 ||
        item.name.toString().search(new RegExp(filter || '', 'i')) != -1,
    ),
);
