import { combineReducers } from '@reduxjs/toolkit';

import { resourceConstants } from './constants';

const initialStateCustomers = {
  isLoadedCustomers: false,
  customers: [],
};
const customers = (state = initialStateCustomers, action) => {
  switch (action.type) {
    case resourceConstants.GET_CUSTOMERS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_CUSTOMERS_SUCCESS:
      return {
        ...state, isLoadedCustomers: true,
        customers: action.customers,
      };
    case resourceConstants.GET_CUSTOMERS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateJobManager = {
  isLoadedJobManagers: false,
  jobManagers: [],
};

const jobManagers = (state = initialStateJobManager, action) => {
  switch (action.type) {
    case resourceConstants.GET_JOB_MANAGERS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_JOB_MANAGERS_SUCCESS:
      return {
        ...state, isLoadedJobManagers: true,
        jobManagers: action.jobManagers,
      };
    case resourceConstants.GET_JOB_MANAGERS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateCarrier = {
  carriers: [],
  isLoadedCarriers: false,
};

function carriers(state = initialStateCarrier, action) {
  switch (action.type) {
    case resourceConstants.GET_CARRIERS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_CARRIERS_SUCCESS:
      return {
        ...state, isLoadedCarriers: true,
        carriers: action.carriers,
      };
    case resourceConstants.GET_CARRIERS_FAILURE:
      return {};
    default:
      return state;
  }
}

const initialStateJobs = {
  isLoadedJobs: false,
  jobs: [],
};

function jobs(state = initialStateJobs, action) {
  switch (action.type) {
    case resourceConstants.GET_JOBS_REQUEST:
      return { ...state };
    case resourceConstants.GET_JOBS_SUCCESS:
      return {
        ...state, jobs: action.jobs,
        isLoadedJobs: true,
      };
    case resourceConstants.GET_JOBS_FAILURE:
      return {};
    default:
      return state;
  }
}

const initialStateDemmurragerReasons = {
  isLoadedReasons: false,
  reasons: [],
};

const demurrageReasons = (state = initialStateDemmurragerReasons, action) => {
  switch (action.type) {
    case resourceConstants.GET_DEMURRAGE_REASONS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_DEMURRAGE_REASONS_SUCCESS:
      return {
        ...state, isLoadedReasons: true,
        reasons: action.reasons,
      };
    case resourceConstants.GET_DEMURRAGE_REASONS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateAllDistricts = {
  isLoadedAllDistricts: false,
  allDistricts: [],
};

const allDistricts = (state = initialStateAllDistricts, action) => {
  switch (action.type) {
    case resourceConstants.GET_ALL_DISTRICTS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_ALL_DISTRICTS_SUCCESS:
      return {
        ...state, isLoadedAllDistricts: true,
        allDistricts: action.allDistricts,
      };
    case resourceConstants.GET_ALL_DISTRICTS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateVendors = {
  isLoadedVendors: false,
  vendors: [],
};

const vendors = (state = initialStateVendors, action) => {
  switch (action.type) {
    case resourceConstants.GET_SAND_VENDORS_REQUEST:
      return { ...state, };
    case resourceConstants.GET_SAND_VENDORS_SUCCESS:
      return {
        ...state, isLoadedVendors: true,
        vendors: action.vendors,
      };
    case resourceConstants.GET_SAND_VENDORS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateCertificates = {
  isLoadedCertificates: false,
  certificate: [],
};

const certificates = (state = initialStateCertificates, action) => {
  switch (action.type) {
    case resourceConstants.GET_CERTIFICATES_REQUEST:
      return { ...state, };
    case resourceConstants.GET_CERTIFICATES_SUCCESS:
      return {
        ...state, isLoadedCertificates: true,
        certificate: action.certificate,
      };
    case resourceConstants.GET_CERTIFICATES_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateContainerVendors = {
  isLoadedContainerVendors: false,
  vendors: [],
};

const containerVendors = (state = initialStateContainerVendors, action) => {
  switch (action.type) {
    case resourceConstants.GET_CONTAINER_VENDORS_REQUEST:
      return {
        ...state,
      };
    case resourceConstants.GET_CONTAINER_VENDORS_SUCCESS:
      return {
        ...state,
        isLoadedContainerVendors: true,
        vendors: action.vendors,
      };
    case resourceConstants.GET_CONTAINER_VENDORS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateTrailers = {
  isLoadedTrailers: false,
  trailers: [],
  filterNumber: '',
};

const trailers = (state = initialStateTrailers, action) => {
  switch (action.type) {
    case resourceConstants.GET_EQUIPMENT_REQUEST:
      return state;
    case resourceConstants.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isLoadedTrailers: true,
        trailers: action.trailers,
        totalPages: action.totalPages,
      };
    case resourceConstants.GET_EQUIPMENT_FAILURE:
      return {};
    case resourceConstants.SET_EQUIPMENT_FILTER_NUMBER:
      return {
        ...state,
        filterNumber: action.filterNumber,
      };
    case resourceConstants.CLEAR_STORE_EQUIPMENT:
      return initialStateTrailers;
    default:
      return state;
  }
};

const initialStateCarrierDocs = {
  isLoadedCarrierDocs: false,
  carrierDocs: [],
};

const carriersDocs = (state = initialStateCarrierDocs, action) => {
  switch (action.type) {
    case resourceConstants.GET_CARRIER_DOCS_REQUEST:
      return {
        ...state,
      };
    case resourceConstants.GET_CARRIER_DOCS_SUCCESS:
      return {
        ...state,
        isLoadedCarrierDocs: true,
        carrierDocs: action.carrierDocs,
      };
    case resourceConstants.GET_CARRIER_DOCS_FAILURE:
      return {};
    default:
      return state;
  }
};

const initialStateDistrictsToCarrier = {
  isLoadedDistrictsToCarrier: false,
  districts: [],
};

const districtToCarrierId = (
  state = initialStateDistrictsToCarrier,
  action,
) => {
  switch (action.type) {
    case resourceConstants.GET_DISTRICT_TO_CARRIER_REQUEST:
      return {
        ...state,
      };
    case resourceConstants.GET_DISTRICT_TO_CARRIER_SUCCESS:
      return {
        ...state,
        isLoadedDistrictsToCarrier: true,
        districts: action.districts,
      };
    case resourceConstants.GET_DISTRICT_TO_CARRIER_FAILURE:
      return {};
    default:
      return state;
  }
};

export const resourceReducer = combineReducers({
  customers,
  jobManagers,
  carriers,
  jobs,
  demurrageReasons,
  vendors,
  allDistricts,
  certificates,
  containerVendors,
  trailers,
  carriersDocs,
  districtToCarrierId,
});
