import React from 'react';

const District = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <g>
      <path d="M12,4.012c-4.404,0-7.988,3.584-7.988,7.988c0,4.405,3.584,7.989,7.988,7.989c4.405,0,7.989-3.584,7.989-7.989 C19.988,7.596,16.404,4.012,12,4.012z M12,18.271C8.538,18.268,5.733,15.463,5.729,12C5.733,8.54,8.538,5.734,12,5.73 c3.461,0.003,6.266,2.809,6.27,6.27c-0.004,3.461-2.809,6.266-6.27,6.27V18.271z"  />
      <path d="M12,7.204C9.352,7.207,7.207,9.352,7.204,12c0.003,2.648,2.148,4.793,4.796,4.797c2.649-0.002,4.795-2.148,4.797-4.797 C16.793,9.352,14.647,7.207,12,7.204z M12,15.078C10.3,15.076,8.923,13.699,8.922,12C8.924,10.301,10.301,8.924,12,8.922 c1.7,0,3.079,1.378,3.079,3.078S13.699,15.077,12,15.078z"  />
      <circle cx="12" cy="12" r="1.823"  />
    </g>
  </svg>
);

export default District;
