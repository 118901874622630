import React, { useState } from 'react';
import { connect } from 'react-redux';

import { carriersRequested } from 'store/actions/Carriers';
import { jobsRequested } from 'store/actions/Jobs';
import { customersRequested } from 'store/actions/Customers';
import { driversRequested } from 'store/actions/Drivers';
import Reconcile from './Reconcile';

export const ReconcileContext = React.createContext();

const mapStateToProps = ({ carriers, jobsNew, customers, driversNew }) => ({
  isLoading:
    carriers.apiIsLoading &&
    jobsNew.apiIsLoading &&
    customers.apiIsLoading &&
    driversNew.apiIsLoading,
});

const mapDispatchToProps = dispatch => ({
  getFilters: () => {
    dispatch(carriersRequested());
    dispatch(jobsRequested());
    dispatch(customersRequested());
    dispatch(driversRequested());
  },
});

const ReconcileWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reconcile);

const ReconcileWithContext = () => {
  const [filters, setFilters] = useState([]);
  const [ordering, setOrdering] = useState({});
  const [pagination, setPagination] = useState({});

  return (
    <ReconcileContext.Provider
      value={{
        filters,
        setFilters,
        ordering,
        setOrdering,
        pagination,
        setPagination,
      }}>
      <ReconcileWithRedux />
    </ReconcileContext.Provider>
  );
};

export default ReconcileWithContext;
