import swal from 'bootstrap-sweetalert';
import profileService from 'services/profile/service';
import { lookUpActions } from 'store/actions/LookUp';
import { validatePassOptions } from 'components/globalInfo/validatePasswordOptions';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

const getUser = () => dispatch => {
  profileService
    .get()
    .then(msg => {
      const user = msg.data.data;
      dispatch(lookUpActions.editUser(user));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const updateProfile = () => (dispatch, getState) => {
  const state = getState();
  const {
    userProfile: {
      update: { firstName, lastName, phoneNumber, timeZone, description },
    },
  } = state;

  const data = {
    firstName,
    lastName,
    phoneNumber,
    timeZone,
    description,
  };
  profileService
    .patch(data)
    .then(() => {
      swal('Successfully updated profile.', '', 'success');
      dispatch(getUser());
    })
    .catch(error => {
      swal(error.response.data.error, '', 'error');
    });
};

const setProfileValue = (value, name) => ({
  type: 'UPDATE_PROFILE_SET_VALUE',
  value,
  name,
});

const setProfile = () => (dispatch, getState) => {
  const set = profile => ({
    type: 'UPDATE_PROFILE_SET_PROFILE',
    profile,
  });
  const state = getState();
  const profile = state.lookUp.user.user;
  dispatch(set(profile));
};

const setInputValue = (value, name) => ({
  type: 'CHANGE_PASSWORD_SET_INPUT_VALUE',
  value,
  name,
});

const submitPass = () => (dispatch, getState) => {
  dispatch({ type: 'CHANGE_PASSWORD_SUBMIT', value: true });
  const state = getState();
  const {
    userProfile: {
      password: { oldPassword, password },
    },
  } = state;
  const data = {
    oldPassword,
    password,
  };

  if (!isStrongPassword(password, [validatePassOptions])) {
    swal({
      title: 'Incorrect new password',
      type: 'error',
      html: true,
      text:
        'Password must be at least 7 characters in length and contain:<br/>- a minimum of 1 lower case letter,<br/>- a minimum of 1 upper case letter,<br/>- a minimum of 1 numeric character,<br/>- a minimum of 1 special character',
    });
    dispatch({ type: 'CHANGE_PASSWORD_SUBMIT', value: false });
  } else {
    profileService
      .password(data)
      .then(() => {
        swal('Successfully updated password.', '', 'success');
        dispatch({ type: 'CHANGE_PASSWORD_SUBMIT', value: false });
        dispatch({ type: 'CHANGE_PASSWORD_CLEAR_STATE' });
      })
      .catch(error => {
        dispatch({ type: 'CHANGE_PASSWORD_SUBMIT', value: false });
        swal(error.response.data.message, '', 'error');
      });
  }
};

export default {
  updateProfile,
  setProfileValue,
  setProfile,
  getUser,
  setInputValue,
  submitPass,
};
