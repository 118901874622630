import { client } from 'api/client';
import { convertTime } from 'utils/convertTime';

export const getPoSchedules = async ({ poId }) => {
  const { data } = await client.get(`v2/purchase-orders/${poId}/schedules`);
  data.data.forEach(schedule => {
    schedule.startDate = convertTime(schedule.startDate);
  });
  return data.data;
};

export const updatePoSchedules = async ({ poId, data }) =>
  client.put(`v2/purchase-orders/${poId}/schedules`, data.schedules, { params: { poNo: data.poNo } });

