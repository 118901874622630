import { connect } from 'react-redux';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import purchaseOrderActions from 'store/actions/PurchaseOrders';
import updatePOActions from 'store/actions/UpdatePO';
import ViewPO from './ViewPO';

const mapStateToProps = state => ({
  isLoadedCustomers: state.resourceReducer.customers.isLoadedCustomers,
  isLoadedJobs: state.resourceReducer.jobs.isLoadedJobs,
  customers: state.resourceReducer.customers.customers,
  jobs: state.resourceReducer.jobs.jobs,
  orders: state.purchaseOrders.orders,
});

const mapDispatchToProps = dispatch => ({
  getCustomers: () => dispatch(resourceActions.getCustomers()),
  getJobs: () => dispatch(resourceActions.getJobs()),
  clearStoreViewPO: () => dispatch(purchaseOrderActions.clearStoreViewPO()),
  initUpdatePO: data => dispatch(updatePOActions.init(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewPO);
