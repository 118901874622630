import React from 'react';

const Rotate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon--fill"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <path
      fillRule="nonzero"
      d="M19.569 11.397c-.864.008-1.727.012-2.59.02a5.145 5.145 0 0 1-1.482 4.157 5.17 5.17 0 1 1-7.312-7.311 5.166 5.166 0 0 1 6.348-.75c-.675.666-1.468 1.456-1.468 1.456-.572.607.01 1.01.394 1.012h5.044a.35.35 0 0 0 .35-.35V4.628c.023-.471-.495-.91-1.004-.4l-1.447 1.434c-3.032-2.216-7.305-1.965-10.044.773a7.754 7.754 0 1 0 10.966 10.967 7.719 7.719 0 0 0 2.245-6.005z"
    />
  </svg>
);

export default Rotate;
