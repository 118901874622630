import React from 'react';
import { SearchInput as Search } from 'components/shared/Input';
import { useRouteMatch } from 'react-router-dom';

const SearchInput = ({ setSearchValue, searchValue, goToSearchResult }) => {
  const match = useRouteMatch();

  return (
    <form
      className="search-form"
      onSubmit={e => goToSearchResult(e, match)}>
      <Search
        noMargin
        onChange={setSearchValue}
        value={searchValue}
        testSelector="main-search_input"
      />
    </form>
  )
};

export default SearchInput;
