import React from 'react';

const OrdersList = ({ purchaseOrders }) => (
  <div className="row" ng-if="purchaseOrder">
    <div className="col-lg-12">
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h5>Purchase Orders List</h5>
        </div>
        <div className="ibox-content">
          <div className="table-responsive">
            <table
              datatable="ng"
              id="datatable_table"
              className="table table--striped table--hover">
              <thead>
                <tr>
                  <th>PO №</th>
                  <th>Job name</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Commodity</th>
                  <th>Orders</th>
                  <th>Delivered</th>
                  <th>Loaded</th>
                  <th>Scheduled</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrders.map(data => (
                  <tr
                    key={data.poNo}
                    data-testid={`purchase-orders-row-${data.poNo}`}>
                    <td>{data.poNo}</td>
                    <td>{data.name}</td>
                    <td>
                      {data.originId} | {data.originName}
                    </td>
                    <td>
                      {data.destinationId} | {data.destinationName}
                    </td>
                    <td>
                      {data.sandTypeId} | {data.sandType}
                    </td>
                    <td>{data.orders}</td>
                    <td>{data.delivered}</td>
                    <td>{data.loaded}</td>
                    <td>{data.scheduled}</td>
                    <td>{data.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OrdersList;
