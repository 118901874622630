// If it has main permission check if it has permGrantId
export const checkPermissionHasGid = (getRolePermission, gid) => {
  if (!getRolePermission || !getRolePermission.grants) {
    return undefined;
  }
  return getRolePermission.grants.some(
    sData => sData.gid === 5 || gid === sData.gid,
  );
};

// Check if role even has main permission
export const checkRoleHasPid = (role, pid) => {
  if (!role || !role.permissions) {
    return undefined;
  }
  return role.permissions.find(fData => fData.pid && fData.pid.pid === pid);
};
