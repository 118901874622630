import moment from 'moment';
import _ from 'lodash';
import { isTimeFormatValid } from 'utils/datePickersFunctions';

export const dayOptions = [
  { label: 'Monday', value: 0 },
  { label: 'Tuesday', value: 1 },
  { label: 'Wednesday', value: 2 },
  { label: 'Thursday', value: 3 },
  { label: 'Friday', value: 4 },
  { label: 'Saturday', value: 5 },
  { label: 'Sunday', value: 6 },
];

const isOverlappingTiming = (currentTiming, value) => {
  const targetTiming = {
    open: moment(value.open, 'HH:mm:ss'),
    close: moment(value.close, 'HH:mm:ss'),
  };

  if (
    targetTiming.open.isBetween(
      currentTiming.open,
      currentTiming.close,
      'm',
      '[]',
    ) ||
    targetTiming.close.isBetween(
      currentTiming.open,
      currentTiming.close,
      'm',
      '[]',
    )
  ) {
    return true;
  }
  return false;
};

export const validateTimings = timings => {
  // Sort the rates by day
  const sortedTimings = _.groupBy(
    timings.map(({ isDanger, ...rest }) => ({ ...rest })),
    'day',
  );

  // make sure there is no gap or over lap between all of the start and stop times of all rows
  dayOptions.forEach(({ value: day }) => {
    const dayTimings = sortedTimings[day];

    if (!dayTimings) {
      return;
    }

    dayTimings.forEach((timing, index) => {
      const currentTiming = {
        open: moment(timing.open, 'HH:mm:ss'),
        close: moment(timing.close, 'HH:mm:ss'),
      };

      if (
        currentTiming.close.isBefore(currentTiming.open) ||
        isTimeFormatValid(timing.open) ||
        isTimeFormatValid(timing.close)
      ) {
        timing.isDanger = true;
      }

      if (index === 0) {
        return;
      }

      // Find and mark all times that overlap with current
      dayTimings.forEach((targetTiming, targetIndex) => {
        if (
          index !== targetIndex &&
          isOverlappingTiming(currentTiming, targetTiming)
        ) {
          targetTiming.isDanger = true;
          timing.isDanger = true;
        }
      });
    });
  });

  return sortedTimings;
};
