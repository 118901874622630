import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Select from 'components/shared/Select';
import { startCase } from 'lodash';

import { useAddTag, useEditTag, useTagsTypes } from 'api/v2/tags';

const AddEditModal = ({ showUpdate, closeUpdate, modalValue }) => {
  const { data: tagTypes } = useTagsTypes();
  const { mutate: addTag } = useAddTag();
  const { mutate: editTag } = useEditTag();

  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    type: 1,
  });

  const handleChange = (e, name) => {
    setFormValues({ ...formValues, [name]: targetValue(e) });
  };

  const tagTypeOptions = useMemo(
    () =>
      (tagTypes ?? []).map(i => ({ label: startCase(i.name), value: i.id })),
    [tagTypes],
  );

  const saveDisabled = useMemo(
    () =>
      !(formValues.name ?? '').trim().length ||
      !(formValues.description ?? '').trim().length,
    [formValues],
  );

  const handleClose = () => {
    setFormValues({
      name: '',
      description: '',
    });
    closeUpdate();
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    switch (modalValue.status) {
      case 'add':
        addTag(data, { onSettled: handleClose });
        break;
      case 'edit':
        editTag(data, { onSettled: handleClose });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (modalValue && !(modalValue?.status === 'add')) {
      modalValue.type = tagTypeOptions.find(
        i => i.label.toLowerCase() === modalValue.type,
      )?.value;
      setFormValues(modalValue);
    } else {
      setFormValues({
        name: '',
        description: '',
      });
    }
  }, [modalValue, tagTypeOptions]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => handleSubmit(e, formValues)}>
          <Modal.Header>
            <h3>{modalValue.status === 'add' ? 'Add Tag' : 'Edit Tag'}</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => handleChange(e, 'name')}
                  value={formValues.name}
                  label="Name"
                  required
                  testSelector="manage-tags-edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  onChange={e => handleChange(e.value, 'type')}
                  options={tagTypeOptions}
                  value={tagTypeOptions.find(i => i.value === formValues.type)}
                  label="Type"
                  required
                  testSelector="manage-tags-edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-12">
                <Textarea
                  rows="3"
                  label="Description"
                  onChange={e => handleChange(e, 'description')}
                  value={formValues.description}
                  required
                  testSelector="manage-tags-edit_description_input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="database_tags_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="database_tags_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddEditModal;
