import axios from 'axios';

export const sandType = {
  get() {
    return axios.get('v2/commodities', {});
  },
  edit(data) {
    return axios.put(`v2/sand-type/${data.id}`, data);
  },
  add(data) {
    return axios.post(`v2/jobs/sand-type`, data);
  },
  remove(data) {
    const body = data;
    return axios.delete(`v2/jobs/sand-type`, { data: body });
  },
};