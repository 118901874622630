import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';

import { useLocations } from 'api/v2/locations';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';

const PurchaseOrder = ({
  customers,
  filter,
  setFilter,
  jobs
}) => {
  const user = authService.getUser();
  const [customersList, setCustomers] = useState(customers);

  const { data: locations } = useLocations();

  useEffect(() => {
    if (user.role === ROLES.CUSTOMER) {
      setCustomers(customers.filter(i => i.id === user.entityId));
    }
  }, []);

  return (
    <form className="add-forms" role="form">
      <Row>
        {customersList.length !== 0 && (
          <Col lg={3} md={3} sm={3}>
            {customersList.length > 1 ? (
              <Select
                placeholder="Please select a Customer"
                onChange={item => setFilter('customerId', item?.value)}
                options={customersList.map(customer => ({
                  value: customer.id,
                  label: `${customer.id} | ${customer.name}`,
                }))}
                testSelector="view-po_po_customer_select"
                isClearable
              />
            ) : (
              <Input
                disabled
                value={`${customersList[0].id} | ${customersList[0].name}`}
                label="Customer"
                testSelector="view-po_po_customer_input"
              />
            )}
          </Col>
        )}
        {jobs.length !== 0 && (
          <Col lg={3} md={3} sm={3}>
            {jobs.length > 1 ? (
              <Select
                placeholder="Please select a Job"
                onChange={item => setFilter('jobId', item?.value)}
                options={jobs.map(job => ({
                  value: job.id,
                  label: `${job.id} | ${job.job_name}`,
                }))}
                testSelector="view-po_po_job_select"
                isClearable
              />
            ) : (
              <Input
                disabled
                value={`${jobs[0].id} | ${jobs[0].job_name}`}
                label="Job"
                testSelector="view-po_po_job_input"
              />
            )}
          </Col>
        )}
        {locations?.length && (
          <Col lg={3} md={3} sm={3}>
            {locations.length > 1 ? (
              <Select
                placeholder="Please select a Location"
                onChange={item => setFilter('sandSiteId', item?.value)}
                options={locations
                  .filter(i => !i.is_deleted)
                  .map(sandSite => ({
                    value: sandSite.id,
                    label: `${sandSite.id} | ${sandSite.name}`,
                  }))}
                testSelector="view-po_po_sandsite_select"
                isClearable
              />
            ) : (
              <Input
                disabled
                value={`${locations[0].id} | ${locations[0].name}`}
                label="Sand Site"
                testSelector="view-po_po_sandsite_input"
              />
            )}
          </Col>
        )}

        <Col lg={1} md={1} sm={1}>
          <Input
            type="checkbox"
            testSelector="view-po_po_is-active_input"
            isChecked={filter.isActive}
            onChange={e => setFilter('isActive', e.target.checked)}
            label="Active"
          />
        </Col>
        {/**
        <Col lg={2} md={2} sm={2}>
          <Button
            testSelector="view-po_po_open_btn"
            type="submit"
            disabled={user.role === ROLES.JOB_MANAGER && !filter.customerId}>
            View PO
          </Button>
        </Col>
        */}
      </Row>
    </form>
  );
};

export default PurchaseOrder;
