import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/Icon';
import BarGraph from 'components/shared/BarGraph';
import { timeFix } from 'components/views/Home/WaterProductionInfoWindow/helpers';

const WaterProductionInfoWindow = ({ order }) => {
  const {
    numberOfOrders,
    numberOfTrucks,
    trucksBehindSchedule,
    alerts,
    tanks,
  } = order;
  return (
    <div className="wellsite-content">
      <div className="info-window__status-container">
        <div className="status-block trucks-vs-orders">
          <Icon icon="trucks_vs_orders" />
          <span className="status-block__text">
            <span>Orders vs Trucks on Site: </span>
            <strong
              data-testid="water-prod-orders-trucks"
              className="status-block__text__values">
              {numberOfOrders || '0'}
              <span> / </span>
              {numberOfTrucks || '0'}
            </strong>
          </span>
        </div>
        <div className="status-block trucks-behind">
          <Icon icon="trucks_behind" />
          <span className="status-block__text">
            <span>Trucks Behind Schedule: </span>
            <strong
              data-testid="water-prod-trucks-behind-schedule"
              className="status-block__text__values">
              {trucksBehindSchedule || '0'}
            </strong>
          </span>
        </div>
      </div>
      {alerts && alerts.driverOnSite && (
        <div className="info-window__warning-container">
          <div className="info-window__warning-container--message">
            <Icon icon="clock" colour="white" />
            <span className="info-window__warning-container--text">
              <span>Delay on site: </span>
              <span
                data-testid="water-prod-average-wait-time"
                className="white">
                Average wait time
                {` ${alerts.driverOnSite.avgDelayedMinutesOnsite} `}min
              </span>
            </span>
          </div>
        </div>
      )}
      {alerts && alerts.upcomingShutIn ? (
        <div className="info-window__warning-container">
          <div className="info-window__warning-container--message">
            <Icon icon="clock" colour="white" />
            <span className="info-window__warning-container--text">
              <span>Estimated time to Shut In: </span>
              <span
                data-testid="water-prod-estimated-time-shut-in"
                className="white">
                {timeFix(alerts.upcomingShutIn.hoursTo)}
              </span>
            </span>
          </div>
        </div>
      ) : null}
      {tanks && tanks.length > 0 ? (
        <BarGraph
          data={tanks.map(tank => ({
            tank: `Tank ${tank.tankNumber}`,
            value:
              tank.level - tank.minLevel > 0
                ? (tank.level - tank.minLevel).toFixed(2)
                : 0,
            min: tank.minLevel * -1,
            alert: tank.upcomingShutIn,
          }))}
          maxValue={Math.max(...tanks.map(tank => parseInt(tank.maxLevel, 10)))}
          type="productionsite"
        />
      ) : null}
    </div>
  );
};

WaterProductionInfoWindow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

export default WaterProductionInfoWindow;
