import { client } from 'api/client';

/**
 * @typedef {{
 *   start: string;
 *   end: string;
 * }} TimeFrame
 */

/**
 *
 * @param {{ jobPlanId: number; timeFrame: TimeFrame }}
 * @returns {Promise<any[]>}
 */
export const getBillableOrders = async ({ jobPlanId, timeFrame }) => {
  const { data } = await client.get(`v2/jobPlan/${jobPlanId}/billableOrders`, {
    params: {
      ...timeFrame,
      currentDate: new Date().toISOString(),
    },
  });
  return data.data.billableOrders;
};
