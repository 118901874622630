import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Authorize, { authorize } from 'components/common/Authorize';
import settingsActions from 'store/actions/AdminSettings';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { Card, Row } from 'react-bootstrap';
import { initSettings } from './helpers';

const AlertsSettings = ({
  settings,
  getSettings,
  isLoadedSettings,
  update,
  clearState,
}) => {
  const [data, initData] = useState({});

  useEffect(() => {
    clearState();
    getSettings();
  }, []);

  useEffect(() => {
    if (settings.length) {
      initData(initSettings(settings));
    }
  }, [settings]);

  const isDisable = () => {
    if (
      data.alertDelayMinutes <= 0 ||
      data.driverOnsiteDelayMinutes <= 0 ||
      data.jobNPTAlertThresholdHours <= 0 ||
      data.waterJobShutInAlertThresholdHours <= 0 ||
      data.waterJobBBLPerHoursRate <= 0
    ) {
      return true;
    }

    return false;
  };

  if (!isLoadedSettings) {
    return <Loader />;
  }

  return (
    <Card>
      <Card.Header as="h5">Alerts Settings</Card.Header>
      <Card.Body>
        <form onSubmit={e => update(e, data)}>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    alertDelayMinutes: Number(value),
                  }))
                }
                value={data.alertDelayMinutes}
                label="Delay Minutes"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_alerts_delay-minutes_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    driverOnsiteDelayMinutes: Number(value),
                  }))
                }
                value={data.driverOnsiteDelayMinutes}
                label="Driver Onsite Delay Minutes"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_alerts_driver-onsite_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    jobNPTAlertThresholdHours: Number(value),
                  }))
                }
                value={data.jobNPTAlertThresholdHours}
                label="Job NPT Alert Threshold Hours"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_alerts_job-npt_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    waterJobShutInAlertThresholdHours: Number(value),
                  }))
                }
                value={data.waterJobShutInAlertThresholdHours}
                label="Water Job Shutin Threshold Hours"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_alerts_water-job-shutin_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    waterJobBBLPerHoursRate: Number(value),
                  }))
                }
                value={data.waterJobBBLPerHoursRate}
                label="Water Job BBL Per Hours Rate"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_alerts_water-job-bbl_input"
              />
            </div>
          </Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Admin', permissions: ['update'] },
            ]}>
            <div className="modal-footer" style={{ paging: 0, border: 'none' }}>
              <Button
                type="submit"
                disabled={isDisable()}
                testSelector="admin-settings_alerts_apply_btn">
                Apply Settings
              </Button>
            </div>
          </Authorize>
        </form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  settings: state.adminSettings.currentSettings.settings,
  isLoadedSettings: state.adminSettings.currentSettings.isLoadedSettings,
});

const mapDispatchToProps = dispatch => ({
  getSettings: () => {
    dispatch(settingsActions.getSettings());
  },
  update: (e, data) => {
    e.preventDefault();
    dispatch(settingsActions.updateAlertsSettings(data));
  },
  clearState: () => dispatch(settingsActions.clearCurrentSettingsState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertsSettings);
