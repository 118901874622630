import React, { useState, useCallback } from 'react';
import swal from 'bootstrap-sweetalert';

import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

import {
  useGetStorageRulesQuery,
  useDeleteRuleMutation,
} from 'features/api/slsApiSlice';
import { useJobPermission } from 'components/views/JobDetails/hooks';
import AddRuleModal from './AddRuleModal';

const StorageRules = ({ jobId, sandTypes, unitGroups }) => {
  const isFullEdit = useJobPermission(jobId);
  const { data: rules, isLoading: isGetRulesLoading } = useGetStorageRulesQuery(
    jobId,
  );
  const [deleteRule] = useDeleteRuleMutation();
  const [addRuleModalOpen, setAddRuleModalOpen] = useState(false);
  const [rule, setRule] = useState(null);
  const [modify, setModify] = useState(false);

  const openEditRuleModal = selectedRule => {
    setRule(selectedRule);
    setModify(true);
    setAddRuleModalOpen(true);
  };

  const openAddRuleModal = () => {
    setRule(null);
    setModify(false);
    setAddRuleModalOpen(true);
  };

  const deleteStorageRule = useCallback(
    storageRule =>
      swal(
        {
          title: 'Are you sure?',
          text: `Delete storage rule on ${storageRule.ruleSubject.name}?`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          showLoaderOnConfirm: true,
        },
        async isConfirm => {
          if (isConfirm) {
            await deleteRule(storageRule).unwrap();
          }
        },
      ),
    [deleteRule],
  );

  const renderRules = storageRules => {
    const simplifiedRules = [];

    const commodityIdSet = new Set();
    storageRules.commodityRules.forEach(storageRule => {
      if (!commodityIdSet.has(storageRule.display.ruleSubject.id)) {
        simplifiedRules.push(storageRule.display);
      }
      commodityIdSet.add(storageRule.display.ruleSubject.id);
    });

    const unitNameSet = new Set();
    storageRules.unitRules.forEach(storageRule => {
      if (
        !unitNameSet.has(
          `${storageRule.display.ruleSubject.name} - ${
            storageRule.display.externalProvider
          }`,
        )
      ) {
        simplifiedRules.push(storageRule.display);
      }
      unitNameSet.add(
        `${storageRule.display.ruleSubject.name} - ${
          storageRule.display.externalProvider
        }`,
      );
    });

    return (
      <table className="table table--hover">
        <thead>
          <tr>
            <th>
              <span className="table-list__header-item">Rule Type</span>
            </th>
            <th>
              <span className="table-list__header-item">Subject</span>
            </th>
            <th>
              <span className="table-list__header-item">
                Low Threshold Quantity
              </span>
            </th>
            <th>
              <span className="table-list__header-item">Target Quantity</span>
            </th>
            <th>
              <span className="table-list__header-item">Alert Email</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {simplifiedRules.map((storageRule, i) => (
            <tr key={`storage-rule-${i}`}>
              <td>
                {storageRule.ruleType.charAt(0).toUpperCase() +
                  storageRule.ruleType.slice(1)}
              </td>
              <td>{storageRule.ruleSubject.name} </td>
              <td>{storageRule.lowThresholdQuantity}</td>
              <td>{storageRule.targetQuantity}</td>
              <td>{storageRule.alertEmail}</td>
              <td>
                <Authorize
                  abilityPermissions={[
                    { resource: 'Jobs', permissions: ['update'] },
                  ]}
                  itemAccessGranted={isFullEdit}>
                  <span>
                    <Button
                      theme="small"
                      onClick={() => openEditRuleModal(storageRule)}>
                      <Icon icon="pencil" />
                    </Button>
                    <Button
                      theme="small"
                      onClick={() => deleteStorageRule(storageRule)}>
                      <Icon icon="trash" />
                    </Button>
                  </span>
                </Authorize>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (isGetRulesLoading) {
    return <Loader />;
  }

  return (
    <>
      <h3>Storage Rules</h3>
      {rules ? renderRules(rules.rules, sandTypes) : ''}
      <AddRuleModal
        sandTypes={sandTypes}
        unitGroups={unitGroups}
        open={addRuleModalOpen}
        closeModal={() => setAddRuleModalOpen(false)}
        jobId={jobId}
        rule={rule}
        modify={modify}
      />
      <Authorize
        abilityPermissions={[{ resource: 'Jobs', permissions: ['update'] }]}
        itemAccessGranted={isFullEdit}>
        <div style={{ textAlign: 'right' }}>
          <Button theme="medium" onClick={() => openAddRuleModal()}>
            <Icon icon="plus" />
          </Button>
        </div>
      </Authorize>
    </>
  );
};

export default StorageRules;
