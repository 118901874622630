import { client } from 'api/client';

export const getCarrierDocs = async carrierId => {
  const { data } = await client.get(`v2/job-manager/carrier/${carrierId}/docs`);
  return data.data.docs;
};

export const uploadCarrierDoc = async ({ carrierId, doc }) => {
  const formData = new FormData();
  formData.append('docType', doc.docType);
  formData.append('file', doc.file);
  formData.append('description', doc.description);
  formData.append('customerId', doc.customerId);

  const { data } = await client.post(
    `v2/carrier/${carrierId}/files/approve`,
    formData,
    {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    },
  );
  return data;
};

export const deleteCarrierDoc = async docId => {
  const { data } = await client.delete(`v2/job-manager/carrier-doc/${docId}`);
  return data;
};

export const verifyCarrierDoc = async docId => {
  const { data } = await client.post(
    `v2/job-manager/carrier-doc/${docId}/verify`,
  );
  return data;
};
