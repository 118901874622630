import React from 'react';
import Icon from 'components/shared/Icon';
import { minDuration } from 'utils/convertTime';

const SandSite = ({ sandSites }) => (
  <div className="col-lg-8">
    <div className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>Sand Sites</h5>
      </div>
      <div className="ibox-content">
        <table className="table table--hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Orders</th>
              <th>Load time</th>
            </tr>
          </thead>
          <tbody>
            {sandSites.map(item => (
              <tr key={`sandSites-${item.id}`}>
                <td>
                  #{item.id}|{item.name}
                </td>
                <td> {item.count} orders </td>
                <td className=" text-navy">
                  <Icon icon="clock-o" />
                  {minDuration(item.avrLoadingTime)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
export default SandSite;
