import swal from 'bootstrap-sweetalert';

import { jobManager } from 'services/jobManager/service';
import setting from 'services/setting/service';

const getSettings = () => {
  const request = () => ({ type: 'ADMIN_SETTINGS_GET_RQUEST' });
  const success = settings => ({
    type: 'ADMIN_SETTINGS_GET_SUCCESS',
    settings,
  });
  const failure = error => ({
    type: 'ADMIN_SETTINGS_GET_FAILURE',
    error,
  });

  return dispatch => {
    dispatch(request());

    jobManager
      .getSettings()
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const applySettings = data => dispatch => {
  data.time = Number(data.hour) * 60 + Number(data.minute);
  const reload = () => window.location.reload();
  jobManager
    .applySetting(data)
    .then(response => {
      swal(response.data.message, '', 'success');
      dispatch(reload())
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};


const clearCurrentSettingsState = () => ({type: 'SETTINGS_CURRENT_CLEAR_STATE'});

const applyQueueMark = (queueDistanceMark, queueTimingMark) => dispatch => {
  jobManager
    .setSettingQueue(queueDistanceMark, queueTimingMark)
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const getCurrentFuelPrice = () => {
  const request = () => ({
    type: 'ADMIN_SETTINGS_FUEL_PRICE_GET_REQUEST',
  });
  const success = currentPrice => ({
    type: 'ADMIN_SETTINGS_FUEL_PRICE_GET_SUCCESS',
    currentPrice,
  });
  const failure = error => ({
    type: 'ADMIN_SETTINGS_FUEL_PRICE_GET_FAILURE',
    error,
  });

  return dispatch => {
    dispatch(request());

    jobManager
      .getCurrentPrice()
      .then(response => {
        dispatch(success(response.data.data.fuelInfo));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getFuelBreakdown = () => {
  const request = () => ({
    type: 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_REQUEST',
  });
  const success = fuelList => ({
    type: 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_SUCCESS',
    fuelList,
  });
  const failure = error => ({
    type: 'ADMIN_SETTINGS_FUEL_BREAKDOWN_GET_FAILURE',
    error,
  });

  return dispatch => {
    dispatch(request());

    jobManager
      .getFuelBreakdown()
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getCompanyCredentials = () => dispatch => {
  const save = data => ({
    type: 'ADMIN_SETTINGS_INIT_COMPANY_ADDRESS',
    data,
  });
  setting.company
    .get()
    .then(response => {
      dispatch(save(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const updateCompanyCredentials = data => () => {
  setting.company
    .update(data)
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const clearCopmanyCredentialsState = () => ({ type: 'SETTINGS_COMPANY_ADDRESS_CLEAR_STATE'});

const getAuditSettings = () => dispatch => {
  const save = data => ({ type: 'ADMIN_SETTINGS_INIT_AUDIT_SETTINGS', data });
  setting.audit
    .get()
    .then(response => {
      dispatch(save(response.data.data.settings));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const updateAuditSettings = data => dispatch => {
  const params = {
    ...data,
    duplicateSandTicketNumbersRuleEnabled: Number(
      data.duplicateSandTicketNumbersRuleEnabled,
    ),
    minimumMinutesForCompletionRuleEnabled: Number(
      data.minimumMinutesForCompletionRuleEnabled,
    ),
    noTimestampRuleEnabled: Number(data.noTimestampRuleEnabled),
    openMoreThan24HoursRuleEnabled: Number(data.openMoreThan24HoursRuleEnabled),
  };

  setting.audit
    .update(params)
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const clearAuditSettingsState = () => ({ type: 'SETTINGS_AUDIT_SETTINGS_CLEAR_STATE'});

const updateAlertsSettings = data => () => {
  setting.alerts
    .update(data)
    .then(response => {
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const actions = {
  getSettings,
  applySettings,
  applyQueueMark,
  getCurrentFuelPrice,
  getFuelBreakdown,
  getCompanyCredentials,
  getAuditSettings,
  updateAuditSettings,
  updateAlertsSettings,
  updateCompanyCredentials,
  clearCurrentSettingsState,
  clearCopmanyCredentialsState,
  clearAuditSettingsState,
};

export default actions;
