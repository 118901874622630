import React from 'react';
import { connect } from 'react-redux';
import Select from 'components/shared/Select';
import actions from 'store/actions/Queue';

const Filters = props => {
  const { filter, setFilter, dataFilter } = props;
  const { district, equipment } = dataFilter;
  const { districtId, equipmentId } = filter;
  const districtOptions = district.map(item => {
    return {
      value: item.id,
      label: `#${item.id} | ${item.name}`,
    };
  });
  districtOptions.unshift({ value: 0, label: 'All districts' });
  const equipmentOptions = equipment.map(item => {
    return {
      value: item.id,
      label: `#${item.id} | ${item.name}`,
    };
  });
  equipmentOptions.unshift({ value: '', label: 'All equipment types' });

  return (
    <div className="row">
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h3>Filters</h3>
        </div>
        <div className="ibox-content">
          <div className="row clerfix">
            <div
              className="form-group has-feedback col-md-3"
              style={{ paddingLeft: 0 }}>
              <Select
                label="Districts"
                value={districtOptions.find(
                  districtOption => districtOption.value == districtId,
                )}
                onChange={item => setFilter(item.value, 'districtId')}
                options={districtOptions}
                testSelector="queue_filters_districts_select"
              />
            </div>
            <div className="form-group has-feedback col-md-3">
              <Select
                label="Equipment types"
                value={equipmentOptions.find(
                  equipmentOption => equipmentOption.value == equipmentId,
                )}
                onChange={item => setFilter(item.value, 'equipmentId')}
                options={equipmentOptions}
                testSelector="queue_filters_equipment-types_select"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filter: state.queue.filter,
});

const mapDispatchToProps = dispatch => ({
  setFilter: (value, name) => dispatch(actions.setFilter(value, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
