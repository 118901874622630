import axios from 'axios';

export const aws = {
  uploadToS3(file, url, type) {
    return fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': type ? type : 'image/png',
      },
    })
  }
}