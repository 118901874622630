import React from 'react';
import NavigationItem from './NavigationItem';
import { routes } from './components/routes';

const constructNavigationList = (listItem, parentItem = null) => {
  const childListItems = listItem.children && listItem.children.length > 0 &&
    listItem.children.map(childListItem =>
      constructNavigationList(childListItem, listItem),
    );

  return (
    <NavigationItem
      key={listItem.title}
      title={listItem.title}
      path={listItem.path}
      icon={listItem.icon}
      downIcon={listItem.downIcon}
      items={childListItems || []}
      parentPath={parentItem && parentItem.path}
    />
  );
};

export default routes().map(constructNavigationList);
