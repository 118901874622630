const ordersFiltersReducerDefaultState = {
  jobId: '',
  locationId: '',
  districtId: '',
  driverId: '',
  carrierId: '',
  jobType: '',
  status: '',
  sandSites: true,
  wellSites: true,
  drivers: true,
  waterDisposal: true,
  waterProduction: true,
};

export default (state = ordersFiltersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_JOB_ID':
      return {
        ...state,
        jobId: action.jobId,
      };
    case 'SET_LOCATION_ID':
      return {
        ...state,
        locationId: action.locationId,
      };
    case 'SET_DISTRICT_ID':
      return {
        ...state,
        districtId: action.districtId,
      };
    case 'SET_DRIVER_ID':
      return {
        ...state,
        driverId: action.driverId,
      };
    case 'SET_CARRIER_ID':
      return {
        ...state,
        carrierId: action.carrierId,
      };
    case 'SET_JOB_TYPE':
      return {
        ...state,
        jobType: action.jobType,
      };
    case 'SET_DRIVER_STATUS':
      return {
        ...state,
        status: action.driverType,
      };
    case 'SET_SAND_SITES':
      return {
        ...state,
        sandSites: action.sandSites,
      };
    case 'SET_WELL_SITES':
      return {
        ...state,
        wellSites: action.wellSites,
      };
    case 'SET_DRIVERS':
      return {
        ...state,
        drivers: action.drivers,
      };
    case 'SET_WATER_DISPOSAL':
      return {
        ...state,
        waterDisposal: action.waterDisposal,
      };
    case 'SET_WATER_PRODUCTION':
      return {
        ...state,
        waterProduction: action.waterProduction,
      };
    default:
      return state;
  }
};
