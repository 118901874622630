import { reducer as notificationsReducer } from 'reapop';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { purchaseOrders } from 'store/reducers/PurchaseOrders';
import { slsApiSlice } from 'features/api/slsApiSlice';
import { alert } from './reducers/alert.reducer';
import auth from '../reducers/Login';
import { resourceReducer } from './resourceReducer/reducer';
import updateProfile from '../reducers/UserProfile';
import events from '../../components/common/Events/reducer';
import Header from '../../components/common/Header/reducer';
import pagination from '../../components/common/ReduxPagination/reducer';
import layout from './reducers/layoutReducers';
import newReducers from '../reducers';
import { apiSlice } from '../../features/api/apiSlice'

const rootReducer = {
    ...newReducers,
    alert,
    auth,
    events,
    notifications: notificationsReducer(),
    pagination,
    purchaseOrders,
    resourceReducer,
    Header,
    updateProfile,
    layout,
    loadingBar: loadingBarReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [slsApiSlice.reducerPath]: slsApiSlice.reducer,
  };

export default rootReducer;
