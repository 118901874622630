import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'features/api/apiSlice';
import { slsApiSlice } from 'features/api/slsApiSlice';

import rootReducer from './modules/index';

const middleware = [
  axiosMiddleware(axios),
  loadingBarMiddleware({
    promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }),
  apiSlice.middleware,
  slsApiSlice.middleware
];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middleware),
});
export default store;
