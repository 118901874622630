import { useMemo, useReducer } from 'react';
import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';

import authService from 'services/auth/service';
import { useJobsForDiversion } from 'api/v2/orders';
import { ROLES } from 'config/constants';

export const useDivertJobs = order => {
  const user = authService.getUser();
  const { data: jobs } = useJobsForDiversion({
    orderId: order.order_id,
    customerId: order.customer_id,
    equipmentId: order.equipment,
    jobId: order.jobId,
    carrierId: user.role === ROLES.CARRIER ? user.entityId : null,
  });

  const jobsList = useMemo(() => {
    const formattedJobs = (jobs || []).map(job => {
      job.label = `${job.jobId} | ${job.jobName}`;
      return job;
    });
    return filterOptions(formattedJobs, null, null, 'jobId', 'label');
  }, [jobs]);

  return jobsList;
};

/**
 * @typedef {{
 *  jobId: number;
 *  additionalMileage: number;
 *  description: string;
 * }} Divert
 */

const initialDivert = {
  jobId: 0,
  additionalMileage: 0,
  description: '',
};

/**
 *
 * @param {Divert} state
 * @returns {Divert}
 */
const DivertReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialDivert;
    default:
      return state;
  }
};

export const useDivertForm = () => {
  /** @type {[Divert, () => {}]} */
  const [data, dispatch] = useReducer(DivertReducer, initialDivert);

  const isValid = useMemo(
    () =>
      data.description?.trim().length &&
      data.additionalMileage >= 0 &&
      data.additionalMileage !== '' &&
      data.jobId,
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
