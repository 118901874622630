import { combineReducers } from '@reduxjs/toolkit';

const initReasons = {
  isLoaded: false,
  reasons: [],
  updateModal: {
    name: '',
    description: '',
  },
};

const reasons = (state = initReasons, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_REASONS':
      return {
        ...state,
        isLoaded: true,
        reasons: action.reasons,
      };
    case 'DATABASE_REASONS_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          parentReason: action.value.name || '',
        },
      };
    case 'DATABASE_REASONS_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          reasonId: action.value.id,
          name: action.value.name,
          description: action.value.description,
        },
      };
    case 'DATABASE_REASONS_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_REASONS_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
          description: '',
        },
      };
    default:
      return state;
  }
};

const initDistricts = {
  isLoaded: false,
  districts: [],
  updateModal: {
    name: '',
    districtDetails: '',
    isCarrierRatioEnabled: false,
    isManualDispatchEnabled: false,
    activeTrucks: 0,
  },
};

const districts = (state = initDistricts, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_DISTRICTS':
      return {
        ...state,
        isLoaded: true,
        districts: action.districts,
      };
    case 'DATABASE_DISTRICTS_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
        },
      };
    case 'DATABASE_DISTRICTS_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          id: action.value.id,
          districtDetails: action.value.district_details,
          isCarrierRatioEnabled: Boolean(action.value.isCarrierRatioEnabled),
          isManualDispatchEnabled: Boolean(
            action.value.isManualDispatchEnabled,
          ),
          activeTrucks: action.value.activeTrucks,
          name: action.value.name,
          accessLevel: action.value.accessLevel
        },
      };
    case 'DATABASE_DISTRICTS_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_DISTRICTS_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
          districtDetails: '',
          isCarrierRatioEnabled: false,
          isManualDispatchEnabled: false,
          activeTrucks: 0,
        },
      };
    default:
      return state;
  }
};

const initCommodity = {
  equipmentTypes: [],
  updateModal: {
    name: '',
    type: '',
    equipmentTypes: [],
    tags: [],
    file: '',
    documents:[],
    driverDisplay: '',
    uom: ''
  },
};

const commodities = (state = initCommodity, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_COMMODITIES':
      return {
        ...state,
        isLoaded: true,
        districts: action.districts,
      };
    case 'DATABASE_COMMODITIES_GET_EQUIPMENT_TYPES':
      return {
        ...state,
        equipmentTypes: action.equipmentTypes,
      };
    case 'DATABASE_COMMODITIES_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          name: '',
          type: '',
          equipmentTypes: [],
          status: action.status,
          tags: [],
          documents: [],
          driverDisplay: '',
          uom: ''
        },
      };
    case 'DATABASE_COMMODITIES_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          id: action.value.id,
          type: action.value.commodityType,
          name: action.value.name,
          equipmentTypes: action.value.equipment,
          tags: action.value.tags,
          documents: action.value.documents,
          driverDisplay: action.value.driverDisplay,
          uom: action.value.unitOfMeasureId,
        },
      };
    case 'DATABASE_COMMODITIES_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_COMMODITIES_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
          type: '',
          equipmentTypes: [],
          tags: [],
          file: '',
          documents:[],
          driverDisplay: '',
          uom: ''
        },
      };
    default:
      return state;
  }
};

const initCommodityReference = {
  updateModal: {
    id: '',
    name: '',
    extRef: ''
  },
};

const commoditiesReference = (state = initCommodityReference, action) => {
  switch (action.type) {
    case 'DATABASE_COMMODITIES_REFERENCE_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          extRef: action.value.extRef
        },
      };
    case 'DATABASE_COMMODITIES_REFERENCE_SET_MODAL_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_COMMODITIES_REFERENCE_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          id: '',
          name: '',
          extRef: ''
        },
      };
    default:
      return state;
  }
};

const initCommodityTypes = {
  commodityTypes: [],
  options:[],
};

const commodityTypes = (state = initCommodityTypes, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_COMMODITY_TYPES':
      return {
        ...state,
        commodityTypes: action.commodityTypes
      };
    case 'DATABASE_SAVE_COMMODITY_TYPES_SELECT':
      return {
        ...state,
        options: action.options
      };
    default:
      return state;
  }
};

const initTags = {
  tags: [],
  options:[],
};

const tags = (state = initTags, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_TAGS':
      return {
        ...state,
        tags: action.tags
      };
    case 'DATABASE_SAVE_TAGS_SELECT':
      return {
        ...state,
        options: action.options
      };
    default:
      return state;
  }
};

const initVendors = {
  isLoaded: false,
  vendors: [],
  updateModal: {
    name: '',
    types: []
  },
  filter: {
    types: [],
    name: ''
  },
  vendorTypes: []
};

const vendors = (state = initVendors, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_VENDOR':
      return {
        ...state,
        isLoaded: true,
        vendors: action.vendors,
      };
    case 'DATABASE_SAVE_VENDOR_TYPES':
      return {
        ...state,
        vendorTypes: action.types,
      };
    case 'DATABASE_VENDOR_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
        },
      };
    case 'DATABASE_VENDOR_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          id: action.value.id,
          name: action.value.name,
          types: action.value.types
        },
      };
    case 'DATABASE_VENDOR_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_VENDOR_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
          types: []
        },
      };
      case 'DATABASE_VENDOR_SET_FILTER_VALUE':
        return {
          ...state,
          filter: {
            ...state.filter,
            [action.name]: action.value,
          }
        };
    default:
      return state;
  }
};

const initCertificates = {
  isLoaded: false,
  certificates: [],
  updateModal: {
    name: '',
  },
};

const certificates = (state = initCertificates, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_CERTIFICATES':
      return {
        ...state,
        isLoaded: true,
        certificates: action.certificates,
      };
    case 'DATABASE_CERTIFICATES_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
        },
      };
    case 'DATABASE_CERTIFICATES_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          id: action.value.id,
          name: action.value.name,
        },
      };
    case 'DATABASE_CERTIFICATES_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_CERTIFICATES_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
        },
      };
    default:
      return state;
  }
};

const initEquipmentTypes = {
  isLoaded: false,
  equipmentTypes: [],
  updateModal: {
    name: '',
  },
};

const equipmentTypes = (state = initEquipmentTypes, action) => {
  switch (action.type) {
    case 'DATABASE_SAVE_EQUIPMENT_TYPES':
      return {
        ...state,
        isLoaded: true,
        equipmentTypes: action.equipmentTypes,
      };
    case 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_ADD_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
        },
      };
    case 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_EDIT_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          status: action.status,
          id: action.value.id,
          name: action.value.name,
        },
      };
    case 'DATABASE_EQUIPMENT_TYPES_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        updateModal: {
          ...state.updateModal,
          [action.name]: action.value,
        },
      };
    case 'DATABASE_EQUIPMENT_TYPES_CLEAR_MODAL_UPDATE':
      return {
        ...state,
        updateModal: {
          name: '',
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  reasons,
  districts,
  commodities,
  commoditiesReference,
  vendors,
  certificates,
  equipmentTypes,
  commodityTypes,
  tags,
});
