import moment from 'moment';
import { useEffect, useMemo, useReducer } from 'react';
import targetValue from 'utils/targetValue';

/**
 * @typedef {{
 *   order_id: string;
 *   stage: string;
 *   sort: string;
 *   truck: string;
 *   statuses: number[];
 *   carrierIds: number[];
 *   customerIds: number[];
 *   districtIds: number[];
 *   jobIds: number[];
 *   startDate: import('moment').Moment | Date() | string;
 *   endDate: import('moment').Moment | Date() | string;
 *   origin: string;
 *   show: {};
 * }} OrderFilters
 */

const initialOrderFilters = {
  order_id: '',
  stage: '',
  sort: '',
  truck: '',
  statuses: [],
  carrierIds: [],
  customerIds: [],
  districtIds: [],
  jobIds: [],
  startDate: moment().subtract(3, 'day'),
  endDate: moment(),
  origin: '',
  show: {},
};

/**
 * @typedef {
 * { type: 'init'; data: OrderFilters[]; } |
 * { type: 'set_value'; name: string; value: string; } |
 * { type: 'clear_state'}
 * } OrderFiltersAction
 */

/**
 *
 * @param {OrderFilters} state
 * @param {OrderFiltersAction} action
 * @returns {OrderFilters}
 */
const OrderFiltersReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialOrderFilters;
    default:
      return state;
  }
};

export const useOrderFilters = ({ defaultValue }) => {
  /** @type {[OrderFilters, () => void]} */
  const [data, dispatch] = useReducer(OrderFiltersReducer, initialOrderFilters);

  useEffect(() => {
    if (defaultValue) {
      dispatch({
        type: 'init',
        data: defaultValue,
      });
    }
  }, [defaultValue]);

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    handlers,
  };
};
