import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';

import {
  useCarrierDocs,
  useDeleteCarrierDoc,
  useVerifyCarrierDoc,
} from 'api/v2/carriers';
import InsuranceDetails from './InsuranceDetails';
import { useInsuranceModal } from './hooks';

const Documents = ({ showModalDocs, closeDocs, carrierId }) => {
  const { data: carrierDocs, isLoading } = useCarrierDocs(carrierId);
  const { mutate: deleteDoc } = useDeleteCarrierDoc(carrierId);
  const { mutate: verifyCarrierDoc } = useVerifyCarrierDoc(carrierId);

  const [
    { showModalInsurance, insureDoc },
    { openInsurance, closeInsurance },
  ] = useInsuranceModal();

  const renderCarrierDoc = item => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.description}</td>
      <td>{item.docType}</td>
      {}
      <td>{item.is_verified ? 'Verified' : 'Not verified'}</td>
      <td>
        <Authorize
          abilityPermissions={[
            { resource: 'Carrier-documents', permissions: ['read'] },
          ]}>
          <a target="_blank" rel="noopener noreferrer" href={item.docLink}>
            <Button
              theme="small"
              colour="green"
              testSelector="admin-carriers_docs_view_btn">
              View
            </Button>
          </a>
        </Authorize>
        <Authorize
          abilityPermissions={[
            { resource: 'Carrier-documents', permissions: ['update'] },
          ]}>
          {!item.is_verified && (
            <Button
              onClick={() => verifyCarrierDoc(item.id)}
              theme="small"
              testSelector="admin-carriers_docs_verify_btn">
              Verify
            </Button>
          )}
        </Authorize>
        <Authorize
          abilityPermissions={[
            { resource: 'Carrier-documents', permissions: ['delete'] },
          ]}>
          <Button
            onClick={() => deleteDoc(item.id)}
            theme="small"
            colour="red"
            testSelector="admin-carriers_docs_delete_btn">
            Delete
          </Button>
        </Authorize>
        {item.docType === 'insurance' && (
          <Button
            onClick={() => openInsurance(item)}
            theme="small"
            colour="orange"
            testSelector="admin-carriers_docs_insurance_btn">
            Insurance Details
          </Button>
        )}
      </td>
    </tr>
  );

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showModalDocs}
        onHide={closeDocs}>
        <Modal.Header>
          <h3>Documents List</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            {isLoading ? (
              <Loader />
            ) : (
              <table
                id="datatable_table"
                className="table table--striped table--hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{(carrierDocs || []).map(renderCarrierDoc)}</tbody>
              </table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeDocs}
            colour="white"
            testSelector="admin-carriers_docs_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <InsuranceDetails
        insureDoc={insureDoc}
        showModalInsurance={showModalInsurance}
        closeInsurance={closeInsurance}
      />
    </div>
  );
};
export default Documents;
