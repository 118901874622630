import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import actions from 'store/actions/ManageDatabase';
import AddEditModal from './AddEditModal';

const EquipmentTypes = ({
  equipmentTypes,
  isLoaded,
  init,
  setModalAddValue,
  setModalEditValue,
}) => {
  const [showUpdate, turnShowUpdate] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openUpdate = (status, value) => {
    switch (status) {
      case 'add':
        setModalAddValue(value || {});
        break;
      case 'edit':
        setModalEditValue(value);
        break;
      default:
        break;
    }
    turnShowUpdate(true);
  };
  return (
    <div>
      {showUpdate && (
        <AddEditModal
          showUpdate={showUpdate}
          closeUpdate={() => turnShowUpdate(false)}
        />
      )}
      <div className="wrapper-content">
        <div className="row">
          <div>
            {isLoaded ? (
              <div className="ibox">
                <div className="ibox-content">
                  <Authorize
                    abilityPermissions={[
                      {
                        resource: 'Database',
                        permissions: ['create'],
                      },
                    ]}
                    access={['JOB_MANAGER']}>
                    <Button
                      onClick={() => openUpdate('add')}
                      inverse
                      className="float-end"
                      testSelector="database_equipment-types_add_btn">
                      <Icon className="icon--margin-right" icon="plus-square" />
                      Add Equipment Type
                    </Button>
                  </Authorize>
                  {equipmentTypes.map(item => (
                    <div
                      key={`key${item.id}`}
                      className="tree-node tree-node-content">
                      <span
                        className="tree-node-content-name"
                        style={{ color: '#ffffff' }}>
                        <strong>
                          #{item.id} | {item.name}{' '}
                        </strong>
                      </span>
                      <span>
                        <Authorize
                          abilityPermissions={[
                            {
                              resource: 'Database',
                              permissions: ['update'],
                            },
                          ]}
                          access={['JOB_MANAGER']}>
                          <Button
                            onClick={() => openUpdate('edit', item)}
                            theme="small"
                            testSelector="database_equipment-types_edit_btn">
                            <Icon icon="pencil" />
                          </Button>
                        </Authorize>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="ibox">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  equipmentTypes: state.database.equipmentTypes.equipmentTypes,
  isLoaded: state.database.equipmentTypes.isLoaded,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.getEquipmentTypes()),
  setModalAddValue: value =>
    dispatch(actions.setModalAddValueEquipmentTypes(value)),
  setModalEditValue: value =>
    dispatch(actions.setModalEditValueEquipmentTypes(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EquipmentTypes);
