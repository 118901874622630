import React, { useState } from 'react';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import AutomatizeLogo from 'assets/automatize_icon.svg';
import ForgotPass from '../ForgotPass';

const LoginForm = props => {
  const { submitLogin, setLogin, setPassword, login } = props;
  const { email, password, errorCredentials, errorMessage } = login;
  const [showModal, turnShowModal] = useState(false);

  return (
    <div className="login__container">
      {showModal && (
        <ForgotPass
          {...props}
          showModal={showModal}
          closeModal={() => turnShowModal(false)}
        />
      )}
      <div className="login__container--branding">
        <img
          src={AutomatizeLogo}
          className="login__container--branding-logo"
          alt="Automatize logo icon"
        />
        <h1 className="login__container--branding-welcome">
          Welcome to Automatize
        </h1>
        <p className="login__container--branding-intro">
          Sign in to your account
        </p>
      </div>
      <form className="m-t" onSubmit={submitLogin}>
        {errorCredentials && (
          <div className="login__container--incorrect-credentials">
            {/* Incorrect email or password. */}
            {errorMessage}
          </div>
        )}
        <div
          className={`form-group ${
            !email || !email.length ? ' has-error' : ''
          }`}>
          <Input
            type="email"
            label="Email"
            name="user"
            onChange={setLogin}
            required
            testSelector="login_form_email_input"
          />
        </div>
        <div
          className={`form-group ${
            !password || !password.length ? ' has-error' : ''
          }`}>
          <Input
            type="password"
            label="Password"
            name="pass"
            onChange={setPassword}
            required
            testSelector="login_form_password_input"
          />
        </div>
        <Button
          type="submit"
          theme="full-width"
          inverse
          testSelector="login_form_login_btn"
          disabled={!password || !password.length || !email || !email.length}>
          Login
        </Button>
      </form>
      <span
        onClick={() => turnShowModal(true)}
        title="Reset password"
        className="do-actiongin__forgot-password"
        data-test="forgot_password">
        Forgot password?
      </span>
      <span> - </span>
      <a
        className="do-actiongin__email-support"
        href="mailto:support@automatize.com"
        target="_blank"
        title="Email support">
        support@automatize.com
      </a>
    </div>
  );
};

export default LoginForm;
