import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import orderDetailsModel from 'models/OrderDetails';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

const Demurrage = props => {
  const {
    info,
    demurrage,
    closeDemurr,
    showModalDemurrage,
    setDemurrage,
    demurrageSave,
  } = props;

  const { selectId } = demurrage;
  const { demurrageReasons } = info;
  const { demurrageList } = orderDetailsModel;
  const demurrageReasonsList = demurrageList(demurrageReasons).map(item => ({
    value: item.id,
    label: `${item.symbol || ''} ${item.name}`,
    disabled: item.disabled,
  }));

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${20}%` }}
        className="modal-container"
        show={showModalDemurrage}
        onHide={closeDemurr}>
        <form onSubmit={e => demurrageSave(e, closeDemurr)}>
          <Modal.Header>
            <h4 className="modal-title">Reason for demurrage</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-12">
                <Select
                  placeholder="Choose Reasons"
                  onChange={item => setDemurrage(item.value)}
                  value={demurrageReasonsList.find(
                    item => item.value === selectId,
                  )}
                  options={demurrageReasonsList}
                  isOptionDisabled={option => option.disabled}
                  testSelector="order-details_pricing_demurrage_reason_select"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeDemurr}
              colour="white"
              testSelector="order-details_pricing_demurrage-modal_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              testSelector="order-details_pricing_demurrage-modal_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  info: state.orderDetails.info,
  demurrage: state.orderDetails.demurrage,
});

const mapDispatchToProps = dispatch => ({
  setDemurrage: value => dispatch(actions.setDemurrage(value)),
  demurrageSave: (e, closeDemurr) => {
    e.preventDefault();
    dispatch(actions.editDemurrage(closeDemurr));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Demurrage);
