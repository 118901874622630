import { client } from 'api/client';

export const get = async () => {
  const { data } = await client.get('v2/certificates');
  return data.data;
}

export const edit = async (params) => {
  const { data } = await client.put(`v2/certificates/${params.id}`);
  return data.data;
}

export const add = async (params) => {
  const { data } = await client.post('v2/certificates', params);
  return data.data;
}

export const remove = async (params) => {
  const { data } = await client.delete(`v2/certificates/${params.certificateId}`);
  return data.data;
}
