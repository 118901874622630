import { successAlert, errorAlert } from 'utils/swalAlerts';
import { addRole, deleteRole, updateRole } from 'services/roles/service';
import { getAllRolesAndPermissions } from 'services/roles/service';

export const loadPermissionDataAction = companyTypeId => async dispatch => {
  dispatch({ type: 'ROLES_INIT_PERMISSIONS_CLEAR' });

  const res = await getAllRolesAndPermissions(companyTypeId);

  const remap = {
    rolesPermissions: res.data.roles.map(role => {
      const result = {
        role: {
          role: role.name,
          rid: role.name.replace(/ /g, '_'),
          _id: role.name,
        },
        rid: role.name.replace(/ /g, '_'),
        description: '',
        _id: role.name,
        roleId: role.id,
      };

      const permissions = [];

      role.permissions.forEach(grant => {
        if (!Array.isArray(grant.resource)) grant.resource = [grant.resource];
        if (!Array.isArray(grant.action)) grant.action = [grant.action];

        grant.resource.forEach(resource => {
          const fullPermissions = res.data.permissions.find(
            permission => permission.resource === resource,
          );
          const grants = grant.action.map(grant => ({
            gid: grant === '*' ? 5 : grant,
            _id: grant,
            grant: grant,
          }));

          if (fullPermissions) {
            const full = !fullPermissions.grants.some(grant => {
              return !grants.find(g => g._id === grant);
            });

            if (full) {
              if (!grants.find(g => g.gid === 5))
                grants.push({ gid: 5, _id: '*', grant: '*' });
            }
          }

          permissions.push({
            pid: {
              pid: resource.replace(/ /g, '_'),
              _id: resource,
            },
            grants,
          });
        });
      });

      result.permissions = permissions;
      result.role.permissions = permissions;

      return result;
    }),
    permissions: res.data.permissions.map(permission => {
      return {
        ...permission,
        permission: permission.resource,
        pid: permission.resource.replace(/ /g, '_'),
        description: '',
        _id: permission.resource,
        grants: permission.grants
          .map((grant, index) => {
            return { gid: grant, grant: grant, _id: grant };
          })
          .concat([{ gid: 5, grant: '*', _id: '*' }]),
      };
    }),
  };
  return dispatch({
    data: remap,
    type: 'ROLES_INIT_PERMISSIONS_SUCCESS',
  });
};

export const changeCompanyTypeIdAction = companyTypeId => dispatch =>
  dispatch({ type: 'ROLES_CHANGE_COMPANY_TYPE_ID', id: companyTypeId });

// Save Changes and reset states
export const saveChangesAction = (editRole, data, resetToInitStates) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const companyTypeId = state.roles.permissions.companyTypeId;
  // On success call resetToInitStates() to close and reset all needed states
  if (editRole) {
    updateRole(data)
      .then(response => {
        successAlert(response);
        resetToInitStates();
        dispatch(loadPermissionDataAction(companyTypeId));
      })
      .catch(error => {
        errorAlert(error, 'Failed to update Role');
      });
  } else {
    addRole(data, companyTypeId)
      .then(response => {
        successAlert(response);
        resetToInitStates();
        dispatch(loadPermissionDataAction(companyTypeId));
      })
      .catch(error => {
        errorAlert(error, 'Failed to add Role');
      });
  }
};

export const deleteRoleAction = (editRole, resetToInitStates) => async (
  dispatch,
  getState,
) => {
  const state = getState();

 await deleteRole(editRole)
    .then(response => {
      successAlert(response);
      resetToInitStates();
      dispatch(loadPermissionDataAction(state.roles.permissions.companyTypeId));
    })
    .catch(error => {
      errorAlert(error, 'Failed to delete Role');
    });
};
