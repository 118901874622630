export const initSettings = settings => {
  return {
    maxDistance: settings.filter(item => item.type == 1)[0].value || '',
    hour: Math.floor(settings.filter(item => item.type == 2)[0].value / 60) || '',
    minute: settings.filter(item => item.type == 2)[0].value % 60 || '',
    driverTimeout: settings.filter(item => item.type == 3)[0].value || '',
    pricePerMile: settings.filter(item => item.type == 4)[0].value || '',
    carrierPercentage: settings.filter(item => item.type == 5)[0].value || '',
    enablePayments: Boolean(Number(settings.filter(item => item.type == 6)[0].value)) || false,
    sessionDuration: settings.filter(item => item.type == 9)[0].value || '',
    enableCallsForPendingOrders: Boolean(
      Number(settings.filter(item => item.type == 21)[0].value) || false,
    ),
  };
};