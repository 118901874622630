import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
	get,
	add,
	voidPayment,
} from './queries';

export const useCustomerPayments = ({ id, status }) =>
	useQuery({
		queryFn: () => get(id, status),
		queryKey: ['customers', id, 'payments', { status }],
		enabled: !!id
	});

export const useAddCustomerPayment = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ data }) => add({ data, id: customerId }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'payments'] });
			swal('Success', 'Payment added', 'success');
		},
	});
}

export const useVoidCustomerPayment = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ data, id }) => voidPayment({ data, id, customerId }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'payments'] });
			swal('Success', 'Payment voided', 'success');
		},
	});
}
