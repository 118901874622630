import { useMemo, useReducer, useEffect } from 'react';
import moment from 'moment';
import multiSelectOptions from 'utils/multiSelectOptions';
import swal from 'bootstrap-sweetalert';
import { cloneDeep } from 'lodash';

import targetValue from 'utils/targetValue';

import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';
import { useTicketTypes } from 'api/v2/jobs';
import { useCertificates } from 'api/v2/certificates';
import { useDistricts } from 'api/v2/districts';
import { useCarriers } from 'api/v2/carriers';
import {
  useCustomers,
  useCustomerPayments,
  useCustomerRateTables,
  useCustomerDemurrageTables,
  useCustomerBillingProfiles,
  useValidateCustomerJobId
} from 'api/v2/customers';
import { useCustomerLocations } from 'api/v2/locations';

export const useCommodityOptions = (stageDesign, customerId) => {
  const { data: customerCommodities } = useCustomerCommodities(customerId);
  const { data: defaultCommodities } = useCommodities();
  const commodities = customerId ? customerCommodities : defaultCommodities;
  const commodityOptions = multiSelectOptions(commodities, 'id', 'name');

  if (stageDesign?.length) {
    const filteredCommodities = commodities.filter(
      commodity => !stageDesign.some(sd => sd.sandTypeId === commodity.id),
    );
    return [
      multiSelectOptions(filteredCommodities, 'id', 'name'),
      commodityOptions,
    ];
  }

  return [commodityOptions, commodityOptions];
};

export const useJobDesignCommodities = (jobDesign, customerId) => {
  const { data: customerCommodities } = useCustomerCommodities(customerId);
  const { data: defaultCommodities } = useCommodities();
  const commodities = customerId ? customerCommodities : defaultCommodities;

  const commodityOptions = useMemo(() => {
    if (jobDesign?.length) {
      const filteredCommodities = commodities.filter(commodity =>
        jobDesign.some(jd => {
          if (jd.commodity) {
            return jd.commodity.id === commodity.id;
          }
          return jd.sandTypeId === commodity.id;
        })
      );
      const commoditiesWithUOMName = filteredCommodities.map(commodity => {
        const matchingJobDesign = jobDesign.find(jd => {
          if (jd.commodity) {
            return jd.commodity.id === commodity.id;
          }
          return jd.sandTypeId === commodity.id;
        });

        return {
          ...commodity,
          commodityUOMName: matchingJobDesign ? matchingJobDesign.commodityUOMName : ''
        };
      });
      return multiSelectOptions(commoditiesWithUOMName, 'id', 'name');
    }
    return multiSelectOptions(commodities, 'id', 'name');
  }, [jobDesign, commodities]);

  return commodityOptions;
};

export const useJobDesignCommoditiesUOM = (jobDesign, storageConfig) => {
  const configUOM = useMemo(() => {
    if (jobDesign?.length) {
      return storageConfig.map(sc => {
        const matchingJobDesign = jobDesign.find(jd => jd.sandTypeId === sc.commodityId);
        return {
          ...sc,
          commodityUOMName: matchingJobDesign ? matchingJobDesign.commodityUOMName : ''
        };
      });
    }
    return storageConfig;
  }, [jobDesign, storageConfig]);
  return configUOM;
};

const initialAddJobFormState = {
  name: '',
  customerId: '',
  districtIds: [],
  wellSiteId: '',
  rateTableId: '',
  demurrageTableId: '',
  jobOperationType: undefined,
  equipment: [],
  jobDesign: [],
  stagingSite: '',
  numberOfStages: '',
  targetStagesPerDay: '',
  totalWeightPerJob: '',
  directions: '',
  startDate: moment().add(1, 'minute'),
  endDate: moment().add(1, 'minute'),
  sendCompletionCodeToDriver: false,
  pumpTime: '',
  wirelineTime: '',
  certificates: [],
  sandTicketDesign: [],
  minDriversAmount: 0,
  demurrageAlert: 0,
  isDraft: false,
  messagesAllowed: false,
  phoneNumbers: [{ code: '+1' }],
  storageConfig: [],
  useCommodityPrice: false,
  operatorList: [],
  containersPerTruck: '',
  customerJobId: '',
  customerProjectId: '',
  navId: '',
  billingProfileId: '',
  wellSites: [],
  billingProfiles: [],
  useBillingProfile: false,
  allIn: 0,
  step: 1,
  addJobInitData: {
    customers: [],
    companyArray: [],
    districts: [],
    certificatesList: [],
    sandTicketTypes: [],
    rateTable: [],
    demurrage: [],
  }
};

const AddJobFormReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        addJobInitData: {
          ...state.addJobInitData,
          ...action.data
        }
      };
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value
      }
    case 'clear_state':
      return {
        ...initialAddJobFormState,
        addJobInitData: state.addJobInitData
      }
    case 'set_customer_info':
      return {
        ...state,
        [action.name]: action.value,
        billingProfileId: null,
        wellSiteId: null,
      }
    case 'set_array_value': {
      const newArray = cloneDeep(state[action.arrayName]);
      newArray[action.index][action.name] = action.value;

      return {
        ...state,
        [action.arrayName]: newArray
      }
    }
    case 'delete_array_value': {
      const newArray = cloneDeep(state[action.arrayName]);
      newArray.splice(action.index, 1);

      return {
        ...state,
        [action.arrayName]: newArray
      }
    }
    case 'add_array_value': {
      const newArray = cloneDeep(state[action.arrayName]);
      newArray.push(action.value);

      return {
        ...state,
        [action.arrayName]: newArray
      }
    }
    default:
      return state;
  }
}

export const useAddJobForm = (selectedCustomerId, setSelectedCustomerId) => {
  const [state, dispatch] = useReducer(AddJobFormReducer, initialAddJobFormState);

  const { mutate: validateCustomerJobId } = useValidateCustomerJobId();

  const { data: sandTicketTypes, isLoading: sandTicketsLoading } = useTicketTypes();
  const { data: certificates, isLoading: certificatesLoading } = useCertificates();
  const { data: districts, isLoading: districtsLoading } = useDistricts();
  const { data: carriers, isLoading: carriersLoading } = useCarriers();
  const { data: customers, isLoading: customersLoading } = useCustomers();

  const { data: wellSites, isLoading: wellSitesLoading } = useCustomerLocations(selectedCustomerId);
  const { data: payments, isLoading: paymentsLoading } = useCustomerPayments({ id: selectedCustomerId });
  const { data: rateTables, isLoading: rateTablesLoading } = useCustomerRateTables(selectedCustomerId);
  const { data: demurrages, isLoading: demurragesLoading } = useCustomerDemurrageTables(selectedCustomerId);
  const { data: billingProfiles, isLoading: billingProfilesLoading } = useCustomerBillingProfiles({ id: selectedCustomerId });

  const customerInfoLoading = customersLoading || wellSitesLoading || paymentsLoading || rateTablesLoading || demurragesLoading || billingProfilesLoading;
  const isLoading = sandTicketsLoading || certificatesLoading || districtsLoading || carriersLoading || customersLoading;

  const handlers = useMemo(() => ({
    init: (data) => dispatch({ type: 'init', data }),
    setValue: (name) => (e) => dispatch({ type: 'set_value', name, value: targetValue(e) }),
    setCustomerInfo: (name, value) => dispatch({ type: 'set_customer_info', name, value }),
    clearState: () => dispatch({ type: 'clear_state' }),
    setStep: (step) => dispatch({ type: 'set_value', name: 'step', value: step }),
    setStartDate: (date) => dispatch({ type: 'set_value', name: 'startDate', value: date }),
    setEndDate: (date) => dispatch({ type: 'set_value', name: 'endDate', value: date }),
    setArrayValue: (value, index, name, arrayName) => dispatch({ type: 'set_array_value', value: targetValue(value), index, name, arrayName }),
    deleteArrayValue: (index, arrayName) => dispatch({ type: 'delete_array_value', index, arrayName }),
    addArrayValue: (arrayName, value) => dispatch({ type: 'add_array_value', value: value ?? {}, arrayName }),
    setOnsiteStorageType: (selectedType, index) => {
      dispatch({ type: 'set_array_value', value: selectedType.id, index, name: 'type', arrayName: 'storageConfig' });
      dispatch({ type: 'set_array_value', value: !!selectedType.sourceId, index, name: 'isLive', arrayName: 'storageConfig' });
    }
  }), []);


  useEffect(() => {
    if (state.step === 2) {
      validateCustomerJobId(
        { customerId: state.customerId, customerJobId: state.customerJobId },
        { onSuccess: (stageDesign) => handlers.setValue('jobDesign')(stageDesign) }
      );
    }
  }, [state.step, state.customerId, state.customerJobId, validateCustomerJobId, handlers]);

  const companyArray = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const formattedCarriers = carriers.map(carrier => ({ ...carrier, id: `${carrier.id}/3` }));
    const formattedCustomers = customers.map(customer => ({ ...customer, id: `${customer.id}/2` }));
    return [...formattedCarriers, ...formattedCustomers];
  }, [carriers, customers, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      handlers.init({
        sandTicketTypes,
        certificatesList: certificates,
        districts,
        companyArray,
        customers
      });
    }

    if (selectedCustomerId && !customerInfoLoading && !rateTables?.length && !payments?.length) {
      swal(
        "You can't create a Job for this customer. Please create rate table or payment first",
        '',
        'error',
      );
      handlers.setValue('customerId')('');
      handlers.setValue('customer')(null);
      setSelectedCustomerId(null);
    }

    if (selectedCustomerId && !customerInfoLoading) {
      handlers.setCustomerInfo('wellSites', wellSites);
      handlers.setCustomerInfo('billingProfiles', billingProfiles);
      handlers.setCustomerInfo('payments', payments);
      handlers.setCustomerInfo('rateTables', rateTables);
      handlers.setCustomerInfo('demurrages', demurrages);
    }
  }, [isLoading,
    selectedCustomerId,
    setSelectedCustomerId,
    handlers,
    customerInfoLoading,
    companyArray,
    customers,
    districts,
    certificates,
    sandTicketTypes,
    wellSites,
    payments,
    rateTables,
    demurrages,
    billingProfiles,
  ]);


  return {
    state,
    handlers
  }
}
