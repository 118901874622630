export const decodeTemplateField = fields => {
    const decodedString = decodeURI(fields);
    const decodedArray = decodedString.split(',');
    const fieldsDecoded = [];
    decodedArray.map(item => {
      const field = item.split('|');
      if (field[0].length > 0) {
        fieldsDecoded.push({ field: field[0], name: field[1] });
      }
    });
    return fieldsDecoded;
  };