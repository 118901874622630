import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import CardToggle from 'components/common/CardToggle';
import { useCommodities } from 'api/v2/commodities';
import ProgressBars from '../ProgressBars';
import { formatPO } from './helpers';

const PO = ({ purchaseOrders }) => {
  const { data: commodities } = useCommodities();

  const renderPoItem = (data, index) => {
    const commodity = commodities.find(item => item.id === data.sandTypeId);

    return (
      <div key={data.poNo}>
        <div>
          <h4 style={{ display: 'inline' }}>
            {data.priority} - Commodity:{' '}
            <span className="text-navy">{data.sandType}</span> / #{data.poNo} -{' '}
            {`${data.total.toLocaleString(navigator.language)}`}
            {` ${data?.commodityUOMName ? data.commodityUOMName : ''}`}
          </h4>
          <br />
          <strong style={{ marginLeft: 10 }}>Customer: </strong>
          <span>{data.customerName}</span> - <strong>Job: </strong>
          <span>{data.name}</span>
        </div>
        <a
          data-bs-toggle="collapse"
          data-parent="#accordion"
          href={`#collapse-po-bar${index}`}>
          <ProgressBars data={data} index={index} />
        </a>
        <div id={`collapse-po-bar${index}`} className="card-collapse collapse">
          <h4>
            Delivered :{' '}
            <b>{`${data.delivered.toLocaleString(
              navigator.language,
            )} ${data?.commodityUOMName ? data?.commodityUOMName : ''}`}</b>{' '}
            Loaded :{' '}
            <b>{`${data.loaded.toLocaleString(
              navigator.language,
            )} ${data?.commodityUOMName ? data?.commodityUOMName : ''}`}</b>
            <br /> Scheduled :{' '}
            <b>{`${data.scheduled.toLocaleString(
              navigator.language,
            )} ${data?.commodityUOMName ? data?.commodityUOMName : ''}`}</b>{' '}
            Remaining :{' '}
            <b>{`${data.left.toLocaleString(navigator.language)} ${data?.commodityUOMName ? data?.commodityUOMName : ''}`}</b>
            <br /> Mileage : <b>{data.approvedMileage}</b> Travel time :
            <b> {data.travelTimeMinutesView}</b>
            <br />
            {data.comment !== null && data.comment.length > 0 ? (
              <span>
                Comment :<b> {data.comment}</b>
              </span>
            ) : null}
          </h4>
        </div>
        <hr />
      </div>
    );
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header as="h4">
          PO
          <CardToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ maxHeight: 345, overflowY: 'scroll' }}>
            {purchaseOrders.length === 0 ? (
              <h5>You have no PO yet</h5>
            ) : (
              formatPO(purchaseOrders).map(renderPoItem)
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default PO;
