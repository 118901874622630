import React from 'react';

const ZoomIn = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <g fillRule="nonzero">
      <path d="M19.623 17.815l-3.333-3.333-.24.24-.369-.37A6.483 6.483 0 0 0 5.898 5.9a6.479 6.479 0 0 0 0 9.164 6.48 6.48 0 0 0 8.453.616l.37.37-.24.239 3.333 3.333a1.28 1.28 0 0 0 1.81 0 1.277 1.277 0 0 0 0-1.807zm-5.967-4.158a4.491 4.491 0 0 1-6.35-6.35 4.491 4.491 0 0 1 6.35 6.35z" />
      <path d="M12.577 9.727h-1.19s-.114-.009-.114-.125v-1.18A.424.424 0 0 0 10.85 8h-.7a.424.424 0 0 0-.423.423v1.171c0 .124-.12.133-.12.133H8.423A.424.424 0 0 0 8 10.15v.7c0 .233.19.423.423.423h1.175s.13.003.13.127v1.177c0 .233.19.423.422.423h.7c.233 0 .423-.19.423-.423v-1.184c0-.114.13-.12.13-.12h1.174c.233 0 .423-.19.423-.423v-.7a.424.424 0 0 0-.423-.423z" />
    </g>
  </svg>
);

export default ZoomIn;
