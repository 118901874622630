import { combineReducers } from '@reduxjs/toolkit';
import moment from 'moment';

const initialStateExportConfig = {
  isLoadedConfig: false,
  config: [],
  isAllChecked: true,
  name: '',
};

const exportConfig = (state = initialStateExportConfig, action) => {
  switch (action.type) {
    case 'EXPORT_GET_CONFIG_REQUEST':
      return {
        ...state,
      };
    case 'EXPORT_GET_CONFIG_SUCCESS':
      return {
        ...state,
        isLoadedConfig: true,
        config: action.config,
      };
    case 'EXPORT_GET_CONFIG_FAILURE':
      return {};
    case 'EXPORT_CHECK_ALL_FIELDS':
      return {
        ...state,
        isAllChecked: !state.isAllChecked,
        config: state.config.map(item => ({
          value: item.value,
          isChecked: !state.isAllChecked,
        })),
      };
    case 'EXPORT_CHECK_ONE_FIELD':
      return {
        ...state,
        config: state.config.map(item => {
          if (item.value === action.name) {
            item.isChecked = !item.isChecked;
            return item;
          }
          return item;
        }),
      };
    case 'EXPORT_SET_TEMPLATE_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'EXPORT_CLEAR_MODAL_ADD_TEMPLATE':
      return {
        ...state,
        name: '',
      };
    default:
      return state;
  }
};

const initialStateExportTemplates = {
  isLoadedTemplates: false,
  templates: [],
  isLoadedTemplate: false,
  template: {},
  lookUpTemplates: [],
};

const templates = (state = initialStateExportTemplates, action) => {
  switch (action.type) {
    case 'EXPORT_GET_TEMPLATES_REQUEST':
      return {
        ...state,
      };
    case 'EXPORT_GET_TEMPLATE_REQUEST':
      return {
        ...state,
      };
    case 'EXPORT_GET_LOOKUP_TEMPLATE_SUCCESS':
      return {
        ...state,
        lookUpTemplates: action.templates,
      };
    case 'EXPORT_GET_TEMPLATES_SUCCESS':
      return {
        ...state,
        isLoadedTemplates: true,
        templates: action.templates,
      };
    case 'EXPORT_GET_TEMPLATE_SUCCESS':
      return {
        ...state,
        isLoadedTemplate: true,
        template: action.template,
      };
    case 'EXPORT_TEMPLATE_CLEAR_MODAL':
      return {
        ...state,
        isLoadedTemplate: false,
        template: {},
      };
    case 'GET_TEMPLATES_FAILURE':
      return {};
    default:
      return state;
  }
};

const initialModalPacketInvoice = {
  info: {},
  openModal: false,
  count: 0,
};

const modalPacketInvoice = (state = initialModalPacketInvoice, action) => {
  switch (action.type) {
    case 'EXPORT_MODAL_PACKET_INVOICE_INIT_DATA':
      return {
        ...state,
        info: action.info,
        openModal: true,
      };
    case 'EXPORT_MODAL_PACKET_INVOICE_TURN':
      return {
        info: {},
        openModal: false,
        count: 0,
      };
    case 'EXPORT_MODAL_PACKET_ADD_COUNT':
      return {
        ...state,
        count: state.count + 1,
      };
    default:
      return state;
  }
};

const initialModalPreview = {
  data: [],
  openModal: false,
  format: '',
  dataReady: false,
};

const modalPreview = (state = initialModalPreview, action) => {
  switch (action.type) {
    case 'EXPORT_MODAL_PREVIEW_INIT_DATA':
      return {
        ...state,
        data: action.data,
        openModal: true,
        format: action.format,
      };
    case 'EXPORT_MODAL_PREVIEW_DATA_READY':
      return {
        ...state,
        dataReady: true,
        data: action.data,
      };
    case 'EXPORT_MODAL_PREVIEW_CLOSE':
      return {
        data: [],
        openModal: false,
        format: '',
        dataReady: false,
      };
    default:
      return state;
  }
};

const initOrders = {
  startDate: moment().subtract(3, 'day'),
  endDate: moment().subtract(3, 'day'),
  selectedCustomers: [],
  selectedJobs: [],
  selectedCarriers: [],
  selectedDistricts: [],
  selectedStatus: [
    { value: 0, label: 'Not Assigned' },
    { value: 2, label: 'Order Accepted' },
    { value: 3, label: 'En Route to Destination' },
    { value: 5, label: 'Entering Loading Site' },
    { value: 1, label: 'Assigned, not accepted' },
    { value: 6, label: 'Entering Wellsite' },
  ],
  format: 'csv',
  sortBy: 'order_id',
  timeZone: '0',
  invoiceId: '',
  template: '',
  orderCount: 0,
  itemsPerPage: 10,
  totalPages: 1,
  orders: [],
  orderLoaded: false,
};

const exportSettings = (state = initOrders, action) => {
  switch (action.type) {
    case 'SET_EXPORT_DATE_RANGE':
      return {
        ...state,
        startDate: action.range.selection.startDate,
        endDate: action.range.selection.endDate,
      };
    case 'SELECT_EXPORT_DATA':
      return {
        ...state,
        [action.name]: action.selected,
      };
    case 'SET_EXPORT_SETTINGS':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'EXPORT_REFRESH_ORSER_COUNT':
      return {
        ...state,
        orderCount: action.count,
      };
    case 'EXPORT_SAVE_ORDERS':
      return {
        ...state,
        orders: action.orders,
        totalPages: action.totalPages,
        orderLoaded: true,
      };
    case 'EXPORT_REINIT_ORDERS':
      return {
        ...state,
        orderLoaded: false,
      };
    default:
      return state;
  }
};

export const exportPage = combineReducers({
  exportConfig,
  exportSettings,
  templates,
  modalPacketInvoice,
  modalPreview,
});
