import { createSelector } from 'reselect';

const getData = data => data;
const getProps = (data, props) => props;

export const locationSelector = createSelector(
  [getData, getProps],
  (data, onlyAlerted) => {
    const sandSiteIds = [];
    const wellSiteIds = [];
    const disposalSiteIds = [];
    const waterProdSiteIds = [];

    const formatedData = data.reduce((array, item) => {
      const baseItems = {
        customerId: item.customer.id,
        customerName: item.customer.name,
        jobId: item.id,
        jobName: item.name,
        operationType: item.operationType,
        jobDistricts: item.districts,
      };
      if (
        item.wellSite &&
        wellSiteIds.filter(id => id === item.wellSite.id).length === 0 &&
        item.orders.length > 0
      ) {
        array.push({
          ...baseItems,
          ...item.wellSite,
          type: 'well_site',
          position: {
            lat: parseFloat(item.wellSite.lat),
            lng: parseFloat(item.wellSite.lng),
          },
        });
        wellSiteIds.push(item.wellSite.id);
      }

      if (
        item.waterProductionSite &&
        waterProdSiteIds.filter(id => id === item.waterProductionSite.id)
          .length === 0 &&
        item.orders.length > 0
      ) {
        array.push({
          ...baseItems,
          ...item.waterProductionSite,
          type: 'water_production',
          position: {
            lat: parseFloat(item.waterProductionSite.lat),
            lng: parseFloat(item.waterProductionSite.lng),
          },
        });
        waterProdSiteIds.push(item.waterProductionSite.id);
      }

      if (item.orders.length) {
        item.orders.forEach(order => {
          const orderItems = {
            stage: order.stage,
            orderId: order.id,
            carrierId: order.carrier.id,
            carrierName: order.carrier.name,
          };
          if (order.driver) {
            array.push({
              ...baseItems,
              ...orderItems,
              ...order.driver,
              type: 'driver',
              status:
                order.status === 3 ||
                order.status === 4 ||
                order.status === 6 ||
                order.status === 7 ||
                order.status === 8
                  ? 'loaded'
                  : 'empty',
              position: {
                lat: parseFloat(order.driver.lat),
                lng: parseFloat(order.driver.lng),
              },
            });
          }

          if (
            order.sandSite &&
            sandSiteIds.filter(id => id === order.sandSite.id).length === 0
          ) {
            array.push({
              ...baseItems,
              ...order.sandSite,
              ...orderItems,
              type: 'sand_site',
              position: {
                lat: parseFloat(order.sandSite.lat),
                lng: parseFloat(order.sandSite.lng),
              },
              driver: order.driver,
            });
            sandSiteIds.push(order.sandSite.id);
          }

          if (
            order.waterDisposalSite &&
            disposalSiteIds.filter(id => id === order.waterDisposalSite.id)
              .length === 0
          ) {
            array.push({
              ...baseItems,
              ...order.waterDisposalSite,
              ...orderItems,
              type: 'water_disposal',
              position: {
                lat: parseFloat(order.waterDisposalSite.lat),
                lng: parseFloat(order.waterDisposalSite.lng),
              },
              driver: order.driver,
            });
            disposalSiteIds.push(order.waterDisposalSite.id);
          }
        });
      }

      return array;
    }, []);

    return onlyAlerted
      ? formatedData.filter(item => item.alerts)
      : formatedData;
  },
);
