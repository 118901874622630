import AdminCarriers from 'components/views/AdminCarriers';
import AdminSettings from 'components/views/AdminSettings';
import AdminAuditLog from 'components/views/AdminAuditLog';
// import Chat from 'components/views/Chat';
import Database from 'components/views/ManageDatabase';
import Districts from 'components/views/DistrictsList';
import DriversList from 'components/views/DriversList';
import JobPlans from 'components/views/JobPlans';
import exportPage from 'components/views/ExportPage';
import Home from 'components/views/Home';
import Invoice from 'components/views/Invoices';
import JobList from 'components/views/JobsList';
import Locations from 'components/views/Locations';
import ManageCustomers from 'components/views/AdminCustomers';
import Queue from 'components/views/Queue';
import CreditMemos from 'components/views/CreditMemos';
import Reconcile from 'components/views/Reconcile';
import Roles from 'components/views/Roles';
import TrackDrivers from 'components/views/TrackDrivers';
import Trailers from 'components/views/Equipments';
import Users from 'components/views/Users';
import ViewPO from 'components/views/ViewPO';

import { authorize } from 'components/common/Authorize';

const navItems = [
  // TODO need permissions from BE for home page
  {
    title: 'HOME',
    path: '/home',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" class="icon--fill"><path d="M10.047,4.603h-4.85C4.537,4.603,4,5.14,4,5.8v4.246c0,0.661,0.536,1.197,1.197,1.197h4.85 c0.661,0,1.198-0.536,1.198-1.197V5.8C11.244,5.14,10.708,4.603,10.047,4.603z M18.618,4.603H13.77 c-0.662,0-1.199,0.536-1.199,1.197v4.246c0,0.661,0.537,1.197,1.199,1.197h4.851c0.661,0,1.196-0.536,1.196-1.197V5.8 C19.816,5.14,19.281,4.603,18.618,4.603L18.618,4.603z M10.047,12.572h-4.85C4.537,12.572,4,13.107,4,13.77v4.246 c0,0.66,0.536,1.196,1.197,1.196h4.85c0.661,0,1.198-0.536,1.198-1.196v-4.248C11.244,13.107,10.707,12.572,10.047,12.572z M18.618,12.572H13.77c-0.662,0-1.199,0.535-1.199,1.197v4.246c0,0.66,0.537,1.197,1.199,1.197h4.851 c0.661,0,1.196-0.537,1.196-1.197V13.77C19.816,13.107,19.28,12.572,18.618,12.572L18.618,12.572z"/></svg>',
    component: Home,
    children: [],
  },
  {
    title: 'JOBS',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M4 8.328h4.328V4H4v4.328zm5.836 0h10.16V4H9.836v4.328zm.004 5.84H20V9.84H9.84v4.328zm0 5.83H20V15.67H9.84v4.328zM4 14.164h4.328V9.836H4v4.328zM4 20h4.328v-4.328H4V20z"/></svg>',
    path: '/jobs',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'View jobs',
        path: '/jobs-list',
        component: JobList,
        children: [],
      },
      {
        title: 'Districts',
        path: '/districts-list',
        component: Districts,
        children: [],
      },
      {
        title: 'Job Plans',
        path: '/job-plans',
        component: JobPlans,
        children: [],
      },
    ],
  },
  {
    title: 'ADMIN',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M15.24 12.931a3.849 3.849 0 0 0-.907-.114h-1.008c1.252-.775 2.12-2.324 2.12-4.134 0-2.576-1.742-4.666-3.889-4.666-2.146 0-3.89 2.09-3.89 4.666 0 1.81.867 3.359 2.122 4.134H9c-.373 0-.73.069-1.073.166C6.247 13.457 5 14.991 5 16.817v2.666h13.333v-2.666c0-1.887-1.328-3.472-3.093-3.886z"/></svg>',
    path: '/admin',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Carriers',
        path: '/carriers',
        component: AdminCarriers,
        children: [],
      },
    ],
  },
  {
    title: 'Teams',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><g transform="translate(4 5.013)"><ellipse cx="13.499" cy="1.8" rx="1.5" ry="1.8"/><ellipse cx="2.5" cy="1.8" rx="1.5" ry="1.8"/><path d="M4 5.5c0-.682.143-1.32.379-1.9H2c-1.1 0-2 .9-2 2v2h4.474A4.978 4.978 0 0 1 4 5.5zM14 3.6h-2.545c.236.579.38 1.219.38 1.9a4.99 4.99 0 0 1-.474 2.1H16v-2c0-1.1-.9-2-2-2z"/><path d="M10.68 8.686A2.886 2.886 0 0 0 10 8.6h-.756c.939-.581 1.59-1.743 1.59-3.1 0-1.932-1.307-3.5-2.917-3.5C6.307 2 5 3.568 5 5.5c0 1.357.65 2.519 1.591 3.1H6c-.28 0-.547.052-.805.125C3.935 9.08 3 10.231 3 11.6v2h10v-2a3.006 3.006 0 0 0-2.32-2.914z"/></g></svg>',
    path: '/teams',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Roles',
        path: '/roles',
        component: Roles,
        children: [],
      },
    ],
  },
  {
    title: 'EXPORT',
    path: '/export',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.636 18.545H6.455V6.91h1.454v2.182h7.273V6.909h1.454v11.636zm-5.09-13.09a.727.727 0 1 1 0 1.454.727.727 0 0 1 0-1.454zm5.09 0h-3.043A2.175 2.175 0 0 0 11.545 4c-.948 0-1.747.61-2.048 1.455H6.455C5.652 5.455 5 6.106 5 6.909v11.636C5 19.35 5.652 20 6.455 20h10.181c.804 0 1.455-.65 1.455-1.455V6.91c0-.803-.651-1.454-1.455-1.454z"/></svg>',
    component: exportPage,
    children: [],
  },
];

// add settings to admin
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Admin',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems
    .find(item => item.title === 'ADMIN')
    .children.push({
      title: 'Settings',
      path: '/settings',
      component: AdminSettings,
      children: [],
    });
}
// add customers to admin
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Customers',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems
    .find(item => item.title === 'ADMIN')
    .children.push({
      title: 'Customers',
      path: '/customers',
      component: ManageCustomers,
      children: [],
    });
}

// Audit Log
if (
  authorize({
    abilityPermissions: [{ resource: 'Admin', permissions: ['read'] }],
  })
) {
  navItems
    .find(item => item.title === 'ADMIN')
    .children.push({
      title: 'Audit Log',
      path: '/audit-log',
      component: AdminAuditLog,
      children: [],
    });
}

// add users to nav menu
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Users',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems
    .find(item => item.title === 'Teams')
    .children.push({
      title: 'Users',
      path: '/users',
      component: Users,
      children: [],
    });
}
// add PO to nav menu
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Purchase-orders',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems.push({
    title: 'PO',
    path: '/po',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M4 4h4.328v4.328H4zM9.836 4h4.328v4.328H9.836zM15.672 4H20v4.328h-4.328zM15.672 9.836H20v4.328h-4.328zM15.672 15.672H20V20h-4.328zM4 15.672h4.328V20H4zM4 9.836h4.328v4.328H4zM9.836 15.672h4.328V20H9.836zM9.836 9.836h4.328v4.328H9.836z"/></svg>',
    component: ViewPO,
    children: [],
  });
}

// pushed it here for saving same order items in menu
navItems.push(
  {
    title: 'EQUIPMENT',
    path: '/equipment',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M10.852 13H9.003a.572.572 0 0 0-.571.571v.396a.19.19 0 0 1-.19.19h-1.06a.19.19 0 0 1-.19-.19v-.396A.572.572 0 0 0 6.42 13H4.571a.572.572 0 0 0-.571.571v5.476c0 .315.256.572.571.572h6.281a.572.572 0 0 0 .572-.572v-5.476a.572.572 0 0 0-.572-.571zM19.852 13h-1.849a.572.572 0 0 0-.571.571v.396a.19.19 0 0 1-.19.19h-1.06a.19.19 0 0 1-.19-.19v-.396A.572.572 0 0 0 15.42 13h-1.849a.572.572 0 0 0-.571.571v5.476c0 .315.256.572.571.572h6.281a.572.572 0 0 0 .572-.572v-5.476a.572.572 0 0 0-.572-.571zM8.571 11.619h6.281a.572.572 0 0 0 .572-.572V5.571A.572.572 0 0 0 14.852 5h-1.849a.572.572 0 0 0-.571.571v.396a.19.19 0 0 1-.19.19h-1.06a.19.19 0 0 1-.19-.19V5.57A.572.572 0 0 0 10.42 5H8.571A.572.572 0 0 0 8 5.571v5.476c0 .315.256.572.571.572z"/></svg>',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Trailers',
        path: '/trailers',
        component: Trailers,
        children: [],
      },
      {
        title: 'Containers',
        path: '/containers',
        component: Trailers,
        children: [],
      },
    ],
  },
  {
    title: 'MANAGE',
    path: '/manage',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16 4.75c0-.45-.2-.75-.5-.75h-11c-.3 0-.5.3-.5.75v1.5c0 .45.2.75.5.75h11c.3 0 .5-.3.5-.75v-1.5zM20 11.75c0-.45-.267-.75-.667-.75H4.667c-.4 0-.667.3-.667.75v1.5c0 .45.267.75.667.75h14.666c.4 0 .667-.3.667-.75v-1.5zM12 18.75c0-.45-.133-.75-.333-.75H4.333c-.2 0-.333.3-.333.75v1.5c0 .45.133.75.333.75h7.334c.2 0 .333-.3.333-.75v-1.5z"/></svg>',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [
      {
        title: 'Database',
        path: '/database',
        component: Database,
        children: [],
      },
      {
        title: 'Locations',
        path: '/locations',
        component: Locations,
        children: [],
      },
    ],
  },
);

// add Billimg to nav menu
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Invoices',
        permissions: ['read'],
      },
    ],
  }) ||
  authorize({
    abilityPermissions: [
      {
        resource: 'Reconcile',
        permissions: ['read'],
      },
    ],
  })
) {
  // push parent item if one of the children permitted
  navItems.push({
    title: 'BILLING',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M9.36 10.74c-.149.123-.32.17-.563.195V9.618c.29.096.486.195.61.291a.593.593 0 0 1 .172.415.625.625 0 0 1-.22.415zM8.31 7.295a.976.976 0 0 0-.515.195.596.596 0 0 0-.195.415c0 .172.047.292.147.391.096.096.291.196.563.292V7.296zm11.475 2.785v8.108a1.803 1.803 0 0 1-1.807 1.807H6.82a1.803 1.803 0 0 1-1.807-1.807V5.807C5.014 4.806 5.82 4 6.821 4h6.983c.706 0 1.05.196 1.488.658l3.81 3.859c.512.486.683.806.683 1.564zm-8.032-1.808h2.685c.32 0 .563-.243.563-.538V7.32a.55.55 0 0 0-.563-.539h-2.685v1.492zM8.31 9.494v1.44c-.44-.071-.854-.267-1.27-.634l-.538.635c.539.463 1.15.734 1.808.806v.534h.463v-.538c.514-.024.901-.172 1.22-.44.292-.267.463-.634.463-1.049 0-.439-.123-.758-.39-.977-.268-.22-.683-.415-1.246-.539h-.023V7.34c.39.048.734.22 1.049.439l.487-.683c-.487-.319-1.002-.514-1.54-.538v-.415H8.33v.367c-.463.024-.854.171-1.15.439a1.406 1.406 0 0 0-.462 1.049c0 .415.147.734.39.978.248.2.64.395 1.202.518zm10.035 7.912a.55.55 0 0 0-.563-.538H6.965a.527.527 0 0 0-.539.538v.415c0 .292.244.54.54.54h10.816c.291 0 .563-.244.563-.54v-.415zm0-3.371a.55.55 0 0 0-.563-.539H6.965a.527.527 0 0 0-.539.539v.415c0 .291.244.539.54.539h10.816c.291 0 .563-.244.563-.54v-.414zm0-3.372a.55.55 0 0 0-.563-.538h-6.029v1.488h6.033c.291 0 .563-.243.563-.539v-.41h-.004z"/></svg>',
    path: '/billing',
    downIcon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
    children: [],
  });

  // check permissions for each children
  if (
    authorize({
      abilityPermissions: [
        {
          resource: 'Invoices',
          permissions: ['read'],
        },
      ],
    })
  ) {
    navItems
      .find(item => item.title === 'BILLING')
      .children.push({
        title: 'Credit Memo',
        path: '/credit-memo',
        component: CreditMemos,
        children: [],
      });
    navItems
      .find(item => item.title === 'BILLING')
      .children.push({
        title: 'Invoice',
        path: '/invoice',
        component: Invoice,
        children: [],
      });
  }
  if (
    authorize({
      abilityPermissions: [
        {
          resource: 'Reconcile',
          permissions: ['read'],
        },
      ],
    })
  ) {
    navItems
      .find(item => item.title === 'BILLING')
      .children.push({
        title: 'Reconcile',
        path: '/reconcile',
        component: Reconcile,
        children: [],
      });
  }
}

// pushed it here for saving same order items in menu
// navItems.push({
//   title: 'CHAT',
//   path: '/chat',
//   icon:
//     '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M17.65 6.34a8.003 8.003 0 0 0-11.31 0 8 8 0 0 0-.335 10.95l.08.067c-.303.652-.822 1.457-1.647 1.863-.273.133-.21.539.09.586.901.14 2.195-.017 3.4-.928l.016.013a7.998 7.998 0 0 0 9.703-1.244A7.996 7.996 0 0 0 17.65 6.34z"/></svg>',
//   component: Chat,
//   children: [],
// });

// add Queue to nav menu
if (
  authorize({
    abilityPermissions: [
      {
        resource: 'Queue',
        permissions: ['read'],
      },
    ],
  })
) {
  navItems.push({
    title: 'QUEUE',
    path: '/queue',
    icon:
      '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M6.462 13.035h6.787a.55.55 0 0 0 .55-.55V6.557a.55.55 0 0 0-.55-.549h-2.055a.55.55 0 0 0-.55.55v.503c0 .1-.081.183-.182.183H9.25a.183.183 0 0 1-.183-.183v-.504a.55.55 0 0 0-.55-.549H6.462a.55.55 0 0 0-.55.55v5.927c0 .303.247.55.55.55zM9.856 8.74l1.194 1.347h-.623v1.679H9.285v-1.679H8.66L9.856 8.74zM15.397 10.199h2.013v.417c0 .172.118.262.235.262.043 0 .109-.012.178-.07l1.472-1.242a.307.307 0 0 0 .11-.234c0-.09-.04-.175-.11-.235l-1.472-1.241a.275.275 0 0 0-.179-.07c-.116 0-.234.09-.234.262v.417h-2.013a.33.33 0 0 0-.33.33v1.074c0 .182.148.33.33.33zM17.819 14.516H6.146A2.149 2.149 0 0 0 4 16.662c0 1.183.963 2.146 2.146 2.146H17.82a2.149 2.149 0 0 0 2.146-2.146 2.149 2.149 0 0 0-2.146-2.146zM6.573 17.76a1.098 1.098 0 1 1 0-2.196 1.098 1.098 0 0 1 0 2.196zm3.606 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197zm3.607 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197zm3.606 0a1.098 1.098 0 1 1 0-2.197 1.098 1.098 0 0 1 0 2.197z"/></svg>',
    component: Queue,
    children: [],
  });
}

// pushed it here for saving same order items in menu
navItems.push({
  title: 'DRIVERS',
  icon:
    '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M19.109 10.588a14.168 14.168 0 0 1-.071-.092c-.282-.371-.51-.789-.71-1.158-.197-.36-.383-.702-.586-.938-.453-.527-.88-.783-1.304-.783h-2.093c-.002 0-.202.003-.443.003h-.341a.534.534 0 0 0-.518.403l-.003.01v4.623l.001.01c0 .002.005.069-.047.125-.057.063-.168.097-.32.097H4.52c-.287 0-.52.22-.52.49v1.682c0 .27.233.49.52.49h1.791a2.103 2.103 0 0 0 2.093 1.916 2.103 2.103 0 0 0 2.092-1.916h2.997a2.103 2.103 0 0 0 2.092 1.916 2.103 2.103 0 0 0 2.092-1.916h1.363c.516 0 .936-.42.936-.937v-1.607c0-1.293-.522-1.97-.867-2.418zm-2.74 4.777a.784.784 0 0 1-1.567 0 .784.784 0 0 1 1.567 0zm1.8-4.077c-.016.027-.062.039-.133.034h-3.6V8.387h1.557c.418 0 .715.182 1.024.629.205.297.748 1.232.776 1.28l.06.114c.156.3.393.752.317.878zm-9.765 3.294a.784.784 0 0 1 0 1.566.784.784 0 0 1 0-1.566z"/><path d="M5.097 12.143h2.467c.25 0 .454-.203.454-.453V9.875a.454.454 0 0 0-.454-.453H5.097a.454.454 0 0 0-.454.453v1.815c0 .25.204.453.454.453zM9.197 12.143h2.467c.25 0 .453-.203.453-.453V9.875a.454.454 0 0 0-.453-.453H9.197a.454.454 0 0 0-.454.453v1.815c0 .25.204.453.454.453zM5.097 8.733h2.467c.25 0 .454-.204.454-.454V6.465a.454.454 0 0 0-.454-.453H5.097a.454.454 0 0 0-.454.453v1.814c0 .25.204.454.454.454zM9.197 8.733h2.467c.25 0 .453-.204.453-.454V6.465a.454.454 0 0 0-.453-.453H9.197a.454.454 0 0 0-.454.453v1.814c0 .25.204.454.454.454z"/></svg>',
  path: '/drivers',
  downIcon:
    '<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" class="icon--fill"><path d="M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z"/></svg>',
  children: [
    {
      title: 'Driver details',
      path: '/driver-details',
      component: DriversList,
      children: [],
    },
    {
      title: 'Track drivers',
      path: '/track-drivers',
      component: TrackDrivers,
      children: [],
    },
  ],
});

export default navItems;
