import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { createFilter } from 'react-select';

import makeAnimated from './Components/AnimatedComponents';

const animatedComponents = makeAnimated();
const { MultiValue, ValueContainer, Input } = animatedComponents;
const filterOption = createFilter({});

const Select = ({
  closeMenuOnSelect = true,
  defaultValue,
  isDisabled = false,
  isMulti = false,
  isOptionDisabled,
  label,
  noMargin = false,
  onChange,
  options,
  placeholder,
  required,
  selectAll = false,
  value,
  isClearable,
  addNew = false,
  functionOnLastInput,
  customOption,
  customStyles,
  testSelector,
}) => {
  const [tempValue, setTempValue] = useState(value);
  const [tempOptions, setTempOptions] = useState(options);
  const [selectableOpts, setSelectableOpts] = useState(options);
  const [onInputChangeValue, setOnInputChangeValue] = useState(undefined);

  const selectAllOpt = {
    label: 'Select all',
    value: '*',
  };
  const addNewOpt = {
    label: '+ Add New',
    value: '**',
  };
  const addCustomOption = {
    label: customOption,
    value: '***',
  };

  useEffect(() => {
    if (customOption) {
      options.unshift(addCustomOption);
    }
    if (isMulti && selectAll) {
      // Add Select all to beginning of options
      setTempOptions([selectAllOpt, ...options]);
      // Filter out disabled options for select all
      const filteredOpts = options.filter(data => data.isDisabled !== true);
      setSelectableOpts(filteredOpts);
    } else {
      setTempOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (value !== tempValue) {
      setTempValue(value);
    }
  }, [value]);

  const handleOnChange = (selected, event) => {
    let result = selected;

    if (isMulti) {
      if (selected === null) {
        // React Select v3 isMulti returns null when cleared using small "x"
        // returns [] when using big 'X, this keeps code correct that relies on empty []
        result = [];
      }

      if (selectAll) {
        // if select all is selected, set result to all non disabled options
        if (
          event.action === 'select-option' &&
          event.option.value === selectAllOpt.value
        ) {
          result = selectableOpts;
        }

        // if every non disabled option is selected remove the select all, else add it back if it was removed
        if (result.length === selectableOpts.length) {
          setTempOptions([...options]);
        } else if (
          tempValue &&
          (event.action === 'remove-value' || event.action === 'clear') &&
          tempValue.length === selectableOpts.length
        ) {
          setTempOptions([selectAllOpt, ...options]);
        }
      }
    }
    if (functionOnLastInput) {
      functionOnLastInput(onInputChangeValue);
    }

    setTempValue(result);
    return onChange(result);
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any',
  };

  const filterAllOptions = rawInput => {
    if (addNew) {
      const filteredOptions = options.filter(o => filterOption(o, rawInput));

      if (filteredOptions.length === 0) {
        filteredOptions.push(addNewOpt);
      }

      setTempOptions(filteredOptions);
    }
  };

  return (
    <ReactSelect
      className={`input__select ${noMargin ? 'input__select--no-margin' : ''}`}
      classNamePrefix="input__select"
      options={tempOptions}
      onInputChange={value => {
        filterAllOptions(value);
        setOnInputChangeValue(value);
      }}
      data-testid={testSelector}
      id={testSelector}
      name={testSelector}
      // Placeholder prop is temporary until replaced inside code
      placeholder={label || placeholder}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={isMulti ? true : isClearable}
      isOptionDisabled={isOptionDisabled}
      // Note: required only adds '*' to label, does not make it required
      required={required}
      closeMenuOnSelect={closeMenuOnSelect}
      // If undefined set to null so it clears select
      value={tempValue ?? defaultValue ?? null}
      defaultValue={defaultValue}
      onChange={handleOnChange}
      filterOption={addNew ? () => true : createFilter(filterConfig)}
      components={{
        // Placeholder and SingleValue is inside ValueContainer, removes duplicate
        Placeholder: () => null,
        SingleValue: () => null,
        MultiValue,
        ValueContainer,
        Input,
      }}
      // Removes almost all inline react-select styles
      styles={{
        container: () => {},
        valueContainer: () => {},
        placeholder: () => {},
        option: () => {},
        control: () => customStyles,
        singleValue: () => {},
        multiValue: () => {},
        menu: () => {},
        menuList: () => {},
        indicatorContainer: () => {},
        indicatorsContainer: () => {},
        indicatorSeparator: () => {},
        input: () => {},
      }}
    />
  );
};

const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
});

Select.propTypes = {
  /** Boolean to keep menu open after selecting an option */
  closeMenuOnSelect: PropTypes.bool,
  /** Default values of select input (already selected on load) */
  defaultValue: PropTypes.oneOfType([
    optionShape,
    PropTypes.arrayOf(optionShape),
  ]),
  /** Boolean to add clearable 'X' for single value selects, default on for isMulti  */
  isClearable: PropTypes.bool,
  /** Boolean to disable select */
  isDisabled: PropTypes.bool,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Boolean to use multi select */
  isMulti: PropTypes.bool,
  /** Deprecated: Use isDisabled inside on option instead */
  isOptionDisabled: PropTypes.func,
  /** Label for select input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function called win selection changes */
  onChange: PropTypes.func,
  /** Array of options */
  options: PropTypes.arrayOf(optionShape).isRequired,
  /** Deprecated: Use label instead */
  placeholder: PropTypes.string,
  /** Boolean to add '*' (not validated) */
  required: PropTypes.bool,
  /** Boolean to add 'Select All' as the first option (Only works with isMulti) */
  selectAll: PropTypes.bool,
  /** Value for input */
  value: PropTypes.oneOfType([
    optionShape,
    PropTypes.arrayOf(optionShape),
    PropTypes.any,
  ]),
  /** Boolean to use for adding 'Add New' option */
  addNew: PropTypes.bool,
  /** Label for the custom option */
  // customOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
