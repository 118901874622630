import React, { useState } from 'react';

import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import Link from 'components/common/Link';
import Label from 'components/shared/Label';
import ToolTip from 'components/shared/ToolTip';
import Icon from 'components/shared/Icon';

import { convertTime } from 'utils/convertTime';
import CarrierCommitmentModal from '../AddCarrierCommitment';

const JobSlideoutDetail = ({ job }) => {
  const [showCarrierModal, setShowCarrierModal] = useState(false);

  const renderCarrierCommitment = commitment => (
    <div className="row" key={`carrier-commitment-${commitment.carrierId}`}>
      <div className="ibox-title">
        <div className="row">
          <h5>
            <span>Carrier #{commitment.carrierId}: </span>
            <Link id={commitment.carrierId} to="carrier-details">
              {commitment.carrierName}{' '}
            </Link>
            {!commitment.isActive && (
              <Label style={{ marginBottom: '3px' }} type="danger">
                Inactive
              </Label>
            )}
            {commitment.onHold && (
              <Label style={{ marginBottom: '3px' }} type="danger">
                On Hold
              </Label>
            )}
          </h5>
        </div>
      </div>
      <div className="ibox-content-order">
        <div className="row">
          <div className="col-md-3">
            <strong>Start date: </strong>
            {convertTime(commitment.startDate)}
          </div>
          <div className="col-md-3">
            <strong>End date: </strong>
            {convertTime(commitment.endDate)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <strong>Trucks Committed: </strong>
            {commitment.totalTrucks}
          </div>
        </div>
      </div>
    </div>
  );

  if (!job) {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0px';
    return <Loader />;
  }

  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = '15px';
  return (
    <>
      <div style={{ padding: 15 }}>
        <div className="page__header__title">Job Schedule Details</div>
        <div className="row ibox-order" key={`job=${job.id}`}>
          <div className="ibox-title">
            <div className="row">
              <h4>
                <span>Job #{job.id}: </span>
                <Link id={job.id} to="job-details">
                  {job.job_name}{' '}
                </Link>
                {job.status ? (
                  <Label type="success">Completed</Label>
                ) : (
                  <Label type="warning">Pending</Label>
                )}
                {!!job.isDraft && (
                  <Label style={{ marginBottom: '3px' }} type="grey">
                    Draft
                  </Label>
                )}
                {job.permissionType === 5 && (
                  <ToolTip title={job.companySharedName} placement="top">
                    <Label type="success">SaaS</Label>
                  </ToolTip>
                )}
              </h4>
            </div>
          </div>
          <div className="ibox-content-order">
            <div className="row">
              <div className="col-md-4">
                <strong>Start date: </strong>
                {convertTime(job.start_date)}
              </div>
              <div className="col-md-4">
                <strong>End date: </strong>
                {convertTime(job.end_date)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong>Job Manager #{job.job_manager_id}: </strong>
                {job.job_manager_name}
              </div>
              <div className="col-md-4">
                <strong>Location #{job.well_site}: </strong>
                {job.well_name}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong>Districts: </strong>
                {job.districts.map((d, index) => (
                  <>
                    <Link to="district-details" id={d.id} key={d.id}>
                      #{d.id} | {d.name}
                    </Link>
                    {index !== job.districts.length - 1 && ', '}
                  </>
                ))}
              </div>
              <div className="col-md-4">
                <strong>Tags: </strong>
                {job.tags.map(t => t.name).join(', ')}
              </div>
            </div>
          </div>
          <div>
            <br />
            <strong>Carrier Commitments: </strong>
            <div className="float-end">
              <Authorize
                abilityPermissions={[
                  { resource: 'Jobs', permissions: ['update'] },
                ]}>
                <Button
                  onClick={() => setShowCarrierModal(true)}
                  testSelector="customer-details_jobs-list_add-job_btn">
                  <Icon className="icon--margin-right" icon="plus-square" />
                  Add Carrier Commitment
                </Button>
              </Authorize>
            </div>
          </div>
          <div className="row" style={{ paddingLeft: 15 }}>
            {job.carrierCommitments.map(renderCarrierCommitment)}
            {!job.carrierCommitments.length && (
              <h5 className="ibox-title">No carriers committed.</h5>
            )}
          </div>
        </div>
      </div>

      <CarrierCommitmentModal
        job={job}
        show={showCarrierModal}
        closeModal={() => setShowCarrierModal(false)}
      />
    </>
  );
};

export default JobSlideoutDetail;
