import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import SearchInput from './SearchInput';
import actions from '../Header/actions';

const mapStateToProps = state => ({
  searchValue: state.Header.searchValue,
});

const mapDispatchToProps = dispatch => ({
  goToSearchResult: (e, match) => {
    e.preventDefault();
    dispatch(actions.goToSearchResult(match));
  },
  setSearchValue: e => dispatch(actions.setSearchValue(targetValue(e))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
