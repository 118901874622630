export default {
  LOCATIONS_CLEAR_STORE: 'LOCATIONS_CLEAR_STORE',
  LOCATIONS_QR_TEMPLATE_REQUESTED: 'LOCATIONS_QR_TEMPLATE_REQUESTED',
  LOCATIONS_QR_TEMPLATE_REQUEST_FAILED: 'LOCATIONS_QR_TEMPLATE_REQUEST_FAILED',
  LOCATIONS_QR_TEMPLATE_REQUEST_SUCCEEDED:
    'LOCATIONS_QR_TEMPLATE_REQUEST_SUCCEEDED',
  LOCATIONS_QR_TEMPLATE_DELIMIT_REQUEST_SUCCEEDED:
    'LOCATIONS_QR_TEMPLATE_DELIMIT_REQUEST_SUCCEEDED',
  LOCATIONS_QR_TEMPLATE_ADD_REQUESTED: 'LOCATIONS_QR_TEMPLATE_ADD_REQUESTED',
  LOCATIONS_QR_TEMPLATE_ADD_REQUEST_FAILED:
    'LOCATIONS_QR_TEMPLATE_ADD_REQUEST_FAILED',
  LOCATIONS_QR_TEMPLATE_ADD_REQUEST_SUCCEEDED:
    'LOCATIONS_QR_TEMPLATE_ADD_REQUEST_SUCCEEDED',
  LOCATIONS_QR_TEMPLATE_CLEAR_STATE: 'LOCATIONS_QR_TEMPLATE_CLEAR_STATE',
  LOCATIONS_SET_VIEW_TO_LOCATION: 'LOCATIONS_SET_VIEW_TO_LOCATION',
  LOCATIONS_SET_FILTER_VALUE: 'LOCATIONS_SET_FILTER_VALUE',
  LOCATIONS_SET_LOCATION: 'LOCATIONS_SET_LOCATION',
  LOCATIONS_TIMING_SET_SELECTED: 'LOCATIONS_TIMING_SET_SELECTED',
  LOCATIONS_TIMING_GET_LIST: 'LOCATIONS_TIMING_GET_LIST',
  LOCATIONS_QR_TEMPLATE_INIT: 'LOCATIONS_QR_TEMPLATE_INIT',
  LOCATIONS_SET_LOCATION_COORDINATS: 'LOCATIONS_SET_LOCATION_COORDINATS',
  LOCATIONS_SET_INPUT_VALUE: 'LOCATIONS_SET_INPUT_VALUE',
  LOCATIONS_CLEAR_MODAL_ADD_LOCATION: 'LOCATIONS_CLEAR_MODAL_ADD_LOCATION',
  LOCATIONS_TURN_PREVIEW: 'LOCATIONS_TURN_PREVIEW',
  LOCATIONS_TIMING_SET_INPUT_VALUE: 'LOCATIONS_TIMING_SET_INPUT_VALUE',
  LOCATIONS_TIMING_CLEAR_STATE: 'LOCATIONS_TIMING_CLEAR_STATE',
};
