import React, { useState } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';

const FileInput = ({
  accept,
  className,
  disabled,
  label,
  noMargin,
  onChange,
  required,
  testSelector,
  multiple
}) => {
  const [fileName, setFileName] = useState('');
  // const inputId = id ? id.replace(/\s+/g, '') : `${uuid.v4()}-file`;

  const uploadFile = e => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      onChange(e);
    }
  };

  return (
    <InputWrapper
      inputType="file"
      id={testSelector}
      className={className}
      noMargin={noMargin}
      disabled={disabled}>
      <input
        accept={accept}
        type="file"
        className="input__file__input"
        disabled={disabled}
        data-testid={testSelector}
        name={testSelector}
        id={testSelector}
        onChange={uploadFile}
        multiple={multiple}
      />
      <span
        className={`input__file__label ${
          fileName ? 'input__file__label--has-file' : ''
        }`}>
        {label}
        {required && <span className="input__file__label--required"> *</span>}
      </span>
      {fileName ? (
        <span className="input__file__text">{`${fileName.substring(0, 16)}${
          fileName.length > 15 ? '...' : ''
        }`}</span>
      ) : null}
      <span className="input__file__button">Choose File</span>
    </InputWrapper>
  );
};

FileInput.propTypes = {
  /** File types that are accepted Example: 'image/*, application/pdf' */
  accept: PropTypes.string,
  /** Custom Class Name */
  className: PropTypes.string,
  /** Disabled boolean */
  disabled: PropTypes.bool,
  /** Unique id */
  id: PropTypes.string,
  /** Label for input */
  label: PropTypes.string.isRequired,
  /** Unique name for input */
  name: PropTypes.string,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Function to call when changing the input */
  onChange: PropTypes.func.isRequired,
  /** Boolean to make input required and add * to label */
  required: PropTypes.bool,
  /** HTML data attribute for test selecting element  */
  testSelector: PropTypes.string,
};

FileInput.defaultProps = {
  accept: undefined,
  className: undefined,
  disabled: false,
  id: undefined,
  name: undefined,
  noMargin: false,
  required: false,
  testSelector: undefined,
  multiple: false
};

export default FileInput;
