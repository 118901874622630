import React from 'react';

const PDF = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-4 -4 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <g>
      <path d="M8.757 12.195c0-.43-.3-.687-.827-.687-.215 0-.361.02-.437.041v1.383a1.6 1.6 0 0 0 .354.028c.563 0 .91-.284.91-.765zm3.267-.674c-.237 0-.39.021-.48.042v3.064c.09.021.236.021.368.021.96.006 1.585-.521 1.585-1.641.007-.972-.563-1.486-1.473-1.486z"/>
      <path d="M18.283 9.721h-.385V7.868c0-.012-.002-.023-.004-.035a.305.305 0 0 0-.074-.203l-3.084-3.523-.002-.002a.31.31 0 0 0-.145-.09c-.006-.002-.011-.005-.017-.006A.282.282 0 0 0 14.501 4H6.922a.627.627 0 0 0-.627.627v5.094h-.384a.896.896 0 0 0-.896.896v4.663c0 .494.401.896.896.896h.384v3.191c0 .346.281.627.627.627h10.35a.627.627 0 0 0 .627-.627v-3.191h.385a.897.897 0 0 0 .896-.896v-4.661a.899.899 0 0 0-.897-.898zM6.923 4.627h7.265v3.21c0 .173.141.313.313.313h2.771v1.571H6.923V4.627zm7.699 8.346c0 .897-.326 1.516-.779 1.898-.493.41-1.243.604-2.16.604-.549 0-.938-.035-1.202-.07v-4.599a9.52 9.52 0 0 1 1.431-.097c.89 0 1.466.16 1.918.5.486.361.792.938.792 1.764zm-8.179 2.453v-4.62a8.69 8.69 0 0 1 1.432-.098c.653 0 1.118.125 1.431.376.299.236.5.625.5 1.084 0 .458-.153.848-.43 1.111-.362.341-.897.494-1.523.494-.138 0-.264-.008-.36-.021v1.676h-1.05v-.002zm10.828 3.771H6.922v-3.021h10.35v3.021zm.909-7.585h-1.8v1.07h1.683v.861H16.38v1.883h-1.062v-4.683h2.861v.869z"/>
    </g>
  </svg>
);

export default PDF;
