import { useContext } from 'react';
import { HomeStateContext } from 'components/views/Home/hooks/HomeStateProvider';

export default function useHomeState() {
  const context = useContext(HomeStateContext);
  if (!context) {
    throw new Error(
      'useHomeState can only be used within an HomeStateProvider',
    );
  }
  return context;
}
