import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/shared/Icon';
import InputWrapper from './InputWrapper';

const SearchInput = ({
  className,
  disabled = false,
  noMargin = false,
  onChange,
  placeholder = 'Search...',
  testSelector,
  value,
}) => (
  <InputWrapper
    inputType="search"
    id={testSelector}
    className={className}
    noMargin={noMargin}
    disabled={disabled}>
    <Icon icon="search" className="input__search__icon" />
    <input
      type="text"
      className="form-input__input"
      disabled={disabled}
      name={testSelector}
      id={testSelector}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      data-testid={testSelector}
    />
  </InputWrapper>
);

SearchInput.propTypes = {
  /** Custom Class Name */
  className: PropTypes.string,
  /** Disabled boolean */
  disabled: PropTypes.bool,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Function to call when changing the input */
  onChange: PropTypes.func.isRequired,
  /** Placeholder for input */
  placeholder: PropTypes.string,
  /** HTML data attribute for test selecting element  */
  testSelector: PropTypes.string,
  /** Value of input */
  value: PropTypes.string,
};

export default SearchInput;
