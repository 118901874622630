import React from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';

const OrderInfo = props => {
  const { order, showOrderInfo, closeOrderInfo, confirmAppointment } = props;
  const {
    orderId,
    driverName,
    carrierName,
    truck,
    jobName,
    po,
    sandTypeName,
    weight,
    confirmationStatus,
    externalStatus,
    loadindEta,
    appointmentTime,
  } = order;

  return (
    <Modal
      className="modal-container"
      {...{ show: showOrderInfo, onHide: closeOrderInfo }}>
      <form>
        <Modal.Header>
          <h3>Order #{orderId}</h3>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td>Driver:</td>
                <th>{driverName}</th>
              </tr>
              <tr>
                <td>Carrier:</td>
                <th>{carrierName}</th>
              </tr>
              <tr>
                <td>Truck:</td>
                <th>{truck}</th>
              </tr>
              <tr>
                <td>Job:</td>
                <th>{jobName}</th>
              </tr>
              <tr>
                <td>PO:</td>
                <th>{po}</th>
              </tr>
              <tr>
                <td>Commodity:</td>
                <th>{sandTypeName}</th>
              </tr>
              <tr>
                <td>Weight:</td>
                <th>{weight} lbs</th>
              </tr>
              <tr>
                <td>ETA:</td>
                <th>
                  {moment
                    .utc(loadindEta)
                    .local()
                    .format('YYYY-MM-DD HH:mm')}
                </th>
              </tr>
              <tr>
                <td>Appointment:</td>
                <th>
                  {moment
                    .utc(appointmentTime)
                    .local()
                    .format('YYYY-MM-DD HH:mm')}
                </th>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {!externalStatus && (
            <>
              {!confirmationStatus && (
                <Button
                  onClick={() => confirmAppointment(order, closeOrderInfo)}
                  testSelector="sandsite-schedule_order-info_confirm_btn">
                  Confirm
                </Button>
              )}
              {/* <Button
                onClick={() =>
                  openDriverChat(order.driverChatId, order.driverId)
                }
                children="Open Chat"
              /> */}
            </>
          )}
          <Button
            onClick={closeOrderInfo}
            colour="white"
            testSelector="sandsite-schedule_order-info_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OrderInfo;
