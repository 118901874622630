import React from 'react';
import Authorize from 'components/common/Authorize';
import ToolTip from 'components/shared/ToolTip';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import RolesListRow from 'components/views/Roles/RolesListRow';

const RolesList = ({
  moreOptionsFunc,
  permissions,
  roles,
  permissionUpdate,
}) => {
  const header = () => (
    <tr>
      {/* empty spacer above permissions column */}
      <td />
      {roles &&
        roles.map(roleData => {
          const { role: roleObj } = roleData;
          const { rid, role } = roleObj;
          // Don't show direct role
          if (role === 'direct') {
            return null;
          }

          return (
            <td key={rid} data-testid={`roles-list-header-rid-${rid}`}>
              <span className="roles-list__header__title">
                <ToolTip title={`${role}`}>{role}</ToolTip>
              </span>
            </td>
          );
        })}
      <td className="table-spacer" />
    </tr>
  );

  const footer = () => (
    <tr>
      <td className="roles-list__footer_permissions-spacer" />
      {roles &&
        roles.map(roleData => {
          const { role: roleObj } = roleData;
          const { rid, role } = roleObj;
          // Don't edit icon
          // TODO: api needs to supply a flag for what can be edited
          if (rid === 'direct' || rid === 'admin' || rid === 'super') {
            // Empty cell for roles that should not be editable permissions, admin, super, ect...
            return <td />;
          }

          return (
            <td key={rid} data-testid={`roles-list-footer-rid-${rid}`}>
              <span className="roles-list__footer__more-options">
                <ToolTip title={`More Options - ${role}`}>
                  <Button
                    testSelector={`roles_list_more-options-${rid}_btn`}
                    theme="small--square"
                    onClick={() => moreOptionsFunc(roleData)}>
                    <Icon icon="pencil" />
                  </Button>
                </ToolTip>
              </span>
            </td>
          );
        })}
    </tr>
  );

  return (
    <div className="roles-list">
      <table className="main-table">
        <thead className="roles-list__header" data-testid="roles-list-header">
          {header()}
        </thead>
        <tbody className="roles-list__body">
          {permissions &&
            permissions.map(permData => {
              return (
                <RolesListRow
                  key={`${permData.permission + permData.pid}`}
                  permData={permData}
                  roles={roles}
                  grants={permData.grants}
                  permissionUpdate={permissionUpdate}
                />
              );
            })}
        </tbody>
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Roles',
                permissions: ['update'],
              },
            ],
          }}>
          <tfoot className="roles-list__footer">{footer()}</tfoot>
        </Authorize>
      </table>
    </div>
  );
};

export default RolesList;
