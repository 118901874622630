import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Authorize from 'components/common/Authorize';
import Page from 'components/shared/Page';
import Block from 'components/shared/Block';
import Loader from 'components/shared/Loader';
import InvoicesFilters from './InvoicesFilters';
import InvoicesList from './InvoicesList';
import { InvoicesContext } from './index';

const basePayload = {
  ignoreRemoved: 1,
  page: 1,
  limit: 10,
};

const Invoices = ({
  isLoading,
  getInvoices,
  getJobs,
  markAsPaid,
  markAsSent,
  deleteInvoices,
  downloadMultipleBulkInvoices,
  customers,
  jobs,
  getCustomers,
  customersIsLoading,
}) => {
  const {
    filters,
    setFilters,
    ordering,
    setOrdering,
    setPagination,
    pagination,
  } = useContext(InvoicesContext);

  const [requestPayload, setRequestPayload] = useState(null);

  const setRequestFilters = requestFilters => {
    setPagination({ page: 1, limit: pagination.limit });
    return setRequestPayload({
      ...basePayload,
      ...requestFilters,
      ...pagination,
      page: 1,
    });
  };

  const setRequestPagination = paginationData => {
    const newObj = {
      page: paginationData.page,
      limit: paginationData.itemsPerPage,
    };

    const match = Object.keys(pagination).every(key => {
      if (!newObj[key]) {
        return false;
      }

      if (newObj[key] === pagination[key]) {
        return true;
      }

      return false;
    });

    if (!match) {
      setPagination(newObj);
      if (requestPayload) {
        return setRequestPayload({ ...requestPayload, ...newObj });
      }

      return setRequestPayload({ ...basePayload, ...newObj });
    }
  };

  useEffect(() => {
    setOrdering({ key: 'created_at', isAscending: false });
    setPagination({ page: 1, limit: 10 });
    getCustomers();
    getJobs();
  }, []);

  useEffect(() => {
    getInvoices(basePayload);
  }, []);

  useEffect(() => {
    if (!requestPayload) {
      return;
    }

    const formattedPayload = Object.assign(
      {},
      _.omit(requestPayload, ['createFrom', 'createTo', 'dueFrom', 'dueTo']),
    );

    if (
      requestPayload.createdFrom &&
      moment(requestPayload.createdFrom).isValid()
    ) {
      formattedPayload.createdFrom = new Date(
        requestPayload.createdFrom,
      ).toISOString();
    }

    if (requestPayload.createdTo && moment(requestPayload.createTo).isValid()) {
      formattedPayload.createdTo = new Date(
        requestPayload.createdTo,
      ).toISOString();
    }

    if (requestPayload.dueFrom && moment(requestPayload.dueFrom).isValid()) {
      formattedPayload.dueFrom = new Date(requestPayload.dueFrom).toISOString();
    }

    if (requestPayload.dueTo && moment(requestPayload.dueTo).isValid()) {
      formattedPayload.dueTo = new Date(requestPayload.dueTo).toISOString();
    }

    getInvoices(formattedPayload);
  }, [requestPayload]);

  return (
    <Page isLoading={customersIsLoading} title="Invoices" className="invoices">
      <Block theme="none" modifiers={['no-border']}>
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Invoices',
                permissions: ['read'],
              },
            ],
          }}>
          <InvoicesFilters
            customerOptions={customers.map(i => ({
              value: i.id,
              label: i.name,
            }))}
            jobOptions={jobs.map(i => ({
              value: i.id,
              label: i.job_name,
            }))}
            filters={filters}
            setFilters={setFilters}
            setRequestFilters={setRequestFilters}
          />

          {(isLoading && <Loader />) || (
            <InvoicesList
              filters={filters}
              ordering={ordering}
              setOrdering={setOrdering}
              markAsPaid={markAsPaid}
              markAsSent={markAsSent}
              deleteInvoices={deleteInvoices}
              downloadMultipleBulkInvoices={downloadMultipleBulkInvoices}
              pagination={pagination}
              setPagination={setRequestPagination}
            />
          )}
        </Authorize>
      </Block>
    </Page>
  );
};

export default Invoices;
