import React, { useState } from 'react';
import swal from 'bootstrap-sweetalert';
import authService from 'services/auth/service';

import { useRemoveCarrierDistrict } from 'api/v2/carriers';

import Link from 'components/common/Link';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import EditRatio from './EditRatioModal';
import AddCarrier from './AddCarrierModal';

const CarrierTableItem = ({ carrierInfo, districtInfo, isRatio, index, link }) => {
  const { mutate: deleteCarrierDistrict } = useRemoveCarrierDistrict(carrierInfo.id);
  const user = authService.getUser();

  return (
    <tr key={`review${carrierInfo.order_id}_${index}`}>
      <td style={{ cursor: 'pointer' }}>
        <Link {...{ id: carrierInfo.id, to: `${link}-details` }}>
          {carrierInfo.id}
        </Link>
      </td>
      <td>{carrierInfo.name}</td>
      {isRatio && (
        <td>{`${districtInfo.activeOrders.filter(e => e.carrier === carrierInfo.id).length
          } / ${carrierInfo.ratio}`}</td>
      )}
      <Authorize
        {...{
          abilityPermissions: [
            {
              resource: 'Carriers',
              permissions: ['delete'],
            },
          ],
        }}>
        {(districtInfo.details[0].accessLevel === 'owner' ||
          user.entityType === 6) && (
            <td>
              <Button
                onClick={() => {
                  swal(
                    {
                      title: 'Are you sure?',
                      text:
                        'Deleting this district will remove all drivers from this district as well',
                      showCancelButton: true,
                      confirmButtonColor: '#DD6B55',
                      cancelButtonColor: 'rgb(35,198,200)',
                      confirmButtonText: 'yes, delete it!',
                      cancelButtonText: 'Cancel',
                      closeOnConfirm: true,
                      closeOnCancel: true,
                    },
                    isConfirm => {
                      if (isConfirm) {
                        deleteCarrierDistrict(districtInfo.id);
                      }
                    },
                  );
                }}
                theme="small"
                testSelector="district-details_delete_carrier_btn">
                <Icon icon="trash" />
              </Button>
            </td>
          )}
      </Authorize>
    </tr>
  );
}

const CarriersTabContent = ({ district: info, tab }) => {
  const [showAddCarrier, setAddModal] = useState(false);
  const triggerAddModal = () => setAddModal(!showAddCarrier);

  const [showEditRatio, setEditRatioModal] = useState(false);
  const triggerEditRatio = () => setEditRatioModal(!showEditRatio);


  const link = tab.substring(0, tab.length - 1);

  const { isCarrierRatioEnabled, activeTrucks } = info.details[0];
  const isRatio = Boolean(isCarrierRatioEnabled);
  return (
    <div className="col-md-12">
      {showAddCarrier && (
        <AddCarrier
          {...{
            showModal: showAddCarrier,
            closeModal: triggerAddModal,
            carriers: info[tab],
            districtId: info.id,
          }}
        />
      )}
      {showEditRatio && (
        <EditRatio
          {...{
            showModal: showEditRatio,
            closeModal: triggerEditRatio,
            carriers: info[tab],
            activeTrucks,
            districtId: info.id,
          }}
        />
      )}
      {info[tab].length === 0 ? (
        <div className="row no-data-tab">
          <p>No {tab}</p>
        </div>
      ) : (
        <table id="datatable_queu" className="table table--hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              {isRatio && <th>Ratio</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            {info[tab].map((data, index) => (
              <CarrierTableItem carrierInfo={data} districtInfo={info} isRatio={isRatio} index={index} link={link} />
            ))}
          </tbody>
        </table>
      )}
      <div className="form-group m-md">
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Carriers',
                permissions: ['update'],
              },
            ],
            itemAccessGranted: info.details[0].accessLevel === 'owner',
          }}>
          <Button
            onClick={triggerAddModal}
            testSelector="district-details_content_add-carrier_btn">
            Add carrier
          </Button>
        </Authorize>
        {isRatio && (
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Carrier-ratio',
                  permissions: ['update'],
                },
              ],
              itemAccessGranted: info.details[0].accessLevel === 'owner',
            }}>
            <Button
              onClick={triggerEditRatio}
              testSelector="district-details_content_edit-ratio_btn">
              Edit carrier ratio
            </Button>
          </Authorize>
        )}
      </div>
    </div>
  );
};

export default CarriersTabContent;
