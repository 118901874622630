import React from 'react';

import Link from 'components/common/Link';
import Authorize from 'components/common/Authorize';
import ToolTip from 'components/shared/ToolTip';
import { orderStatus } from 'components/globalInfo/orderStatus';
import { commodityTypes, image } from 'components/globalInfo/commodityUnits';

const linkPermission = (id, name, linkTo) => (
  <span>
    <Authorize forbidden={['CUSTOMER']}>
      <Link id={id} to={linkTo}>
        {`# ${id || 'unknown'} | ${name || 'unknown'}`}
      </Link>
    </Authorize>
    <Authorize
      forbidden={['VENDOR', 'CARRIER', 'JOB_COORDINATOR', 'JOB_MANAGER']}>
      {`# ${id || 'unknown'} | ${name || 'unknown'}`}
    </Authorize>
  </span>
);

const linkLocation = (order, type) => {
  const {
    well_site_id: wellSiteId,
    well_site_name: wellSiteName,
    loading_site_id: loadingSiteId,
    loading_site_name: loadingSiteName,
  } = order;
  switch (type) {
    case 'origin':
      return linkPermission(loadingSiteId, loadingSiteName, 'location-details');
    case 'destination':
      return linkPermission(wellSiteId, wellSiteName, 'location-details');
    default:
      return null;
  }
};

const Info = ({ order }) => {
  const {
    order_id: orderId,
    customer_order_id: customerOrderId,
    stage,
    status,
    customer_id: customerId,
    name,
    job_id: jobId,
    job_name: jobName,
    po,
    commodity_name: commodityName,
    commodityType,
    mileage,
    carrier_id: carrierId,
    carrier_name: carrierName,
    truck,
    sand_ticket_no: sandTicketNo,
    driver_id: driverId,
    driver_name: driverName,
    weight,
    driver_weight: driverWeight,
    operation_type: operationType,
  } = order;

  return (
    <div>
      <h3>
        Order #{orderId} / {customerOrderId || 'unknown '}
        {operationType !== 12 && ` - Stage ${stage || 'unknown '} `}
      </h3>
      <span className={`label label-${orderStatus[status].class} float-end`}>
        {orderStatus[status].name}
      </span>

      <Authorize access={['VENDOR', 'JOB_MANAGER', 'CUSTOMER']}>
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Customer #{customerId || 'unknown'}: </b>
                <Link id={customerId} to="customer-details">
                  {name || 'unknown'}
                </Link>
              </li>
              <li>
                <b>Job: </b>
                <Link id={jobId} to="job-details">
                  #{jobId || 'unknown'} | {jobName || ''}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>PO: </b>
                {po || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      </Authorize>

      {status !== 0 && status !== 1 && Boolean(driverId) && (
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <Authorize access={['JOB_COORDINATOR']}>
                <li>
                  <b>Carrier #{carrierId || 'unknown'}: </b>
                  <Link id={carrierId} to="carrier-details">
                    {carrierName || 'unknown'}
                  </Link>
                </li>
              </Authorize>
              <li>
                <b>Truck: </b>
                {truck || 'unknown'}
              </li>
              <li>
                <b>Ticket Number: </b>
                {sandTicketNo || 'unknown'}
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Driver #{driverId || 'unknown'}: </b>
                <Link id={driverId} to="driver-details">
                  {driverName || 'unknown'}
                </Link>
              </li>
              <li>
                <b>Quantity (requested/loaded): </b>
                {weight || 'unknown'} /{driverWeight || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      )}
      <Authorize access={['VENDOR', 'JOB_MANAGER', 'CUSTOMER']}>
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Origin: </b>
                {linkLocation(order, 'origin')}
              </li>
              <li>
                <b>
                  <ToolTip title={commodityTypes(commodityType).title}>
                    {image(commodityType)}
                  </ToolTip>
                  Commodity Type:{' '}
                </b>
                {commodityName || 'unknown'}
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Destination: </b>
                {linkLocation(order, 'destination')}
              </li>
              <li>
                <b>Mileage: </b>
                {mileage || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      </Authorize>
    </div>
  );
};

export default Info;
