import axios from 'axios';

export const sandSite = {
  get() {
    return axios.get('v2/locations?type=2,3,4');
  },
  delete(sandSiteId) {
    return axios.delete(`v2/locations/${sandSiteId}`);
  },
  addSandSite(data) {
    return axios.post('v2/locations', data);
  },
  editSandSite(data) {
    return axios.put(`v2/locations/${data.id}`, data);
  },
  getSandSiteDetails(sandSiteId) {
    return axios.get(`v2/locations/${sandSiteId || 0}`, {});
  },
  getSandSiteSchedule(sandSiteId, time) {
    return axios.get(
      `v2/sand-site/${sandSiteId || 0}/schedule?date=${time}`,
      {},
    );
  },
  getSandSiteRequiredTickets(sandSiteId) {
    return axios.get(`v2/sand-site/${sandSiteId}/required-tickets`, {});
  },
  getSandSiteVendors(sandSiteId) {
    return axios.get(`v2/sand-site/${sandSiteId}/vendors`, {});
  },
  schedule: {
    settings(data) {
      return axios.put(
        `v2/sand-site/${data.sandSiteId}/schedule/settings`,
        data,
      );
    },
  },
  lanes: {
    add(data) {
      return axios.post(`v2/sand-site/${data.sandSiteId}/lanes/`, {
        ...data,
      });
    },
    edit(data) {
      return axios.put(`v2/sand-site/${data.sandSiteId}/lanes/${data.id}`, {
        ...data,
      });
    },
    delete(data) {
      return axios.delete(`v2/sand-site/${data.sandSiteId}/lanes/${data.id}`);
    },
  },
  break: {
    add(data) {
      return axios.post(`v2/sand-site/${data.sandSiteId}/schedule/break`, data);
    },
    delete(data) {
      return axios.delete(
        `v2/sand-site/${data.sandSiteId}/schedule/break/${data.id}/line/${
          data.lineId
        }`,
      );
    },
  },
  timing: {
    get(data) {
      return axios.get(`v2/locations/${data.sandSiteId}/timing`, {});
    },
    update(data) {
      const { timings, selectedLocation } = data;
      return axios.put(`v2/locations/${selectedLocation}/timing`, {
        timings,
      });
    },
  },
  district: {
    update(data) {
      return axios.post(`/v2/sand-site/${data.sandSiteId}/districts`, {districts: data.params})
    }
  },
  qrTemplate: {
    get(sandSiteId) {
      return axios.get(
        `v2/locations/${sandSiteId}/qr`,
        {},
      );
    },
    post(data) {
      const { selectedLocation, template, delimiter } = data;
      return axios.put(`v2/locations/${selectedLocation}/qr`,
        {template, delimiter});
    }
  }
};
