import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';

import Jobs from './Jobs';
import Orders from './Orders';
import Containers from './Containers';

const CustomerStats = ({ jobStats, details }) => (
  <Authorize
    abilityPermissions={[{ resource: 'Customers', permissions: ['read'] }]}
    itemAccessGranted={details.accessLevel === 'owner'}>
    <Row>
      <Col lg={3} md={3} style={{ paddingLeft: 0 }}>
        <Jobs jobStats={jobStats} />
      </Col>
      <Col lg={4} md={4}>
        <Orders details={details} />
      </Col>
      <Col lg={5} md={5} style={{ paddingRight: 0 }}>
        <Containers details={details} />
      </Col>
    </Row>
  </Authorize>
);
export default CustomerStats;
