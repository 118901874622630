import React from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'bootstrap-sweetalert';
import { cloneDeep } from 'lodash';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import {
  billOptions,
  auditOptions,
} from 'components/globalInfo/customerBillings';
import Loader from 'components/shared/Loader';

import { useEditCustomerBillingTerms } from 'api/v2/customers';
import { billableOptions } from '../../CustomerDetails/Info/EditBillingTerms/helpers';
import { useAddBillingTermsForm } from './hooks';

const AddBillingTerms = ({
  addCustomer,
  showEdit,
  closeAdd,
}) => {
  const { input: { billingTerms } } = addCustomer;
  const { formState, handlers: { setValue, updateJobOpValue } } = useAddBillingTermsForm(billingTerms?.customerId);
  const { mutate: editBillingTerms } = useEditCustomerBillingTerms(billingTerms?.customerId);

  const {
    dueDays,
    billingFrequency,
    billTo,
    bulkInvoiceEnabled,
    packetInvoiceEnabled,
    csvInvoiceEnabled,
    nonBillable,
    billableOperationTypeStatus,
    approvalsRequired
  } = formState;

  const submit = e => {
    e.preventDefault();
    const data = cloneDeep(formState);

    if (!data.approvalsRequired?.length) {
      delete data.approvalsRequired;
    }

    if (!data.billableOperationTypeStatus?.length) {
      delete data.billableOperationTypeStatus;
    }

    editBillingTerms(
      data,
      {
        onSuccess: () => {
          swal('Success!', 'The Customer Is Added Successfully!', 'success');
          closeAdd();
        },
        onError: (err) => {
          swal('Error', err.response.data.message, 'error');
        }
      }
    );
  }

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeAdd}>
        <form
          onSubmit={submit}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Save Billing Details</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-billing-details-modal">
              <div className="edit-billing-details-modal__column">
                <Input
                  onChange={value => setValue(value, 'dueDays')}
                  value={dueDays}
                  label="Due date (days)"
                  required
                  testSelector="admin-customers_add-billing-terms_due-date_input"
                />
                <Input
                  onChange={value => setValue(value, 'billingFrequency')}
                  value={billingFrequency}
                  label="Billing frequency (days)"
                  required
                  testSelector="admin-customers_add-billing-terms_billing-frequency_input"
                />
                <label className="small font-noraml">Bill to</label>
                <Select
                  value={billOptions.find(item => item.value === billTo)}
                  onChange={item => setValue(item.value, 'billTo')}
                  placeholder="Bill to"
                  options={billOptions}
                  required
                  testSelector="admin-customers_add-bill-terms_bill-to_select"
                />
                <Select
                  placeholder="Audit required"
                  options={auditOptions}
                  onChange={selectedAuditRequirements =>
                    setValue(
                      selectedAuditRequirements.map(item => item.value),
                      'approvalsRequired',
                    )
                  }
                  defaultValue={auditOptions.filter(
                    option =>
                      (approvalsRequired &&
                        approvalsRequired.includes(option.value)) ||
                      false,
                  )}
                  closeMenuOnSelect={false}
                  isDisabled={nonBillable}
                  isMulti
                  testSelector="admin-customers_add-bill-terms_audit-required_select"
                />
              </div>

              <div className="edit-billing-details-modal__column">
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_bulk-invoice_input"
                  isChecked={bulkInvoiceEnabled}
                  onChange={e => setValue(e, 'bulkInvoiceEnabled')}
                  label="Attach bulk invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_packet-invoice_input"
                  isChecked={packetInvoiceEnabled}
                  onChange={e => setValue(e, 'packetInvoiceEnabled')}
                  label="Attach packet invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_csv-invoice_input"
                  isChecked={csvInvoiceEnabled}
                  onChange={e => setValue(e, 'csvInvoiceEnabled')}
                  label="Attach csv invoice"
                />
                <label className="small font-noraml" style={{ marginTop: 5 }}>
                  Billable status per Job Type
                </label>
                {billableOperationTypeStatus?.length ? (
                  billableOptions(billableOperationTypeStatus).map(option => (
                    <Input
                      key={option.value}
                      type="checkbox"
                      testSelector={`admin-customers_add-billing-terms_status-per-job-type_${option.value
                        }`}
                      isChecked={option.isChecked}
                      onChange={e => {
                        updateJobOpValue(option.value);
                      }}
                      label={option.label}
                    />
                  ))
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeAdd}
              colour="white"
              testSelector="admin-customers_billing-terms_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              testSelector="admin-customers_billing-terms_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
export default AddBillingTerms;
