import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import { orderStatus } from 'components/globalInfo/orderStatus';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import DropdownButton from 'components/shared/DropdownButton';
import Authorize, { authorize } from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import OrderList from 'components/views/OrderList';
import EditJob from 'components/views/JobsList/EditJobModal';

import { useJobDetails } from 'api/v2/jobs';
import TodayInfo from './TodayInfo';
import JobDetailsTab from './Tabs';
import AddOrder from './AddOrderModal';

const JobDetails = () => {
  const [showAddOrder, turnAddModal] = useState(false);
  const [showEditJob, turnEditJobModal] = useState(false);
  const [autoRefreshIsChecked, turnAutoRefreshIsChecked] = useState(false);

  const { jobId } = useParams();
  const { data: jobDetails, isLoading, refetch, isRefetching } = useJobDetails({
    jobId,
    autoRefetch: autoRefreshIsChecked,
  });
  const {
    details: { operationType, jobName, isFullEdit },
  } = jobDetails || { details: {} };

  const initFilters = useMemo(
    () => ({
      sort: 'order_id DESC',
      order_id: '',
      stage: '',
      statuses: orderStatus.reduce(
        (acc, item) =>
          item.id !== 4 ? [...acc, { value: item.id, label: item.name }] : acc,
        [],
      ),
      truck: '',
      itemsPerPage: 10,
      jobId,
      show: {
        sort: true,
        order_id: true,
        statuses: true,
        truck: true,
        stage: operationType !== 12,
      },
    }),
    [jobId, operationType],
  );

  const actionOptions = useMemo(() => {
    const options = [];
    if (isFullEdit) {
      if (
        authorize({
          abilityPermissions: [{ resource: 'Orders', permissions: ['create'] }],
        })
      ) {
        options.push(
          {
            name: 'addOrder',
            label: 'Add Order',
            onClick: () => turnAddModal(true),
          }
        );
      }
      if (
        authorize({
          abilityPermissions: [{ resource: 'Jobs', permissions: ['update'] }],
        })
      ) {
        options.push(
          {
            name: 'editJob',
            label: 'Edit Job',
            onClick: () => turnEditJobModal(true),
          }
        )
      }
    }
    return options;
  }, [isFullEdit]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page job-details">
      <Header title={`Job Details - ${jobName}`}>
        <div className="job-details__options">
          {(authorize({
            abilityPermissions: [
              { resource: 'Orders', permissions: ['create'] },
            ],
          }) ||
            authorize({
              abilityPermissions: [
                { resource: 'Jobs', permissions: ['update'] },
              ],
            })) &&
            !!actionOptions.length && (
              <DropdownButton
                inverse
                text="Actions"
                icon="down"
                options={actionOptions}
                testSelector="job-details_actions_dropdown-btn"
              />
            )}
          <Authorize
            abilityPermissions={[{ resource: 'Jobs', permissions: ['read'] }]}>
            <Button
              inverse
              theme="square"
              onClick={refetch}
              disabled={isRefetching}
              testSelector="job-details_refresh_btn">
              {isRefetching ? <Loader /> : <Icon icon="refresh" />}
            </Button>
          </Authorize>
          <Authorize
            abilityPermissions={[{ resource: 'Jobs', permissions: ['read'] }]}>
            <Input
              type="checkbox"
              testSelector="job-details_auto-refresh_input"
              onChange={() => {
                turnAutoRefreshIsChecked(!autoRefreshIsChecked);
              }}
              label="Auto-Refresh"
              isChecked={autoRefreshIsChecked}
            />
          </Authorize>
        </div>
      </Header>

      <Authorize
        abilityPermissions={[
          { resource: 'Data-van-info', permissions: ['read'] },
        ]}>
        <Row>
          <TodayInfo
            stats={jobDetails.stats}
            isStagingSite={!!jobDetails.details?.stagingSite}
          />
          <JobDetailsTab storageConfig={jobDetails.storage} details={jobDetails.details} initFilters={initFilters} />
        </Row>
        <Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Data-van-info', permissions: ['read'] },
            ]}>
            <OrderList initFilters={initFilters} />
          </Authorize>
        </Row>
      </Authorize>

      {showEditJob && (
        <EditJob
          jobId={jobId}
          showEditJob={showEditJob}
          closeEditJobModal={() => turnEditJobModal(false)}
        />
      )}
      {showAddOrder && (
        <AddOrder
          jobId={jobId}
          showAddOrder={showAddOrder}
          initFilters={initFilters}
          closeAddOrderModal={() => turnAddModal(false)}
        />
      )}
    </div>
  );
};
export default JobDetails;
