import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setAllFields } from './helpers';
import actions from 'store/actions/Export';
import targetValue from 'utils/targetValue';
import TemplateItems from './TemplateItems';
import ItemsOrder from './ItemsOrder';

const AddTemplate = props => {
  const { showAddTemplate, closeAddTemplate } = props;
  const [step, setStep] = useState(1)

  return (
    <Modal
      className="modal-container"
      show={showAddTemplate}
      onHide={closeAddTemplate}>
      {currentStep(step, props, setStep)}
    </Modal>
  );
};

const mapStateToProps = state => ({
  selectedFields: setAllFields(state.exportPage.exportConfig.config, true),
  isAllChecked: state.exportPage.exportConfig.isAllChecked,
  name: state.exportPage.exportConfig.name,
  config: state.exportPage.exportConfig.config,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkAllFields: () => dispatch(actions.checkAllFields()),
  checkOneField: name => dispatch(actions.checkOneField(name)),
  setTemplateName: e => dispatch(actions.setTemplateName(targetValue(e))),
  createTemplate: (e,itemList) => {
    e.preventDefault();
    dispatch(actions.createTemplate(ownProps.closeAddTemplate, itemList));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTemplate);

const currentStep = (step, props, setStep) => {
  switch (step) {
    case 1:
      return <TemplateItems {...{ props, setStep}} key="templateItems" />;
    case 2:
      return <ItemsOrder {...{ props, setStep}} key="itemsOrder" />;
  }
};
