export const userRole = {
  1: 'Vendor',
  2: 'Customer',
  3: 'Carrier',
  7: 'Job Coordinator',
  6: 'Job Manager',
};

export const userRoleLinks = {
  1: 'vendor',
  2: 'customer',
  3: 'carrier',
  7: 'job-coordinator',
  6: 'job-manager',
};
