import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from 'components/shared/Button';
import { useAnalyticsList } from './hooks';

const DriverAnalytics = ({ carrierId, closeModal, showModal }) => {
  const viewArray = useAnalyticsList(carrierId);

  const renderDataItem = item => (
    <tr key={`item-${item.driver_id}`}>
      <td>{item.driver_name}</td>
      <td>{item.driver_id}</td>
      <td>{item.truck_no}</td>
    </tr>
  );

  const renderViewArrayItem = array => (
    <div key={`array-${array.header}`}>
      <Modal.Header>
        <Modal.Title as="h3">{array.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table--hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Driver Id</th>
              <th>Truck №</th>
            </tr>
          </thead>
          <tbody>{(array.data || []).map(renderDataItem)}</tbody>
        </table>
      </Modal.Body>
    </div>
  );

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModal} onHide={closeModal}>
        {viewArray.map(renderViewArrayItem)}
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="carrier-details_drivers-analytics_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DriverAnalytics;
