import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datetime';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import { isTimeFormatValid } from 'utils/datePickersFunctions';
import { timeZonesForTimings } from 'components/globalInfo/timeZones';
import { Checkbox } from 'components/shared/Input';
import { useCustomers } from 'api/v2/customers';
import { Modal, Row } from 'react-bootstrap';
import { dayOptions } from './helpers';

const UpdateLocationTiming = ({
  updateTimings,
  setInputTiming,
  dellItemTiming,
  addItemTiming,
  disableSave,
  timeZone,
  setTimeZone,
  setExemptions,
  exemptions,
}) => {
  const { data: customers } = useCustomers();
  const customerOptions = useMemo(
    () =>
      (customers || []).map(i => ({
        label: i.name,
        value: i.id,
      })),
    [customers],
  );

  const [badTimeFormat, setBadTimeFormat] = useState(false);

  useEffect(() => {
    Object.values(updateTimings)
      .flat()
      .some(e => {
        const invalidTimeFormat =
          isTimeFormatValid(e.open) || isTimeFormatValid(e.close);
        if (invalidTimeFormat || e.isDanger) {
          disableSave(true);
          if (invalidTimeFormat) {
            setBadTimeFormat(true);
          }
          return true;
        }
        disableSave(false);
        setBadTimeFormat(false);
        return false;
      });
  }, [disableSave, updateTimings]);

  const renderTimingItem = (data, index) => {
    if (index !== 0 && (updateTimings[data.day] || []).some(i => i.closed)) {
      return null;
    }

    return (
      <div key={`update-timing-${index}`} className="row">
        <div
          className="form-group has-feedback col-md-3 col-lg-3 col-sm-3"
          data-testid={`input-timing-select-${index}`}>
          <Select
            customStyles={
              data.isDanger && {
                borderColor: 'red',
              }
            }
            placeholder="Day"
            value={dayOptions.find(i => i.value === data.day)}
            options={dayOptions}
            isDisabled
          />
        </div>
        <div
          className="form-group has-feedback col-md-3 col-lg-3 col-sm-3"
          data-testid={`update-timing-open-${index}`}>
          <DatePicker
            className={`${data.isDanger ? 'red-border' : ''
              } form-input__calendar`}
            dateFormat={false}
            timeFormat="hh:mm A"
            viewMode="time"
            value={data.open}
            onChange={e => setInputTiming(e, index, data.day, 'open')}
            data-testid="locations_update_open-time_dp"
            id="locations_update_open-time_dp"
            name="locations_update_open-time_dp"
          />
        </div>
        <div
          className="form-group has-feedback col-md-3 col-lg-3 col-sm-3"
          data-testid={`update-timing-close-${index}`}>
          <DatePicker
            className={`${data.isDanger ? 'red-border' : ''
              } form-input__calendar`}
            dateFormat={false}
            timeFormat="hh:mm A"
            viewMode="time"
            value={data.close}
            onChange={e => setInputTiming(e, index, data.day, 'close')}
            isValidDate={current => current.isSameOrAfter(data.open)}
            data-testid="locations_update_close-time_dp"
            id="locations_update_close-time_dp"
            name="locations_update_close-time_dp"
          />
        </div>
        <div className="form-group has-feedback col-lg-1 col-md-1 col-sm-1">
          {index === 0 && (
            <Checkbox
              isChecked={(updateTimings[data.day] || []).some(i => i.closed)}
              onChange={() =>
                setInputTiming(!data.closed ? 1 : 0, index, data.day, 'closed')
              }
              disabled={index !== 0}
            />
          )}
        </div>
        <div className="form-group has-feedback col-lg-1 col-md-1 col-sm-1">
          {!!index && (
            <Button
              onClick={e => dellItemTiming(e, index, data.day)}
              testSelector={`locations_update-modal_timing-${index}_btn`}>
              <Icon icon="minus" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderTimingDay = day => (
    <div className="row" key={`day-${day.value}`}>
      <div
        className="form-group has-feedback col-lg-1 col-md-1 col-sm-1"
        style={{ paddingLeft: 0 }}>
        {!(updateTimings[day.value] || []).some(i => i.closed) && (
          <Button
            onClick={() => addItemTiming('updatetimings', day.value)}
            testSelector="customer-details_billing_rate-schedule_add-day_btn">
            <Icon icon="plus" />
          </Button>
        )}
      </div>
      <div className="form-group has-feedback col-lg-11 col-md-11 col-sm-11">
        {(updateTimings[day.value] || [{ day: day.value }]).map(
          renderTimingItem,
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal.Header>
        <Modal.Title as="h4">Update Timing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-pannel-padding">
        <Row>
          <div className="form-group has-feedback col-md-6">
            <Select
              placeholder="Time Zone"
              onChange={setTimeZone}
              options={timeZonesForTimings}
              value={timeZonesForTimings.find(item => item.value === timeZone)}
              required
              testSelector="customer-details_billing_rate-schedule_time-zone_select"
            />
          </div>
          <div className="form-group has-feedback col-md-6">
            <Select
              label="Exemptions"
              onChange={setExemptions}
              options={customerOptions}
              value={customerOptions.filter(item =>
                exemptions.includes(item.value),
              )}
              isMulti
              testSelector="customer-details_billing_rate-schedule_exemptions_select"
            />
          </div>
        </Row>
        <div className="form-group">
          {badTimeFormat && (
            <div className="alert-input-msg">
              Time format should be &quot;hh:mm AM/PM&quot;
            </div>
          )}
          <div style={{ paddingLeft: '38em', paddingBottom: '1em' }}>
            CLOSED
          </div>
          {dayOptions.map(renderTimingDay)}
        </div>
      </Modal.Body>
    </div>
  );
};
export default UpdateLocationTiming;
