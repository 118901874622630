import React from 'react';
import SlidePanel from 'components/shared/SlidePanel';
import JobSlideoutDetail from './JobSlideoutDetail';

const CalendarJobSlideout = ({
  isVisible,
  closePanel,
  selectedJob,
  customerId,
}) => (
    <SlidePanel isVisible={isVisible} closePanel={closePanel} width="55%">
      <JobSlideoutDetail
        customerId={customerId}
        job={selectedJob}
      />
    </SlidePanel>
  );

export default CalendarJobSlideout;
