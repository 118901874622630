import React, { Suspense, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import { authorize } from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';

const user = authService.getUser();

const DriversList = React.lazy(() => import('./DriversListTab'));
const Districts = React.lazy(() => import('./DistrictsTab'));
const Documents = React.lazy(() => import('./DocumentsTab'));
const Jobs = React.lazy(() => import('./JobsTab'));

const ExportedTabs = ({ carrierId }) => {
  const [tabKey, setTabKey] = useState(
    user.role === ROLES.CUSTOMER ? 'districts' : 'drivers',
  );

  return (
    <div
      className="wrapper-content animated fadeInUp"
      style={{ minHeight: window.innerHeight }}>
      <div className="row">
        <div>
          <div className="tabs-container">
            <Tabs
              activeKey={tabKey}
              onSelect={x => setTabKey(x)}
              id="controlled-tab-example">
              {authorize({
                forbidden: ['CUSTOMER'],
                abilityPermissions: [
                  { resource: 'Drivers', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="drivers" title="Drivers List">
                    <Suspense fallback={<Loader />}>
                      <DriversList carrierId={carrierId} />
                    </Suspense>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Districts', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="districts" title="Districts">
                    <Suspense fallback={<Loader />}>
                      <Districts carrierId={carrierId} />
                    </Suspense>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Carriers', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="documents" title="Documents">
                    <Suspense fallback={<Loader />}>
                      <Documents carrierId={carrierId} />
                    </Suspense>
                  </Tab>
                )}
              {authorize({
                abilityPermissions: [
                  { resource: 'Jobs', permissions: ['read'] },
                ],
              }) && (
                  <Tab eventKey="jobs" title="Jobs">
                    <Suspense fallback={<Loader />}>
                      <Jobs carrierId={carrierId} />
                    </Suspense>
                  </Tab>
                )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExportedTabs;
