export const priceTypes = {
  1: { title: 'Distance price' },
  2: { title: 'Origin demurrage' },
  3: { title: 'Destination demurrage' },
  4: { title: 'Fuel Surcharge' },
  6: { title: 'Managerial Fee'},
  7: { title: 'Additional charge' },
  8: { title: 'Volume bonus' },
  9: { title: 'Vine Demurrage 12/24' },
  10: { title: 'Commodity Price' },
  11: { title: 'Custom' },
  12: { title: 'Bonus' },
  13: { title: 'Logistics' },
};
