export const checkLocationIcon = type => {
  switch (type) {
    case 1:
      return 'well_site';
    case 2:
      return 'sand_site';
    case 3:
      return 'water_disposal';
    case 4:
      return 'staging_site';
    case 5:
      return 'acid_facility';
    default:
      return '';
  }
};