import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import trackDriversActions from 'store/actions/TrackDrivers';
import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';
import { Col } from 'react-bootstrap';

const statusOptions = [
  { value: 0, label: 'Free' },
  { value: 1, label: 'Active' },
];

const FilterDrivers = props => {
  const {
    selectId,
    selectName,
    selectOfflineDrivers,
    selectDeletedDrivers,
    selectStatus,
    offline,
    isDeleted,
    jobs,
    isLoadedJobs,
    selectJobId,
  } = props;

  const jobsList = useMemo(() => {
    const formattedJobs = jobs.map(item => {
      item.label = `${item.id} | ${item.job_name}`;
      return item;
    });
    return filterOptions(formattedJobs, null, null, null, 'label');
  }, [jobs]);

  return (
    <div className="row">
      <div className="col-lg-12 track_driver_view">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h2>Filter</h2>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div
                className="form-group has-feedback col-lg-3 col-md-3 col-xs-12"
                style={{ paddingTop: 0 }}>
                <Input
                  onChange={selectId}
                  label="Truck"
                  testSelector="track-drivers_filter_truck_input"
                />
              </div>
              <div className="form-group has-feedback col-lg-3 col-md-3 col-xs-12">
                <Input
                  onChange={selectName}
                  label="Driver name"
                  testSelector="track-drivers_filter_driver-name_input"
                />
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="form-group" style={{ marginLeft: 15 }}>
                  <Select
                    placeholder="Job"
                    onChange={item => selectJobId(item?.value)}
                    options={jobsList}
                    isDisabled={!isLoadedJobs}
                    isClearable
                    testSelector="track-drivers_filters_sand-type_select"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="form-group" style={{ marginLeft: 15 }}>
                  <Select
                    placeholder="Status"
                    onChange={item => selectStatus(item?.value)}
                    options={statusOptions}
                    isClearable
                    testSelector="track-drivers_filters_status_select"
                  />
                </div>
              </div>
              <div className="row">
                <Col md={12} className="float-end">
                  <Button
                    inverse={offline}
                    onClick={selectOfflineDrivers}
                    className="float-end"
                    style={{ marginLeft: 20 }}
                    testSelector="track-driver_filters_online_btn">
                    <Icon icon="truck" className="icon--margin-right" />
                    {offline ? 'Online Drivers' : 'Offline Drivers'}
                  </Button>
                  <span>
                    <Input
                      type="checkbox"
                      label="Deleted Drivers"
                      isChecked={isDeleted}
                      onChange={selectDeletedDrivers}
                      className="float-end"
                      testSelector="track-driver_filters_deleted_checkbox"
                    />
                  </span>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  selectId: e =>
    dispatch(trackDriversActions.selectTruckNumber(e.target.value)),
  selectName: e =>
    dispatch(trackDriversActions.selectDriverName(e.target.value)),
  selectJobId: e => dispatch(trackDriversActions.selectJobId(targetValue(e))),
  selectStatus: e => dispatch(trackDriversActions.selectStatus(targetValue(e))),
  selectOfflineDrivers: () => {
    dispatch(trackDriversActions.selectOfflineDrivers());
  },
  selectDeletedDrivers: () =>
    dispatch(trackDriversActions.selectDeletedDrivers()),
});

export default connect(
  null,
  mapDispatchToProps,
)(FilterDrivers);
