import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrderDetails';
import { modelTypes } from 'components/globalInfo/modelPriceTypes';
import { carrierPercentageModelType } from 'components/globalInfo/carrierPercentageModelType';
import { carrierPercentageApplyTo } from 'components/globalInfo/carrierPercentageApplyTo';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import { filterOptions } from 'utils/selectUtils';

const EditPriceInfo = ({
  getOrderPriceModel,
  getRateTablesList,
  info,
  priceInfo,
  setInputValue,
  setSelectValue,
  closeInfo,
  editPriceModel,
}) => {
  useEffect(() => {
    const {
      order: { order_id, customer_id },
    } = info;
    getOrderPriceModel(order_id);
    getRateTablesList(customer_id);
  }, []);

  const { rateTables, input, priceModelReady } = priceInfo;
  const {
    percentage,
    formulaType,
    applyCarrierPercentageTo,
    typeProperty,
    rateTableId,
    mileage,
    billableMileage,
    customRate,
  } = input;

  const modelTypesList = filterOptions(modelTypes, null, null, null, 'title');

  const formattedRateTables = rateTables
    .filter(rate => !rate.isDeactivated)
    .map(rate => {
      rate.label = `${rate.id} | ${rate.name}`;
      return rate;
    });
  const rateTableList = filterOptions(
    formattedRateTables,
    null,
    null,
    null,
    'label',
  );

  const carrierPercentageModelTypeList = filterOptions(
    carrierPercentageModelType,
    null,
    null,
    null,
    'title',
  );
  const carrierPercentageApplyToList = filterOptions(
    carrierPercentageApplyTo,
    null,
    null,
    null,
    'title',
  );

  if (!priceModelReady) {
    return <Loader />;
  }
  return (
    <div>
      <form onSubmit={e => editPriceModel(e, closeInfo)}>
        <Modal.Header>
          <h3 className="modal-title">Edit</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ marginBottom: 40 }}>Delivery Rates</h3>
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-md-4">
              <div>
                <Select
                  onChange={item => setSelectValue(item.value, 'typeProperty')}
                  label="Calculation Type"
                  placeholder="Please Select Calculation Type"
                  value={modelTypesList.find(
                    item => item.value === typeProperty,
                  )}
                  options={modelTypesList}
                  required
                  testSelector="order-details_pricing_edit-price-info_calculation-type_select"
                />
              </div>
            </div>
            {(() => {
              switch (Number(typeProperty)) {
                case 0:
                case 1:
                case 2:
                  return (
                    <div className="form-group has-feedback col-md-4">
                      <div>
                        <Select
                          isDisabled={typeProperty !== 2}
                          label="Rate Table"
                          placeholder="Please Select Rate Table"
                          onChange={item =>
                            setSelectValue(item.value, 'rateTableId')
                          }
                          value={rateTableList.find(
                            item => item.value === rateTableId,
                          )}
                          options={rateTableList}
                          required
                          testSelector="order-details_pricing_edit-price-info_rate-table_select"
                        />
                      </div>
                    </div>
                  );
                case 3:
                  return (
                    <div className="form-group has-feedback col-md-4">
                      <div className="input-group">
                        <Input
                          type="number"
                          onChange={e => setInputValue(e, 'customRate')}
                          value={customRate}
                          label="Price per mile ($)"
                          step="0.01"
                          testSelector="order-details_pricing_info_edit_price-per-mile_input"
                        />
                      </div>
                    </div>
                  );
                case 4:
                  return (
                    <div className="form-group has-feedback col-md-4">
                      <div className="input-group">
                        <Input
                          type="number"
                          onChange={e => setInputValue(e, 'customRate')}
                          value={customRate}
                          label="Load rate ($)"
                          step="0.01"
                          testSelector="order-details_pricing_info_edit_load-rate_input"
                        />
                      </div>
                    </div>
                  );
              }
            })()}

            <div className="form-group has-feedback col-md-2">
              <div>
                <Input
                  type="number"
                  value={mileage}
                  label="Mileage"
                  disabled
                  testSelector="order-details_pricing_info_edit_mileage_input"
                />
              </div>
            </div>
            <div className="form-group has-feedback col-md-2">
              <div>
                <Input
                  type="number"
                  onChange={e => setInputValue(e, 'billableMileage')}
                  value={billableMileage}
                  label="Billable mileage"
                  testSelector="order-details_pricing_info_edit_billable-mileage_input"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ marginBottom: 40 }}>Carrier Percentage</h3>
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-md-4">
              <div>
                <Select
                  onChange={item => setSelectValue(item.value, 'formulaType')}
                  value={carrierPercentageModelTypeList.find(
                    item => item.value === formulaType,
                  )}
                  label="Price Rounding Model"
                  placeholder="Please Select Rounding Model"
                  options={carrierPercentageModelTypeList}
                  required
                  testSelector="order-details_pricing_edit-price-info_price-rounding_select"
                />
              </div>
            </div>
            <div className="form-group has-feedback col-md-4">
              <div>
                <Select
                  onChange={item =>
                    setSelectValue(item.value, 'applyCarrierPercentageTo')
                  }
                  value={carrierPercentageApplyToList.find(
                    item => item.value === applyCarrierPercentageTo,
                  )}
                  options={carrierPercentageApplyToList}
                  label="Apply to"
                  placeholder="Please select"
                  required
                  testSelector="order-details_pricing_edit-price-info_apply-to_select"
                />
              </div>
            </div>
            <div className="form-group has-feedback col-md-4">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'percentage')}
                value={percentage}
                label="Percentage"
                step="0.01"
                min="1"
                max="100"
                testSelector="order-details_pricing_info_edit_percetage_input"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeInfo}
            colour="white"
            children="Close"
            testSelector="order-details_price-info_edit_close_btn"
          />
          <Button
            type="submit"
            children="Submit"
            testSelector="order-details_price-info_edit_save_btn"
          />
        </Modal.Footer>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  priceInfo: state.orderDetails.priceInfo,
  info: state.orderDetails.info,
});

const mapDispatchToProps = dispatch => ({
  setSelectValue: (value, name) =>
    dispatch(actions.setInputValueEditPrice(value, name)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueEditPrice(targetValue(e), name)),
  getOrderPriceModel: orderId => dispatch(actions.getOrderPriceModel(orderId)),
  getRateTablesList: customerId =>
    dispatch(actions.getRateTablesList(customerId)),
  editPriceModel: (e, closeInfo) => {
    e.preventDefault();
    dispatch(actions.editPriceModel(closeInfo));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPriceInfo);
