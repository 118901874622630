import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDistrictDetails } from 'api/v2/districts';

import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import AutomatizeLogo from 'components/shared/AutomatizeLogo';

import Stats from './Stats';
import Tabs from './Tabs';
import Orders from './Orders';
import AddUnit from './Tabs/Units/AddUnitModal';
import EditDistrict from './EditDistrictModal';

const DistrictDetails = () => {
  const params = useParams();

  const { data: districtData, isLoading } = useDistrictDetails(params.districtId);
  const isLoaded = !isLoading && districtData;

  const [tab, setTab] = useState('customers');

  const [showAddModal, setAddModal] = useState(false);
  const triggerAddModal = () => setAddModal(!showAddModal);

  const [showEditModal, setEditModal] = useState(false);
  const triggerEditModal = () => setEditModal(!showEditModal);

  if (!isLoaded) {
    return <Loader />;
  }

  const district = districtData.details[0];
  const activeOrders = districtData.activeOrders || [];

  return (
    <div className="row">
      {showAddModal && (
        <AddUnit showAddModal closeAddModal={triggerAddModal} districtId={Number(params.districtId)} />
      )}
      {showEditModal && (
        <EditDistrict showModal={showEditModal} closeModal={triggerEditModal} district={district} />
      )}
      <div style={{ display: 'flex' }}>
        <Header title="District details" />
        {district.companyOwnerId === 1 &&
          district.companyOwnerType === 6 && (
            <AutomatizeLogo margin={5} />
          )}
        <div style={{ margin: '7px 0 0 15px' }}>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Districts',
                  permissions: ['update'],
                },
              ],
            }}>
            <Button
              onClick={triggerEditModal}
              theme="small"
              testSelector="district-details_edit_btn">
              <Icon icon="pencil" />
            </Button>
          </Authorize>
        </div>
      </div>
      <div>
        <Header title={`${district.name}`} />
      </div>
      {!!district.isCarrierRatioEnabled && (
        <>
          <h2>
            Active trucks:{' '}
            <span
              className={
                activeOrders.length / district.activeTrucks > 1
                  ? 'text-danger'
                  : ''
              }>
              {activeOrders.length} / {district.activeTrucks}
            </span>
          </h2>
          {activeOrders.length / district.activeTrucks > 1 ? (
            <p>
              {`Carrier ratio has been changed and drivers will only be able to be
              assigned orders when ratio falls below ${district.activeTrucks
                } and space is available`}
            </p>
          ) : (
            ''
          )}
        </>
      )}
      <Stats district={districtData} />
      <Tabs tab={tab} info={districtData} setTab={setTab} triggerAddModal={triggerAddModal} />
      <div style={{ marginTop: '20px' }}>
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Orders',
                permissions: ['read'],
              },
            ],
          }}>
          <Orders district={districtData} />
        </Authorize>
      </div>
    </div>
  );
};

export default DistrictDetails;
