import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ServerPagination from 'components/shared/ServerPagination';
import TableList from 'components/shared/TableList';
import { newConvertTime } from 'utils/convertTime';
import { convertNumberToCurrencyFromAmount } from 'utils/convertNumberToCurrency';
import SlidePanel from 'components/shared/SlidePanel';
import Authorize, { authorize } from 'components/common/Authorize';
import CreditMemoDetails from '../CreditMemoDetails';

import {
  clearCreditMemoDetails,
} from 'store/actions/CreditMemos';

import authService from 'services/auth/service';

const CreditMemosList = ({
  creditMemos,
  ordering,
  filters,
  setOrdering,
  pagination,
  setPagination,
  totalItems,
  clearCreditMemoDetails
  // creditMemos: []
}) => {
  const [selectedCreditMemo, selectNewCreditMemo] = useState(null);

  const columns = [
    { key: 'id', value: 'ID' },
    { key: 'invoice_id', value: 'Invoice' },
    { key: 'created_at', value: 'Date' },
    { key: 'total_price', value: 'Total' },
  ];

  const sorting = key => {
    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const creditMemoItems = creditMemos.map(item => ({
    key: item.id,
    onRowClick: () => selectNewCreditMemo(item.id),
    originalData: item,
    data: {
      id: () => `#${item.id}`,
      invoice_id: () => (
        <>
          <div>
            # {item.invoiceId}
          </div>
          <div>Total orders: {item.orderCount}</div>
        </>
      ),
      created_at: () => (
        <div>Created: {newConvertTime(item.createdAt)}</div>
      ),
      total_price: () => (
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Pricing',
                permissions: ['read']
              }
            ]
          }}
        >
          {convertNumberToCurrencyFromAmount(item.totalPrice)}
        </Authorize>
      ),
    },
  }));

  return (
    <div className="credit-memo-list">
      <TableList
        clickableTable
        onSort={sorting}
        columns={columns}
        listItems={creditMemoItems}
      />

      <SlidePanel
        isVisible={!!selectedCreditMemo}
        closePanel={() => {
          clearCreditMemoDetails();
          selectNewCreditMemo(null);
        }}>
          <CreditMemoDetails creditMemoId={selectedCreditMemo} />
      </SlidePanel>

      {creditMemos && creditMemos.length > 0 && (
        <ServerPagination
          useLimit
          pagination={pagination}
          onUpdate={setPagination}
          totalItems={totalItems}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  creditMemos: state.creditMemos.creditMemos.creditMemos,
  totalItems: state.creditMemos.creditMemos.totalItems
});


const mapDispatchToProps = dispatch => ({
  clearCreditMemoDetails: () => dispatch(clearCreditMemoDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemosList);
