import React from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Queue';
import Link from 'components/common/Link';
import IboxTools from 'components/common/IboxTools';
import { authorize } from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import { availableTo } from 'utils/calculateTime';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';
import Label from 'components/shared/Label';

const user = authService.getUser();

const DriversList = props => {
  const {
    drivers,
    caretCarrier,
    caretDriverEquipment,
    caretDriverDistrict,
    caretDriverName,
    sortDriversData,
    equipmentRequired,
  } = props;

  return (
    <div className="ibox float-e-margins queue-table-list">
      <div className="ibox-title">
        <h3>Drivers List</h3>
        <IboxTools />
      </div>
      <div
        className="ibox-content"
        style={{
          maxHeight: 500,
          overflowY: 'auto',
          marginBottom: 70,
          WebkitOverflowScrolling: 'touch',
        }}>
        <div className="table-responsive">
          <table id="datatable_table" className="table table--hover">
            <thead>
              <tr>
                <th>
                  Name{' '}
                  {caretDriverName ? (
                    <Icon
                      onClick={() => sortDriversData('name')}
                      icon="caret-up"
                    />
                  ) : (
                    <Icon
                      onClick={() => sortDriversData('-name')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>
                  Carrier{' '}
                  {caretCarrier ? (
                    <Icon
                      onClick={() => sortDriversData('carrierName')}
                      icon="caret-up"
                    />
                  ) : (
                    <Icon
                      onClick={() => sortDriversData('-carrierName')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>
                  District{' '}
                  {caretDriverDistrict ? (
                    <Icon
                      onClick={() => sortDriversData('districtName')}
                      icon="caret-up"
                    />
                  ) : (
                    <Icon
                      onClick={() => sortDriversData('-districtName')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>
                  Equipment{' '}
                  {caretDriverEquipment ? (
                    <Icon
                      onClick={() => sortDriversData('equipment')}
                      icon="caret-up"
                    />
                  ) : (
                    <Icon
                      onClick={() => sortDriversData('-equipment')}
                      icon="caret-down"
                    />
                  )}
                </th>
                <th>HOS</th>
              </tr>
            </thead>

            <tbody className="driver-table">
              {drivers.map(data => (
                <tr
                  key={data.id}
                  className={data.class}
                  title={`#${data.id}`}>
                  <td>
                    {/*{data.onlineStatus == 1 ? (*/}
                    {/*<span className="badge badge--primary">online</span>*/}
                    {/*) : (*/}
                    {/*<span className="badge badge--danger">offline</span>*/}
                    {/*)} <br />*/}
                    {authorize({
                      abilityPermissions: [
                        { resource: 'Drivers', permissions: ['read'] },
                      ],
                    }) ? (
                      <Link
                        {...{
                          id: data.id,
                          to: 'driver-details',
                        }}>
                        {data.name}
                      </Link>
                    ) : (
                      data.name
                    )}
                    <div>
                      {user.role === ROLES.JOB_MANAGER && Boolean(data.on_hold) && (
                        <Label style={{ marginBottom: '3px' }} type="danger">
                          On Hold
                        </Label>
                      )}
                      {user.role === ROLES.JOB_MANAGER && data.is_active === 0 && (
                        <Label style={{ marginBottom: '3px' }} type="danger">
                          InActive
                        </Label>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>{data.carrierName}</div>
                    <div>
                      <strong>Truck: </strong>#{data.truckNumber}
                    </div>
                  </td>
                  <td>
                    {data.districts.map((district, index) =>
                      index === 0 ? district.name : `, ${district.name}`,
                    )}
                  </td>
                  <td>
                    {equipmentRequired.map(
                      item =>
                        item.id == data.equipment && (
                          <>
                            <span className="badge">{item.title}</span>
                            {item.title === 'Containerized' && (
                              <>
                                <br />
                                <ToolTip title="Containers" placement="right">
                                  <small>
                                    {data.containers
                                      .map(item => item)
                                      .join(', ')}
                                  </small>
                                </ToolTip>
                              </>
                            )}
                          </>
                        ),
                    )}
                  </td>
                  <td>
                    {data.available_to && (
                      <span
                        className={`badge badge--${
                          availableTo(data.available_to).badge
                        }`}>
                        {availableTo(data.available_to).hours}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  caretCarrier: state.queue.sortDriversList.carrierName,
  caretDriverDistrict: state.queue.sortDriversList.districtName,
  caretDriverEquipment: state.queue.sortDriversList.equipment,
  caretDriverName: state.queue.sortDriversList.name,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  sortDriversData: value => {
    dispatch(actions.setSortDriversData(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriversList);
