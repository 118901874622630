import { useSearchParams } from 'helpers/hooks';
import { useMemo, useReducer, useState } from 'react';

export const useOrderFromQR = initOrder => {
  const urlParams = useSearchParams();

  const order = useMemo(() => {
    const complete = urlParams.get('complete');
    if (complete) {
      const fromQr = urlParams.get('fromQr');
      return { ...initOrder, fromQr: Boolean(fromQr) };
    }
    return initOrder;
  }, [initOrder, urlParams]);

  return order;
};

/**
 * @typedef {{
 *   modify: boolean;
 *   uploadTicket: boolean;
 *   divert: boolean;
 *   transfer: boolean;
 *   requeue: boolean;
 * }} OrderModals
 */

const initialOrderModals = {
  modify: false,
  uploadTicket: false,
  divert: false,
  transfer: false,
  requeue: false,
};

/**
 *
 * @param {OrderModals} state
 * @returns {OrderModals}
 */
const OrderModalsReducer = (state, action) => {
  switch (action.type) {
    case 'toggle_modal':
      return {
        ...state,
        [action.name]: !state[action.name],
      };
    case 'clear_state':
      return initialOrderModals;
    default:
      return state;
  }
};

export const useOrderModals = () => {
  /** @type {[OrderModals, () => {}]} */
  const [data, dispatch] = useReducer(OrderModalsReducer, initialOrderModals);

  const toggleModal = name => () => dispatch({ type: 'toggle_modal', name });

  return {
    openModals: data,
    toggleModal,
  };
};

export const useDemurrageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();

  const toggleModal = (demurrageData) => {
      setData(demurrageData);  
      setShowModal(value => !value);
    };

  return {
    data,
    show: showModal,
    toggle: toggleModal,
  }
}
