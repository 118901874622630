import JobDetails from 'components/views/JobDetails';
import OrderDetails from 'components/views/OrderDetails';
import LocationDetails from 'components/views/LocationDetails';
import SandSiteSchedule from 'components/views/SandSiteSchedule';
import CustomerDetails from 'components/views/CustomerDetails';
import UserProfile from 'components/views/UserProfile';
import DriverDetails from 'components/views/DriverDetails';
import DistrictDetails from 'components/views/DistrictDetails';
import CarrierDetails from 'components/views/CarrierDetails';
import Districts from 'components/views/DistrictsList';
import SearchResult from 'components/views/SearchResult';
import JobList from 'components/views/JobsList';
import JobStats from 'components/views/JobStats';
import JobPlanDetails from 'components/views/JobPlans/JobPlansDetails'

export const links = [
  {
    path: '/job-details/:jobId',
    component: JobDetails,
  },
  {
    path: '/order-details/:orderId',
    component: OrderDetails,
  },
  {
    path: '/location-details/:locationId',
    component: LocationDetails,
  },
  {
    path: '/sand-site-schedule/:sandSiteId',
    component: SandSiteSchedule,
  },
  {
    path: '/customer-details/:customerId',
    component: CustomerDetails,
  },
  {
    path: '/district-details/:districtId',
    component: DistrictDetails,
  },
  {
    path: '/update-profile',
    component: UserProfile,
  },
  {
    path: '/driver-details/:driverId',
    component: DriverDetails,
  },
  {
    path: '/carrier-details/:carrierId',
    component: CarrierDetails,
  },
  {
    path: '/search-result/:resultId',
    component: SearchResult,
  },
  // {
  //   path: '/chat/:id',
  //   component: Chat,
  // },
  {
    path: '/jobs-list',
    component: JobList,
  },
  {
    path: '/districts-list',
    component: Districts,
  },
  {
    path: '/job-stats/:jobId',
    component: JobStats,
  },
  {
    path: '/jobPlan-details/:jobPlanId',
    component: JobPlanDetails,
  }
];

