export default {
  '0': {
    title: 'Open',
    color: 'label-info',
    btnColor: 'info',
  },
  '1': {
    title: 'Reviewed',
    color: 'label-primary',
    btnColor: 'primary',
  },
  '2': {
    title: 'Approved',
    color: 'label-success',
    btnColor: 'success',
  },
  '3': {
    title: 'Unapproved',
    color: 'label-info',
    btnColor: 'info',
  },
  '4': {
    title: 'Contested',
    color: 'label-danger',
    btnColor: 'danger',
  },
};
