import { useReducer, useMemo } from 'react';

const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const initialState = {
  selectedDistricts: [
    { value: defaultOnBoardingDistrict, label: 'On-boarding' },
  ],
  email: '',
  name: '',
  address: '',
  phone: '',
  fleetSize: '',
  city: '',
  numberUS: '',
  numberEIN: '',
  numberMC: '',
  file: null,
  zip: '',
  state: '',
  companyType: '',
  qbCarrierId: '',
  interstateCertified: false,
  factorViewId: '',
  bcSync: false,
  isNonPurchasable: false,
};

const carrierFormReducer = (state, action) => {
  switch (action.type) {
    case 'change_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'change_districts':
      return {
        ...state,
        selectedDistricts: action.selectedDistricts,
      };

    default:
      return state;
  }
};

export const useCarrierForm = () => {
  const [state, dispatch] = useReducer(carrierFormReducer, initialState);

  const handlers = useMemo(
    () => ({
      valueChange: (name, value) =>
        dispatch({
          type: 'change_value',
          name,
          value,
        }),
      districtChange: selectedDistricts => {
        const hasDefault = selectedDistricts.some(
          option => option.value === defaultOnBoardingDistrict,
        );
        if (hasDefault) {
          return dispatch({
            type: 'change_districts',
            selectedDistricts,
          });
        }
        const newDistricts = [
          ...selectedDistricts,
          { value: defaultOnBoardingDistrict, label: 'On-boarding' },
        ];
        return dispatch({
          type: 'change_districts',
          selectedDistricts: newDistricts,
        });
      },
    }),
    [],
  );

  const isValid = useMemo(() => {
    if (
      !state.name ||
      !state.email ||
      !state.phone ||
      !state.fleetSize ||
      !state.address ||
      !state.city ||
      !state.state ||
      !state.zip ||
      !state.numberUS ||
      !state.numberEIN ||
      !state.numberMC ||
      state.selectedDistricts.length === 0 ||
      !state.file ||
      state.companyType === ''
    ) {
      return false;
    }
    return true;
  }, [state]);

  return {
    data: state,
    handlers,
    isValid,
  };
};
