const getEnvironment = baseUrl => {
  switch (baseUrl) {
    case 'http://localhost:3000/' || 'https://api.dev.automatizelabs.com/':
      return 'development';
    case 'https://api.uat.automatizelabs.com/':
      return 'UAT';
    case 'https://api.atmz.io/':
      return 'production';
    default:
      return 'development';
  }
};

export { getEnvironment };
