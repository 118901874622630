import React, { useState } from 'react';

import Select from '../Select';

const options = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 500, label: '500' },
  { value: 750, label: '750' },
  { value: 1000, label: '1000' },
];

const PaginationLimit = ({ onLimitChange, currentLimit = 10 }) => {
  const [limit, setLimit] = useState(currentLimit);

  const updateLimit = value => {
    setLimit(value);
    if (onLimitChange) {
      onLimitChange(value);
    }
  };

  return (
    <Select
      placeholder="Results"
      options={options}
      onChange={({ value }) => updateLimit(value)}
      value={options.find(({ value }) => value === limit)}
      testSelector="pagination_limit_results_select"
    />
  );
};

export default PaginationLimit;
