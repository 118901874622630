import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/DriverDetails';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

const EditTruck = ({
  closeModalEditInfo,
  showEditTruck,
  closeEditTruck,
  editData,
  changeDriverTruck,
  setModalValue,
  requiredEquipment,
}) => {
  const { equipment, truck, trailer, container } = editData;
  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => () => closeModalEditInfo(), []);

  const equipmentOptions = requiredEquipment.map(item => ({
    value: item.id,
    label: item.title,
  }));

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      !truck?.trim().length ||
      !trailer?.trim().length ||
      !container?.trim().length ||
      !equipment
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [editData]);

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showEditTruck}
      onHide={closeEditTruck}>
      <form role="form" onSubmit={changeDriverTruck}>
        <Modal.Header>
          <h3>Edit Truck Information</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setModalValue(e, 'truck')}
                value={truck}
                label="Truck number"
                required
                testSelector="driver-details_edit-truck_truck-number_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setModalValue(e, 'trailer')}
                value={trailer}
                label="Trailer number"
                required
                testSelector="driver-details_edit-truck_trailer-number_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Please Select Equipment"
                onChange={item => setModalValue(item.value, 'equipment')}
                options={equipmentOptions}
                value={equipmentOptions.find(
                  equipmentOption => equipmentOption.value == equipment,
                )}
                required
                testSelector="driver-details_cedit-truck_equipment_select"
              />
            </div>

            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setModalValue(e, 'container')}
                value={container}
                label="Container"
                required
                testSelector="driver-details_edit-truck_container_input"
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeEditTruck}
            colour="white"
            testSelector="driver-details_edit-truck_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="driver-details_edit-truck_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  editData: state.driverProfile.editInfo.editInfoDriver,
  requiredEquipment: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setModalValue: (e, name) => {
    dispatch(actions.setEditDriverValue(targetValue(e), name));
  },
  changeDriverTruck: e => {
    e.preventDefault();
    dispatch(actions.changeDriverTruck(ownProps.closeEditTruck));
  },
  closeModalEditInfo: () => dispatch(actions.closeModalEditInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTruck);
