import React from 'react';

const NavigationMenu = ({ open, listItems }) => (
  <ul
    data-test="navigation_submenu"
    className={`navigation__submenu
      ${(open && 'navigation__submenu--open') || ''}`}>
    {listItems}
  </ul>
);

export default NavigationMenu;
