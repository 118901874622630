import React from 'react';
import Icon from 'components/shared/Icon';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';

const SandSiteInfo = ({ sandSite, totalOrders, averageTimeLoad }) => (
  <Card>
    <Card.Body>
      <h2 style={{ display: 'inline' }}>
        #{sandSite.id}
        <strong className="text-navy"> {sandSite.name}</strong>
        <Badge
          bg="none"
          className="badge-info float-end"
          title="Average loading time for the last 24 hours before the last loaded order">
          Average loading time: {Math.round(averageTimeLoad) || 0} min
        </Badge>
        <Badge bg="none" className="badge-primary float-end">
          Completed orders: {totalOrders}
        </Badge>
      </h2>
      <Row>
        <Col lg={4}>
          <Table>
            <tbody>
              <tr>
                <td>
                  <strong>
                    <Icon icon="envelope" /> Email:&nbsp;
                  </strong>
                  {sandSite.email || 'N/A'}
                </td>
              </tr>
              <tr className="border-bottom-table">
                <td>
                  <strong>
                    <Icon icon="phone" /> Phone:&nbsp;
                  </strong>
                  {sandSite.contactNumber}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={4}>
          <Table>
            <tbody>
              <tr>
                <td>
                  <strong>
                    <Icon icon="map-marker" /> State:&nbsp;
                  </strong>
                  {sandSite.state}, {sandSite.city}
                </td>
              </tr>
              <tr className="border-bottom-table">
                <td>
                  <strong>Address:&nbsp; </strong>
                  {sandSite.address}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={4}>
          <Table>
            <tbody>
              <tr className="border-bottom-table">
                <td>
                  <strong>Special instructions:&nbsp; </strong>
                  {sandSite.loadingSiteSpecialInstructions}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default SandSiteInfo;
