import { carrier } from 'services/carrier/service';
import UpdatePO from 'store/constants/UpdatePO';
import swal from 'bootstrap-sweetalert';

const clear = () => ({ type: UpdatePO.VIEW_PO_UPDATE_MODAL_CLEAR });

const init = data => dispatch => {
  const success = (res, carriers) => ({
    type: UpdatePO.VIEW_PO_UPDATE_MODAL_INIT,
    res,
    carriers,
  });
  carrier
    .getCarriers()
    .then(msg => {
      dispatch(success(data, msg.data.data.carriers));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  dispatch(success(data));
};

const setValue = (value, name) => ({
  type: UpdatePO.VIEW_PO_UPDATE_MODAL_SET_VALUE,
  value,
  name,
});

export default {
  init,
  clear,
  setValue,
};
