const { client } = require('api/client');

/**
 * @typedef {{
 *  id: number;
 *  name: string;
 * }} Equipment
 */

/**
 * @typedef {{
 *  id: number;
 *  name: string;
 * }} Tag
 */

/**
 * @typedef {{
 *  docId: string;
 *  docName: string;
 * }} Document
 */

/**
 * @typedef {{
 *  name: string;
 *  commodityType: number;
 *  driverDisplay: string;
 *  unitOfMeasureId: number;
 *  unitOfMeasureName: string;
 *  equipment: Equipment[];
 *  tags: Tag[];
 *  documents: Document[];
 * }} Commodity
 */

export const getCommodities = async () => {
  const { data } = await client.get('v2/commodities');
  return data.data.commodities;
};

/**
 * @param {number} commodityId
 * @returns {Promise<Object>}
 */
export const getCommodity = async commodityId => {
  const { data } = await client.get(`v2/commodities/${commodityId}`);
  return data.data.commodity;
};

/**
 * @param {number} customerId
 * @returns {Promise<Object>}
 */
export const getCustomerCommodities = async customerId => {
  const { data } = await client.get(`v2/commodities/customer/${customerId}`);
  return data.data.commodities;
};

export const getJobCommodities = async jobId => {
  const { data } = await client.get(`v2/jobs/${jobId}/commodities`);
  return data.data;
}

export const getCommoditiesTypes = async () => {
  const { data } = await client.get('v2/commodities/types');
  return data.data;
};

/**
 * @typedef {{
 *  name: string
 *  commodityType: number
 *  uom: number
 *  equipmentTypes: number[]
 *  driverDisplay: string
 *  documents: Document[]
 *  tags: number[]
 * }} commodity
 * @returns {Promise<Object>}
 */
export const createCommodity = async commodity => {
  const { data } = await client.post(`v2/commodities`, commodity);
  return data;
};

/**
 * @typedef {{
 *  commodityId: number;
 *  name: string
 *  commodityType: number
 *  uom: number
 *  equipmentTypes: number[]
 *  driverDisplay: string
 *  documents: Document[]
 *  tags: number[]
 * }} commodity
 * @returns {Promise<Object>}
 */
export const editCommodity = async ({ commodityId, commodity }) => {
  const { data } = await client.patch(
    `v2/commodities/${commodityId}`,
    commodity,
  );
  return data;
};

/**
 * @param {Object} params
 * @param {number} params.commodityId
 * @returns {Promise<Object>}
 */
export const getExtRef = async ({ commodityId }) => {
  const { data } = await client.get(`/v2/commodity/${commodityId}/references`);
  return data.data;
};

/**
 * @typedef {{
 *  commodityId: number;
 *  extRef: string
 *  extRefuom: number;
 * }} extRef
 * @returns {Promise<Object>}
 */
export const addExtref = async ({ commodityId, extRef }) => {
  const { data } = await client.post(
    `v2/commodity/${commodityId}/references`,
    extRef,
  );
  return data;
};

/**
 * @param {number} commodityId
 * @returns {Promise<Object>}
 */
export const deleteCommodity = async (commodityId) => {
  const { data } = await client.delete(`v2/commodities/${commodityId}`);
  return data;
};
