import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from 'components/views/Login';
import ResetPassword from 'components/views/ResetPassword';
import Invoice from 'components/views/Invoice';
import { history } from '../helpers/history';
import { alertActions } from '../store/modules/actions/alert.actions';
import layoutActions from '../store/modules/actions/layoutActions';
import headerActions from './common/Header/actions';
import chatActions from '../store/actions/Chat';
import dispatchOnLocationChange from '../utils/dispatchOnLocationChange';

import Main from './layouts/Main';
import '../config/axios.config';

const App = props => {
  const { dispatch, navIsHidden, url } = props;

  history.listen(location => {
    // hide navigation on mobile on page change
    if (window.innerWidth < 769 && !navIsHidden) {
      dispatch(layoutActions.toggleNavigationShownOrHidden());
    }
    const pathObject = {
      '/': alertActions.clear,
    };
    const bangPathObject = {
      '/search-result': headerActions.clearState,
      '/chat': chatActions.clearChat,
    };
    dispatchOnLocationChange(dispatch, location, pathObject);
    dispatchOnLocationChange(dispatch, location, bangPathObject, false);
  });

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/invoice">
          <Invoice />
        </Route>
        <Route path="/job-manager">
          <Main />
        </Route>
        <Route path="/job-coordinator">
          <Main />
        </Route>
        <Route path="/customer">
          <Main />
        </Route>
        <Route path="/carrier">
          <Main />
        </Route>
        <Route path="/vendor">
          <Main />
        </Route>

        <Route path="*" render={() => <Redirect to={url || '/login'} />} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = state => {
  const {
    auth: { login },
    layout: { navIsHidden },
  } = state;

  const { url } = login;
  return {
    url,
    navIsHidden,
  };
};

// Adds hot reloading to all react components under App
const ExportedApp = connect(mapStateToProps)(App);
export default ExportedApp;
