import React from 'react';
import Modal from 'components/shared/Modal';
import authService from 'services/auth/service';
import { connect } from 'react-redux';
import { userRole } from 'components/globalInfo/userRoles';
import Button from 'components/shared/Button';
import actions from 'components/common/Navigation/actions';
import { Col, Row } from 'react-bootstrap';

const SwitchRoleModal = ({ open, onClose, switchRole, userLoggedIn }) => {
  // TODO Currently, the 'active' property returns as a string, if it change -> update code to .filter(role => role.active)
  const roles = authService.getRoles().filter(role => !!Number(role.active));
  const {
    currentRole: { entityId, roleTypeId, roleId },
  } = userLoggedIn;

  const body = (
    <div className="role-switch-modal__contents">
      <div className="role-switch-modal__contents--role-buttons">
        <Row>
        {roles.map((role, index) => (
          <Button
            key={index}
            testSelector={`user-widget_role_btn_${index}`}
            theme="tall"
            onClick={() => {
              switchRole(role);
            }}
            disabled={entityId === role.entityId && roleId === role.roleId && roleTypeId === role.roleTypeId}>
            <h3 className="role-switch-modal__contents--button-text-big">
              {role.entityName}
            </h3>
            <h4 className="role-switch-modal__contents--button-text-small">
              {userRole[role.roleId]} - ({role.roleTypeName})
            </h4>
          </Button>
        ))}
        </Row>
      </div>
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} title="Switch Role" body={body} />
  );
};
const mapDispatchToProps = dispatch => ({
  switchRole: role => dispatch(actions.switchRole(role)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SwitchRoleModal);
