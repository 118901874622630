import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import authService from 'services/auth/service';
import {
  loadPermissionDataAction,
  changeCompanyTypeIdAction,
} from 'store/actions/Roles';
import RolesPage from 'components/views/Roles/RolesPage';

const RolesHoc = ({
  loadPermissionData,
  loading,
  permissionData,
  changeCompanyTypeId,
}) => {
  useEffect(() => {
    // Load role data on mount
    const user = authService.getUser();
    loadPermissionData(user.companyType);
  }, []);

  return (
    <RolesPage
      permissionData={permissionData}
      loading={loading}
      loadPermissionData={loadPermissionData}
      changeCompanyTypeId={changeCompanyTypeId}
    />
  );
};

const mapStateToProps = state => ({
  permissionData: state.roles.permissions.permissionData,
  loading: state.roles.permissions.loading,
});

const mapDispatchToProps = dispatch => ({
  loadPermissionData: companyTypeId =>
    dispatch(loadPermissionDataAction(companyTypeId)),
  changeCompanyTypeId: companyTypeId =>
    dispatch(changeCompanyTypeIdAction(companyTypeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RolesHoc);
