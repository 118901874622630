import axios from 'axios';

export default {
  get() {
    return axios.get('v2/job-manager/demurrage-reason');
  },
  edit(data) {
    return axios.put(`v2/job-manager/demurrage-reason/${data.reasonId}`, data);
  },
  add(data) {
    return axios.post('v2/job-manager/demurrage-reason', data);
  },
  remove(data) {
    return axios.delete(`v2/job-manager/demurrage-reason/${data.reasonId}`, {
      data,
    });
  },
  editDemurrageReason(params) {
    return axios.put(`v2/order/${params.orderId}/demurrage-reason`, params);
  },
};
