import React from 'react';

import { useOrderPrices } from 'api/v2/orders';

const Price = ({ order }) => {
  const { data: priceData } = useOrderPrices(order.order_id);

  const prices = priceData?.prices ?? [];
  const endToEndTime = prices.find(item => Boolean(item.endtoEndTime))
    ?.endtoEndTime;

  const sharedOrder = order.accessLevel === 'shared';
  const { totalPrice } = order;
  const mileage = order?.mileage;

  const renderPriceItem = (item, index) => (
    <div key={`price-${index}`}>
      {!!item.price && (
        <div
          style={
            prices?.length > 1
              ? {
                  borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
                  marginBottom: '15px',
                }
              : { marginBottom: '15px' }
          }>
          <strong>{item.title}</strong>
          {item.name && <span className="text-navy">{` - ${item.name}`}</span>}
          {': '}
          <strong className="text-navy">${item.price} </strong>- {item.comment}
        </div>
      )}
    </div>
  );

  return (
    <>
      <h3>
        Total price:
        <span className="text-navy"> ${sharedOrder ? 0 : totalPrice}</span>
      </h3>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Mileage: </strong>
        <span className="text-navy">
          {sharedOrder ? 'N/A' : mileage ?? 'N/A'}
        </span>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Order Time: </strong>
        <span className="text-navy">
          {endToEndTime && !sharedOrder ? `${endToEndTime} minutes` : 'N/A'}
        </span>
      </div>
      {prices.map(renderPriceItem)}
    </>
  );
};

export default Price;
