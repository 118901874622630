import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import { useAddDistrict } from 'api/v2/districts';

import targetValue from 'utils/targetValue';
import actions from 'store/actions/Districts';

const Add = ({
  clearModal,
  showAdd,
  closeAdd,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(
    () => () => {
      clearModal();
    },
    [],
  );
  const { mutate: addDistrict } = useAddDistrict();
  const [inputValues, _setInputValue] = useState({});

  const setInputValue = (e, name) =>
    _setInputValue(prev => ({ ...prev, [name]: targetValue(e) }))

  const add = (e, closeModal) => {
    e.preventDefault();
    addDistrict(
      {
        ...inputValues,
        district: inputValues.name,
        isCarrierRatioEnabled: Number(inputValues.isCarrierRatioEnabled ?? 0),
        isManualDispatchEnabled: Number(inputValues.isManualDispatchEnabled ?? 0),
      },
      {
        onSuccess: closeModal
      }
    );
  }

  // determine add disabled
  useEffect(() => {
    let disabled = false;

    if (!inputValues.name?.trim().length || !inputValues.districtDetails?.trim().length) {
      disabled = true;
    }

    setSaveDisabled(disabled);
  }, [inputValues]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showAdd} onHide={closeAdd}>
        <Modal.Header>
          <Modal.Title as="h3">Add District</Modal.Title>
        </Modal.Header>
        <form className="m-t" role="form" onSubmit={e => add(e, closeAdd)}>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'name')}
                  value={inputValues.name}
                  label="Name"
                  required
                  testSelector="districts_add_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'districtDetails')}
                  value={inputValues.districtDetails}
                  label="District details"
                  required
                  testSelector="districts_add_details_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="district-details_add_carrier-ratio_input"
                  isChecked={inputValues.isCarrierRatioEnabled}
                  onChange={e => setInputValue(e, 'isCarrierRatioEnabled')}
                  label="Carrier Ratio"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="district-details_add_manual-dispatch_input"
                  isChecked={inputValues.isManualDispatchEnabled}
                  onChange={e => setInputValue(e, 'isManualDispatchEnabled')}
                  label="Manual Dispatch"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeAdd}
              testSelector="districts_add-modal_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              inverse
              testSelector="districts_add-modal_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};


const mapDispatchToProps = dispatch => ({
  clearModal: () => dispatch(actions.clearModal()),
});

export default connect(
  null,
  mapDispatchToProps,
)(Add);
