import swal from 'bootstrap-sweetalert';
import { users } from 'services/users/service';
import { validatePassOptions } from 'components/globalInfo/validatePasswordOptions';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

const init = (email, key) => ({
  type: 'SET_NEW_PASSWORD_INIT',
  email,
  key,
});
const setInputValue = (value, name) => ({
  type: 'SET_NEW_PASSWORD_SET_INPUT_VALUE',
  value,
  name,
});

const setPass = () => (dispatch, getState) => {
  const state = getState();
  const resetPass = state.resetPass;
  const { confirmPass, pass, key, email } = resetPass;

  if (confirmPass !== pass) {
    swal('Different passwords', '', 'error');
    return false;
  }
  if (!isStrongPassword(pass, [validatePassOptions])) {
    swal({
      title: 'Incorrect new password',
      type: 'error',
      html: true,
      text:
        'Password must be at least 7 characters in length and contain:<br/>- a minimum of 1 lower case letter,<br/>- a minimum of 1 upper case letter,<br/>- a minimum of 1 numeric character,<br/>- a minimum of 1 special character',
    });
    return;
  } else {
    const data = {
      password: pass,
      key,
      email,
    };
    users
      .setNewPass(data)
      .then(msg =>
        swal(
          {
            title: 'Success!',
            text: '',
            type: 'success',
          },
          isConfirm => {
            window.location = '/login';
          },
        ),
      )
      .catch(error => swal(error.response.data.message, '', 'error'));
  }
};
const actions = {
  init,
  setInputValue,
  setPass,
};

export default actions;
