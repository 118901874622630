import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { orderStatus } from 'components/globalInfo/orderStatus';
import Link from 'components/common/Link';
import Authorize, { authorize } from 'components/common/Authorize';
import OrderActions from 'components/views/OrderActions';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Badge from 'components/shared/Badge';

import { useEquipment } from 'api/v2/equipment';
import { useDownloadInvoicePdf } from 'api/v2/orders';
import OrderInfoModalButton from './OrderInfoModal';

const LabelWarning = ({ order }) => {
  const {
    status,
    unload_appt: unloadAppt,
    now_substr_hours: nowSubstrHours,
    driver_id: driverId,
    diffTime,
    eta,
    date_now: dateNow,
    diffTimeWellSite,
    well_arrival_and_substract: wellArrivalAndSubtract,
  } = order;
  switch (status) {
    case 0:
    case 1: {
      if (unloadAppt < nowSubstrHours && Boolean(!driverId)) {
        return (
          <Icon
            data-bs-toggle="tooltip"
            title="not queued order"
            icon="exclamation-triangle text-danger order-warning"
            aria-hidden="true"
          />
        );
      }
      return null;
    }
    case 2: {
      if (eta > unloadAppt || unloadAppt < dateNow) {
        return (
          <Icon
            data-bs-toggle="tooltip"
            title={`load may late for ${diffTime} minutes`}
            icon="exclamation-triangle text-danger order-warning"
            aria-hidden="true"
          />
        );
      }
      return null;
    }
    case 3:
    case 5: {
      if (eta > unloadAppt || unloadAppt < dateNow) {
        return (
          <Icon
            data-bs-toggle="tooltip"
            title={`load may late for ${diffTime} minutes`}
            icon="exclamation-triangle text-danger order-warning"
            aria-hidden="true"
          />
        );
      }
      return null;
    }
    case 6: {
      if (diffTimeWellSite > 0) {
        return (
          <Icon
            data-bs-toggle="tooltip"
            title={`load delayed for ${diffTimeWellSite} minutes`}
            icon="exclamation-triangle text-danger order-warning"
            aria-hidden="true"
          />
        );
      }
      return null;
    }
    case 4: {
      if (unloadAppt < wellArrivalAndSubtract) {
        return (
          <Icon
            data-bs-toggle="tooltip"
            title="order was delivered with delay"
            icon="exclamation-triangle text-muted order-warning"
            aria-hidden="true"
          />
        );
      }
      return null;
    }
    default:
      return null;
  }
};

const OrderHeader = ({ order, refreshOrder }) => {
  const { mutate: downloadInvoicePdf } = useDownloadInvoicePdf();
  const { data: equipmentRequired } = useEquipment();

  const {
    status,
    order_id: orderId,
    stage,
    manual_dispatch_enabled: manualDispatchEnabled,
    isDiverted,
    invoice_id: invoiceId,
    equipment,
    operation_type: operationType,
    po_equipment_id: poEquipmentId,
    truck_status: truckStatus,
    interstateCertRequired,
    isAuto,
  } = order;

  return (
    <div className="order-item__header">
      <div className="order-item__header--order-info">
        <span>
          <LabelWarning order={order} />
          {authorize({
            abilityPermissions: [{ resource: 'Jobs', permissions: ['read'] }],
          }) ? (
            <Link id={orderId} to="order-details">
              Order #{orderId}
            </Link>
          ) : (
            `Order #${orderId}`
          )}
          {operationType !== 12 && <span> - stage {stage}</span>}
        </span>
      </div>

      <div className="order-item__header--order-tags">
        <span
          style={{ color: orderStatus[status].color }}
          className={`button button--label-type button-${
            orderStatus[status].class
          }`}>
          {orderStatus[status].name}
        </span>
        {truckStatus === 1 && (
          <span className="button button--label-type button--red">
            Truck Down
          </span>
        )}
        {Boolean(interstateCertRequired) && (
          <span className="button button--label-type button--purple">
            Interstate Cert Required
          </span>
        )}
        {Boolean(manualDispatchEnabled) && (
          <span className="button button--label-type">Manual</span>
        )}
        {Boolean(isDiverted) && (
          <span className="button button--label-type">Diverted</span>
        )}
        {!!Number(equipment) && (
          <span className="button button--label-type button--yellow">
            {equipmentRequired.map(item => item.id === equipment && item.title)}
          </span>
        )}
        {!Number(equipment) && !!poEquipmentId && (
          <span className="button button--label-type button--yellow">
            {equipmentRequired.map(
              item => item.id === poEquipmentId && item.title,
            )}
          </span>
        )}
        {status === 4 && (
          <Authorize forbidden={['CARRIER']}>
            {Boolean(invoiceId) && (
              <Button
                key="Invoiced"
                theme="small"
                className="button--label-type"
                testSelector="order-list_order_header_invoiced_btn">
                Invoiced
              </Button>
            )}
            <Authorize
              abilityPermissions={[
                { resource: 'Orders', permissions: ['read'] },
              ]}
              forbidden={['JOB_COORDINATOR']}>
              <Dropdown as="span">
                <Dropdown.Toggle
                  as="button"
                  className="button button--label-type">
                  <Icon icon="download" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key="download"
                    onClick={() => downloadInvoicePdf(orderId)}>
                    Download invoice
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Authorize>
            <OrderInfoModalButton order={order} />
          </Authorize>
        )}
        <span>
          <Authorize
            abilityPermissions={[
              { resource: 'Orders', permissions: ['update'] },
            ]}>
            <OrderActions
              order={order}
              exportComponent={{
                modals: true,
                actions: true,
              }}
            />
          </Authorize>
        </span>
        <Authorize
          abilityPermissions={[{ resource: 'Orders', permissions: ['read'] }]}>
          <span
            onClick={() => refreshOrder(orderId)}
            className="button button--label-type">
            <Icon icon="refresh" />
          </span>
        </Authorize>
        {!!isAuto && <Badge type="info">Auto Order</Badge>}
      </div>
    </div>
  );
};
export default OrderHeader;
