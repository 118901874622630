export const orderStatus = [
  { id: 0, name: 'Not Assigned', class: 'info', color: '#23c6c8' },
  { id: 1, name: 'Assigned, not accepted', class: 'info', color: '#23c6c8' },
  { id: 2, name: 'Order Accepted', class: 'warning', color: '#f8ac59' },
  {
    id: 3,
    name: 'En Route to Destination',
    class: 'primary',
    color: '#1ab394',
  },
  { id: 4, name: 'Order Completed', class: 'danger', color: '#ed5565' },
  { id: 5, name: 'Entering Loading Site', class: 'primary', color: '#1ab394' },
  { id: 6, name: 'Entering Destination', class: 'primary', color: '#1ab394' },
  { id: 7, name: 'En route to Staging', class: 'primary', color: '#ffa106' },
  { id: 8, name: 'Entering Staging', class: 'primary', color: '#ffa106' },
];
