import React from 'react';

const Excel = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <path d="M18.266 9.716h-.383V7.864l-.003-.035a.306.306 0 0 0-.075-.202l-3.08-3.52a.333.333 0 0 0-.064-.055l-.022-.012a.28.28 0 0 0-.062-.026l-.017-.005A.29.29 0 0 0 14.488 4H6.916a.627.627 0 0 0-.626.627v5.089h-.384c-.494 0-.896.4-.896.895v4.659c0 .494.402.895.896.895h.384v3.189c0 .344.28.625.626.625h10.34a.626.626 0 0 0 .627-.625v-3.189h.383c.494 0 .896-.4.896-.895v-4.66a.893.893 0 0 0-.896-.894zm-11.35-5.09h7.259v3.207c0 .173.14.313.313.313h2.768v1.57H6.916v-5.09zm6.35 8.544c-.617-.214-1.019-.555-1.019-1.094 0-.632.528-1.116 1.401-1.116.418 0 .727.088.946.187l-.187.676a1.765 1.765 0 0 0-.775-.176c-.363 0-.539.165-.539.358 0 .236.211.34.688.522.654.242.962.582.962 1.104 0 .622-.478 1.149-1.495 1.149-.423 0-.841-.109-1.05-.225l.17-.693c.226.115.571.23.93.23.386 0 .588-.16.588-.4 0-.229-.176-.362-.62-.522zm-1.42.853v.703H9.531V11.02h.842v3.003h1.473zm-5.003.704h-.957l1.072-1.875-1.033-1.831h.962l.324.676c.11.225.193.406.28.615h.011c.089-.236.16-.401.253-.615l.314-.677h.957L7.98 12.83l1.1 1.896h-.968l-.336-.67c-.137-.259-.225-.451-.33-.666h-.01c-.077.215-.17.407-.286.666l-.308.672.001-.001zm10.413 4.457H6.916v-3.02h10.34v3.02zm.006-4.457l-.336-.67c-.137-.26-.225-.451-.33-.666h-.01c-.078.215-.171.406-.287.666l-.307.67h-.957l1.072-1.875-1.034-1.831h.962l.324.676c.11.225.192.406.281.615h.01c.089-.236.16-.401.254-.615l.312-.677h.957l-1.044 1.81 1.101 1.896h-.968z"/>
  </svg>
);

export default Excel;
