import React from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { useDivertOrder } from 'api/v2/orders';
import { useDivertForm, useDivertJobs } from './hooks';

const Divert = ({ openModal, turnModal, order }) => {
  const jobsList = useDivertJobs(order);
  const { mutate: divert, isPending } = useDivertOrder({
    orderId: order.order_id,
  });

  const { data, isValid, handlers } = useDivertForm();

  const handleClose = () => {
    turnModal();
    handlers.clear();
  };

  const handleSubmit = e => {
    e.preventDefault();
    divert(data, {
      onSuccess: handleClose,
    });
  };

  return (
    <Modal className="modal-container" show={openModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title as="h3">Divert</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Job"
                onChange={handlers.valueChange('jobId')}
                options={jobsList}
                value={jobsList.find(item => item.value === data.jobId)}
                required
                testSelector="order-actions_divert_job_select"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={handlers.valueChange('description')}
                value={data.description}
                label="Description"
                required
                testSelector="order-actions_divert_additional-mileage_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={handlers.valueChange('additionalMileage')}
                value={data.additionalMileage}
                label="Additional Mileage"
                required
                testSelector="order-actions_divert_description_input"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="order-actions_divert_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isValid || isPending}
            testSelector="order-actions_divert_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default Divert;
