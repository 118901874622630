import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import actions from 'store/actions/Equipments';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import eventTarget from 'utils/dispatchWithFieldValue';

const EditContainer = ({
  showEditContainer,
  closeEditContainer,
  customers,
  vendors,
  setEditCustomer,
  setEditEquipmentNumber,
  setEditContainerVendor,
  editContainerData,
  editContainer,
}) => {
  const { customerId, containerNumber, containerVendorId } = editContainerData;

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showEditContainer}
      onHide={closeEditContainer}>
      <form onSubmit={editContainer} className="m-t" role="form">
        <Modal.Header>
          <h4 className="modal-title">Edit Container</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                label="Select Customer"
                options={customers.map(customer => ({
                  value: customer.id,
                  label: customer.name,
                }))}
                onChange={setEditCustomer}
                value={customerId}
                required
                testSelector="equipment-trailers_edit-container_customer_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                label="Equipment number"
                onChange={setEditEquipmentNumber}
                value={containerNumber}
                required
                testSelector="equipment-trailers_edit-container_number_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Vendor"
                options={vendors.map(vendor => ({
                  value: vendor.id,
                  label: vendor.name,
                }))}
                onChange={setEditContainerVendor}
                value={containerVendorId}
                required
                testSelector="equipment-trailers_edit-container_vendor_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6" />
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeEditContainer}
            testSelector="equipments_edit-container_close_btn"
            colour="white">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!containerNumber.trim().length}
            testSelector="equipments_edit-container_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  customers: state.resourceReducer.customers.customers,
  vendors: state.resourceReducer.containerVendors.vendors,
  editContainerData: state.equipmentTrailers.editContainer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setEditCustomer: customer => {
    dispatch(actions.setEditCustomer(customer));
  },
  setEditEquipmentNumber: eventTarget(actions.setEditEquipmentNumber, dispatch),
  setEditContainerVendor: containerVendor => {
    dispatch(actions.setEditContainerVendor(containerVendor));
  },
  editContainer: e => {
    e.preventDefault();
    dispatch(actions.editContainer(ownProps.closeEditContainer));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditContainer);
