import axios from 'axios';
import FormData from 'form-data';

export const carrier = {
  getDistrictAccordingToCarrier(carrierId) {
    return axios.post('v1/getDistrictAccordingToCarrier', {
      carrierId,
    });
  },
  getCarriers() {
    return axios.get('v2/carrier', {});
  },
  addDistrictCarrier(carrierId, districtId) {
    return axios.post('v2/carrier/district', {
      carrierId,
      districtId,
    });
  },
  addManyDistricts(data) {
    return axios.post(`v2/carrier/${data.carrierId}/districts`, data);
  },
  deleteDistrict(data) {
    return axios.delete(
      `v2/carrier/${data.carrierId}/districts/${data.districtId}`,
      {
        deleteDriverDistricts: data.deleteDriverDistricts
          ? data.deleteDriverDistricts
          : 0,
      },
    );
  },
  deleteDistrictCarrier(carrierId, district) {
    return axios.delete('v2/carrier/district', {
      data: { carrierId, district },
    });
  },
  deleteCarrier(carrierId) {
    // TODO: Move this to path param instead of body
    return axios.delete('v2/carrier', {
      data: { carrierId },
    });
  },
  unDeleteCarrier(carrierId) {
    return axios.delete(`v2/carrier/${carrierId}/enable`);
  },

  addCarrierRatioToCarriersDistricts(params) {
    const { carrierId } = params;
    return axios.post(
      `v2/carrier/${carrierId}/districts/carrier-ratio`,
      params,
    );
  },

  getCarriersDocs(carrierId) {
    return axios.get(`v2/job-manager/carrier/${carrierId}/docs`);
  },

  syncBc(id, sync) {
    return axios.post(`v2/carrier/${id}/bc-sync`, { bcSync: sync });
  },

  deleteCarrierDoc(docId) {
    return axios.delete(`v2/job-manager/carrier-doc/${docId}`, {
      data: { docId },
    });
  },
  verifyCarrierDoc(docId) {
    return axios.post(`v2/job-manager/carrier-doc/${docId}/verify`, {
      docId,
    });
  },
  addCarrier(state) {
    const data = {
      name: state.name,
      email: state.email,
      contactNo: state.phone,
      fleetSize: Number(state.fleetSize),
      districts: state.selectedDistricts,
      usDotNo: state.numberUS,
      mcNo: state.numberMC,
      einNo: state.numberEIN,
      streetAddress: state.address,
      city: state.city,
      state: state.state,
      zip: state.zip,
      enableRemit: Boolean(state.remit),
      companyTypeId: state.companyType,
      interstateCertified: Number(state.interstateCertified),
      isNonPurchasable: Number(state.isNonPurchasable),
      bcSync: Boolean(state.bcSync),
    };
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      data.accessToken = accessToken;
    }

    if (state.factorViewId && state.factorViewId !== '') {
      data.factorViewId = Number(state.factorViewId);
    }

    if (state.logo) {
      data.profilePhotoUrl = state.logo;
    }

    if (state.qbCarrierId) {
      data.qbCarrierId = Number(state.qbCarrierId);
    }

    return axios.post('v2/carrier', data);
  },
  // Deprecated
  createCarrier(state) {
    const data = new FormData();
    data.append('accessToken', localStorage.getItem('accessToken'));
    data.append('name', state.name);
    data.append('email', state.email);
    data.append('contactNo', state.phone);
    data.append('fleetSize', state.fleetSize);
    data.append('district', state.selectedDistricts);
    data.append('usDotNo', state.numberUS);
    data.append('mcNo', state.numberMC);
    data.append('einNo', state.numberEIN);
    data.append('address', state.address);
    data.append('percentage', state.percentage);
    data.append('city', state.city);
    data.append('zip', state.zip);
    data.append('state', state.state);
    data.append('enableRemit', state.remit);
    data.append('logo', state.file);
    data.append('companyType', state.companyType);

    return axios.post('v2/carrier', data, {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    });
  },
  getCarrierDetails(id) {
    return axios.get(`v2/carrier/${id}/details`, {});
  },
  getCarrierDriverList(carrierId) {
    return axios.post('v2/lookUp/driver/search', { carrierId });
  },
  member: {
    get(carrierId) {
      return axios.post(`v2/carrier/${carrierId}/getMembers`, {});
    },
    add(data) {
      return axios.post(`v2/createCarrierMember`, data);
    },
    remove(data) {
      return axios.post(
        `v2/carrier/${data.carrierId}/members/${data.id}/delete`,
        {},
      );
    },
  },
  editBillingAddress(data) {
    const { carrierId, address } = data;
    return axios.put(`v2/carrier/${carrierId}/address`, address);
  },
  editSettings({ carrierId, input }) {
    return axios.put(`v2/carrier/${carrierId}/settings`, input);
  },
  toggleEnableRemit(data) {
    return axios.put(`v2/carrier/${data.carrierId}/toggle-enable-remit`, data);
  },
  editCarrier(data) {
    const { carrierId, info } = data;
    return axios.put(`v2/carrier/${carrierId}/edit`, info);
  },
  editCarrierFinancials(data) {
    const { carrierId, info } = data;
    return axios.put(`v2/carrier/${carrierId}/finances`, info);
  },
  editLogo(formData) {
    return axios.put(`v2/carrier/${formData.get('carrierId')}/logo`, formData, {
      headers: {
        dataType: 'json',
        processData: false,
        contentType: false,
      },
    });
  },
  remit: {
    edit(data) {
      return axios.put(`v2/carrier/${data.carrierId}/remit`, data);
    },
  },
  geotab: {
    get(id) {
      return axios.get(`v2/external/geotab/settings/carrier/${id}`);
    },
    post(data) {
      return axios.post(`v2/external/geotab/settings/carrier/${data.id}`, data);
    },
  },
  document: {
    upload(params) {
      const data = new FormData();
      data.append('docType', params.docType);
      data.append('file', params.file);
      data.append('description', params.description);
      data.append('carrierId', params.carrierId);
      data.append('customerId', params.customerId);
      return axios.post(`v2/carrier/${params.carrierId}/files/approve`, data, {
        headers: {
          dataType: 'json',
          processData: false,
          contentType: false,
        },
      });
    },
    insurance(data) {
      const formData = new FormData();
      formData.append('docType', data.docType);
      formData.append('file', data.file);
      formData.append('description', data.description);
      formData.append('effectiveDate', data.effectiveDate);
      formData.append('expirationDate', data.expirationDate);
      formData.append('comertialGeneralyLiabilityLimits', data.CGLL);
      formData.append(
        'comertialGeneralyLiabilityLimitsEffectiveDate',
        data.CGLLEffD,
      );
      formData.append(
        'comertialGeneralyLiabilityLimitsExpirationDate',
        data.CGLExpD,
      );
      formData.append('automatizeLiabilityLimits', data.ALL);
      formData.append('workersCompensationLimits', data.WCL);
      formData.append('workersCompensationLimitsEffectiveDate', data.WCLEffD);
      formData.append('workersCompensationLimitsExpirationDate', data.WCLExpD);
      formData.append('cargoLimits', data.cargoLimits);
      return axios.post(
        `v2/carrier/${data.carrierId}/insurance/approve`,
        formData,
        {
          headers: {
            dataType: 'json',
            processData: false,
            contentType: false,
          },
        },
      );
    },
  },
  getTruckAnalytics(data) {
    return axios.get(`v2/carrier/${data.carrierId}/trucks/analytics`, data);
  },
  getCarriersJobs(id) {
    return axios.get(`v2/carrier/${id}/permissions/jobs`, {});
  },
  notification(data) {
    return axios.post('v2/carrier/send-notification', data);
  },
  scada: {
    get(id) {
      return axios.get(`v2/carrier/${id}/scada-report`);
    },
  },
};
