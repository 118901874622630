import React, { useState } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';

const PasswordInput = ({
  className,
  disabled,
  id,
  label,
  name,
  noMargin,
  onChange,
  required,
  testSelector,
  value,
}) => {
  const inputId = id ? id.replace(/\s+/g, '') : `${uuid.v4()}-password`;
  const [inputType, changeType] = useState('password');
  const [visibilityClass, changeVisibilityClass] = useState('fa-eye-slash');

  const toggleVisibility = () => {
    if (inputType === 'password') {
      changeType('text');
      changeVisibilityClass('fa-eye');
    } else {
      changeType('password');
      changeVisibilityClass('fa-eye-slash');
    }
  };

  return (
    <InputWrapper
      inputType={inputType}
      id={testSelector}
      className={className}
      noMargin={noMargin}
      disabled={disabled}>
      <input
        type={inputType}
        className="input__text__input"
        disabled={disabled}
        name={testSelector}
        id={testSelector}
        placeholder=" "
        value={value}
        onChange={onChange}
        data-testid={testSelector}
      />
      <span className="input__text__label">
        {label}
        {required && <span className="input__text__label--required"> *</span>}
      </span>
      <span
        onClick={() => toggleVisibility()}
        toggle="#password-field"
        className={`fa fa-lg ${visibilityClass} input-password__eye`}
      />
    </InputWrapper>
  );
};

PasswordInput.propTypes = {
  /** Custom Class Name */
  className: PropTypes.string,
  /** Disabled boolean */
  disabled: PropTypes.bool,
  /** Unique id */
  id: PropTypes.string,
  /** Label for input */
  label: PropTypes.string,
  /** Unique name for input */
  name: PropTypes.string,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Function to call when changing the input */
  onChange: PropTypes.func.isRequired,
  /** Boolean to make input required and add * to label */
  required: PropTypes.bool,
  /** HTML data attribute for test selecting element  */
  testSelector: PropTypes.string,
  /** Value of input */
  value: PropTypes.string,
};

PasswordInput.defaultProps = {
  className: undefined,
  disabled: false,
  id: undefined,
  label: 'Password',
  name: undefined,
  noMargin: false,
  required: false,
  testSelector: undefined,
  value: undefined,
};

export default PasswordInput;
