import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

const ForgotPass = ({
  setLogin,
  forgetPassword,
  showModal,
  closeModal,
}) => {
  const [submitDisabled, toggleSubmitDisabled] = useState(true);

  return (
    <div className="inmodal">
      <Modal
        data-test="forgot_password_modal"
        style={{ top: `${20}%` }}
        className="modal-container"
        show={showModal}
        onHide={closeModal}>
        <form onSubmit={e => forgetPassword(e, closeModal)}>
          <Modal.Header>
            <h4 className="modal-title font-noraml">Forgot Password?</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Request a new password by submitting your email address:</p>
            <Input
              testSelector="forgot_password_input_email"
              onChange={(e) => {
                setLogin(e);
                if (e.target.value) {
                  toggleSubmitDisabled(false)
                } else {
                  toggleSubmitDisabled(true)
                }
              }}
              type="email"
              label="Email Address"
              required
              testSelector="login_forgot-psw_email_input"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              testSelector="login_forgot-psw_close_btn"
              colour="white"
              children="Close"
              onClick={closeModal}
            />
            <Button
              testSelector="login_forgot-psw_save_btn"
              type="submit"
              children="Request"
              disabled={submitDisabled}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ForgotPass;
