import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import { useAddStorageType, useEditStorageType } from 'api/v2/storage-types';
import Select from 'components/shared/Select';
import { useSourceOptions, useStorageTypeForm } from './hooks';

const AddStorageTypeModal = ({
  showModal,
  closeModal,
  action = 'add',
  storageType,
}) => {
  const { data: formData, handlers, isValid } = useStorageTypeForm({
    showModal,
    defaultValue: storageType,
  });
  const { mutate: addStorageType, isPending: addPending } = useAddStorageType();
  const {
    mutate: editStorageType,
    isPending: editPending,
  } = useEditStorageType({ storageTypeId: storageType?.id });

  const sourceOptions = useSourceOptions();

  const handleClose = () => {
    closeModal();
    handlers.clear();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (action === 'edit') {
      editStorageType(formData, {
        onSuccess: handleClose,
      });
    } else {
      addStorageType(formData, {
        onSuccess: handleClose,
      });
    }
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModal} onHide={handleClose}>
        <form className="m-t" role="form" onSubmit={handleSubmit}>
          <Modal.Header>
            <h3>{action === 'add' ? 'Add' : 'Edit'} Storage Type</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={handlers.valueChange('name')}
                  value={formData.name}
                  label="Name"
                  required
                  testSelector="manage-database_storage-types_add-edit_name_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={handlers.valueChange('description')}
                  value={formData.description}
                  label="Description"
                  required
                  testSelector="manage-database_storage-types_add-edit_description_input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Select
                  onChange={({ value }) =>
                    handlers.valueChange('sourceId')(value)
                  }
                  value={
                    formData.sourceId
                      ? sourceOptions.find(o => o.value === formData.sourceId)
                      : { label: 'Manual', value: undefined }
                  }
                  options={sourceOptions}
                  label="Source"
                  required
                  testSelector="manage-database_storage-types_add-edit_source_input"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="database_storage-types_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={!isValid || addPending || editPending}
              testSelector="database_storage-types_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddStorageTypeModal;
