import axios from 'axios';

export const tags = {
  get() {
    return axios.get('v2/tags', {});
  },
  getByType(type) {
    return axios.get('v2/tags', { params: { type } });
  },
  edit(data) {
    return axios.patch(`v2/tags/${data.id}`, data);
  },
  add(data) {
    return axios.post('v2/tags', data);
  },
  delete(data) {
    return axios.patch(`v2/tags/${data.id}/delete`, data);
  },
};
