import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/shared/Loader';

import {
  creditMemoPreviewRequested,
} from 'store/actions/CreditMemos';

const CreditMemoPreview = ({
  currentCreditMemo,
  currentCreditMemoId,
  getCreditMemoPreview,
  open,
}) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (open && !file) {
      getCreditMemoPreview(
        currentCreditMemoId,
        currentCreditMemo.carrierId || null,
      ).then(data => setFile(data));
    }
  }, [open]);

  return (
    open && (
      <div className="breakdown-preview">
        {!file ? (
          <Loader />
        ) : (
          <object
            data={file}
            type="application/pdf"
            className="breakdown-preview--container"
          />
        )}
      </div>
    )
  );
};

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  getCreditMemoPreview: (id, carrierId) => dispatch(creditMemoPreviewRequested(id, carrierId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemoPreview);
