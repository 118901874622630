import { wellSite } from 'services/wellSite/service';
import { sandType } from 'services/sandType/service';
import { tags } from 'services/tags/service';
import { jobManager } from 'services/jobManager/service';
import { equipment } from 'services/equipment/service';
import profile from 'services/profile/service';
import { vendor } from 'services/vendor/service';
import swal from 'bootstrap-sweetalert';
import { sandSite } from '../../services/sandSite/service';

const init = () => {
  const success = (data, array) => ({
    type: 'LOOKUP_RESPONSE_SUCCESS',
    data,
    array,
  });
  const failure = error => ({ type: 'LOOKUP_RESPONSE_FAILURE', error });
  return dispatch => {
    sandSite
      .get()
      .then(response => {
        dispatch(success(response.data.data.locations, 'sandSites'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    wellSite
      .get()
      .then(response => {
        dispatch(success(response.data.data, 'wellSites'));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.error || 'Something went wrong.', '', 'error');
      });
    sandType
      .get()
      .then(response => {
        dispatch(success(response.data.data.commodities, 'sandTypes'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    tags
      .get()
      .then(res => {
        dispatch(success(res.data.data, 'tags'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    profile
      .get()
      .then(response => {
        dispatch(success(response.data.data, 'user'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    vendor
      .get()
      .then(response => {
        dispatch(success(response.data.data, 'vendors'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    equipment
      .get()
      .then(response => {
        dispatch(success(response.data.data.equipments, 'equipmentRequired'));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
    jobManager
      .getSettings()
      .then(response => {
        dispatch(
          success(
            response.data.data.find(item => item.type === 9),
            'sessionLifetime',
          ),
        );
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.data.message) {
          swal(error.response.data.message, '', 'error');
        } else {
          swal('Something went wrong, please try again', '', 'error');
        }
      });
  };
};

const edit = (newData, id, arrayName) => ({
  type: 'LOOKUP_EDIT',
  newData,
  id,
  arrayName,
});
const editUser = newData => ({ type: 'LOOKUP_EDIT_USER', newData });
const add = (newData, arrayName) => ({
  type: 'LOOKUP_ADD',
  newData,
  arrayName,
});
const deleteL = (id, arrayName) => ({
  type: 'LOOKUP_DELETE',
  id,
  arrayName,
});
const refresh = (newArray, arrayName) => ({
  type: 'LOOKUP_REFRESH_DRIVERS_SUCCESS',
  newArray,
  arrayName,
});

export const lookUpActions = {
  init,
  edit,
  add,
  deleteL,
  editUser,
  refresh,
};
