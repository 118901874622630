import React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';

const Tabs = ({ events, changeActiveTab }) => {
  const { activeTab } = events;

  return (
    <ul className="tabs">
      <li
        className={`tabs__tab ${
          activeTab === 'completed' ? 'tabs__tab--active' : ''
        }`}
        onClick={() => {
          changeActiveTab('completed');
        }}>
        <span className="tabs__tab--title">Completed</span>
      </li>
      <li
        className={`tabs__tab ${
          activeTab === 'tracking' ? 'tabs__tab--active' : ''
        }`}
        onClick={() => {
          changeActiveTab('tracking');
        }}>
        <span className="tabs__tab--title">Tracking</span>
      </li>
      <li
        className={`tabs__tab ${
          activeTab === 'alerts' ? 'tabs__tab--active' : ''
        }`}
        onClick={() => {
          changeActiveTab('alerts');
        }}>
        <span className="tabs__tab--title">Alerts</span>
      </li>
      {/* <li
        className={`tabs__tab ${
          activeTab === 'chat' ? 'tabs__tab--active' : ''
        }`}
        onClick={() => {
          changeActiveTab('chat');
        }}>
        <span className="tabs__tab--title">Chat</span>
      </li> */}
    </ul>
  );
};

const mapStateToProps = state => ({
  events: state.events,
});

const mapDispatchToProps = dispatch => ({
  changeActiveTab: tab => dispatch(actions.changeActiveTab(tab)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tabs);
