import React from 'react';
import moment from 'moment';

import { calculateTime } from 'utils/calculateTime';
import { driverLogsEvents } from 'components/globalInfo/driverLogsEvents';

const Logs = ({ logs }) => {
  return (
    <div className="row block-list-orders">
      {logs.length > 0 ? (
        <table id="datatable_logs" className="table table--hover">
          <thead>
            <tr>
              <th>Order id</th>
              <th>Job</th>
              <th>Truck</th>
              <th>Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, i) => (
              <tr key={i}>
                <td>{log.orderId}</td>
                <td>{log.jobName}</td>
                <td>{log.truck}</td>
                <td>
                  <span>{moment(log.date).format('YYYY-MM-DD hh:mm A')}</span> (
                  {calculateTime(log.date)} ago)
                </td>
                {driverLogsEvents
                  .filter(i => i.id === log.eventType)
                  .map((item, index) => (
                    <td key={index}>
                      <span className={`label ${item.color}`}>
                        {item.title}
                      </span>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4>This driver does not have logs</h4>
      )}
    </div>
  );
};

export default Logs;
