import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const Collapse = ({ children, in: inProp }) => (
  <CSSTransition
    enter={false}
    mountOnEnter
    unmountOnExit
    in={inProp}
    timeout={250}
    classNames="input__select__multi-value--animation">
    {children}
  </CSSTransition>
);

Collapse.propTypes = {
  /** Children is supplied by react-select */
  children: PropTypes.element.isRequired,
  /** in is supplied by TransitionGroup */
  in: PropTypes.bool.isRequired,
};

export default Collapse;
