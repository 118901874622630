import swal from 'bootstrap-sweetalert';
import { jobPlans } from 'services/jobPlans/service';
import FileSaver from 'file-saver';
import { order } from 'services/order/service';
import moment from 'moment';
import { convertDataForOld, orderMap } from 'components/views/JobPlans/helpers';
import _ from 'lodash';

const initJobPlans = () => async (dispatch, getState) => {
  try {
    const success = data => ({
      type: 'JOB_PLANS_RESPONSE_SUCCESS',
      data,
    });
    const allJobPlansData = await jobPlans.get();
    dispatch(success(allJobPlansData.data.data.jobPlans));
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Job Plans',
        '',
        'error',
      );
  }
};

const getJobPlanData = (data, isTurnTime, resetTimeFrame) => async (
  dispatch,
  getState,
) => {
  const { id } = data;
  const state = getState();
  const {
    jobPlans: {
      jobPlanTurnTimeDetails: { posFilter, carrierFilter, driverFilter },
    },
  } = state;

  const newData = { id };
  if (isTurnTime && posFilter && posFilter.length) {
    newData.pos = posFilter.map(v => v.label).join(',');
  }
  if (isTurnTime && carrierFilter && carrierFilter.length) {
    newData.carriers = carrierFilter.map(v => v.value).join(',');
  }
  if (isTurnTime && driverFilter && driverFilter.length) {
    newData.drivers = driverFilter.map(v => v.value).join(',');
  }
  try {
    const success = data => ({
      type: 'JOB_PLANS_SET_DETAILS_VALUE',
      data,
    });
    const successTurn = data => ({
      type: 'JOB_PLANS_TURN_ADD_DATA',
      data,
    });
    const jobPlanData = await jobPlans.getPlan(newData);

    if (isTurnTime) {
      dispatch(successTurn(jobPlanData.data.data));
    } else {
      dispatch(success(jobPlanData.data.data));
    }
    const allBillableTimeBands =
      jobPlanData?.data?.data?.allBillableTimeBands || [];
    if (resetTimeFrame) {
      dispatch(success(jobPlanData.data.data));
      dispatch(
        getBillableOrders({
          id,
          start: allBillableTimeBands[allBillableTimeBands.length - 1].start,
          end: allBillableTimeBands[allBillableTimeBands.length - 1].end,
        }),
      );
      if (allBillableTimeBands && allBillableTimeBands.length > 0) {
        dispatch(setTimeFrameFilter(allBillableTimeBands.length - 1));
      }
    }
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Job Plan Data',
        '',
        'error',
      );
  }
};

const downloadPDF = jobPlanId => async () => {
  try {
    const jobPlanData = await jobPlans.getReport({
      id: jobPlanId,
      responseType: 'arraybuffer',
    });
    const file = new Blob([jobPlanData.data], { type: 'application/pdf' });
    FileSaver.saveAs(file, `jobPlanId-${jobPlanId}.pdf`);
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Job Plan Report',
        '',
        'error',
      );
  }
};

const clearJobPlanData = () => ({ type: 'JOB_PLAN_CLEAR_DATA' });

const clearTurnData = () => ({ type: 'JOB_PLAN_TURN_CLEAR_DATA' });
const clearTurnDataNotFilters = () => ({
  type: 'JOB_PLAN_TURN_CLEAR_DATA_NOT_FILTERS',
});

const getJobPlanConfigData = idData => async (dispatch, getState) => {
  try {
    const success = data => ({
      type: 'JOB_PLANS_UPDATE_SET_DETAILS_VALUE',
      data,
    });
    const jobPlanData = await jobPlans.getPlan({ id: idData.id });
    const editJobPlanData = jobPlanData.data.data;
    if (editJobPlanData?.billingStartDate) {
      editJobPlanData.billingStartDate = moment
        .utc(editJobPlanData.billingStartDate)
        .format('YYYY-MM-DD');
    }
    if (editJobPlanData?.billingFrequency) {
      editJobPlanData.billingFrequencyDays = editJobPlanData?.billingFrequency;
    }
    dispatch(success(editJobPlanData));
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Job Plan Data',
        '',
        'error',
      );
  }
};

const clearJobPlanUpdateData = () => ({ type: 'JOB_PLAN_UPDATE_CLEAR_DATA' });

const setUpdateInputValue = (value, name) => ({
  type: 'JOB_PLANS_UPDATE_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const setInputValue = (value, name) => ({
  type: 'JOB_PLANS_SET_MODAL_UPDATE_VALUE',
  value,
  name,
});

const clearAddModal = () => ({ type: 'JOB_PLANS_CLEAR_MODAL' });

const addOnSubmit = events => async (dispatch, getState) => {
  const state = getState();
  const {
    jobPlans: {
      jobPlanInfo: {
        jobId,
        duration,
        tonsPerLoad,
        hoursPerShift,
        loadHistory,
        loadRefresh,
        billingFrequencyDays,
        billingStartDate,
        billingTimeZone,
      },
    },
  } = state;

  const data = {
    jobId,
    duration,
    tonsPerLoad,
    hoursPerShift,
    loadHistory,
    loadRefresh,
    turns: events,
    billingFrequency: billingFrequencyDays,
    billingStartDate: new Date(billingStartDate).toISOString(),
    billingTimeZone,
  };
  try {
    const jobplanData = await jobPlans.add(data);
    swal('Success Job Plan created!', '', 'success');
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error creating Job Plan',
        '',
        'error',
      );
  }
  dispatch(initJobPlans());
};

const editOnSubmit = (events, id) => async (dispatch, getState) => {
  const state = getState();

  const {
    jobPlans: {
      jobPlanUpdateInfo: {
        jobId,
        duration,
        tonsPerLoad,
        hoursPerShift,
        loadHistory,
        loadRefresh,
        targetStagesPerDay,
        tonsPerStage,
        tonsPerDay,
        billingFrequencyDays,
        billingStartDate,
        billingTimeZone,
      },
    },
  } = state;

  const data = {
    id,
    jobId,
    duration,
    tonsPerLoad,
    hoursPerShift,
    loadHistory,
    loadRefresh,
    targetStagesPerDay,
    tonsPerStage,
    tonsPerDay,
    turns: events,
    billingFrequency: billingFrequencyDays,
    billingStartDate: new Date(billingStartDate).toISOString(),
    billingTimeZone,
  };

  try {
    const jobplanData = await jobPlans.edit(data);
    dispatch(getJobPlanData({ id }, true, true));
    swal('Success Job Plan edited!', '', 'success');
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error creating Job Plan',
        '',
        'error',
      );
  }
};

const setFilterValue = (value, name) => ({
  type: 'JOB_PLAN_TURN_SET_VALUE',
  value,
  name,
});

const setTimeFrameFilter = value => ({
  type: 'JOB_PLAN_SET_TIMEFRAME',
  value,
});

const getBillableOrders = paramsData => async (dispatch, getState) => {
  const isStartCall = data => ({
    type: 'JOB_PLANS_BILLABLE_ORDERS_CALL',
    data,
  });
  try {
    dispatch(isStartCall(true));

    const success = data => ({
      type: 'JOB_PLANS_BILLABLE_ORDERS_SET',
      data,
    });

    const jobPlanData = await jobPlans.getBillableOrders(paramsData);
    const tmpBillableOrders = jobPlanData.data.data?.billableOrders
      ? _.cloneDeep(jobPlanData.data.data?.billableOrders)
      : [];
    tmpBillableOrders.sort(
      (a, b) => orderMap[a.billableStatus] - orderMap[b.billableStatus],
    );
    dispatch(success(tmpBillableOrders || []));
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Billable Orders Data',
        '',
        'error',
      );
  } finally {
    dispatch(isStartCall(false));
  }
};

const getBillableOrdersDetailsTickets = (orderId, paramsData) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    jobPlans: {
      jobPlanInfo: { billableOrders },
    },
  } = state;

  const isStartCall = data => ({
    type: 'JOB_PLANS_ORDERS_CALL',
    data,
  });

  try {
    dispatch(isStartCall(true));

    const success = data => ({
      type: 'JOB_PLANS_BILLABLE_ORDERS_SET',
      data,
    });
    const billableOrdersData = await jobPlans.getOrders({
      orderIds: [orderId],
    });
    const orderData = billableOrdersData.data.data?.orders.find(
      e => e.orderId === orderId,
    );

    const ticketsForOrder = await order.getTickets(orderId);

    if (orderData) {
      orderData.tickets = ticketsForOrder.data.data.tickets;
      orderData.config = ticketsForOrder.data.data.config;
    }

    const tmpBillableOrders = _.cloneDeep(billableOrders);
    const index = tmpBillableOrders.findIndex(
      order => order.orderId === orderId,
    );

    if (tmpBillableOrders.some(order => order.orderId === orderId)) {
      if (index !== -1) {
        tmpBillableOrders[index] = convertDataForOld({
          ...tmpBillableOrders[index],
          ...orderData,
          tickets: ticketsForOrder.data.data.tickets,
          config: ticketsForOrder.data.data.config,
        });
      }
      await dispatch(success(tmpBillableOrders || []));
      await dispatch({
        tickets: ticketsForOrder.data.data.tickets,
        config: ticketsForOrder.data.data.config,
        type: 'TICKETS_REQUEST_SUCCEEDED',
      });
      dispatch(isStartCall(false));
    } else {
      tmpBillableOrders[index].tickets = [];
      tmpBillableOrders[index].config = [];
      await dispatch(success(tmpBillableOrders || []));
      dispatch(isStartCall(false));
    }
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Billable Orders Data',
        '',
        'error',
      );
    dispatch(isStartCall(false));
  }
};

const getOrderTickets = orderId => async (dispatch, getState) => {
  const state = getState();

  const {
    jobPlans: {
      jobPlanInfo: { billableOrders },
    },
  } = state;

  try {
    const success = data => ({
      type: 'JOB_PLANS_BILLABLE_ORDERS_SET',
      data,
    });

    const ticketsForOrder = await order.getTickets(orderId);

    const tmpBillableOrders = _.cloneDeep(billableOrders);
    const index = tmpBillableOrders.findIndex(
      order => order.orderId === orderId,
    );

    if (
      ticketsForOrder.data.data &&
      tmpBillableOrders.some(order => order.orderId === orderId)
    ) {
      if (index !== -1) {
        tmpBillableOrders[index].tickets = ticketsForOrder.data.data.tickets;
        tmpBillableOrders[index].config = ticketsForOrder.data.data.config;
      }
      dispatch(success(tmpBillableOrders || []));
      dispatch({
        tickets: ticketsForOrder.data.data.tickets,
        config: ticketsForOrder.data.data.config,
        type: 'TICKETS_REQUEST_SUCCEEDED',
      });
    } else {
      tmpBillableOrders[index].tickets = [];
      tmpBillableOrders[index].config = [];
      dispatch(success(tmpBillableOrders || []));
    }
  } catch (error) {
    error =>
      swal(
        error.response.data.message ||
        `Error getting Tickets for Order ${orderId}`,
        '',
        'error',
      );
  }
};

const changeJobplanVisibility = (jobPlanId, vis) => async (
  dispatch,
  getState,
) => {
  try {
    const visReturnData = await jobPlans.editVisiblity({
      id: jobPlanId,
      status: vis,
    });
  } catch (error) {
    error =>
      swal(
        error.response.data.message || 'Error getting Billable Orders Data',
        '',
        'error',
      );
  }
  dispatch(initJobPlans());
};

const setHidden = value => ({
  type: 'JOB_PLAN_SET_HIDDEN',
  value,
});

export default {
  initJobPlans,
  getJobPlanData,
  clearJobPlanData,
  setInputValue,
  clearAddModal,
  addOnSubmit,
  getJobPlanConfigData,
  getBillableOrders,
  getBillableOrdersDetailsTickets,
  clearJobPlanUpdateData,
  setUpdateInputValue,
  editOnSubmit,
  downloadPDF,
  setFilterValue,
  clearTurnData,
  clearTurnDataNotFilters,
  getOrderTickets,
  setTimeFrameFilter,
  setHidden,
  changeJobplanVisibility,
};
