import React, { useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

import { filterOptions } from 'utils/selectUtils';
import { useDemurrageReasons, useSaveDemmurageReason } from 'api/v2/orders';
import { reasonsFormatted } from './helpers';
import { useDemurrageReasonForm } from './hooks';

const DemurrageReason = ({ orderId, openModal, turnModal, demurrageData }) => {
  const { mutate: saveReason, isPending } = useSaveDemmurageReason({ orderId });
  const { data: reasons } = useDemurrageReasons();
  const reasonsList = useMemo(() => {
    if (!reasons.length) {
      return [];
    }
    return filterOptions(reasonsFormatted(reasons), null, null, null, 'label');
  }, [reasons]);

  const {
    sandSiteDemurrageReasonId,
    sandDemurrage,
    wellDemurrage,
    wellSiteDemurrageReasonId,
  } = demurrageData || {};

  const { data: formData, isValid, handlers } = useDemurrageReasonForm({
    demurrageData,
  });

  const handleClose = () => {
    handlers.clear();
    turnModal();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const paramData = {
      orderId,
      sandsiteDemurrageReasonId:
        formData.sandSiteReason || sandSiteDemurrageReasonId || '0',
      wellsiteDemurrageReasonId:
        formData.wellsiteReason || wellSiteDemurrageReasonId || '0',
    };

    saveReason(paramData, {
      onSuccess: handleClose,
    });
  };

  return (
    <Modal className="modal-container" show={openModal} onHide={handleSubmit}>
      <Modal.Header>
        <h3 className="modal-title">Reason for demurrage</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {demurrageData ? (
            <Row>
              {sandDemurrage.price !== 0 && !sandSiteDemurrageReasonId && (
                <div className="form-group has-feedback col-md-6">
                  <label className="small font-noraml">Origin reason</label>
                  <Select
                    placeholder="Please Select Reason"
                    options={reasonsList}
                    onChange={handlers.valueChange('sandSiteReason')}
                    value={reasonsList.find(
                      item => item.value === formData.sandSiteReason,
                    )}
                    required
                    isOptionDisabled={option => option.disabled}
                    testSelector="order-actions_modify_demurr-reason_sandsite-reason_select"
                  />
                </div>
              )}
              {wellDemurrage.price !== 0 && !wellSiteDemurrageReasonId && (
                <div className="form-group has-feedback col-md-6">
                  <label className="small font-noraml">
                    Destination reason
                  </label>
                  <Select
                    placeholder="Please Select Reason"
                    options={reasonsList}
                    onChange={handlers.valueChange('wellsiteReason')}
                    value={reasonsList.find(
                      item => item.value === formData.wellsiteReason,
                    )}
                    required
                    isOptionDisabled={option => option.disabled}
                    testSelector="order-actions_modify_demurr-reason_wellsite-reason_select"
                  />
                </div>
              )}
            </Row>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            colour="white"
            onClick={handleClose}
            testSelector="order-actions_modify_demurrage-reason_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isValid || isPending}
            testSelector="order-actions_modify_demurrage-reason_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DemurrageReason;
