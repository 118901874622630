export const orderBillingStatus = Object.freeze({
  0: 'New',
  1: 'Contested',
  10: 'Aprroved by Job Manager',
  11: 'Approved By Carrier',
  20: 'Ready to Bill',
  21: 'Billed',
  22: 'Paid',
  23: 'Settled',
  24: 'Non Billable',
});

export const orderNonBillingCode = Object.freeze({
  1: "Reverted Non Billable, Order is Billable",
  2: "Lost Load During an Accident",
  3: "Loaded without an order and the well couldn't use the sand",
  4: "Sand contaminated on the order and the well refused",
  5: "Age of Order past billing window",
});

export const revertNonBillableOptions = [
  { value: 0, label: `Reverted Non Billable, Order is Billable` },
];

export const nonBillableOptions = [
  { value: 2, label: `Lost Load During an Accident` },
  { value: 3, label: `Loaded without an order and the well couldn't use the sand` },
  { value: 4, label: `Sand contaminated on the order and the well refused` },
  { value: 5, label: `Age of Order past billing window` },
];
