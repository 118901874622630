import React, { useState } from 'react';

import {
  useDeleteUnitOfMeasure,
  useUnitsOfMeasure,
} from 'api/v2/units-of-measure';

import { deleteAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import { Row } from 'react-bootstrap';
import AddEditModal from './AddEditModal';

const UnitsOfMeasure = () => {
  const { data: unitsOfMeasure, isLoading, isFetching } = useUnitsOfMeasure();
  const { mutate: deleteUnitOfMeasure } = useDeleteUnitOfMeasure();
  const [showUpdate, turnShowUpdate] = useState(false);
  const [editModalValue, setEditModalValue] = useState({});
  const [status, setStatus] = useState('');

  const isLoaded = !isLoading && !isFetching;

  const openUpdate = (newStatus, value) => {
    setStatus(newStatus);

    if (newStatus === 'edit') {
      setEditModalValue(value);
    }

    turnShowUpdate(true);
  };

  const renderUnitOfMeasure = uom => (
    <div key={`key${uom.id}`} className="tree-node tree-node-content">
      <span className="tree-node-content-name">
        <strong style={{ color: '#ffffff' }}>
          #{uom.id} | {uom.name}
        </strong>
      </span>
      <Authorize
        abilityPermissions={[{ resource: 'Database', permissions: ['update'] }]}
        access={['JOB_MANAGER']}>
        <span>
          <Button
            onClick={() => openUpdate('edit', uom)}
            theme="small"
            testSelector="database_districts_edit_btn">
            <Icon icon="pencil" />
          </Button>
        </span>
      </Authorize>
      <Authorize
        abilityPermissions={[{ resource: 'Database', permissions: ['delete'] }]}
        access={['JOB_MANAGER']}>
        <Button
          onClick={() => deleteAlert(deleteUnitOfMeasure, uom.id)}
          theme="small"
          testSelector="database_certificates_delete_btn">
          <Icon icon="trash" />
        </Button>
      </Authorize>
    </div>
  );

  return (
    <div className="wrapper-content">
      <Row>
        {isLoaded ? (
          <div className="ibox">
            <div className="ibox-content">
              <Authorize
                abilityPermissions={[
                  { resource: 'Database', permissions: ['create'] },
                ]}
                access={['JOB_MANAGER']}>
                <Button
                  onClick={() => openUpdate('add')}
                  inverse
                  className="float-end">
                  <Icon className="icon--margin-right" icon="plus-square" />
                  Add Unit Of Measure
                </Button>
              </Authorize>
              {unitsOfMeasure.map(renderUnitOfMeasure)}
            </div>
          </div>
        ) : (
          <div className="ibox">
            <Loader />
          </div>
        )}
      </Row>

      <AddEditModal
        status={status}
        value={editModalValue ?? {}}
        closeUpdate={() => turnShowUpdate(false)}
        showUpdate={showUpdate}
      />
    </div>
  );
};

export default UnitsOfMeasure;
