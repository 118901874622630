import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import { convertTime } from 'utils/convertTime';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ordersReviewStatus from 'components/globalInfo/ordersReviewStatus';

const ModalHistory = ({
  getHistory,
  clearHistory,
  showHistory,
  closeHistory,
  history,
  isLoadedHistory,
  outsideOrderId,
}) => {
  useEffect(() => {
    if (outsideOrderId) {
      getHistory(outsideOrderId);
    } else {
      getHistory();
    }
    return () => clearHistory();
  }, [outsideOrderId, getHistory, clearHistory]);

  const renderHistoryItem = (item, index) => (
    <li key={`history-item-${index}`} className="list-group-item">
      <div
        style={{
          width: `${95}%`,
          display: 'inline-block',
        }}>
        <span className="text-info">
          {item.firstName} {item.lastName}
        </span>
        <span
          style={{
            marginLeft: 5,
          }}
          className={`badge badge-${ordersReviewStatus[item.status].btnColor}`}>
          {ordersReviewStatus[item.status].title}
        </span>
        <span className="text-right">
          {item.disputeReasonName && `${item.disputeReasonName} - `}
          {item.comment}
        </span>
      </div>
      <br />
      <small>
        <Icon icon="clock-o" />
        {convertTime(item.createdAt)}
      </small>
    </li>
  );

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${10}%` }}
        className="modal-container"
        show={showHistory}
        onHide={closeHistory}>
        <Modal.Header>
          <h3>History</h3>
        </Modal.Header>
        {!isLoadedHistory ? (
          <Loader />
        ) : (
          <Modal.Body>
            <div
              className="content-comments-order no-padding"
              style={{ minHeight: '300px' }}>
              <ul className="list-group">
                {(!history.length && (
                  <div className="no-padding">
                    <h4>No history yet</h4>
                  </div>
                )) ||
                  history.map(renderHistoryItem)}
              </ul>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            onClick={closeHistory}
            colour="white"
            testSelector="order-details_order-header_history_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  history: state.orderDetails.history.history,
  isLoadedHistory: state.orderDetails.history.isLoadedHistory,
});

const mapDispatchToProps = dispatch => ({
  getHistory: orderId => dispatch(actions.getHistory(orderId || null)),
  clearHistory: () => dispatch(actions.clearHistory()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalHistory);
