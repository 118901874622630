const initInfo = {
  email: '',
  key: '',
  confirmPass: '',
  pass: '',
};

export default (state = initInfo, action) => {
  switch (action.type) {
    case 'SET_NEW_PASSWORD_INIT':
      return {
        ...state,
        email: action.email,
        key: action.key,
      };
    case 'SET_NEW_PASSWORD_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};
