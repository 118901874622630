import React from 'react';
import QrReader from 'react-qr-reader';
import swal from 'bootstrap-sweetalert';

import { history } from 'helpers/history';
import { useAudio } from 'helpers/hooks';

import qrCodeAudio from "assets/audio/qr-code-scan.wav";

export default () => {
  const invalidQR = () => swal('Invalid QR code', '', 'error');

  const [playing, toggleAudio] = useAudio(qrCodeAudio);

  const handleScan = data => {
    if (data) {
      toggleAudio();
      try {
        const order = JSON.parse(data);
        if (order && order.order_id) {
          history.push(
            `/job-coordinator/order-details/${order.order_id}?complete=true&fromQr=true`,
          );
        } else {
          invalidQR();
        }
      } catch (error) {
        invalidQR();
      }
    }
  };
  const handleError = err => {
    invalidQR();
  };

  return (
    <div>
      <div className="ibox-title">
        <h5>Please Scan a QR code provided by Automatize Driver mobile app</h5>
      </div>
      <div className="scaner-wrapper">
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '70%' }}
        />
      </div>
    </div>
  );
};
