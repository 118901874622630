import React from 'react';
import { Col, Card, Row } from 'react-bootstrap';

const Orders = ({ details }) => (
  <Card>
    <Card.Header>
      <Card.Title>
        <h5>Orders</h5>
      </Card.Title>
    </Card.Header>
    <Card.Body className="text-center" style={{ paddingTop: '25px' }}>
      <Row>
        <Col lg={4} md={4} sm={4} xs={4} style={{ color: '#1ab394' }}>
          <h1 className="no-margins">{details.totalOrders || 0}</h1>
          <div className="font-bold">Total</div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} style={{ color: 'rgb(240,83,98)' }}>
          <h1 className="no-margins">{details.ongoingOrders || 0}</h1>
          <div className="font-bold">Ongoing</div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} style={{ color: 'rgb(7,198,201)' }}>
          <h1 className="no-margins">{details.comletedOrders || 0}</h1>
          <div className="font-bold">Completed</div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default Orders;
