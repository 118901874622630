import React from 'react';

const Left = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill">
    <path d="M15 16.6L10.649 12 15 7.4 13.676 6 8 12l5.676 6z" />
  </svg>
);

export default Left;
