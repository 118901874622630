import { sortBy, uniqBy } from 'lodash';
import moment from 'moment';

export const selectColor = number => {
  if (!number) {
    return 'hsl(204, 8%, 76%)';
  }

  const hue = number * 137.508; // use golden angle approximation
  return `hsl(${hue}, 60%, 70%)`;
};

const parseDate = date => {
  if (date === null) {
    return new Date();
  }
  const result = new Date(date);
  if (!Number.isNaN(result)) {
    return result;
  }
  return new Date();
};

const generateCarrierColor = commitment => {
  if (commitment.onHold) {
    return '#f7245bd9';
  }
  if (!commitment.isActive) {
    return '#ffa106d9';
  }
  return '#cdcdced9';
};

const mapCarrierTasks = (carrierCommitments, jobTask) =>
  sortBy(carrierCommitments, 'startDate').map((carrierCommitment, index) => {
    const color = generateCarrierColor(carrierCommitment);

    /** @type {import('gantt-task-react').Task[]} */
    const carrierCommitmentTask = {
      carrierCommitment,
      start: parseDate(carrierCommitment.startDate),
      end: parseDate(carrierCommitment.endDate),
      name: `${carrierCommitment.carrierName}: ${carrierCommitment.totalTrucks}
             ${carrierCommitment.onHold ? ' [ON HOLD]' : ''}
             ${!carrierCommitment.isActive ? ' [INACTIVE]' : ''}`,
      id: `${jobTask.id}-commitment-${index}`,
      progress: 0,
      type: 'task',
      project: jobTask.id,
      isDisabled: true,
      displayOrder: jobTask.displayOrder,
      styles: {
        backgroundColor: color,
        backgroundSelectedColor: color,
      },
    };
    return carrierCommitmentTask;
  });

const filterAndSortJobs = jobs => {
  const filteredJobs = jobs.filter(job => !!job.end_date && job.status === 0);
  const districts = uniqBy(filteredJobs.flatMap(job => job.districts), 'id');

  // Duplicate jobs under each district for that job
  const jobsByDistrict = districts.flatMap(district =>
    filteredJobs
      .filter(job => job.districts.find(d => d.id === district.id))
      .map(j => ({
        ...j,
        district: district.id,
        district_name: district.name,
      })),
  );

  return sortBy(jobsByDistrict, 'district');
};

export const mapJobTasks = jobs => {
  if (!jobs) {
    return [];
  }

  const carrierCommitmentTasks = [];
  const jobTasks = filterAndSortJobs(jobs).map((job, index) => {
    const hasCommitments = !!job.carrierCommitments?.length;
    const color = selectColor(job.tags?.[0]?.id);

    /** @type {import('gantt-task-react').Task[]} */
    const jobTask = {
      job,
      start: parseDate(job.start_date),
      end: parseDate(job.end_date),
      name: job.job_name,
      id: `job-${job.id}-${job.district}`,
      type: 'project',
      progress: 0,
      isDisabled: true,
      // Lets calender know there are children
      hideChildren: hasCommitments ? true : undefined,
      styles: {
        backgroundColor: color,
        backgroundSelectedColor: color,
      },
      displayOrder: index + 1,
    };

    if (hasCommitments) {
      carrierCommitmentTasks.push(
        ...mapCarrierTasks(job.carrierCommitments, jobTask),
      );
    }

    return jobTask;
  });

  return [...jobTasks, ...carrierCommitmentTasks];
};

const handleFilter = (filterKey, filterValue, task) => {
  switch (filterKey) {
    case 'tags':
      return (task.job.tags || []).some(t => filterValue.includes(t.id));
    case 'carrierName':
      return (task.job.carrierCommitments || []).some(c =>
        c.carrierName.toLowerCase().includes(filterValue.toLowerCase()),
      );
    case 'startDate':
      return moment(task.job.start_date).isSameOrAfter(
        moment(filterValue),
        'D',
      );
    case 'endDate':
      return moment(task.job.end_date).isSameOrBefore(moment(filterValue), 'D');
    default:
      return String(task.job[filterKey])
        .toLowerCase()
        .includes(filterValue.toLowerCase());
  }
};

export const jobTaskFilter = (task, filters) => {
  const filterEntries = Object.entries(filters);
  if (!filterEntries.length) return true;

  if (task.job) {
    return filterEntries.every(([key, value]) =>
      handleFilter(key, value, task),
    );
  }

  return true;
};

export const carrierTaskFilter = (task, filters, tasks) => {
  const filterKeys = Object.keys(filters);
  if (!filterKeys.length) return true;

  if (task.carrierCommitment) {
    // Check if project has already been filtered out
    if (!tasks.find(i => i.id === task.project)) {
      return false;
    }
  }

  return true;
};
