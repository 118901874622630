import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actionsSearch from 'store/actions/SearchResult';
import Link from 'components/common/Link';
import Pagination from 'components/common/ReduxPagination';
import Header from 'components/common/Header';
import { searchType } from 'components/globalInfo/searchType';
import { useParams , useLocation } from 'react-router-dom';
import SearchResultLabel from './SearchResultLabel';

const pathName = type => {
  switch (type) {
    case 1:
      return 'order';
    case 2:
      return 'job';
    case 3:
      return 'carrier';
    case 4:
      return 'customer';
    case 5:
      return 'driver';
    default:
      return <span />;
  }
};

const SearchResult = ({
  getSearchResultData,
  setQueryParam,
  clearState,
  query,
  searchData,
  isLoaded,
  clearStatePagination,
  itemsPerPage,
  currentPage,
}) => {
  const { pathname } = useLocation();
  const { results, total } = searchData;
  const totalPages = Math.ceil(total / itemsPerPage);

  const { resultId } = useParams();

  useEffect(() => () => clearState(), [clearState]);

  useEffect(() => {
    setQueryParam(resultId);
    clearStatePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultId]);

  useEffect(() => {
    if (query) {
      getSearchResultData(pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentPage]);

  return (
    <div>
      <Header title="Search result" smallTitle="Search result" />
      <div className="wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-content">
                <h2>
                  {!!isLoaded && total} results found for:
                  <span className="text-navy">“{query}”</span>
                </h2>
                <Pagination totalPages={totalPages || 1} />
                <div className="search-container">
                  {isLoaded &&
                    results &&
                    results.map(data => (
                      <div
                        key={data.id}
                        className="search-result"
                        style={{ cursor: 'pointer' }}>
                        <Link
                          {...{
                            id: data.id,
                            to: `${pathName(data.type)}-details`,
                          }}>
                          <h3>
                            <span>
                              {searchType[data.type]} #{data.id}
                            </span>
                            {Boolean(data.name.length) && (
                              <span> - {data.name}</span>
                            )}
                            {Boolean(data.bol.length) && (
                              <span> - {data.bol}</span>
                            )}
                          </h3>
                        </Link>

                        <div
                          className="vote-icon"
                          style={{ float: 'right', marginTop: '-40px' }}>
                          <SearchResultLabel data={data} />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  query: state.search.query,
  searchData: state.search.searchData,
  isLoaded: state.search.isLoaded,
  pathname: state.search.pathname,
  itemsPerPage: state.search.itemsPerPage,
  currentPage: state.pagination.pagination.currentPage,
});

const mapDispatchToProps = dispatch => ({
  getSearchResultData: pathname =>
    dispatch(actionsSearch.getSearchResultData(pathname)),
  clearState: () => dispatch(actionsSearch.clearState()),
  setQueryParam: query => dispatch(actionsSearch.setQueryParam(query)),
  clearStatePagination: () => {
    dispatch({ type: 'PAGINATION_CLEAR_STATE' });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResult);
