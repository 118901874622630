import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import { useUnitsOfMeasure } from 'api/v2/units-of-measure';
import Loader from 'components/shared/Loader';
import { useExtRef, useAddExtRef } from 'api/v2/commodities';

const ReferenceModal = ({ showExtRefModal, setShowExtRefModal, item, uom }) => {
  const [formData, setFormData] = useState({
    extRef: '',
    uom: uom,
  });
  const { data: unitsOfMeasure, isLoading: uomIsLoading } = useUnitsOfMeasure();
  const { data: refData, isLoading: refIsLoading } = useExtRef(item?.id);
  const { mutate: editExtRef, isPending: isExtRefPending } = useAddExtRef(
    item?.id,
  );

  useEffect(() => {
    if (refData) {
      setFormData({
        extRef: refData.extRef || '',
        uom: refData.extRefuom || uom,
      });
    }
  }, [refData, showExtRefModal]);

  const handleChange = (e, field) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: targetValue(e),
    }));
  };

  const handleClose = () => {
    setFormData({ extRef: '', uom: '' });
    setShowExtRefModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    editExtRef(
      { ...formData, commodityId: item?.id },
      { onSuccess: handleClose },
    );
  };

  const saveDisabled = useMemo(() => {
    return !formData.extRef.trim().length;
  }, [formData.extRef]);

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showExtRefModal}
        onHide={handleClose}>
        <form className="m-t" role="form" onSubmit={handleSubmit}>
          <Modal.Header>
            <h3>{`Edit Commodity ${item?.name || ''} Reference`}</h3>
          </Modal.Header>
          {uomIsLoading || refIsLoading ? (
            <Loader />
          ) : (
            <Modal.Body className="form-group">
              <Row>
                <div className="form-group has-feedback col-md-12">
                  <Input
                    onChange={e => handleChange(e, 'extRef')}
                    value={formData.extRef}
                    label="External Reference Id"
                    required
                    testSelector="manage-database_commodities_add-edit_extRef_input"
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group has-feedback col-md-12">
                  <Select
                    onChange={value => {
                      handleChange(value.id, 'uom');
                    }}
                    value={unitsOfMeasure
                      .map(e => ({ id: e.id, label: e.name }))
                      .find(m => m.id === formData?.uom)}
                    options={unitsOfMeasure.map(e => ({
                      id: e.id,
                      label: e.name,
                    }))}
                    placeholder="Select Unit of Measure"
                    testSelector="manage-database_commodities_add-edit_extRef_uom"
                  />
                </div>
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="database_commodities_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled || isExtRefPending}
              testSelector="database_commodities_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ReferenceModal;
