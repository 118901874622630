export default operationType => {
  switch (operationType) {
    case 0:
      return {
        value: 0,
        type: 'UNKNOWN',
        label: 'Unknown',
      };
    case 1:
      return {
        value: 1,
        type: 'COMPLETIONS',
        label: 'Completions',
      };
    case 2:
      return {
        value: 2,
        type: 'DRILLING',
        label: 'Drilling',
      };
    case 10:
      return {
        value: 10,
        type: 'CONSTRUCTION',
        label: 'Construction',
      };
    case 11:
      return {
        value: 11,
        type: 'FLOWBACK',
        label: 'Flowback',
      };
    case 12:
      return {
        value: 12,
        type: 'PRODUCTION',
        label: 'Production',
      };
    case 13:
      return {
        value: 13,
        type: 'HEAVY_HAUL',
        label: 'Heavy Haul',
      };
    default:
      return {
        value: 0,
        type: 'UNKNOWN',
        label: 'Unknown',
      };
  }
};

export const operationTypes = {
  '0': {
    value: 0,
    type: 'UNKNOWN',
    label: 'Unknown',
  },
  '1': {
    value: 1,
    type: 'COMPLETIONS',
    label: 'Completions',
  },
  '2': {
    value: 2,
    type: 'DRILLING',
    label: 'Drilling',
  },
  '10': {
    value: 10,
    type: 'CONSTRUCTION',
    label: 'Construction',
  },
  '11': {
    value: 11,
    type: 'FLOWBACK',
    label: 'Flowback',
  },
  '12': {
    value: 12,
    type: 'PRODUCTION',
    label: 'Production',
  },
  // '13': {//add this when heavy haul is added fully
  //   value: 13,
  //   type: 'HEAVY_HAUL',
  //   lable: 'Heavy Haul',
  // },
};
