import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { linearGradientDef } from '@nivo/core';

const PieChart = ({ data, gradientFill, size, total, innerRadius }) => {
  const gradientDefs = [
    linearGradientDef('orange', [
      { offset: 0, color: '#f76224' },
      { offset: 100, color: '#ffa106' },
    ]),
    linearGradientDef('yellow', [
      { offset: 0, color: '#dc9c14' },
      { offset: 100, color: '#f0e33a' },
    ]),
    linearGradientDef('grey', [
      { offset: 0, color: '#404757' },
      { offset: 100, color: '#404757' },
    ]),
    linearGradientDef('green', [
      { offset: 0, color: '#429321' },
      { offset: 100, color: '#b4ec51' },
    ]),
    linearGradientDef('blue', [
      { offset: 0, color: '#22adcf' },
      { offset: 100, color: '#6fc6d9' },
    ]),
    linearGradientDef('purple', [
      { offset: 0, color: '#5732b0' },
      { offset: 100, color: '#a64aea' },
    ]),
  ];

  return (
    <div
      className="pie-chart"
      style={{ width: `${size}px`, height: `${size}px` }}>
      <ResponsivePie
        data={data}
        innerRadius={innerRadius || 0.7}
        defs={gradientDefs}
        fill={gradientFill}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        isInteractive={false}
      />
      {total || total === 0 ? (
        <div className="pie-chart__total">
          <span style={{ fontSize: `${size * 0.3}px` }}>{total}</span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PieChart;
