import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { queue } from 'services/queue/service';
import { order } from 'services/order/service';
import { changeCaret } from 'utils/changeCaretForSort';

let gs;

const initCount = () => ({ type: 'SET_COUNT_DATA' });

const init = () => (dispatch, getState) => {
  const success = (data, array) => ({
    type: 'GET_QUEUE_DATA_SUCCESS',
    data,
    array,
  });
  const failure = error => ({ type: 'GET_QUEUE_DATA_FAILURE', error });
  const {
    queue: { count, countAPI },
  } = getState();

  queue
    .getAvailableDrivers()
    .then(response => {
      const drivers = [...response.data.data.availableDrivers];
      drivers.forEach(driver => {
        driver.status == 2 ? (driver.class = 'assigned-pending') : '';
      });
      dispatch(success(drivers, 'drivers'));
    })
    .catch(error => {
      dispatch(failure(error));
      if (error.data.message) {
        swal(error.response.data.message, '', 'error');
      } else {
        swal('Something went wrong, please try again', '', 'error');
      }
    });

  queue
    .getOrders()
    .then(response => {
      const orders = [...response.data.data.orders];
      orders.forEach(order => {
        const dateWarning = moment().add(1, 'h');
        const now = moment();

        if (moment(order.expected_pickup_time).isSameOrAfter(now) &&
          moment(order.expected_pickup_time).isSameOrBefore(moment(dateWarning))) {
          order.class = 'warning-date';
        } else if (moment(order.expected_pickup_time).isBefore(moment(now))) {
          order.class = 'danger-date';
        } else {
          order.class = '';
        }
      });
      dispatch(success(orders, 'orders'));
    })
    .catch(error => {
      dispatch(failure(error));
      if (error.data.message) {
        swal(error.response.data.message, '', 'error');
      } else {
        swal('Something went wrong, please try again', '', 'error');
      }
    });
};

const initInterval = () => (dispatch, getState) => {
  gs = setInterval(() => {
    const {
      queue: { count, countAPI },
    } = getState();
    if (Number(count) === Number(countAPI)) {
      dispatch(initCount());
      dispatch(init());
    }
  }, 15000);
};

const clearInterval = () => dispatch => {
  clearTimeout(gs);
};

const setFilter = (value, name) => dispatch => {
  dispatch({ type: 'FILTER_SET_DISTRICT_VALUE', value, name });
};

const setSortOrdersData = value => (dispatch, getState) => {
  const {
    queue: { sortOrdersList },
  } = getState();
  const sortOrdersListNew = changeCaret(value, sortOrdersList);
  dispatch({ type: 'SET_SORT_ORDER_DATA', sortOrdersListNew });
  dispatch(setFilter(value, 'sortOrder'));
};

const setSortDriversData = value => (dispatch, getState) => {
  const {
    queue: { sortDriversList },
  } = getState();
  const sortDriversListNew = changeCaret(value, sortDriversList);
  dispatch({ type: 'SET_SORT_DRIVER_DATA', sortDriversListNew });
  dispatch(setFilter(value, 'sortDriver'));
};

const setAssignValue = (value, name) => ({
  type: 'ASSIGN_DRIVER_SET_VALUE',
  value,
  name,
});

const turnModalAssign = order => ({
  type: 'ASSIGN_DRIVER_TURN_MODAL',
  order,
});

const turnModalLoading = isLoading => ({
  type: 'ASSIGN_DRIVER_SET_LOADING',
  value: isLoading
})

const submitAssignDriver = (forceAssignment = false) => (
  dispatch,
  getState,
) => {
  const {
    queue: { assign },
  } = getState();
  dispatch(turnModalLoading(true));
  assign.driverId =
    typeof assign.driverId === 'object'
      ? parseInt(assign.driverId.value)
      : parseInt(assign.driverId);
  assign.orderId = assign.order.order_id;
  if (forceAssignment) {
    assign.isDriverAcceptanceRequired = false;
  }
  order
    .assign(assign)
    .then(msg => {
      dispatch(turnModalLoading(false));
      dispatch(turnModalAssign());
      dispatch(init());
      swal('Success!', '', 'success');
    })
    .catch(error => {
      dispatch(turnModalLoading(false));
      if (error.response) {
        swal(error.response.data.message, '', 'error');
      } else if (error.data) {
        swal(error.data.message, '', 'error');
      } else {
        swal('Something went wrong, please try again', '', 'error');
      }
    });
};

const clearStateQueue = () => ({ type: 'QUEUE_CLEAR_STATE' });

const actions = {
  init,
  initInterval,
  clearInterval,
  initCount,
  setFilter,
  setSortOrdersData,
  setSortDriversData,
  setAssignValue,
  submitAssignDriver,
  turnModalAssign,
  clearStateQueue,
};

export default actions;
