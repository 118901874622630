import { client } from 'api/client';
import authService from 'services/auth/service';
import { dynamicSort } from 'utils/dynamicSort';

const token = authService.getToken();

/**
 *
 * @param {number} jobId
 */
export const getJobComments = async jobId => {
  const { data } = await client.get(`v2/job/${jobId}/comments`, {});

  return data.data.comments.map(comment => ({
    ...comment,
    profileUrl:
      comment?.profileUrl?.length > 0
        ? `${comment.profileUrl}?token=${token}`
        : 'https://www.gravatar.com/avatar/294fcfe0ab64d8c7ecce8ec3214af997',
  })).sort(dynamicSort('-createdAt'));
};

export const sendJobComment = async ({ jobId, comment }) => {
  const { data } = await client.post(`v2/job/${jobId}/comments`, { comment });
  return data;
};
