import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Row } from 'react-bootstrap';

import Authorize, { authorize } from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';

import settingsActions from 'store/actions/AdminSettings';

const CompanyAddress = ({
  companyAddress,
  isLoadedCompanyAddress,
  getCompanyCredentials,
  update,
  clearState,
}) => {
  const [data, setData] = useState(companyAddress);

  useEffect(() => {
    clearState();
    getCompanyCredentials();
  }, []);
  useEffect(() => {
    setData(companyAddress);
  }, [companyAddress]);

  const isDisable = () => {
    if (
      !data.name.trim().length ||
      !data.phoneNumber.trim().length ||
      !data.city.trim().length ||
      !data.state.trim().length ||
      !data.address.trim().length ||
      !data.addressSecond.trim().length ||
      !data.zipCode.trim().length ||
      !data.departmentId.trim().length ||
      !data.email.trim().length ||
      !data.taxId.trim().length
    ) {
      return true;
    }

    return false;
  };

  if (!isLoadedCompanyAddress) {
    return <Loader />;
  }

  return (
    <Card>
      <Card.Header as="h5">Company Address</Card.Header>
      <Card.Body>
        <form onSubmit={e => update(e, data)}>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    name: value,
                  }))
                }
                value={data.name}
                label="Name"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_name_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="tel"
                value={data.phoneNumber}
                onChange={value =>
                  setData(cs => ({
                    ...cs,
                    phoneNumber: value,
                  }))
                }
                label="Phone Number"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    city: value,
                  }))
                }
                value={data.city}
                label="City"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_city_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    state: value,
                  }))
                }
                value={data.state}
                label="State"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_state_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    address: value,
                  }))
                }
                value={data.address}
                label="Address 1"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_address-1_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,

                    addressSecond: value,
                  }))
                }
                value={data.addressSecond}
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                label="Address 2"
                testSelector="admin-settings_company-address_address-2_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    taxId: value,
                  }))
                }
                value={data.taxId}
                label="Tax ID"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_tax-id_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="email"
                label="Email"
                value={data.email}
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    email: value,
                  }))
                }
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                testSelector="admin-settings_company-address_email_input"
                required
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    zipCode: value,
                  }))
                }
                value={data.zipCode}
                label="ZIP"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_zip_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={({ target: { value } }) =>
                  setData(cs => ({
                    ...cs,
                    departmentId: value,
                  }))
                }
                value={data.departmentId}
                label="Department ID"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_company-address_department_input"
              />
            </div>
          </Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Admin', permissions: ['update'] },
            ]}>
            <div className="modal-footer" style={{ border: 'none' }}>
              <Button
                type="submit"
                disabled={isDisable()}
                testSelector="admin-settings_company-address_save_btn">
                Save
              </Button>
            </div>
          </Authorize>
        </form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  companyAddress: state.adminSettings.companyAddress.companyAddress,
  isLoadedCompanyAddress:
    state.adminSettings.companyAddress.isLoadedCompanyAddress,
});

const mapDispatchToProps = dispatch => ({
  getCompanyCredentials: () => {
    dispatch(settingsActions.getCompanyCredentials());
  },
  update: (e, data) => {
    e.preventDefault();
    dispatch(settingsActions.updateCompanyCredentials(data));
  },
  clearState: () => dispatch(settingsActions.clearCopmanyCredentialsState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyAddress);
