import moment from 'moment';

export const isDateFormatValid = date =>
  moment(date, 'MM/DD/YY hh:mm A', true).isValid();

export const validateCommitmentTimes = (carrierCommitments, job) => {
  const jobStart = moment(job.start_date);
  const jobEnd = moment(job.end_date);

  const result = carrierCommitments.map(commitment => {
    const start = moment(commitment.startDate);
    const end = moment(commitment.endDate);

    const startBeforeJob = start.isBefore(jobStart) || end.isBefore(jobStart);
    const endAfterJob = end.isAfter(jobEnd) || start.isAfter(jobEnd);
    if (end.isBefore(start) || startBeforeJob || endAfterJob) {
      return {
        ...commitment,
        isDanger: true,
      };
    }
    return {
      ...commitment,
      isDanger: false,
    };
  });
  return result;
};

const calculateAverageMileage = pos => {
  const sum = pos.reduce((a, b) => a + b.approvedMileage, 0);
  const avg = sum / pos.length || 0;
  return avg;
};

export const calculateRequiredTrucks = job => {
  if (
    !(
      job.details?.totalWeightPerJob &&
      job.details?.totalStages &&
      job.details?.targetStagesPerDay &&
      job.purchaseOrders?.length
    )
  ) {
    return null;
  }

  const avgWeight = 48000;
  const loadingTime = 0.5;
  const driveTimePerDay = 11;

  const totalWeightPerStage =
    job.details.totalWeightPerJob / job.details.totalStages;
  const stagesPerDay = job.details.targetStagesPerDay;

  const mileage = calculateAverageMileage(job.purchaseOrders);

  const loadsPerDay = (totalWeightPerStage * stagesPerDay) / avgWeight;
  const travelTime = (mileage * 2) / 55 + loadingTime;
  const totalTrucksActive = loadsPerDay / (driveTimePerDay / travelTime);
  const totalTrucksAssigned = totalTrucksActive * 1.42;
  const totalRequired = totalTrucksAssigned + (totalTrucksAssigned + 0.1);
  return Math.ceil(totalRequired);
};
