import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setNotificationValue as setNotificationValueAction,
  selectBusy as selectBusyAction,
  selectFree as selectFreeAction,
  checkDriver as checkDriverAction,
  selectAll as selectAllAction,
  sendMessage as sendMessageAction,
} from 'store/actions/Drivers';
import targetValue from 'utils/targetValue';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Label from 'components/shared/Label';
import Loader from 'components/shared/Loader';

import { useGetPaginatedDriversQuery, apiSlice } from 'features/api/apiSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal, Row } from 'react-bootstrap';
import { isSameTerms } from './helpers';

const getStatus = (free, busy) => {
  if (free) {
    return 0;
  }
  if (busy) {
    return 1;
  }
  return undefined;
};
const SendNotifications = ({
  showModalSendNotifications,
  closeSendNotifications,
  setModalValue,
  refetchDrivers,
  sendMessage,
  selectFree,
  selectBusy,
  stateData,
  checkDriver,
  selectAll,
}) => {
  const {
    ids,
    selectAllLabel,
    free,
    busy,
    driverId,
    name,
    district,
    truck,
    carrier,
  } = stateData;
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [page, setPage] = useState(1);
  const [newDrivers, setNewDrivers] = useState([]);
  const [filterTerms, setFilterTerms] = useState({
    driverId: '',
    name: '',
    district: '',
    truck: '',
    carrier: '',
  });

  const [searchPressed, setSearchPressed] = useState(false);
  const { data: driversResult, isLoading } = useGetPaginatedDriversQuery({
    driverId: filterTerms?.driverId?.length
      ? Number(filterTerms.driverId)
      : undefined,
    driverName: filterTerms.name.length ? filterTerms.name : undefined,
    districtDetail: filterTerms.district.length
      ? filterTerms.district
      : undefined,
    truckNumber: filterTerms.truck.length ? filterTerms.truck : undefined,
    carrierName: filterTerms.carrier.length ? filterTerms.carrier : undefined,
    isDeleted: 0,
    status: getStatus(free, busy),
    page,
  });

  const applyFilters = () => {
    if (
      isSameTerms(filterTerms, {
        driverId,
        name,
        district,
        truck,
        carrier,
      })
    ) {
      return;
    }
    selectAll(newDrivers, false);
    setFilterTerms({ driverId, name, district, truck, carrier });
    setNewDrivers([]);
    setPage(1);
    setSearchPressed(true);
    refetchDrivers();
  };

  const fetchMoreDrivers = () => {
    if (searchPressed) {
      setSearchPressed(false);
      return;
    }

    setPage(oldPage => oldPage + 1);
    refetchDrivers();
  };

  useEffect(() => {
    setSearchPressed(false);
    setNewDrivers(oldDrivers => [...oldDrivers, ...(driversResult ?? [])]);
  }, [driversResult]);

  const handleInputMessage = e => {
    setSaveDisabled(!e.target.value.trim().length);
    setModalValue(e, 'message');
  };

  const renderDriverItem = item => (
    <div
      key={`driver-${item.id}`}
      className="vote-item"
      style={{ padding: '5px 5px' }}>
      <div className="row">
        <div className="col-lg-12" style={{ display: 'flex' }}>
          <div>
            <Input
              type="checkbox"
              isChecked={Boolean(ids[item.id]) || false}
              onChange={e => checkDriver(item.id, e)}
              testSelector={`drivers-list_send-notification_select-driver_${
                item.id
              }_input`}
            />
          </div>
          <label
            htmlFor={`drivers-list_send-notification_select-driver_${
              item.id
            }_input`}
            style={{ marginLeft: 5 }}>
            <strong
              className="text-navy"
              style={{ cursor: 'pointer', fontSize: 16 }}>
              <Icon icon="male" /> driver #{item.id}:
            </strong>
            <span> {item.name}</span>
            {item.status ? (
              <Label style={{ padding: '0px 5px' }} type="danger">
                active
              </Label>
            ) : (
              <Label style={{ padding: '0px 5px' }} type="primary">
                free
              </Label>
            )}
          </label>
        </div>
      </div>
      <div className="row footerSandSite" />
    </div>
  );

  const renderBody = () => (
    <>
      <Row>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            type="number"
            onChange={e => {
              setModalValue(e, 'driverId');
            }}
            label="Driver ID"
            testSelector="drivers_send-notifications_driver-id_input"
          />
        </div>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setModalValue(e, 'name');
            }}
            label="Driver Name"
            testSelector="drivers_send-notifications_name_input"
          />
        </div>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setModalValue(e, 'district');
            }}
            label="District"
            testSelector="drivers_send-notifications_district_input"
          />
        </div>
        <div className="form-group has-feedback col-md-3 col-sm-6 col-lg-6">
          <Input
            type="number"
            onChange={e => {
              setModalValue(e, 'truck');
            }}
            label="Truck number"
            testSelector="drivers_send-notifications_truck-number_input"
          />
        </div>
        <div className="form-group has-feedback col-md-3 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setModalValue(e, 'carrier');
            }}
            label="Carrier name"
            testSelector="drivers_send-notifications_carrier-name_input"
          />
        </div>
        <Button onClick={applyFilters}>Search</Button>
      </Row>
      <Row>
        <div className="col-lg-5">
          <Input
            onChange={handleInputMessage}
            label="Message"
            required
            testSelector="drivers_send-notifications_message_input"
          />
        </div>
        <div className="col-lg-7">
          <Button
            onClick={() => {
              setPage(1);
              setSearchPressed(true);
              setNewDrivers([]);
              selectFree();
              refetchDrivers();
            }}
            theme="small"
            inverse={free}
            drivers-list_filter_toggle_btn
            testSelector="drivers-list_send-notifications_free_btn">
            Free
          </Button>
          <Button
            onClick={() => {
              setPage(1);
              setSearchPressed(true);
              setNewDrivers([]);
              selectBusy();
              refetchDrivers();
            }}
            theme="small"
            inverse={busy}
            testSelector="drivers-list_send-notifications_busy_btn">
            Busy
          </Button>
        </div>
      </Row>
      <div
        id="scrollableDiv"
        style={{
          marginTop: 10,
          maxHeight: '500px',
          overflowY: 'scroll',
        }}>
        <Row className="vote-item" style={{ padding: '5px 5px' }}>
          <div className="col-lg-12">
            <Input
              type="checkbox"
              testSelector="drivers-list_send-notification_select-all_input"
              isChecked={selectAllLabel}
              onChange={e => selectAll(newDrivers, e)}
              label="Select All"
            />
          </div>
        </Row>
        <InfiniteScroll
          dataLength={newDrivers.length}
          hasMore={Boolean(driversResult?.length)}
          loader={<Loader />}
          next={fetchMoreDrivers}
          scrollableTarget="scrollableDiv">
          {newDrivers.map(renderDriverItem)}
        </InfiniteScroll>
      </div>
    </>
  );

  return (
    <Modal
      onKeyPress={e => {
        if (e.key === 'Enter') {
          applyFilters();
        }
      }}
      show={showModalSendNotifications}
      onHide={() => {
        setPage(1);
        setNewDrivers([]);
        setFilterTerms({
          driverId: '',
          name: '',
          district: '',
          truck: '',
          carrier: '',
        });
        closeSendNotifications();
      }}>
      <Modal.Header>
        <Modal.Title as="h3">Select Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>{isLoading ? <Loader /> : renderBody()}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeSendNotifications}
          colour="white"
          testSelector="drivers-list_send-notifications_close_btn">
          Close
        </Button>
        <Button
          onClick={e => sendMessage(e, closeSendNotifications)}
          disabled={saveDisabled}
          testSelector="drivers-list_send-notifications_send_btn">
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  stateData: state.driversNew.notification,
});

const mapDispatchToProps = dispatch => ({
  setModalValue: (e, name) => {
    dispatch(setNotificationValueAction(targetValue(e), name));
  },
  selectFree: () => {
    dispatch(selectFreeAction());
  },
  selectBusy: () => {
    dispatch(selectBusyAction());
  },
  checkDriver: (id, e) => {
    dispatch(checkDriverAction(id, targetValue(e)));
  },
  selectAll: (newDrivers, e) => {
    dispatch(selectAllAction(newDrivers, targetValue(e)));
  },
  sendMessage: (e, close) => {
    e.preventDefault();
    dispatch(sendMessageAction(close));
  },
  refetchDrivers: () => {
    dispatch(apiSlice.util.invalidateTags(['PaginatedDriver']));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendNotifications);
