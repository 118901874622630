import { combineReducers } from '@reduxjs/toolkit';
import addOrderModal from './AddOrderModal';
import addPrice from './AddPriceCarrier';
import alertEmails from './AlertEmails';
import autoSettings from './AutoSettings';
import { containerized } from './Containerized';
import { documents } from './Documents';
import events from './Events';
import operatorAddModal from './OperatorAddModal';
import { poModals } from './PoModals';
import sendNotification from './SendNotification';
import { stageDesign } from './StageDesign';
import { stages } from './Stages';
import { tabs } from './Tabs';
import { tankModals } from './TankModals';
import todayInfoModal from './TodayInfoModal';
import { totalStats } from './TotalStats';
import { unitModals } from './UnitModals';

const init = {
  loading: true,
  loadingOrders: true,
  details: [{}],
  orders: [{}],
  purchaseOrders: [{}],
  rateTable: [{}],
  stageDesign: [{}],
  stages: [{}],
  totalStats: [{}],
  storageHistory: [{}],
  pounds: '',
  conteinerizedSettings: [{}],
  demurrageTable: [{}],
  units: [{}],
  carriers: [{}],
  tanks: [{}],
  totalPages: '',
  logisticPo: [],
  stats: {},
  stageDesignConfig: {},
  storageTimeline: {},
  storageStatus: {},
  storageSiloInfo: [{}],
  autoSettings: {},
  operators: [],
  billingProfile: [],
  jobAlerts: [],
};
const details = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_GET_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        details: action.date.details,
        purchaseOrders: action.date.purchaseOrders,
        rateTable: action.date.rateTable,
        stageDesign: action.date.stageDesign,
        stages: action.date.stages,
        totalStats: action.date.totalStats,
        storageHistory: action.date.storageHistory,
        pounds: action.date.details.commodityUnits,
        conteinerizedSettings: action.date.conteinerizedSettings,
        demurrageTable: action.date.demurrageTable,
        units: action.date.units,
        carriers: action.date.carriers,
        facilityId: action.date.facilityId,
        documents: action.date.documents,
        logisticPo: action.date.logisticPo,
        stats: action.date.stats,
        stageDesignConfig: action.date.stageDesignConfig,
        storageTimeline: action.date.storageTimeline,
        storageStatus: action.date.storageStatus,
        storageSiloInfo: action.date.storageSiloInfo,
        operators: action.date.operators,
        billingProfile: action.date.billingProfile,
        districts: action.date.districts,
        jobAlerts: action.date.jobAlerts,
        cygnet: action.date.cygnet,
      };
    case 'JOB_DETAILS_GET_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.data.orders,
        totalPages: action.data.totalPages,
        loadingOrders: false,
      };
    case 'JOB_DETAILS_GET_ORDERS_REQUEST':
      return {
        ...state,
        loadingOrders: true,
      };
    case 'JOB_DETAILS_GET_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'JOB_DETAILS_GET_TANKS_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'JOB_DETAILS_GET_DETAILS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'JOB_DETAILS_GET_TANKS_SUCCESS':
      return {
        ...state,
        tanks: action.tanks,
      };
    case 'JOB_DETAILS_SWITCH_WEIGHT_STATUS':
      return {
        ...state,
        pounds: action.data,
      };
    case 'JOB_DETAILS_ORDER_LIST_REFRESH_ORDER':
      return {
        ...state,
        orders: action.orders,
      };
    case 'UPDATE_AUTO_ORDER_SUCCESS':
      return {
        ...state,
        details: {
          ...state.details,
          autoOrder: action.autoOrder,
        },
      };
    case 'JOB_DETAILS_CLEAR_STATE':
      return init;

    default:
      return state;
  }
};

export const jobDetails = combineReducers({
  addOrderModal,
  addPrice,
  alertEmails,
  autoSettings,
  containerized,
  details,
  documents,
  events,
  operatorAddModal,
  poModals,
  sendNotification,
  stageDesign,
  stages,
  tabs,
  tankModals,
  todayInfoModal,
  totalStats,
  unitModals,
});
