import React from 'react';
import { authorize } from 'components/common/Authorize';
import { Tabs, Tab } from 'react-bootstrap';
import authService from 'services/auth/service';

import CarriersTabContent from './Carriers';
import DefaultTabContent from './Default';
import DriversTabContent from './Drivers';
import OrdersTabContent from './Orders';
import UnitsTabContent from './Units';

const DistrictTabs = ({
  setTab,
  tab,
  info,
  triggerAddModal
}) => {
  const { accessLevel } = info.details[0];
  const user = authService.getUser();

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="tabs-container">
          <Tabs activeKey={tab} onSelect={setTab} id="controlled-tab-example">
            {authorize({
              abilityPermissions: [
                { resource: 'Customers', permissions: ['read'] },
              ],
            }) &&
              <Tab eventKey="customers" title="Customers">
                <DefaultTabContent district={info} tab="customers" />
              </Tab>
            }
            {authorize({
              abilityPermissions: [
                { resource: 'Carriers', permissions: ['read'] },
              ],
            }) &&
              <Tab eventKey="carriers" title="Carriers" >
                <CarriersTabContent district={info} tab="carriers" />
              </Tab>
            }
            {authorize({
              abilityPermissions: [{ resource: 'Jobs', permissions: ['read'] }],
            }) &&
              <Tab eventKey="jobs" title="Jobs">
                <DefaultTabContent district={info} tab="jobs" />
              </Tab>
            }
            {authorize({
              abilityPermissions: [
                { resource: 'Carrier-ratio', permissions: ['update'] },
              ],
            }) &&
              (accessLevel === "owner" || user.entityType === 6) && [
                <Tab eventKey="activeOrders" title="Orders" >
                  <OrdersTabContent district={info} tab="activeOrders" />
                </Tab>,
                <Tab eventKey="activeDrivers" title="Drivers">
                  <DriversTabContent district={info} tab="activeDrivers" />
                </Tab>,
              ]}

            {authorize({
              abilityPermissions: [
                { resource: 'Locations', permissions: ['read'] },
              ],
            }) &&
              <Tab eventKey="units" title="Disposal Units">
                <UnitsTabContent district={info} triggerAddModal={triggerAddModal} />
              </Tab>}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default DistrictTabs;
