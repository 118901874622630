import React from 'react';
import Icon from 'components/shared/Icon';

export default district => (
  <div className="col-md-2 col-sm-3 text-center district-item">
    <Icon icon="exchange" /> district
    <br />
    <strong className="text-navy">
      <span>#{district.id}: </span><span>{district.name}</span>
    </strong>
  </div>
);
