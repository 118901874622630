import { customer } from 'services/customer/service';

export const customersRequested = params => async dispatch => {
  dispatch({ type: 'CUSTOMERS_REQUESTED' });

  const customers = await customer.get(params).catch(error => {
    // TODO: Add Generic Failed Handler
    return dispatch({ type: 'CUSTOMERS_REQUEST_FAILED' });
  });

  return dispatch({
    customers: customers.data.data.customers,
    type: 'CUSTOMERS_REQUEST_SUCCEEDED',
  });
};

export default {
  customersRequested,
};
