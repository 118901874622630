import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import Loader from 'components/shared/Loader';

import { useCommodities } from 'api/v2/commodities';
import { useHistoricalLevelsTimeline } from 'api/sls/storage';
import { getMinimumTimelineWeight } from './helpers';

const colorsPredicted = [
  'rgba(166,74,234,1)',
  'rgba(247,36,91,1)',
  'rgba(255,161,6,1)',
  'rgba(248,231,28,1)',
  'rgba(152,226,86,1)',
  'rgba(78,121,247,1)',
];
const colorsAlphaPredicted = [
  'rgba(166,74,234,0.5)',
  'rgba(247,36,91,0.5)',
  'rgba(255,161,6,0.5)',
  'rgba(248,231,28,0.5)',
  'rgba(152,226,86,0.5)',
  'rgba(78,121,247,0.5)',
];

const LineGraph = ({ sandColors, jobId, storageId }) => {
  const datasets = [];
  const labels = [];

  const { data: sandTypes } = useCommodities();
  const predicted = {};

  const { data: storageTimeline, isLoading } = useHistoricalLevelsTimeline({
    jobId,
    intervalHours: 16,
    storageId,
  });

  if (isLoading || !storageTimeline) {
    return <Loader />;
  }
  const minimumTimelineWeight = getMinimumTimelineWeight(storageTimeline);

  let minActualAdded = 0;
  let minPredictedAdded = 0;
  let colorIndex = 0;

  Object.keys(storageTimeline.actual).forEach(sandTypeId => {
    const sandLineActual = storageTimeline.actual[sandTypeId].map(
      t => t.weight,
    );
    colorIndex += 1;
    if (!minActualAdded) {
      colorIndex = 0;
      storageTimeline.actual[sandTypeId].forEach(t => {
        minActualAdded = 1;
        labels.push(
          moment
            .utc(t.date)
            .local()
            .format('HH:mm'),
        );
      });
    }

    datasets.push({
      label:
        sandTypes.find(t => t.id === Number(sandTypeId))?.name ?? sandTypeId,
      backgroundColor: sandColors[sandTypeId]?.colorAlpha,
      borderColor: sandColors[sandTypeId]?.color,
      data: sandLineActual,
    });
  });

  const actualLabelLength = labels.length;
  if (actualLabelLength > 0) {
    Object.keys(predicted).forEach(sandTypeId => {
      if (storageTimeline.actual[sandTypeId]) {
        let sandLinePredicted = predicted[sandTypeId].map(t => t.weight);

        sandLinePredicted = [
          storageTimeline.actual[sandTypeId][actualLabelLength - 1].weight,
          ...sandLinePredicted,
        ];

        sandLinePredicted = [
          ...new Array(actualLabelLength - 1).fill(null),
          ...sandLinePredicted,
        ];

        colorIndex += 1;

        if (!minPredictedAdded) {
          colorIndex = 0;
          predicted[sandTypeId].forEach(t => {
            minPredictedAdded = 1;
            labels.push(
              moment
                .utc(t.date)
                .local()
                .format('HH:mm'),
            );
          });
        }

        const sandName =
          sandTypes.find(t => t.id === Number(sandTypeId))?.name ?? sandTypeId;
        datasets.push({
          label: `${sandName} / predicted`,
          backgroundColor: colorsAlphaPredicted[colorIndex],
          borderColor: colorsPredicted[colorIndex],
          data: sandLinePredicted,
        });
      }
    });
  }

  const data = {
    labels,
    datasets,
  };

  /**
   *  @type { import('chart.js').ChartOptions }
   */
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: minimumTimelineWeight - 500,
          },
        },
      ],
    },
  };

  return (
    <div>
      <Line data={data} height={122} options={options} />
    </div>
  );
};

export default LineGraph;
