import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';

import { useDebounce } from 'helpers/hooks';

import Authorize from 'components/common/Authorize';
import Block from 'components/shared/Block';
import Filter from 'components/views/Locations/Filter';
import LocationsList from 'components/views/Locations/LocationsList';
import LocationsMap from 'components/views/Locations/Map';
import Pagination from 'components/shared/Pagination';
import LocationsHeader from 'components/views/Locations/LocationsHeader';

import { usePaginatedLocations } from 'api/v2/locations';

import locationsActions from 'store/actions/Locations';

const SandSites = ({ clearStore }) => {
  useEffect(() => () => clearStore(), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, _setFilter] = useState({ includeDeleted: false });
  const setFilter = (value, name) => {
    setPage(1);
    _setFilter({ ...filter, [name]: value });
  };
  const debouncedFilters = useDebounce(filter, 500);

  const { data, isLoading } = usePaginatedLocations({ page, pageSize, ...debouncedFilters });


  return (
    <div>
      <LocationsHeader />
      <Authorize
        abilityPermissions={[{ resource: 'Locations', permissions: ['read'] }]}>
        <Row>
          <Col lg={6}>
            <Block float modifiers={['no-padding']}>
              <div className="ibox-content">
                <Filter filter={filter} setFilter={setFilter} />
                <Pagination
                  withLimit
                  onPageChange={newPage => setPage(newPage.currentPage)}
                  onLimitChange={limit => {
                    setPageSize(limit);
                    setPage(1);
                  }}
                  currentLimit={pageSize}
                  currentPage={page}
                  totalPages={data?.totalPages ?? 10}
                />
                <Row className="sandsiteContainer">
                  <LocationsList
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    locations={data?.locations ?? []}
                    isLoading={isLoading}
                    totalPages={data?.totalPages ?? 10}
                    setPageSize={setPageSize}
                    setFilter={setFilter}
                  />
                </Row>
              </div>
            </Block>
          </Col>
          <Col lg={6}>
            <Block float title="LOCATION">
              <LocationsMap
                locations={data?.locations ?? []}
              />
            </Block>
          </Col>
        </Row>
      </Authorize>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  clearStore: () => dispatch(locationsActions.clearLocationStore()),
});


export default connect(
  null,
  mapDispatchToProps,
)(SandSites);
