/**
 * Get validation message from Joi validation error res
 * @param {import("axios").AxiosError} error
 * @returns {string}
 */
export const getValidationMessage = error => {
  const errorObj = error.response.data;
  if (errorObj.name === 'ValidationError') {
    // Get context message from validation error
    return errorObj.details.body[0].context?.message ??
    errorObj.details.body[0]?.message;
  }

  // Return message if exists
  return errorObj?.message ?? '';
};
