import { useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';

import { useCustomerDetails } from 'api/v2/customers';

import targetValue from 'utils/targetValue';

const initialBillingTermsState = {
  dueDays: '',
  billingFrequency: '',
  bulkInvoiceEnabled: false,
  packetInvoiceEnabled: false,
  csvInvoiceEnabled: false,
  nonBillable: false,
  billTo: '',
  approvalsRequired: [],
  bulkInvoiceTemplateId: 1,
  billableOperationTypeStatus: [],
}


const AddBillingTermsFormReducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        ...action.data
      }
    }
    case 'set_value': {
      return {
        ...state,
        [action.name]: action.value
      }
    }
    case 'update_job_op_value': {
      if (!state.billableOperationTypeStatus?.length) {
        return state;
      }

      const billableOperationTypeStatus = cloneDeep(state.billableOperationTypeStatus);
      const opValue = billableOperationTypeStatus.find(op => op.operationType === action.value);
      opValue.billableStatus = Number(!opValue.billableStatus);

      return {
        ...state,
        billableOperationTypeStatus
      }
    }
    default: {
      return state;
    }
  }
}

export const useAddBillingTermsForm = (customerId) => {
  const { data: details, isLoading } = useCustomerDetails(customerId);
  const [formState, dispatch] = useReducer(AddBillingTermsFormReducer, initialBillingTermsState);

  useEffect(() => {
    if (details?.billableOperationTypes && details?.approvalsRequired && !isLoading) {
      const billableOperations = details.billableOperationTypes.map(type => ({
        ...type,
        billableStatus: false
      }));

      dispatch({
        type: 'init',
        data: {
          billableOperationTypeStatus: billableOperations,
          approvalsRequired: details.approvalsRequired
        }
      });
    }
  }, [details, isLoading]);

  const handlers = {
    setValue: (e, name) => dispatch({ type: 'set_value', name, value: targetValue(e) }),
    updateJobOpValue: (value) => dispatch({ type: 'update_job_op_value', value })
  }

  return {
    formState,
    handlers
  }
}
