import moment from 'moment';

export const modifyDueDate = (data, action, days) => {
  switch (action) {
    case 'add':
      return moment(data).add(days, 'days');
    case 'subtract':
      return moment(data).subtract(days, 'days');
    default:
      break;
  }
};
