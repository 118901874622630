import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-switch';
import ToolTip from '../ToolTip';

import Icon from '../Icon';
import Button from '../Button';
import UIDatePicker from '../UIDatePicker';
import Select from '../Select';
import Input from '../Input';
import Label from '../Label';

const FilterContext = React.createContext();

const Filter = ({
  children,
  onUpdate = null,
  active = [],
  manualUpdate = false,
  filterVisible,
}) => {
  const [activeFilters, setActiveFilters] = useState(active);
  const [appliedFilters, setAppliedFilters] = useState({
    filters: [],
    trigger: false,
  });
  const [filterVisibility, setFilterVisibility] = useState(
    filterVisible || false,
  );

  useEffect(() => {
    if (onUpdate && appliedFilters.trigger) {
      onUpdate(activeFilters);
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (!manualUpdate) {
      setAppliedFilters({ filters: activeFilters, trigger: true });
    }
  }, [activeFilters]);

  useEffect(() => {
    setActiveFilters(active);

    if (!appliedFilters.trigger) {
      setAppliedFilters({ filters: active, trigger: false });
    }
  }, [active]);

  const setFilter = (
    filterKey,
    type = null,
    value = null,
    label = null,
    onFilter = null,
  ) => {
    const removedFilter = activeFilters.filter(
      filter => filter.key !== filterKey,
    );

    if (!value) return setActiveFilters(removedFilter);

    return setActiveFilters([
      ...removedFilter,
      { key: filterKey, value, type, label, onFilter },
    ]);
  };

  const removeFilter = filterKey => {
    const removedFilter = activeFilters.filter(
      filter => filter.key !== filterKey,
    );
    setActiveFilters(removedFilter);
    if (removedFilter.length > 0) {
      setAppliedFilters({ filters: removedFilter, trigger: true });
    } else {
      setAppliedFilters({ filters: [], trigger: false });
      onUpdate([]);
    }
  };

  return (
    <FilterContext.Provider value={{ setFilter, activeFilters }}>
      <div className="filters">
        <div className="filters__top-row">
          <span className="filters__active">
            Filters:
            {(appliedFilters.filters.length > 0 && (
              <>
                {appliedFilters.filters.map(({ key, label }) => (
                  <Label key={key} type="filter">
                    {label}
                    <Icon
                      icon="close"
                      onClick={() =>
                        manualUpdate ? removeFilter(key) : setFilter(key)
                      }
                    />
                  </Label>
                ))}
                <Button
                  theme="small"
                  onClick={() => {
                    setActiveFilters([]);
                    setAppliedFilters({ filters: [], trigger: false });
                    onUpdate([]);
                  }}>
                  Clear All
                </Button>
              </>
            )) || <strong> None</strong>}
          </span>

          <ToolTip title="Open/Close Filters" placement="left">
            <Button
              theme="small"
              className="filter-toggle button--small--square"
              onClick={() => setFilterVisibility(!filterVisibility)}
              inverse={filterVisibility}>
              <Icon icon="filter" />
            </Button>
          </ToolTip>
        </div>
        <div
          className={`filters__form-row ${filterVisibility ? 'filters__form-row--open' : ''
            }`}>
          <form className="filters__form" autoComplete='off' onSubmit={() => 'applyFilters'}>
            {children}
          </form>
          {manualUpdate && (
            <Button
              theme="small"
              onClick={() => {
                if (activeFilters.length > 0) {
                  setAppliedFilters({ filters: activeFilters, trigger: true });
                } else {
                  setAppliedFilters({ filters: [], trigger: false });
                  onUpdate([]);
                }
              }}>
              Apply Filters
            </Button>
          )}
        </div>
      </div>
    </FilterContext.Provider>
  );
};

export const FilterInput = ({ filterKey, filterLabel = null, ...params }) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState('');

  const updateFilter = ({ target: { value } }) => {
    const typedValue = params.type === 'number' ? parseInt(value, 10) : value;
    return setFilter(filterKey, params.type, typedValue, filterLabel);
  };

  useEffect(() => {
    if (activeFilters.find(item => item.key === filterKey)) {
      setValue(activeFilters.find(item => item.key === filterKey).value);
    } else {
      setValue('');
    }
  }, [activeFilters]);

  return (
    <div
      className={`filters__item ${params.size ? `filters__item--${params.size}` : ''
        }`}>
      <Input
        {...params}
        value={currentValue}
        onChange={updateFilter}
        testSelector="filter_item_input"
      />
    </div>
  );
};

export const FilterSelect = ({
  filterKey,
  options,
  labelKey = 'label',
  valueKey = 'value',
  type = null,
  exact = false,
  filterLabel = null,
  filterType = null,
  onFilter = null,
  ...params
}) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState([]);

  const updateFilter = value => {
    if (params.isMulti) {
      const typedValues = value.map(item =>
        type === 'number' ? parseInt(item.value, 10) : item.value,
      );
      let typeSelect = type;

      if (type === 'array') {
        typeSelect = exact ? 'arrayEvery' : 'arraySome';
      } else {
        typeSelect = exact ? 'multipleEvery' : 'multipleSome';
      }

      return setFilter(
        filterKey,
        typeSelect,
        value.length > 0 ? typedValues : null,
        filterLabel,
        onFilter,
      );
    }

    return setFilter(
      filterKey,
      type,
      type === 'number' ? parseInt(value, 10) : value,
      filterLabel,
      onFilter,
    );
  };

  const filteredOptions = options.map(item => ({
    value: item[valueKey],
    label: item[labelKey],
  }));

  useEffect(() => {
    if (activeFilters.find(item => item.key === filterKey)) {
      if (params.isMulti) {
        setValue(
          filteredOptions.filter(optionItems =>
            activeFilters
              .find(item => item.key === filterKey)
              .value.includes(optionItems.value),
          ),
        );
      } else {
        setValue(
          filteredOptions.find(
            optionItems =>
              activeFilters.find(item => item.key === filterKey).value ===
              optionItems.value,
          ),
        );
      }
    } else {
      setValue([]);
    }
  }, [activeFilters]);

  return (
    <div className="filters__item">
      <Select
        {...params}
        value={currentValue}
        options={filteredOptions}
        onChange={selection =>
          updateFilter(params.isMulti ? selection : selection.value)
        }
        testSelector="filter_current_value_select"
      />
    </div>
  );
};

export const FilterDatePicker = ({
  filterKey,
  filterLabel = null,
  range = null,
  isDateTimePicker = false,
  isTimePicker = false,
  timeFormat = true,
  isSimpleDatePicker = false,
  checkFormat = () => { },
  ...params
}) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState([]);

  useEffect(() => {
    if (range) {
      if (activeFilters.find(item => item.key === `${range}-${filterKey}`)) {
        setValue(
          activeFilters.find(item => item.key === `${range}-${filterKey}`)
            .value,
        );
      } else {
        setValue(null);
      }
    } else if (activeFilters.find(item => item.key === filterKey)) {
      setValue(activeFilters.find(item => item.key === filterKey).value);
    } else {
      setValue(null);
    }
  }, [activeFilters]);

  const updateFilter = value => {
    if (range) {
      return setFilter(`${range}-${filterKey}`, 'range', value, filterLabel);
    }

    return setFilter(filterKey, 'date', value, filterLabel);
  };

  return (
    <div
      className={`filters__item ${params.size ? `filters__item--${params.size}` : ''
        }`}>
      <UIDatePicker
        onAccept={item => {
          updateFilter(item);
          checkFormat(item);
        }}
        value={currentValue}
        data-testid="shared-filter_update_dp"
        id="shared-filter_update_dp"
        name="shared-filter_update_dp"
        isDateTimePicker={isDateTimePicker}
        isTimePicker={isTimePicker}
        timeFormat={timeFormat}
        isSimpleDatePicker={isSimpleDatePicker}
        {...params}
      />
    </div>
  );
};

export const FilterToggle = ({
  filterKey,
  filterLabel = null,
  onFilter,
  ...params
}) => {
  const [checked, setChecked] = useState(false);
  const { setFilter } = useContext(FilterContext);
  const updateFilter = value => {
    setChecked(value);
    return setFilter(filterKey, 'bool', value, filterLabel, onFilter);
  };

  return (
    <div className="filters__item">
      <label className="filter-toggle">
        <span className="filter-toggle__left-label">All</span>
        <Toggle
          checked={checked}
          onChange={updateFilter}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
          onColor="#6fc6d9"
          offColor="#5c5f65"
          height={20}
          width={40}
        />
        <span className="filter-toggle__right-label">Demurrage</span>
      </label>
    </div>
  );
};

Filter.propTypes = {
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.string,
  ]).isRequired,
  /** onUpdate function takes in (filterData)  */
  onUpdate: PropTypes.func,
  /** active array */
  active: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  ),
  /** manualUpdate boolean */
  manualUpdate: PropTypes.bool,
};

Filter.defaultProps = {
  onUpdate: undefined,
  active: [],
  manualUpdate: false,
};

export default Filter;
