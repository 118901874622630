const initialStateSandSiteDetails = {
  isLoadedSandSiteDetails: false,
  sandSite: [],
  sandSiteId: 0,
};

export const sandSiteDetails = (
  state = initialStateSandSiteDetails,
  action,
) => {
  switch (action.type) {
    case 'SAND_SITE_DETAILS_INIT_SUCCESS':
      return {
        ...state,
        isLoadedSandSiteDetails: true,
        sandSite: action.data,
        sandSiteId: action.data.sandSiteDetails[0].id,
      };
    case 'SAND_SITE_DETAILS_INIT_FAILURE':
      return state;
    case 'SAND_SITE_INIT_CLEAR':
      return initialStateSandSiteDetails;
    default:
      return state;
  }
};
