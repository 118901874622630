import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import { useJobCommodities } from 'api/v2/commodities';

/**
 *
 * @param {{
 *  storageGroups: import('.').StorageGroup;
 * }} props
 */
const StorageGroupStatus = ({ storageGroup }) => {
  const unitsUnloading = useMemo(
    () =>
      storageGroup.units
        .filter(unit => unit.flowStatus < 0)
        .map(unit => unit.id),
    [storageGroup.units],
  );
  const { data: commodities } = useJobCommodities(storageGroup.jobId)
  const commodity = useMemo(() => {
    if (!storageGroup?.units.length || !commodities?.length) {
      return {}
    }

    return commodities?.find(c => c.id === storageGroup.units[0].commodityTypeId)
  }, [commodities, storageGroup.units]);
  const unloading = Boolean(unitsUnloading.length);

  const unitIdRef = useRef();

  const copyToClipBoard = () => {
    const unitId = unitIdRef.current?.innerText;
    navigator.clipboard.writeText(unitId);
  };

  return (
    <div key={`storage-group-${storageGroup.storageId}`}>
      <Row style={{ paddingBottom: '15px' }}>
        <Col md={4} sm={4} lg={4}>
          <h4>
            {storageGroup.name}{' '}
            {Boolean(storageGroup.details?.isDeleted) && (
              <span className="font-bold text-danger">
                ({`${storageGroup.details?.deletedBy?.name ?? 'System'}`} Discontinued)
              </span>
            )}
            < br />
            ID: <text ref={unitIdRef}>{storageGroup.externalId}</text>{' '}
            <Button onClick={copyToClipBoard} theme="small" colour="white">
              <Icon icon="copy" />
            </Button>
          </h4>
        </Col>
        {storageGroup.externalProvider !== 'manual' && (
          <Col md={4} sm={4} lg={4}>
            <div style={{ width: '100%' }}>
              <span style={{ paddingRight: '37px' }}>Sync Status:</span>
              <span
                className={storageGroup.active ? 'greenFlash' : 'redStatic'}>
                &nbsp;
                {storageGroup.active ? '• Online' : '• Offline'}
              </span>
            </div>
            <div style={{ width: '100%' }}>
              <span style={{ paddingRight: '13px' }}>Location Status:</span>
              <span className="greenFlash">&nbsp;• Active</span>
            </div>
            <div style={{ width: '100%' }}>
              <span style={{ paddingRight: '5px' }}>Unloading Status:</span>
              <span className={unloading ? 'greenFlash' : 'yellowStatic'}>
                &nbsp;
                {unloading ? '• Unloading' : '• Standby'}
              </span>
            </div>
          </Col>
        )}
        <Col md={4} sm={4} lg={4}>
          <div style={{ width: '100%', fontSize: '15px' }}>
            <span>Last Sync:</span>
            <span>
              &nbsp;
              {moment
                .utc(storageGroup.lastSyncTimestamp)
                .local()
                .format('LLL')}
            </span>
          </div>
          <div style={{ width: '100%', fontSize: '15px' }}>
            <span>Total Downhole:</span>
            <span>
              &nbsp;
              {storageGroup?.totalDownhole?.toLocaleString() ?? 0} {commodity?.unitOfMeasureName}
            </span>
          </div>
          <div style={{ width: '100%', fontSize: '15px' }}>
            {unloading && (
              <>
                <span>Unloading:</span>
                <span>
                  {' '}
                  {unloading
                    ? ` ${unitsUnloading.length > 1 ? 'Containers' : 'Container'
                    }
                ${unitsUnloading.join(', ')} `
                    : ''}
                </span>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div >
  );
};
export default StorageGroupStatus;
