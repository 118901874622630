import React, { useEffect, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';

const EditSandTicket = ({
  init,
  showModalEditTicket,
  closeEditTicket,
  editSandTicket,
  modalEditST,
  setInputValue,
  sandTicketTypes,
}) => {
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    sand_ticket_type: sandTicketType,
    ticket_number: ticketNumber,
  } = modalEditST;

  const sandTicketTypesList = filterOptions(
    sandTicketTypes,
    null,
    null,
    'sandTicketTypeId',
  );

  const saveDisabled = useMemo(
    () => !sandTicketType || !ticketNumber?.trim().length,
    [sandTicketType, ticketNumber],
  );

  return (
    <Modal
      style={{ top: `${20}%` }}
      className="modal-container"
      show={showModalEditTicket}
      onHide={closeEditTicket}>
      <form onSubmit={e => editSandTicket(e, closeEditTicket)}>
        <Modal.Header>
          <Modal.Title as="h3">Edit Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                onChange={e => setInputValue(e, 'ticket_number')}
                value={ticketNumber}
                label="Ticket Number"
                required
                testSelector="order-details_sand-tickets_edit_number_input"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Select
                label="Ticket Type"
                onChange={item => setInputValue(item.value, 'sand_ticket_type')}
                options={sandTicketTypesList}
                value={sandTicketTypesList.find(
                  item => item.value === sandTicketType,
                )}
                required
                testSelector="order-details_sand-tickets_edit_type_select"
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeEditTicket}
            colour="white"
            testSelector="order-details_sand-tickets_edit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="order-details_sand-tickets_edit_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalEditST: state.orderDetails.modalEditST,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.initSandTicket()),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  editSandTicket: (e, closeEditTicket) => {
    e.preventDefault();
    dispatch(actions.editSandTicket(closeEditTicket));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSandTicket);
