import React, { useEffect } from 'react';
import moment from 'moment';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import { useLocations } from 'api/v2/locations';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Label from 'components/shared/Label';
import { trailerLocation } from 'components/globalInfo/trailerLocation';

const History = ({
  getVendors,
  showHistory,
  closeHistory,
  history,
  isLoadedEquipmentHistory,
  sandTypes,
  isLoadedVendors,
  vendors,
}) => {
  useEffect(() => {
    getVendors();
  }, [getVendors]);

  const { data: acidFacilities } = useLocations({ type: 5 });
  const getLocationName = location => {
    if (location.location_type !== 5) {
      return location.locationName
    }

    return acidFacilities.find(facility => facility.id === location.location_id)?.name ?? 'unknown'
  }

  return (
    <Modal className="modal-container" show={showHistory} onHide={closeHistory}>
      <form className="m-t" role="form">
        <Modal.Header>
          <h4 className="modal-title">History</h4>
        </Modal.Header>
        <Modal.Body>
          <div
            id="vertical-timeline"
            className="vertical-container dark-timeline ">
            {isLoadedVendors &&
              isLoadedEquipmentHistory &&
              history.map(item => (
                <div key={item.id} className="vertical-timeline-block">
                  <div className="vertical-timeline-icon navy-bg">
                    <Icon icon="location-arrow" />
                  </div>

                  <div className="vertical-timeline-content">
                    <div className="col-md-12 text-left">
                      <strong style={{ fontSize: 14 }}>
                        {trailerLocation[item.location_type]
                          ? trailerLocation[item.location_type].name
                          : ''}
                        /
                      </strong>
                      <span>
                        <span
                          className="text-navy"
                          ui-sref="jobs.wellsiteDetails({id:item.location_id})"
                          style={{ cursor: 'pointer' }}>
                          {getLocationName(item)}
                        </span>
                      </span>
                      {item.order_id != null && (
                        <span
                          ui-sref="jobs.breadcrumb({id:item.order_id})"
                          style={{ cursor: 'pointer' }}>
                          - Order #{item.order_id}
                        </span>
                      )}
                      <span className="vertical-date float-end">
                        <small>
                          {moment(item.created_at).format('YYYY-MM-DD hh:mm A')}
                        </small>
                      </span>

                      {item.status == 3 && (
                        <Label style={{ marginLeft: 15 }} type="primary">
                          Empty
                        </Label>
                      )}
                      {item.status == 1 && item.locationType != 3 && (
                        <Label style={{ marginLeft: 15 }} type="success">
                          Staged
                        </Label>
                      )}
                      {item.status == 1 && item.locationType == 3 && (
                        <Label style={{ marginLeft: 15 }} type="success">
                          Full
                        </Label>
                      )}
                      {item.status == 2 && (
                        <Label style={{ marginLeft: 15 }} type="warning">
                          Unloading
                        </Label>
                      )}
                      {item.status == 3 && item.location_type == 3 && (
                        <Label style={{ marginLeft: 15 }} type="info">
                          En route to Origin
                        </Label>
                      )}
                      {item.status == 1 && item.location_type == 3 && (
                        <Label style={{ marginLeft: 15 }} type="info">
                          En route to Destination
                        </Label>
                      )}
                      <br />
                    </div>
                    {item.status != 3 && (
                      <div className="col-md-4 col-xs-4">
                        {sandTypes
                          .filter(sand => sand.id == item.sand_type)
                          .map(i => (
                            <span key={i.id}>
                              <strong>Commodity: </strong>
                              {i.name}
                            </span>
                          ))}
                        <br />
                      </div>
                    )}
                    {item.status != 3 && (
                      <div className="col-md-4 col-xs-4">
                        {vendors
                          .filter(vendor => vendor.id == item.sand_vendor)
                          .map(i => (
                            <span key={i.id}>
                              <strong>Vendor: </strong> {i.name}
                            </span>
                          ))}
                      </div>
                    )}
                    {item.status != 3 && (
                      <div className="col-md-4 col-xs-4">
                        <span>
                          <strong>Quantity: </strong>
                          {item.weight}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeHistory}
            colour="white"
            testSelector="equipments_history_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  history: state.equipmentTrailers.equipmentHistory.history,
  isLoadedEquipmentHistory:
    state.equipmentTrailers.equipmentHistory.isLoadedEquipmentHistory,
  sandSites: state.lookUp.sandSites,
  wellSites: state.lookUp.wellSites,
  sandTypes: state.lookUp.sandTypes,
  isLoadedVendors: state.resourceReducer.vendors.isLoadedVendors,
  vendors: state.resourceReducer.vendors.vendors,
});

const mapDispatchToProps = dispatch => ({
  getVendors: () => dispatch(resourceActions.getVendors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(History);
