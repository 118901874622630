import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { switchDay } from 'components/views/Locations/TimingModal/helpers';

const TimingList = ({ timing }) => {
  const timingEntries = [];
  for (let i = 0; i < 7; i += 1) {
    if (!timing) {
      break;
    }
    const entries = timing.filter(t => t.day === i);
    if (!entries.length) {
      timingEntries.push({
        open_timing: moment('00:00:00', 'HH:mm:ss').format('hh:mm A'),
        close_timing: moment('23:59:00', 'HH:mm:ss').format('hh:mm A'),
        day: i 
      });
    } else if (entries.some(e => e.closed)) {
      timingEntries.push({
        open_timing: "CLOSED",
        close_timing: "CLOSED",
        day: entries[0].day
      })
    } else {
      timingEntries.push(...entries.map(entry => ({
        open_timing: moment(entry.open_timing, 'HH:mm:ss').format('hh:mm A'),
        close_timing: moment(entry.close_timing, 'HH:mm:ss').format('hh:mm A'),
        day: entry.day
      })))
    }
  }

  return (
    <div>
      <div className="modal-header modal-bg">
        <h4 className="modal-title font-noraml">Timing</h4>
      </div>
      <div className="modal-body">
        <div className="ibox-content">
          <div className="table-responsive">
            <table className="table table--striped table--hover">
              <thead>
                <tr>
                  <th>Days</th>
                  <th>Open</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                {timing ? (
                    _.sortBy(timingEntries, 'open')
                    .map((item, i) => (
                      <tr key={`day-${item.day}-${i}`}>
                        <td>{timingEntries[i - 1]?.day !== item.day && switchDay(item.day)}</td>
                        <td>
                          {item.open_timing}
                        </td>
                        <td>
                          {item.close_timing}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>All Days</td>
                    <td>12:00 AM</td>
                    <td>11:59 PM</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimingList