export const chatTemplates = (type, member) => {
  switch (type) {
    // case 1:
    //   return `Completion code for order #${member.orderId} is ${member.code}`;
    case 2:
      return `Hello Sir, we kindly ask you to reload a clear picture of your origin ticket under order #${
        member.lastOrderId
      }`;
    case 3:
      return `Hello Sir, we kindly ask you to reload a clear picture of your destination ticket under order #${
        member.lastOrderId
      }`;
    case 4:
      return `Hello Sir, I\'m writing you regarding the order #${
        member.lastOrderId
      }. Could you please tell us what is Sandsite demurrage reason? Thanks!`;
    case 5:
      return `Hello Sir, I\'m writing you regarding the order #${
        member.lastOrderId
      }. Could you please tell us what is Wellsite demurrage reason? Thanks!`;
  }
};
