import React, { useEffect, useState } from 'react';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Label from 'components/shared/Label';
import { Col, Row } from 'react-bootstrap';
import noAvatar from '../../../../../public/img/no_avatar.jpg';

const UserList = ({
  users,
  setFilterValue,
  setFilterSelect,
  selectUser,
  filter: { email, role },
  roleOptions,
}) => {
  const [selectRoleOptions, setSelectRoleOptions] = useState([]);
  const [showDeleted, turnDeleted] = useState(false);
  const filteredUsers = showDeleted ? users : users.filter(i => !i.isDeleted);

  useEffect(() => {
    if (roleOptions && roleOptions.length) {
      const tmp = [...roleOptions];
      tmp.unshift({ value: '', label: 'All Roles' });
      setSelectRoleOptions(tmp);
    }
  }, [roleOptions]);

  const renderUserItem = user => (
    <tr onClick={() => selectUser(user)} key={`user-${user.id}`}>
      <td className="client-avatar">
        {user.profile.profileUrl && user.profile.profileUrl.length ? (
          <img
            src={user.profile.profileUrl}
            width="28"
            height="28"
            className="img-rounded"
            alt="avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = noAvatar;
            }}
          />
        ) : (
          <img src={noAvatar} alt="avatar" />
        )}
      </td>
      <td>
        <a data-bs-toggle="tab" className="client-link">
          <span>{user.profile.firstName} </span>
          {user.profile.lastName}
        </a>
      </td>

      <td>
        <Icon icon="envelope" />
        {user.email}
      </td>
      <td>{user.profile.phoneNumber}</td>
      <td className="client-status">
        <span>
          {user.isVerified ? (
            <Label
              style={{ marginLeft: 0, marginRight: 10, marginBottom: 5 }}
              className="float-start"
              type="info"
            >
              verified
            </Label>
          ) : (
            <Label
              style={{ marginLeft: 0, marginRight: 10, marginBottom: 5 }}
              className="float-start"
              type="warning"
            >
              not verified
            </Label>
          )}
          {Boolean(user.isDeleted) && (
            <Label
              style={{ marginLeft: 0 }}
              className="float-start"
              type="red"
            >
              Deleted
            </Label>
          )}
        </span>
      </td>
    </tr>
  );

  return (
    <Col sm={8}>
      <div className="ibox">
        <div className="ibox-content">
          <Row
            className="input-group "
            style={{
              width: '100%',
              float: 'none',
            }}>
            <Col md={6} className="form-group has-feedback">
              <Input
                onChange={e => setFilterValue(e, 'email')}
                value={email}
                label="Filter users by email"
                testSelector="users_list_filter-by-mail_input"
              />
            </Col>
            <Col md={3} className="form-group has-feedback">
              <Select
                placeholder="Role"
                onChange={item => setFilterSelect(item.value, 'role')}
                defaultValue={selectRoleOptions.find(
                  item => item.value === role.toString(),
                )}
                options={selectRoleOptions}
                testSelector="users_list_role-filter_select"
              />
            </Col>
            <Col
              md={3}
              className="form-group has-feedback"
              style={{ marginTop: '15px', textAlign: 'center' }}>
              <Input
                type="checkbox"
                testSelector="view-po_po_is-active_input"
                isChecked={showDeleted}
                onChange={e => turnDeleted(e.target.checked)}
                label="Deleted users"
              />
            </Col>
          </Row>
          <div className="clients-list">
            <div
              className="table-responsive"
              style={{
                overflowY: 'scroll',
                height: 600,
              }}>
              <table className="table table--striped table--hover">
                <thead>
                  <tr>
                    <th />
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{filteredUsers.map(renderUserItem)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default UserList;
