const init = {
  steps: [],
  isLoading: false,
  hasNotarizations: true,
};

export default (state = { ...init }, action) => {
  switch (action.type) {
    case 'NOTARIZATION_GET_REPORT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'NOTARIZATION_GET_REPORT_SUCCESS':
      return {
        ...state,
        steps: action.report.steps,
        isLoading: false,
        hasNotarizations: true,
      };
    case 'NOTARIZATION_GET_REPORT_FAIL':
      return {
        ...state,
        hasNotarizations: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
