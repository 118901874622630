import swal from 'bootstrap-sweetalert';
import { search } from 'services/searchResult/service';
import { history } from 'helpers/history';

const getSearchResultData = pathname => (dispatch, getState) => {
  const request = () => ({ type: 'SEARCH_RESULT_GET_DATA_REQUEST' });
  const success = (data, value) => ({
    type: 'SEARCH_RESULT_GET_DATA_SUCCESS',
    data,
    pathname: value,
  });

  const {
    search: { query, itemsPerPage },
    pagination: {
      pagination: { currentPage },
    },
  } = getState();
  const data = {
    query,
    itemsPerPage,
    page: currentPage,
  };
  dispatch(request());
  search
    .getSearchData(data)
    .then(response => {
      const { data: responseData } = response.data;
      if (responseData.results.length === 1 && currentPage === 1) {
        switch (responseData.results[0].type) {
          case 1:
            history.push(
              `/${pathname.split('/')[1]}/order-details/${
                responseData.results[0].id
              }`,
            );
            break;
          case 2:
            history.push(
              `/${pathname.split('/')[1]}/job-details/${
                responseData.results[0].id
              }`,
            );
            break;
          case 3:
            history.push(
              `/${pathname.split('/')[1]}/carrier-details/${
                responseData.results[0].id
              }`,
            );
            break;
          case 4:
            history.push(
              `/${pathname.split('/')[1]}/customer-details/${
                responseData.results[0].id
              }`,
            );
            break;
          case 5:
            history.push(
              `/${pathname.split('/')[1]}/driver-details/${
                responseData.results[0].id
              }`,
            );
            break;
          default:
            break;
        }
      }
      dispatch(success(responseData, pathname));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};
const clearState = () => ({ type: 'SEARCH_RESULT_CLEAR_STATE' });
const setQueryParam = query => ({
  type: 'SEARCH_RESULT_SET_QUERY',
  query,
});

const actionsSearch = {
  getSearchResultData,
  clearState,
  setQueryParam,
};

export default actionsSearch;
