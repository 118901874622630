import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Authorize, { authorize } from 'components/common/Authorize';
import settingsActions from 'store/actions/AdminSettings';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { Card, Row } from 'react-bootstrap';
import { initSettings } from './helpers';

const ChooseSettings = ({
  settings,
  getSettings,
  isLoadedSettings,
  applySettings,
  clearState,
}) => {
  const [data, initData] = useState({});

  useEffect(() => {
    clearState();
    getSettings();
  }, []);

  useEffect(() => {
    if (settings.length) {
      initData(initSettings(settings));
    }
  }, [settings]);

  if (!isLoadedSettings) {
    return <Loader />;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Choose Settings</Card.Title>
      </Card.Header>
      <Card.Body>
        <form
          onSubmit={e =>
            applySettings(e, {
              ...data,
              expireTime: data.sessionDuration,
              payments: Boolean(data.enablePayments),
            })
          }>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    maxDistance: value,
                  }))
                }
                value={data.maxDistance}
                label="Max distance (mi)"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_max-distance_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    driverTimeout: value,
                  }))
                }
                value={data.driverTimeout}
                label="Driver timeout (minutes)"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_driver-timeout_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    hour: value,
                  }))
                }
                value={data.hour}
                label="Time before load is queued (Hours)"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_time-before-load-hours_input"
              />
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    minute: value,
                  }))
                }
                value={data.minute}
                label="Time before load is queued (Minutes)"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_time-before-load-min_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    pricePerMile: value,
                  }))
                }
                value={data.pricePerMile}
                label="Price per mile, starting from 351 miles"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_price-per-mile_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    carrierPercentage: value,
                  }))
                }
                value={data.carrierPercentage}
                label="Carrier percentage"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_carrier-percentage_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    sessionDuration: value,
                  }))
                }
                value={data.sessionDuration}
                label="Session duration (minutes)"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                required
                testSelector="admin-settings_choose-settings_session-duration_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="checkbox"
                testSelector="admin-settings_choose-settings_enable-payments_input"
                label="Enable Payments"
                isChecked={data.enablePayments}
                onChange={() =>
                  initData(cs => ({
                    ...cs,
                    enablePayments: !cs.enablePayments,
                  }))
                }
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="checkbox"
                label="Enable Twilio calls"
                testSelector="admin-settings_choose-settings_enable-twilio-calls_input"
                isChecked={data.enableCallsForPendingOrders}
                onChange={() =>
                  initData(cs => ({
                    ...cs,
                    enableCallsForPendingOrders: !cs.enableCallsForPendingOrders,
                  }))
                }
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
              />
            </div>
          </Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Admin', permissions: ['update'] },
            ]}>
            <div className="modal-footer" style={{ paging: 0, border: 'none' }}>
              <Button
                type="submit"
                testSelector="admin-settings_settings_apply_btn">
                Apply Settings
              </Button>
            </div>
          </Authorize>
        </form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  settings: state.adminSettings.currentSettings.settings,
  isLoadedSettings: state.adminSettings.currentSettings.isLoadedSettings,
});

const mapDispatchToProps = dispatch => ({
  getSettings: () => {
    dispatch(settingsActions.getSettings());
  },
  applySettings: (e, data) => {
    e.preventDefault();
    dispatch(settingsActions.applySettings(data));
  },
  clearState: () => {
    dispatch(settingsActions.clearCurrentSettingsState());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseSettings);
