import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';

import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import targetValue from 'utils/targetValue';
import { useImportStorageFile } from 'api/sls/storage';
import Icon from 'components/shared/Icon';

const StorageImportModal = ({ jobId, show, handleClose }) => {
  const [file, setFile] = useState();

  const { mutate: uploadFile, isPending } = useImportStorageFile({ jobId });

  const handleSubmit = e => {
    e.preventDefault();
    uploadFile(file, {
      onSuccess: handleClose,
    });
  };

  const renderContent = () => (
    <>
      <Row>
        <p>
          Upload a CSV with the following headers:
          <br />
          <code>storage_id,amount,date</code>
          <br />
          <small>Date should follow a 24h `MM/DD/YY HH:mm` format.</small>
        </p>
      </Row>
      <Row>
        <Input
          type="file"
          onChange={e => setFile(targetValue(e))}
          value={file}
          required
          label="Upload CSV"
          accept=".csv"
          testSelector="storage-input"
        />
      </Row>
      <Row>
        <p>
          <small>
            <span className="input__text__label--required"> *</span> Imported
            data is added to existing records.
          </small>
        </p>
      </Row>
    </>
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title as="h3">Storage Data Import</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>{renderContent()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" colour="white" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={isPending}>
            Upload
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StorageImportWrapper = ({ jobId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      <Button onClick={handleShow}>
        <Icon icon="upload" />
      </Button>

      <StorageImportModal
        jobId={jobId}
        show={showModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default StorageImportWrapper;
