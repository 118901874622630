import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';
import { addPrice } from 'store/actions/Orders';
import { connect } from 'react-redux';

const AddPriceModal = ({ open, onClose, orderId, addPriceFunc }) => {
  const [price, setPrice] = useState(null);
  const [comment, setComment] = useState(null);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineSaveDisabled = () => {
    let disabled = false;

    if (!price || !comment) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };

  useEffect(() => {
    determineSaveDisabled();
  }, [price, comment]);

  const body = (
    <>
      {isFormSubmitted ? (
        <Loader />
      ) : (
        <div className="contest-modal">
          <Input
            label="Price $"
            name="Add Price"
            type="number"
            onChange={priceInput => {
              setPrice(priceInput.target.value);
            }}
            value={price}
            required
            testSelector="reconcile_order-details_add-price_price_input"
          />
          <Input
            label="Comment"
            name="Comment"
            onChange={commentInput => {
              setComment(commentInput.target.value);
            }}
            required
            testSelector="reconcile_order-details_add-price_comment_input"
          />
        </div>
      )}
    </>
  );

  const handleSendAndClose = () => {
    setFormSubmitted(true);
    addPriceFunc(orderId, price, comment, onClose);
    // onClose();
  };
  const footer = (
    <>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="reconcile_order-details_add-price_close_btn">
        Close
      </Button>
      <Button
        onClick={() => handleSendAndClose()}
        disabled={saveDisabled || isFormSubmitted}
        testSelector="reconcile_order-details_add-price_save_btn">
        Save
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Price"
      body={body}
      footer={footer}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  addPriceFunc: (orderId, price, comment, close) =>
    dispatch(addPrice(orderId, price, comment, close)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddPriceModal);
