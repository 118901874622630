import { client } from 'api/client';

export const getDistricts = async () => {
  const { data } = await client.get('v2/district');
  return data.data.district;
};

export const removeDistrict = async (data) =>
  client.delete(`v2/district/${data.districtId}`, { data });

export const editDistrict = async (data) =>
  client.put(`v2/district/${data.id}`, data);

export const addDistrict = async (data) =>
  client.post('v2/district', data);

export const getDistrictDetails = async ({ id }) => {
  const response = await client.get(`v2/district/${id}/details`)
  return response.data.data;
}

export const addDistrictUnit = async (data) =>
  client.post(`v2/district/${data.id}/units`, data);

export const editDistrictUnit = async (data) =>
  client.put(`v2/district/${data.districtId}/units/$${data.unitId}`, data);

export const getDistrictUnits = async ({ id }) => {
  const response = await client.get(`v2/district/${id}/units`)
  return response.data.data.units;
}

export const addDistrictCarrier = async (data) =>
  client.post('v2/carrier/district', data);

export const addCarrierRatioToDistrict = async (data) =>
  client.post(`v2/districts/${data.districtId}/carrier-ratio`, data);

export const editRatio = async (data) =>
  client.post(`v2/district/${data.districtId}/ratio`, data);

export const getNotAddedDistricts = async (carrierId) => {
  const response = await client.get(`v2/district/not-added`, { carrierId });
  return response.data.data.district;
}
