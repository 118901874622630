const ordersDefaultState = {
  collection: [],
  totalItems: 0,
  lastParams: {},
  apiIsLoading: false,
  lastApiCallSucceeded: true,
  comments: [],
  commentsApiLoading: true,
  commentSendLoading: false,
  history: [],
  historyLoading: true,
  addComment: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  disputeReasons: {
    data: null,
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  priceBreakdown: {
    data: {},
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  downloadInvoicePdf: {
    data: null,
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
  addPrice: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  removePrice: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  orderUpdate: {
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
  uploadTicket: {
    input: {
      sandTicketNo: '',
      weight: '',
      driverId: '',
      orderId: '',
      sandTicketType: '',
      containerIds: [],
      url: null,
      crop: {
        x: 10,
        y: 10,
      },
    },
    init: {
      containers: [],
      sandTicketsTypes: [],
      containersPerTruck: 1,
    },
    isLoaded: false,
  },
};

export default (state = ordersDefaultState, data) => {
  switch (data.type) {
    case 'SET_ORDER_BY_ID':
      return {
        ...state,
        collection: [
          ...state.collection.filter(
            order => order.order_id !== data.order.order_id,
          ),
          data.order,
        ],
      };
    case 'ORDERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'ORDERS_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: data.orders,
        totalItems: data.totalItems,
        lastParams: data.lastParams,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'ORDERS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'APPROVE_ORDERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'APPROVE_ORDERS_SUCCEEDED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'APPROVE_ORDERS_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'CONTEST_ORDERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'CONTEST_ORDERS_SUCCEEDED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'CONTEST_ORDERS_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'ORDER_DISPUTE_REASONS_REQUESTED':
      return {
        ...state,
        disputeReasons: {
          ...state.disputeReasons,
          apiIsLoading: true,
        },
      };
    case 'ORDER_DISPUTE_REASONS_SUCCEEDED':
      return {
        ...state,
        disputeReasons: {
          data: data.disputeReasons,
          apiIsLoading: true,
          lastApiCallSucceeded: false,
        },
      };
    case 'ORDER_DISPUTE_REASONS_FAILED':
      return {
        ...state,
        disputeReasons: {
          data: data.disputeReasons,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'CREATE_INVOICE_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'CREATE_INVOICE_SUCCEEDED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'CREATE_INVOICE_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'ORDER_GET_COMMENTS_REQUESTED':
      return {
        ...state,
        commentsApiLoading: true,
      };
    case 'ORDER_GET_COMMENTS_SUCCESS':
      return {
        ...state,
        comments: data.comments,
        commentsApiLoading: false,
      };
    case 'ORDER_GET_COMMENTS_FAILED':
      return {
        ...state,
        commentsApiLoading: false,
      };
    case 'ADD_COMMENT_REQUESTED':
      return {
        ...state,
        addComment: {
          apiIsLoading: true,
        },
      };
    case 'ADD_COMMENT_SUCCEEDED':
      return {
        ...state,
        addComment: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ADD_COMMENT_FAILED':
      return {
        ...state,
        addComment: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ORDER_PRICE_BREAKDOWN_REQUESTED':
      return {
        ...state,
        priceBreakdown: {
          ...state.priceBreakdown,
          apiIsLoading: true,
        },
      };
    case 'ORDER_PRICE_BREAKDOWN_SUCCEEDED':
      return {
        ...state,
        priceBreakdown: {
          data: data.priceBreakdown,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ORDER_PRICE_BREAKDOWN_FAILED':
      return {
        ...state,
        priceBreakdown: {
          ...state.priceBreakdown,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'DOWNLOAD_INVOICE_PDF_REQUESTED':
      return {
        ...state,
        downloadInvoicePdf: {
          ...state.downloadInvoicePdf,
          apiIsLoading: true,
        },
      };
    case 'DOWNLOAD_INVOICE_PDF_SUCCEEDED':
      return {
        ...state,
        downloadInvoicePdf: {
          data: data.downloadInvoicePdf,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'DOWNLOAD_INVOICE_PDF_FAILED':
      return {
        ...state,
        downloadInvoicePdf: {
          data: data.downloadInvoicePdf,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ADD_PRICE_REQUESTED':
      return {
        ...state,
        addPrice: {
          ...state.addPrice,
          apiIsLoading: true,
        },
      };
    case 'ADD_PRICE_SUCCEEDED':
      return {
        ...state,
        addPrice: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ADD_PRICE_FAILED':
      return {
        ...state,
        addPrice: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'REMOVE_PRICE_REQUESTED':
      return {
        ...state,
        removePrice: {
          ...state.removePrice,
          apiIsLoading: true,
        },
      };
    case 'REMOVE_PRICE_SUCCEEDED':
      return {
        ...state,
        removePrice: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'REMOVE_PRICE_FAILED':
      return {
        ...state,
        removePrice: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'UPDATE_ORDER_REQUESTED':
      return {
        ...state,
        orderUpdate: {
          ...state.orderUpdate,
          apiIsLoading: true,
        },
      };
    case 'UPDATE_ORDER_REQUEST_SUCCEEDED':
      return {
        ...state,
        orderUpdate: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'UPDATE_ORDER_REQUEST_FAILED':
      return {
        ...state,
        orderUpdate: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ORDER_HISTORY_REQUESTED':
      return {
        ...state,
        historyLoading: true,
      };
    case 'ORDER_HISTORY_SUCCEEDED':
      return {
        ...state,
        history: data.history,
        historyLoading: false,
      };
    case 'ORDER_HISTORY_FAILED':
      return {
        ...state,
        historyLoading: false,
      };
    case 'ORDER_HISTORY_CLEAR':
      return {
        ...state,
        history: [],
        historyLoading: true,
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_INPUT':
      return {
        ...state,
        uploadTicket: {
          ...state.uploadTicket,
          input: {
            ...state.uploadTicket.input,
            [data.name]: data.value,
          },
        },
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_INIT_DATA':
      return {
        ...state,
        uploadTicket: {
          ...state.uploadTicket,
          init: {
            ...state.uploadTicket.init,
            [data.name]: data.value,
          },
        },
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_REQUEST':
      return {
        ...state,
        uploadTicket: {
          ...state.uploadTicket,
          isLoaded: !state.uploadTicket.isLoaded,
        },
      };
    case 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS':
      return {
        ...state,
        uploadTicket: {
          ...ordersDefaultState.uploadTicket
        },
      };
    default:
      return state;
  }
};
