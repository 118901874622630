import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

const PaginationFirst = ({ disabled, onClick }) => (
  <Button
    theme="square"
    colour='grey'
    onClick={onClick}
    disabled={disabled}
  >
    <Icon icon="double_left" />
  </Button>
);

PaginationFirst.defaultProps = {
  onClick: () => null
}

export default PaginationFirst;
