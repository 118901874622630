import React from 'react';

const Loader = ({ size = 59 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 59 59"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className="loader">
    <defs>
      <path
        d="M22.502.974C10.098.974.041 11.03.041 23.436c0 12.403 10.056 22.458 22.461 22.458 8.92 0 12.412-6.979 15.215-5.463 1.857 1.006 7.243 6.596 7.243 6.596V23.436C44.96 11.029 34.906.974 22.502.974zm-.001 33.887c-6.013 0-10.888-4.875-10.888-10.888s4.875-10.888 10.888-10.888c6.014 0 10.888 4.875 10.888 10.888s-4.874 10.888-10.888 10.888z"
        id="b"
      />
      <filter
        x="-21.1%"
        y="-20.6%"
        width="151.2%"
        height="149.9%"
        filterUnits="objectBoundingBox"
        id="a">
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="3.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24852808 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" transform="translate(5 4)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#1F67A4" xlinkHref="#b" />
      </g>
      <path
        d="M49.896 25.817a22.722 22.722 0 0 0-.21-1.894c-.01-.055-.021-.108-.029-.163-.08-.479-.176-.955-.285-1.427-.018-.075-.033-.149-.052-.224a22.818 22.818 0 0 0-.434-1.545l-.021-.061a22.21 22.21 0 0 0-1.273-3.108l-.031-.06a22.264 22.264 0 0 0-1.762-2.918l-18.298 2.668c6.014 0 10.888 4.875 10.888 10.888s-4.874 10.888-10.888 10.888c-4.398 0-8.177-2.613-9.896-6.366l6.127 17.077.012.003c.592.1 1.187.174 1.784.227.058.006.116.009.174.014.574.047 1.15.073 1.727.076l.073.002c.532 0 1.044-.028 1.538-.074.168-.016.326-.045.489-.065.319-.04.638-.081.942-.138.184-.035.359-.079.539-.119.268-.061.533-.122.789-.193.18-.051.354-.107.529-.163.238-.077.475-.155.703-.24a18.44 18.44 0 0 0 1.606-.692c.206-.102.407-.205.604-.312a16.3 16.3 0 0 0 .994-.567c.121-.072.244-.145.361-.218.202-.127.396-.253.588-.379.093-.061.189-.122.279-.182.263-.176.518-.349.761-.516l.029-.02a.758.758 0 0 1 .035-.025l.688-.469c.074-.049.143-.092.213-.14.164-.107.324-.214.479-.31.088-.055.17-.101.254-.15.133-.079.264-.156.391-.223.088-.047.174-.085.258-.126.193-.095.394-.173.6-.235.183-.054.37-.09.56-.106.045-.003.092-.017.137-.017.064 0 .127.017.191.023.059.007.117.01.176.021.158.034.312.085.457.162.008.004.016.004.023.008 1.857 1.006 7.243 6.596 7.243 6.596V27.436c0-.096-.007-.191-.007-.287a21.119 21.119 0 0 0-.055-1.332z"
        fill="#1A4B77"
      />
      <g>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 27.5 28"
          to="360 27.5 28"
          dur="3s"
          repeatCount="indefinite"
        />
        <path
          d="M42.006 27.622l-3.162-1.616a10.61 10.61 0 0 0-.531-1.971l1.924-2.975a14.3 14.3 0 0 0-2.747-3.587l-3.371 1.102a10.675 10.675 0 0 0-1.772-1.026l-.747-3.473a14.813 14.813 0 0 0-4.469-.585l-1.618 3.164c-.673.113-1.333.292-1.972.535l-2.978-1.934a14.25 14.25 0 0 0-3.578 2.757l1.091 3.372c-.394.56-.738 1.141-1.019 1.767l-3.474.748c-.19.646-.331 1.313-.431 1.998l5.509.725c.748-4.788 5.183-8.131 10.006-7.5 4.822.633 8.236 5.004 7.728 9.821l5.514.721c.082-.677.115-1.36.096-2.041"
          fill="#22ADCF"
        />
        <path
          d="M12.997 28.375l3.164 1.61c.113.676.291 1.338.531 1.979l-1.928 2.974a14.522 14.522 0 0 0 2.749 3.588l3.377-1.103c.556.388 1.141.734 1.767 1.025l.75 3.473c1.45.422 2.957.619 4.467.587l1.618-3.163a12.143 12.143 0 0 0 1.976-.535l2.974 1.932a14.412 14.412 0 0 0 3.578-2.757l-1.094-3.37c.396-.56.738-1.153 1.021-1.776l3.472-.741c.215-.721.371-1.452.471-2.213.008-.075.012-.146.02-.221l-5.515-.721c-.008.073-.01.146-.017.221-.644 4.903-5.138 8.346-10.036 7.705-4.897-.642-8.362-5.126-7.719-10.03.01-.075.028-.146.039-.219l-5.509-.723c-.012.073-.029.144-.038.218-.1.749-.139 1.505-.118 2.26"
          fill="#6FC6D9"
        />
      </g>
    </g>
  </svg>
);

export default Loader;
