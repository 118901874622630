import React, { useMemo, useState } from 'react';
import { convertTime } from 'utils/convertTime';
import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import { useAddOrderComment, useOrderComments } from 'api/v2/orders';
import Loader from 'components/shared/Loader';
import targetValue from 'utils/targetValue';

const Comments = ({ orderId }) => {
  const { data: comments, isLoading } = useOrderComments({ orderId });
  const filteredComments = useMemo(
    () =>
      (comments || []).filter(
        (c, i, array) =>
          i === 0 ||
          c.comment !== 'Driver is Connected.' ||
          array[i - 1].comment !== 'Driver is Connected.',
      ),
    [comments],
  );

  const { mutate: postComment } = useAddOrderComment({
    orderId,
  });

  const [comment, setComment] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    postComment(comment, {
      onSuccess: () => setComment(''),
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderComment = item => (
    <div key={item.comment_id} className="comment-list__comment">
      <div className="comment-list__comment--details">
        <span>@{item.character_type} </span>
        <span className="float-end">
          <Icon icon="clock-o" /> {convertTime(item.created_at)}
        </span>
      </div>
      <div className="comment-list__comment--comment">{item.comment}</div>
    </div>
  );

  const renderCommentsList = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (!filteredComments.length) {
      return <div>There are no comments on this order yet.</div>;
    }
    return (
      <div className="comment-list">
        <ul className="list-group">{filteredComments.map(renderComment)}</ul>
      </div>
    );
  };

  return (
    <>
      <h3>Comments for order</h3>
      {renderCommentsList()}
      <div>
        <Authorize
          abilityPermissions={[
            { resource: 'Orders', permissions: ['update'] },
          ]}>
          <form role="form" onSubmit={handleSubmit}>
            <Input
              onChange={e => setComment(targetValue(e))}
              value={comment}
              label="Write your comment here"
              required
              testSelector="order-details_comments_text_input"
            />
          </form>
        </Authorize>
      </div>
    </>
  );
};
export default Comments;
