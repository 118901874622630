import swal from 'bootstrap-sweetalert';

export const successAlert = (response, text = '') => {
  const message =
    response && response.data && response.data.message
      ? response.data.message
      : 'Success';
  swal(`${text || message} !`, '', 'success');
};

export const errorAlert = (error, text = '') => {
  const message = error
    ? `Reason: ${error}.`
    : 'Reason: unknown. Please try again.';
  swal(`${text || 'Something went wrong'}.`, message, 'error');
};
