import moment from 'moment';
import { combineReducers } from '@reduxjs/toolkit';

const initState = {
  filter: {
    sort: 'id',
    id: '',
    name: '',
    status: 0,
    jobType: -1,
    isDraftJob: 0,
  },
  addJob: {
    name: '',
    customerId: '',
    districtIds: '',
    wellSiteId: '',
    rateTableId: '',
    demurrageTableId: '',
    jobOperationType: undefined,
    equipment: '',
    jobDesign: [],
    stagingSite: '',
    numberOfStages: '',
    targetStagesPerDay: '',
    totalWeightPerJob: '',
    directions: '',
    startDate: moment().add(1, 'minute'),
    endDate: moment().add(1, 'minute'),
    sendCompletionCodeToDriver: false,
    pumpTime: '',
    wirelineTime: '',
    certificates: '',
    sandTicketDesign: '',
    minDriversAmount: 0,
    demurrageAlert: 0,
    messagesAllowed: false,
    phoneNumbers: [{ code: '+1' }],
    storageConfig: [],
    useCommodityPrice: false,
    operatorList: [],
    containersPerTruck: '',
    customerJobId: '',
    customerProjectId: '',
    navId: '',
    billingProfileId: '',
    useBillingProfile: false,
    allIn: 0,
  },
  addJobInitData: {
    step: 1,
    customers: [],
    companyArray: [],
    districts: [],
    certificatesList: [],
    sandTicketTypes: [],
    rateTable: [],
    wellSites: [],
    demmurage: [],
    billingProfiles: [],
  },
  editJob: {
    loaded: false,
    storageConfig: [],
    demurrageAlert: 0,
    additionalOperators: [],
  },
  editJobInitData: {
    sandTicketTypes: [],
    districts: [],
    wellSites: [],
    phoneNumbers: [],
    onsiteStorageTypes: [],
    sandTypes: [],
    billingProfiles: [],
    companyArray: [],
  },
  onsiteStorageTypesData: {
    onsiteStorageTypes: [],
  },
  users: [],
};

const jobs = (state = initState, action) => {
  switch (action.type) {
    case 'JOB_LIST_CLEAR_STATE':
      return initState;
    case 'JOB_LIST_GET_USERS':
      return {
        ...state,
        users: action.users,
      };
    case 'JOB_LIST_SET_STATUS':
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.value,
          isDraftJob: 0,
        },
      };
    case 'JOB_LIST_SET_IS_DRAFT_JOB':
      return {
        ...state,
        filter: {
          ...state.filter,
          status: 0,
          isDraftJob: action.value,
        },
      };
    case 'JOB_LIST_SET_FILTER_VALUE':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.name]: action.value,
        },
      };
    case 'JOB_LIST_SET_ADD_JOB_VALUE':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          [action.name]: action.value,
        },
      };
    case 'ADD_JOB_CHANGE_CUSTOMER_SELECT':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          wellSiteId: '',
          rateTableId: '',
          billingProfileId: '',
          demurrageTableId: '',
        },
      };
    case 'JOB_LIST_CLEAR_ADD_JOB_VALUE':
      return {
        ...state,
        addJob: {
          ...initState.addJob,
          storageConfig: [],
          jobDesign: [],
        },
        addJobInitData: { ...initState.addJobInitData },
      };
    case 'JOB_LIST_CLEAR_EDIT_JOB_VALUE':
      return {
        ...state,
        editJob: initState.editJob,
        editJobInitData: initState.editJobInitData,
      };
    case 'MODAL_ADD_JOB_SAVE_INIT_DATA':
      return {
        ...state,
        addJobInitData: {
          ...state.addJobInitData,
          [action.name]: action.data,
        },
      };
    case 'MODAL_ADD_JOB_SET_STEP':
      return {
        ...state,
        addJobInitData: {
          ...state.addJobInitData,
          step: action.step,
        },
      };
    case 'MODAL_ADD_JOB_SET_VALUE':
      return {
        ...state,
        addJob: {
          ...state.addJob,
          [action.name]: action.value,
        },
      };
    case 'MODAL_EDIT_JOB_SET_VALUE':
      return {
        ...state,
        editJob: {
          ...state.editJob,
          [action.name]: action.value,
        },
      };
    case 'MODAL_EDIT_JOB_SAVE_INIT_JOB':
      return {
        ...state,
        editJob: {
          ...state.editJob,
          ...action.data,
        },
      };
    case 'MODAL_EDIT_JOB_SAVE_INIT_DATA':
      return {
        ...state,
        editJobInitData: {
          ...state.editJobInitData,
          [action.name]: action.data,
        },
      };
    case 'SET_ONSITE_STORAGE_TYPES':
      return {
        ...state,
        onsiteStorageTypesData: {
          ...state.onsiteStorageTypesData,
          onsiteStorageTypes: action.data,
        },
      };
    default:
      return state;
  }
};
const initCertificatesState = {
  isLoaded: false,
  certOn: [],
  certOff: [],
  jobId: '',
  isFullEdit: false,
};
const certificatesModal = (state = initCertificatesState, action) => {
  switch (action.type) {
    case 'JOB_LIST_CERTIFICATES_MODAL_CLEAR':
      return initState;
    case 'JOB_LIST_INIT_CERTIFICATES_MODAL':
      return {
        ...state,
        certOn: action.array.certOn,
        certOff: action.array.certOff,
        jobId: action.jobId,
        isLoaded: true,
        isFullEdit: action.isFullEdit,
      };
    default:
      return state;
  }
};

const initQuickBooksState = {
  isLoaded: false,
  isSyncing: false,
  qbProjectId: '',
  qbCustomerId: '',
  qbLinkUrl: '',
  jobName: '',
  jobId: '',
};
const quickBooksModal = (state = initQuickBooksState, action) => {
  switch (action.type) {
    case 'JOB_LIST_QUICKBOOKS_MODAL_CLEAR':
      return initQuickBooksState;
    case 'JOB_LIST_INIT_QUICKBOOKS_MODAL':
      return {
        ...state,
        isLoaded: true,
        qbProjectId: action.qbProjectId,
        qbCustomerId: action.qbCustomerId,
        qbLinkUrl: action.qbDeepLinkUrl,
        jobName: action.name,
        jobId: action.id,
      };
    case 'MODAL_QUICKBOOKS_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};

export default combineReducers({
  jobs,
  certificatesModal,
  quickBooksModal,
});
