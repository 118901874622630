import React from 'react';
import Content from './Content';
import Header from './Header';

const OrderList = ({ orders, refreshOrder }) => {
  if (!orders.length) {
    return <div>No orders found.</div>;
  }

  return orders.map(order => (
    <div className="ibox ibox-order" key={`order-${order.order_id}`}>
      <Header order={order} refreshOrder={refreshOrder} />
      <Content order={order} />
    </div>
  ));
};
export default OrderList;
