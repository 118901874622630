import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import Authorize, { authorize } from 'components/common/Authorize';
import ServerPagination from 'components/shared/ServerPagination';
import TableList from 'components/shared/TableList';
import { convertTime } from 'utils/convertTime';
import genericSorting from 'store/selectors/genericSorting';
import alerts from 'utils/alerts';
import statuses from 'utils/statuses';
import { findLongestWord } from 'utils/stringFunctions';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import Badge from 'components/shared/Badge';
import { confirmAlert } from 'components/sweetalert';
import { approveOrder } from 'store/actions/Orders';
import ContestModal from 'components/shared/ContestModal';
import Link from 'components/common/Link';
import ReconcileSlidePanel from '../ReconcileSlidePanel';

const ReconcileActionItemsList = ({
  orders,
  totalItems,
  ordering,
  setOrdering,
  pagination,
  setPagination,
  approveOrderFunc,
  equipmentRequired,
  getOrders
}) => {
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [contestModal, setContestModal] = useState(false);
  const orderIds = checkedOrders.map(({ key }) => key);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const sorting = key => {
    if (key === 'status') {
      if (ordering.key === 'review_status') {
        return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
      }

      return setOrdering({ key: 'review_status', isAscending: false });
    }

    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const columns = [
    { key: 'order_id', value: 'ORDER' },
    { key: 'customer_name', value: 'CUSTOMER' },
    { key: 'customer_job_id', value: 'CUSTOMER JOB ID' },
    { key: 'job_name', value: 'JOB' },
    { key: 'carrier_name', value: 'CARRIER' },
    { key: 'truck', value: 'TRUCK NUMBER' },
    { key: 'completed_at', value: 'COMPLETED' },
    { key: 'delivery_price', value: 'DELIVERY' },
    { key: 'additional_price', value: 'ADDITIONAL' },
    { key: 'totalPrice', value: 'TOTAL' },
    { key: 'alerts', value: 'ALERTS' },
    { key: 'status', value: 'STATUS' },
  ];

  const approveContest = param => {
    swal.close();
    setContestModal(param);
  };

  const actionOptions = [
    {
      name: 'approve',
      label: 'Approve',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(approveOrderFunc, orderIds, {
            title: `Are you sure you want to approve these ${orderIds.length} orders?`,
            text: ' ',
          });
        } else {
          approveOrderFunc(orderIds);
        }
      },
    },
    {
      name: 'contest',
      label: 'Contest',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(approveContest, true, {
            title: `Are you sure you want to contest these ${orderIds.length} orders?`,
            text: ' ',
          });
        } else {
          approveContest(true);
        }
      },
    },
  ];

  // const exportOptions = [
  //   {
  //     name: 'excel',
  //     label: 'Excel Workbook',
  //     onClick: () => console.log('Excel Workbook'),
  //     dropdownIcon: 'excel',
  //   },
  //   {
  //     name: 'csv',
  //     label: 'CSV File',
  //     onClick: () => console.log('CSV File'),
  //     dropdownIcon: 'csv',
  //   },
  //   {
  //     name: 'pdf',
  //     label: 'PDF Document',
  //     onClick: () => console.log('PDF Document'),
  //     dropdownIcon: 'pdf',
  //   },
  // ];

  const approvals = order => {
    const approvalList = [
      { id: 6, title: 'Manager' },
      { id: 3, title: 'Carrier' },
      { id: 2, title: 'Customer' },
    ];

    return approvalList.map(approvalId => {
      let tempStatus = '';

      const approved = order.billing.approvals.some(
        someElement => someElement === approvalId.id,
      );

      const pending = approved
        ? false
        : order.billing.approvalsRequired.some(
            sData => sData === approvalId.id,
          );

      if (approved) {
        tempStatus = 'Approved';
      } else if (pending) {
        tempStatus = 'Pending Approval';
      } else {
        tempStatus = 'Not Required';
      }

      return {
        id: approvalId.id,
        status: tempStatus,
        title: approvalId.title,
        pending,
        approved,
      };
    });
  };

  const csvHeaders = columns
    .concat([
      { key: 'formatted_alert', value: 'ALERT DESC' },
      { key: 'formatted_status', value: 'STATUS DESC' }
    ])
    .map(c => {
      c.label = c.value;
      return c;
    });

  const csvOrders = orders?.length ? _.cloneDeep(orders).map(o => {
    o.formatted_alert = o.alerts.map(alert => {
      if (alert === 4096) {
        alert = o.fieldpro_alerts
          .map(item => alerts[alert].reason[item])
          .join(', ');
        return alert;
      }
      alert = alerts[alert].reason;
      return alert;
    });

    o.formatted_status = statuses[o.review_status];
    o.completed_at = convertTime(o.completed_at);
    o.customer_job_id = o.customer_job_id || o.po_reference
    return o;
  }) : [];

  const builtItems = orders.map(order => ({
    key: order.order_id,
    isSelected: selectedOrder && selectedOrder.order_id === order.order_id,
    originalData: order,
    onRowClick: () => setSelectedOrder(order),
    data: {
      order_id: () => (
        <span>
          #
          {authorize({
            abilityPermissions: [{ resource: 'Orders', permissions: ['read'] }],
          }) ? (
            <Link id={order.order_id} to="order-details" target="_blank">
              {order.order_id}
            </Link>
          ) : (
            order.order_id
          )}
        </span>
      ),
      job_name: () => (
        <ToolTip
          title={findLongestWord(order.job_name) > 10 ? order.job_name : ''}
          placement="bottom">
          {`${
            findLongestWord(order.job_name) > 10
              ? order.job_name.substring(0, 10)
              : order.job_name
          }`}
          {findLongestWord(order.job_name) > 10 && '...'}
        </ToolTip>
      ),
      customer_name: () => (
        <ToolTip
          title={
            findLongestWord(order.customer_name) > 10 ? order.customer_name : ''
          }
          placement="bottom">
          {`${
            findLongestWord(order.customer_name) > 10
              ? order.customer_name.substring(0, 10)
              : order.customer_name
          }`}
          {findLongestWord(order.customer_name) > 10 && '...'}
        </ToolTip>
      ),
      customer_job_id: () => order.customer_job_id || order.po_reference,
      carrier_name: () => (
        <ToolTip
          title={
            findLongestWord(order.carrier_name) > 10 ? order.carrier_name : ''
          }
          placement="bottom">
          {`${
            findLongestWord(order.carrier_name) > 10
              ? order.carrier_name.substring(0, 10)
              : order.carrier_name
          }`}
          {findLongestWord(order.carrier_name) > 10 && '...'}
        </ToolTip>
      ),
      truck: () => order.truck,
      completed_at: () => convertTime(order.completed_at),
      delivery_price: () => `$${order.delivery_price}`,
      additional_price: () => `$${order.additional_price}`,
      totalPrice: () => `$${order.totalPrice}`,
      alerts: () => (
        <>
          {order.alerts.map(alert => (
            <>
              {alerts[alert] && (
                <ToolTip
                  placement="left"
                  key={`${order.order_id}_${alert}`}
                  title={
                    <div key={alert}>
                      {alerts[alert].badge}:
                      <strong>
                        {alert === 4096 ? (
                          <>
                            {order.fieldpro_alerts
                              .map(item => alerts[alert].reason[item])
                              .join(', ')}
                          </>
                        ) : (
                          alerts[alert].reason
                        )}
                      </strong>
                    </div>
                  }>
                  <Badge
                    key={`${order.order_id}_${alert}`}
                    type={alerts[alert].color ? alerts[alert].color : ''}>
                    {alerts[alert].badge}
                  </Badge>
                </ToolTip>
              )}
            </>
          ))}
        </>
      ),
      status: () => (
        <div className="reconcile__status">
          <Badge
            className={`reconcile__status__badge badge badge--status-${order.review_status}`}>
            {statuses[order.review_status]}
          </Badge>
          <span className="reconcile__status__tooltip">
            <ToolTip
              title={
                <div className="audit-status__tooltip">
                  <span className="audit-status__tooltip__text">
                    AUDIT REQUIRED
                  </span>
                  {approvals(order).map(item => (
                      <div
                        key={`tooltip-${item.id}`}
                        className="audit-status__tooltip__list">
                        <div
                          className={`audit-status__circle ${
                            item.pending ? 'audit-status__circle--pending' : ''
                          } ${
                            item.approved
                              ? 'audit-status__circle--approved'
                              : ''
                          } audit-status__circle--tooltip`}
                        />
                        <span className="audit-status__tooltip__text">
                          {`${item.title}: `}
                          <span className="audit-status__tooltip__text--bold">
                            {item.status}
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              }
              placement="left">
              <div className="audit-status">
                {approvals(order).map(item => (
                    <div
                      key={`approval-${item.id}`}
                      className={`audit-status__circle ${
                        item.pending ? 'audit-status__circle--pending' : ''
                      } ${
                        item.approved ? 'audit-status__circle--approved' : ''
                      }`}
                    />
                  ))}
              </div>
            </ToolTip>
          </span>
        </div>
      ),
    },
  }));

  return (
    <div>
      <div className="invoice-list__actions">
        <span>
          <strong>{checkedOrders.length}</strong> orders selected
        </span>
        <span className="invoice-list__actions--buttons">
          <CSVLink data={csvOrders} headers={csvHeaders} filename="order-actions-list.csv">
            <Button
              theme="small"
              onClick={() => {}}>
              <Icon icon="download" />
            </Button>
          </CSVLink>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Reconcile',
                  permissions: ['update'],
                },
              ],
            }}>
            <DropdownButton
              theme="small"
              isDisabled={checkedOrders.length < 1}
              text="Actions"
              icon="down"
              options={actionOptions}
              noMargin
            />
          </Authorize>
          {/* TODO: Hidden until functionality is completed
          <ToolTip title="Export Selected..." placement="left">
            <DropdownButton
              onClick={() => {}}
              icon="export"
              className="button--small--square"
              options={exportOptions}
            />
          </ToolTip> */}
        </span>
      </div>
      <TableList
        onSort={sorting}
        checkboxColumn
        clickableTable
        onCheck={rows => setCheckedOrders(rows)}
        columns={columns}
        listItems={builtItems}
        modifiers={['action-items']}
      />
      <ReconcileSlidePanel
        isVisible={!!selectedOrder}
        closePanel={() => setSelectedOrder(null)}
        page="actionItems"
        selectedOrder={selectedOrder}
        equipmentRequired={equipmentRequired}
        getOrders={getOrders}
      />

      {orders && orders.length > 0 && (
        <ServerPagination
          pagination={pagination}
          onUpdate={setPagination}
          totalItems={totalItems}
        />
      )}
      <ContestModal
        open={contestModal}
        onClose={() => setContestModal(false)}
        orderIds={orderIds}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  orders: genericSorting(state.orders.collection, props),
  totalItems: state.orders.totalItems,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  approveOrderFunc: orderId => dispatch(approveOrder(orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconcileActionItemsList);
