import axios from 'axios';

export const roles = {
  createRole(data) {
    return axios.post(`v2/auth/roles`, data);
  },
  getRoles(companyTypeId) {
    return axios.get(`v2/auth/roles?companyTypeId=${companyTypeId}`);
  },
  getRoleTypes() {
    return axios.get(`v2/auth/role-types`);
  },
  getPermissions() {
    return axios.get('v2/auth/roles/permissions', {});
  },
  updateRole(role, roleDetails) {
    return axios.patch(`v2/auth/roles/${role}`, roleDetails);
  },
  deleteRole(id) {
    return axios.delete(`v2/auth/roles/${id}`);
  },
};
