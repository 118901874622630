import React from 'react';
import { createRoot } from 'react-dom/client';
import jquery from 'jquery';
import { Provider } from 'react-redux';
import { Bugfender } from '@bugfender/sdk';
import { SnackbarProvider } from 'notistack';

import APIProvider from 'api/APIProvider';
import store from './store/store';
import App from './components/App';
import packageJson from '../package.json';

// SCSS index
import 'styles';

// FIXME: jQuery has got to go, Once removed from the components
// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = jquery;

const getBugfenderKey = baseUrl => {
  switch (baseUrl) {
    case 'http://localhost:3000/' || 'https://api.dev.automatizelabs.com/':
      return 'CQ4bAcSKyEiWddaPzVNhgUyixm5kK9lw';
    case 'https://api.uat.automatizelabs.com/':
      return 'giYpKUsTBEQ45MvoUsVjogXX6mG5o6dc';
    default:
      return 'rHIb0lkIfg5nP9lUOre4sQlHUINPDSPL';
  }
};

// eslint-disable-next-line no-underscore-dangle
const bugFenderKey = getBugfenderKey(window._env_.API_BASE_URL);

Bugfender.init({
  appKey: bugFenderKey,
  version: packageJson.version,
  logUIEvents: false,
}).catch(() => {});

// FIXME: Bootstrap to be deprecated in project
import('bootstrap');

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <APIProvider>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <App />
      </SnackbarProvider>
    </Provider>
  </APIProvider>,
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
