import React from 'react';

const JobModalHeader = ({ step, jobOperationType }) => {
  if (!jobOperationType || jobOperationType === 12) {
    return null;
  }
  return (
    <div className="steps">
      <ul role="tablist">
        <li
          role="tab"
          className={step === 1 ? 'current' : 'disabled'}
          style={{ width: '20%' }}>
          <a>
            <span className="number">1.</span> Job
          </a>
        </li>
        <li
          role="tab"
          className={step === 2 ? 'current' : 'disabled'}
          style={{ width: '20%' }}>
          <a>
            <span className="number">2.</span> Job Details
          </a>
        </li>
        <li
          role="tab"
          className={step === 3 ? 'current' : 'disabled'}
          style={{ width: '20%' }}>
          <a>
            <span className="number">3.</span> Alerts
          </a>
        </li>
        <li
          role="tab"
          className={step === 4 ? 'current' : 'disabled'}
          style={{ width: '20%' }}>
          <a>
            <span className="number">4.</span> Job Design
          </a>
        </li>
        <li
          role="tab"
          className={step === 5 ? 'current' : 'disabled'}
          style={{ width: '20%' }}>
          <a>
            <span className="number">5.</span> Storage
          </a>
        </li>
      </ul>
    </div>
  );
};
export default JobModalHeader;
