import React from 'react';
import { JsonToTable } from 'react-json-to-table';
import ToolTip from 'components/shared/ToolTip';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import _ from 'lodash'

const AuditDetailsPanel = ({ request, response, updateSummary, onClose, isVisible }) => (
    <div className={`audit-details-slide-panel ${isVisible ? 'audit-details-slide-panel--visible' : ''}`}>
      <div className="audit-details-slide-panel__content"> 
        {!_.isEmpty(updateSummary) && (<><h2>Updated Values:</h2>
        <JsonToTable json={updateSummary} /></>)}
        <h2>Request:</h2>
        <JsonToTable json={request} />
        <h2>Response:</h2>
        <JsonToTable json={response} />
      </div>
      <span className="audit-details-slide-panel__close">
        <ToolTip title="Close Panel">
          <Button
            theme="small"
            onClick={onClose}
            className="button--small--square">
            <Icon icon="right" />
          </Button>
        </ToolTip>
      </span>
    </div>
);

export default AuditDetailsPanel;