import axios from 'axios';
import moment from 'moment';

export const jobPlans = {
  get(
    time = new Date(
      moment()
        .startOf('day')
        .utc()
        .format(),
    ),
  ) {
    return axios.get(`v2/jobPlan`, {
      params: { currentDate: time },
    });
  },
  getPlan(
    data,
    time = new Date(
      moment()
        .utc()
        .format(),
    ),
  ) {
    const getParams = {
      currentDate: time,
    };

    if (data?.pos) {
      getParams.pos = data.pos;
    }
    if (data?.carriers) {
      getParams.carriers = data.carriers;
    }
    if (data?.drivers) {
      getParams.drivers = data.drivers;
    }

    return axios.get(`v2/jobPlan/${data.id}`, {
      params: getParams,
    });
  },
  add(data) {
    return axios.post('v2/jobPlan', data);
  },
  edit(data) {
    return axios.put(`v2/jobPlan/${data.id}`, data);
  },
  editVisiblity(data) {
    return axios.put(`v2/jobPlan/${data.id}/status`, {status: data.status});//{status: 'open' || 'closed'}
  },
  remove(data) {
    return axios.delete(`v2/jobPlan/${data.jobPlanId}`, { data });
  },
  getReport(data) {
    return axios({
      method: 'post',
      url: `v2/jobPlan/report`,
      responseType: 'arraybuffer',
      data,
    });
  },
  getBillableOrders(
    data,
    time = new Date(
      moment()
        .utc()
        .format(),
    ),
  ) {
    const params = {
      currentDate: time,
      start: data.start,
      end: data.end,
    };

    return axios.get(`v2/jobPlan/${data.id}/billableOrders`, {
      params,
    });
  },
  getOrders(data) {
    return axios.post('v2/order/search/orders', data); //data== orderIds:[1,2,3]
  },
};
