import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import actions from 'store/actions/ManageDatabase';
import Select from 'components/shared/Select';

import targetValue from 'utils/targetValue';

const AddEditModal = ({
  showUpdate,
  closeUpdate,
  setInputValue,
  updateModal,
  add,
  edit,
  clear,
  vendorTypes,
}) => {
  useEffect(
    () => () => {
      clear();
    },
    [],
  );

  const { name, status, types } = updateModal;
  const selectFunction = {
    add,
    edit,
  };
  const [saveDisabled, setSaveDisabled] = useState(true);

  const vendorTypesOptions = vendorTypes.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const determineAddDisabled = () => {
    let disabled = false;
    if (!name.trim().length || !types.length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [name, types]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => selectFunction[status](e, closeUpdate)}>
          <Modal.Header>
            <h3>{status == 'add' ? 'Add Vendor' : 'Edit Vendor'}</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="manage-database_vendors_add-edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  label="Vendor Type"
                  options={vendorTypesOptions}
                  onChange={selected => setInputValue(selected, 'types')}
                  defaultValue={vendorTypesOptions.filter(item =>
                    types.map(i => i.id).includes(item.value),
                  )}
                  accountForPadding={false}
                  isMulti
                  required
                  closeMenuOnSelect={false}
                  testSelector="database_vendors_filter_types_select"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeUpdate}
              colour="white"
              testSelector="database_vendor_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="database_vendor_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  updateModal: state.database.vendors.updateModal,
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueVendor(targetValue(e), name)),
  clear: () => dispatch(actions.clearVendorModal()),
  add: (e, closeModal) => {
    e.preventDefault();
    dispatch(actions.addVendor(closeModal));
  },
  edit: (e, closeModal) => {
    e.preventDefault();
    dispatch(actions.editVendor(closeModal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditModal);
