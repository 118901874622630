import axios from 'axios';
import authService from '../services/auth/service';

// eslint-disable-next-line no-underscore-dangle
const serverUrl = window._env_.API_BASE_URL;
axios.defaults.baseURL = serverUrl;

// Interceptors

axios.interceptors.response.use(
  response => {
    response.status = response.data.status || response.status;
    if (
      response.status === 400 ||
      response.status === 403 ||
      response.status === 300 ||
      response.status === 500
    ) {
      return Promise.reject(response);
    }

    if (response.status === 401) {
      localStorage.clear();
      window.location = '/login';
    }

    return response;
  },
  error => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear();
      window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  },
);

axios.interceptors.request.use(
  config => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `jwt ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: serverUrl }) => async ({
  url,
  method,
  data,
  params,
}) => {
  try {
    const result = await axios({ baseURL: baseUrl, url, method, data, params });
    return { data: result.data?.data || result.data };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
