import constants from './constants';

const initialState = {
  eventsReceive: false,
  eventsList: [],
  openEvents: false,
  lastEventId: 0,
  updateInterval: 15000,
  activeTab: 'completed',
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case constants.EVENTS_REQUEST:
      return Object.assign({}, state, {
        eventsReceive: false,
      });
    case constants.EVENTS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        eventsList: action.events,
        eventsReceive: true,
        lastEventId: action.lastEventId,
      });
    case constants.EVENTS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        eventsReceive: false,
        error: action.error,
      });
    case constants.EVENTS_CHANGE_BAR_STATUS:
      return Object.assign({}, state, {
        openEvents: !state.openEvents,
      });
    case constants.EVENTS_UPDATE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        eventsList: action.events,
        lastEventId: action.lastEventId,
      });
    case constants.EVENTS_CHANGE_ACTIVE_TAB:
      return Object.assign({}, state, {
        activeTab: action.tab,
      });
    case constants.EVENTS_CHAT_MESSAGE:
      return Object.assign({}, state, {
        eventsList: action.events,
      });
    default:
      return state;
  }
};

export default events;
