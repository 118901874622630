import axios from 'axios';

const login = (username, password) =>
  axios.post('v2/auth/login', {
    email: username,
    password,
  });

const authenticate = (code, roleId, entityId, roleTypeId) =>
  axios.post('v2/auth/choose-role/atmz', {
    code,
    roleId,
    entityId,
    roleTypeId,
  });

const forgetPassword = email => axios.post(`v2/auth/reset-password`, { email });

export const userService = {
  login,
  forgetPassword,
  authenticate,
};
