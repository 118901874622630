export const switchLocationType = type => {
  switch (type) {
    case 1:
      return {
        title: 'Wellsite',
        weight: 'Volume (bbl)',
        type: 'Commodity '
      }
    case 2:
      return {
        title: 'Sand Site',
        weight: 'Weight (lb)',
        type: 'Sand ',
      };
    case 3:
      return {
        title: 'Disposal Unit',
        weight: 'Volume (bbl)',
        type: 'Commodity ',
      };
    case 4:
      return {
        title: 'Staging Site',
        weight: 'Weight (lb)',
        type: 'Staging ',
      };
    case 5:
      return {
        title: 'Acid Facility',
        weight: 'Volume (bbl)',
        type: 'Commodity ',
      };
    default:
      return '';
  }
};
