import React, { useContext } from 'react';
import GoogleMap, {
  AutomatizeMarker,
  polyCoords,
  milesToMeters,
} from 'components/shared/GoogleMap';
import { Polygon } from 'google-maps-react';
import Icon from 'components/shared/Icon';

import { locationColors } from 'components/globalInfo/polygonColors';
import { Row } from 'react-bootstrap';
import DriverInfoWindow from './DriverInfoWindow';

const checkLocationIcon = type => {
  switch (type) {
    case 1:
      return 'wellsite';
    case 2:
      return 'sandsite';
    case 3:
      return 'water_disposal';
    case 4:
      return 'staging_site';
    case 5:
      return 'acid_facility';
    default:
      return '';
  }
};

const validLocationTypes = {
  1: 'well_site',
  2: 'sand_site',
  3: 'water_disposal',
  4: 'staging_site',
  5: 'acid_facility'
};

const infoWindowContent = {
  driver: item => <DriverInfoWindow driver={item} />,
};

const infoWindowTitle = {
  driver: item => (
    <>
      <Icon icon="driver" colour="white" />
      <span className="info-window__title--text">
        #<a href={`driver-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  sand_site: item => (
    <>
      <Icon icon={checkLocationIcon(item.locationType)} colour="white" />
      <span className="info-window__title--text">
        #{item.id} {item.name}
      </span>
    </>
  ),
};

const LocationDetailsMap = ({ drivers, sandSite }) => {
  const initialCenter = { lat: sandSite.latitude, lng: sandSite.longitude };

  return (
    <div className="col-lg-6">
      <Row>
        <div className="col-lg-6">
          <strong>Longitude:</strong> {sandSite.longitude}
        </div>
        <div className="col-lg-6">
          <strong>Latitude:</strong> {sandSite.latitude}
        </div>
      </Row>
      <div
        className="sandsiteDetailsMap"
        style={{ marginTop: 30, height: 300, position: 'relative' }}>
        <GoogleMap fitMarkers initialCenter={initialCenter}>
          <Polygon
            paths={polyCoords(
              milesToMeters(sandSite.geofenceRange),
              30,
              sandSite.latitude,
              sandSite.longitude,
            )}
            strokeColor={locationColors[sandSite.locationType].color}
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor={locationColors[sandSite.locationType].color}
            fillOpacity={0}
          />
          <AutomatizeMarker
            position={{ lat: sandSite.latitude, lng: sandSite.longitude }}
            type={validLocationTypes[sandSite.locationType]}
            infoWindow={{
              title: infoWindowTitle.sand_site(sandSite),
              width: 350,
            }}
          />
          {drivers.map(driver => (
            <AutomatizeMarker
              position={{ lat: driver.latitude, lng: driver.longitude }}
              type="driver"
              key={driver.id}
              status={
                driver.order_status === 3 ||
                  driver.order_status === 4 ||
                  driver.order_status === 6 ||
                  driver.order_status === 7 ||
                  driver.order_status === 8
                  ? 'loaded'
                  : 'empty'
              }
              infoWindow={{
                title: infoWindowTitle.driver(driver),
                content: infoWindowContent.driver(driver),
                width: 350,
              }}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default LocationDetailsMap;
