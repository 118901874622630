import React from 'react';

const More = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    className="icon--fill">
    <path
      fill="#fff"
      d="M7.5 22.5A7.5 7.5 0 110 30a7.5 7.5 0 017.5-7.5zm22.5 0a7.5 7.5 0 11-7.5 7.5 7.5 7.5 0 017.5-7.5zm22.5 0A7.5 7.5 0 1145 30a7.5 7.5 0 017.5-7.5z"
    />
  </svg>
);

export default More;