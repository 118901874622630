const openAddModal = () => ({
  type: 'ADD_UNIT_SET_INPUT_VALUE',
  name: 'showAddModal',
  value: true,
});

export default {
  openAddModal,
}

