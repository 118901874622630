import React, { useState, useEffect } from 'react';
import Loader from 'components/shared/Loader';

const PreviewInvoice = ({
  currentInvoice,
  getInvoicePreview,
  carrierId,
  open,
  groupedOrders = {},
}) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (open && !file) {
      getInvoicePreview(
        currentInvoice.id,
        carrierId || null,
      ).then(data => setFile(data));
    }
  }, [open]);

  return (
    open && (
      <div className="breakdown-preview">
        {!file ? (
          <Loader />
        ) : (
          <object
            data={file}
            type="application/pdf"
            className="breakdown-preview--container"
          />
        )}
      </div>
    )
  );
};

export default PreviewInvoice;
