import React, { useEffect, useMemo, useState } from 'react';
import targetValue from 'utils/targetValue';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import {
  billOptions,
  auditOptions,
} from 'components/globalInfo/customerBillings';
import { useEditCustomerBillingTerms } from 'api/v2/customers';
import swal from 'bootstrap-sweetalert';
import { billableOptions } from './helpers';

const initEditBillingTerms = {
  dueDays: '',
  billingFrequency: '',
  bulkInvoiceEnabled: false,
  packetInvoiceEnabled: false,
  csvInvoiceEnabled: false,
  nonBillable: false,
  billTo: '',
  approvalsRequired: [],
  bulkInvoiceTemplateId: 1,
  billableOperationTypeStatus: [],
};

const EditBillingTerms = ({
  closeEdit, // false close modal function in parent
  showEdit, // true close modal function in parent
  details,
}) => {
  const { mutate: updateBillingTerms } = useEditCustomerBillingTerms(details?.id);

  const [terms, setTerms] = useState(initEditBillingTerms);

  useEffect(() => {
    if (details.billingTerms) {
      setTerms({
        ...details.billingTerms,
        approvalsRequired: details.approvalsRequired,
        billableOperationTypeStatus: details.billableOperationTypes,
      });
    } else {
      setTerms(initEditBillingTerms);
    }
  }, [details, showEdit]);

  const setValue = (e, name) => {
    const value = targetValue(e);
    setTerms(state => ({ ...state, [name]: value }));
  };

  const updateBillableOpType = value => {
    const opTypeIndex = terms.billableOperationTypeStatus.findIndex(
      type => type.operationType === value,
    );
    const opType = terms.billableOperationTypeStatus[opTypeIndex];

    const newOpTypeValue = {
      ...opType,
      billableStatus: !opType.billableStatus ? 1 : 0,
    };
    setTerms(state => ({
      ...state,
      billableOperationTypeStatus: [
        ...state.billableOperationTypeStatus.slice(0, opTypeIndex),
        newOpTypeValue,
        ...state.billableOperationTypeStatus.slice(opTypeIndex + 1),
      ],
    }));
  };

  const saveEdit = async e => {
    e.preventDefault();
    const customerId = details.id;
    const data = {
      ...terms,
      id: customerId,
    };

    data.bulkInvoiceEnabled = Boolean(data.bulkInvoiceEnabled);
    data.packetInvoiceEnabled = Boolean(data.packetInvoiceEnabled);
    data.csvInvoiceEnabled = Boolean(data.csvInvoiceEnabled);
    data.nonBillable = Boolean(data.nonBillable);

    if (terms.approvalsRequired.length) {
      data.approvalsRequired = terms.approvalsRequired;
    }
    if (terms.billableOperationTypeStatus.length) {
      data.billingOperationTypes = terms.billableOperationTypeStatus;
    }

    updateBillingTerms(data, {
      onSuccess: closeEdit,
      onError: error => { swal('Error updating billing terms.', error?.response?.data?.message, 'error'); }
    });
  };

  const saveDisabled = useMemo(() => {
    if (!terms.billTo || !terms.billingFrequency || !terms.dueDays) {
      return true;
    }
    return false;
  }, [terms]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeEdit}>
        <form
          onSubmit={e => saveEdit(e, closeEdit)}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Billing Details</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-billing-details-modal">
              <div className="edit-billing-details-modal__column">
                <Input
                  onChange={value => setValue(value, 'dueDays')}
                  value={terms.dueDays}
                  label="Due date (days)"
                  type="number"
                  required
                  testSelector="customer-details_info_edit-billing-terms_due-date_input"
                />
                <Input
                  onChange={value => setValue(value, 'billingFrequency')}
                  value={terms.billingFrequency}
                  label="Billing frequency (days)"
                  type="number"
                  required
                  testSelector="customer-details_info_edit-billing-terms_billing-frequency_input"
                />
                <label className="small font-noraml">Bill to</label>
                <Select
                  value={billOptions.find(item => item.value === terms.billTo)}
                  onChange={item => setValue(item.value, 'billTo')}
                  placeholder="Bill to"
                  options={billOptions}
                  required
                  testSelector="customer-details_info_edit-bill-terms_bill-to_select"
                />
                <Select
                  placeholder="Audit required"
                  options={auditOptions}
                  onChange={selectedAuditRequirements =>
                    setValue(
                      selectedAuditRequirements.map(item => item.value),
                      'approvalsRequired',
                    )
                  }
                  defaultValue={auditOptions.filter(option =>
                    details.approvalsRequired.includes(option.value),
                  )}
                  closeMenuOnSelect={false}
                  isDisabled={!!terms.nonBillable}
                  isMulti
                  testSelector="customer-details_info_edit-bill-terms_audit-required_select"
                />
              </div>

              <div className="edit-billing-details-modal__column">
                <Input
                  type="checkbox"
                  testSelector="customer-details_info_edit-billing-terms_bulk-invoice_input"
                  isChecked={terms.bulkInvoiceEnabled}
                  onChange={e => setValue(e, 'bulkInvoiceEnabled')}
                  label="Attach bulk invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="customer-details_info_edit-billing-terms_packet-invoice_input"
                  isChecked={terms.packetInvoiceEnabled}
                  onChange={e => setValue(e, 'packetInvoiceEnabled')}
                  label="Attach packet invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="customer-details_info_edit-billing-terms_csv-invoice_input"
                  isChecked={terms.csvInvoiceEnabled}
                  onChange={e => setValue(e, 'csvInvoiceEnabled')}
                  label="Attach csv invoice"
                />
                <label className="small font-noraml" style={{ marginTop: 5 }}>
                  Billable status per Job Type
                </label>
                {billableOptions(terms.billableOperationTypeStatus).map(
                  option => (
                    <Input
                      key={option.value}
                      type="checkbox"
                      testSelector={`customer-details_info_edit-billing-terms_billable-status_${option.value
                        }_input`}
                      isChecked={option.isChecked}
                      onChange={() => {
                        updateBillableOpType(option.value);
                      }}
                      label={option.label}
                    />
                  ),
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEdit}
              colour="white"
              testSelector="customer-details_info_edit-bill-terms_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="customer-details_info_edit-bill-terms_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditBillingTerms;
