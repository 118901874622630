import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ticketPreviewRequested } from 'store/actions/OrderTickets';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import Loader from 'components/shared/Loader';
import FileViewer from 'components/shared/FileViewer';

const OrderInfoTicket = ({ ticket, config, getTicketPreview }) => {
  const [image, setImage] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible && !image) {
      getTicketPreview().then(data => setImage(data));
    }
  }, [visible]);

  const configTicket = config.find(c => c.ticketType === ticket.ticketType);

  return (
    <div className="modify-ticket panel panel-default">
      <div className="modify-ticket__header">
        <span className="modify-ticket__header__text">
          {configTicket ? configTicket.ticketName : 'Unknown'}:
          <span className="white"> #{ticket.ticketNumber}</span>
        </span>
        <div className="modify-ticket__header__buttons">
          <ToolTip title="Preview">
            <Button
              theme="small"
              className="button--small--square collapsed panel-title--with-arrow"
              testSelector="order-actions_modify_order-info-ticket_accordion_btn"
              data-bs-toggle="collapse"
              data-parent="#accordion"
              href={`#ticket-${ticket.id}`}
              onClick={() => setVisible(!visible)}
              disabled={ticket.isDeleted === 1}>
              <Icon icon="down" />
            </Button>
          </ToolTip>
        </div>
      </div>
      <div
        className="modify-ticket__preview panel-collapse collapse"
        id={`ticket-${ticket.id}`}>
        {!image ? (
          <Loader />
        ) : (
          <FileViewer
            image={image}
            title={`${configTicket ? configTicket.ticketName : 'Unknown'}: #${ticket.ticketNumber
              }`}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, { ticket, orderId }) => ({
  getTicketPreview: () => dispatch(ticketPreviewRequested(orderId, ticket.id)),
});

export default connect(
  null,
  mapDispatchToProps,
)(OrderInfoTicket);
