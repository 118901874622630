import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Link from 'components/common/Link';
import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import {
  creditMemoDetailsRequested,
  downloadMemoRequested,
} from 'store/actions/CreditMemos';
import CreditMemoActions from '../CreditMemoActions';


const CreditMemoDetails = ({
  creditMemoId,
  customerCreditMemo,
  carrierCreditMemos,
  getCreditMemo,
  downloadCreditMemo,
  isLoading,
}) => {
  const [currentCreditMemoId, setCurrentCreditMemoId] = useState(null);
  const [currentCreditMemo, setCurrentCreditMemo] = useState({});
  const [carrierId, setCarrierId] = useState(null);

  useEffect(() => {
    if (creditMemoId && !currentCreditMemoId) {
      getCreditMemo(creditMemoId);
      setCurrentCreditMemoId(creditMemoId);
    }
    return () => {
      setCurrentCreditMemo({});
      setCarrierId(null);
      setCurrentCreditMemoId(null);
    };
  }, [creditMemoId]);

  const handleCarrierId = (carrierID, type) => {
    setCarrierId(carrierID);
  };

  const customerDownloadOptions = [
    {
      name: 'pdf',
      label: 'PDF',
      onClick: () => {
        downloadCreditMemo(creditMemoId, null);
      },
      dropdownIcon: 'download',
    },
  ];

  const carrierDownloadOptions = [
    {
      name: 'pdf',
      label: 'PDF',
      onClick: () => {
        downloadCreditMemo(creditMemoId, carrierId);
      },
      dropdownIcon: 'download',
    },
  ];

  return (
    <>
      <div className="page__header__title credit-memo-header">
        Credit Memo Details
      </div>
      <div className="invoice-breakdown credit-memo-breakdown">
        {!isLoading ? (
          <>
            <Authorize {...{ forbidden: ['CARRIER'] }}>
              <div className="invoice-breakdown__section">
                {(customerCreditMemo && (
                  <>
                    <h4>Customer Credit Memo</h4>
                    <ul className="breakdown-list">
                      <li className="breakdown-list--item">
                        <span>
                          <span>
                            <span className="item--bold-white">
                              #
                              <Link
                                {...{
                                  id: customerCreditMemo.customerId,
                                  to: 'customer-details',
                                }}>
                                {customerCreditMemo.customerId}
                              </Link>{' '}
                              | {customerCreditMemo.customerName}
                            </span>
                          </span>
                          <div>
                            Total orders: {customerCreditMemo.orderCount}
                          </div>
                        </span>
                        <CreditMemoActions
                          downloadOptions={customerDownloadOptions}
                          currentCreditMemoId={creditMemoId}
                          currentCreditMemo={customerCreditMemo}
                          handleCarrierId={handleCarrierId}
                          getCreditMemoPreview={() => {}}
                          carrierId={carrierId}
                        />
                      </li>
                    </ul>
                  </>
                )) || <h4>No customer credit memo found.</h4>}
              </div>
            </Authorize>
            <div className="invoice-breakdown__section">
              {(carrierCreditMemos && carrierCreditMemos.length > 0 && (
                <>
                  <h4>Carrier Invoices</h4>
                  <ul className="breakdown-list">
                    {carrierCreditMemos.map(item => {
                      const subTotal = item.totalUnadjustedPrice || 0;
                      const total = item.totalPrice || 0;
                      return (
                        <li
                          key={item.carrierId}
                          className="breakdown-list--item">
                          <span>
                            <span>
                              <span className="item--bold-white">
                                #{item.carrierId} | {item.carrierName}
                              </span>
                            </span>
                            <div>Orders: {item.orderCount}</div>
                          </span>
                          <span>
                            <Authorize
                              {...{ forbidden: ['CUSTOMER', 'CARRIER'] }}>
                              <div>${subTotal.toFixed(2)} </div>
                            </Authorize>
                            <div>
                              Total:{' '}
                              <span className="item--bold-white">
                                ${total.toFixed(2)}
                              </span>
                            </div>
                          </span>
                          <CreditMemoActions
                            downloadOptions={carrierDownloadOptions}
                            handleCarrierId={handleCarrierId}
                            getCreditMemoPreview={() => {}}
                            currentCreditMemo={item}
                            currentCreditMemoId={creditMemoId}
                            carrierId={item.carrierId}
                            groupedOrders={item}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )) || <h4>No carrier credit memos found.</h4>}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getCreditMemo: creditMemoId =>
    dispatch(creditMemoDetailsRequested(creditMemoId)),
  downloadCreditMemo: (creditMemoId, carrierId) =>
    dispatch(downloadMemoRequested(creditMemoId, carrierId)),
});

const mapStateToProps = state => ({
  customerCreditMemo: state.creditMemos.creditMemoDetails.customerCreditMemo,
  carrierCreditMemos: state.creditMemos.creditMemoDetails.carrierCreditMemos,
  isLoading: state.creditMemos.creditMemoDetails.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditMemoDetails);
