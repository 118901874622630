import React from 'react';

const CSV = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <path d="M18.266 9.716h-.383V7.864c0-.011-.002-.023-.004-.035a.303.303 0 0 0-.074-.203l-3.08-3.519-.002-.002a.332.332 0 0 0-.146-.091l-.017-.005A.29.29 0 0 0 14.488 4H6.916a.627.627 0 0 0-.626.627v5.089h-.384c-.494 0-.896.4-.896.895v4.659c0 .494.402.895.896.895h.384v3.189c0 .344.28.625.626.625h10.34a.626.626 0 0 0 .627-.625v-3.189h.383c.494 0 .896-.4.896-.895v-4.66a.895.895 0 0 0-.896-.894zm-11.35-5.09h7.259v3.207c0 .173.14.313.313.313h2.768v1.57H6.916v-5.09zm4.718 8.501c-.778-.27-1.284-.7-1.284-1.38 0-.8.666-1.41 1.77-1.41.526 0 .916.11 1.192.236l-.234.854a2.221 2.221 0 0 0-.98-.223c-.457 0-.68.209-.68.452 0 .298.264.43.868.66.825.305 1.215.735 1.215 1.394 0 .785-.604 1.451-1.888 1.451-.535 0-1.062-.14-1.326-.284l.215-.875c.285.146.722.291 1.173.291.486 0 .743-.199.743-.507 0-.291-.222-.458-.784-.659zm-5.513-.305c0-1.597 1.14-2.485 2.555-2.485.548 0 .965.11 1.152.208l-.215.84a2.297 2.297 0 0 0-.889-.173c-.84 0-1.492.506-1.492 1.548 0 .936.555 1.526 1.5 1.526.319 0 .673-.069.881-.151l.16.825c-.195.097-.632.201-1.201.201-1.618 0-2.45-1.007-2.45-2.341l-.001.002zm11.135 6.36H6.916v-3.018h10.34v3.02-.002zm-.699-4.09H15.32l-1.5-4.679h1.16l.569 1.979c.16.555.306 1.09.416 1.672h.021c.119-.562.265-1.117.424-1.652l.597-1.999h1.125l-1.575 4.679z"/>
  </svg>
);

export default CSV;
