import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/Icon';

const WaterDisposalInfoWindow = ({ order }) => {
  const {
    numberOfOrders,
    numberOfTrucks,
    trucksBehindSchedule,
    alerts,
  } = order;
  return (
    <div className="sandsite-content">
      <div className="info-window__status-container">
        <div className="status-block trucks-vs-orders">
          <Icon icon="trucks_vs_orders" />
          <span className="status-block__text">
            <span>Orders vs Trucks on Site: </span>
            <strong
              className="status-block__text__values"
              data-testid="water-disposal-orders-trucks">
              {numberOfOrders || '0'}
              <span> / </span>
              {numberOfTrucks || '0'}
            </strong>
          </span>
        </div>
        <div className="status-block trucks-behind">
          <Icon icon="trucks_behind" />
          <span className="status-block__text">
            <span>Trucks Behind Schedule: </span>
            <strong
              className="status-block__text__values"
              data-testid="water-disposal-trucks-behind-schedule">
              {trucksBehindSchedule || '0'}
            </strong>
          </span>
        </div>
      </div>
      {alerts && alerts.driverOnSite ? (
        <div className="info-window__warning-container">
          <div className="info-window__warning-container--message">
            <Icon icon="clock" colour="white" />
            <span className="info-window__warning-container--text">
              <span>Delay on site: </span>
              <span
                className="white"
                data-testid="water-disposal-average-wait-time">
                Average wait time
                {` ${alerts.driverOnSite.avgDelayedMinutesOnsite} `} min
              </span>
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

WaterDisposalInfoWindow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

export default WaterDisposalInfoWindow;
