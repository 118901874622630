import moment from 'moment';

export const calculateTime = time => {
  const start = moment();
  const end = moment(time);

  const timediffms = start - end;
  const duration = moment.duration(timediffms);

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const timeDifference = `${days} days, ${hours} hours ${minutes} minutes `;

  return timeDifference;
};

export const calculateHoursAgo = time => {
  const start = moment();
  const end = moment(time);

  const duration = moment.duration(start.diff(end));
  const hours = duration.asHours().toFixed(0);
  return hours;
};

export const availableTo = time => {
  const start = moment();
  const end = moment(time);

  const duration = moment.duration(end.diff(start));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const timeTo = {};
  if (duration.milliseconds() < 0) {
    timeTo.label = 'danger';
    timeTo.hours = 'not available';
  } else if (hours >= 0 && hours < 1) {
    timeTo.label = 'warning';
    timeTo.hours = `${minutes} min`;
  } else {
    timeTo.label = 'info';
    timeTo.hours = `${hours} h ${minutes} min`;
  }
  return timeTo;
};
