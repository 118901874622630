import React from 'react';

const TrucksBehind = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#22ADCF" />
        <stop offset="100%" stopColor="#6FC6D9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="#6FC6D9"
        opacity=".297"
        transform="matrix(-1 0 0 1 30 0)"
      />
      <circle
        cx="15"
        cy="15"
        r="11.25"
        fill="#6FC6D9"
        transform="matrix(-1 0 0 1 30 0)"
      />
      <g fill="#FFF">
        <path d="M8.336 14.051l.066-.086c.264-.348.477-.74.666-1.085.184-.339.359-.659.55-.88.424-.494.824-.734 1.221-.734h1.963c.002 0 .19.003.415.003h.32c.229 0 .428.155.485.377l.003.01V16c-.001.003-.006.065.043.118.054.059.158.09.3.09h7.644c.27 0 .488.206.488.46v1.577c0 .253-.219.459-.488.459h-1.679a1.972 1.972 0 0 1-1.961 1.796 1.972 1.972 0 0 1-1.962-1.796H13.6a1.972 1.972 0 0 1-1.961 1.796 1.972 1.972 0 0 1-1.962-1.796H8.4a.879.879 0 0 1-.878-.878v-1.506c0-1.213.49-1.848.814-2.268zm2.569 4.479a.735.735 0 1 0 1.47-.002.735.735 0 0 0-1.47.002zm-1.689-3.822c.015.025.06.036.126.032h3.374v-2.753h-1.459c-.393 0-.67.171-.96.59-.192.279-.702 1.156-.728 1.2l-.055.107c-.148.28-.37.705-.298.824zm9.156 3.088a.735.735 0 1 0 .001 1.47.735.735 0 0 0-.001-1.47z" />
        <path d="M21.472 15.51h-2.313a.426.426 0 0 1-.425-.426v-1.7c0-.235.19-.426.425-.426h2.313c.234 0 .425.19.425.425v1.7c0 .235-.19.426-.425.426zM17.628 15.51h-2.313a.426.426 0 0 1-.425-.426v-1.7c0-.235.19-.426.425-.426h2.313c.235 0 .425.19.425.425v1.7c0 .235-.19.426-.425.426zM21.472 12.312h-2.313a.426.426 0 0 1-.425-.425v-1.701c0-.235.19-.425.425-.425h2.313c.234 0 .425.19.425.425v1.7c0 .235-.19.426-.425.426zM17.628 12.312h-2.313a.426.426 0 0 1-.425-.425v-1.701c0-.235.19-.425.425-.425h2.313c.235 0 .425.19.425.425v1.7c0 .235-.19.426-.425.426z" />
      </g>
    </g>
  </svg>
);

export default TrucksBehind;
