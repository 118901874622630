import { combineReducers } from '@reduxjs/toolkit';
import { loginConstants } from '../constants/Login';
import authService from '../../../src/services/auth/service';
import { actions } from 'reapop';

const initialStateLogin = {
  isAbleToSelectRole: false,
  roles: [],
  jwtTemp: '',
  email: '',
  password: '',
  url: '',
  isLoggedOut: false,
  lockReset: false,
  errorCredentials: false,
  errorMessage: '',
};

const login = (state = initialStateLogin, action) => {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user,
        submitted: true,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        email: '',
        password: '',
        isAbleToSelectRole: action.ableSelect,
        roles: action.roles,
        jwtTemp: action.jwtTemp,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
      };
    case loginConstants.LOGIN_CREDENTIALS_FAILURE:
      return {
        ...state,
        errorCredentials: true,
        errorMessage: action.errorMessage ?? '',
      };
    case loginConstants.LOGIN_TOKEN_EXPIRED:
      return initialStateLogin;
    case loginConstants.LOGOUT:
      return {
        ...initialStateLogin,
        isLoggedOut: true,
      };
    case loginConstants.LOGIN_SET_LOGIN:
      return {
        ...state,
        email: action.login,
      };
    case loginConstants.LOGIN_SET_PASSWORD:
      return {
        ...state,
        password: action.pass,
      };
    case loginConstants.LOGIN_TURN_LOCK_RESET:
      return {
        ...state,
        lockReset: !state.lockReset,
      };
    case loginConstants.SET_CURRENT_URL:
      return {
        ...state,
        url: action.url,
      };
    case loginConstants.CLEAR:
      return initialStateLogin;
    default:
      return state;
  }
};

const user = authService.getUser();

const initialStateAuth = {
  isLoggedIn: false,
  user: {},
};
const initialStateLoggedIn = {
  isLoggedIn: true,
  status: user ? user.role : undefined,
};

const initialState = user ? initialStateLoggedIn : initialStateAuth;

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.AUTHENTICATE_REQUEST:
      return {
        ...state,
      };
    case loginConstants.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case loginConstants.AUTHENTICATE_FAILURE:
      return {
        ...state,
      };
    case loginConstants.CLEAR:
      return initialStateAuth;
    default:
      return state;
  }
};

export default combineReducers({
  authentication,
  login,
});
