import Icon from 'components/shared/Icon';
import { startCase } from 'lodash';
import React from 'react';

const CustomTaskListTable = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  // locale,
  onExpanderClick,
}) => {
  const jobTasks = tasks.filter(i => i.type === 'project');

  const renderListTask = task => {
    let expanderSymbol = '';
    if (task.hideChildren === false) {
      expanderSymbol = 'chevron-down';
    } else if (task.hideChildren === true) {
      expanderSymbol = 'chevron-right';
    }

    const prevJobTask = jobTasks[jobTasks.findIndex(t => t.id === task.id) - 1];
    const isDuplicateDistrict =
      prevJobTask && prevJobTask.job.district === task.job?.district;

    if (task.carrierCommitment) {
      return (
        <div
          className="table-row"
          style={{ height: rowHeight }}
          key={`${task.id}-row`}
          onClick={() => onExpanderClick(task)}>
          <div
            className="cell"
            style={{
              minWidth: rowWidth / 2,
              maxWidth: rowWidth / 2,
            }}
          />
          <div
            className="cell"
            style={{
              minWidth: rowWidth,
              maxWidth: rowWidth,
            }}
            title={task.name}>
            <div className="name-wrapper--muted" style={{ paddingLeft: 45 }}>
              {task.carrierCommitment.carrierName}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="table-row"
        style={{ height: rowHeight }}
        key={`${task.id}-row`}
        onClick={() => onExpanderClick(task)}>
        {!isDuplicateDistrict ? (
          <div
            className="cell"
            style={{
              minWidth: rowWidth / 2,
              maxWidth: rowWidth / 2,
            }}
            title={task.name}>
            <strong className="name-wrapper">
              {startCase(task.job.district_name ?? 'No District')}
            </strong>
          </div>
        ) : (
          <div
            className="cell"
            style={{
              minWidth: rowWidth / 2,
              maxWidth: rowWidth / 2,
            }}
          />
        )}
        <div
          className="cell"
          style={{
            minWidth: rowWidth,
            maxWidth: rowWidth,
          }}
          title={task.name}>
          <div className="name-wrapper">
            <strong
              style={
                !expanderSymbol ? { paddingLeft: 10 } : { marginLeft: -10 }
              }>
              {!!expanderSymbol && (
                <a className="collapse-link" style={{ fontSize: 12 }}>
                  <Icon icon={expanderSymbol} />
                </a>
              )}
              #{task.job.id} | {task.name}
            </strong>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="task-list"
      style={{
        fontFamily,
        fontSize,
      }}>
      {tasks.map(renderListTask)}
    </div>
  );
};
export default CustomTaskListTable;
