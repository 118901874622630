import axios from 'axios';

export const users = {
  get() {
    return axios.get('v2/users', {});
  },
  role: {
    get(id) {
      return axios.get(`v2/users/${id}/roles`, {});
    },
    getAutomatizeRoles() {
      return axios.get(`v2/auth/roles/automatize/entities`, {});
    },
    getRoles(companyType, entityId) {
      return axios.get(
        `v2/auth/roles/${companyType}/entities${
          entityId ? '/' + entityId : ''
        }`,
        {},
      );
    },
    remove(data) {
      return axios.delete(`v2/auth/users/${data.id}/roles`, {
        data: data.body,
      });
    },
    add(data) {
      const { id, ...noId } = data;
      return axios.post(`v2/auth/users/${data.id}/roles`, noId);
    },
  },

  remove(id) {
    return axios.post(`v2/users/${id}/remove/`, {});
  },
  restore(id) {
    return axios.post(`v2/users/${id}/restore`, {});
  },
  edit(data) {
    return axios.put(`v2/users/profile`, data);
  },
  add(data) {
    return axios.post('v2/users', data);
  },
  notification(value) {
    return axios.put(`v2/users/notification/${value}`);
  },
  setNewPass(data) {
    return axios.put('v2/password/set', data);
  },
};
