import { connect } from 'react-redux';
import targetValue, { targetValueNumber } from 'utils/targetValue';
import locationsActions from 'store/actions/Locations';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Input from 'components/views/Locations/Input';
import PreviewOrders from 'components/views/Locations/PreviewOrders';

const AddLocationModal = props => {
  const {
    init,
    location,
    clearModalState,
    action,
    openModal,
    closeModal,
  } = props;

  useEffect(() => {
    init(location.id);
    return () => clearModalState();
  }, []);

  const { showPreview } = location;

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={openModal} onHide={closeModal} size='lg'>
        <Modal.Header>
          <h3 className="modal-title" data-testid="add-location-title">
            {' '}
            {showPreview
              ? 'Preview'
              : `${action === 'add' ? 'Add New' : 'Edit'} location`}
          </h3>
        </Modal.Header>
        {showPreview ? (
          <PreviewOrders {...props} />
        ) : (
          <Input {...props} testSelector="locations_add_input" />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  location: state.locations.modalAddLocation,
  allVendors: state.lookUp.vendors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setInputValueNumber: (e, name) =>
    dispatch(locationsActions.setInputValue(targetValueNumber(e), name)),
  setInputValue: (e, name) =>
    dispatch(locationsActions.setInputValue(targetValue(e), name)),
  updateLocation: e => {
    e.preventDefault();
    dispatch(
      locationsActions.updateLocation(ownProps.closeModal, ownProps.action),
    );
  },
  clearModalState: () => dispatch(locationsActions.clearModalState()),
  init: locationId => dispatch(locationsActions.init(locationId)),
  turnPreview: () => dispatch(locationsActions.turnPreview()),
  reroute: e => {
    e.preventDefault();
    dispatch(locationsActions.reroute(ownProps.closeModal, ownProps.action));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLocationModal);
