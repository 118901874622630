import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HomeMap from 'components/views/Home/HomeMap';
import Loader from 'components/shared/Loader';
import HomeFilters from 'components/views/Home/HomeFilters';
import operationType from 'utils/operationTypes';
import { isInArray } from 'components/views/Home/HomePage/helpers';
import useHomeState from 'components/views/Home/hooks/useHomeState';

const HomePage = ({ getMapData, isLoading, mapJobs }) => {
  const { onlyAlerts } = useHomeState();
  useEffect(() => {
    getMapData(onlyAlerts);
  }, [onlyAlerts]);

  if (isLoading) {
    return (
      <div data-testid="home-page-loading" className="page__loader">
        <Loader size={100} />
      </div>
    );
  }

  const jobIdAndName = mapJobs.reduce((array, job) => {
    if (job.id && job.name && !isInArray(array, job.id)) {
      array.push({
        value: parseInt(job.id, 10),
        label: `#${job.id} | ${job.name}`,
      });
    }
    return array;
  }, []);

  const collectedSandAndWellLocation = mapJobs.reduce((array, item) => {
    if (
      item.wellSite &&
      (!onlyAlerts || (onlyAlerts && item.wellSite.alerts))
    ) {
      array.push({
        value: parseInt(item.wellSite.id, 10),
        label: `#${item.wellSite.id} | ${item.wellSite.name}`,
      });
    }

    if (
      item.waterProductionSite &&
      (!onlyAlerts || (onlyAlerts && item.waterProductionSite.alerts))
    ) {
      array.push({
        value: parseInt(item.waterProductionSite.id, 10),
        label: `#${item.waterProductionSite.id} | ${item.waterProductionSite.name}`,
      });
    }

    if (item.orders && item.orders.length) {
      item.orders.forEach(order => {
        if (
          order.sandSite &&
          (!onlyAlerts || (onlyAlerts && order.sandSite.alerts))
        ) {
          array.push({
            value: parseInt(order.sandSite.id, 10),
            label: `#${order.sandSite.id} | ${order.sandSite.name}`,
          });
        }
        if (
          order.waterDisposalSite &&
          (!onlyAlerts || (onlyAlerts && order.waterDisposalSite.alerts))
        ) {
          array.push({
            value: parseInt(order.waterDisposalSite.id, 10),
            label: `#${order.waterDisposalSite.id} | ${order.waterDisposalSite.name}`,
          });
        }
      });
    }
    return array;
  }, []);

  const locationIdAndName = [
    ...new Set(collectedSandAndWellLocation.map(job => job.value)),
  ]
    .filter(item => item != null && item !== 0)
    .map(id => ({
      value: id,
      label: collectedSandAndWellLocation.find(item => item.value === id).label,
    }));

  const districtIdAndName = mapJobs.reduce((array, order) => {
    if (order.districts) {
      order.districts.forEach(district => {
        if (!isInArray(array, district.id)) {
          array.push({
            value: district.id,
            label: `#${district.id} | ${district.name}`,
          });
        }
      });
    }
    return array;
  }, []);

  const driverIdAndName = mapJobs.reduce((array, item) => {
    if (item.orders) {
      item.orders.forEach(order => {
        if (
          order.driver &&
          (!onlyAlerts || (onlyAlerts && order.driver.alerts)) &&
          !isInArray(array, order.driver.id)
        ) {
          array.push({
            value: parseInt(order.driver.id, 10),
            label: `#${order.driver.id} | ${order.driver.name}`,
          });
        }
      });
    }
    return array;
  }, []);

  const carrierIdAndName = mapJobs.reduce((array, item) => {
    if (item.orders) {
      item.orders.forEach(order => {
        if (order.carrier && !isInArray(array, order.carrier.id)) {
          if (
            onlyAlerts &&
            (!order.driver || !order.driver.alerts) &&
            (!order.sandSite || !order.sandSite.alerts) &&
            (!order.waterDisposalSite || !order.waterDisposalSite.alerts)
          ) {
            return;
          }
          array.push({
            value: parseInt(order.carrier.id, 10),
            label: `#${order.carrier.id} | ${order.carrier.name}`,
          });
        }
      });
    }
    return array;
  }, []);

  const jobTypes = [...new Set(mapJobs.map(order => order.operationType))].map(
    id => ({
      value: parseInt(id, 10),
      label:
        operationType[
          mapJobs.find(item => item.operationType === id).operationType
        ],
    }),
  );

  return (
    <div className="page">
      <HomeFilters
        jobIdAndName={jobIdAndName}
        locationIdAndName={locationIdAndName}
        districtIdAndName={districtIdAndName}
        driverIdAndName={driverIdAndName}
        carrierIdAndName={carrierIdAndName}
        jobTypes={jobTypes}
      />
      <HomeMap mapJobs={mapJobs} onlyAlerted={onlyAlerts} />
    </div>
  );
};

HomePage.propTypes = {
  getMapData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mapJobs: PropTypes.instanceOf(Array).isRequired,
};

export default HomePage;
