import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  deleteCarrierDoc,
  getCarrierDocs,
  uploadCarrierDoc,
  verifyCarrierDoc,
} from './queries';

export const useCarrierDocs = carrierId =>
  useQuery({
    queryKey: ['carriers-docs', carrierId],
    queryFn: () => getCarrierDocs(carrierId),
    enabled: !!carrierId,
  });

export const useUploadCarrierDoc = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers-docs', carrierId],
    mutationFn: doc => uploadCarrierDoc({ carrierId, doc }),
    onSuccess: () => {
      swal('Successfully Uploaded Carrier Document', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers-docs', carrierId] });
    },
    onError: error =>
      swal('Unable to Upload Carrier Document', error.message, 'error'),
  });
};

export const useDeleteCarrierDoc = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers-docs', carrierId],
    mutationFn: deleteCarrierDoc,
    onSuccess: () => {
      swal('Successfully Deleted Carrier Document', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers-docs', carrierId] });
    },
    onError: error =>
      swal('Unable to Delete Carrier Document', error.message, 'error'),
  });
};

export const useVerifyCarrierDoc = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers-docs', carrierId],
    mutationFn: verifyCarrierDoc,
    enabled: !!carrierId,
    onSuccess: () => {
      swal('Successfully Verified Carrier Document', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers-docs', carrierId] });
    },
    onError: error =>
      swal('Unable to Verify Carrier Document', error.message, 'error'),
  });
};
