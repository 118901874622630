import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useReducer } from 'react';
import targetValue from 'utils/targetValue';

/**
 * @typedef {{
 *   id: number;
 *   commodityType: string;
 *   commodityTypeId: number;
 *   storageType: string;
 *   levelPercentage: number;
 *   capacityWeight: number;
 *   currentWeight: number;
 *   weightDifference: number; // calculated at storage service
 *   flowStatus: 1 | 0 | -1;
 *   beltSpeedPercent: number;
 *   details: object;
 * }} StorageUnit
 */

const initialUnit = {
  id: 0,
  commodityType: '',
  commodityTypeId: 0,
  storageType: 'silo',
  levelPercentage: 0,
  capacityWeight: 0,
  currentWeight: 0,

  weightDifference: 0,
  flowStatus: 0,
  beltSpeedPercent: 0,
  details: {},
};

/**
 * @typedef {{
 *   jobId: number;
 *   storageId: number;
 *   externalId: string;
 *   externalProvider: string;
 *   units: StorageUnit[];
 *   lastSyncTimestamp: string;
 *   details: object;
 * }} StorageGroup
 */

const initialStorageGroup = {
  jobId: '',
  storageId: 0,
  externalId: '',
  externalProvider: 'manual',
  units: [initialUnit],
  lastSyncTimestamp: new Date(),
  details: {},
};

/**
 *
 * @param {StorageGroup} state
 * @returns {StorageGroup}
 */
const StorageGroupReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.value;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'set_unit_value': {
      const newUnits = cloneDeep(state.units);
      newUnits[action.index][action.name] = action.value;

      if (action.name === 'currentWeight') {
        newUnits[action.index].levelPercentage = Math.round(
          (action.value / newUnits[action.index].capacityWeight) * 100,
        );
        newUnits[action.index][action.name] = Number(action.value);
      }

      return {
        ...state,
        units: newUnits,
      };
    }
    case 'add_unit': {
      return {
        ...state,
        units: [...state.units, initialUnit],
      };
    }
    case 'remove_unit': {
      const newUnits = cloneDeep(state.units);
      newUnits.splice(action.index, 1);
      return {
        ...state,
        units: newUnits,
      };
    }
    case 'clear_state':
      return initialStorageGroup;
    default:
      return state;
  }
};

export const useStorageGroupForm = ({ defaultValue, show }) => {
  /** @type {[StorageGroup, () => {}]} */
  const [data, dispatch] = useReducer(StorageGroupReducer, initialStorageGroup);

  useEffect(() => {
    if (show && defaultValue) {
      // eslint-disable-next-line no-underscore-dangle
      delete defaultValue._id;
      dispatch({
        type: 'init',
        value: {
          ...defaultValue,
          lastSyncTimestamp: new Date(),
        },
      });
    }
  }, [defaultValue, show]);

  const isValid = useMemo(
    () =>
      data.jobId &&
      data.storageId &&
      data.units.length &&
      data.units.every(u => u.currentWeight),
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      unitValueChange: (index, name) => e =>
        dispatch({
          type: 'set_unit_value',
          index,
          name,
          value: targetValue(e),
        }),
      addUnit: () =>
        dispatch({
          type: 'add_unit',
        }),
      removeUnit: index => () => dispatch({ type: 'remove_unit', index }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
