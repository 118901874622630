const invoicesDefaultState = {
  collection: [],
  totalItems: 0,
  lastParams: {},
  apiIsLoading: false,
  lastApiCallSucceeded: true,
  action: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  details: {
    data: null,
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  previewCollection: [],
  linkCollection: [],
  invoicePreview: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  addComment: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  generatedDownloadLink: {
    data: null,
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  emailSendAction: {
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
  downloadInvoiceFile: {
    data: null,
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  creditMemo: {
    orders: [],
    reason: '',
    apiIsLoading: false,
    totalCost: 0,
  },
  jobs: {
    jobs: [],
    apiIsLoading: false
  }
};

export default (state = invoicesDefaultState, { type, ...rest }) => {
  switch (type) {
    case 'INVOICES_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'INVOICES_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: rest.invoices,
        totalItems: rest.totalItems,
        lastParams: rest.lastParams,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'INVOICES_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'ACTION_REQUESTED':
      return {
        ...state,
        action: {
          ...state.action,
          apiIsLoading: true,
        },
      };
    case 'ACTION_REQUEST_SUCCEEDED':
      return {
        ...state,
        action: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ACTION_REQUEST_FAILED':
      return {
        ...state,
        action: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'INVOICE_DETAILS_REQUESTED':
      return {
        ...state,
        details: {
          ...state.details,
          apiIsLoading: true,
        },
      };
    case 'INVOICE_DETAILS_REQUEST_SUCCEEDED':
      return {
        ...state,
        details: {
          data: rest.details,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'INVOICE_DETAILS_REQUEST_FAILED':
      return {
        ...state,
        details: {
          ...state.details,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ACTION_REQUEST_SUCCEEDED_WITH_PREVIEWS':
      return {
        ...state,
        previewCollection: [
          ...state.previewCollection.filter(item => item.id !== rest.data.id),
          rest.data,
        ],
        action: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ACTION_REQUEST_SUCCEEDED_WITH_LINKS':
      return {
        ...state,
        linkCollection: [
          ...state.linkCollection.filter(item => item.id !== rest.data.id),
          rest.data,
        ],
        action: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'GENERATED_DOWNLOAD_LINK_REQUESTED':
      return {
        ...state,
        generatedDownloadLink: {
          ...state.generatedDownloadLink,
          apiIsLoading: true,
        },
      };
    case 'GENERATED_DOWNLOAD_LINK_SUCCEEDED':
      return {
        ...state,
        generatedDownloadLink: {
          data: rest.generatedDownloadLink,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'GENERATED_DOWNLOAD_LINK_FAILED':
      return {
        ...state,
        generatedDownloadLink: {
          data: rest.generatedDownloadLink,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ACTION_SEND_EMAIL_REQUESTED':
      return {
        ...state,
        emailSendAction: {
          ...state.emailSendAction,
          apiIsLoading: true,
        },
      };
    case 'ACTION_REQUEST_SEND_EMAIL_SUCCEEDED':
      return {
        ...state,
        emailSendAction: {
          ...state.emailSendAction,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ACTION_REQUEST_SEND_EMAIL_FAILED':
      return {
        ...state,
        emailSendAction: {
          ...state.emailSendAction,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'DOWNLOAD_INVOICE_FILE_REQUESTED':
      return {
        ...state,
        downloadInvoiceFile: {
          ...state.downloadInvoiceFile,
          apiIsLoading: true,
        },
      };
    case 'DOWNLOAD_INVOICE_FILE_SUCCEEDED':
      return {
        ...state,
        downloadInvoiceFile: {
          data: rest.downloadInvoiceFile,
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'DOWNLOAD_INVOICE_FILE_FAILED':
      return {
        ...state,
        downloadInvoiceFile: {
          data: rest.downloadInvoiceFile,
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'ADD_COMMENT_REQUESTED':
      return {
        ...state,
        addComment: {
          ...state.emailSendAction,
          apiIsLoading: true,
        },
      };
    case 'ADD_COMMENT_SUCCEEDED':
      return {
        ...state,
        addComment: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'ADD_COMMENT_FAILED':
      return {
        ...state,
        addComment: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'INVOICE_PREVIEW_REQUESTED':
      return {
        ...state,
        invoicePreview: {
          ...state.emailSendAction,
          apiIsLoading: true,
        },
      };
    case 'INVOICE_PREVIEW_REQUEST_SUCCEEDED':
      return {
        ...state,
        invoicePreview: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'INVOICE_PREVIEW_REQUEST_FAILED':
      return {
        ...state,
        invoicePreview: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'CREDIT_MEMO_REQUEST_ORDERS':
      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          apiIsLoading: true,
        },
      };
    case 'CREDIT_MEMO_SET_ORDERS':
      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          apiIsLoading: false,
          orders: rest.orders,
        },
      };
    case 'CREDIT_MEMO_REQUEST_FAILED':
      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          apiIsLoading: false,
        },
      };
    case 'CREDIT_MEMO_UPDATE':
      let newOrderArray = [...state.creditMemo.orders];
      let newCost = 0;

      if (rest.index === -1) {
        newOrderArray = newOrderArray.map(o => {
          o.isChecked = rest.isChecked
          return o;
        })
        if (rest.isChecked) {
          for (let i = 0; i < newOrderArray.length; i++) {
            const o = newOrderArray[i];
            newCost = newCost + o.totalPrice;
          }
        }
      } else {
        newOrderArray[rest.index].isChecked = rest.isChecked;
        newCost = rest.isChecked
          ? state.creditMemo.totalCost + rest.price
          : state.creditMemo.totalCost - rest.price
      }

      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          apiIsLoading: false,
          orders: newOrderArray,
          totalCost: newCost,
        },
      };
    case 'CREDIT_MEMO_UPDATE_REASON':
      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          reason: rest.reason
        }
      }
    case 'CREDIT_MEMO_CLEAR':
      return {
        ...state,
        creditMemo: {
          ...state.creditMemo,
          apiIsLoading: false,
          orders: [],
          totalCost: 0,
          reason: '',
        }
      }
    case 'ACTION_JOBS_REQUESTED':
      return {
        ...state,
        jobs: {
          ...state.jobs,
          apiIsLoading: true,
        }
      }
    case 'ACTION_JOBS_FAILED':
      return {
        ...state,
        jobs: {
          ...state.jobs,
          jobs: [],
          apiIsLoading: false,
        }
      }
    case 'ACTION_JOBS_REQUEST_SUCCEEDED':
      return {
        ...state,
        jobs: {
          ...state.jobs,
          jobs: rest.jobs,
          apiIsLoading: false,
        }
      }
    default:
      return state;
  }
};
