import { client } from 'api/client';

export const get = async () => {
	const { data } = await client.get('v2/users');
	return data.data;
}

export const remove = async (id) => {
	const { data } = await client.post(`v2/users/${id}/remove/`);
	return data.data;
}

export const restore = async (id) => {
	const { data } = await client.post(`v2/users/${id}/restore`);
	return data.data;
}

export const edit = async (params) => {
	const { data } = await client.put('v2/users', params);
	return data.data;
}

export const add = async (params) => {
	const { data } = await client.post('v2/users', params);
	return data.data;
}

export const notification = async (value) => {
	const { data } = await client.put(`v2/users/notification/${value}`);
	return data.data
}

export const setNewPass = async (params) => {
	const { data } = await client.put('v2/password/set', params);
	return data.data;
}
