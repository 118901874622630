import { combineReducers } from '@reduxjs/toolkit';

import { zoomDefault, center } from 'components/globalInfo/mapConstants';
import locationsActions from 'store/constants/Locations';
import locationsTiming from 'store/reducers/LocationsTiming';

const qrTemplateState = {
  template: {
    driver_weight: '',
    bol: '',
  },
  apiIsLoading: false,
  dataIsLoaded: false,
  delimitedTemplate: {
    delimiter: '',
    driverWeightIndex: 0,
    bolIndex: 1,
    arrivalIndex: 2,
    departureIndex: 3,
  },
};

const qrTemplate = (state = qrTemplateState, data) => {
  switch (data.type) {
    case locationsActions.LOCATIONS_QR_TEMPLATE_INIT:
      return {
        ...state,
        selectedLocation: data.location,
      };
    case locationsActions.LOCATIONS_QR_TEMPLATE_REQUESTED:
      return {
        ...state,
        dataIsLoaded: false,
        apiIsLoading: true,
      };
    case locationsActions.LOCATIONS_QR_TEMPLATE_REQUEST_SUCCEEDED:
      return {
        ...state,
        template: data.data,
        delimitedTemplate: {
          delimiter: '',
          driverWeightIndex: 0,
          bolIndex: 1,
          arrivalIndex: 2,
          departureIndex: 3,
        },
        dataIsLoaded: true,
        apiIsLoading: false,
      };
    case locationsActions.LOCATIONS_QR_TEMPLATE_DELIMIT_REQUEST_SUCCEEDED:
      return {
        ...state,
        delimitedTemplate: data.data,
        template: {
          driver_weight: '',
          bol: '',
        },
        dataIsLoaded: true,
        apiIsLoading: false,
      };
    case locationsActions.LOCATIONS_QR_TEMPLATE_REQUEST_FAILED:
      return {
        ...state,
        dataIsLoaded: false,
        apiIsLoading: false,
      };
    case locationsActions.LOCATIONS_QR_TEMPLATE_CLEAR_STATE:
      return qrTemplateState;
    default:
      return state;
  }
};

const initMapConfig = {
  longitude: center[0],
  latitude: center[1],
  zoom: zoomDefault,
};

const locationsMap = (state = initMapConfig, action) => {
  switch (action.type) {
    case locationsActions.LOCATIONS_SET_VIEW_TO_LOCATION:
      return {
        ...action.data,
      };
    case locationsActions.LOCATIONS_CLEAR_STORE:
      return initMapConfig;
    default:
      return state;
  }
};

const initFilter = {
  id: '',
  name: '',
  isDeleted: false,
};

const locationsFilter = (state = initFilter, action) => {
  switch (action.type) {
    case locationsActions.LOCATIONS_SET_FILTER_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case locationsActions.LOCATIONS_CLEAR_STORE:
      return initFilter;
    default:
      return state;
  }
};

const initState = {
  searchedLocation: '',
  name: '',
  email: '',
  contact_no: '',
  address: '',
  city: '',
  state: '',
  latitude: '',
  longitude: '',
  loading_site_special_instruction: '',
  geofenceRange: '',
  locationType: 2,
  carrierOwnerId: '',
  suspended: '',
  carriers: [],
  ticketTypes: [],
  requiredTicketTypes: [],
  showPreview: false,
  filteredOrders: [],
  loading: false,
  vendors: [],
};

const addLocations = (state = { ...initState }, action) => {
  switch (action.type) {
    case locationsActions.LOCATIONS_SET_LOCATION:
      return {
        ...state,
        ...action.location,
        loading: false,
      };
    case locationsActions.LOCATIONS_SET_LOCATION_COORDINATS:
      return {
        ...state,
        latitude: action.lat,
        longitude: action.long,
      };
    case locationsActions.LOCATIONS_SET_INPUT_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case locationsActions.LOCATIONS_TURN_PREVIEW:
      return {
        ...state,
        showPreview: !state.showPreview,
      };
    case locationsActions.LOCATIONS_CLEAR_MODAL_ADD_LOCATION:
      return initState;
    default:
      return state;
  }
};

export default combineReducers({
  qrTemplate,
  mapConfig: locationsMap,
  filter: locationsFilter,
  modalAddLocation: addLocations,
  modalTiming: locationsTiming,
});
