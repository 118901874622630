import { createSelector } from 'reselect';

const jobs = state => state.resourceReducer.jobs.jobs;
const customerId = state => state.equipmentTrailers.equipmentStatus.customerId;

export const jobsFilter = createSelector(
  [jobs, customerId],
  (jobs, customerId) =>
    jobs.filter(item => !item.status && customerId == item.customer_id),
);

export const findCustomerWellSites = (customerId, wellSites) => {
    return wellSites.filter(site => site.companyOwnerId == customerId);
  };

export const findCustomer = (user, customers) => {
  if (!user || !user.entityType) {
    return [];
  }
  if (user.entityType === 2) {
    return customers.filter(c => c.id === user.entityId);
  }
  return customers;
}