import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'store/actions/Invoice';
import Button from 'components/shared/Button';
import AutomatizeLogo from 'assets/automatize_icon.svg';
import AutomatizeName from 'assets/automatize_name.svg';
import decode from 'jwt-decode';

const Invoice = ({ init, clear, download }) => {
  const { search } = useLocation();
  const { token } = qs.parse(search);
  const decoded = decode(token);
  const { invoiceId } = decoded;

  useEffect(() => {
    init(search);
    return () => clear();
  }, [search, clear, init]);

  return (
    <div className="do-action-wrapper">
      <div className="do-action">
        <div className="do-action__contanier">
          <div className="do-action__container--branding">
            <img
              src={AutomatizeLogo}
              className="do-action__container--branding-logo"
              alt="Automatize logo icon"
            />
            <h3 className="do-action__container--branding-welcome">
              Welcome to
            </h3>
            <img
              src={AutomatizeName}
              className="do-action__container--branding-name"
              alt="Automatize logo name"
            />
          </div>

          <div className="do-action__container--actions">
            {!token ? (
              <>
                <h3 className="do-action__container--branding-welcome">
                  Download Packet Invoice: Error
                </h3>
                <p>
                  The link you clicked is invalid or has expired. If you
                  wouldn't mind: Please contact our support team if you still
                  need to download the file.
                </p>
              </>
            ) : (
              <>
                <h3 className="do-action__container--branding-welcome">
                  Download Packet Invoice #{invoiceId}
                </h3>
                <Button
                  onClick={() => download(1)}
                  testSelector="invoice_download-packet_btn"
                  children="Download Packet"
                  theme="full-width"
                />
                <Button
                  onClick={() => download(2)}
                  testSelector="invoice_download-bulk_btn"
                  children="Download Bulk"
                  theme="full-width"
                />
                <Button
                  onClick={() => download(3)}
                  testSelector="invoice_download-csv_btn"
                  children="Download CSV"
                  theme="full-width"
                />
              </>
            )}
            <a href="/" className="do-action__container--dashbord-link">
              Open Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>

  )
}


const mapStateToProps = state => ({
  invoice: state.invoice,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(actions.clear()),
  init: search => dispatch(actions.init(search)),
  download: type => dispatch(actions.download(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invoice);
