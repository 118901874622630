export default (state = {}, action) => {
    switch (action.type) {
      case 'INVOICE_INIT_DATA':
        return {
          ...state,
          ...action.data,
        };
      case 'INVOICE_CLEAR':
        return {};
      default:
        return state;
    }
  };