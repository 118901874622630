import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { driver } from 'services/driver/service';
import authService from 'services/auth/service';
import { userRole } from 'components/globalInfo/userRoles';
import { chatTemplates } from 'components/globalInfo/chatTemplates';
import { history } from 'helpers/history';

const refreshHeads = heads => ({ type: 'CHAT_REFRESH_HEADS', heads });

const reinitListener = () => (dispatch, getState) => {
};

const init = () => (dispatch, getState) => {
  dispatch(reinitListener());

  // users
  //   .get()
  //   .then(item => {
  //     const user = item.data.map(item => {
  //       item.name = `${item.profile.firstName} ${item.profile.lastName}`;
  //       item.statusLabel = 'User';
  //       return item;
  //     });
  //     const {drivers} = state.lookUp;
  //     const members = user.concat(drivers);
  //     dispatch(readyMembers(members));
  //   })
  //   .catch(() => {
  //     dispatch(readyMembers(state.lookUp.drivers));
  //   });
};

const destroy = () => dispatch => {
};


const setInputValue = (value, name) => ({
  type: 'CHAT_SET_INPUT_VALUE',
  value,
  name,
});
const setChatFile = file => ({ type: 'CHAT_SET_FILE', file });
const unsetChatFile = () => ({ type: 'CHAT_UNSET_FILE' });
const clearChat = () => ({ type: 'CLEAR_CHAT' });

const setChatRoom = member => (dispatch, getState) => {
  history.push(`/${window.location.pathname.split('/')[1]}/chat/${member.id}`);
  const state = getState();
  const set = (chatRoom, messagesCount, member) => ({
    type: 'CHAT_SET_ROOM',
    chatRoom,
    messagesCount,
    member,
  });
  const setAutocomplite = memberAutocomplite => ({
    type: 'CHAT_SET_MEMBER_AUTOCOMPLITE',
    memberAutocomplite,
  });
  const { heads } = state.chat;
  let messagesCount = 0;
  if (heads[member.chat_id]) {
    messagesCount = heads[member.chat_id].count;
  }
  dispatch(set(member.chat_id, messagesCount, member));
  dispatch(
    setAutocomplite({
      orderId: member.order_id,
      code: member.code,
      lastOrderId: member.last_order_id,
      id: member.id,
    }),
  );
  dispatch(getMessages());
};

const getMember = driverId => (dispatch, getState) => {
  const state = getState();
  const { members } = state.chat;
  const member = members.filter(member => member.id == driverId);
  return member;
};

const getMessages = () => (dispatch, getState) => {
};


const saveLoadMoreMessages = message => (dispatch, getState) => {
  const save = messages => ({ type: 'CHAT_SAVE_MESSAGGES', messages });
  const state = getState();
  const messages = message.concat(state.chat.messages);
  dispatch(save(messages));
};

const sendMessage = () => (dispatch, getState) => {
  const send = () => ({ type: 'CHAT_SEND_MESSAGGES' });
  const state = getState();
  const { chat } = state;
  const { memberAutocomplite, chatRoom, text, isFile } = chat;
  if (chatRoom == '') {
    swal('Select chat room');
    return;
  }
  const key = chatRoom.split('_');
  const pushMessage = () => {
    dispatch(send());
    if (key[0] == 'driver') {
      driver
        .sendMessage({ driversId: [memberAutocomplite.id], message: text })
        .then(() => { });
    }
  };
  if (text.trim().length > 0) {
    if (!isFile) {
      pushMessage();
    }
  }
};

const loadMore = () => (dispatch, getState) => {
};

const setChatTemplate = index => (dispatch, getState) => {
  const set = template => ({ type: 'CHAT_SET_TEMPLATE', template });
  const state = getState();
  const { memberAutocomplite } = state.chat;
  const template = chatTemplates(index, memberAutocomplite);
  dispatch(set(template));
};


const actions = {
  init,
  reinitListener,
  destroy,
  setChatRoom,
  sendMessage,
  setInputValue,
  clearChat,
  loadMore,
  setChatTemplate,
  setChatFile,
  unsetChatFile,
  getMember,
};

export default actions;
