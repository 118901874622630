import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PaginationSelect from './PaginationSelect';
import PaginationBack from './PaginationBack';
import PaginationNext from './PaginationNext';
import PaginationLimit from './PaginationLimit';
import PaginationLast from './PaginationLast';
import PaginationFirst from './PaginationFirst';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  withLimit,
  onLimitChange,
  currentLimit,
  isDisabled,
}) => {
  const [pageState, setState] = useState({
    currentPage,
    totalPages,
  });

  useEffect(() => {
    setState(state => ({ ...state, totalPages }));
  }, [totalPages]);

  useEffect(() => {
    setState(state => ({ ...state, currentPage }));
  }, [currentPage]);

  const changePage = newPage => {
    const newState = {
      ...pageState,
      currentPage: newPage,
    };
    setState(newState);
    onPageChange(newState);
  };

  const handleLimitChange = newLimit => {
    changePage(1);
    onLimitChange(newLimit);
  };

  const onSelect = ({ value }) => changePage(value);

  const handleNext = () => changePage(pageState.currentPage + 1);
  const handleBack = () => changePage(pageState.currentPage - 1);
  const handleFirst = () => changePage(1);
  const handleLast = () => changePage(pageState.totalPages);

  return (
    <div className="pagination-new">
      <div className="pagination-new__pages">
        <PaginationFirst
          disabled={isDisabled || pageState.currentPage <= 1}
          onClick={handleFirst}
        />
        <PaginationBack
          disabled={isDisabled || pageState.currentPage <= 1}
          onClick={handleBack}
        />
        Page
        <PaginationSelect
          currentPage={pageState.currentPage}
          totalPages={pageState.totalPages}
          onSelect={onSelect}
          isDisabled={isDisabled}
        />
        of {pageState.totalPages}
        <PaginationNext
          disabled={isDisabled || pageState.currentPage >= pageState.totalPages}
          onClick={handleNext}
        />
        <PaginationLast
          disabled={isDisabled || pageState.currentPage >= pageState.totalPages}
          onClick={handleLast}
        />
      </div>
      {withLimit && (
        <div className="pagination-new__limit">
          Number of results{' '}
          <PaginationLimit
            currentLimit={currentLimit}
            onLimitChange={handleLimitChange}
          />
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  /** Number current page selected */
  currentPage: PropTypes.number,
  /** Number total pages */
  totalPages: PropTypes.number,
  /** Function to Change Page */
  onPageChange: PropTypes.func,
  /** Boolean to limit */
  withLimit: PropTypes.bool,
  /** Function Change Limit */
  onLimitChange: PropTypes.func,
  /** Shown Limit in Pagination */
  currentLimit: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  currentLimit: 10,
  onPageChange: () => null,
  onLimitChange: () => null,
  withLimit: false,
};

export default Pagination;
