import React, { useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { useCommodityOptions } from './hooks';

const JobDesign = ({
  closeAddJobModal,
  addJob,
  formHandlers
}) => {
  const { setStep, setArrayValue, deleteArrayValue, addArrayValue } = formHandlers;
  const { jobDesign, customerId } = addJob;
  const [filteredCommodityOptions, commodityOptions] = useCommodityOptions(
    jobDesign,
    customerId,
  );

  const isDisabled = useMemo(() => {
    const f = jobDesign.map(i => {
      if (
        i.volume > 2000000000 ||
        i.minVolume > 2000000000 ||
        i.loadWeight < 100 ||
        i.loadWeight > 85000
      ) {
        return true;
      }
      return false;
    });
    return f.find(value => value === true);
  }, [jobDesign]);

  const renderJobDesignItem = (item, index) => (
    <Row key={`stage-${index}`}>
      {item.volume > 2000000000 && (
        <div className="alert-input-msg">
          Quantity should be less than 2,000,000,000
        </div>
      )}
      {item.minVolume > 2000000000 && (
        <div className="alert-input-msg">
          Alert should be less than 2,000,000,000
        </div>
      )}
      {(item.loadWeight < 100 || item.loadWeight > 85000) && (
        <div className="alert-input-msg">
          Load quantity should be more than 1,000 and less than 85,000
        </div>
      )}
      <div className="form-group has-feedback col-md-3 p-w-xxs">
        <div className="form-group">
          <Select
            placeholder="Commodity"
            onChange={e => {
              setArrayValue(e.value, index, 'sandTypeId', 'jobDesign');
            }}
            value={commodityOptions.find(k => k.value === item.sandTypeId)}
            options={filteredCommodityOptions}
            required
            testSelector="jobs_add_job-design_sand-type_select"
          />
        </div>
      </div>
      <div className="form-group has-feedback col-md-3 p-w-xxs">
        <Input
          type="number"
          onChange={e => setArrayValue(e, index, 'volume', 'jobDesign')}
          value={item.volume || ''}
          label="Quantity"
          required
          min="0"
          max="2000000000"
          testSelector="jobs_add-job-design_volume_input"
        />
      </div>
      <div className="form-group has-feedback col-md-3 p-w-xxs">
        <Input
          type="number"
          onChange={e => setArrayValue(e, index, 'minVolume', 'jobDesign')}
          value={item.minVolume || ''}
          label="Alert"
          required
          min="0"
          testSelector="jobs_add-job-design_alert_input"
        />
      </div>
      <div className="form-group has-feedback col-md-2 p-w-xxs">
        <Input
          type="number"
          onChange={e => setArrayValue(e, index, 'loadWeight', 'jobDesign')}
          value={item.loadWeight || ''}
          label="Load quantity"
          required
          min="100"
          testSelector="jobs_add_job-design_load-weight_input"
        />
      </div>
      <div className="form-group has-feedback col-md-1 p-w-xxs">
        <Icon
          className="trash-btn-sm"
          style={{
            padding: '12px',
          }}
          icon="trash del-icon"
          onClick={() => deleteArrayValue(index, 'jobDesign')}
        />
      </div>
    </Row>
  );

  return (
    <form className="wizard-big wizard" onSubmit={() => setStep(5)}>
      <Modal.Body>
        <div style={{ padding: '20px 10px' }}>
          <div className="step4">
            <h4 className="title">Job Design</h4>
            <div className="m-b-sm">
              * Load quantity field is editable and the value entered will be
              enforced throughout the platform
            </div>
            <fieldset id="form-p-2" role="tabpanel" className="body">
              {jobDesign.map(renderJobDesignItem)}
            </fieldset>
            <Button
              onClick={() => addArrayValue('jobDesign', { loadWeight: 60000 })}
              theme="small"
              testSelector="jobs-list_add-modal_job-design_add-item_btn">
              <Icon icon="plus" /> Add Stage Design Item
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_job-design_close_btn">
          Close
        </Button>
        <Button
          onClick={() => setStep(3)}
          testSelector="jobs-list_add-modal_job-design_previous_btn">
          Previous
        </Button>
        <Button
          type="submit"
          disabled={isDisabled}
          testSelector="jobs-list_add-modal_job-design_save_btn">
          Next
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default JobDesign;
