import React, { useMemo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import { authorize } from 'components/common/Authorize';

import authService from 'services/auth/service';
import { useOrder } from 'api/v2/orders';
import Timeline from './Timeline';
import Comments from './Comments';
import Transfers from './Transfers';
import Pricing from './Pricing';
import SandTickets from './SandTickets';
import OrderDetailsMap from './Map';
import Financials from './Financials';

const blockStyle = { paddingTop: '15px', minHeight: '300px' };

const OrderTabs = ({ orderId }) => {
  const { data: orderDetails } = useOrder({ orderId });
  const { info: order, transfers } = useMemo(() => orderDetails ?? {}, [
    orderDetails,
  ]);

  const user = authService.getUser();
  const isPricePermission =
    user.entityType === 3 ? user.entityId === order.carrier_id : true;

  return (
    <Tabs defaultActiveKey="geofence" id="order-tabs">
      <Tab eventKey="geofence" title="Geofence" style={blockStyle}>
        <Timeline orderId={orderId} />
      </Tab>
      {authorize({
        forbidden: ['JOB_COORDINATOR'],
        abilityPermissions: [{ resource: 'Pricing', permissions: ['read'] }],
      }) &&
        isPricePermission && (
          <Tab eventKey="pricing" title="Pricing" style={blockStyle}>
            <Pricing orderId={orderId} />
          </Tab>
        )}
      <Tab eventKey="documents" title="Documents" style={blockStyle}>
        <SandTickets orderId={orderId} />
      </Tab>
      <Tab eventKey="map" title="Map" style={blockStyle}>
        <OrderDetailsMap orderId={orderId} />
      </Tab>
      <Tab eventKey="logs" title="Logs" style={blockStyle}>
        <Comments orderId={orderId} />
      </Tab>
      <Tab eventKey="transfers" title="Transfers" style={blockStyle}>
        <Transfers transfers={transfers} />
      </Tab>
      {authorize({
        abilityPermissions: [
          { resource: 'Carrier-documents', permissions: ['update'] },
        ],
      }) && (
        <Tab eventKey="financials" title="Financial" style={blockStyle}>
          <Financials
            orderId={orderId}
            billingStatus={order.billing_status.toString()}
          />
        </Tab>
      )}
    </Tabs>
  );
};
export default OrderTabs;
