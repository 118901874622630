import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

const Stats = ({ loadingTime, unloadingTime, volume }) => {
  const getData = array => ({
    labels: array.map(item => item.range),
    datasets: [
      {
        label: 'orders',
        backgroundColor: '#8cd9c9',
        borderColor: '#588a7f',
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: array.map(item => item.count),
      },
    ],
  });
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h5>Stats</h5>
          </div>
          <div className="ibox-content">
            <div className="col-md-4">
              <h3>Loading time</h3>
              <Bar data={getData(loadingTime)} options={options} />
            </div>
            <div className="col-md-4">
              <h3>Volume</h3>
              <Line data={getData(volume)} options={options} />
            </div>
            <div className="col-md-4">
              <h3>Unloading time</h3>
              <Bar data={getData(unloadingTime)} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stats;
