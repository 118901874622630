import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';

import { useEditDistrictUnit } from 'api/v2/districts';

import actions from 'store/actions/DistrictDetails';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

const EditUnit = ({ districtId, editUnit, showEditModal, closeEditModal, clear }) => {
  const [inputValues, _setInputValues] = useState(editUnit ?? {});
  const setInputValue = (value, name) =>
    _setInputValues(prev => ({ ...prev, [name]: targetValue(value) }));


  const { mutate: editDistrictUnit, isPending: editing } = useEditDistrictUnit(districtId);
  const submitEdit = (e, closeModal) => {
    e.preventDefault();
    editDistrictUnit({
      ...inputValues,
      dollarsPerTon: Number(inputValues.dollarsPerTon),
      id: districtId,
      customerId: Number(inputValues.customerId),
      priority: Number(inputValues.priority),
    }, {
      onSuccess: closeModal
    });
  }

  useEffect(() => () => {
    clear();
  }, []);

  return (
    <Modal
      className="modal-container"
      show={showEditModal}
      onHide={closeEditModal}>
      <Modal.Header>
        <h3>Edit Unit</h3>
      </Modal.Header>
      <form onSubmit={e => submitEdit(e, closeEditModal)}>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'dollarsPerTon')}
                value={inputValues.dollarsPerTon}
                label="Price per unit"
                required
                min="0"
                step="any"
                testSelector="district-details_edit-unit_price-per-unit_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'priority')}
                value={inputValues.priority}
                label="Priority"
                min="0"
                required
                testSelector="district-details_edit-unit_priority_input"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeEditModal}
            disabled={editing}
            colour="white"
            testSelector="district-details_edit-unit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={editing}
            testSelector="district-details_edit-unit_save_btn">
            {editing ? 'Loading' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  editUnit: state.district.editUnit,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(actions.clearEditUnit()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUnit);
