import React from 'react';

import GoogleMap, { AutomatizeMarker } from 'components/shared/GoogleMap';

const DriverDetailsMap = props => {
  const { driver, orders } = props;
  const { id, name, carrier_name, truck_no, longitude, latitude, status } = driver;
  const initialCenter = { lat: latitude, lng: longitude };
  const orderStatus = orders.length > 0 ? orders[orders.length - 1].status : null
  return (
    <div style={{ height: 315, position: 'relative' }}>
      <GoogleMap fitMarkers initialCenter={initialCenter} data-testid='driver-details-map'>
        <AutomatizeMarker
          position={{ lat: latitude, lng: longitude }}
          type="driver"
          status={(orderStatus === 3 || orderStatus === 4 || orderStatus === 6 || orderStatus === 7 || orderStatus === 8) && status ? 'loaded' : 'empty'}
          infoWindow={{
            title: `#${id} ${name}`,
            content: (
              <div className="driver-content">
                <div className="driver-content__columns">
                  <span className="driver-content__columns--item">
                    <span>
                      Carrier:
                      <span className="driver-content--white">
                        {` ${carrier_name}` || ` Unavailable`}
                      </span>
                    </span>
                  </span>
                  <span className="driver-content__columns--item">
                    <span>
                      Truck №:
                      <span className="driver-content--white">
                        {` ${truck_no}` || ` Unavailable`}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            ),
            width: 340,
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default DriverDetailsMap;
