import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input, { Radio, RadioGroup } from 'components/shared/Input';
import Loader from 'components/shared/Loader';
import { connect } from 'react-redux';
import locationsActions from 'store/actions/Locations';
import _ from 'lodash';
import { Row } from 'react-bootstrap';

const defaultState = {
  driver_weight: '',
  bol: '',
};

const defaultDelimitState = {
  delimiter: '',
  driverWeightIndex: 0,
  bolIndex: 1,
  arrivalIndex: 2,
  departureIndex: 3,
};
const displayResult = {
  driverWeightIndex: 'Weight',
  bolIndex: 'Bol',
  arrivalIndex: 'Arrival',
  departureIndex: 'Departure',
};

const QRTemplateModal = ({
  open,
  onClose,
  templateState,
  selectedLocation,
  addTemplate,
}) => {
  const [selectedOption, setSelectedOption] = useState('Radio 1');
  const [currentState, setCurrentState] = useState(defaultState);
  const [keyPair, setKeyPair] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const {
    apiIsLoading,
    delimitedTemplate,
    delimitedTemplate: { delimiter },
    template,
  } = templateState;
  const [currentDelimitState, setCurrentDelimitState] = useState(
    templateState.delimitedTemplate,
  );
  const [result, setResult] = useState('');

  useEffect(() => {
    if (delimiter) {
      setSelectedOption('Radio 2');
    }
  }, [delimiter]);

  const updateKeyPair = obj => {
    const tmpObj = { ...obj };
    if (tmpObj.hasOwnProperty('driver_weight')) {
      delete tmpObj.driver_weight;
    }
    if (tmpObj.hasOwnProperty('bol')) {
      delete tmpObj.bol;
    }
    if (!_.isEmpty(tmpObj)) {
      const arrayOfObjects = [];
      for (const [key, value] of Object.entries(tmpObj)) {
        arrayOfObjects.push({ [key]: value });
      }
      return arrayOfObjects;
    }
    return [];
  };

  useEffect(() => {
    const { bol, driver_weight } = template;
    const subsetTemplate = { bol, driver_weight };
    setCurrentState(subsetTemplate);
    setCurrentDelimitState(delimitedTemplate);
    const tmpKeyPairs = updateKeyPair(template);
    if (tmpKeyPairs.length) {
      setKeyPair(tmpKeyPairs);
    } else {
      setKeyPair([]);
    }
  }, [delimitedTemplate, template]);

  const showResult = state => {
    const data = { ...state };
    delete data.delimiter;
    let largeKey;
    let largeValue = -Infinity;
    for (const key in data) {
      if (data[key] > largeValue) {
        largeKey = key;
        largeValue = data[key];
      }
    }
    const newArray = new Array(largeValue);
    for (const key in data) {
      newArray[data[key]] = displayResult[key];
    }
    setResult(newArray.join(currentDelimitState?.delimiter));
  };

  useEffect(() => {
    showResult(currentDelimitState);
  }, [currentDelimitState]);

  const determineAddDisabled = () => {
    let disabled = false;
    if (selectedOption === 'Radio 1') {
      if (!currentState?.driver_weight && !currentState?.bol) {
        disabled = true;
      }
    }
    if (selectedOption === 'Radio 2') {
      if (!currentDelimitState?.delimiter) {
        disabled = true;
      }
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [currentState, currentDelimitState]);

  const handleKeyPair = (index, name, value) => {
    const newArr = [...keyPair];
    if (name === 'add') {
      newArr.push({ '': '' });
    }
    if (name === 'delete') {
      newArr.pop();
    }
    setKeyPair(newArr);
  };

  const jsonFormat = () => {
    if (apiIsLoading) {
      return <Loader />;
    }
    return (
      <Row>
        <div className="form-group col-md-6">
          <Input
            label="QR Key code value"
            onChange={({ target: { value } }) => {
              setCurrentState(cs => ({
                ...cs,
                driver_weight: value,
              }));
            }}
            value={currentState?.driver_weight || ''}
            testSelector="locations_qr-template_template_weight_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="Matching value"
            value="driver_weight"
            readonly
            testSelector="locations_qr-template_template_weightRO_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="QR Key code value"
            onChange={({ target: { value } }) => {
              setCurrentState(cs => ({
                ...cs,
                bol: value,
              }));
            }}
            value={currentState?.bol || ''}
            testSelector="locations_qr-template_template_bol_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="Matching value"
            value="bol"
            readonly
            testSelector="locations_qr-template_template_bolRO_input"
          />
        </div>
        {keyPair.map((element, index) => (
          <Row key={`qr-template-${index}`}>
            <div className="form-group col-md-6">
              <Input
                label="QR Key code value"
                onChange={({ target: { value } }) => {
                  setKeyPair(kp => {
                    const tmp = [...kp];
                    tmp[index] = {
                      [`${value}`]: Object.values(tmp[index])[0],
                    };
                    return tmp;
                  });
                }}
                value={Object.keys(element)[0] || ''}
                testSelector="locations_qr-template_template_key_input"
              />
            </div>
            <div className="form-group col-md-6">
              <Input
                label="Matching value"
                onChange={({ target: { value } }) => {
                  setKeyPair(kp => {
                    const tmp = [...kp];
                    tmp[index] = { [Object.keys(tmp[index])[0]]: `${value}` };
                    return tmp;
                  });
                }}
                value={Object.values(element)[0] || ''}
                testSelector="locations_qr-template_template_value_input"
              />
            </div>
          </Row>
        ))}
        <div className="form-group col-md-6">
          <Button
            onClick={() => handleKeyPair(0, 'add')}
            theme="small"
            testSelector="qrtemplate_modal_add_btn">
            + Add Key Pair
          </Button>
          <Button
            onClick={() => handleKeyPair(0, 'delete')}
            theme="small"
            testSelector="qrtemplate_modal_remove_btn">
            - Remove Last Key Pair
          </Button>
        </div>
        <div className="form-group col-md-6">
          <RadioGroup
            name="radioGroup"
            onChange={state => setSelectedOption(state.target.value)}>
            <Radio
              label="JSON Format"
              value="Radio 1"
              isChecked={selectedOption === 'Radio 1'}
            />
            <Radio
              label="Delimiter Format"
              value="Radio 2"
              isChecked={selectedOption === 'Radio 2'}
            />
          </RadioGroup>
        </div>
      </Row>
    );
  };

  const delimitedFormat = () => {
    if (apiIsLoading) {
      return <Loader />;
    }
    return (
      <Row>
        <div className="form-group col-md-6">
          <Input
            label="Key"
            value="Weight"
            readonly
            testSelector="locations_qr-template_delimit_weightRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Delimiter"
            maxlength="1"
            onChange={({ target: { value } }) => {
              setCurrentDelimitState(cs => ({
                ...cs,
                delimiter: value,
              }));
            }}
            value={currentDelimitState?.delimiter || ''}
            testSelector="locations_qr-template_delimiter_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Index"
            type="number"
            min={0}
            step={1}
            onChange={({ target: { value } }) => {
              setCurrentDelimitState(cs => ({
                ...cs,
                driverWeightIndex: Number(value),
              }));
            }}
            value={Number(currentDelimitState?.driverWeightIndex)}
            testSelector="locations_qr-template_driverWeightIndex_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="Key"
            value="Bol"
            readonly
            testSelector="locations_qr-template_delimit_BolRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Delimiter"
            readonly
            disabled
            value={currentDelimitState?.delimiter || ''}
            testSelector="locations_qr-template_delimiterRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Index"
            type="number"
            min={0}
            step={1}
            onChange={({ target: { value } }) => {
              setCurrentDelimitState(cs => ({
                ...cs,
                bolIndex: Number(value),
              }));
            }}
            value={Number(currentDelimitState?.bolIndex)}
            testSelector="locations_qr-template_bolIndex_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="Key"
            value="Arrival"
            readonly
            testSelector="locations_qr-template_delimit_ArrivalRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Delimiter"
            readonly
            disabled
            value={currentDelimitState?.delimiter || ''}
            testSelector="locations_qr-template_delimiterRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Index"
            type="number"
            min={0}
            step={1}
            onChange={({ target: { value } }) => {
              setCurrentDelimitState(cs => ({
                ...cs,
                arrivalIndex: Number(value),
              }));
            }}
            value={Number(currentDelimitState?.arrivalIndex)}
            testSelector="locations_qr-template_arrivalIndex_input"
          />
        </div>
        <div className="form-group col-md-6">
          <Input
            label="Key"
            value="Departure"
            readonly
            testSelector="locations_qr-template_delimit_DepartureRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Delimiter"
            readonly
            disabled
            value={currentDelimitState?.delimiter || ''}
            testSelector="locations_qr-template_delimiterRO_input"
          />
        </div>
        <div className="form-group col-md-3">
          <Input
            label="Index"
            type="number"
            min={0}
            step={1}
            onChange={({ target: { value } }) => {
              setCurrentDelimitState(cs => ({
                ...cs,
                departureIndex: Number(value),
              }));
            }}
            value={currentDelimitState?.departureIndex || ''}
            testSelector="locations_qr-template_departureIndex_input"
          />
        </div>
        <div className="form-group col-md-12">
          <Input
            label="Result"
            readonly
            value={result || ''}
            testSelector="locations_qr-template_resultRO_input"
          />
        </div>

        <div className="form-group col-md-6" />
        <div className="form-group col-md-6">
          <RadioGroup
            name="radioGroup"
            onChange={state => setSelectedOption(state.target.value)}>
            <Radio
              label="JSON Format"
              value="Radio 1"
              isChecked={selectedOption === 'Radio 1'}
            />
            <Radio
              label="Delimiter Format"
              value="Radio 2"
              isChecked={selectedOption === 'Radio 2'}
            />
          </RadioGroup>
        </div>
      </Row>
    );
  };

  const handleSendAndClose = () => {
    const tmpOBJ = {};
    keyPair.forEach(element => {
      Object.assign(tmpOBJ, element);
    });
    Object.assign(tmpOBJ, currentState);
    const params = {
      template: JSON.stringify(tmpOBJ),
      location: selectedLocation,
    };

    if (selectedOption === 'Radio 2') {
      params.delimiter = currentDelimitState?.delimiter || '';
      params.template = result || '';
    }

    addTemplate(params);
    onClose();
    setCurrentState(defaultState);
    setCurrentDelimitState(defaultDelimitState);
    setKeyPair([]);
    setSelectedOption('Radio 1');
  };
  const footer = (
    <>
      <Button
        colour="white"
        onClick={() => {
          onClose();
          setCurrentState(defaultState);
          setCurrentDelimitState(defaultDelimitState);
          setKeyPair([]);
          setSelectedOption('Radio 1');
        }}
        testSelector="locations_qrtemplate-modal_close_btn">
        Close
      </Button>
      <Button
        onClick={() => handleSendAndClose()}
        disabled={saveDisabled}
        testSelector="locations_qrtemplate-modal_save_btn">
        Save
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="QR Template"
      body={selectedOption === 'Radio 1' ? jsonFormat() : delimitedFormat()}
      footer={footer}
    />
  );
};

const mapStateToProps = state => ({
  templateState: state.locations.qrTemplate,
});

const mapDispatchToProps = dispatch => ({
  addTemplate: params => dispatch(locationsActions.addQRTemplate(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QRTemplateModal);
