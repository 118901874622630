import React from 'react';

const DriverInfoWindow = ({ driver }) => {
  return (
    <div className="driver-content">
      <div className="driver-content__columns">
        <span className="driver-content__columns--item">
          Carrier:
          <span className="driver-content--white">
            {` ${driver.carrier_name}` || ` Unavailable`}
          </span>
        </span>
        <span className="driver-content__columns--item">
          Truck №:
          <span className="driver-content--white">
            {` ${driver.truck_no}` || ` Unavailable`}
          </span>
        </span>
      </div>
    </div>
  );
};

export default DriverInfoWindow;
