import { useMemo, useState } from 'react';

export const useStorageTypeModalControls = () => {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handlers = useMemo(
    () => ({
      openUpdate: storageType => {
        setData({
          storageType,
          action: 'edit'
        });
        setIsOpen(true);
      },
      openAdd: () => setIsOpen(true), 
      close: () => {
        setIsOpen(false);
        setData({});
      },
    }),
    [],
  );

  return {
    isOpen,
    data,
    ...handlers,
  };
};
