import React from 'react';
import { Modal } from 'react-bootstrap';
import { operationTypes } from 'components/globalInfo/jobOperationType';
import Button from 'components/shared/Button';

const JobOperationType = ({ closeAddJobModal, setModalValue }) => {
  const renderJobTypeButton = item =>
    !!Number(item) && (
      <Button
        key={`jobOperationType-${operationTypes[item].label}`}
        testSelector="jobs-list_add-modal_job-op-type_set-value_btn"
        onClick={() => {
          setModalValue('jobOperationType')(operationTypes[item].value)
        }}
        disabled={operationTypes[item].value !== 1}>
        {operationTypes[item].label}
      </Button>
    );

  return (
    <form className="wizard-big wizard">
      <Modal.Body>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}>
          {Object.keys(operationTypes).map(renderJobTypeButton)}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_job-op-type_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default JobOperationType;
