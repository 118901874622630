/* eslint-disable no-param-reassign */
export const androidItems = drivers => {
  const androidList = Object.entries(
    drivers
      .filter(driver => driver.deviceType === 0)
      .map(i => i.deviceVersion)
      .reduce((item, index) => {
        item[index] = (item[index] || 0) + 1;
        return item;
      }, {}),
  );

  const itemsAndroid = androidList.map(d => {
    return {
      key: d[0],
      data: {
        version: () => d[0],
        count: () => d[1],
      },
    };
  });
  return itemsAndroid;
};

export const IOSItems = drivers => {
  const iosList = Object.entries(
    drivers
      .filter(driver => driver.deviceType === 1)
      .map(i => i.deviceVersion)
      .reduce((item, index) => {
        item[index] = (item[index] || 0) + 1;
        return item;
      }, {}),
  );

  const itemsIOS = iosList.map(d => {
    return {
      key: d[0],
      data: {
        version: () => d[0],
        count: () => d[1],
      },
    };
  });
  return itemsIOS;
};

export const devicesPercents = drivers => {
  const androidTotalCount = drivers.filter(item => item.deviceType === 0)
    .length;
  const iosTotalCount = drivers.filter(item => item.deviceType === 1).length;
  const totalDevicesCount = androidTotalCount + iosTotalCount;
  const androidPercent = Math.round(
    (androidTotalCount * 100) / totalDevicesCount,
  );
  const iosPercent = 100 - androidPercent;
  return {
    androidPercent,
    iosPercent,
  };
};
