import React, { useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/Equipments';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import eventTarget from 'utils/dispatchWithFieldValue';

const Status = ({
  equipmentStatus,
  closeModalEquipment,
  showStatus,
  closeStatus,
  sandTypes,
  isLoadedContainerVendors,
  vendors,
  setStatus,
  setSandTypeId,
  setVendorId,
  setWeight,
  submitStatus,
}) => {
  const { sandTypeId, sandVendorId, weight, status } = equipmentStatus;

  useEffect(() => () => closeModalEquipment(), []);

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showStatus}
      onHide={closeStatus}>
      <form onSubmit={submitStatus} className="m-t" role="form">
        <Modal.Header>
          <h4 className="modal-title">Edit equipment status</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Status"
                options={[
                  { value: 3, label: 'Empty' },
                  { value: 2, label: 'Unloading' },
                  { value: 1, label: 'Staged' },
                ]}
                onChange={setStatus}
                value={status}
                required
                testSelector="equipment-trailers_status_status_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              {status.value != 3 ? (
                <Select
                  label="Commodity Type"
                  options={sandTypes.map(sandType => ({
                    value: sandType.id,
                    label: sandType.name,
                  }))}
                  onChange={setSandTypeId}
                  value={sandTypeId}
                  required
                  testSelector="equipment-trailers_status_sandtype_select"
                />
              ) : (
                <Select
                  label="Commodity Type"
                  isDisabled
                  testSelector="equipment-trailers_status_sandtype-disabled_select"
                />
              )}
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              {status.value != 3 ? (
                <Select
                  label="Commodity Vendor"
                  options={
                    isLoadedContainerVendors &&
                    vendors.map(vendor => ({
                      value: vendor.id,
                      label: vendor.name,
                    }))
                  }
                  onChange={setVendorId}
                  value={sandVendorId}
                  required
                  testSelector="equipment-trailers_status_vendor_select"
                />
              ) : (
                <Select
                  label="Commodity Vendor"
                  isDisabled
                  testSelector="equipment-trailers_status_vendor-disabled_select"
                />
              )}
            </div>
            <div className="form-group has-feedback col-md-6">
              <div className="form-group">
                {status.value != 3 ? (
                  <Input
                    label="Quantity"
                    type="number"
                    onChange={setWeight}
                    value={weight}
                    required
                    testSelector="equipment-trailers_status_weight_input"
                  />
                ) : (
                  <Input
                    label="Quantity"
                    type="number"
                    onChange={setWeight}
                    value={weight}
                    disabled
                    testSelector="equipment-trailers_status_weight-disabled_input"
                  />
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeStatus}
            colour="white"
            testSelector="equipments_status_close_btn">
            Close
          </Button>
          <Button type="submit" testSelector="equipments_status_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  equipmentStatus: state.equipmentTrailers.equipmentStatus,
  sandTypes: state.lookUp.sandTypes,
  isLoadedContainerVendors:
    state.resourceReducer.containerVendors.isLoadedContainerVendors,
  vendors: state.resourceReducer.containerVendors.vendors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setStatus: status => {
    dispatch(actions.setStatus(status));
  },
  setSandTypeId: sandType => {
    dispatch(actions.setSandTypeId(sandType));
  },
  setVendorId: vendor => {
    dispatch(actions.setVendorId(vendor));
  },
  setWeight: eventTarget(actions.setWeight, dispatch),
  submitStatus: e => {
    e.preventDefault();
    dispatch(
      actions.submitStatus(ownProps.closeStatus, ownProps.equipmentType),
    );
  },
  closeModalEquipment: () => dispatch(actions.closeModalEquipment()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Status);
