import adminSettings from './AdminSettings';
import adminCarriers from './AdminCarriers';
import alerts from './Alerts';
import carriers from './Carriers';
import { chat } from './Chat';
import creditMemos from './CreditMemos';
import customers from './Customers';
import customerDetails from './CustomerDetails';
import database from './ManageDatabase';
import { districts } from './Districts';
import district from './DistrictDetails';
import drivers from './Drivers';
import { driverProfile } from './DriverDetails';
import { equipmentTrailers } from './Equipments';
import { exportPage } from './Export';
import firebase from './Firebase';
import invoice from './Invoice';
import invoices from './Invoices';
import { jobDetails } from './JobDetails/index';
import jobs from './Jobs';
import jobsList from './JobsList';
import jobStats from './JobStats';
import locations from './Locations';
import login from './Login';
import { lookUp } from './LookUp';
import manageCustomers from './ManageCustomers';
import notarizations from './NotarizationsReport';
import orders from './Orders';
import orderActions from './OrderActions';
import { orderDetails } from './OrderDetails';
import orderList from './OrdersList';
import orderTickets from './OrderTickets';
import ordersFilters from './OrdersFilters';
import po from './Po';
import { queue } from './Queue';
import resetPass from './ResetPassword';
import roles from './Roles';
import { sandSiteDetails } from './SandSiteDetails';
import locationDetails from './LocationDetails';
import { sandSiteSchedule } from './SandSiteSchedule';
import search from './SearchResult';
import supplyChainMap from './SupplyChainMap';
import { trackDrivers } from './TrackDrivers';
import userProfile from './UserProfile';
import users from './Users';
import { wellSiteDetails } from './WellSiteDetails';
import auditSearch from './AuditSearch';
import { jobPlans } from './JobPlans';

export default {
  adminSettings,
  adminCarriers,
  alerts,
  auditSearch,
  carriers,
  chat,
  customers,
  customerDetails,
  database,
  districts,
  district,
  driversNew: drivers,
  driverProfile,
  equipmentTrailers,
  exportPage,
  firebase,
  invoice,
  invoices,
  jobPlans,
  creditMemos,
  jobDetails,
  jobsNew: jobs,
  jobsList,
  jobStats,
  locations,
  login,
  lookUp,
  manageCustomers,
  notarizations,
  orders,
  orderActions,
  orderDetails,
  orderList,
  orderTickets,
  ordersFilters,
  po,
  queue,
  resetPass,
  roles,
  sandSiteDetails,
  locationDetails,
  sandSiteSchedule,
  search,
  supplyChainMap,
  trackDrivers,
  userProfile,
  users,
  wellSiteDetails,
};
