/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import Icon from 'components/shared/Icon';
import NavigationMenu from './NavigationMenu';
import { history } from '../../../helpers/history';

const NavigationItem = ({
  title,
  path,
  icon = null,
  downIcon = null,
  items,
  parentPath,
}) => {
  const match = useRouteMatch();
  const location = useLocation();

  const [navOpen, setNavOpen] = useState(location.pathname.includes(path));

  useEffect(() => {
    const routeListener = history.listen(listener =>
      setNavOpen(listener.pathname.includes(path)),
    );
    return routeListener();
  }, [path]);

  const toggleNavigationMenu = () => {
    setNavOpen(!navOpen);
  };

  const combinedPath = match.path + (parentPath || '') + path;
  const iconMarkup = { __html: icon };

  return (
    <li
      className={`navigation__item ${(items.length > 0 &&
        'navigation__item--has-children') ||
        ''} ${(location.pathname.includes(path) &&
        'navigation__item--active') ||
        ''} ${navOpen ? 'navigation__item--open' : ''}`}>
      {(items.length > 0 && (
        <>
          <span
            onClick={toggleNavigationMenu}
            onKeyDown={toggleNavigationMenu}
            role="button"
            tabIndex={0}
            className="navigation__link">
            {icon && (
              <Icon
                className="icon__custom"
                icon={icon}
                dangerouslySetInnerHTML={iconMarkup}
              />
            )}

            <span
              className="navigation__link--text"
              data-test="navigation_item_text">
              {title}
            </span>

            {downIcon && <Icon icon="down" />}
          </span>

          <NavigationMenu open={navOpen} listItems={items} />
        </>
      )) || (
        <Link to={combinedPath} className="navigation__link">
          {icon && (
            <Icon
              className="icon__custom"
              icon={icon}
              dangerouslySetInnerHTML={iconMarkup}
            />
          )}
          <span
            className="navigation__link--text"
            data-test="navigation_item_text">
            {title}
          </span>
        </Link>
      )}
    </li>
  );
};
export default NavigationItem;
