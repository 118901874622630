import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';

const TextInput = ({
  className,
  disabled = false,
  label,
  noMargin = false,
  onChange,
  required = false,
  testSelector,
  value,
  onFocus,
  onBlur,
  readonly,
  maxlength = undefined,
  disallowedChars,
}) => {
  const handleChange = event => {
    if (
      disallowedChars &&
      new RegExp(`[${disallowedChars}]`).test(event?.target?.value ?? event)
    ) {
      return;
    }
    onChange(event);
  };

  return (
    <InputWrapper
      inputType="text"
      id={testSelector}
      className={className}
      noMargin={noMargin}
      disabled={disabled}>
      <input
        type="text"
        className="input__text__input"
        disabled={disabled}
        name={testSelector}
        id={testSelector}
        placeholder=" "
        value={value}
        onChange={handleChange}
        data-testid={testSelector}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readonly}
        maxLength={maxlength}
      />
      <span className="input__text__label">
        {label}
        {required && <span className="input__text__label--required"> *</span>}
      </span>
    </InputWrapper>
  );
};

TextInput.propTypes = {
  /** Custom Class Name */
  className: PropTypes.string,
  /** Disabled boolean */
  disabled: PropTypes.bool,
  /** Label for input */
  label: PropTypes.string.isRequired,
  /** Boolean to remove margin */
  noMargin: PropTypes.bool,
  /** Function to call when changing the input */
  onChange: PropTypes.func.isRequired,
  /** Boolean to make input required and add * to label */
  required: PropTypes.bool,
  /** HTML data attribute for test selecting element  */
  testSelector: PropTypes.string,
  /** Value of input */
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disallowedChars: PropTypes.string,
};

export default TextInput;
