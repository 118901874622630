import React, { useState, useMemo } from 'react';

export const GoogleMapContext = React.createContext();

export const GoogleMapProvider = ({ children, fitMarkers }) => {
  const [map, setMap] = useState(null);
  const [config, setConfig] = useState({
    fitMarkers,
  });

  const contextParams = useMemo(
    () => ({
      map,
      setMap,
      config,
      setConfig,
    }),
    [config, map],
  );

  return (
    <GoogleMapContext.Provider value={contextParams}>
      {children}
    </GoogleMapContext.Provider>
  );
};