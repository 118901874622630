import React from 'react';
import { convertTime } from 'utils/convertTime';
import Link from 'components/common/Link';

const OrdersList = ({
  drivers,
  locationType,
  switchLocationType,
}) => (
  <>
    {drivers.length === 0 && (
      <div className="col-lg-6" style={{ padding: 15 }}>
        <span>No orders for this </span>
        {`${switchLocationType(locationType).title.toLowerCase()}.`}
      </div>
    )}
    {drivers.length > 0 && (
      <div className="col-lg-6" style={{ maxHeight: 400, overflowY: 'scroll' }}>
        {drivers
          .sort((a, b) => {
            const dateA = new Date(a.eta);
            const dateB = new Date(b.eta);
            return dateB - dateA;
          })
          .map(driver => (
            <div key={driver.id} className="ibox ibox-order">
              <div className="ibox-title">
                <div style={{ clear: 'both' }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                      <h5 style={{ cursor: 'pointer' }}>
                        <span>
                          Order
                          <Link
                            {...{ id: driver.order_id, to: 'order-details' }}>
                            {' '}
                            #{driver.order_id}{' '}
                          </Link>
                        </span>
                        /<span> Driver </span>
                        <Link {...{ id: driver.id, to: 'driver-details' }}>
                          #{driver.id}
                        </Link>{' '}
                        <span className="text-navy">{driver.name}</span>
                      </h5>
                    </div>
                    <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                      <span style={{ float: 'right' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ibox-content-order">
                <div className="row">
                  <div className="col-md-5 col-xs-5 col-lg-5">
                    <div className="col-lg-12" style={{ paddingTop: 0 }}>
                      <strong>Truck: </strong> {driver.truck_no}
                    </div>

                    <div className="col-lg-12">
                      <strong>
                        {switchLocationType(locationType).type} type:{' '}
                      </strong>{' '}
                      {driver.commodity?.name ? driver.commodity.name : 'Unknown'}
                    </div>
                  </div>
                  <div className="col-md-7 col-xs-7 col-lg-7">
                    <div className="col-lg-12">
                      <strong>
                        Quantity:
                      </strong>{' '}
                      {driver.weight}
                    </div>
                    <div className="col-lg-12">
                      <strong>Unload appt: </strong>{' '}
                      {convertTime(driver.unload_appointment)}
                    </div>
                    {convertTime(driver.eta) !== 'Invalid date' && (
                      <div className="col-lg-12">
                        <strong>ETA: </strong> {convertTime(driver.eta)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    )}
  </>
);

export default OrdersList;
