import { combineReducers } from '@reduxjs/toolkit';

const initData = {
    file: undefined,
    name: '',
    selectedDocumentId: '',
    uploading: false,
  };
  
  export const data = (state = initData, action) => {
    switch (action.type) {
      case 'JOB_DETAILS_SET_VALUE':
        return {
          ...state,
          [action.name]: action.value,
        };
      case 'JOB_DETAILS_UPLOAD_REQUEST':
        return {
          ...state,
          uploading: true,
        };
      case 'JOB_DETAILS_UPLOAD_SUCCESS':
        return initData;
      case 'JOB_DETAILS_JOB_DOCUMENTS_CLEAR_STATE':
        return initData;
      case 'JOB_DETAILS_JOB_DOCUMENTS_SELECT_DOCUMENT':
        return {
          ...state,
          selectedDocumentId: action.id,
        };
      default:
        return state;
    }
  };

  const initPreview = {
    img: '',
    contentType: '',
    nativeImg: '',
  };
  
  export const preview = (state = initPreview, action) => {
    switch (action.type) {
      case 'JOB_DETAILS_DOCUMENTS_INIT_PREVIEW':
        return {
          ...state,
          img: action.image,
          contentType: action.contentType,
          nativeImg: action.nativeImg,
        };
      case 'JOB_DETAILS_DOCUMENTS_CLEAR':
        return initPreview;
      default:
        return state;
    }
  };

export const documents = combineReducers({
    data,
    preview
  });