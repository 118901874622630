import numeral from 'numeral';

import { findPaymentItemType } from 'utils/paymentItemCalculations.js';

export const convertNumberToCurrency = number => {
  return numeral(number / 100).format('$0,0.00');
};

export const convertNumberToCurrencyFromAmount = number => {
  return numeral(number).format('$0,0.00');
};

const calculatePerBBLPrice = (bbls, price) => {
  return Number(price) / Number(bbls)
}

export const getFormattedCustomPrice = (item, order) => {
  const customPrice = convertNumberToCurrencyFromAmount(item.customerPrice);
  if (item.type === 1) {
    let type = 'per mile';
    let price = order.customerPrice;
    if (order.operation_type === 12) {
      if (item.customerPricePerTon) {
        type = 'per ton';
        price = item.customerPricePerTon;
      } else if (item.rangeType === 4) {
        type = 'per bbl';
        price = calculatePerBBLPrice(order.driver_weight, item.customerPrice);
      } else {
        type = 'per hour';
        price = item.customerPricePerHour || 0;
      }
    } else {
      type = findPaymentItemType(item);

      if (
        item.type === 1
        && item.rangeType === 5
        && item.billableValueField === 1
      ) {
        price = item.customerPricePerHour || 0
      } else {
        price = item.customerPricePerTon;
      }
    }
    const customPerPrice = convertNumberToCurrencyFromAmount(price);
    return `${customPrice} - ${customPerPrice} ${type}`;
  }
  return customPrice;
};

export const getFormattedCarrierPrice = (item, order) => {
  const carrierPrice = convertNumberToCurrencyFromAmount(item.carrierPrice);
  if (item.type === 1) {
    let type = 'per mile';
    let price = order.carrierPrice;
    if (order.operation_type === 12) {
      if (item.carrierPricePerTon) {
        type = 'per ton';
        price = item.carrierPricePerTon;
      } else if (item.rangeType === 4) {
        type = 'per bbl';
        price = calculatePerBBLPrice(order.driver_weight, item.carrierPrice);
      } else {
        type = 'per hour';
        price = item.carrierPricePerHour || 0;
      }
    } else {
      type = findPaymentItemType(item);

      if (
        item.type === 1
        && item.rangeType === 5
        && item.billableValueField === 1
      ) {
        price = item.carrierPricePerHour || 0
      } else {
        price = item.carrierPricePerTon;
      }
    }
    const carrierPerPrice = convertNumberToCurrencyFromAmount(price);
    return ` (${carrierPrice} - ${carrierPerPrice} ${type})`;
  }
  return ` (${carrierPrice})`;
};
