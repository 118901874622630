import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Header from 'components/common/Header';
import Profile from './Profile';
import Logins from './Logins';
import Settings from './Settings';

const UpdateProfile = () => {
  const [tabKey, changeTab] = useState(1);
  return (
    <div>
      <Header title="Profile" />
      <Tabs activeKey={tabKey} onSelect={changeTab} id="controlled-tab-example">
        <Tab eventKey={1} title="Profile">
          {tabKey == 1 && <Profile />}
        </Tab>
        <Tab eventKey={2} title="Logins">
          {tabKey == 2 && <Logins />}
        </Tab>
        <Tab eventKey={3} title="Settings">
          {tabKey == 3 && <Settings />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default UpdateProfile