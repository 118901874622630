import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from 'store/actions/DriverDetails';

import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import DriverInf from './DriverInfo';
import MoreInformation from './MoreInformation';

const DriverDetails = ({
  driverInf,
  isLoadedDriverProfile,
  getDriverProfile,
  clearStore,
}) => {
  const { driverId } = useParams();

  useEffect(() => {
    getDriverProfile(driverId);
    return () => {
      clearStore();
    };
  }, []);

  if (!isLoadedDriverProfile) {
    return <Loader />;
  }

  return (
    <div>
      <Header title="Driver Details" />
      <Row>
        {driverInf.driver[0] ? (
          <>
            <DriverInf
              key="driver-info"
              driverInf={driverInf.driver[0]}
              driverDistricts={driverInf.districts}
              carrierId={driverInf.driver[0].carrier_id}
              orders={driverInf.driverAcceptedOrders}
            />
            <MoreInformation
              key="more-info"
              ratings={driverInf.driverRatings}
              driverInf={driverInf.driver[0]}
              orders={driverInf.driverAcceptedOrders}
              rejectedOrders={driverInf.driverRejectedOrders}
              logs={driverInf.logs}
            />
          </>
        ) : (
          <div className="wrapper-content project-manager text-center">
            <h3>Driver not found, deleted or you have no permissions</h3>
          </div>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  driverInf: state.driverProfile.driver.driverInf,
  isLoadedDriverProfile: state.driverProfile.driver.isLoadedDriverProfile,
});

const mapDispatchToProps = dispatch => ({
  getDriverProfile: id => dispatch(actions.getDriverProfile(id)),
  clearStore: () => dispatch(actions.clearStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverDetails);
