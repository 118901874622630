import React from 'react';

const LocationAlways = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path fill="#98E456" fillRule="nonzero" d="M9.791 13.51c.21.633.61.655.892.052l3.204-6.865c.281-.605.02-.865-.584-.583L6.438 9.317c-.604.282-.58.681.052.892l2.476.825.825 2.476z"/>
        <circle cx="10" cy="10" r="10" fillRule="nonzero" stroke="#98E456"/>
    </g>
  </svg>
);

export default LocationAlways;