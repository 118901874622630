import { combineReducers } from '@reduxjs/toolkit';

const init = {
  districtsList: [],
  drivers: [],
  orders: [],
  filter: '',
  add: {
    name: '',
    districtDetails: '',
    isCarrierRatioEnabled: false,
    isManualDispatchEnabled: false,
  },
  dataLoaded: false,
};

const info = (state = init, action) => {
  switch (action.type) {
    case 'DISTRICTS_DETAILS_RESPONSE_SUCCESS':
      return {
        ...state,
        [action.array]: action.data,
      };
    case 'DISTRICTS_DETAILS_ALL_LOADED':
      return {
        ...state,
        dataLoaded: true,
      }
    case 'DISTRICTS_DETAILS_SET_FILTER':
      return {
        ...state,
        filter: action.value,
      };
    case 'DISTRICTS_DETAILS_SET_MODAL_UPDATE_VALUE':
      return {
        ...state,
        add: {
          ...state.add,
          [action.name]: action.value,
        },
      };
    case 'DISTRICTS_DETAILS_CLEAR_MODAL_ADD':
      return {
        ...state,
        add: init.add,
      };
    case 'DISTRICTS_DETAILS_DELETE_DISTRICT':
      return init;
    default:
      return state;
  }
};

const districtsDefaultState = {
  fullDistrictsList: [],
};

const fullDistrictsList = (state = districtsDefaultState, data) => {
  
  switch (data.type) {
    case 'DISTRICTS_REQUESTED':
      return {
        ...state,
      };
    case 'DISTRICTS_REQUEST_SUCCEEDED':
      return {
        ...state,
        fullDistrictsList: data.districts,
      };
    case 'DISTRICTS_REQUEST_FAILED':
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const districts = combineReducers({
  info,
  fullDistrictsList
});
