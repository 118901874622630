import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import {
  DatePicker as SimpleDatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DatePicker from 'components/shared/DatePicker';
import PropTypes from 'prop-types';
import Icon from 'components/shared/Icon';

const UIDatePicker = ({
  isDateTimePicker = false,
  isTimePicker = false,
  isSimpleDatePicker = false,
  variant,
  label,
  value,
  forceValue,
  className,
  disabled,
  disablePast,
  is12Hr,
  required,
  defaultTime = new Date(),
  onAccept,
  timeFormat = true,
  ...inputAttributes
}) => {
  const [selectedDate, handleDateChange] = useState(defaultTime);
  if (value === 'Invalid date') {
    value = null;
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className="form-input__datepicker">
        {(isDateTimePicker && (
          <DatePicker
            label={label}
            className="form-input__calendar2"
            onChange={e => {
              onAccept(e);
            }}
            value={value}
            dateFormat="YYYY-MM-DD"
            timeFormat={timeFormat}
          />
        )) ||
          (isTimePicker && (
            <TimePicker
              clearable
              ampm={false}
              label={label}
              value={forceValue ? value || null : selectedDate}
              onChange={e => {
                onAccept(e);
              }}
              disabled={disabled}
              required={required}
              variant={variant}
              autoOk
              className={`form-input__calendar ${className}`}
              {...inputAttributes}
            />
          )) ||
          (isSimpleDatePicker && (
            <SimpleDatePicker
              variant={variant}
              value={forceValue ? value || null : selectedDate}
              onChange={e => {
                onAccept(e);
              }}
              label={label}
              className={`form-input__calendar ${className}`}
              format="DD MMMM YYYY"
              disabled={disabled}
              disablePast={disablePast}
              autoOk
              showTodayButton
              required={required}
              {...inputAttributes}
            />
          ))}

        <Icon icon="calendar" colour="white" />
      </div>
    </MuiPickersUtilsProvider>
  );
};

UIDatePicker.propTypes = {
  isDateTimePicker: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  forceValue: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  is12Hr: PropTypes.bool,
  required: PropTypes.bool,
  isTimePicker: PropTypes.bool,
  defaultTime: PropTypes.instanceOf(Date),
};

UIDatePicker.defaultProps = {
  isDateTimePicker: false,
  variant: 'dialog',
  label: 'Date',
  value: '',
  forceValue: false,
  className: '',
  disabled: false,
  disablePast: false,
  is12Hr: false,
  required: false,
  isTimePicker: false,
  defaultTime: new Date(),
};

export default UIDatePicker;
