import React, { useEffect, useState } from 'react';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import ToolTip from 'components/shared/ToolTip';
import { commodityTypes, image } from 'components/globalInfo/commodityUnits';
import Loader from 'components/shared/Loader';
import { useCommodities, useDeleteCommodity } from 'api/v2/commodities';
import AddEditModal from './AddEditModal';
import ReferenceModal from './ReferenceModal';


const Commodities = ({ remove }) => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [addEditStatus, setAddEditStatus] = useState('add');
  const [editItem, setEditItem] = useState(undefined);
  const { data: commodities, isLoading } = useCommodities();
  const { mutate: deleteCommodity } = useDeleteCommodity();
  const [showExtRefModal, setShowExtRefModal] = useState(false);
  const [refItem, setRefItem] = useState(undefined);

  const handleDeleteCommodity = (commodityId) => {
    deleteCommodity(commodityId);
  };

  return (
    <div className="panel-body">
        <AddEditModal
          showUpdate={showUpdate}
          closeUpdate={() => setShowUpdate(false)}
          status={addEditStatus}
          item={editItem}
        />
        <ReferenceModal
          showExtRefModal={showExtRefModal}
          setShowExtRefModal={setShowExtRefModal}
          item={refItem}
          uom={refItem?.unitOfMeasureId}
         />
      <div className="wrapper-content">
        <div className="row">
          <div>
            <div className="ibox">
              {isLoading ? (
                <Loader />
              ) : (
                <div className="ibox-content">
                  <Authorize
                    {...{
                      forbidden: ['CARRIER'],
                      abilityPermissions: [
                        {
                          resource: 'Database',
                          permissions: ['create'],
                        },
                        {
                          resource: 'Commodities',
                          permissions: ['create'],
                        },
                      ],
                    }}>
                    <Button
                      onClick={() => {
                        setAddEditStatus('add')
                        setShowUpdate(true)
                      }}
                      inverse
                      className="float-end"
                      testSelector="database_commodities_add_btn">
                      <Icon className="icon--margin-right" icon="plus-square" />
                      Add Commodity
                    </Button>
                  </Authorize>
                  {commodities.map(item => (
                    <div
                      key={`key${item.id}`}
                      className="tree-node tree-node-content">
                      <span
                        className="tree-node-content-name"
                        style={{ color: '#ffffff' }}>
                        <ToolTip
                          title={commodityTypes(item?.commodityType)?.title}>
                          {image(item.commodityType)}
                        </ToolTip>
                        <strong
                          style={{
                            marginLeft: item.commodityType === 4 ? '23px' : '',
                          }}>{` ${item.id} | ${item.name} `}</strong>
                      </span>
                      <Authorize {...{ forbidden: ['CARRIER'] }}>
                        <span>
                          <Authorize
                            {...{
                              abilityPermissions: [
                                {
                                  resource: 'Database',
                                  permissions: ['update'],
                                },
                                {
                                  resource: 'Commodities',
                                  permissions: ['update'],
                                },
                              ],
                            }}>
                            <ToolTip title="Edit Commodity">
                              <Button
                                onClick={() => {
                                  setEditItem(item);
                                  setAddEditStatus('edit');
                                  setShowUpdate(true);
                                }}
                                theme="small"
                                testSelector="database_commodities_edit_btn">
                                <Icon icon="pencil" />
                              </Button>
                            </ToolTip>
                          </Authorize>
                          <Authorize
                            {...{
                              abilityPermissions: [
                                {
                                  resource: 'Database',
                                  permissions: ['delete'],
                                },
                                {
                                  resource: 'Commodities',
                                  permissions: ['delete'],
                                },
                              ],
                            }}>
                            <ToolTip title="Delete Commodity">
                              <Button
                                onClick={() => handleDeleteCommodity(item.id)}
                                theme="small"
                                testSelector="database_commodities_delete_btn">
                                <Icon icon="trash" />
                              </Button>
                            </ToolTip>
                          </Authorize>
                        </span>
                      </Authorize>
                      <Authorize {...{ forbidden: ['JOB_MANAGER'] }}>
                        <ToolTip title="Add External Reference">
                          <Button
                            onClick={() => {
                              setRefItem(item);
                              setShowExtRefModal(true);
                            }}
                            theme="small"
                            testSelector="database_commodities_reference_open_btn">
                            <Icon icon="add" />
                          </Button>
                        </ToolTip>
                      </Authorize>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commodities;
