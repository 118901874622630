const init = {
  update: {
    firstName: '',
    lastName: '',
    description: '',
    phoneNumber: '',
    timeZone: ''
  },
  password: {},
  isPswUpdating: false,
};

export default (state = init, action) => {
  switch (action.type) {
    case 'UPDATE_PROFILE_SET_PROFILE':
      return {
        ...state,
        update: {
          ...state.update,
          ...action.profile,
        },
      };
    case 'UPDATE_PROFILE_SET_VALUE':
      return {
        ...state,
        update: {
          ...state.update,
          [action.name]: action.value,
        },
      };
    case 'CHANGE_PASSWORD_SET_INPUT_VALUE': {
      return {
        ...state,
        password: {
          ...state.password,
          [action.name]: action.value,
        },
      };
    }
    case 'CHANGE_PASSWORD_SUBMIT': {
      return {
        ...state,
        isPswUpdating: action.value
      };
    }
    case 'CHANGE_PASSWORD_CLEAR_STATE': {
      return {
        ...state,
        password: {},
      };
    }
    default:
      return state;
  }
};
