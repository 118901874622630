import React from 'react';
const NoPreview = () => (
    <svg
      version="1.2"
      baseProfile="tiny"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      className="icon--fill"
      viewBox="0 0 22 22"
    >
      {/* Centered eye shape */}
      <path
        d="M12 6C9.944 6 7.172 7.744 5.125 10.577a.682.682 0 0 0 0 .788c2.045 2.836 4.817 4.58 7.873 4.58 3.057 0 5.827-1.744 7.872-4.576a.684.684 0 0 0 0-.789C18.825 7.744 16.055 6 12 6zm.219 8.475c-2.029.132-3.704-1.612-3.577-3.731.105-1.747 1.463-3.164 3.138-3.273 2.029-.133 3.705 1.61 3.578 3.73-.109 1.744-1.467 3.16-3.139 3.274zm-.102-1.618C11.022 11.928 10.12 10.99 10.191 9.85c.056-.942.789-1.703 1.692-1.765 1.094-.071 1.996.867 1.924 2.007-.059.946-.791 1.707-1.692 1.765z"
        fill="currentColor"
        transform="scale(.85) translate(1, 3)" 
      />
      
      {/* No symbol (circle with a diagonal line through it) */}
      <circle cx="12" cy="12" r="9" fill="none" stroke="red" strokeWidth="2" />
      <line x1="6" y1="6" x2="18" y2="18" stroke="red" strokeWidth="2" />
    </svg>
  );
  
  export default NoPreview;
  
