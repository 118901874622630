import React from 'react';
import Icon from 'components/shared/Icon';

const EventItem = ({
  icon,
  badgeText,
  badgeColor,
  messageBegin,
  orderLink,
  onClick,
  messageEnd,
  driver,
  time,
}) => (
  <div className="dashboard-events__item">
    <span
      className={`dashboard-events__badge ${badgeColor &&
        `dashboard-events__badge--${badgeColor}`}`}
    >
      {icon && <Icon icon={icon} />} {badgeText && badgeText}
    </span>
    <br />
    {orderLink && <b onClick={onClick && onClick}>#{orderLink}</b>}
    {messageBegin && <span> {messageBegin}</span>}
    {driver && <b> {driver}</b>}
    {messageEnd && <span> {messageEnd}</span>}
    {time && <span> {time}</span>}
  </div>
);

export default EventItem;
