import { createSelector } from 'reselect';

const getVendors = state => state.database.vendors.vendors;
const getFilter = state => state.database.vendors.filter;

export const filterVendors = createSelector(
  getVendors,
  getFilter,
  (vendors, filter) => {
    const filteredTypes = filter.types.map(i => i.value);
    return vendors.filter(
      item =>
        item.types.some(i =>
          filteredTypes.length ? filteredTypes.includes(i.id) : i,
        ) &&
        item.name
          .toString()
          .toLowerCase()
          .indexOf(filter.name.toLowerCase()) !== -1,
    );
  },
);
