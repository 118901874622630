import React from 'react';
import { convertTime } from 'utils/convertTime';
import Icon from 'components/shared/Icon';

const Transfers = ({ transfers }) => (
  <div className="ibox float-e-margins">
    {transfers.length ? (
      <table className="table table-hover no-margins">
        <thead>
          <tr>
            <th>From driver</th>
            <th />
            <th>To driver</th>
            <th>Date</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {transfers.map((item, index) => (
            <tr key={`item-${index}`}>
              <td>
                #{item.from_driver_id} | {item.transfer_from_name}
              </td>
              <td>
                <Icon icon="chevron-right" />
              </td>
              <td>
                #{item.to_driver_id} | {item.transfer_to_name}
              </td>
              <td>{convertTime(item.actual_transfer_date)}</td>
              <td>{item.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>
        <h4>No transfers info</h4>
      </div>
    )}
  </div>
);
export default Transfers;
