import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import actions from 'store/actions/ManageDatabase';
import AddEditModal from './AddEditModal';

const Certificates = ({
  certificates,
  isLoaded,
  remove,
  init,
  setModalAddValue,
  setModalEditValue,
}) => {
  const [showUpdate, turnShowUpdate] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const openUpdate = (status, value) => {
    switch (status) {
      case 'add':
        setModalAddValue(value || {});
        break;
      case 'edit':
        setModalEditValue(value);
        break;
    }
    turnShowUpdate(true);
  };
  return (
    <div>
      {showUpdate && (
        <AddEditModal
          {...{ showUpdate }}
          closeUpdate={() => turnShowUpdate(false)}
        />
      )}
      <div className="wrapper-content">
        <div className="row">
          <div>
            {isLoaded ? (
              <div className="ibox">
                <div className="ibox-content">
                  <Authorize
                    {...{
                      abilityPermissions: [
                        {
                          resource: 'Database',
                          permissions: ['create'],
                        },
                      ],
                    }}>
                    <Button
                      onClick={() => openUpdate('add')}
                      inverse
                      className="float-end"
                      testSelector="database_certificates_add_btn">
                      <Icon className="icon--margin-right" icon="plus-square" />
                      Add Certificate
                    </Button>
                  </Authorize>
                  {certificates.map(item => (
                    <div
                      key={`key${item.id}`}
                      className="tree-node tree-node-content">
                      <span
                        className="tree-node-content-name"
                        style={{ color: '#ffffff' }}>
                        <strong>
                          #{item.id} | {item.name}{' '}
                        </strong>
                      </span>
                      <span>
                        <Authorize
                          {...{
                            abilityPermissions: [
                              {
                                resource: 'Database',
                                permissions: ['update'],
                              },
                            ],
                          }}>
                          <Button
                            onClick={() => openUpdate('edit', item)}
                            theme="small"
                            testSelector="database_certificates_edit_btn">
                            <Icon icon="pencil" />
                          </Button>
                        </Authorize>
                        <Authorize
                          {...{
                            abilityPermissions: [
                              {
                                resource: 'Database',
                                permissions: ['delete'],
                              },
                            ],
                          }}>
                          <Button
                            onClick={() => confirmAlert(remove, item.id)}
                            theme="small"
                            testSelector="database_certificates_delete_btn">
                            <Icon icon="trash" />
                          </Button>
                        </Authorize>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="ibox">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  certificates: state.database.certificates.certificates,
  isLoaded: state.database.certificates.isLoaded,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.getCertificates()),
  remove: id => dispatch(actions.removeCertificate(id)),
  setModalAddValue: value =>
    dispatch(actions.setModalAddValueCertificate(value)),
  setModalEditValue: value =>
    dispatch(actions.setModalEditValueCertificate(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Certificates);
