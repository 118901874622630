const init = {
  emails: [],
  downtimeMinutes: 0,
  confirmDelete: -1,
  email: '',
  minutes: '',
};

export default (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_GET_NOTIFICATIONS_INFO_SUCCESS':
      return {
        ...state,
        emails: action.data.emails,
        downtimeMinutes: action.data.downtimeMinutes,
      };
    case 'JOB_DETAILS_CONFIRM_DELETE_EMAIL_ID':
      return {
        ...state,
        confirmDelete: action.id,
      };
    case 'JOB_DETAILS_DELETE_NOTIFICATION_EMAIL_SUCCESS':
      return {
        ...state,
        emails: action.res.emails,
        downtimeMinutes: action.res.downtimeMinutes,
        confirmDelete: -1,
      };
    case 'JOB_DETAILS_UPDATE_NOTIFICATION_INFO_SUCCESS':
      return {
        ...state,
        emails: action.res.emails,
        downtimeMinutes: action.res.downtimeMinutes,
        confirmDelete: -1,
      };
    case 'JOB_DETAILS_NOTIFICATION_SET_VALUE':
      return {
        ...state,
        [action.data.field]: action.data.value,
        confirmDelete: -1,
      };
    case 'JOB_DETAILS_NOTIFICATION_ERROR_IGNORE':
      return { ...state };
    case 'JOB_DETAILS_AUTO_SETTINGS_CLEAR':
      return init;
    default:
      return state;
  }
};
