const initialStateQueueData = {
  count: 0,
  countAPI: 2,
  events: [],
  drivers: [],
  orders: [],
  filter: {
    sortOrder: 'districtId',
    sortDriver: 'districtId',
    districtId: '',
    equipmentId: '',
  },
  filterData: {
    district: '',
    equipment: '',
  },
  sortOrdersList: {
    job_name: false,
    district: false,
    equipment: false,
    expected_pickup_time: false,
  },
  sortDriversList: {
    carrierName: false,
    districtName: false,
    equipment: false,
    name: false,
  },
  assign: {
    openModal: false,
    driverId: '',
    isLoading: false,
  },
};

export const queue = (state = initialStateQueueData, action) => {
  switch (action.type) {
    case 'GET_QUEUE_DATA_REQUEST':
      return {
        ...state,
      };
    case 'GET_QUEUE_DATA_SUCCESS':
      return {
        ...state,
        count: (state.count + 1),
        [action.array]: action.data,
      };
    case 'GET_QUEUE_DATA_FAILURE':
      return {
        ...state,
        count: (state.count + 1),
      };
    case 'SET_COUNT_DATA':
      return {
        ...state,
        count: 0,
      };
    case 'FILTER_SET_DISTRICT_VALUE':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.name]: action.value,
        },
      };
    case 'SET_SORT_ORDER_DATA':
      return {
        ...state,
        sortOrdersList: action.sortOrdersListNew,
      };
    case 'SET_SORT_DRIVER_DATA':
      return {
        ...state,
        sortDriversList: action.sortDriversListNew,
      };
    case 'ASSIGN_DRIVER_SET_VALUE':
      return {
        ...state,
        assign: {
          ...state.assign,
          [action.name]: action.value,
        },
      };
    case 'ASSIGN_DRIVER_SET_LOADING':
      return {
        ...state,
        assign: {
          ...state.assign,
          isLoading: action.value,
        }
      }
    case 'ASSIGN_DRIVER_TURN_MODAL':
      return {
        ...state,
        assign: {
          ...state.assign,
          openModal: !state.assign.openModal,
          order: action.order,
        },
      };
    case 'QUEUE_CLEAR_STATE':
			return initialStateQueueData;
    default:
      return state;
  }
};