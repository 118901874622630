import moment from 'moment';

export const isDateFormatValid = (date, action) => {
  const isValid = moment(date, 'YYYY-MM-DD hh:mm A', true).isValid();
  action(!isValid);
  return isValid;
};

export const isTimeFormatValid = date => !moment(date, 'hh:mm A', true).isValid();

export const checkData = data => {
  const today = new Date();
  return data && data !== 'Invalid date' ? data : today;
};