import { driver } from 'services/driver/service';
import { carrier } from 'services/carrier/service';
import swal from 'bootstrap-sweetalert';
import { dynamicSort } from 'utils/dynamicSort';
import { lookUpActions } from 'store/actions/LookUp';
import { apiSlice, selectLookupDrivers } from 'features/api/apiSlice';

const initSortedDrivers = drivers => dispatch => {
  const success = (data, ios, android) => ({
    type: 'DRIVERS_REQUEST_SUCCEEDED',
    drivers: data,
    ios,
    android,
  });

  const iosArray = drivers
    .filter(i => Number(i.deviceType) === 1)
    .sort(dynamicSort('-deviceVersion'));
  const iosVersions = Array.from(
    new Set(iosArray.map(item => item.deviceVersion).filter(e => e)),
  );
  const androidArray = drivers
    .filter(i => Number(i.deviceType) === 0)
    .sort(dynamicSort('-deviceVersion'));
  const androidVersions = Array.from(
    new Set(androidArray.map(item => item.deviceVersion).filter(e => e)),
  );
  dispatch(success(drivers, iosVersions, androidVersions));
};

export const driversRequested = () => async dispatch => {
  dispatch({ type: 'DRIVERS_REQUESTED' });

  const drivers = await driver.get().catch(error => {
    swal(error.response.data.message, 'Unable to load Drivers', 'error');
    return dispatch({ type: 'DRIVERS_REQUEST_FAILED' });
  });

  dispatch(apiSlice.endpoints.getDrivers.initiate());
  dispatch(lookUpActions.refresh(drivers.data.data, 'drivers'));
  return dispatch(initSortedDrivers(drivers.data.data));
};

export const changeFilters = filters => ({
  type: 'DRIVER_LIST_CHANGE_FILTERS',
  filters,
});

export const setModalValue = (value, name) => ({
  type: 'DRIVER_LIST_SET_MODAL_VALUE',
  value,
  name,
});

export const closeEditModal = () => ({ type: 'DRIVER_LIST_CLEAR_EDIT_MODAL' });

export const refresh = () => dispatch => {
  const request = () => ({ type: 'DRIVER_LIST_REFRESH_REQUEST' });
  const success = () => ({ type: 'DRIVER_LIST_REFRESH_SUCCESS' });

  dispatch(request());
  driver
    .get()
    .then(response => {
      dispatch(lookUpActions.refresh(response.data.data.drivers, 'drivers'));
      dispatch(driversRequested());
      dispatch(success());
    })
    .catch(error => {
      swal(
        error.response.data.message,
        'Unable to load Driver Details',
        'error',
      );
    });
};

export const resetDistrictsLoadedRequested = () => ({
  type: 'DRIVER_LIST_DISTRICTS_NOT_LOADED',
});

export const changeDistrictsRequested = () => (dispatch, getState) => {
  const {
    driversNew: { selectedDriver },
  } = getState();

  const districtIds = selectedDriver.districts_selected.map(
    district => district.value,
  );
  selectedDriver.district_detail = selectedDriver.districts_selected
    .map(district => district.label.split('|').pop())
    .join(',');
  driver
    .changeDistricts(districtIds, Number(selectedDriver.id))
    .then(response => {
      dispatch(resetDistrictsLoadedRequested());
      // dispatch(
      //   lookUpActions.edit(selectedDriver, selectedDriver.id, 'drivers'),
      // );
      dispatch(driversRequested());
      closeEditModal();
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(
          error.response.data?.message,
          'Failed to change Districts',
          'error',
        );
      } else {
        swal(
          error.message || 'Cannot Change This District',
          'Failed to change Districts',
          'error',
        );
      }
    });
};

export const getCarriers = () => dispatch => {
  const save = carriers => ({
    type: 'DRIVER_LIST_SET_CARRIERS',
    carriers,
  });
  carrier
    .getCarriers()
    .then(response => {
      dispatch(save(response.data.data.carriers));
    })
    .catch(error => {
      swal(error.response.data.error || 'Something went wrong.', '', 'error');
    });
};

export const setDistrictsRequested = () => (dispatch, getState) => {
  const request = () => ({
    type: 'DRIVER_LIST_DISTRICTS_NOT_LOADED',
  });
  const success = returnedDistricts => ({
    type: 'DRIVER_LIST_SET_DRIVER_DISTRICTS',
    districts: returnedDistricts,
  });
  const {
    driversNew: { selectedDriver },
  } = getState();
  dispatch(request());
  driver
    .getDriverProfile(selectedDriver.id)
    .then(response => {
      const districts = response.data.data.districts.map(item => ({
        value: item.id,
        label: item.name,
      }));
      dispatch(success(districts));
    })
    .catch(error => {
      swal(error.response.data.message, 'Failed to set Districts', 'error');
    });
};

export const changeDriverRate = () => (dispatch, getState) => {
  const {
    driversNew: { selectedDriver },
  } = getState();

  driver
    .changeDriverRate(selectedDriver.id, {
      comment: selectedDriver.comment,
      rating: selectedDriver.rating,
    })
    .then(response => {
      // dispatch(
      //   lookUpActions.edit(selectedDriver, selectedDriver.id, 'drivers'),
      // );
      swal(response.data.message, '', 'success');
      dispatch(driversRequested());
      closeEditModal();
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(
          error.response.data?.message,
          'Failed to change Driver Rate.',
          'error',
        );
      } else {
        swal(
          error.message || 'Something went wrong.',
          'Failed to change Driver Rate.',
          'error',
        );
      }
    });
};

export const changePercentage = () => (dispatch, getState) => {
  const {
    driversNew: { selectedDriver },
  } = getState();
  driver
    .changePercentage(selectedDriver.id, selectedDriver.pay_percentage)
    .then(response => {
      // dispatch(
      //   lookUpActions.edit(selectedDriver, selectedDriver.id, 'drivers'),
      // );
      // dispatch(driversRequested());
      swal(response.data.message, '', 'success');
      dispatch(closeEditModal());
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(
          error.response.data?.message,
          'Failed to change Driver Percentage.',
          'error',
        );
      }
    });
};

export const deleteDriver = driverId => () => {
  driver
    .deleteDriver(driverId)
    .then(response => {
      // dispatch(lookUpActions.deleteL(driverId, 'drivers'));
      // dispatch(driversRequested());
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(error.response.data?.message, 'Failed to delete Driver', 'error');
      }
    });
};

export const restoreDriver = driverId => () => {
  driver
    .restoreDriver(driverId)
    .then(response => {
      // dispatch(refresh());
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(error.response.data.message, 'Failed to restore Driver', 'error');
      }
    });
};

export const freeDriver = driverId => dispatch => {
  driver
    .freeDriver(driverId)
    .then(response => {
      swal(response.data.message, '', 'success');
      dispatch(refresh());
    })
    .catch(error => {
      swal(
        error?.data?.message || 'Failed to free Driver',
        'Failed to free Driver',
        'error',
      );
    });
};

export const logOutDriver = data => dispatch => {
  driver
    .logOutDriver(data.id)
    .then(response => {
      // dispatch(lookUpActions.edit({ ...data, is_logged_in: 0 }, data.id, 'drivers'));
      dispatch(driversRequested());
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      if (error.isAxiosError) {
        swal(error.response.data.message, 'Failed to log out Driver', 'error');
      }
    });
};

export const setFilterValue = (value, name) => ({
  type: 'DRIVER_LIST_SET_FILTER_VALUE',
  value,
  name,
});

export const addDriver = (driverData, closeAddDriver) => dispatch => {
  dispatch({ type: 'DRIVER_LIST_ADD_DRIVER_REQUEST' });
  const success = () => ({ type: 'DRIVER_LIST_ADD_DRIVER_SUCCESS' });
  const failed = () => ({ type: 'DRIVER_LIST_ADD_DRIVER_FAILED' });
  const clear = () => ({ type: 'DRIVER_LIST_ADD_DRIVER_CLEAR_STATE' });
  const {
    name,
    email,
    carrierId,
    vehicleNumber,
    district,
    homeDistrict,
    trailerNumber,
    contactNo,
    certificatePhotos,
    equipment,
  } = driverData;

  const data = {
    name,
    email,
    carrierId,
    district,
    vehicleNumber,
    trailerNumber,
    equipment,
    certificatePhotos,
    contactNo,
    deviceType: '0',
    deviceToken: '0',
    lat: '0',
    long: '0',
    homeDistrict,
  };

  driver
    .createDriver(data)
    .then(response => {
      // const params = {
      //   accepted_jobs_count: 0,
      //   carrierId,
      //   carrier_name: carrierName,
      //   chat_id: null,
      //   contact_no: contactNo,
      //   deviceVersion: '',
      //   district_detail: district,
      //   home_district: homeDistrict,
      //   email,
      //   equipment,
      //   id: response.data.data.id,
      //   is_deleted: 0,
      //   is_logged_in: 1,
      //   name,
      //   online_status: 1,
      //   order_chat_id: null,
      //   order_id: null,
      //   order_status: null,
      //   pay_percentage: 0,
      //   phone_info: null,
      //   rating: 0,
      //   rejected_jobs_count: 0,
      //   sand_site_id: null,
      //   sand_type: null,
      //   status: 0,
      //   trailer_no: trailerNumber,
      //   truck_no: vehicleNumber,
      // };
      // dispatch(lookUpActions.add(params, 'drivers'));
      closeAddDriver();
      dispatch(success());
      swal(response.data.message, '', 'success');
      // dispatch(refresh());
    })
    .catch(error => {
      dispatch(failed());
      dispatch(clear());
      closeAddDriver();
      if (error.isAxiosError) {
        swal(error.response.data.message, 'Failed to create Driver', 'error');
      } else {
        swal('Something went wrong.', 'Failed to create Driver', 'error');
      }
    });
};

// Driver notification actions
export const setDriverDetails = data => ({
  type: 'DRIVER_LIST_SET_DRIVER_DETAILS',
  driver: data,
});

export const sendMessage = close => (dispatch, getState) => {
  const state = getState();

  const {
    driversNew: {
      notification: { message, ids },
    },
  } = state;
  const { data: drivers } = selectLookupDrivers(state);

  if (!Object.values(ids).includes(true)) {
    swal('Please select at least one driver', '', 'error');
    return;
  }

  let idsList = [];
  idsList = Object.keys(ids).filter(item => ids[item]);
  if (message.trim().length > 0) {
    driver
      .sendMessage({ driversId: idsList, message })
      .then(response => {
        swal(response.data.message, '', 'success');
        close();
      })
      .catch(error => {
        swal(
          error?.response?.data?.message ||
          'All Notifications could not be delivered',
          'Failed to send Message',
          'error',
        );
        close();
      });
  }
};

export const setNotificationValue = (value, name) => ({
  type: 'DRIVER_LIST_MODAL_NOTIFICATION_VALUE',
  value,
  name,
});

export const selectFree = () => ({ type: 'DRIVER_LIST_SELECT_FREE_DRIVER' });
export const selectBusy = () => ({ type: 'DRIVER_LIST_SELECT_BUSY_DRIVER' });

export const checkDriver = (id, status) => ({
  type: 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_DRIVER',
  id,
  status,
});
export const selectAll = (drivers, status) => dispatch => {
  const ids = {};
  drivers.forEach(d => {
    ids[d.id] = status;
  });
  dispatch({
    type: 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_ALL_DRIVER',
    ids,
    status,
  });
};

export const clearNotificationState = () => ({
  type: 'DRIVER_LIST_CLEAR_STORE_SEND_NOTIFICATION',
});
