import authService from 'services/auth/service';
import { ROLES } from 'config/constants';

export const position = () => {
  const user = authService.getUser();
  if (!user) return [];
  switch (user.role) {
    case ROLES.VENDOR:
      return 'Vendor';
    case ROLES.JOB_MANAGER:
      return 'Job Manager';
    case ROLES.JOB_COORDINATOR:
      return 'Job Coordinator';
    case ROLES.CARRIER:
      return 'Carrier';
    case ROLES.CUSTOMER:
      return 'Customer';
    default:
      return '';
  }
};
