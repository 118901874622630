import React, { useState, useMemo } from 'react';
import { startCase } from 'lodash';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import ToolTip from 'components/shared/ToolTip';
import targetValue from 'utils/targetValue';
import { useTags } from 'api/v2/tags';

import DatePicker from 'components/shared/DatePicker';
import ViewSwitcher from './ViewSwitcher';

const CalendarFilters = ({ filters, applyFilters, viewSwitcherProps }) => {
  const { data: jobTags} = useTags({ type: 2 });
  const tagOptions = useMemo(
    () =>
      (jobTags || []).map(tag => ({
        label: tag.name,
        value: tag.id,
      })),
    [jobTags],
  );

  const [filterVisibility, setFilterVisibility] = useState(true);
  const [activeFilters, setActiveFilters] = useState(filters);

  const addFilter = (name, value) => {
    const newFilters = {
      ...activeFilters,
      [name]: value,
    };
    setActiveFilters(newFilters);
    applyFilters(newFilters);
  };

  const removeFilter = name => {
    const newFilters = {
      ...activeFilters,
      [name]: undefined,
    };
    delete newFilters[name];
    setActiveFilters(newFilters);
    applyFilters(newFilters);
  };

  const handleChange = name => e => {
    const value = targetValue(e);
    if (value !== '' && (Array.isArray(value) ? value.length : true)) {
      addFilter(name, value);
    } else {
      removeFilter(name);
    }
  };

  const clearAllFilters = () => {
    setActiveFilters({});
    applyFilters({});
  };

  return (
    <div className="filters">
      <div
        className={`filters__top-row ${
          Object.keys(activeFilters).length ? '' : 'driver-filters__top-row'
        }`}>
        {!!Object.keys(activeFilters).length && (
          <div className="filters__active">
            {Object.keys(activeFilters).map(filter => (
              <span className="label label--filter" key={filter}>
                {startCase(filter)}
                <Icon
                  data-testid={`remove-driver-filter-${filter}`}
                  icon="close"
                  onClick={() => removeFilter(filter)}
                />
              </span>
            ))}
            <Button
              testSelector="drivers-list_filter_clear-all_btn"
              theme="small"
              onClick={clearAllFilters}>
              Clear All
            </Button>
          </div>
        )}
        <ToolTip
          title={`${filterVisibility ? 'Hide' : 'Show'} Filters`}
          placement="left">
          <Button
            theme="small"
            className="filter-toggle button--small--square"
            onClick={() => setFilterVisibility(!filterVisibility)}
            inverse={filterVisibility}
            testSelector="drivers-list_filter_toggle_btn">
            <Icon icon="filter" />
          </Button>
        </ToolTip>
      </div>
      <div
        className={`filters__form-row ${
          filterVisibility ? 'filters__form-row--open' : ''
        }`}>
        <div className="filters__form">
          <div className="filters__item">
            <Input
              label="Job Id"
              onChange={handleChange('id')}
              value={activeFilters?.id ?? ''}
              testSelector="job_filter_id_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Job Name"
              onChange={handleChange('job_name')}
              value={activeFilters?.job_name ?? ''}
              testSelector="job_filter_name_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="District"
              onChange={handleChange('district_name')}
              value={activeFilters?.district_name ?? ''}
              testSelector="job_filter_district_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Carrier Name"
              onChange={handleChange('carrierName')}
              value={activeFilters?.carrierName ?? ''}
              testSelector="job_filter_carrier-name_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Location"
              onChange={handleChange('well_name')}
              value={activeFilters?.well_name ?? ''}
              testSelector="job_filter_location_input"
            />
          </div>
          <div className="filters__item">
            <Select
              placeholder="Tags"
              isMulti
              isClearable={false}
              onChange={e => handleChange('tags')(e.map(i => i.value))}
              options={tagOptions}
              value={tagOptions.filter(i =>
                (activeFilters?.tags || []).includes(i.value),
              )}
              testSelector="jobs_add_job-details_tags_input"
            />
          </div>
          <div className="filters__item">
            <DatePicker
              dateFormat="MM/DD/YY"
              timeFormat={false}
              value={activeFilters?.startDate}
              onChange={handleChange('startDate')}
              label="Start Date"
              testSelector="job_filter_start-date_input"
            />
          </div>
          <div className="filters__item">
            <DatePicker
              dateFormat="MM/DD/YY"
              timeFormat={false}
              value={activeFilters?.endDate}
              onChange={handleChange('endDate')}
              label="End Date"
              testSelector="job_filter_end-date_input"
            />
          </div>
          <div className="filters__item--quarter">
            <ViewSwitcher
              view={viewSwitcherProps.view}
              onViewModeChange={viewSwitcherProps.onViewModeChange}
              onViewListChange={viewSwitcherProps.onViewListChange}
              isChecked={viewSwitcherProps.isChecked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalendarFilters;
