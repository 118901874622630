import { connect } from 'react-redux';
import Pagination from './component';

const mapStateToProps = state => ({
  currentPage: state.pagination.pagination.currentPage,
});

const mapDispatchToProps = dispatch => ({
  setPage: value => {
    dispatch({ type: 'PAGINATION_SET_PAGE', value });
  },
  clearState: () => {
    dispatch({ type: 'PAGINATION_CLEAR_STATE' });
  },
});

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pagination);
