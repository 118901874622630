import React from 'react';
import Icon from 'components/shared/Icon';
import Label from 'components/shared/Label';
import { convertTimeWithSeconds } from 'utils/convertTime';
import Pagination from 'components/shared/Pagination';

const DriversList = ({
  drivers,
  selectLocation,
  applyFilters,
  setPageLimit,
  pagination,
  pageLimit,
  driverData,
  setPagination,
}) => {
  if (!drivers.length) {
    return (
      <div className="ibox-content text-center">
        <h3>No drivers available</h3>
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={Math.ceil((driverData?.size ?? 0) / pageLimit) ?? 0}
          onPageChange={setPagination}
          withLimit
          currentLimit={pageLimit}
          onLimitChange={e => {
            setPagination({ currentPage: 1 });
            setPageLimit(e);
            applyFilters();
          }}
        />
      </div>
    );
  }

  const renderDriver = (item, index) => {
    const handleClickDriver = () => {
      selectLocation(item);
    };

    return (
      <div key={item.id} className="vote-item" style={{ padding: '5px 5px' }}>
        <div className="row">
          <div
            id="selectDriver"
            className="col-xs-12"
            onClick={handleClickDriver}
            onKeyDown={handleClickDriver}
            role="button"
            tabIndex={index}>
            <strong
              className="text-navy"
              style={{ cursor: 'pointer', fontSize: 16 }}>
              <Icon icon="male" /> Driver #{item.id}: {item.name}
            </strong>
            {item.status === 0 ? (
              <Label type="primary" size="sm">
                Free
              </Label>
            ) : (
              <Label type="warning" size="sm">
                Active
              </Label>
            )}
            {item.online_status === 0 && (
              <Label type="danger" size="sm">
                Offline
              </Label>
            )}
            {!!item.is_deleted && (
              <Label type="danger" size="sm">
                Deleted
              </Label>
            )}
            {item.unload_appointment ? (
              <small
                className="float-end"
                style={{
                  fontStyle: 'italic',
                }}>
                {`unload appointment:${convertTimeWithSeconds(
                  item.unload_appointment,
                )}`}
              </small>
            ) : null}
          </div>
        </div>
        <div className="row">
          {item.status === 1 ? (
            <div className="col-xs-4">Order #{item.order_id}</div>
          ) : null}

          <div className="col-xs-4">
            <strong>
              <Icon icon="building-o" /> Carrier: #{item.carrier_id}{' '}
              {item.carrier_name}
            </strong>
          </div>
          <div className="col-xs-4">
            <strong>
              <Icon icon="truck" /> Truck: {item.truck_no}
            </strong>
          </div>
        </div>
        <div className="row footerSandSite" />
      </div>
    );
  };

  return (
    <div className="ibox-content">
      {drivers.map(renderDriver)}
      <Pagination
        currentPage={pagination.currentPage}
        totalPages={Math.ceil((driverData?.size ?? 0) / pageLimit) ?? 0}
        onPageChange={setPagination}
        withLimit
        currentLimit={pageLimit}
        onLimitChange={e => {
          setPagination({ currentPage: 1 });
          setPageLimit(e);
          applyFilters();
        }}
      />
    </div>
  );
};

export default DriversList;
