import React from 'react';

const SelectUser = () => {
  return (
    <div className="col-sm-4">
      <div className="ibox ">
        <div className="ibox-content">
          <div className="text-center" style={{ height: 730 }}>
            <p style={{ color: 'grey' }}>Click on any user</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUser;
