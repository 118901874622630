import axios from 'axios';

export const invoice = {
  getInvoices(data) {
    return axios.post('v2/billing/invoice/search', data);
  },
  getInvoiceDetails(id) {
    return axios.post(`v2/billing/invoice/${id}/details`, {});
  },
  markAsPaid(id) {
    return axios.post(`v2/billing/invoice/${id}/status/Paid`, {});
  },
  markAsSent(id) {
    return axios.post(`v2/billing/invoice/${id}/status/Sent`, {});
  },
  deleteInvoice(id) {
    return axios.post(`v2/billing/invoice/${id}/remove`, {});
  },
  generateLink(data) {
    return axios.post(
      `v2/billing/bulkinvoice/generateDownloadLinkForInvoice`,
      data,
    );
  },
  getBulkInvoice(data) {
    return axios({
      method: 'post',
      url: `v2/billing/invoice/${data.id}/bulkinvoice.pdf`,
      responseType: 'arraybuffer',
      data,
    });
  },
  getMultipleBulkInvoices(data) {
    return axios({
      method: 'post',
      url: `v2/billing/invoice/multibulkinvoice.zip`,
      contentType: `application/zip`,
      responseType: 'arraybuffer',
      data,
    })
  },
  getBillingInvoice(params) {
    return axios({
      method: 'post',
      url: `v2/billing/invoice/${params.id}/${params.type}.${
        params.contentType
      }`,
      contentType: `application/${params.contentType}`,
      data: params,
      responseType: 'arraybuffer',
    });
  },
  download(data) {
    return axios.get('v2/billing/invoice/externalDownload', {
      params: {
        p: data.token,
        type: data.type,
      },
      responseType: 'arraybuffer',
    });
  },
  comments: {
    add(data) {
      return axios.post(
        `v2/billing/invoice/${data.selectedInvoice}/comments/add`,
        { comment: data.newComment },
      );
    },
  },
  sendInvoiceToEmail(data) {
    return axios.post(`v2/billing/invoice/${data.id}/sendTo`, data);
  },
  recreatePercentage(data) {
    return axios.get(
      `v2/order/${data.order_id}/bol/recreate?percentage=${data.percentage}`,
    );
  },
  recreate(data) {
    return axios.post(`v2/billing/invoice/${data.id}/compile`, data);
  },
  getCreditMemoOrders(invoiceId) {
    return axios.get(`v2/billing/invoice/${invoiceId}/creditmemo`)
  },
  createCreditMemo(invoiceId, data) {
    return axios.post(`v2/billing/invoice/${invoiceId}/creditmemo`,data);
  }
};
