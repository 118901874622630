import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { Card, Row } from 'react-bootstrap';

import Authorize, { authorize } from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import settingsActions from 'store/actions/AdminSettings';

const AuditSettings = ({
  isLoadedAuditSettings,
  auditSettings,
  getAuditSettings,
  update,
  clearState,
}) => {
  const [data, initData] = useState({});

  useEffect(() => {
    clearState();
    getAuditSettings();
  }, []);

  useEffect(() => {
    if (!_isEmpty(auditSettings)) {
      initData(auditSettings);
    }
  }, [auditSettings]);

  if (!isLoadedAuditSettings) {
    return <Loader />;
  }

  return (
    <Card>
      <Card.Header as="h5">Audit Settings</Card.Header>
      <Card.Body>
        <form onSubmit={e => update(e, data)}>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="number"
                onChange={({ target: { value } }) =>
                  initData(cs => ({
                    ...cs,
                    maxDemurrage: value,
                  }))
                }
                value={data.maxDemurrage}
                label="Max demurrage"
                disabled={
                  !authorize({
                    abilityPermissions: [
                      {
                        resource: 'Admin',
                        permissions: ['update'],
                      },
                    ],
                  })
                }
                testSelector="admin-settings_audit-settings_max-demurrage_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <div className="row" style={{ margin: 0 }}>
                <Input
                  type="checkbox"
                  testSelector="admin-settings_audit_duplicate-sandticket-numbers-rule_input"
                  onChange={() =>
                    initData(cs => ({
                      ...cs,
                      duplicateSandTicketNumbersRuleEnabled: !data.duplicateSandTicketNumbersRuleEnabled,
                    }))
                  }
                  label="Duplicate Sand Ticket numbers rule enabled"
                  isChecked={data.duplicateSandTicketNumbersRuleEnabled}
                  disabled={
                    !authorize({
                      abilityPermissions: [
                        {
                          resource: 'Admin',
                          permissions: ['update'],
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group has-feedback col-md-6">
              <div className="row" style={{ margin: 0 }}>
                <Input
                  type="checkbox"
                  testSelector="admin-settings_audit_min-minutes-for-completion_input"
                  onChange={() =>
                    initData(cs => ({
                      ...cs,
                      minimumMinutesForCompletionRuleEnabled: !data.minimumMinutesForCompletionRuleEnabled,
                    }))
                  }
                  label="Minimum minutes for completion rule enabled"
                  isChecked={data.minimumMinutesForCompletionRuleEnabled}
                  disabled={
                    !authorize({
                      abilityPermissions: [
                        {
                          resource: 'Admin',
                          permissions: ['update'],
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <div className="row" style={{ margin: 0 }}>
                <Input
                  type="checkbox"
                  testSelector="admin-settings_audit_no-timestamp-rule_input"
                  onChange={() =>
                    initData(cs => ({
                      ...cs,
                      noTimestampRuleEnabled: !data.noTimestampRuleEnabled,
                    }))
                  }
                  label="No timestamp rule enabled"
                  isChecked={data.noTimestampRuleEnabled}
                  disabled={
                    !authorize({
                      abilityPermissions: [
                        {
                          resource: 'Admin',
                          permissions: ['update'],
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group has-feedback col-md-6">
              <div className="row" style={{ margin: 0 }}>
                <Input
                  type="checkbox"
                  id="openMoreThan24HoursRuleEnabled"
                  testSelector="admin-settings_audit_open-more-than-24hoursRule_input"
                  onChange={() =>
                    initData(cs => ({
                      ...cs,
                      openMoreThan24HoursRuleEnabled: !data.openMoreThan24HoursRuleEnabled,
                    }))
                  }
                  label="Open more than 24 hours rule enabled"
                  isChecked={data.openMoreThan24HoursRuleEnabled}
                  disabled={
                    !authorize({
                      abilityPermissions: [
                        {
                          resource: 'Admin',
                          permissions: ['update'],
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
          </Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Admin', permissions: ['update'] },
            ]}>
            <div className="modal-footer" style={{ border: 'none' }}>
              <Button
                type="submit"
                testSelector="admin-settings_audit_save_btn">
                Save
              </Button>
            </div>
          </Authorize>
        </form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  isLoadedAuditSettings:
    state.adminSettings.auditSettings.isLoadedAuditSettings,
  auditSettings: state.adminSettings.auditSettings.auditSettings,
});

const mapDispatchToProps = dispatch => ({
  getAuditSettings: () => dispatch(settingsActions.getAuditSettings()),
  update: (e, data) => {
    e.preventDefault();
    dispatch(settingsActions.updateAuditSettings(data));
  },
  clearState: () => dispatch(settingsActions.clearAuditSettingsState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditSettings);
