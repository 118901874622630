import React from 'react';

const IOS = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
   <path d="M12.346 8.501c-.02-2.026 1.645-2.998 1.72-3.046-.936-1.375-2.393-1.564-2.912-1.585-1.24-.127-2.42.733-3.05.733-.627 0-1.598-.715-2.627-.696-1.351.02-2.597.79-3.293 2.006-1.405 2.448-.36 6.076 1.009 8.061.669.972 1.466 2.064 2.513 2.025 1.008-.04 1.39-.656 2.609-.656s1.561.656 2.628.636c1.086-.02 1.773-.99 2.437-1.966.768-1.127 1.084-2.219 1.103-2.275-.024-.011-2.116-.816-2.137-3.237zm-2.005-5.946c.556-.677.931-1.617.829-2.555-.8.033-1.771.536-2.345 1.212-.516.6-.967 1.556-.846 2.475.894.07 1.806-.456 2.362-1.132z"/>
  </svg>
);

export default IOS;