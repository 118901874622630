import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Accordion, Card, Row } from 'react-bootstrap';
import CardToggle from 'components/common/CardToggle';

const options = { responsive: true };

const SandTypes = ({ sandVolumes }) => (
  <Accordion defaultActiveKey="0">
    <Card>
      <Card.Header as="h4">
        Commodities
        <CardToggle eventKey="0" />
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <Row>
            {sandVolumes.some(item => item.totalWeight > 0) ? (
              <Pie
                data={{
                  datasets: [
                    {
                      data: sandVolumes.map(item => item.totalWeight),
                      backgroundColor: [
                        '#ed5565',
                        '#1ab394',
                        '#f8ac59',
                        '#23c6c8',
                        'blue',
                      ],
                      label: 'Dataset 1',
                    },
                  ],
                  labels: sandVolumes.map(item => item.sandTypeName),
                }}
                options={options}
                height={122}
              />
            ) : (
              <span style={{ margin: 20 }}>No commodities.</span>
            )}
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
);

export default SandTypes;
