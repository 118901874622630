import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'config/axios.config';

// eslint-disable-next-line no-underscore-dangle
const baseUrl = window._env_.SERVERLESS_API_URL;

export const slsApiSlice = createApi({
  reducerPath: 'slsApi',
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ['AutoOrder', 'Storage', 'StorageRules'],
  endpoints: builder => ({
    getJobAutoOrders: builder.query({
      query: jobId => ({
        url: `auto-order/${jobId}/potential-orders`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'AutoOrder', id: arg }],
    }),
    createJobAutoOrders: builder.mutation({
      query: ({ jobId, data }) => ({
        url: `auto-order/${jobId}/potential-orders`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'AutoOrder', id: arg.jobId },
      ],
    }),
    getJobAutoOrderSettings: builder.query({
      query: jobId => ({
        url: `auto-order/${jobId}/settings`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'AutoOrder', id: arg }],
    }),
    updateJobAutoOrderSettings: builder.mutation({
      query: ({ jobId, data }) => ({
        url: `auto-order/${jobId}/settings`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'AutoOrder', id: arg.jobId },
      ],
    }),
    updateJobAutoOrderRecommendations: builder.mutation({
      query: ({ jobId, data }) => ({
        url: `auto-order/${jobId}/re-recommend`,
        method: 'POST',
        data
      }),
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: 'AutoOrder', id: arg.jobId }],
    }),
    getStorageDetails: builder.query({
      query: jobId => ({
        url: `/onsite-storage/${jobId}`,
      }),
      providesTags: ['Storage'],
    }),
    getHistoricalLevelsTimeline: builder.query({
      query: ({ jobId, intervalHours }) => ({
        url: `/onsite-storage/${jobId}/historical-levels-timeline?intervalHours=${intervalHours}`,
      }),
      providesTags: ['Storage'],
    }),
    addRule: builder.mutation({
      query: ({
        jobId,
        ruleType,
        targetQuantity,
        alertEmail,
        lowThresholdQuantity,
        ruleSubject,
      }) => ({
        url: `/onsite-storage/${jobId}/rules`,
        method: 'POST',
        data: {
          jobId,
          ruleType,
          targetQuantity,
          alertEmail,
          lowThresholdQuantity,
          ruleSubject,
        },
      }),
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: 'StorageRules', id: arg.jobId }],
    }),
    editRule: builder.mutation({
      query: ({
        jobId,
        ruleType,
        targetQuantity,
        alertEmail,
        lowThresholdQuantity,
        ruleSubject,
      }) => ({
        url: `/onsite-storage/${jobId}/rules`,
        method: 'PUT',
        data: {
          jobId,
          ruleType,
          targetQuantity,
          alertEmail,
          lowThresholdQuantity,
          ruleSubject,
        },
      }),
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: 'StorageRules', id: arg.jobId }],
    }),
    deleteRule: builder.mutation({
      query: ({ jobId, ruleType, ruleSubject }) => ({
        url: `/onsite-storage/${jobId}/rules`,
        method: 'DELETE',
        data: {
          jobId,
          ruleType,
          ruleSubject,
        },
      }),
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: 'StorageRules', id: arg.jobId }],
    }),

    getStorageRules: builder.query({
      query: jobId => ({
        url: `/onsite-storage/${jobId}/rules`,
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'StorageRules', id: arg },
      ],
    }),
  }),
});

export const {
  useGetJobAutoOrdersQuery,
  useCreateJobAutoOrdersMutation,
  useGetStorageDetailsQuery,
  useGetHistoricalLevelsTimelineQuery,
  useGetJobAutoOrderSettingsQuery,
  useUpdateJobAutoOrderSettingsMutation,
  useUpdateJobAutoOrderRecommendationsMutation,
  useAddRuleMutation,
  useDeleteRuleMutation,
  useEditRuleMutation,
  useGetStorageRulesQuery,
} = slsApiSlice;
