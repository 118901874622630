import React, { useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';

import { useCustomerLocations } from 'api/v2/locations';
import { useJobs } from 'api/v2/jobs';

import EditCustomer from './EditCustomer';
import EditBillingAddress from './EditBillingAddress';
import EditBillingTerms from './EditBillingTerms';
import EditLogo from './EditBillingLogo';

const companyOptions = {
  2: 'Managed Customer',
  3: 'SaaS Customer',
};

const InfoCustomer = ({ details }) => {
  const [showEdit, turnEdit] = useState(false);
  const [showEditBillingAddress, turnEditBillingAddress] = useState(false);
  const [showEditBillingTerms, turnEditBillingTerms] = useState(false);
  const [showEditLogo, turnEditLogo] = useState(false);

  const { data: locationsList } = useCustomerLocations(details?.id);
  const { data: jobs } = useJobs(
    {
      customerId: details?.id,
    },
    {
      skip: !details?.id,
    },
  );

  const address = details.address && details.address[0];
  const {
    billingTerms,
    fuelSurchargeEnabled,
    logo,
    accessLevel,
    companyUserCount,
  } = details;

  return (
    <Card>
      <Card.Header />
      <Card.Body>
        <Row>
          <h2>
            <span className="text-navy">
              <strong> {details.customerName} </strong>
            </span>
          </h2>
        </Row>
        <Row>
          <div className="col-lg-4 col-md-9 col-xs-9">
            <div className="profile-image">
              {logo ? (
                <Image rounded src={logo} alt="profile" className="m-t-xs" />
              ) : (
                <Icon icon="user-circle-o" />
              )}
            </div>
            <div className="profile-info">
              <dl className="dl-horizontal">
                <dt>
                  <Icon icon="envelope" /> Email:
                </dt>
                <dd> {details.email}</dd>
                <dt>
                  <Icon icon="phone" /> Phone:
                </dt>
                <dd> {details.phoneNumber}</dd>
                {address && (
                  <>
                    <dt>
                      <Icon icon="map-marker" /> Address:
                    </dt>
                    <dd>
                      {' '}
                      {address.address}
                      <br /> {address.city}, {address.state}
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
          <div className="col-md-2 col-xs-3">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    Users:
                    <strong> {companyUserCount}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Locations:
                    <strong> {locationsList && locationsList.length}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Total Jobs:
                    <strong> {jobs && jobs.length}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Due date (days):
                    <strong> {billingTerms.dueDays}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Billing frequency (days):
                    <strong> {billingTerms.billingFrequency}</strong>
                  </td>
                </tr>
                <Authorize
                  abilityPermissions={[
                    { resource: 'Carrier-documents', permissions: ['read'] },
                  ]}>
                  <tr>
                    <td>
                      BC ID:
                      <strong> {details.bcId || 'NOT SYNCED'} </strong>
                    </td>
                  </tr>
                </Authorize>
                <tr>
                  <td>
                    <strong>
                      {
                        companyOptions[
                          (details &&
                            details.companyType &&
                            details.companyType.toString()) ||
                            2
                        ]
                      }
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-lg-6 col-md-12 col-xs-12 no-padding"
            style={{ textAlign: 'center' }}>
            <div className="row">
              <div className="col-md-6 col-xs-6 text-center">
                <div>Fuel Surcharge Enabled:</div>
                <h2 className="no-margins text-navy">
                  <b>{fuelSurchargeEnabled ? 'yes' : 'no'}</b>
                </h2>
              </div>
              <div className="col-md-6 col-xs-6 text-center billing-check">
                <div>Attach Bulk Invoice:</div>
                <h3>
                  <Icon
                    icon={`${
                      billingTerms.bulkInvoiceEnabled ? 'check-' : ''
                    }square-o text-navy`}
                    className="text-navy no-margins"
                  />
                </h3>
              </div>
            </div>
            <div className="row" style={{ marginTop: '25px' }}>
              <div className="col-md-6 col-xs-6 text-center billing-check">
                <div style={{ paddingLeft: 0 }}>Attach Packet Invoice:</div>
                <h3>
                  <Icon
                    icon={`${
                      billingTerms.packetInvoiceEnabled ? 'check-' : ''
                    }square-o text-navy`}
                    className="text-navy no-margins"
                  />
                </h3>
              </div>
              <div className="col-md-6 col-xs-6 text-center billing-check">
                <div style={{ paddingLeft: 0 }}>Attach CSV Invoice:</div>
                <h3>
                  <Icon
                    icon={`${
                      billingTerms.csvInvoiceEnabled ? 'check-' : ''
                    }square-o text-navy`}
                    className="text-navy no-margins"
                  />
                </h3>
              </div>
            </div>
          </div>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col>
            <Authorize
              abilityPermissions={[
                { resource: 'Customers', permissions: ['update'] },
              ]}
              itemAccessGranted={
                (accessLevel === 'shared' && companyUserCount === 0) ||
                accessLevel === 'owner'
              }>
              <span
                className="edit-info-carrier"
                onClick={() => turnEdit(true)}>
                <Icon icon="pencil" /> Edit Customer
              </span>
              <span
                className="edit-info-carrier"
                onClick={() => turnEditBillingAddress(true)}>
                <Icon icon="pencil" /> Edit Billing Address
              </span>
              <span
                className="edit-info-carrier"
                onClick={() => turnEditBillingTerms(true)}>
                <Icon icon="pencil" /> Edit Billing Details
              </span>
              <span
                className="edit-info-carrier"
                onClick={() => turnEditLogo(true)}>
                <Icon icon="pencil" /> Edit Logo
              </span>
            </Authorize>
          </Col>
        </Row>
      </Card.Body>

      <EditCustomer
        showEdit={showEdit}
        details={details}
        closeEdit={() => turnEdit(false)}
      />
      <EditBillingAddress
        showEdit={showEditBillingAddress}
        details={details}
        closeEdit={() => turnEditBillingAddress(false)}
      />
      <EditBillingTerms
        showEdit={showEditBillingTerms}
        details={details}
        closeEdit={() => turnEditBillingTerms(false)}
      />
      <EditLogo
        showEdit={showEditLogo}
        details={details}
        closeEdit={() => turnEditLogo(false)}
      />
    </Card>
  );
};

export default InfoCustomer;
