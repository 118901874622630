export default {
  1: {
    badge: 'Duplicate Tickets',
    reason: 'Duplicate tickets uploaded to multiple orders',
  },
  2: {
    badge: 'Inactivity detected',
    reason: 'Driver inactive for more than 60min',
  },
  4: { badge: 'Diversion', reason: 'Order diverted to another job' },
  8: { badge: 'Contested Times', reason: 'Driver contested time' },
  16: {
    badge: 'Missing Ticket',
    reason: ' Not all required tickets uploaded yet',
  },
  64: { badge: 'Transferred', reason: 'Order transferred to new driver' },
  128: {
    badge: 'Ticket Uploaded',
    reason: 'A ticket has been uploaded for the order',
  },
  256: {
    badge: 'Missing Price',
    reason: 'Order price was not calculated correctly',
  },
  512: {
    badge: 'Missing Quantity',
    reason: 'Order has no weight inputted or is under 20,000lbs',
  },
  1024: {
    badge: 'Demurrage no reason',
    reason: 'No reason was given for demurrage',
  },
  2048: {
    badge: 'Scan Match',
    reason: 'All data from third party system is matched',
    color: 'green',
  },
  4096: {
    badge: 'Scan Mismatch',
    reason: {
      1: 'Volume does not match',
      2: 'ArrivedAt is off by more than 24 hours',
      3: 'DepartedAt is off by more than 24 hours',
    },
    color: 'red',
  },
  8192: {
    badge: 'Scan Unavailable',
    reason: 'Third party system data has not been uploaded yet',
    color: 'orange',
  },
  16384: {
    badge: 'Price Updated',
    reason: 'The pricing on this order has been updated.',
    color: 'orange',
  },
  32768: {
    badge: 'Credited',
    reason: 'This order is a part of a credit memo.',
    color: 'red',
  }
};
