import { connect } from 'react-redux';
import locationsActions from 'store/actions/Locations';
import React, { useEffect, useRef } from 'react';
import GoogleMap, {
  AutomatizeMarker,
  polyCoords,
  milesToMeters,
} from 'components/shared/GoogleMap';
import { Polygon } from 'google-maps-react';
import Icon from 'components/shared/Icon';
import { center } from 'components/globalInfo/mapConstants';
import { locationColors } from 'components/globalInfo/polygonColors';

const MapAddLocation = props => {
  const { selectCoordinates, location } = props;
  const polygonRef = useRef()
  const { longitude, latitude } = location;
  let newLatitude = latitude || 0;
  let newLongitude = longitude || 0;
  if (latitude > 90) newLatitude = 90;
  if (latitude < -90) newLatitude = -90;
  if (latitude > 180) newLongitude = 180;
  if (latitude < -180) newLongitude = -180;

  const initialCenter = {
    lng: newLongitude || center[0],
    lat: newLatitude || center[1],
  };

  useEffect(() => {
    polygonRef.current && polygonRef.current.polygon.setOptions({
      strokeColor: locationColors[location.locationType].color,
    })
  }, [location.locationType])

  const checkLocationType = type => {
    switch (type) {
      case 1:
        return 'well_site';
      case 2:
        return 'sand_site';
      case 3:
        return 'water_disposal';
      case 4:
        return 'staging_site';
      case 5:
        return 'acid_facility';
      default:
        return '';
    }
  };
  const checkLocationIcon = type => {
    switch (type) {
      case 1:
        return 'wellsite';
      case 2:
        return 'sandsite';
      case 3:
        return 'water_disposal';
      case 4:
        return 'staging_site';
      case 5:
        return 'acid_facility';
      default:
        return '';
    }
  };

  const onMapClicked = (mapProps, map, clickEvent) => {
    selectCoordinates(clickEvent.latLng.lng(), clickEvent.latLng.lat());
  };

  return (
    <div className="locations-map">
      <GoogleMap
        fitMarkers
        initialCenter={initialCenter}
        center={initialCenter}
        zoom={14}
        onClick={onMapClicked}>
        {!isNaN(Number.parseFloat(location.latitude)) &&
          !isNaN(Number.parseFloat(location.longitude)) &&
          !isNaN(Number.parseFloat(location.geofenceRange)) && (
            <Polygon
              paths={polyCoords(
                milesToMeters(location.geofenceRange),
                30,
                location.latitude,
                location.longitude,
              )}
              ref={polygonRef}
              strokeColor={locationColors[location.locationType].color}
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor={locationColors[location.locationType].color}
              fillOpacity={0}
            />
          )}
        {!isNaN(Number.parseFloat(latitude)) &&
          !isNaN(Number.parseFloat(longitude)) && (
            <AutomatizeMarker
              position={{ lng: longitude, lat: latitude }}
              type={checkLocationType(location.locationType)}
              infoWindow={{
                title: (
                  <>
                    <Icon
                      icon={
                        checkLocationIcon(location.locationType)
                      }
                      colour="white"
                    />
                    <span className="info-window__title--text">
                      #{location.id} {location.name}
                    </span>
                  </>
                ),
                width: 380,
              }}
            />
          )}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = state => ({
  location: state.locations.modalAddLocation,
});

const mapDispatchToProps = dispatch => ({
  selectCoordinates: (lng, lat) =>
    dispatch(locationsActions.selectCoordinates(lng, lat)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapAddLocation);
