import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row, Container, Modal } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import DatePicker from 'components/shared/DatePicker';
import targetValue from 'utils/targetValue';

import { convertTime } from 'utils/convertTime';
import { isDateFormatValid } from 'utils/datePickersFunctions';

import { useJobPurchaseOrders } from 'api/v2/purchase-orders';

import { stagesRequested } from 'store/actions/Jobs';
import { updateOrder } from 'store/actions/Orders';

import { SlideOutPanelContext } from './OrderDetails';
import { submitFunction, isLoadWeightValid } from './helpers';

const toMoment = data =>
  moment(data, ['YYYY-MM-DD hh:mm A', 'MM-DD-YYYY hh:mm A', 'D MMM YYYY H:mm']);

const ModifyModal = ({
  open,
  onClose,
  order,
  getData,
  isLoading,
  stages,
  updateOrder,
  modifyOrder,
  isUpdating,
  getOrders,
}) => {
  const { setCurrentOrder } = useContext(SlideOutPanelContext);
  const { data: pos } = useJobPurchaseOrders(order?.job_id ?? order?.jobId);
  const [isDateAlert, turnDateAlert] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (open) {
      getData();
      turnDateAlert(false);
      setFormState({
        customerOrderId: order.customer_order_id,
        sandTicketNo: order.sand_ticket_no,
        orderAcceptedAt: convertTime(order.order_accepted_at),
        loadArrivalTime: convertTime(order.load_arrival),
        loadDepartureTime: convertTime(order.load_depart),
        stageArrivalTime: order.enter_stage
          ? convertTime(order.enter_stage)
          : undefined,
        stageDepartureTime: order.depart_stage
          ? convertTime(order.depart_stage)
          : undefined,
        wellArrivalTime: convertTime(order.well_arrival),
        wellDepartureTime: convertTime(order.well_depart),
        mileage: order.mileage,
        kingComp: order.king_comp,
        driverWeight: order.driver_weight ? order.driver_weight : order.weight,
        po: order.po,
        stage: order.stage,
        status: order.status,
        orderId: order.order_id,
        truck: order.truck,
        trailer_number: order.trailer_number,
        shouldComplete: false, // This does not seem to ever be true but is passes to back end
        updatedAt: order.updated_at,
        completedAt: order.completed_at,
        createdAt: order.created_at,
      });
    }
  }, [open]);

  const setFormInputValue = (value, name) =>
    setFormState({ ...formState, [name]: value });

  const minForAlert = 240;
  const loadAcceptedTime = toMoment(formState.orderAcceptedAt);
  const loadDepartTime = toMoment(formState.loadDepartureTime);
  const loadArriveTime = toMoment(formState.loadArrivalTime);
  const sandSiteTime = loadDepartTime.diff(loadArriveTime, 'minutes');

  const wellDepartTime = toMoment(formState.wellDepartureTime);
  const wellArriveTime = toMoment(formState.wellArrivalTime);
  const wellsiteTime = wellDepartTime.diff(wellArriveTime, 'minutes');

  const stageOptions = stages.map(stage => ({
    value: stage.stageId,
    label: `${stage.stageId}`,
  }));

  const poOptions = pos.map(item => ({
    label:
      order.operation_type !== 12
        ? `${item.poNo} | ${item.sandTypeName} | ${item.originName}`
        : `${item.originName}`,
    value: item.poNo,
    disabled: !item.enabled,
  }));

  const body = (
    <Container>
      <Row>
        <Col lg={6} md={6} sm={6} className="form-group has-feedback">
          <Input
            label="Customer Order ID"
            onChange={value =>
              setFormInputValue(targetValue(value), 'customerOrderId')
            }
            value={formState.customerOrderId}
            testSelector="reconcile_order-details_modify_customer-order-id_input"
          />
        </Col>
        {order.driver_id && (
          <>
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <Input
                label="Ticket Number"
                testSelector="reconcile_order-details_modify_ticket_input"
                onChange={value =>
                  setFormInputValue(targetValue(value), 'sandTicketNo')
                }
                value={formState.sandTicketNo}
              />
            </Col>
            <div className="nested-no-outer-gutter" style={{ color: 'red' }}>
              {isDateAlert && (
                <p>* Date format should be YYYY/MM/DD hh:mm AM/PM</p>
              )}
            </div>
            <div className="alert-input-msg">
              {isLoadWeightValid(formState.driverWeight, order.weight) && (
                <p>Quantity should be more than 100 and less than 65,000</p>
              )}
            </div>
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <DatePicker
                label="Order Accepted At"
                value={formState.orderAcceptedAt === 'Invalid date' ? undefined : formState.orderAcceptedAt}
                onChange={e => {
                  if (isDateFormatValid(e, turnDateAlert)) {
                    setFormInputValue(
                      moment(targetValue(e)).format('YYYY-MM-DD hh:mm A'),
                      'orderAcceptedAt',
                    );
                  }
                }}
                dateFormat="YYYY-MM-DD"
                timeFormat="hh:mm A"
                data-testid={`reconcile_order-details_modify_${loadAcceptedTime}_dp`}
                id={`reconcile_order-details_modify_${loadAcceptedTime}_dp`}
                name={`reconcile_order-details_modify_${loadAcceptedTime}_dp`}
              />
            </Col>
            <Col lg={6} md={6} sm={6} className="form-group has-feedback" />

            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <DatePicker
                value={formState.loadArrivalTime === 'Invalid date' ? undefined : formState.loadArrivalTime}
                label="Origin Arrival Time"
                onChange={e => {
                  if (isDateFormatValid(e, turnDateAlert)) {
                    setFormInputValue(e, 'loadArrivalTime');
                  }
                }}
                dateFormat="YYYY-MM-DD"
                timeFormat="hh:mm A"
                data-testid={`reconcile_order-details_modify_${loadArriveTime}_dp`}
                id={`reconcile_order-details_modify_${loadArriveTime}_dp`}
                name={`reconcile_order-details_modify_${loadArriveTime}_dp`}
              />
            </Col>
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <DatePicker
                label="Origin Depart time"
                value={formState.loadDepartureTime === 'Invalid date' ? undefined : formState.loadDepartureTime}
                onChange={e => {
                  if (isDateFormatValid(e, turnDateAlert)) {
                    setFormInputValue(
                      moment(e).format('YYYY-MM-DD hh:mm A'),
                      'loadDepartureTime',
                    );
                  }
                }}
                dateFormat="YYYY-MM-DD"
                timeFormat="hh:mm A"
                data-testid={`reconcile_order-details_modify_${loadDepartTime}_dp`}
                id={`reconcile_order-details_modify_${loadDepartTime}_dp`}
                name={`reconcile_order-details_modify_${loadDepartTime}_dp`}
              />
            </Col>
            {order.staging_site && formState.stageArrivalTime !== undefined && (
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <DatePicker
                  label="Staging Arrival Time"
                  value={formState.stageArrivalTime}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert)
                    setFormInputValue(e, 'stageArrivalTime')
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  data-testid={`reconcile_order-details_modify_${formState.stageArrivalTime
                    }_dp`}
                  id={`reconcile_order-details_modify_${formState.stageArrivalTime
                    }_dp`}
                  name={`reconcile_order-details_modify_${formState.stageArrivalTime
                    }_dp`}
                />
              </Col>
            )}
            {order.staging_site && formState.stageDepartureTime !== undefined && (
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <DatePicker
                  className="form-input__calendar"
                  label="Staging Depart time"
                  value={formState.stageDepartureTime}
                  onChange={e => {
                    if (isDateFormatValid(e, turnDateAlert)) {
                      setFormInputValue(
                        moment(e).format('YYYY-MM-DD hh:mm A'),
                        'stageDepartureTime',
                      );
                    }
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  data-testid={`reconcile_order-details_modify_${formState.stageDepartureTime
                    }_dp`}
                  id={`reconcile_order-details_modify_${formState.stageDepartureTime
                    }_dp`}
                  name={`reconcile_order-details_modify_${formState.stageDepartureTime
                    }_dp`}
                />
              </Col>
            )}
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <DatePicker
                onChange={e => {
                  if (isDateFormatValid(e, turnDateAlert)) {
                    setFormInputValue(
                      moment(e).format('YYYY-MM-DD hh:mm A'),
                      'wellArrivalTime',
                    );
                  }
                }}
                value={formState.wellArrivalTime === 'Invalid date' ? undefined : formState.wellArrivalTime}
                label="Destination Arrival Time"
                dateFormat="YYYY-MM-DD"
                timeFormat="hh:mm A"
                data-testid={`reconcile_order-details_modify_${wellArriveTime}_dp`}
                id={`reconcile_order-details_modify_${wellArriveTime}_dp`}
                name={`reconcile_order-details_modify_${wellArriveTime}_dp`}
              />
            </Col>
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <DatePicker
                className="form-input__calendar"
                label="Destination Depart time"
                value={formState.wellDepartureTime === 'Invalid date' ? undefined : formState.wellDepartureTime}
                onChange={e => {
                  if (isDateFormatValid(e, turnDateAlert)) {
                    setFormInputValue(
                      moment(e).format('YYYY-MM-DD hh:mm A'),
                      'wellDepartureTime',
                    );
                  }
                }}
                dateFormat="YYYY-MM-DD"
                timeFormat="hh:mm A"
                data-testid={`reconcile_order-details_modify_${wellDepartTime}_dp`}
                id={`reconcile_order-details_modify_${wellDepartTime}_dp`}
                name={`reconcile_order-details_modify_${wellDepartTime}_dp`}
              />
            </Col>
          </>
        )}
        <Col lg={6} md={6} sm={6} className="form-group has-feedback">
          <Input
            label="Truck"
            onChange={value => setFormInputValue(targetValue(value), 'truck')}
            value={formState.truck}
            testSelector="reconcile_order-details_modify_truck_input"
          />
        </Col>
        <Col lg={6} md={6} sm={6} className="form-group has-feedback">
          <Input
            label="Trailer"
            onChange={value =>
              setFormInputValue(targetValue(value), 'trailer_number')
            }
            value={formState.trailer_number}
            testSelector="reconcile_order-details_modify_trailer_input"
          />
        </Col>
        <Col lg={6} md={6} sm={6} className="form-group has-feedback">
          <Input
            min={0}
            type="number"
            label="Mileage"
            testSelector="reconcile_order-details_modify_mileage_input"
            onChange={value => setFormInputValue(targetValue(value), 'mileage')}
            value={formState.mileage}
          />
        </Col>
        {
          order.operation_type !== 12 && (
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <Input
                label="Silo/King/Comp"
                onChange={value => setFormInputValue(targetValue(value), 'kingComp')}
                value={formState.kingComp}
                testSelector="reconcile_order-details_modify_king-comp_input"
              />
            </Col>
          )
        }
        {
          order.driver_id && (
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <Input
                min={100}
                // max={order.weight}
                max={85000}
                type="number"
                label="Quantity"
                testSelector="reconcile_order-details_modify_load-weight_input"
                onChange={value =>
                  setFormInputValue(targetValue(value), 'driverWeight')
                }
                value={formState.driverWeight}
              />
            </Col>
          )
        }
        {
          order.operation_type !== 12 && (
            <Col lg={6} md={6} sm={6} className="form-group has-feedback">
              <Select
                label="Stage"
                placeholder="Please Select Stage"
                onChange={({ value }) => setFormState({ ...formState, stage: value })}
                value={stageOptions.find(item => item.value === formState.stage)}
                options={stageOptions}
                testSelector="reconcile_order-details_modify_stage_select"
              />
            </Col>
          )
        }
        <Col lg={6} md={6} sm={6} className="form-group has-feedback">
          <Select
            label={
              order.operation_type === 12
                ? 'Destination'
                : 'PO / Commodity / Location'
            }
            placeholder="Please Select PO"
            onChange={({ value }) => setFormState({ ...formState, po: value })}
            value={poOptions.find(item => item.value === formState.po)}
            options={poOptions}
            isOptionDisabled={option => option.disabled}
            testSelector="reconcile_order-details_modify_po_select"
          />
        </Col>
        <Col lg={6} md={6} sm={6} className="form-group has-feedback" />
        {
          order.status !== 1 && order.status !== 0 && (
            <>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <div className="vertical-container dark-timeline">
                  <div className="vertical-timeline-block">
                    <div className="vertical-timeline-icon navy-bg">
                      <Icon icon="truck" />
                    </div>
                    <div className="vertical-timeline-content">
                      <span>
                        <b>Driver was at Origin</b>
                      </span>
                      <span className="vertical-date">
                        <h1
                          className={`font-bold ${sandSiteTime > minForAlert ? 'text-warning' : 'text-navy'
                            }`}>
                          {sandSiteTime} min
                        </h1>
                        {sandSiteTime < 5 && (
                          <div className="text-danger">Incorrect time</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <div className="vertical-container dark-timeline">
                  <div className="vertical-timeline-block">
                    <div className="vertical-timeline-icon navy-bg">
                      <Icon icon="truck" />
                    </div>
                    <div className="vertical-timeline-content">
                      <span>
                        <b>Driver was at Destination</b>
                      </span>
                      <span className="vertical-date">
                        <h1
                          className={`font-bold ${wellsiteTime > minForAlert ? 'text-warning' : 'text-navy'
                            }`}>
                          {wellsiteTime} min
                        </h1>
                        {wellsiteTime < 16 && (
                          <div className="text-danger">Incorrect time</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </>
          )
        }
      </Row>
    </Container>
  );

  const onSuccess = params => {
    const updatedOrder = {
      ...order,
      customer_order_id: params.customerOrderId,
      sand_ticket_no: params.sandTicketNo,
      load_arrival: params.loadArrivalTime,
      load_depart: params.loadDepartureTime,
      well_arrival: params.wellArrivalTime,
      well_depart: params.wellDepartureTime,
      mileage: params.mileage,
      king_comp: params.kingComp,
      trailer_number: params.trailer_number,
      truck: params.truck,
      driver_weight: params.driverWeight,
      po: params.po,
      stage: params.stage,
      status: params.status,
      order_id: params.orderId,
      updated_at: params.updatedAt,
    };
    if (params.stageArrivalTime && params.stageArrivalTime !== 'Invalid date') {
      updatedOrder.stageArrivalTime = params.stageArrivalTime;
    }
    if (
      params.stageDepartureTime &&
      params.stageDepartureTime !== 'Invalid date'
    ) {
      updatedOrder.stageDepartureTime = params.stageDepartureTime;
    }
    setCurrentOrder(updatedOrder);
    modifyOrder(updatedOrder);
    onClose();
    if (getOrders) {
      getOrders();
    }
  };

  const footer = (
    <>
      <Button
        onClick={() => {
          onClose();
          setFormState({});
        }}
        colour="white"
        testSelector="reconcile_order-details_modify_close_btn">
        Close
      </Button>
      <Button
        className="margin-right--none"
        type="submit"
        onClick={e =>
          submitFunction(
            e,
            formState,
            order,
            onSuccess,
            updateOrder,
            order.staging_site,
          )
        }
        testSelector="reconcile_order-details_modify_save_btn"
        disabled={
          isDateAlert || isLoadWeightValid(formState.driverWeight, order.weight)
        }>
        Save
      </Button>
    </>
  );

  return (
    <Modal className="modal-container" show={open} onHide={() => {
      onClose();
      setFormState({});
    }}>
      <Modal.Header>
        <h3 className="modal-title">Modify Order #{order.order_id}</h3>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <Loader /> : body}
      </Modal.Body>
      <Modal.Footer>
        {isLoading ? null : (isUpdating && <Loader />) || footer}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({
  jobsNew: { stages },
  po,
  orders: { orderUpdate },
}) => ({
  isLoading: po.apiIsLoading && stages.apiIsLoading,
  isUpdating: orderUpdate.apiIsLoading,
  po: po.collection,
  stages: stages.collection,
});

const mapDispatchToProps = (dispatch, { order: { job_id, jobId } }) => ({
  getData: () => {
    dispatch(stagesRequested(jobId || job_id));
  },
  updateOrder: (params, order, callback) =>
    dispatch(updateOrder(params, order, callback)),
  modifyOrder: order =>
    dispatch({
      order,
      type: 'SET_ORDER_BY_ID',
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyModal);
