import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal, { ModalForm } from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import {
  setModalValue as setModalValueAction,
  changeDriverRate as changeDriverRateAction,
} from 'store/actions/Drivers';
import targetValue from 'utils/targetValue';
import { ratesList } from 'components/globalInfo/rateList';

const ChangeDriverRate = props => {
  const {
    showModalDriverRate,
    closeRate,
    driver,
    changeDriverRate,
    setModalValue,
  } = props;
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      !driver.rating ||
      !driver.comment ||
      (driver.comment && !driver.comment.trim().length)
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [driver]);

  const header = <h3>Edit Rate</h3>;
  const body = (
    <>
      <ModalForm>
        <Select
          label="Rating"
          onChange={e => setModalValue(e.value, 'rating')}
          options={ratesList}
          required
          value={ratesList.find(item => item.value === driver.rating)}
          testSelector="drivers_rate-modal_rating_select"
        />
        <Input
          onChange={e => setModalValue(e, 'comment')}
          label="Comment"
          required
          testSelector="drivers_change-rate_comment_input"
        />
      </ModalForm>
    </>
  );
  const footer = (
    <>
      <Button
        onClick={closeRate}
        colour="white"
        testSelector="drivers-list_change-rate_close_btn">
        Close
      </Button>
      <Button
        onClick={changeDriverRate}
        disabled={saveDisabled}
        testSelector="drivers-list_change-rate_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalDriverRate}
      onClose={closeRate}
    />
  );
};

const mapStateToProps = state => ({
  driver: state.driversNew.selectedDriver,
});

const mapDispatchToProps = dispatch => ({
  setModalValue: (e, name) =>
    dispatch(setModalValueAction(targetValue(e), name)),
  changeDriverRate: e => {
    e.preventDefault();
    dispatch(changeDriverRateAction());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeDriverRate);
