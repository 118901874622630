import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from 'components/shared/Input';
import actions from 'store/actions/SandSiteSchedule';
import Header from 'components/common/Header';
import targetValue from 'utils/targetValue';
import { useParams } from 'react-router-dom';
import Lanes from './Lanes';
import Calendar from './Calendar/Calendar';
import OrderList from './OrderList';
import AddEditLane from './AddEditLane';
import {
  filterOrders,
  getUnconfirmedOrders,
  mapExternalOrders,
  orderData,
} from './helpers';


const SandSiteSchedule = ({
  getSandSiteSchedule,
  schedule,
  sandTypes,
  confirmAppointment,
  deleteLanes,
  lanesModal,
  setModalValue,
  setSettingValue,
  externalOrders,
  setCurrentOrder,
  clearCurrentOrder,
  setLanesModalData,
  clearModalValue,
}) => {
  const [showLanesModal, turnModal] = useState(false);
  const { sandSiteId } = useParams();

  useEffect(() => {
    getSandSiteSchedule(sandSiteId);
    const gs = setInterval(() => {
      getSandSiteSchedule(sandSiteId);
    }, 300000);
    return () => clearInterval(gs);
  }, []);

  const openLanesModal = data => {
    setLanesModalData(data);
    turnModal(true);
  };

  const { lanes, slots, currentOrder, moveLane, settings } = schedule;
  const { autoConfirmation, autoScheduling } = settings;
  const orderEvents = schedule.orders.map(o => orderData(o));
  const externalOrdersEvents = externalOrders.map(o =>
    orderData(o, 'external'),
  );
  const events = orderEvents.concat(slots, externalOrdersEvents);

  return (
    <div>
      {showLanesModal && (
        <AddEditLane
          {...{
            lanesModal,
            sandTypes,
            showLanesModal,
            setModalValue,
          }}
          closeLanesModal={() => {
            clearModalValue();
            turnModal(false);
          }}
        />
      )}

      <Header title="Facility Schedule" />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox">
            <div className="ibox-title">
              <Input
                type="checkbox"
                testSelector="send-site-schedule_auto-scheduling_input"
                isChecked={Boolean(autoScheduling)}
                onChange={e => setSettingValue(e, 'autoScheduling')}
                label="Auto scheduling ( Will automatically find next available time slot and lane  when driver selects the load )"
              />
              <Input
                type="checkbox"
                testSelector="send-site-schedule_auto-confirmation_input"
                isChecked={Boolean(autoConfirmation)}
                onChange={e => setSettingValue(e, 'autoConfirmation')}
                label="Auto confirmation (Will automatically confirm loading appointment time)"
              />
            </div>
            <div className="ibox-content">
              <Lanes
                {...{ lanes, deleteLanes }}
                openLanesModal={openLanesModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {!autoConfirmation && (
          <div className="col-lg-3">
            <div className="ibox">
              <div className="ibox-title">
                <h3>Confirmation</h3>
              </div>
              <div className="ibox-content">
                <OrderList
                  {...{
                    orders: orderEvents,
                    currentOrder,
                    confirmAppointment,
                    setCurrentOrder,
                    clearCurrentOrder,
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className={`col-lg-${!autoConfirmation ? '9' : '12'}`}>
          <div className="ibox">
            <div className="ibox-title">
              <h3>Schedule</h3>
            </div>
            <div className="ibox-content">
              <Calendar
                {...{
                  lanes,
                  events,
                  currentOrder,
                  confirmAppointment,
                  moveLane,
                  schedule,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  confirmationOrders: getUnconfirmedOrders(state),
  calendarOrders: filterOrders(state)(1),
  externalOrders: mapExternalOrders(state),
  schedule: state.sandSiteSchedule.schedule.schedule,
  lanesModal: state.sandSiteSchedule.schedule.lanesModal,
  isLoaded: state.sandSiteSchedule.schedule.isLoaded,
  sandTypes: state.lookUp.sandTypes,
});

const mapDispatchToProps = dispatch => ({
  getSandSiteSchedule: sandSiteId =>
    dispatch(actions.getSandSiteSchedule(sandSiteId)),
  confirmAppointment: (order, closeModal) =>
    dispatch(actions.confirmAppointment(order, closeModal)),
  deleteLanes: lane => dispatch(actions.deleteLanes(lane)),
  setLanesModalData: data => dispatch(actions.setLanesModalData(data)),
  setModalValue: (event, name) =>
    dispatch(actions.setModalValue(targetValue(event), name)),
  clearModalValue: () => dispatch(actions.clearModalValue()),
  setCheckBox: () => dispatch(actions.clearModalValue()),
  setSettingValue: (e, name) =>
    dispatch(actions.setSettingValue(targetValue(e), name)),
  setCurrentOrder: id => dispatch(actions.setCurrentOrder(id)),
  clearCurrentOrder: () => dispatch(actions.clearCurrentOrder()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SandSiteSchedule);
