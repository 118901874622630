import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datetime';
import actions from 'store/actions/SandSiteSchedule';
import { eventTargetName } from 'utils/dispatchWithFieldAndNameValues';
import { convertTime } from 'utils/convertTime';
import eventTargetChecked from 'utils/dispatchWithCheckboxValue';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import { isDateFormatValid } from 'utils/datePickersFunctions';

const SelectSlot = props => {
  const {
    showSlot,
    closeSlot,
    setDescription,
    setStartTime,
    setEndTime,
    setFillAll,
    start,
    end,
    addSlot,
    fillAll,
  } = props;
  const [isDateAlert, turnDateAlert] = useState(false);
  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showSlot}
      onHide={closeSlot}>
      <form onSubmit={addSlot}>
        <Modal.Header>
          <h4 className="modal-title">Select Maintenance Slot</h4>
        </Modal.Header>
        <Modal.Body>
          {isDateAlert && (
            <div className="nested-no-outer-gutter" style={{ color: 'red' }}>
              <p>* Incorrect date format</p>
            </div>
          )}
          <Row>
            <div className="form-group has-feedback col-md-6">
              <label className="small font-noraml">Start</label>
              <DatePicker
                value={start}
                onChange={e => {
                  isDateFormatValid(e, turnDateAlert);
                  setStartTime(e);
                }}
                data-testid="sandsite-schedule_select-slot_start_dp"
                id="sandsite-schedule_select-slot_start_dp"
                name="sandsite-schedule_select-slot_start_dp"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <label className="small font-noraml">End</label>
              <DatePicker
                value={end}
                onChange={e => {
                  isDateFormatValid(e, turnDateAlert);
                  setEndTime(e);
                }}
                isValidDate={current => current.isSameOrAfter(start)}
                data-testid="sandsite-schedule_select-slot_end_dp"
                id="sandsite-schedule_select-slot_end_dp"
                name="sandsite-schedule_select-slot_end_dp"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Textarea
                onChange={setDescription}
                className="specialinstruction"
                rows="3"
                placeholder="Description"
                label="Description"
                testSelector="locations_input_instructions_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                type="checkbox"
                testSelector="send-site-schedule_select-slot_fill-all_input"
                isChecked={fillAll}
                onChange={setFillAll}
                label="Fill all lanes"
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeSlot}
            colour="white"
            children="Close"
            testSelector="sandsite-schedule_select-slot_close_btn"
          />
          <Button
            type="submit"
            children="Save"
            disabled={isDateAlert}
            testSelector="sandsite-schedule_select-slot_save_btn"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  start: state.sandSiteSchedule.slotLine.start,
  end: state.sandSiteSchedule.slotLine.end,
  fillAll: state.sandSiteSchedule.slotLine.fillAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setDescription: eventTargetName(
    actions.setSlotModalValue,
    dispatch,
    'description',
  ),
  setStartTime: start => dispatch(actions.setStartDate(convertTime(start))),
  setEndTime: end => dispatch(actions.setEndDate(convertTime(end))),
  setFillAll: eventTargetChecked(
    actions.setSlotModalValue,
    dispatch,
    'fillAll',
  ),
  addSlot: e => {
    e.preventDefault();
    dispatch(actions.addSlot(ownProps.closeSlot));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectSlot);
