import { addOrder } from 'services/addOrder/service';
import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import { toUTC } from 'utils/convertTime';

export const getCalculatedPickUp = async passedData => {
  const response = await addOrder.getCalculatedPickUp(passedData);
  return moment(response.data.data).local();
};

const sortUnits = units =>
  units.sort((a, b) => {
    if (
      a.priority > b.priority ||
      (a.priority === b.priority && a.poNo > b.poNo)
    ) {
      return 1;
    }
    return -1;
  });

/**
 *
 * @param {import('./hooks').AddOrder} formData
 * @param {any} jobDetails
 * @param {any[]} poCommodities
 * @returns
 */
export const formatAddOrderInfo = (formData, jobDetails, poCommodities) => {
  const {
    details: { operationType, customerId },
  } = jobDetails;
  const data = { ...formData };

  if (moment(data.startDate).isBefore(moment())) {
    return swal('Past dates not allowed!', '', 'error');
  }
  if (
    operationType !== 12 &&
    moment(data.startDate).isBefore(moment(data.loadDate))
  ) {
    return swal(
      'Pick Up Appointment cannot be after Deliver Appointment',
      '',
      'error',
    );
  }
  if (operationType !== 12 && !data.loadDate) {
    return swal('Pick Up Appointment cannot be Empty', '', 'error');
  }
  if (
    moment(data.loadDate).isAfter(moment().add(10, 'hours')) ||
    moment(data.loadDate).isBefore(moment().subtract(10, 'hours'))
  ) {
    return swal(
      'Pick Up Appointment Must be 10 hours before or after Now.',
      '',
      'error',
    );
  }
  if (!data.autoAssign && !data.driverId) {
    return swal(
      'You must either select a driver or select auto assign!',
      '',
      'error',
    );
  }
  if (operationType === 12) {
    const { units } = jobDetails;
    const filteredUnits = units.filter(
      unit => unit.isEnabled && !unit.suspended,
    );

    const sortedUnits = sortUnits(filteredUnits);

    if (!sortedUnits.length) {
      return swal(
        'You do not have any enabled units. Please enable a unit in the units tab.',
        '',
        'error',
      );
    }
    const sandId = sortedUnits[0].id;
    // TODO: Fix for water jobs
    const po = poCommodities.find(type => type.ssId === sandId);
    data.purchaseOrderId = po.poNo;
    data.originId = po.originId;
    data.destinationId = data.po.destinationId;
    data.sandTypeId = po.sandType;
  } else {
    data.purchaseOrderId = data.po.poNo;
    data.originId = data.po.originId;
    data.destinationId = data.po.destinationId;
    data.sandTypeId = data.po.sandType;
  }

  data.stage = data.stage || 0;
  data.unloadAppointment = toUTC(data.startDate);
  data.loadAppointment = operationType === 12 ? '' : toUTC(data.loadDate);
  data.customerId = customerId;

  if (!data.autoAssign && data.count === '1') {
    data.driverId = +data.driverId;
  } else {
    data.driverId = 0;
  }

  data.manualDispatchEnabled = data.manualDispatchEnabled ? '1' : '0';

  if (!data.stagingSite) {
    delete data.stagingSite;
  }

  return data;
};
