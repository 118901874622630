import { combineReducers } from '@reduxjs/toolkit';
import { AdminCarrierConstants } from '../constants/AdminCarriers';

const initialStateDistricts = {
  isLoadedNotAddedDistricts: false,
  notAddedDistricts: [],
};

const notAddedDistricts = (state = initialStateDistricts, action) => {
  switch (action.type) {
    case 'JOB_MANAGER_GET_NOT_ADDED_DISTRICTS_REQUEST':
      return { ...state };
    case 'JOB_MANAGER_GET_NOT_ADDED_DISTRICTS_SUCCESS':
      return {
        ...state,
        isLoadedNotAddedDistricts: true,
        notAddedDistricts: action.notAddedDistricts,
      };
    case 'JOB_MANAGER_GET_NOT_ADDED_DISTRICTS_FAILURE':
      return {};
    default:
      return state;
  }
};

const initialStateDocuments = {
  isCarriersDocs: false,
  carrierDocs: [],
};

const carriersDocs = (state = initialStateDocuments, action) => {
  switch (action.type) {
    case 'JOB_MANAGER_GET_CARRIERS_DOCS_REQUEST':
      return { ...state };
    case 'JOB_MANAGER_GET_CARRIERS_DOCS_SUCCESS':
      return {
        ...state,
        isCarriersDocs: true,
        carrierDocs: action.carrierDocs,
      };
    case 'JOB_MANAGER_GET_CARRIERS_DOCS_FAILURE':
      return initialStateDocuments;
    case 'JOB_MANAGER_CARRIERS_DOCS_CLEAR_MODAL':
      return initialStateDocuments;
    default:
      return state;
  }
};

const initialStateAllDistricts = {
  isLoadedAllDistricts: false,
  allDistricts: [],
};

const allDistricts = (state = initialStateAllDistricts, action) => {
  switch (action.type) {
    case 'JOB_MANAGER_GET_ALL_DISTRICTS_REQUEST':
      return { ...state };
    case 'JOB_MANAGER_GET_ALL_DISTRICTS_SUCCESS':
      return {
        ...state,
        isLoadedAllDistricts: true,
        allDistricts: action.allDistricts,
      };
    case 'JOB_MANAGER_GET_ALL_DISTRICTS_FAILURE':
      return {};
    default:
      return state;
  }
};

const selectedDistricts = (state = [], action) => {
  switch (action.type) {
    case 'JOB_MANAGER_SELECT_DISTRICTS':
      return {
        selectedDistricts: action.selectedDistricts,
      };

    default:
      return state;
  }
};

const initSendNotification = {
  sending: false,
  message: '',
};

const sendNotification = (state = initSendNotification, action) => {
  switch (action.type) {
    case 'ADMIN_CARRIERS_SEND_NOTIFICATION_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ADMIN_CARRIERS_SEND_NOTIFICATION_CLEAR':
      return initSendNotification;
    default:
      return state;
  }
};

const initialStateCarrier = {
  carriers: {},
  isLoadedCarriers: false,
};

function carriers(state = initialStateCarrier, action) {
  switch (action.type) {
    case AdminCarrierConstants.GET_CARRIERS_REQUEST:
      return Object.assign({}, state, {});
    case AdminCarrierConstants.GET_CARRIERS_SUCCESS:
      return Object.assign({}, state, {
        isLoadedCarriers: true,
        carriers: action.carriers,
      });
    case AdminCarrierConstants.GET_CARRIERS_FAILURE:
      return {};
    default:
      return state;
  }
}

const adminCarriers = combineReducers({
  notAddedDistricts,
  carriersDocs,
  allDistricts,
  selectedDistricts,
  carriers,
  sendNotification,
});

export default adminCarriers;