import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import { getPoSchedules, updatePoSchedules } from './queries';

export const usePoSchedules = (poId) =>
  useQuery({
    queryKey: ['po-schedules', poId],
    queryFn: () => getPoSchedules({ poId }),
    enabled: !!poId,
    placeholderData: [],
  });

export const useUpdatePoSchedules = (poId) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['po-schedules', poId],
    mutationFn: data => updatePoSchedules({ poId, data }),
    onSuccess: () => {
      swal('Success!', '', 'success');
      client.invalidateQueries({ queryKey: ['po-schedules', poId] });
    },
    onError: error => {
      swal('Error!', error.response.data.message, 'error');
    }
  });
}
