export default e => {
  if (!e || !e.target) return e;
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked;
    case 'file':
      return e.target.files[0];
    default:
      return e.target.value;
  }
};

export const targetValueNumber = (e) => {
  if (!e || !e.target || !e.target.value.length) return e;
  return Number(e.target.value);
}
