import React from 'react';
import { Row } from 'react-bootstrap';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import { confirmAlert } from 'components/sweetalert';

import {
  useDeleteStorageType,
  useSuspenseStorageTypes,
} from 'api/v2/storage-types';
import { useStorageTypeModalControls } from './hooks';
import AddStorageTypeModal from './AddStorageTypeModal';

const StorageTypes = () => {
  const { data: storageTypes } = useSuspenseStorageTypes();
  const { mutate: deleteStorageType } = useDeleteStorageType();

  const addEditModal = useStorageTypeModalControls();

  const renderStorageType = item => (
    <div key={`key${item.id}`} className="tree-node tree-node-content">
      <span className="tree-node-content-name" style={{ color: '#ffffff' }}>
        <strong>
          #{item.id} | {item.name} - {item.description}
        </strong>
      </span>
      <span>
        <Authorize
          abilityPermissions={[
            { resource: 'Database', permissions: ['update'] },
          ]}
          access={['JOB_MANAGER']}>
          <Button
            onClick={() => addEditModal.openUpdate(item)}
            theme="small"
            testSelector="database_storage-types_edit_btn">
            <Icon icon="pencil" />
          </Button>
        </Authorize>
        <Authorize
          abilityPermissions={[
            { resource: 'Database', permissions: ['delete'] },
          ]}
          access={['JOB_MANAGER']}>
          <Button
            onClick={() => confirmAlert(deleteStorageType, item.id)}
            theme="small"
            testSelector="database_certificates_delete_btn">
            <Icon icon="trash" />
          </Button>
        </Authorize>
      </span>
    </div>
  );

  return (
    <div className="wrapper-content">
      <Row>
        <div className="ibox">
          <div className="ibox-content">
            <Authorize
              abilityPermissions={[
                { resource: 'Database', permissions: ['create'] },
              ]}
              access={['JOB_MANAGER']}>
              <Button
                onClick={addEditModal.openAdd}
                inverse
                className="float-end"
                testSelector="database_storage-types_add_btn">
                <Icon className="icon--margin-right" icon="plus-square" />
                Add Storage Type
              </Button>
            </Authorize>
            {storageTypes.map(renderStorageType)}
          </div>
        </div>
      </Row>

      <AddStorageTypeModal
        showModal={addEditModal.isOpen}
        closeModal={addEditModal.close}
        action={addEditModal.data?.action}
        storageType={addEditModal.data?.storageType}
      />
    </div>
  );
};
export default StorageTypes;
