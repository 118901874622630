const initInfo = {
    alertEmail: [],
    isLoading: false,
  };
  
  export default (state = initInfo, action) => {
    switch (action.type) {
      case 'JOB_DETAILS_ALERT_EMAILS_GET_REQUEST':
        return {
          ...state,
          isLoading: true,
        };
      case 'JOB_DETAILS_ALERT_EMAILS_GET_SUCCESS':
        return {
          ...state,
          isLoading: false,
          alertEmail: action.emails,
        };
      case 'JOB_DETAILS_ALERT_EMAILS_GET_FAILURE':
        return initInfo;
      default:
        return state;
    }
  };
  