const initialState = {
    heads: [],
    filterName: '',
    members: [],
    headsReady: false,
    membersReady: false,
    messages: [],
    messagesReady: false,
    messagesCount: 0,
    chatRoom: '',
    memberAutocomplite: {},
    lastSeenKey: '',
    text: '',
    showAll: false,
    member: {},
  };
  
  export const chat = (state = initialState, action) => {
    switch (action.type) {
      case 'CHAT_INIT_HEADS': {
        return {
          ...state,
          heads: action.heads,
          headsReady: true,
        };
      }
      case 'CHAT_REFRESH_HEADS': {
        return {
          ...state,
          heads: action.heads,
        };
      }
      case 'CHAT_INIT_MEMBERS': {
        return {
          ...state,
          members: action.members,
          membersReady: true,
        };
      }
      case 'CHAT_SET_ROOM': {
        return {
          ...state,
          chatRoom: action.chatRoom,
          messages: [],
          messagesReady: false,
          messagesCount: action.messagesCount,
          member: action.member,
        };
      }
      case 'CHAT_SET_MEMBER_AUTOCOMPLITE': {
        return {
          ...state,
          memberAutocomplite: action.memberAutocomplite,
        };
      }
      case 'CHAT_SAVE_LAST_KEY': {
        return {
          ...state,
          lastSeenKey: action.key,
        };
      }
      case 'CHAT_SAVE_MESSAGGES': {
        return {
          ...state,
          messages: action.messages,
          messagesReady: true,
        };
      }
      case 'CHAT_SET_INPUT_VALUE': {
        return {
          ...state,
          [action.name]: action.value,
        };
      }
      case 'CHAT_SEND_MESSAGGES': {
        return {
          ...state,
          text: '',
          isFile: false,
          fileName: '',
          file: null,
        };
      }
      case 'CHAT_SET_TEMPLATE': {
        return {
          ...state,
          text: action.template,
        };
      }
      case 'CHAT_SET_FILE': {
        return {
          ...state,
          file: action.file,
          fileName: action.file.name,
          isFile: true,
        };
      }
      case 'CHAT_UNSET_FILE': {
        return {
          ...state,
          isFile: false,
          fileName: '',
          file: null,
        };
      }
      case 'CLEAR_CHAT': {
        return initialState;
      }
      default:
        return state;
    }
  };