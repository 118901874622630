import React from 'react';

const Water = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <path d="M18.187 19.814h-.675L13.94 9.352a.527.527 0 0 0-.5-.352h-3.165a.527.527 0 0 0-.5.352L6.201 19.814h-.675A.521.521 0 0 0 5 20.33c0 .285.236.515.527.515h12.66c.291 0 .527-.23.527-.515a.521.521 0 0 0-.527-.515zm-7.814-8.96l.738.72-1.476 1.442.738-2.162zm3.706 2.162l-1.476-1.441.738-.72.738 2.16zm.813 2.38l1.386 4.06-3.529-2.526 2.143-1.533zm-3.035.896L9.5 14.604l2.357-2.301 2.357 2.301-2.357 1.688zm0-5.445l-.836-.817h1.673l-.837.817zm-3.034 4.55l2.142 1.533-3.529 2.527 1.387-4.06zm3.034 2.172l3.136 2.245H8.721l3.136-2.245zM15.76 6.323c-.094-.184-.46-.785-1.194-.785-.187 0-.383.04-.582.12-1.209.478-1.955 1.897-1.986 1.957a.099.099 0 0 0 .137.131 2.105 2.105 0 0 1 1.917-.003c.203.102.415.154.629.154.43 0 .832-.218 1.046-.57.19-.31.202-.676.034-1.004zM7.69 6.323c.094-.184.46-.785 1.195-.785.186 0 .382.04.581.12 1.209.478 1.955 1.897 1.986 1.957a.099.099 0 0 1-.137.131 2.105 2.105 0 0 0-1.917-.003 1.393 1.393 0 0 1-.629.154c-.43 0-.832-.218-1.046-.57a1.007 1.007 0 0 1-.033-1.004zM11.658 6.974c.01 0 .02-.002.031-.006a.099.099 0 0 0 .067-.098c0-.01-.033-1.037.892-1.696.482-.344.687-.908.522-1.435A1.017 1.017 0 0 0 12.19 3h-.018c-.61 0-1.299.331-1.4 1.26-.141 1.293.766 2.615.804 2.671.02.027.05.043.082.043z" />
  </svg>
);

export default Water;
