import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/NotarizationsReport';
import Loader from 'components/shared/Loader';
import Label from 'components/shared/Label';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';

const NotarizationsReport = props => {
  const { order, notarizations, getReport } = props;
  const { steps, isLoading, hasNotarizations } = notarizations;

  useEffect(() => {
    getReport(order.order_id);
  }, []);

  return (
    <div className="row" style={{ paddingLeft: 0 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {hasNotarizations && steps.length > 0 ? (
            <ul className="unstyled">
              {steps.map(step => (
                <li key={step.stepType}>
                  {step.stepType === 12 ? (
                    <Authorize {...{ access: ['CARRIER', 'JOB_MANAGER'] }}>
                      {step.isSuccessful ? (
                        <Icon icon="true" />
                      ) : (
                        <Icon icon="false" />
                      )}
                      <div className="notarization__block">
                        <strong className="notarization__block-title">
                          {step.stepName}
                        </strong>
                        {step.description && step.description.length > 0 && (
                          <span>: {step.description}</span>
                        )}
                      </div>
                    </Authorize>
                  ) : (
                    <>
                      <div className="notarization__block">
                        {step.isSuccessful ? (
                          <Icon icon="true" />
                        ) : (
                          <Icon icon="false" />
                        )}
                        <strong className="notarization__block-title">
                          {step.stepName}
                        </strong>
                        {step.description && step.description.length > 0 && (
                          <span>: {step.description}</span>
                        )}
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <div>
              <ul className="unstyled">
                <li>
                  <strong>Mileage: </strong>
                  <span>{order.mileage || 'unknown'}</span>
                </li>
                <li>
                  <Label type="warning" testSelector="notarization-report_warning_label">
                    Notarization data is not available for this order
                  </Label>
                </li>
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  notarizations: state.notarizations,
});

const mapDispatchToProps = dispatch => ({
  getReport: id => dispatch(actions.getReport(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotarizationsReport);
