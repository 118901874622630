import React from 'react';
import ReactDatePicker from 'react-datetime';
import PropTypes from 'prop-types';
import { TextInput as Input } from '../Input/index';


const WrappedInput = (props, openCalendar) => (
  <Input
    onFocus={openCalendar}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const DatePicker = ({
  value,
  label,
  className,
  onChange,
  onBlur,
  testSelector,
  inputProps,
  dateFormat,
  timeFormat,
  closeOnSelect = false,
  isValidDate = () => true,
  required = false,
  disabled = false
}) => {

  const handleChange = dateValue => {
    onChange(dateValue);
  };

  return (
    <ReactDatePicker
      value={value}
      className={className}
      onChange={handleChange}
      id={testSelector}
      data-testid={testSelector}
      name={testSelector}
      renderInput={WrappedInput}
      timeFormat={timeFormat ?? true}
      inputProps={{
        label,
        testSelector: `${testSelector}_input`,
        required,
        className,
        disabled,
        onBlur,
        ...inputProps,
      }}
      dateFormat={dateFormat}
      closeOnSelect={closeOnSelect}
      isValidDate={isValidDate}
    />
  );
};

DatePicker.propTypes = {
  /** value string | date | moment */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  testSelector: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

DatePicker.defaultProps = {
  value: '',
  label: '',
  className: 'form-input__calendar',
  onChange: () => { },
  testSelector: '',
  inputProps: {},
  timeFormat: undefined,
  dateFormat: undefined,
};

export default DatePicker;
