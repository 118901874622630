import { combineReducers } from '@reduxjs/toolkit';

const initialStatePermissions = {
    permissionData: {},
    loading: true,
    companyTypeId: 1,
};

const permissions = (state = initialStatePermissions, action) => {
  switch (action.type) {
    case 'ROLES_INIT_PERMISSIONS_SUCCESS':
      return {
        ...state,
        permissionData: action.data,
        loading: false,
      };
    case 'ROLES_INIT_PERMISSIONS_FAIL':
      return {
        ...state,
        loading: true,
        permissionData: {},
      };
    case 'ROLES_CHANGE_COMPANY_TYPE_ID':
      return {
        ...state,
        companyTypeId: action.id,
      };
    case 'ROLES_INIT_PERMISSIONS_CLEAR':
        return {
            ...state,
            permissionData: {},
            loading: true,
          };
    default:
      return state;
  }
};

export default combineReducers({
  permissions,
});
