import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Authorize from 'components/common/Authorize';
import Page from 'components/shared/Page';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import DriverListBlock from 'components/views/DriversList/DriverListBlock';
import DeviceStatistics from 'components/views/DriversList/DevicesStats';
import DriverStatistics from 'components/views/DriversList/DriverStats';
import AddDriver from 'components/views/DriversList/AddDriverModal';
import SendNotifications from 'components/views/DriversList/SendNotificationsModal';

import { clearNotificationState as clearNotificationStateAction } from 'store/actions/Drivers';
import { useGetDriversPagesQuery } from 'features/api/apiSlice';
import { useDebounce } from 'helpers/hooks';

const useQueryFilters = (filters, onFilterChange) => {
  const debouncedFilters = useDebounce(filters, 500);

  const mappedFilters = useMemo(
    () => ({
      driverName: debouncedFilters.name?.length
        ? debouncedFilters.name
        : undefined,
      isDeleted: debouncedFilters.deletedStatus ? 1 : 0,
      driverId: debouncedFilters.id?.length
        ? Number(debouncedFilters.id)
        : undefined,
      driverEmail: debouncedFilters.email?.length
        ? debouncedFilters.email
        : undefined,
      driverPhone: debouncedFilters.phone?.length
        ? debouncedFilters.phone
        : undefined,
      locationPermission:
        debouncedFilters.location !== null &&
        debouncedFilters.location !== undefined &&
        debouncedFilters.location !== ''
          ? Number(debouncedFilters.location)
          : undefined,
      districtDetail: debouncedFilters.district?.length
        ? debouncedFilters.district
        : undefined,
      truckNumber: debouncedFilters.truck?.length
        ? debouncedFilters.truck
        : undefined,
      carrierName: debouncedFilters.carrier?.length
        ? debouncedFilters.carrier
        : undefined,
      status: [0, 1].includes(debouncedFilters.freeBusyStatus)
        ? debouncedFilters.freeBusyStatus
        : undefined,
      androidVersion: debouncedFilters.android?.length
        ? debouncedFilters.android
        : undefined,
      iOSVersion: debouncedFilters.ios?.length
        ? debouncedFilters.ios
        : undefined,
      appVersion: debouncedFilters.appVersion?.length
        ? debouncedFilters.appVersion
        : undefined,
    }),
    [debouncedFilters],
  );

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(mappedFilters);
    }
  }, [mappedFilters]);

  return mappedFilters;
};

const DriverList = ({ filters, equipmentRequired, clearNotificationState }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  // Pagination, Results per page,
  const [pagination, setPagination] = useState({
    currentPage: 1,
  });
  const [pageLimit, setPageLimit] = useState(10);

  const applyFilters = () => {
    setPagination({ currentPage: 1 });
  };
  const queryFilters = useQueryFilters(filters, applyFilters);

  const {
    data: driverData,
    isLoading,
    isFetching,
    refetch,
  } = useGetDriversPagesQuery({
    ...queryFilters,
    page: pagination.currentPage,
    pageSize: pageLimit,
  });

  useEffect(() => {}, [filters]);

  const driverActions = (
    <>
      <Authorize
        abilityPermissions={[{ resource: 'Drivers', permissions: ['create'] }]}>
        <Button
          key="add-driver"
          inverse
          testSelector="drivers-list_add-driver_btn"
          onClick={() => {
            setOpenAdd(true);
          }}>
          <Icon className="icon--margin-right" icon="plus-square" />
          Add Driver
        </Button>
      </Authorize>
      <Authorize
        abilityPermissions={[{ resource: 'Drivers', permissions: ['update'] }]}>
        <Button
          key="send-notification"
          inverse
          testSelector="drivers-list_send-notification_btn"
          onClick={() => setOpenNotifications(true)}>
          Send Notification
        </Button>
      </Authorize>
      <Button onClick={refetch} testSelector="drivers-list_refresh_btn">
        <Icon icon="refresh" />
      </Button>
    </>
  );

  return (
    <>
      <Page
        title="Driver List"
        className="driver-list-wrapper"
        actions={driverActions}
        isLoading={isLoading}>
        {!driverData?.drivers?.length ? (
          <div className="ibox float-e-margins" style={{ marginTop: 10 }}>
            <div className="ibox-content text-center">
              <h3>No drivers available</h3>
            </div>
          </div>
        ) : (
          <div className="driver-list-wrapper__stats-block">
            <DriverStatistics drivers={driverData.drivers} />
            <DeviceStatistics drivers={driverData.drivers} />
          </div>
        )}
        <DriverListBlock
          totalPages={Math.ceil((driverData?.size ?? 0) / pageLimit)}
          applyFilters={applyFilters}
          drivers={driverData?.drivers ?? []}
          equipmentRequired={equipmentRequired}
          apiIsLoading={isFetching}
          setPagination={setPagination}
          pagination={pagination}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      </Page>

      {openAdd && (
        <AddDriver
          showModalAddDriver={openAdd}
          closeAddDriver={() => setOpenAdd(false)}
        />
      )}
      {openNotifications && (
        <SendNotifications
          showModalSendNotifications={openNotifications}
          closeSendNotifications={() => {
            clearNotificationState();
            setOpenNotifications(false);
          }}
        />
      )}
    </>
  );
};

/** @param {import('types/redux').RootState} state */
const mapStateToProps = state => ({
  filters: state.driversNew.filters,
  isLoadedCarriers: state.driversNew.isLoadedCarriers,
  carriers: state.driversNew.carriers,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  clearNotificationState: () => dispatch(clearNotificationStateAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverList);
