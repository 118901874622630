import React from 'react';

const Link = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    viewBox="-4 -4 24 24"
  >
    <g>
      <path d="M9.745 6.225a3.727 3.727 0 0 0-5.269-.001v.001L1.09 9.613a3.727 3.727 0 0 0 5.267 5.274l2.795-2.796a.266.266 0 0 0 .002-.376.262.262 0 0 0-.192-.078h-.107a4.501 4.501 0 0 1-1.703-.33.265.265 0 0 0-.29.059l-2.01 2.012a1.598 1.598 0 0 1-2.26-2.26l3.4-3.396a1.597 1.597 0 0 1 2.256 0 1.09 1.09 0 0 0 1.496 0c.18-.18.29-.42.31-.676a1.067 1.067 0 0 0-.309-.821z"/>
      <path d="M14.88 1.091a3.725 3.725 0 0 0-5.269 0h-.002l-2.792 2.79a.265.265 0 0 0-.002.376.265.265 0 0 0 .197.079h.098a4.49 4.49 0 0 1 1.701.332c.1.041.215.018.291-.058l2.004-2.002a1.598 1.598 0 0 1 2.26 2.26l-2.497 2.494-.021.024-.873.867a1.6 1.6 0 0 1-2.258 0 1.09 1.09 0 0 0-1.496 0 1.064 1.064 0 0 0 0 1.501c.308.311.669.562 1.065.746l.168.072c.055.023.114.041.17.062s.114.041.17.057l.157.043c.107.025.213.047.322.066.132.02.264.031.397.037h.202l.16-.02c.058-.002.12-.016.188-.016h.09l.185-.027.085-.014.154-.033h.029a3.724 3.724 0 0 0 1.728-.979l3.388-3.388a3.727 3.727 0 0 0 .001-5.269c-.001 0-.001 0 0 0z"/>
    </g>
  </svg>
);

export default Link;
