import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

const TemplateItems = ({ props, setStep }) => {
  const {
    closeAddTemplate,
    config,
    isAllChecked,
    checkAllFields,
    checkOneField,
    name,
    setTemplateName,
  } = props;

  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    config.sort((a, b) => a.value.localeCompare(b.value));
  }, [])

  const determineAddDisabled = () => {
    let disabled = false;
    if (!name.trim().length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [name]);
  return (
    <form role="form" onSubmit={() => setStep(2)}>
      <Modal.Header>
        <Modal.Title as="h4">Add Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          value={name}
          onChange={setTemplateName}
          label="Template Name"
          required
          testSelector="export_templates_add_name_input"
        />
        <div style={{ padding: '5px 5px' }}>
          <Row>
            <Col md={4}>
              <Input
                type="checkbox"
                testSelector="export_templates_add_select-all_input"
                onChange={checkAllFields}
                isChecked={isAllChecked}
                noMargin
                label="Select All"
              />
              <hr style={{ border: 'solid 1px rgba(255, 255, 255, 0.1)' }} />
            </Col>
          </Row>
          <div className="row footerSandSite" />
        </div>
        <Row>
          {config.map((data, i) => (
            <Col key={i} md={4}>
              <div
                style={{
                  padding: '5px 5px',
                  borderBottom: 'none',
                  display: 'flex',
                }}>
                <span
                  data-bs-toggle="tooltip"
                  title={data.value.length > 15 ? data.value : ''}>
                  <Input
                    type="checkbox"
                    onChange={() => checkOneField(data.value)}
                    isChecked={data.isChecked}
                    testSelector={`export_templates_add_item-${data.value}_input`}
                    label={`${data.value.substring(0, 16).split('_').map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join(' ')}${data.value.length > 15 ? '...' : ''
                      }`}
                  />
                </span>
                <label
                  htmlFor={`export_templates_add_item-${data.value}_input`}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddTemplate}
          colour="white"
          children="Close"
          testSelector="export_templates_add_close_btn"
        />
        <Button
          type="submit"
          disabled={saveDisabled}
          children="Next"
          testSelector="export_templates_add_save_btn"
        />
      </Modal.Footer>
    </form>
  );
};

export default TemplateItems;
