import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import moment from 'moment';

const TurnOrders = ({ info, isVisible, setIsVisible }) => {
  return (
    <div className="inmodal">
      <Modal
        style={{ top: '20%' }}
        className="modal-container"
        show={isVisible}
        onHide={() => setIsVisible(false)}>
        <div>
          <Modal.Header>
            <h3 className="modal-title">Orders</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  flex: '1 0 50%',
                  display: 'flex',
                  borderBottom: '1px solid',
                  borderBottomColor: 'rgba(255, 255, 255, .1)',
                  width: '100%',
                  padding: '7px',
                }}>
                <strong
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  Order Id{' '}
                </strong>
                <strong
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  Order Accepted At{' '}
                </strong>
              </div>

              {!info?.length && (
                <div className="font-bold text-danger textCenter">{`NO Data`}</div>
              )}

              {info.map((item, index) => (
                <div
                  key={`TurnInfo${index}`}
                  style={{
                    flex: '1 0 50%',
                    display: 'flex',
                    padding: '5px',
                  }}>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {item?.order_id}{' '}
                  </strong>
                  <strong
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {moment(item?.order_accepted_at).format('YYYY-MM-DD hh:mm A')}
                  </strong>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setIsVisible(false)}
              colour="white"
              children="Close"
              testSelector="turn-info-details_close_btn"
            />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};
export default TurnOrders;