import { combineReducers } from '@reduxjs/toolkit';

const initContainers = {
  loading: true,
  equipments: [],
  filterEquipmentNumber: '',
};
export const containers = (state = initContainers, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_GET_CONTAINERS_SUCCESS':
      return Object.assign({}, state, {
        loading: false,
        equipments: action.date.equipments,
      });
    case 'JOB_DETAILS_CONTAINERS_SET_FILTER_VALUE':
      return Object.assign({}, state, {
        filterEquipmentNumber: action.value,
      });
    case 'JOB_DETAILS_GET_CONTAINERS_FAILURE':
      return { loading: true };
    default:
      return state;
  }
};

const initSettings = {
  openModal: false,
  conteinerizedSettings: {
    stageBuffer: '',
    stageName: '',
    containersPerTruck: '',
    autoDispatchEnabled: '',
  },
  stageNames: [],
};

export const settings = (state = initSettings, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TURN_MODAL_CONTEINER_SETTINGS':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_CONTEINER_SETTINGS_INIT':
      return {
        ...state,
        conteinerizedSettings: action.data,
      };
    case 'JOB_DETAILS_CONTEINER_SETTINGS_SET_INPUT_VALUE':
      return {
        ...state,
        conteinerizedSettings: {
          ...state.conteinerizedSettings,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_CONTAINERIZED_STORAGE_EDIT_SUCCESS':
      return initSettings;
    case 'JOB_DETAILS_CONTEINER_SETTINGS_CLEAR_STORE':
      return initSettings;
    default:
      return state;
  }
};

export const containerized = combineReducers({
  containers,
  settings,
});
