import React from 'react';
import { Col } from 'react-bootstrap';
import { useOrderTickets } from 'api/v2/orders';
import OrderInfoTicket from './OrderInfoTicket';

const OrderDocuments = ({ orderId }) => {
  const { data: orderTickets } = useOrderTickets({ orderId });

  return (
    <Col md={12}>
      <div className="modify__documents">
        <div className="modify__documents__tickets panel-group" id="accordion">
          {orderTickets?.tickets &&
            orderTickets.tickets.map(ticket => (
              <OrderInfoTicket
                key={ticket.id}
                ticket={ticket}
                config={orderTickets.config}
                orderId={orderId}
              />
            ))}
        </div>
      </div>
    </Col>
  );
};

export default OrderDocuments;
