const selectTruckNumber = number => ({
  type: 'TRACK_DRIVERS_SELECT_TRUCK',
  number,
});
const selectDriverName = name => ({
  type: 'TRACK_DRIVERS_SELECT_NAME',
  name,
});
const selectJobId = jobId => ({
  type: 'TRACK_DRIVERS_SELECT_JOB_ID',
  jobId,
});
const selectStatus = status => ({
  type: 'TRACK_DRIVERS_SELECT_STATUS',
  status,
});

const selectFreeDrivers = () => ({
  type: 'TRACK_DRIVERS_SELECT_FREE_DRIVERS',
});
const selectBusyDrivers = () => ({
  type: 'TRACK_DRIVERS_SELECT_BUSY_DRIVERS',
});
const selectOfflineDrivers = () => ({
  type: 'TRACK_DRIVERS_SELECT_OFFLINE_DRIVERS',
});
const selectDeletedDrivers = () => ({
  type: 'TRACK_DRIVERS_SELECT_DELETED_DRIVERS',
});

const selectDriverLocation = driver => ({
  type: 'TRACK_DRIVERS_SELECT_LOCATION',
  driver,
});

const clearStore = () => ({ type: 'TRACK_DRIVERS_CLEAR_STORE' });

const actions = {
  selectTruckNumber,
  selectDriverName,
  selectFreeDrivers,
  selectBusyDrivers,
  selectOfflineDrivers,
  selectDeletedDrivers,
  selectDriverLocation,
  selectJobId,
  selectStatus,
  clearStore,
};

export default actions;
