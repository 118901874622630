const orderTicketsDefaultState = {
  tickets: [],
  config: null,
  apiIsLoading: false,
  lastApiCallSucceeded: true,
  actionApiIsLoading: false,
  actionLastApiCallSucceeded: true,
  preview: {
    apiIsLoading: false,
    lastApiCallSucceeded: true,
  },
  downloadAllTickets: {
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
  downloadSingleTicket: {
    apiIsLoading: false,
    lastApiCallSucceeded: false,
  },
};

export default (state = orderTicketsDefaultState, data) => {
  switch (data.type) {
    case 'TICKETS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'TICKETS_REQUEST_SUCCEEDED':
      return {
        ...state,
        tickets: data.tickets,
        config: data.config,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'TICKETS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    case 'TICKET_PREVIEW_REQUESTED':
      return {
        ...state,
        preview: {
          ...state.preview,
          apiIsLoading: true,
        },
      };
    case 'TICKET_PREVIEW_REQUEST_SUCCEEDED':
      return {
        ...state,
        preview: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'TICKET_PREVIEW_REQUEST_FAILED':
      return {
        ...state,
        preview: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'TICKET_ACTION_REQUESTED':
      return {
        ...state,
        actionApiIsLoading: true,
      };
    case 'TICKET_ACTION_REQUEST_SUCCEEDED':
      return {
        ...state,
        actionApiIsLoading: false,
        actionLastApiCallSucceeded: true,
      };
    case 'TICKET_ACTION_REQUEST_FAILED':
      return {
        ...state,
        actionApiIsLoading: false,
        actionLastApiCallSucceeded: false,
      };
    case 'DOWNLOAD_ALL_TICKETS_REQUESTED':
      return {
        ...state,
        downloadAllTickets: {
          ...state.downloadAllTickets,
          apiIsLoading: true,
        },
      };
    case 'DOWNLOAD_ALL_TICKETS_SUCCEEDED':
      return {
        ...state,
        downloadAllTickets: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'DOWNLOAD_ALL_TICKETS_FAILED':
      return {
        ...state,
        downloadAllTickets: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    case 'DOWNLOAD_SINGLE_TICKETS_REQUESTED':
      return {
        ...state,
        downloadSingleTicket: {
          ...state.downloadSingleTicket,
          apiIsLoading: true,
        },
      };
    case 'DOWNLOAD_SINGLE_TICKETS_SUCCEEDED':
      return {
        ...state,
        downloadSingleTicket: {
          apiIsLoading: false,
          lastApiCallSucceeded: true,
        },
      };
    case 'DOWNLOAD_SINGLE_TICKETS_FAILED':
      return {
        ...state,
        downloadSingleTicket: {
          apiIsLoading: false,
          lastApiCallSucceeded: false,
        },
      };
    default:
      return state;
  }
};
