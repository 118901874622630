import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import {
  get,
  edit,
  add,
  remove
} from './queries';

export const useCertificates = () =>
  useQuery({
    queryKey: ['certificates'],
    queryFn: get,
  });

export const useEditCertificate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: edit,
    onSuccess: () => {
      swal('Success', 'Certificate updated successfully', 'success');
      queryClient.invalidateQueries({ queryKey: ['certificates'] });
    },
  });
}

export const useAddCertificate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: add,
    onSuccess: () => {
      swal('Success', 'Certificate added successfully', 'success');
      queryClient.invalidateQueries({ queryKey: ['certificates'] });
    },
  });
}

export const useRemoveCertificate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: remove,
    onSuccess: () => {
      swal('Success', 'Certificate removed successfully', 'success');
      queryClient.invalidateQueries({ queryKey: ['certificates'] });
    },
  });
}
