export const billOptions = [
  {
    value: 1,
    label: 'Automatize',
  },
  {
    value: 2,
    label: 'Customer',
  },
];

export const auditOptions = [
  {
    value: 2,
    label: 'Customer',
  },
  {
    value: 3,
    label: 'Carrier',
  },
  {
    value: 6,
    label: 'Job Manager',
  },
];
