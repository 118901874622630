import { useJobDetails } from 'api/v2/jobs';
import { useCommodities } from 'api/v2/commodities';
import { groupBy } from 'lodash';
import { useMemo } from 'react';

export const useCommodityOptions = jobId => {
  const {
    data: { jobDesign },
  } = useJobDetails({ jobId });
  const { data: commodities } = useCommodities();

  const commodityOptions = useMemo(
    () =>
      jobDesign.map(design => {
        const commodity = commodities.find(c => c.id === design.sandTypeId);

        return {
          ...design,
          ...commodity,
          value: design.sandTypeId,
          label: design.sandTypeName,
        }
      }),
    [jobDesign, commodities],
  );

  return commodityOptions;
};

export const useStageDesignOptions = jobId => {
  const { data: jobDetails } = useJobDetails({ jobId });

  const stageDesignOptions = useMemo(() => {
    const stageDesign = jobDetails?.stageDesign;
    if (!stageDesign) {
      return [];
    }

    const groupedStageDesign = groupBy(stageDesign, 'name');
    return Object.keys(groupedStageDesign).map(designName => ({
      label: designName,
      value: designName,
      design: groupedStageDesign[designName],
    }));
  }, [jobDetails]);
  return stageDesignOptions;
};

export const poArrayCombiner = poArray => {
  if (poArray.length) {
    return poArray.map(po => {
      const mappedPo = { ...po };
      mappedPo.scheduledPercentage = (po.scheduled * 100) / po.total;
      if (po.scheduledPercentage > 100) {
        mappedPo.scheduledPercentage = 100;
      }
      mappedPo.leftPercentage = (po.left * 100) / po.total;
      mappedPo.loadedPercentage = (po.loaded * 100) / po.total;
      mappedPo.deliveredPercentage = (po.delivered * 100) / po.total;
      mappedPo.travelTimeMinutesView = `${parseInt(
        po.travelTimeMinutes / 60,
        10,
      )} hour(s) ${parseInt(po.travelTimeMinutes % 60, 10)} minute(s)`;
      return mappedPo;
    });
  }
  return poArray;
};

export const usePoOptions = jobId => {
  const {
    data: { purchaseOrders },
  } = useJobDetails({ jobId });

  const poOptions = useMemo(
    () =>
      poArrayCombiner(purchaseOrders).map(po => ({
        value: po.poNo,
        label: `${po.poNo} | ${po.sandType} | ${po.originName}`,
        commodity: po.sandTypeId,
      })),
    [purchaseOrders],
  );
  return poOptions;
};
