import axios from 'axios';

export const certificates = {
  get() {
    return axios.get('v2/certificates', {});
  },
  edit(data) {
    return axios.put(`v2/certificates/${data.id}`, data);
  },
  add(data) {
    return axios.post('v2/certificates', data);
  },
  remove(data) {
    return axios.delete(`v2/certificates/${data.certificateId}`, {});
  },
};
