import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';

import targetValue from 'utils/targetValue';
import { useAddCarrierRatioToDistrict } from 'api/v2/districts';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

const EditRatio = ({ carriers, showModal, closeModal, activeTrucks, districtId }) => {
  const { mutate: editCarrierRatio } = useAddCarrierRatioToDistrict(districtId);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [carrierRatio, setCarrierRatio] = useState(carriers);

  const submitEdit = (e) => {
    e.preventDefault();
    const ratio = Array.isArray(carrierRatio) && carrierRatio.length
      ? carrierRatio.map(item => ({
        carrierId: Number(item.id),
        activeTrucks: Number(item.ratio),
      }))
      : [];

    editCarrierRatio(
      {
        carrierRatio: ratio,
        districtId
      },
      {
        onSuccess: closeModal
      }
    );
  };

  const updateRatio = (e, id) => {
    const ratio = targetValue(e);
    const data = carrierRatio.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ratio
        };
      }
      return item;
    });

    setCarrierRatio(data);
  };

  const total = useMemo(() =>
    carrierRatio.reduce((acc, item) => acc + Number(item.ratio), 0),
    [carrierRatio]);

  // determine save disabled
  useEffect(() => {
    let disabled = false;

    if (carrierRatio.find(i => i.ratio === '')) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  }, [carrierRatio]);

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      <Modal.Header>
        <h3>Edit Carrier Ratio</h3>
      </Modal.Header>
      <form onSubmit={e => submitEdit(e, closeModal)}>
        <Modal.Body className="form-group">
          <div className="form-group">
            <h5>
              Current active trucks
              <span
                className="label-primary label"
                style={{ fontSize: 'initial' }}>
                {activeTrucks}
              </span>
              Updated active trucks will be
              <span
                className="label-primary label"
                style={{ fontSize: 'initial' }}>
                {total}
              </span>
            </h5>
          </div>
          {carrierRatio.map(item => (
            <Row key={`ration-${item.id}`}>
              <div
                className="form-group has-feedback col-md-4"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '45px',
                }}>
                {item.id} | {item.name}
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => updateRatio(e, item.id)}
                  value={item.ratio}
                  label={`Ratio for ${item.name}`}
                  min="0"
                  max="100"
                  required
                  testSelector="district-details_edit-ratio_ratio-for_input"
                />
              </div>
            </Row>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="district-details_edit-ratio_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="district-details_edit-ratio_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditRatio;
