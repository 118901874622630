export const submitFunction = (e, formState, order, onSuccess, updateOrder, stagingSite) => {
  e.preventDefault();
  if (
    stagingSite && formState.stageArrivalTime &&
    formState.stageArrivalTime !== 'Invalid date' &&
    (formState.stageDepartureTime === 'Invalid date' ||
      !formState.stageDepartureTime)
  ) {
    swal('Please, select a Stage Departure Time', '', 'error');
  } else if (
    stagingSite && formState.stageDepartureTime &&
    formState.stageDepartureTime !== 'Invalid date' &&
    (formState.stageArrivalTime === 'Invalid date' ||
      !formState.stageArrivalTime)
  ) {
    swal('Please, select a Stage Arrival Time', '', 'error');
  } else {
    updateOrder(formState, order, onSuccess);
  }
};

export const isLoadWeightValid = (weight, jobWeight) => {
  // if (weight && (weight > jobWeight || weight < 100)) {
  if (!weight || (weight > 85000 || weight < 100)) {
    return true;
  }
  return false;
};
