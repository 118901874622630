import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  createUnitOfMeasure,
  getUnitsOfMeasure,
  updateUnitOfMeasure,
  deleteUnitOfMeasure
} from './queries';

export const useCreateUnitOfMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: data => createUnitOfMeasure(data),
    mutationKey: ['units-of-measure'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['units-of-measure'] });
      swal('Success', 'Unit of measure created successfully', 'success');
    },
    onError: (error) => {
      swal('Error', error.response.data.data.message, 'error');
    }
  });
}

export const useUnitsOfMeasure = () =>
  useQuery({
    queryKey: ['units-of-measure'],
    queryFn: getUnitsOfMeasure
  });

export const useUpdateUnitOfMeasure = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (unit) => updateUnitOfMeasure({ ...unit, id }),
    mutationKey: ['units-of-measure'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['units-of-measure'] });
      swal('Success', 'Unit of measure updated successfully', 'success');
    },
    onError: (error) => {
      swal('Error', error.response.data.data.message, 'error');
    }
  });
}

export const useDeleteUnitOfMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteUnitOfMeasure,
    mutationKey: ['units-of-measure'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['units-of-measure'] });
      swal('Success', 'Unit of measure deleted successfully', 'success');
    },
    onError: (error) => {
      swal('Error', error.response.data.data.message, 'error');
    }
  });
}
