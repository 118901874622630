import React from 'react';
import Icon from 'components/shared/Icon';

export const image = data => {
    switch (data) {
      case 1:
        return <Icon icon="sand" />;
      case 2:
        return <Icon icon="waterdrop"/>
      case 3:
        return <Icon icon="water" />;
      case 5:
        return <Icon icon="driver" />;
      default:
        return;
    }
  };

export const commodityUnits = {
  0: {
    title: 'unknown',
    sign: 'unknown',
  },
  1: {
    title: 'pounds',
    sign: 'lbs',
  },
  2: {
    title: 'barrels',
    sign: 'bbl',
  },
};

export const commodityTypes = commodityId => {
  switch(commodityId){
    case 0:
      return {
        title: 'Unknown',
        color: 'default',
      }
    case 1:
      return {
        title: 'Sand',
        color: 'warning',
      }
    case 2:
      return {
        title: 'Fresh water',
        color: 'info',
      }
    case 3:
      return {
        title: 'Production water',
        color: 'success',
      }
    case 4:
      return {
        title: 'Unknown',
        color: 'default',
      }
    case 5:
      return {
        title: 'Freight',
        color: 'primary',
      }
    default:
      return {
        title: 'Unknown',
        color: 'default',
      }
  }
};
