import React from 'react';
import { convertTimeWithSeconds } from 'utils/convertTime';
import orderDetailsModel from 'models/OrderDetails';
import { checkTime } from 'components/globalInfo/timeline';
import ToolTip from 'components/shared/ToolTip';
import moment from 'moment';

const checkIsNaN = value => (isNaN(value) ? `-` : `${Math.abs(value)} M`);

const timeLateFaster = value => {
  return (
    <>
      {isNaN(value) ? (
        ''
      ) : value >= 0 ? (
        <span className="timeline__list__title__warning">
          {Math.abs(value)}m late
        </span>
      ) : (
        <span className="timeline__list__title__warning">
          {Math.abs(value)}m faster
        </span>
      )}
    </>
  );
};

const Timeline = ({ data = null }) => {
  const { loadingTimes } = orderDetailsModel;
  const loadUnloadTimes = loadingTimes(data);

  data.loading_late = moment(data.load_arrival).diff(
    moment(data.expected_pickup_time),
    'minutes',
  );
  data.unloading_late = moment(data.well_arrival).diff(
    moment(data.unload_appt),
    'minutes',
  );

  const timeline = [
    {
      section: 'accepted',
      time: checkIsNaN(data.accepted_minutes),
      values: [
        {
          title: 'Accepted order',
          value: convertTimeWithSeconds(data.order_accepted_at),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
      ],
    },
    {
      section: 'loading',
      time: `${loadUnloadTimes.loading} M`,
      values: [
        {
          title: 'Arrived at Origin',
          value: convertTimeWithSeconds(data.load_arrival),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
        {
          title: 'Departure from Origin',
          value: convertTimeWithSeconds(data.load_depart),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
        {
          title: 'Scheduled',
          value: convertTimeWithSeconds(data.expected_pickup_time),
          faster: timeLateFaster(data.loading_late),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
      ],
    },
    {
      section: 'unloading',
      time: `${loadUnloadTimes.unloading} M`,
      values: [
        {
          title: 'Arrived at Destination',
          value: convertTimeWithSeconds(data.well_arrival),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
        {
          title: 'Departure from Destination',
          value: convertTimeWithSeconds(data.well_depart),
          isAlerted:
            data.driver_time_well_departure &&
            checkTime(data.driver_time_well_departure) !== 'unknown',
          alternateTitle: `Driver Triggered Exit Geofence: ${checkTime(
            data.driver_time_well_departure,
          )}`,
          alternateDate: checkTime(data.driver_time_well_departure),
        },
        {
          title: 'Scheduled',
          value: convertTimeWithSeconds(data.unload_appt),
          faster: timeLateFaster(data.unloading_late),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
      ],
    },
  ];

  if (data.enter_stage) {
    timeline.splice(2, 0, {
      section: 'staging',
      time: `${loadUnloadTimes.staging} M`,
      values: [
        {
          title: 'Arrived at Stage',
          value: convertTimeWithSeconds(data.enter_stage),
          isAlerted: false,
          alternateTitle: null,
          alternateDate: null,
        },
      ],
    });
  }
  if (data.depart_stage) {
    let array = timeline.find(i => i.section === 'staging');
    array.values.push({
      title: 'Departure from Stage',
      value: convertTimeWithSeconds(data.depart_stage),
      isAlerted: false,
      alternateTitle: null,
      alternateDate: null,
    });
  }

  return (
    <div className="timeline">
      {timeline.map(line => (
        <div key={line.section}>
          <h4 className="timeline__title">
            <span>{line.section}</span>
            <span className="white timeline__title__text">{line.time}</span>
          </h4>
          <div className="timeline__list">
            {line.values.map((item, index) => (
              <div
                key={item.title}
                className={
                  `timeline__list__item ${!!index &&
                    'timeline__list__border'}` || ''
                }>
                <span
                  className={
                    item.isAlerted
                      ? 'timeline__list__title__danger'
                      : 'timeline__list__title'
                  }>
                  {item.isAlerted ? (
                    <ToolTip title={item.alternateTitle} placement="top">
                      {item.title} {!!item.faster && item.faster}
                    </ToolTip>
                  ) : (
                    <>
                      {item.title} {!!item.faster && item.faster}
                    </>
                  )}
                </span>
                <span className="timeline__list__value">{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
