import { useReducer, useMemo, useEffect } from 'react';
import targetValue from 'utils/targetValue';
import { cloneDeep } from 'lodash';

import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';
import { useStorageTypes } from 'api/v2/storage-types';
import { useJobDetails } from 'api/v2/jobs';

const initialStorageType = {
  commodity: null,
  storageType: null,
  name: '',
  capacity: null,
  storageId: null,
};

const initialStorageConfig = {
  storageConfig: [initialStorageType],
};

const StorageReducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        storageConfig: action.data,
      };
    }
    case 'set_array_value': {
      const newStorageConfig = cloneDeep(state.storageConfig);
      newStorageConfig[action.index][action.name] = action.value;

      return {
        ...state,
        storageConfig: newStorageConfig,
      };
    }
    case 'add_array_value': {
      const newStorageConfig = cloneDeep(state.storageConfig);
      newStorageConfig.push({ ...initialStorageType });

      return {
        ...state,
        storageConfig: newStorageConfig,
      };
    }
    case 'delete_array_value': {
      const newStorageConfig = cloneDeep(state.storageConfig);
      newStorageConfig.splice(action.index, 1);

      return {
        ...state,
        storageConfig: newStorageConfig,
      };
    }
    default: {
      return state;
    }
  }
};

export const useAddUOMStorageSectionForm = ({
  showModal,
  defaultValue,
  jobId,
}) => {
  const [data, dispatch] = useReducer(StorageReducer, initialStorageConfig);
  const { data: jobDetails } = useJobDetails({ jobId });
  const customerId = jobDetails?.details?.customerId;
  const { data: customerCommodities } = useCustomerCommodities(customerId);
  const { data: defaultCommodities } = useCommodities();
  const commodities = customerId ? customerCommodities : defaultCommodities;
  const { data: storageTypes } = useStorageTypes();
  const commodityOptions = useMemo(() => {
    if (jobDetails?.jobDesign) {
      return (commodities || [])
        .map(comm => ({ ...comm, value: comm.id, label: comm.name }))
        .filter(comm =>
          jobDetails.jobDesign.some(design => design.sandTypeId === comm.id),
        );
    }
    return (commodities || []).map(comm => ({
      ...comm,
      value: comm.id,
      label: comm.name,
    }));
  }, [commodities, jobDetails]);

  const storageTypeOptions = useMemo(
    () =>
      storageTypes.map(type => ({ ...type, value: type.id, label: type.name })),
    [storageTypes],
  );

  useEffect(() => {
    if (defaultValue && showModal) {
      dispatch({
        type: 'init',
        data: defaultValue.map(item => ({
          ...item,
          commodity: commodityOptions.find(
            comm => comm.id === item.commodityId,
          ),
          storageType: storageTypeOptions.find(
            type => type.id === item.storageTypeId,
          ),
        })),
      });
    }
  }, [defaultValue, showModal, commodityOptions, storageTypeOptions]);

  const handlers = useMemo(
    () => ({
      setArrayValue: (name, index) => e =>
        dispatch({
          type: 'set_array_value',
          name,
          index,
          value: targetValue(e),
        }),
      addArrayValue: () => dispatch({ type: 'add_array_value' }),
      deleteArrayValue: index => () =>
        dispatch({ type: 'delete_array_value', index }),
    }),
    [],
  );

  const isValid = useMemo(
    () =>
      data.storageConfig.every(storage => {
        if (!storage.name?.length) {
          return false;
        }

        if (
          !storage.storageType.isLive &&
          (!storage.commodity || !storage.capacity)
        ) {
          return false;
        }

        if (storage.storageType.isLive && !storage.storageId?.length) {
          return false;
        }

        return true;
      }),
    [data.storageConfig],
  );

  return {
    data,
    commodityOptions,
    storageTypeOptions,
    handlers,
    isValid,
  };
};
