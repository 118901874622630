import React, { useMemo } from 'react';
import Select from 'components/shared/Select';

import { useLocations } from 'api/v2/locations';

const StagingSiteSelect = ({ onChange, jobStagingSite, stagingSite }) => {
  const { data: locations } = useLocations();
  const stagingSiteOptions = useMemo(() => {
    if (jobStagingSite) {
      return (locations || [])
        .filter(item => item.id === jobStagingSite)
        .map(i => ({
          value: i.id,
          label: i.name,
        }));
    }
    return [];
  }, [jobStagingSite, locations]);

  return (
    <Select
      closeMenuOnSelect={false}
      onChange={onChange}
      value={
        stagingSite && stagingSiteOptions.find(k => k.value === stagingSite)
      }
      options={stagingSiteOptions}
      placeholder="Staging Site"
      testSelector="job-details_add-order_staging-site_select"
      isClearable
    />
  );
};
export default StagingSiteSelect;
