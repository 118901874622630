import {
  useQuery,
} from '@tanstack/react-query';
import { getBillableOrders } from './queries';

/**
 * 
 * @param {{ jobPlanId: number; timeFrame: import('./queries').TimeFrame }} 
 */
export const useBillableOrders = ({ jobPlanId, timeFrame }) => useQuery({
  queryKey: ['job-plans', Number(jobPlanId), 'orders', timeFrame],
  queryFn: () => getBillableOrders({ jobPlanId, timeFrame }),
  enabled: !!(jobPlanId && timeFrame?.start && timeFrame?.end)
})
