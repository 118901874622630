import moment from 'moment';

export const matchingIntegerArray = (array1, array2) => {
  const set1 = new Set(array1);
  for (const num of array2) {
    if (set1.has(num)) {
      return true;
    }
  }
  return false;
};

export const loadEventsOptions = [
  {
    value: 2,
    orderValue: 1,
    label: 'Load Accepted',
  },
  {
    value: 5,
    orderValue: 2,
    label: 'Load Arrival',
  },
  {
    value: 3,
    orderValue: 3,
    label: 'Load Depart',
  },
  {
    value: 7,
    orderValue: 4,
    label: 'En Route To Stage',
  },
  {
    value: 8,
    orderValue: 5,
    label: 'Arrived At Stage',
  },
  {
    value: 6,
    orderValue: 6,
    label: 'Well  Arrival',
  },
  {
    value: 4,
    orderValue: 7,
    label: 'Completed',
  },
];

export const billableStatus = Object.freeze({
  1: 'Active Passing',
  2: 'Active at Risk',
  3: 'Passed',
  4: 'Failed',
});
export const billableStatusColors = Object.freeze({
  1: 'green',
  2: 'orange',
  3: 'darkGreen',
  4: 'red',
  5: 'default',
});
export const orderMap = {
  2: 0,
  4: 1,
  1: 2,
  3: 3
};


export const convertDataForOld = (data, forModify) => {
  if (data) {
    return {
      ...data,
      created_at: data.createdAt,
      completed_at: data.completedAt,
      customer_order_id: data.customerOrderId,
      driver_weight: data.driverWeight,
      updated_at: data.updatedAt,
      billing_status: data.billingStatus || 0,
      review_status: data.reviewStatus || 0,
      equipment: data.equipmentId,
      is_deleted: data.isDeleted,
      carrier_id: data.carrierId,
      customer_id: data.customerId,
      order_id: data.orderId,
      load_arrival: data.loadArrival,
      load_depart: data.loadDepart,
      well_arrival: data.wellArrival,
      well_depart: data.wellDepart,
      unload_appt: data.unloadAppt,
      accepted_minutes: moment(data.orderAcceptedAt).diff(moment(data.loadArrival), 'minutes'),
      loading_minutes: moment(data.loadDepartt).diff(moment(data.loadArrival), 'minutes'),
      loading_late: moment(data.loadArrival).diff(moment(data.expectedPickupTime), 'minutes'),
      unloading_minutes: moment(data.wellDepart).diff(moment(data.wellArrival), 'minutes'),
      unloading_late: moment(data.wellArrival).diff(moment(data.unloadAppt), 'minutes'),
      order_accepted_at: data.orderAcceptedAt,
      expected_pickup_time: data.expectedPickupTime,
      enter_stage: forModify && !data?.staging_enter_time ? 'Invalid date' : data.staging_enter_time,
      enterStage: data.staging_enter_time,
      depart_stage: forModify && !data?.staging_exit_time ? 'Invalid date' : data.staging_exit_time,
      departStage: data.staging_exit_time,
      driverId: data.driver_id,
      truckStatus: data.truck_status,
      trailer_number: data.trailerNumber,
      kingComp: data.king_comp,
      invoice_id: data.invoiceId,

      chatId: 1,
    }
  }
  return {};

}
