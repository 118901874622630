import { combineReducers } from '@reduxjs/toolkit';
import { zoomDefault } from 'components/globalInfo/mapConstants';

const initialStateFilterDrivers = {
  number: '',
  name: '',
  offline: false,
  isDeleted: false,
  status: undefined,
  jobId: undefined,
};

const filterDrivers = (state = initialStateFilterDrivers, action) => {
  switch (action.type) {
    case 'TRACK_DRIVERS_SELECT_TRUCK':
      return { ...state, number: action.number };
    case 'TRACK_DRIVERS_SELECT_NAME':
      return { ...state, name: action.name };
    case 'TRACK_DRIVERS_SELECT_JOB_ID':
      return { ...state, jobId: action.jobId };
    case 'TRACK_DRIVERS_SELECT_FREE_DRIVERS':
      return { ...state, free: !state.free };
    case 'TRACK_DRIVERS_SELECT_BUSY_DRIVERS':
      return { ...state, busy: !state.busy };
    case 'TRACK_DRIVERS_SELECT_STATUS':
      return { ...state, status: action.status };
    case 'TRACK_DRIVERS_SELECT_OFFLINE_DRIVERS':
      return { ...state, offline: !state.offline };
    case 'TRACK_DRIVERS_SELECT_DELETED_DRIVERS':
      return { ...state, isDeleted: !state.isDeleted };
    case 'TRACK_DRIVERS_CLEAR_STORE': {
      return initialStateFilterDrivers;
    }

    default:
      return state;
  }
};

const initialStateLocation = {
  driver: { latitude: 34.50885, longitude: -104.245758 },
  zoom: zoomDefault,
};

const selectLocation = (state = initialStateLocation, action) => {
  switch (action.type) {
    case 'TRACK_DRIVERS_SELECT_LOCATION':
      return { ...state, driver: action.driver, zoom: 12 };
    case 'TRACK_DRIVERS_CLEAR_STORE':
      return initialStateLocation;
    default:
      return state;
  }
};

export const trackDrivers = combineReducers({
  filterDrivers,
  selectLocation,
});
