import decode from 'jwt-decode';
import axios from 'axios';
import swal from 'bootstrap-sweetalert';

class authService {
  constructor() {
    this.login = this.login.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  login(code, roleId, entityId, roleTypeId) {
    if (this.isTokenExpired(code)) {
      swal('Auth token has expired', '', 'error');
      return Promise.reject('expired');
    }
    return axios
      .post('v2/auth/choose-role/atmz', {
        code,
        roleId,
        entityId,
        roleTypeId,
      })
      .then(res => {
        if (res.data.data.token) {
          this.setToken(res.data.data.token);
        }
        return Promise.resolve(res);
      });
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUserPermissions() {
    return localStorage.getItem('userPermissions');
  }

  getUserCompanyType() {
    return localStorage.getItem('selectedCompanyType');
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userPermissions');
  }

  getUser() {
    if (this.loggedIn()) {
      const user = decode(this.getToken());
      const userPermissions = this.getUserPermissions();
      const companyType = this.getUserCompanyType();
      const roles = this.getRoles();
      const selectedRoleType = JSON.parse(
        localStorage.getItem('selectedRoleTypeId'),
      );
      const selectedRoles = roles.filter(role => role.entityId === user.entity);
      const currentRole = selectedRoles.find(
        item => item.roleTypeId === selectedRoleType,
      );

      return {
        id: user.id,
        role: user.role,
        name: user.name,
        permissions: user.permission,
        userPermissions,
        entityId: user.entity,
        entityType: user.role,
        roles,
        currentRole,
        companyType,
      };
    }
    return null;
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  getRoles() {
    const roles = JSON.parse(localStorage.getItem('roles'));

    if (!roles) {
      this.logout();
    }

    return roles;
  }

  setPermissions(inputPermissions) {
    localStorage.removeItem('userPermissions');
    const userPerms = inputPermissions.map(perm => {
      let resourceName = perm[1]
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

      // This formatting is temporary and will be fixed on the backend
      if (resourceName === 'Manage-database') {
        resourceName = 'Database';
      }
      if (resourceName === 'Manage-locations') {
        resourceName = 'Locations';
      }
      if (resourceName === 'Manage-districts') {
        resourceName = 'Districts';
      }
      if (resourceName === 'Equipment-trailers') {
        resourceName = 'Trailers';
      }
      if (resourceName === 'Equipment-containers') {
        resourceName = 'Containers';
      }
      if (resourceName === 'Po') {
        resourceName = 'Purchase-orders';
      }
      if (resourceName === 'Admin-settings') {
        resourceName = 'Admin';
      }

      perm[1] = resourceName;

      return perm;
    });

    const formattedUserPermissions = JSON.stringify(userPerms);
    localStorage.setItem('userPermissions', formattedUserPermissions);
  }

  getCompany() {
    return localStorage.getItem('selectedEntity');
  }

  getRoleTypes() {
    return axios.get('v2/auth/role-types', {}).then(
      res => Promise.resolve(res.data),
      error => {
        console.error(error);
      },
    );
  }
}

export default new authService();
