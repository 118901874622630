export const trailerLocation = {
  1: {
    name: 'Sand Site',
    image: 'sand-site.png',
    id: 1,
  },
  2: {
    name: 'Wellsite',
    image: 'wellsite.png',
    id: 2,
  },
  3: {
    name: 'Driver',
    image: 'truck.png',
    id: 3,
  },
  4: {
    name: 'Job',
    image: 'manager.png',
    id: 4,
  },
  5: {
    name: 'Acid Facility',
    image: 'mapMarker-acidFacility.svg',
    id: 5,
  },
  6: {
    name: 'Unknown',
    image: 'from.png',
    id: 6,
  }
};
