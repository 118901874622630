import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useRemoveDistrict, useDistricts } from 'api/v2/districts';

import { deleteAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Label from 'components/shared/Label';
import ToolTip from 'components/shared/ToolTip';
import AutomatizeLogo from 'components/shared/AutomatizeLogo';
import Authorize from 'components/common/Authorize';
import actions from 'store/actions/ManageDatabase';
import AddEditModal from './AddEditModal';

const Districts = ({
  setModalAddValue,
  setModalEditValue,
}) => {
  const { data: districts, isLoading, isFetching } = useDistricts();
  const { mutate: removeDistrict } = useRemoveDistrict();

  const [showUpdate, turnShowUpdate] = useState(false);

  const isLoaded = !isLoading && !isFetching;

  const openUpdate = (status, value) => {
    switch (status) {
      case 'add':
        setModalAddValue(value || {});
        break;
      case 'edit':
        setModalEditValue(value);
        break;
      default:
        break;
    }
    turnShowUpdate(true);
  };

  return (
    <div>
      {showUpdate && (
        <AddEditModal
          {...{ showUpdate }}
          closeUpdate={() => turnShowUpdate(false)}
        />
      )}
      <div className="wrapper-content">
        <div className="row">
          <div>
            {isLoaded ? (
              <div className="ibox">
                <div className="ibox-content">
                  <Authorize
                    {...{
                      abilityPermissions: [
                        {
                          resource: 'Database',
                          permissions: ['create'],
                        },
                      ],
                    }}>
                    <Button
                      onClick={() => openUpdate('add')}
                      inverse
                      className="float-end"
                      testSelector="database_districts_add_btn">
                      <Icon className="icon--margin-right" icon="plus-square" />
                      Add District
                    </Button>
                  </Authorize>
                  {districts.map(item => (
                    <div
                      key={`key${item.id}`}
                      className="tree-node tree-node-content">
                      <span className="tree-node-content-name">
                        <strong style={{ color: '#ffffff' }}>
                          <Icon icon="angle-right" /> #{item.id} | {item.name}
                        </strong>
                        {Boolean(item.district_details.length) && (
                          <em> ({item.district_details}) </em>
                        )}
                      </span>
                      {item.companyOwnerName && (
                        <ToolTip title={item.companyOwnerName} placement="top">
                          <Label type="success">SaaS</Label>
                        </ToolTip>
                      )}
                      {item.companyOwnerId === 1 &&
                        item.companyOwnerType === 6 && <AutomatizeLogo />}
                      <Authorize {...{ forbidden: ['CARRIER'] }}>
                        <span>
                          <Authorize
                            {...{
                              abilityPermissions: [
                                {
                                  resource: 'Database',
                                  permissions: ['update'],
                                },
                              ],
                            }}>
                            <Button
                              onClick={() => openUpdate('edit', item)}
                              theme="small"
                              testSelector="database_districts_edit_btn">
                              <Icon icon="pencil" />
                            </Button>
                          </Authorize>

                          <Authorize
                            {...{
                              abilityPermissions: [
                                {
                                  resource: 'Districts',
                                  permissions: ['delete'],
                                },
                              ],
                            }}>
                            <Button
                              onClick={() =>
                                deleteAlert(removeDistrict, item.id, null, null, {
                                  confirmText: 'Remove district',
                                  text:
                                    'All carriers and drivers will be removed from this district.',
                                })
                              }
                              theme="small"
                              testSelector="database_districts_delete_btn">
                              <Icon icon="trash" />
                            </Button>
                          </Authorize>
                        </span>
                      </Authorize>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="ibox">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  setModalAddValue: value => dispatch(actions.setModalAddValueDistrict(value)),
  setModalEditValue: value =>
    dispatch(actions.setModalEditValueDistrict(value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Districts);
