import React, { createRef, useImperativeHandle } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getEnvironment } from 'utils/getEnvironment';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // 1 min
    },
  },
});

/** @typedef {{ children: React.ReactNode}} APIProviderProps */

// In case you want to use Query functions outside React tree
/** @type {QueryClient} */
export const QueryRef = createRef();

/** @type {React.FC<APIProviderProps>} */
const APIProvider = ({ children }) => {
  useImperativeHandle(QueryRef, () => queryClient);

  // eslint-disable-next-line no-underscore-dangle
  const env = getEnvironment(window._env_.API_BASE_URL);

  return (
    <QueryClientProvider client={queryClient}>
      {env === 'development' && <ReactQueryDevtools />}
      {children}
    </QueryClientProvider>
  );
};
export default APIProvider;
