import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import { confirmAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';

import actions from 'store/actions/ManageDatabase';
import { filterVendors } from 'store/selectors/vendorsDatabase';

import AddEditModal from './AddEditModal';
import Filter from './Filters';

const Vendors = ({
  vendors,
  vendorTypes,
  isLoaded,
  remove,
  unDeleteVendor,
  init,
  setModalAddValue,
  setModalEditValue,
}) => {
  const [showUpdate, turnShowUpdate] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const openUpdate = (status, value) => {
    switch (status) {
      case 'add':
        setModalAddValue(value || {});
        break;
      case 'edit':
        setModalEditValue(value);
        break;
      default:
        break;
    }
    turnShowUpdate(true);
  };

  const renderVendor = item => (
    <div key={`key${item.id}`} className="tree-node tree-node-content">
      <span className="tree-node-content-name" style={{ color: '#ffffff' }}>
        <strong>
          #{item.id} | {item.name} ( {item.types.map(t => t.name).join(', ')} )
        </strong>
      </span>
      <Authorize forbidden={['CARRIER']}>
        <span>
          <Authorize
            abilityPermissions={[
              { resource: 'Database', permissions: ['update'] },
            ]}>
            <Button
              onClick={() => openUpdate('edit', item)}
              theme="small"
              testSelector="database_vendor_edit_btn">
              <Icon icon="pencil" />
            </Button>
          </Authorize>
          <Authorize
            abilityPermissions={[
              { resource: 'Database', permissions: ['delete'] },
            ]}>
            {!item.deleted ? (
              <Button
                onClick={() => confirmAlert(remove, item.id)}
                theme="small"
                testSelector="database_vendor_delete_btn">
                <Icon icon="trash" />
              </Button>
            ) : (
              <Button
                colour="red"
                theme="small"
                testSelector="database_vendor_undelete_btn"
                onClick={() => {
                  confirmAlert(unDeleteVendor, item.id, {
                    title: 'Are you sure you want to UnDelete the Vendor?',
                    text: ' ',
                  });
                }}>
                Deleted
              </Button>
            )}
          </Authorize>
        </span>
      </Authorize>
    </div>
  );

  return (
    <div>
      {showUpdate && (
        <AddEditModal
          showUpdate={showUpdate}
          vendorTypes={vendorTypes}
          closeUpdate={() => turnShowUpdate(false)}
        />
      )}

      <div className="wrapper-content">
        <Row className="ibox">
          {isLoaded ? (
            <div className="ibox-content">
              <Authorize
                forbidden={['CARRIER']}
                abilityPermissions={[
                  { resource: 'Database', permissions: ['create'] },
                ]}>
                <Button
                  onClick={() => openUpdate('add')}
                  inverse
                  className="float-end"
                  testSelector="database_vendor_add_btn">
                  <Icon className="icon--margin-right" icon="plus-square" />
                  Add Vendor
                </Button>
              </Authorize>
              <Filter vendorTypes={vendorTypes} />
              {vendors.map(renderVendor)}
            </div>
          ) : (
            <Loader />
          )}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  vendors: filterVendors(state),
  isLoaded: state.database.vendors.isLoaded,
  vendorTypes: state.database.vendors.vendorTypes,
});

const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch(actions.getVendors());
    dispatch(actions.getVendorTypes());
  },
  remove: id => dispatch(actions.removeVendor(id)),
  unDeleteVendor: id => dispatch(actions.unDeleteVendor(id)),
  setModalAddValue: value => dispatch(actions.setModalAddValueVendor(value)),
  setModalEditValue: value => dispatch(actions.setModalEditValueVendor(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Vendors);
