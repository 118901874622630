import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import actions from 'store/actions/SandSiteSchedule';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import OrderInfo from './OrderInfo';

const OrderList = ({
  setCurrentOrder,
  clearCurrentOrder,
  orders,
  confirmAppointment,
  setSelectedOrder,
  currentOrder,
}) => {
  const [showOrderInfo, turnModal] = useState(false);

  return (
    <ul className="sortable-list connectList agile-list ui-sortable">
      {showOrderInfo && (
        <OrderInfo
          {...{
            showOrderInfo,
            order: currentOrder,
            confirmAppointment,
          }}
          closeOrderInfo={() => {
            clearCurrentOrder();
            turnModal(false);
          }}
        />
      )}
      {orders.map(o => {
        if ((o.status == 2 || o.status == 5) && o.confirmationStatus == 0) {
          return (
            <li
              key={'order' + o.id}
              className="warning-element"
              id={`order_${o.id}`}
              onClick={() => setSelectedOrder(o.id)}>
              <div className="agile-detail">
                <strong>{o.carrierName}</strong>
                <Button
                  theme="small"
                  onClick={() => {
                    setCurrentOrder(o.id);
                    turnModal(true);
                  }}
                  testSelector="sandsite-schedule_order-list_info_btn"
                  colour="white">
                  <Icon icon="info" />
                </Button>
              </div>
              <span>
                <strong>Truck#: </strong>
                {o.truck}
              </span>
              <span>
                <strong>Commodity: </strong>
                {o.sandType}
              </span>
              <div className="agile-detail">
                <Button
                  onClick={() => confirmAppointment(o)}
                  theme="small"
                  testSelector="sandsite-schedule_order-list_confirm-appointment_btn"
                  colour="white">
                  Confirm <Icon icon="clock-o" />
                </Button>
                {moment
                  .utc(o.appointmentTime)
                  .local()
                  .calendar()}
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
};

const mapDispatchToProps = dispatch => ({
  setSelectedOrder: orderId => {
    dispatch(actions.setSelectedOrder(orderId));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(OrderList);
