import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import Header from 'components/common/Header';
import Link from 'components/common/Link';

import actions from 'store/actions/JobPlans';
import districtActions from 'store/actions/Districts';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import ToolTip from 'components/shared/ToolTip';
import Authorize from 'components/common/Authorize';

import { matchingIntegerArray } from './helpers';
import Add from './AddJobPlan';
import InfoModal from './InfoModal';

const JobPlans = ({
  initJobPlans,
  jobPlansInfo,
  getJobs,
  clearJobsState,
  districts = [],
  getDistricts,
  hidden,
  setHidden,
  changeJobplanVisibility,
}) => {
  const [addIsVisible, setAddIsVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [stagesDayInfoVisible, setStagesDayInfoVisible] = useState(false);
  const [tonsStageInfoVisible, setTonsStageInfoVisible] = useState(false);
  const [tonsDayInfoVisible, setTonsDayInfoVisible] = useState(false);
  const { jobPlans } = jobPlansInfo;
  const [jobIdValue, setJobIdValue] = useState('');
  const [districtValue, setDistrictValue] = useState([]);
  const [jobPlansFiltered, setJobPlansFiltered] = useState([]);

  const districtsOptions = [...districts].reverse().map(d => ({
    value: d.id,
    label: d.name || 'N/A',
  }));

  useEffect(() => {
    initJobPlans();
    getJobs();
    getDistricts();
    return () => {
      clearJobsState();
    };
  }, []);

  const handleFilterChange = (
    jobPlans,
    jobFilter = '',
    districtFilter = [],
  ) => {
    let tmpPlans = _.cloneDeep(jobPlans);
    if (jobFilter) {
      const filterByJobId = tmpPlans.filter(p =>
        p.jobId.toString().includes(jobFilter.toString()),
      );
      tmpPlans = filterByJobId;
    }
    if (districtFilter.length) {
      const filteredByDistricts = tmpPlans.filter(p =>
        matchingIntegerArray(p.jobDistricts.map(d => d.id), districtFilter),
      );
      tmpPlans = filteredByDistricts;
    }
    tmpPlans.sort((a, b) => {
      if (a.jobPlanId < b.jobPlanId) {
        return 1;
      }
      if (a.jobPlanId > b.jobPlanId) {
        return -1;
      }
      return 0;
    });

    setJobPlansFiltered(tmpPlans);
  };

  useEffect(() => {
    handleFilterChange(jobPlans, jobIdValue, districtValue);
  }, [jobPlans, jobIdValue, districtValue]);

  if (!jobPlans) {
    return <Loader />;
  }

  const renderJobPlan = (data, index) => {
    if (hidden && data.status === 'closed') {
      return (
        <div
          className="form-group has-feedback col-md-6"
          key={`job-plan-${index}`}>
          <div className="ibox-content">
            <Link
              {...{
                id: data.jobPlanId,
                to: 'jobPlan-details',
              }}>
              <div className="flexBetween">
                <strong>Plan #{data.jobPlanId}</strong>
                {data.status === 'open' ? (
                  <ToolTip title={`Hide Job Plan # ${data.jobPlanId}`}>
                    <Button
                      key={`jobplans_visibility_${data.jobPlanId}_btn`}
                      onClick={e => {
                        e.preventDefault();
                        changeJobplanVisibility(data.jobPlanId, 'closed');
                      }}
                      theme="small"
                      colour="white"
                      testSelector={`jobplans_visibility_${
                        data.jobPlanId
                      }_btn`}>
                      <Icon icon="noPreview" />
                    </Button>
                  </ToolTip>
                ) : (
                  <ToolTip title={`Show Job Plan # ${data.jobPlanId}`}>
                    <Button
                      key={`jobplans_visibility_${data.jobPlanId}_btn`}
                      onClick={e => {
                        e.preventDefault();
                        changeJobplanVisibility(data.jobPlanId, 'open');
                      }}
                      theme="small"
                      colour="white"
                      testSelector={`jobplans_visibility_${
                        data.jobPlanId
                      }_btn`}>
                      <Icon icon="preview" />
                    </Button>
                  </ToolTip>
                )}
              </div>
              <div>
                <ToolTip title={`Link to job details Job #${data.jobId}.`}>
                  <Link
                    {...{
                      id: data.jobId,
                      to: 'job-details',
                    }}>
                    <h4>
                      Job #{data.jobId}:
                      <span style={{ color: '#18a689', margin: '0' }}>
                        {' '}
                        {data.jobName}
                      </span>
                    </h4>
                  </Link>
                </ToolTip>
                <span className="marginLeft5">
                  <Icon
                    icon="info-circle"
                    className="text-navy"
                    onClick={e => {
                      e.preventDefault();
                      setInfoVisible(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
                <div>
                  <ToolTip title="Shown Values Determined by Original Job Setup.">
                    <div className="flexBetween">
                      <div>
                        Stages/Day
                        <span className="marginLeft5">
                          <Icon
                            icon="info-circle"
                            className="text-navy"
                            onClick={e => {
                              e.preventDefault();
                              setStagesDayInfoVisible(true);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </span>
                      </div>
                      <div className="flexEvenly width50">
                        <strong className="text-success width100 textCenter">
                          {data?.stagesPerDay?.expected ||
                          data?.stagesPerDay?.expected == 0
                            ? data?.stagesPerDay?.expected
                            : 'N/A'}
                        </strong>
                        <strong className="text-navy width100 textCenter">
                          {data?.stagesPerDay?.actual ||
                          data?.stagesPerDay?.actual == 0
                            ? data?.stagesPerDay?.actual
                            : 'N/A'}
                        </strong>
                      </div>
                    </div>
                  </ToolTip>
                  <div className="flexBetween">
                    <div>
                      Tons/Stage
                      <span className="marginLeft5">
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            setTonsStageInfoVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                    <div className="flexEvenly width50">
                      <strong className="text-success width100 textCenter">
                        {Number.isInteger(data.tonsPerStage.expected)
                          ? data.tonsPerStage.expected
                          : data.tonsPerStage.expected.toFixed(5)}
                      </strong>
                      <strong className="text-navy width100 textCenter">
                        {Number.isInteger(data.tonsPerStage.actual)
                          ? data.tonsPerStage.actual
                          : data.tonsPerStage.actual.toFixed(5)}
                      </strong>
                    </div>
                  </div>
                  <div className="flexBetween">
                    <div>
                      Tons/Day
                      <span className="marginLeft5">
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            setTonsDayInfoVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                    <div className="flexEvenly width50">
                      <strong className="text-success width100 textCenter">
                        {Number.isInteger(data.tonsPerDay.expected)
                          ? data.tonsPerDay.expected
                          : data.tonsPerDay.expected.toFixed(5)}
                      </strong>
                      <strong className="text-navy width100 textCenter">
                        {Number.isInteger(data.tonsPerDay.actual)
                          ? data.tonsPerDay.actual
                          : data.tonsPerDay.actual.toFixed(5)}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }

    if (!hidden && data.status === 'open') {
      return (
        <div
          className="form-group has-feedback col-md-6"
          key={`job-plan-${index}`}>
          <div className="ibox-content">
            <Link
              {...{
                id: data.jobPlanId,
                to: 'jobPlan-details',
              }}>
              <div className="flexBetween">
                <strong>Plan #{data.jobPlanId}</strong>
                {data.status === 'open' ? (
                  <ToolTip title={`Hide Job Plan # ${data.jobPlanId}`}>
                    <Button
                      key={`jobplans_visibility_${data.jobPlanId}_btn`}
                      onClick={e => {
                        e.preventDefault();
                        changeJobplanVisibility(data.jobPlanId, 'closed');
                      }}
                      theme="small"
                      colour="white"
                      testSelector={`jobplans_visibility_${
                        data.jobPlanId
                      }_btn`}>
                      <Icon icon="noPreview" />
                    </Button>
                  </ToolTip>
                ) : (
                  <ToolTip title={`Show Job Plan # ${data.jobPlanId}`}>
                    <Button
                      key={`jobplans_visibility_${data.jobPlanId}_btn`}
                      onClick={e => {
                        e.preventDefault();
                        changeJobplanVisibility(data.jobPlanId, 'open');
                      }}
                      theme="small"
                      colour="white"
                      testSelector={`jobplans_visibility_${
                        data.jobPlanId
                      }_btn`}>
                      <Icon icon="preview" />
                    </Button>
                  </ToolTip>
                )}
              </div>
              <div>
                <ToolTip title={`Link to job details Job #${data.jobId}.`}>
                  <Link
                    {...{
                      id: data.jobId,
                      to: 'job-details',
                    }}>
                    <h4>
                      Job #{data.jobId}:
                      <span style={{ color: '#18a689', margin: '0' }}>
                        {' '}
                        {data.jobName}
                      </span>
                    </h4>
                  </Link>
                </ToolTip>
                <span className="marginLeft5">
                  <Icon
                    icon="info-circle"
                    className="text-navy"
                    onClick={e => {
                      e.preventDefault();
                      setInfoVisible(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
                <div>
                  <ToolTip title="Shown Values Determined by Original Job Setup.">
                    <div className="flexBetween">
                      <div>
                        Stages/Day
                        <span className="marginLeft5">
                          <Icon
                            icon="info-circle"
                            className="text-navy"
                            onClick={e => {
                              e.preventDefault();
                              setStagesDayInfoVisible(true);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </span>
                      </div>
                      <div className="flexEvenly width50">
                        <strong className="text-success width100 textCenter">
                          {data?.stagesPerDay?.expected ||
                          data?.stagesPerDay?.expected == 0
                            ? data?.stagesPerDay?.expected
                            : 'N/A'}
                        </strong>
                        <strong className="text-navy width100 textCenter">
                          {data?.stagesPerDay?.actual ||
                          data?.stagesPerDay?.actual == 0
                            ? data?.stagesPerDay?.actual
                            : 'N/A'}
                        </strong>
                      </div>
                    </div>
                  </ToolTip>
                  <div className="flexBetween">
                    <div>
                      Tons/Stage
                      <span className="marginLeft5">
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            setTonsStageInfoVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                    <div className="flexEvenly width50">
                      <strong className="text-success width100 textCenter">
                        {Number.isInteger(data.tonsPerStage.expected)
                          ? data.tonsPerStage.expected
                          : data.tonsPerStage.expected.toFixed(5)}
                      </strong>
                      <strong className="text-navy width100 textCenter">
                        {Number.isInteger(data.tonsPerStage.actual)
                          ? data.tonsPerStage.actual
                          : data.tonsPerStage.actual.toFixed(5)}
                      </strong>
                    </div>
                  </div>
                  <div className="flexBetween">
                    <div>
                      Tons/Day
                      <span className="marginLeft5">
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            setTonsDayInfoVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                    <div className="flexEvenly width50">
                      <strong className="text-success width100 textCenter">
                        {Number.isInteger(data.tonsPerDay.expected)
                          ? data.tonsPerDay.expected
                          : data.tonsPerDay.expected.toFixed(5)}
                      </strong>
                      <strong className="text-navy width100 textCenter">
                        {Number.isInteger(data.tonsPerDay.actual)
                          ? data.tonsPerDay.actual
                          : data.tonsPerDay.actual.toFixed(5)}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }

  };

  return (
    <>
      <Header title="Job Plans">
        <Authorize
          abilityPermissions={[
            { resource: 'Job-plans', permissions: ['create'] },
          ]}>
          <Button
            onClick={() => setAddIsVisible(true)}
            inverse
            testSelector="jobPlans_add_btn"
            className="floatRight">
            <Icon icon="plus-square" className="icon--margin-right" />
            Add Job Plan
          </Button>
        </Authorize>
      </Header>

      <Row style={{ marginLeft: -8 }}>
        <Col md={12} as="h3">
          Sort by:
        </Col>
        <Col md={12} style={{ marginTop: '13px' }}>
          <Input
            onChange={() => setHidden(!hidden)}
            type="checkbox"
            label="Show Hidden"
            isChecked={hidden}
          />
        </Col>

        <Col md={6} className="form-group has-feedback">
          <Select
            options={districtsOptions}
            placeholder="District"
            testSelector="jobPlans_filters_district_select"
            isMulti
            closeMenuOnSelect={false}
            onChange={item => {
              const values = item.map(i => i.value);
              setDistrictValue(values);
            }}
            defaultValue={districtsOptions
              .filter(item => districtValue.includes(item.id))
              .map(d => ({
                label: d.name,
                value: d.id,
              }))}
          />
        </Col>
        <Col md={6} className="form-group has-feedback">
          <Input
            type="number"
            onChange={e => {
              setJobIdValue(e.target.value);
            }}
            step={1}
            min={0}
            value={jobIdValue}
            label="Job ID"
            testSelector="jobPlans_filters_id_input"
          />
        </Col>
      </Row>
      <Row>{jobPlansFiltered.map(renderJobPlan)}</Row>

      <Add
        isVisible={addIsVisible}
        closeAdd={() => {
          setAddIsVisible(false);
        }}
      />
      <InfoModal
        isVisible={infoVisible}
        setIsVisible={setInfoVisible}
        info="Shown Values Determined by Original Job Setup"
      />
      <InfoModal
        isVisible={stagesDayInfoVisible}
        setIsVisible={setStagesDayInfoVisible}
        info={`Expected: Number of stages per day inputted upon job creation\n\nActual: Number of stages completed today`}
      />
      <InfoModal
        isVisible={tonsStageInfoVisible}
        setIsVisible={setTonsStageInfoVisible}
        info={`Expected: Number of tons inputted upon job creation divided by total number of stages inputted upon job creation\n\nActual: Total driver weight entered today divided by number of stages run today`}
      />
      <InfoModal
        isVisible={tonsDayInfoVisible}
        setIsVisible={setTonsDayInfoVisible}
        info={`Expected: Target stages per day inputted upon job creation then multiplied by the expected tons per stage (above)\n\nActual: Total driver weight entered today`}
      />
    </>
  );
};

const mapStateToProps = state => ({
  jobPlansInfo: state.jobPlans.jobPlanInfo,
  districts: state.districts.info.districtsList,
  hidden: state.jobPlans.jobPlanInfo.hidden,
});

const mapDispatchToProps = dispatch => ({
  initJobPlans: () => dispatch(actions.initJobPlans()),
  getJobs: () => dispatch(resourceActions.getJobs()),
  clearJobsState: () => dispatch({ type: 'JOB_LIST_CLEAR_STATE' }),
  getDistricts: () => dispatch(districtActions.init()),
  setHidden: isHidden => dispatch(actions.setHidden(isHidden)),
  changeJobplanVisibility: (jobPlanId, vis) =>
    dispatch(actions.changeJobplanVisibility(jobPlanId, vis)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobPlans);
