import React from 'react';

const Question = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="icon--fill">
    <path d="M10.37 14.91a7 7 0 0 1 .35-2.48 3.9 3.9 0 0 1 1.15-1.57 7.22 7.22 0 0 0 1.21-1.22 2 2 0 0 0 .41-1.22c0-1.08-.46-1.62-1.39-1.62a1.3 1.3 0 0 0-1 .47 2.06 2.06 0 0 0-.42 1.28H7A4.34 4.34 0 0 1 8.35 5.2 5.41 5.41 0 0 1 12.1 4a5.61 5.61 0 0 1 3.71 1.11 3.89 3.89 0 0 1 1.32 3.15 3.65 3.65 0 0 1-.36 1.62 5.23 5.23 0 0 1-1.14 1.5l-1.23 1.15a2.8 2.8 0 0 0-.73 1 4 4 0 0 0-.23 1.35zm-.44 3.27a1.66 1.66 0 0 1 .57-1.3 2.07 2.07 0 0 1 1.43-.51 2.08 2.08 0 0 1 1.44.51 1.67 1.67 0 0 1 .58 1.3 1.69 1.69 0 0 1-.58 1.31 2.08 2.08 0 0 1-1.44.51 2.07 2.07 0 0 1-1.43-.51 1.68 1.68 0 0 1-.57-1.31z" />
  </svg>
);

export default Question;
