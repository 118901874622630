export const sortPOs = purchaseOrders =>
  purchaseOrders.sort((a, b) => {
    if (a.priority > b.priority) {
      return 1;
    }
    if (a.priority === b.priority) {
      if (a.poNo > b.poNo) {
        return 1;
      }
      return -1;
    }
    return -1;
  });

export const logSortPOs = logisticPo =>
  logisticPo.sort((a, b) => {
    if (a.priority > b.priority) {
      return 1;
    }
    if (a.priority === b.priority) {
      if (a.poNo > b.poNo) {
        return 1;
      }
      return -1;
    }
    return -1;
  });

export const filterAvailableCommodities = (jobDesigns, commodities) =>
  commodities.filter(c => jobDesigns.some(jd => jd.sandTypeId === c.id));

export const determineUnit = (unit) => {
  switch (unit) {
    case 1:
      return 'lbs.';
    case 2:
      return 'gal.';
    case 3:
      return 'bbl.';
    default:
      return 'lbs.';
  }
}

export const sortPOsByType = purchaseOrders =>
  purchaseOrders.sort((a, b) => {
    if (a.sandTypeId > b.sandTypeId) {
      return 1;
    }
    if (a.sandTypeId === b.sandTypeId) {
      if (a.priority > b.priority) {
        return 1;
      }
      return -1;
    }
    return -1;
  });

const limitTypes = {
  '24hlimit': 1,
  MidnightToMidnight: 2,
};

export const getLimitBadge = type => {
  switch (Number(type)) {
    case limitTypes['24hlimit']:
      return '24h limit';
    case limitTypes.MidnightToMidnight:
      return 'midnight-midnight limit ';
    default:
      return '';
  }
};
