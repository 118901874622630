export default (state = { isLoaded: false }, action) => {
  switch (action.type) {
    case 'JOB_STATS_SAVE_INIT_VALUE':
      return {
        ...state,
        ...action.data,
        isLoaded: true,
      };
    default:
      return state;
  }
};
