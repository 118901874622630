const init = {
  loading: true,
  comments: [],
  comment: '',
};

export default (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_GET_EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        comments: action.date,
      };
    case 'JOB_DETAILS_EVENTS_CLEAR_INPUT':
      return {
        ...state,
        comment: '',
      };
    case 'JOB_DETAILS_EVENTS_SET_INPUT':
      return {
        ...state,
        comment: action.data,
      };
    case 'JOB_DETAILS_GET_EVENTS_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'JOB_DETAILS_EVENTS_CLEAR_STATE':
      return init;
    default:
      return state;
  }
};
