import swal from 'bootstrap-sweetalert';

export default (text, confirmFN, cancelFN) => {
  swal(
    {
      title: '',
      text,
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: "Yes, I'm sure!",
      cancelButtonText: "No, I'm not",
      closeOnConfirm: true,
      closeOnCancel: true,
      showLoaderOnConfirm: true,
    },
    isConfirm => {
      if (isConfirm) {
        confirmFN();
      } else {
        cancelFN();
      }
    },
  );
};
