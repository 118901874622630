import React from 'react';

const LocationPending = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g>
      <g stroke="null" id="svg_1" fill="none">
        <path
          stroke="null"
          d="m10.775019,14.516967c0.227026,0.656754 0.657371,0.679925 0.96127,0.053605l3.452093,-7.122269c0.303899,-0.627357 0.022272,-0.897806 -0.628633,-0.605223l-7.397754,3.323541c-0.650905,0.292236 -0.626119,0.706901 0.05532,0.925819l2.668278,0.855959l0.889426,2.568568z"
          fill="#FFA106"
        />
        <circle
          stroke="#FFA106"
          id="svg_3"
          r="10.375258"
          cy="10.875251"
          cx="11.000249"
        />
      </g>
    </g>
  </svg>
);

export default LocationPending;