import axios from 'axios';

export const document = {
  getSignedUrl() {
    return axios.get('v2/documents/url', {});
  },
  finishUpload(id) {
    return axios.post(`v2/documents/${id}/verify`, {});
  },
  getDocument(id) {
    return axios.get(`v2/documents/${id}`, {});
  },
};
