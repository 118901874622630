import axios from 'axios';

export default {
  types: {
    get() {
      return axios.get('v2/sand-ticket-types', {});
    },
  },
  post(data) {
    return axios({
      method: 'post',
      url: `v2/order/${data.get("orderId")}/sand-ticket`,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
  },
};
