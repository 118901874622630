export const initMarks = settings => {
  return {
    queueTimingMark: settings.filter(item => item.type == 7)[0].value,
    queueDistanceMark: settings.filter(item => item.type == 8)[0].value,
  };
};

export const onRangeChanged = queueTimingMark => {
  return {
    queueTimingMark: queueTimingMark / 100,
    queueDistanceMark: Math.round((1 - queueTimingMark / 100) * 100) / 100,
  };
};
