import { slsClient } from 'api/client';
import authService from 'services/auth/service';

export const getStorageDetails = async ({ jobId }) => {
  const { data } = await slsClient.get(`/onsite-storage/${jobId}`);
  return data;
};

export const getHistoricalLevels = async ({
  jobId,
  // intervalHours,
  storageId,
}) => {
  const { data } = await slsClient.get(
    `/onsite-storage/${jobId}/historical-levels`,
    {
      params: {
        // intervalHours,
        storageId,
        limit: 20,
        isDeleted: true,
      },
    },
  );
  return data;
};

export const getHistoricalLevelsTimeline = async ({
  jobId,
  intervalHours,
  storageId,
}) => {
  const { data } = await slsClient.get(
    `/onsite-storage/${jobId}/historical-levels-timeline`,
    {
      params: {
        intervalHours,
        storageId,
      },
    },
  );
  return data;
};

export const deleteHistoricalRecord = async recordId => {
  const user = authService.getUser();

  const { data } = await slsClient.delete(
    `/onsite-storage/historical-levels/${recordId}`,
    {
      params: {
        deletedBy: user.name,
      },
    },
  );
  return data;
};

export const addStorageRecord = async storageData => {
  const user = authService.getUser();

  const { data } = await slsClient.post('/onsite-storage', {
    ...storageData,
    createdBy: user.name,
  });
  return data;
};

/**
 *
 * @param {{
 *  jobId: number;
 *  file: File
 * }} params
 */
export const importStorageFile = async ({ jobId, file }) => {
  const { data } = await slsClient.post(`/storage/${jobId}/csv`, file, {
    params: { tz: Intl.DateTimeFormat().resolvedOptions().timeZone },
    headers: { 'Content-Type': file.type },
  });
  return data;
};
