import moment from 'moment';
import { createSelector } from 'reselect';

const getData = (data, props) => data.collection;

const getFilters = (state, props) => props.filters;

export default createSelector(
  [getData, getFilters],
  (data, filters) => {
    return data.filter(row => {
      return filters.every(({ key, value, type, onFilter = null }) => {
        if (onFilter) {
          return onFilter(row, key, value);
        }

        if (type === 'multipleEvery') {
          return value.every(multiVal => row[key] === multiVal);
        }

        if (type === 'multipleSome') {
          return value.some(multiVal => row[key] === multiVal);
        }

        if (type === 'arrayEvery') {
          return value.every(keyItems => row[key].includes(keyItems));
        }

        if (type === 'arraySome') {
          return value.some(keyItems => row[key].includes(keyItems));
        }

        if (type === 'range') {
          const [range, rangeKey] = key.split('-');
          const momentValueRange = moment(row[rangeKey]);

          if (range === 'from') {
            return momentValueRange.isSameOrAfter(value);
          }

          return momentValueRange.isSameOrBefore(value);
        }

        if (type === 'date') {
          const momentValueDate = moment(row[key]);

          return momentValueDate.isSame(value, 'day');
        }

        return row[key] === value;
      });
    });
  },
);
