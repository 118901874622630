import { createSelector } from 'reselect';

const getData = data => data;
const getFilters = (state, filters) => filters;

export default createSelector(
  [getData, getFilters],
  (
    jobs,
    {
      jobId,
      locationId,
      districtId,
      driverId,
      carrierId,
      jobType,
      status,
      sandSites,
      wellSites,
      drivers,
      waterProduction,
      waterDisposal,
    },
  ) => {
    
    return jobs.filter(job => {
      if (jobId && jobId !== job.jobId) {
        return false;
      }

      if (locationId && locationId !== job.id) {
        return false;
      }

      if (
        districtId &&
        !job.jobDistricts.find(
          district => Number(district.id) === Number(districtId),
        )
      ) {
        return false;
      }

      if (driverId) {
        if (job.type === 'driver') {
          if (Number(job.id) !== driverId) {
            return false;
          }
        } else if (!job.driver || job.driver.id !== driverId) {
          return false;
        }
      }

      if (carrierId && job.carrierId !== carrierId) {
        return false;
      }

      if (jobType && job.operationType !== jobType) {
        return false;
      }

      if (!sandSites && job.type === 'sand_site') {
        return false;
      }

      if (!wellSites && job.type === 'well_site') {
        return false;
      }

      if (!drivers && job.type === 'driver') {
        return false;
      }
      if(drivers && status && job.status !== status){
          return false;
      }

      if (!waterProduction && job.type === 'water_production') {
        return false;
      }

      if (!waterDisposal && job.type === 'water_disposal') {
        return false;
      }

      return true;
    });
  },
);
