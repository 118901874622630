const init = {
  input: {
    name: '',
    carrierPercentage: '',
    demurrageTableId: '',
    price: '',
    type: '',
    carrierId: '',
  },
  demurrageList: [],
};

export default (state = { ...init }, action) => {
  switch (action.type) {
    case 'ADD_PRICE_SET_INIT_DATA':
      return {
        ...state,
        input: {
          ...state.input,
          carrierId: action.carrierId,
        },
      };
    case 'ADD_PRICE_SAVE_DEMMURAGE_LIST':
      return {
        ...state,
        demurrageList: action.demmurage,
      };
    case 'ADD_PRICER_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'ADD_PRICE_CLEAR':
      return init;
    default:
      return state;
  }
};
