import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Job from './Job';
import Alerts from './Alerts';
import JobDesign from './JobDesign';
import JobDetails from './JobDetails';
import OnsiteStorage from './OnsiteStorage';
import Header from './Header';
import JobOperationType from './JobOperationType';
// import WaterJob from './WaterJob';
import { useAddJobForm } from './hooks';

const CurrentStep = ({ formState, formHandlers, selectedCustomer, setSelectedCustomer, userCustomer, closeAddJobModal }) => {
  // This will have to be refactored if we want the WaterJob
  // if (Number(jobOperationType) === 12) {
  //   return (
  //     <WaterJob props={props} userCustomer={userCustomer} key="waterjob" />
  //   );
  // }

  switch (formState.step) {
    case 1:
      return <Job
        closeAddJobModal={closeAddJobModal}
        formHandlers={formHandlers}
        addJob={formState}
        addJobInitData={formState.addJobInitData}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        userCustomer={userCustomer}
        key="job"
      />;
    case 2:
      return <JobDetails
        closeAddJobModal={closeAddJobModal}
        addJob={formState}
        addJobInitData={formState.addJobInitData}
        formHandlers={formHandlers}
        key="job-details"
      />;
    case 3:
      return <Alerts
        closeAddJobModal={closeAddJobModal}
        addJob={formState}
        formHandlers={formHandlers}
        key="alerts"
      />;
    case 4:
      return <JobDesign
        closeAddJobModal={closeAddJobModal}
        addJob={formState}
        formHandlers={formHandlers}
        key="job-design"
      />;
    case 5:
      return <OnsiteStorage
        closeAddJobModal={closeAddJobModal}
        addJob={formState}
        formHandlers={formHandlers}
        key="storage"
      />;
    default:
      return null;
  }
};

const AddJob = ({ showAddJob, closeAddJobModal, customerIdFromCustomer }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(customerIdFromCustomer ?? null);
  const { state: addJobFormState, handlers } = useAddJobForm(selectedCustomer, setSelectedCustomer);

  const { setValue, clearState, setStep } = handlers;
  const { jobOperationType, step } = addJobFormState;

  return (
    <Modal
      className="modal-container"
      size="xl"
      dialogClassName="modal-w40"
      show={showAddJob}
      onHide={() => {
        closeAddJobModal();
        setSelectedCustomer(null);
        clearState();
      }}>
      <Modal.Header style={{ display: 'block' }}>
        <Row>
          <Modal.Title as="h3">Add Job</Modal.Title>
          <Header
            step={step}
            setStep={setStep}
            jobOperationType={jobOperationType}
          />
        </Row>
      </Modal.Header>
      {jobOperationType !== undefined ? (
        <CurrentStep
          step={step}
          closeAddJobModal={() => {
            closeAddJobModal();
            clearState();
          }}
          formState={addJobFormState}
          formHandlers={handlers}
          userCustomer={customerIdFromCustomer}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
      ) : (
        <JobOperationType
          closeAddJobModal={closeAddJobModal}
          setModalValue={setValue}
        />
      )}
    </Modal>
  );
};

export default AddJob;
