import React, { useMemo, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Link from 'components/common/Link';
import { deleteAlert } from 'components/sweetalert';
import Badge from 'components/shared/Badge';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';

import {
  useCarrierSyncBc,
  useCarriers,
  useDeleteCarrier,
} from 'api/v2/carriers';

import DocumentsModal from './DocumentsModal';

const CarriersList = () => {
  const [carrierName, setCarrierName] = useState('');
  const [carrierId, setCarrierId] = useState(null);
  const [showModalDocs, setShowModalDocs] = useState(false);

  const { data: carriers, isLoading } = useCarriers();
  const { mutate: deleteCarrier } = useDeleteCarrier();
  const { mutate: syncBc } = useCarrierSyncBc();

  const openDocs = id => {
    setShowModalDocs(true);
    setCarrierId(id);
  };

  const closeDocs = () => {
    setShowModalDocs(false);
    setCarrierId(null);
  };

  const filteredCarriers = useMemo(
    () =>
      Object.values(carriers || []).filter(item =>
        item.name
          .toLowerCase()
          .includes((carrierName && carrierName.toLowerCase()) || ''),
      ),
    [carriers, carrierName],
  );

  const renderCarrierItem = item => (
    <tr key={item.id}>
      <td>
        <Row>
          <Col lg={3} md={3} sm={3} xs={3}>
            <Image
              src={item.profilePhotoUrl}
              width="60"
              height="60"
              alt="logo"
              rounded
            />
          </Col>
          <Col lg={9} md={3} sm={3} xs={3}>
            <span>
              #{item.id}:
              <Link id={item.id} to="carrier-details">
                {` ${item.name}`}
              </Link>
            </span>
            {Boolean(item.enableRemit) === true && (
              <strong
                style={{
                  color: 'grey',
                }}>
                {` Remit`}
              </strong>
            )}
            {!!item.interstateCertified && (
              <Badge type="purple">Interstate Certified</Badge>
            )}
            <Authorize access={['JOB_MANAGER']}>
              {!!item.onHold && <Badge type="red">On-Hold</Badge>}
              {!item.isActive && <Badge type="red">Is Inactive</Badge>}
            </Authorize>
            <br />
            <span>
              <Icon icon="envelope" />
              Email: {item.email}
            </span>
            <br />
            <span>
              <Icon icon="phone" />
              Phone: {item.contactNo}
            </span>
            <br />
          </Col>
        </Row>
      </td>
      <td className="project-title">
        {item.address1 && item.address1.length > 0 ? (
          <span>
            <Icon icon="map-marker" />
            Address: {item.address1}
          </span>
        ) : null}
        <br />
        {item.city && item.city.length > 0 ? (
          <span>
            <Icon icon="map-pin" />
            City: {item.city}
          </span>
        ) : null}
        <br />
        <div
          style={{
            maxWidth: 300,
          }}>
          <span>
            <Icon icon="exchange" />
            Districts:{' '}
            {item.districts.map((i, index) => (
              <span key={`${index}s`}>
                {i.name}
                <span>, </span>
              </span>
            ))}
          </span>
        </div>
      </td>
      <td className="project-completion line-height-zero text-center">
        <Row>
          <Col className="cluster-info-sub">
            <small>Fleet size</small>
            <h4>{item.fleetSize}</h4>
            <small>US DOT Number</small>
            <h4>{item.usDotNo}</h4>
          </Col>
          <Col className="cluster-info-sub">
            <small>MC Number</small>
            <h4>{item.mcNo}</h4>
            <small>EIN/SS Number</small>
            <h4>{item.einNo}</h4>
          </Col>
        </Row>
      </td>
      <td className="project-actions">
        <Authorize
          abilityPermissions={[
            { resource: 'Carrier-documents', permissions: ['update'] },
          ]}>
          {item.bcSync ? (
            <Button
              onClick={() => {
                syncBc({ carrierId: item.id, sync: false });
              }}
              theme="small"
              colour="green"
              testSelector="admin-carriers_list_bc_synced_btn">
              BC SYNCED
            </Button>
          ) : (
            <Button
              onClick={() => {
                syncBc({ carrierId: item.id, sync: true });
              }}
              theme="small"
              colour="purple"
              testSelector="admin-carriers_list_sync_bc_btn">
              SYNC BC
            </Button>
          )}
        </Authorize>
        <Authorize
          abilityPermissions={[
            { resource: 'Carrier-documents', permissions: ['read'] },
          ]}>
          <Button
            onClick={() => openDocs(item.id)}
            theme="small"
            colour="white"
            testSelector="admin-carriers_list_docs_btn">
            <Icon className="icon--margin-right" icon="folder-o" />
            Documents
          </Button>
        </Authorize>
        <Authorize
          abilityPermissions={[
            { resource: 'Carriers', permissions: ['delete'] },
          ]}>
          <Button
            onClick={() => deleteAlert(deleteCarrier, item.id)}
            testSelector="admin-carriers_list_delete_btn"
            theme="small"
            colour="white">
            <Icon icon="trash-o" />
          </Button>
        </Authorize>
      </td>
    </tr>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Row className="form-group has-feedback">
        <Col lg={3} md={3} sm={4} xs={12}>
          <Input
            onChange={e => setCarrierName(e.target.value)}
            label="Name"
            testSelector="admin-carriers_carriers-list_name_input"
          />
        </Col>
      </Row>

      <Row>
        <div className="ibox float-e-margins">
          <div className="ibox-content">
            <div className="project-list">
              <table className="table table--hover">
                <tbody>{filteredCarriers.map(renderCarrierItem)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </Row>

      <DocumentsModal
        carrierId={carrierId}
        showModalDocs={showModalDocs}
        closeDocs={closeDocs}
      />
    </div>
  );
};
export default CarriersList;
