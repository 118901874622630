import React from 'react';

const AnimatedInput = WrappedComponent => {
  // strip transition props off so they don't get passed into the Input component
  // props are inherited from Input
  return ({ in: inProp, onExited, appear, enter, exit, ...props }) => {
    return (
    <WrappedComponent {...props} />
  )};
};

export default AnimatedInput;
