import { combineReducers } from '@reduxjs/toolkit';

const creditMemoDefaultState = {
  creditMemos: [],
  totalItems: 0,
  isLoading: false,
}

const creditMemos = (state = creditMemoDefaultState, data) => {
  switch (data.type) {
    case 'CREDIT_MEMOS_REQUESTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'CREDIT_MEMOS_SUCCEEDED':
      return {
        ...state,
        creditMemos: data.creditMemos,
        totalItems: data.totalItems,
        isLoading: false
      }
    case 'CREDIT_MEMOS_FAILED':
      return {
        ...state,
        isLoading: false
      }
    case 'CLEAR_CREDIT_MEMOS':
      return creditMemoDefaultState;
    default:
      return state;
  }
}

const creditMemoDetailsDefaultState = {
  customerCreditMemo: null,
  carrierCreditMemos: [],
  isLoading: false,
};

const creditMemoDetails = (state = creditMemoDetailsDefaultState, data) => {
  switch (data.type) {
    case 'CREDIT_MEMO_DETAILS_REQUESTED':
      return {
        ...state,
        isLoading: true
      }
    case 'CREDIT_MEMO_DETAILS_SUCCEEDED':
      return {
        ...state,
        customerCreditMemo: data.customerCreditMemo,
        carrierCreditMemos: data.carrierCreditMemos,
        isLoading: false,
      }
    case 'CREDIT_MEMO_DETAILS_FAILE':
      return {
        ...state,
        isLoading: false
      }
    case 'CLEAR_CREDIT_MEMO_DETAILS':
      return creditMemoDetailsDefaultState;
    default:
      return state;
  }
}

const creditMemoPreviewDefaultState = {
  isLoading: false,
}

const creditMemoPreview = (state = creditMemoPreviewDefaultState, data) => {
  switch (data.type) {
    case 'CREDIT_MEMO_PREVIEW_REQUESTED':
      return {
        ...state,
        isLoading: true,
      }
    case 'CREDIT_MEMO_PREVIEW_SUCCEEDED':
      return {
        ...state,
        isLoading: false,
      }
    case 'CREDIT_MEMO_PREVIEW_FAILED':
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state;
  }
}

export default combineReducers({
  creditMemos,
  creditMemoDetails,
  creditMemoPreview,
})