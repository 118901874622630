import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

const PaginationLast = ({ disabled, onClick }) => (
  <Button
    theme="square"
    colour='grey'
    onClick={onClick}
    disabled={disabled}
  >
    <Icon icon="double_right" />
  </Button>
);

PaginationLast.defaultProps = {
  onBack: () => null
}

export default PaginationLast;
