import { combineReducers } from '@reduxjs/toolkit';

const initialStateSandSiteSchedule = {
  isLoaded: false,
  schedule: {
    orders: [],
    lanes: [],
    slots: [],
    currentOrder: {},
    moveLane: '',
    externalOrders: [],
    settings: {}
  },
  lanesModal: {
    name: '',
    truckCapacityPerHour: '',
    notes: '',
    sandTypeId: '',
  },
};

const schedule = (state = initialStateSandSiteSchedule, action) => {
  switch (action.type) {
    case 'GET_SAND_SITE_SCHEDULE_REQUEST':
      return state;
    case 'GET_SAND_SITE_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoaded: true,
        schedule: {
          ...state.schedule,
          ...action.schedule,
        },
        sandSiteId: action.schedule.sandSiteId,
      };
    case 'GET_SAND_SITE_SCHEDULE_FAILURE':
      return state;
    case 'SET_LANES_MODAL_DATA':
      return {
        ...state,
        lanesModal: {
          ...state.lanesModal,
          ...action.data,
        },
      };
    case 'SET_LANES_MODAL_VALUE':
      const { value, name } = action;
      return {
        ...state,
        lanesModal: {
          ...state.lanesModal,
          [name]: value,
        },
      };
    case 'SUBMIT_LANES_MODAL_SUCCESS':
      return {
        ...state,
        lanesModal: {},
      };
    case 'CLEAR_LANES_MODAL_VALUE':
      return {
        ...state,
        lanesModal: {
          name: '',
          truckCapacityPerHour: '',
          notes: '',
          sandTypeId: '',
        },
      };
    case 'SAND_SITE_SCHEDULE_CONFIRM_ORDER':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          orders: action.orders,
        },
      };
    case 'SET_NEW_ORDER_SCHEDULE_TIME':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          orders: action.orders,
        },
      };
    case 'ADD_SCHEDULE_SLOT':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          slots: action.slots,
        },
      };
    case 'REMOVE_SLOT':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          slots: action.data,
        },
      };
    case 'SET_CURRENT_ORDER_INFO':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          currentOrder: action.data,
        },
      };
    case 'SET_MOVE_LANE':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          moveLane: action.laneId,
        },
      };
    case 'CLEAR_CURRENT_ORDER_INFO':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          currentOrder: {},
        },
      };
    case 'SCHEDULE_SET_SETTINGS':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          settings: {
            ...state.schedule.settings,
            [action.name]: action.value,
          },
        },
      };
    case 'SET_SELECTED_ORDER':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          selectedOrder: action.orderId,
        },
      };
    default:
      return state;
  }
};

const initialStateSlotLine = {
  start: '',
  end: '',
  description: '',
  fillAll: false,
};

const slotLine = (state = initialStateSlotLine, action) => {
  switch (action.type) {
    case 'SET_SLOT_WORK_TIME':
      return {
        ...state,
        start: action.start,
        end: action.end,
        lineId: action.lineId,
      };
    case 'SET_SLOT_MODAL_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'SET_SLOT_START_TIME':
      return {
        ...state,
        start: action.start,
      };
    case 'SET_SLOT_END_TIME':
      return {
        ...state,
        end: action.end,
      };
    case 'CLEAR_SLOT_STORE':
      return initialStateSlotLine;
    default:
      return state;
  }
};

const initChat = {
  chatId: '',
  driverId: '',
  key: '',
  messages: [],
  messagesReady: false,
  text: '',
  isFile: false,
  file: null,
  fileName: '',
};
const chat = (state = initChat, action) => {
  switch (action.type) {
    case 'SCHEDULE_INIT_DRIVER_CHAT':
      return {
        ...state,
        chatId: action.chatId,
        driverId: action.driverId,
      };
    case 'SCHEDULE_SAVE_LAST_KEY':
      return {
        ...state,
        key: action.key,
      };
    case 'SCHEDULE_SAVE_MESSAGGES':
      return {
        ...state,
        messages: action.messages,
        messagesReady: true,
      };
    case 'SCHEDULE_SEND_MESSAGGES':
      return {
        ...state,
        text: '',
        isFile: false,
        fileName: '',
        file: null,
      };
    case 'SCHEDULE_SET_CHAT_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'SCHEDULE_SET_FILE':
      return {
        ...state,
        file: action.file,
        fileName: action.file.name,
        isFile: true,
      };
    case 'SCHEDULE_UNSET_FILE':
      return {
        ...state,
        isFile: false,
        fileName: '',
        file: null,
      };
    default:
      return state;
  }
};

export const sandSiteSchedule = combineReducers({
  schedule,
  slotLine,
  chat,
});
