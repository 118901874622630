import React, { useState, useReducer } from 'react';
import _isEqual from 'lodash/isEqual';
import Authorize from 'components/common/Authorize';
import Block from 'components/shared/Block';
import Page from 'components/shared/Page';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import RolesList from 'components/views/Roles/RolesList';
import AddEditRole from 'components/views/Roles/AddEditRole';
import {
  getSelectedGrants,
  updateRolesReducer,
  UPDATE_ROLE_ACTIONS,
} from 'components/views/Roles/RolesPage/helpers';

// Builds a role object with the changes made when changing checkboxes
const buildUpdatedRole = (selectedCheckboxes, role, permData, grants) => {
  // Find if rolePermissions array already has permission
  const findPermission = role.permissions
    ? role.permissions.find(fData => fData.pid === permData._id)
    : undefined;

  // If all are selected set to [5], else map through for values, [] = none selected
  const selectedGrants = getSelectedGrants(selectedCheckboxes, grants);

  if (findPermission) {
    // If role has permission modify / remove it
    const updatedPermissions = role.permissions.reduce(
      (accumulator, currentValue) => {
        // Modify grants
        if (currentValue.pid === permData._id) {
          // Remove permission
          if (selectedGrants.length === 0) {
            return accumulator;
          }

          accumulator.push({
            ...currentValue,
            grants: selectedGrants,
          });

          return accumulator;
        }

        // Don't modify, push
        accumulator.push(currentValue);
        return accumulator;
      },
      [],
    );

    const updatedSingleRole = {
      ...role,
      permissions: updatedPermissions,
    };
    return updatedSingleRole;
  }

  // This covers if a role does not have any permissions
  const rolePermissions = role.permissions || [];

  // Add permission to role
  const updatedSingleRole = {
    ...role,
    permissions: [
      ...rolePermissions,
      {
        pid: permData._id,
        grants: selectedGrants,
      },
    ],
  };

  return updatedSingleRole;
};

const Roles = props => {
  const {
    permissionData,
    loading,
    loadPermissionData,
    changeCompanyTypeId,
  } = props;
  const [panelOpen, setPanelOpen] = useState(false);
  // Set edit role and pass it to Add role to edit a role
  const [editRole, setEditRole] = useState(undefined);
  const [updatedRoles, setUpdatedRoles] = useReducer(updateRolesReducer, []);

  // TODO: does not work with the new api update
  // If a role has been modified from its original data push it into updatedRoles
  const permissionUpdate = (selectedCheckboxes, roleData, permData) => {
    // Find if role is already modified and in updatedRoles
    const findUpdatedRole = updatedRoles.find(
      fData => fData.rolePermId === roleData._id,
    );

    // Role has not been modified, build it and push it into state
    const rolePermissions = roleData.permissions
      ? roleData.permissions.map(mData => ({
          pid: mData.pid._id,
          grants: mData.grants.map(grantData => grantData._id),
        }))
      : [];

    const reducedRole = {
      rolePermId: roleData._id,
      rtpId: roleData.role.rid,
      role: roleData.role._id,
      permissions: rolePermissions,
    };

    if (findUpdatedRole) {
      // Role has been modified and is in updatedRoles
      const tempUpdatedRole = buildUpdatedRole(
        selectedCheckboxes,
        findUpdatedRole,
        permData,
        permData.grants,
      );

      // Role is equal to starting data, remove it from updatedRoles
      if (_isEqual(tempUpdatedRole.permissions, reducedRole.permissions)) {
        setUpdatedRoles({
          type: UPDATE_ROLE_ACTIONS.REMOVE_FROM_MODIFIED_ROLES,
          data: tempUpdatedRole,
        });
      } else {
        // Replace role with current updated data
        setUpdatedRoles({
          type: UPDATE_ROLE_ACTIONS.UPDATE_MODIFIED_ROLES,
          data: tempUpdatedRole,
        });
      }
    } else {
      const tempUpdatedRole = buildUpdatedRole(
        selectedCheckboxes,
        reducedRole,
        permData,
        permData.grants,
      );

      // Push updated role to updatedRoles
      setUpdatedRoles({
        type: UPDATE_ROLE_ACTIONS.ADD_TO_MODIFIED_ROLES,
        data: tempUpdatedRole,
      });
    }
  };

  const pageActions = (
    <Authorize
      {...{
        abilityPermissions: [
          {
            resource: 'Roles',
            permissions: ['create'],
          },
        ],
      }}>
      <Button
        inverse
        testSelector="roles_add-role_btn"
        onClick={() => setPanelOpen(true)}>
        <Icon className="icon--margin-right" icon="plus-square" />
        Add Role
      </Button>
    </Authorize>
  );

  // TODO: Update when backend has endpoint to return these types
  const companyTypeOptions = [
    {
      value: 1,
      label: 'Admin',
    },
    {
      value: 2,
      label: 'Managed Customer',
    },
    {
      value: 3,
      label: 'SaaS Customer',
    },
    {
      value: 4,
      label: 'Managed Carrier',
    },
    {
      value: 5,
      label: 'SaaS Carrier',
    },
  ];

  const subTitle = (
    <div data-testid="roles-select-container" className="roles-select">
      <Authorize
        {...{
          access: ['JOB_MANAGER'],
          abilityPermissions: [
            {
              resource: 'Roles',
              permissions: ['read'],
            },
          ],
        }}>
        <Select
          onChange={selected => {
            changeCompanyTypeId(selected.value);
            loadPermissionData(selected.value);
          }}
          options={companyTypeOptions}
          placeholder="Select Type"
          defaultValue={companyTypeOptions[0]}
          testSelector="roles_page_type_select"
        />
      </Authorize>
    </div>
  );

  return (
    <Page
      className="roles inner-page"
      title="Roles"
      subtitle={subTitle}
      actions={pageActions}>
      <Block float>
        {(loading && (
          <div data-testid="roles-page-loading">
            <Loader size={100} />
          </div>
        )) || (
          <>
            {permissionData && (
              <RolesList
                permissions={permissionData.permissions}
                roles={permissionData.rolesPermissions}
                grants={permissionData.grants}
                moreOptionsFunc={data => {
                  setEditRole(data);
                  setPanelOpen(true);
                }}
                permissionUpdate={permissionUpdate}
              />
            )}
            <div data-testid="roles-footer" className="roles__footer" />
          </>
        )}
      </Block>
      {panelOpen && (
        <AddEditRole
          editRole={editRole}
          setEditRole={setEditRole}
          panelOpen={panelOpen}
          permissionData={permissionData}
          setPanelOpen={setPanelOpen}
        />
      )}
    </Page>
  );
};

export default Roles;
