import React, { useEffect, useState } from 'react';

import Loader from 'components/shared/Loader';
import { useJobs } from 'api/v2/jobs';
import JobCalendar from './Calendar';

import CalendarJobSlideout from './CalendarJobSlideout';

const JobCalendarPage = ({ customerId }) => {
  const { data: jobs, isLoading } = useJobs({
    customerId,
    includeCarrierCommitments: true,
  });

  const [selectedJob, setSelectedJob] = useState();

  useEffect(() => {
    if (selectedJob) {
      setSelectedJob((jobs || []).find(j => j.id === selectedJob.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const handleClick = task => {
    if (task.job) {
      if (selectedJob && task.job.id === selectedJob.id) {
        setSelectedJob();
      } else {
        setSelectedJob(task.job);
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <JobCalendar jobs={jobs} onClick={handleClick} />

      <CalendarJobSlideout
        isVisible={!!selectedJob}
        selectedJob={selectedJob}
        closePanel={() => setSelectedJob()}
      />
    </div>
  );
};
export default JobCalendarPage;
