import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/DriverDetails';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

const EditDriverRate = ({
  closeModalEditInfo,
  showModalDriverRate,
  closeRate,
  editData,
  setModalValue,
  changeDriverRate,
}) => {
  useEffect(() => () => closeModalEditInfo(), []);

  const { rating, comment } = editData;
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineAddDisabled = () => {
    let disabled = false;
    if (!rating || !comment?.trim().length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [editData]);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${10}%` }}
        className="modal-container"
        show={showModalDriverRate}
        onHide={closeRate}>
        <form onSubmit={e => changeDriverRate(e)} className="m-t" role="form">
          <Modal.Header>
            <h3>Edit Rate</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Please Select Rating"
                  onChange={item => setModalValue(item.value, 'rating')}
                  options={[
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                  ]}
                  required
                  testSelector="driver-details_change-rating_rating_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'comment')}
                  value={comment}
                  label="Comment"
                  required
                  testSelector="driver-details_change-rating_comment_input"
                />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={closeRate}
              colour="white"
              children="Close"
              testSelector="driver-details_chanage-rating_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              disabled={saveDisabled}
              testSelector="driver-details_chanage-rating_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  editData: state.driverProfile.editInfo.editInfoDriver,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setModalValue: (e, name) => {
    dispatch(actions.setEditDriverValue(targetValue(e), name));
  },
  closeModalEditInfo: () => dispatch(actions.closeModalEditInfo()),
  changeDriverRate: e => {
    e.preventDefault();
    dispatch(actions.changeDriverRate(ownProps.closeRate));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDriverRate);
