export const formatPO = purchaseOrders => {
  return purchaseOrders.map(po => {
    po.shceduledPercentage = (po.shceduled * 100) / po.total;
    if (po.shceduledPercentage > 100) {
      po.shceduledPercentage = 100;
    }
    po.leftPercentage = (po.left * 100) / po.total;
    po.loadedPercentage = (po.loaded * 100) / po.total;
    po.deliveredPercentage = (po.delivered * 100) / po.total;
    po.travelTimeMinutesView = `${parseInt(
      po.travelTimeMinutes / 60,
    )} hour(s) ${parseInt(po.travelTimeMinutes % 60)} minute(s)`;
    return po;
  });
};
