const init = {
  companyArray: [],
  operatorList: [],
  openModal: false
};

export default (state = { ...init }, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_OPERATOR_ADD_TURN':
      return {
        ...state,
        openModal: !state.openModal
      };
    case 'JOB_DETAILS_OPERATOR_ADD_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value
      };
    case 'JOB_DETAILS_OPERATOR_ADD_SUCCESS':
      return init;
    case 'JOB_DETAILS_OPERATOR_ADD_ERROR':
      return { ...state };
    default:
      return state;
  }
};
