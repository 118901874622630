import React, { useEffect, useState } from 'react';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import Modal, { ModalForm, FullWidth } from 'components/shared/Modal';
import { connect } from 'react-redux';
import {
  setModalValue as setModalValueAction,
  setDistrictsRequested,
  changeDistrictsRequested,
  resetDistrictsLoadedRequested,
} from 'store/actions/Drivers';
import targetValue from 'utils/targetValue';

const ChangeDistrict = props => {
  const {
    showModalDistrict,
    closeDistrict,
    districts,
    isLoadedDistrictsToCarrier,
    setModalValue,
    changeDistricts,
    districtsSelected,
    setDistricts,
    driverDistricts,
    areDistrictsLoaded,
    resetDistrictsLoaded,
  } = props;
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setDistricts();
    return () => {
      resetDistrictsLoaded();
      closeDistrict();
    };
  }, []);

  const districtOptions = districts
    ? districts.map(item => {
        return {
          value: item.id,
          label: `#${item.id} | ${item.name}`,
        };
      })
    : [];

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      (districtsSelected && !districtsSelected.length) ||
      !districtsSelected ||
      !districtOptions.length
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [districtsSelected, driverDistricts]);

  const header = <h3>Edit District</h3>;
  const body = (
    <>
      <ModalForm>
        <FullWidth>
          {(isLoadedDistrictsToCarrier && areDistrictsLoaded && (
            <>
              {districtOptions.length ? (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Districts"
                  onChange={e => setModalValue(e, 'districts_selected')}
                  options={districtOptions}
                  value={districtsSelected || driverDistricts}
                  required
                  testSelector="drivers_change-district_districts_select"
                />
              ) : (
                <span>There is no districts for this driver</span>
              )}
            </>
          )) || <Loader />}
        </FullWidth>
      </ModalForm>
    </>
  );
  const footer = (
    <>
      <Button
        onClick={() => {
          resetDistrictsLoaded();
          closeDistrict();
        }}
        colour="white"
        testSelector="drivers-list_change-district_close_btn">
        Close
      </Button>
      <Button
        disabled={!areDistrictsLoaded || saveDisabled}
        onClick={changeDistricts}
        testSelector="drivers-list_change-district_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalDistrict}
      onClose={closeDistrict}
    />
  );
};

const mapStateToProps = state => ({
  districts: state.resourceReducer.districtToCarrierId.districts,
  isLoadedDistrictsToCarrier:
    state.resourceReducer.districtToCarrierId.isLoadedDistrictsToCarrier,
  districtsSelected: state.driversNew.selectedDriver.districts_selected,
  driverDistricts: state.driversNew.filters.districts,
  areDistrictsLoaded: state.driversNew.filters.areDistrictsLoaded,
});

const mapDispatchToProps = dispatch => ({
  setModalValue: (e, name) =>
    dispatch(setModalValueAction(targetValue(e), name)),
  changeDistricts: e => {
    e.preventDefault();
    dispatch(changeDistrictsRequested());
  },
  setDistricts: () => dispatch(setDistrictsRequested()),
  resetDistrictsLoaded: () => dispatch(resetDistrictsLoadedRequested()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeDistrict);
