import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import moment from 'moment';

import { orderStatus } from 'components/globalInfo/orderStatus';
import OrderList from 'components/views/OrderList';

import targetValue from 'utils/targetValue';
import actions from 'store/actions/Export';
import Export from './Export';

const initFilters = {
  sort: 'order_id DESC',
  order_id: '',
  stage: '',
  statuses: orderStatus.reduce(
    (acc, item) =>
      item.id !== 4 ? [...acc, { value: item.id, label: item.name }] : acc,
    [],
  ),
  truck: '',
  itemsPerPage: 10,
  carrierIds: [],
  customerIds: [],
  districtIds: [],
  jobIds: [],
  startDate: moment().subtract(3, 'day'),
  endDate: moment(),
  show: {
    statuses: true,
    carrierIds: true,
    customerIds: true,
    districtIds: true,
    jobIds: true,
    date: true,
    count: true,
    origin: true,
    sortByDate: true,
  },
};

const OrdersBlock = ({
  exportSettings,
  isLoadedTemplates,
  templates,

  setFilterValue,
  download,
}) => {
  const [filters, setFilters] = useState(initFilters);

  const handleDownload = (e, type) => {
    e.preventDefault();
    download(filters, type);
  };

  return (
    <Row>
      <Export
        exportSettings={exportSettings}
        isLoadedTemplates={isLoadedTemplates}
        templates={templates}
        setFilterValue={setFilterValue}
        download={handleDownload}
      />
      <OrderList initFilters={initFilters} onFilterChange={setFilters} />
    </Row>
  );
};
const mapStateToProps = state => ({
  exportSettings: state.exportPage.exportSettings,
  isLoadedTemplates: state.exportPage.templates.isLoadedTemplates,
  templates: state.exportPage.templates.templates,
});

const mapDispatchToProps = dispatch => ({
  setFilterValue: (e, name) => {
    dispatch(actions.setFilterValue(targetValue(e), name));
  },
  download: (filters, selectedBtn) => {
    dispatch(actions.download(filters, selectedBtn));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersBlock);
