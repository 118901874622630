import React from 'react';
import { orderStatus } from 'components/globalInfo/orderStatus';

const SearchResultLabel = data => {
  switch (data.type) {
    case 1:
      return (
        <span
          className={`button button--small btn-${orderStatus[data.status].class}`}>
          {orderStatus[data.status].name}
        </span>
      );
    case 2:
      return (
        <span>
          {data.status ? (
            <span className="button button--red button--small">Completed</span>
          ) : (
            <span className="button button--orange button--small">Pending</span>
          )}
        </span>
      );
    case 5:
      return (
        <span>
          {data.status ? (
            <span className="button button--red button--small">busy</span>
          ) : (
            <span className="button button--small">free</span>
          )}
        </span>
      );
    default:
      return <span />;
  }
};

export default SearchResultLabel;
