import React, { useState } from 'react';
import { Modal, Form, Col, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import DatePicker from 'components/shared/DatePicker';
import Input from 'components/shared/Input';

import { useAddStorageRecord } from 'api/sls/storage';
import { useCommodities } from 'api/v2/commodities';
import { useStorageGroupForm } from './hooks';

const AddInventoryLevelModal = ({ storageGroup, show, onClose }) => {
  const { mutate: addStorage, isPending } = useAddStorageRecord(storageGroup.jobId);
  const { data: formData, handlers } = useStorageGroupForm({
    defaultValue: storageGroup,
    show,
  });

  const { data: commodities } = useCommodities();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = event => {
    event.preventDefault();
    addStorage(
      {
        jobId: formData.jobId,
        externalProvider: 'manual',
        storageGroups: [
          {
            ...formData,
            lastSyncTimestamp: new Date(
              formData.lastSyncTimestamp,
            ).toISOString(),
          },
        ],
      },
      {
        onSuccess: handleClose,
      },
    );
  };

  /**
   * @param {import('./hooks').StorageUnit} unit
   * @param {number} index
   */
  const renderUnitForm = (unit, index) => (
    <Row key={`unit-${unit.id}`}>
      <Form.Group controlId="unitName" as={Col}>
        <Form.Control
          as={Input}
          type="text"
          label="Unit Name"
          value={`${storageGroup.name}`}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="unitCommodity" as={Col}>
        <Form.Control
          as={Input}
          type="text"
          label="Commodity"
          value={commodities.find(i => i.id === unit.commodityTypeId)?.name}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="inventoryAmount" as={Col}>
        <Form.Control
          as={Input}
          type="number"
          label="Amount"
          value={unit.currentWeight}
          onChange={handlers.unitValueChange(index, 'currentWeight')}
          required
        />
      </Form.Group>
      <Form.Group controlId="date" as={Col}>
        <DatePicker
          label="Date"
          value={formData.lastSyncTimestamp}
          onChange={handlers.valueChange('lastSyncTimestamp')}
          testSelector="storage_update-inventory_date_dp"
        />
      </Form.Group>
    </Row>
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Update Inventory for {formData.name}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>{formData.units.map(renderUnitForm)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" disabled={isPending}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const AddInventoryLevelWrapper = ({ storageGroup }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      <Button onClick={handleShow}>Update Inventory</Button>

      <AddInventoryLevelModal
        storageGroup={storageGroup}
        show={showModal}
        onClose={handleClose}
      />
    </div>
  );
};

export default AddInventoryLevelWrapper;
