import axios from 'axios';

export const equipmentTypes = {
  get() {
    return axios.get('v2/equipmentTypes', {});
  },
  edit(data) {
    return axios.put(`v2/equipmentTypes/${data.id}`, { name: data.name });
  },
  add(data) {
    return axios.post('v2/equipmentTypes', data);
  },
};