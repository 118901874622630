import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderActions';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import DatePicker from 'components/shared/DatePicker';
import { getDriver } from './helpers';

const Transfer = ({
  order,
  init,
  openModal,
  turnModal,
  transfer,
  setInputValue,
  submitTransfer,
  drivers,
}) => {
  useEffect(() => {
    init(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { input } = transfer;
  const { newDriverId, date, comment } = input;
  const [isDateAlert, turnDateAlert] = useState(false);
  const now = new Date();

  const formattedDrivers = drivers
    .filter(item => {
      if (order.interstateCertRequired) {
        return item.interstateCertified;
      }
      return true;
    })
    .map(item => {
      item.label = `${item.id} | ${item.name}`;
      return item;
    });
  const driverList = filterOptions(formattedDrivers, null, null, null, 'label');

  const isDisable = () => {
    if (!newDriverId || !comment.trim().length || isDateAlert) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    turnModal();
  }

  const handleSubmit = (e) => {
    submitTransfer(e, handleClose);
  }

  return (
    <Modal className="modal-container" show={openModal} onHide={handleClose}>
      <Modal.Header>
        <h3 className="modal-title">Transfer Order</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            {isDateAlert && (
              <Row sm={12} md={12} lg={12}>
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>* Incorrect date format</p>
                </div>
              </Row>
            )}
            <div className="form-group has-feedback col-md-6">
              <Select
                label="Select Driver"
                onChange={item => setInputValue(item.value, 'newDriverId')}
                options={driverList}
                value={driverList.find(item => item.value === newDriverId)}
                testSelector="order-actions_transfer_modify_driver_select"
                required
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <DatePicker
                label="Actual Transfer Date"
                required
                value={date}
                timeFormat="hh:mm A"
                onChange={e => {
                  isDateFormatValid(e, turnDateAlert);
                  setInputValue(e, 'date');
                }}
                isValidDate={current => current.isSameOrAfter(now, 'day')}
                testSelector="order-actions_transfer_modify_date_dp"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                label="Comment"
                onChange={e => setInputValue(e, 'comment')}
                value={comment}
                required
                testSelector="order-actions_transfer_comment_input"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="order-actions_transfer_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={isDisable()}
            testSelector="order-actions_transfer_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  transfer: state.orderActions.transfer,
  drivers: getDriver(state),
});

const mapDispatchToProps = dispatch => ({
  init: order => dispatch(actions.initTransfer(order)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueTransfer(targetValue(e), name)),
  submitTransfer: (e, callbackFn) => {
    e.preventDefault();
    dispatch(actions.submitTransfer(callbackFn));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transfer);
