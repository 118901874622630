import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Authorize from 'components/common/Authorize';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import authService from 'services/auth/service'

const InvoiceActivity = ({ invoiceDetails, addComment, setCurrentTab }) => {
  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState({});
  const [comment, setComment] = useState('');

  useEffect(() => {
    setCurrentTab('activity');
  }, []);

  useEffect(() => {
    if (invoiceDetails) {
      setCurrentInvoiceDetails(invoiceDetails);
    }
  }, [invoiceDetails]);

  const activityList = invoiceDetails.comments;

  activityList.map((activity, index) => {
    if (activity.created_at) {
      return (
        (activityList[index].created_date = moment(activity.created_at).format(
          'DD MMM YYYY',
        )),
        (activityList[index].created_time = moment(activity.created_at).format(
          'HH:mm',
        ))
      );
    }
  });

  const sortedActivity = _.groupBy(activityList, 'created_date');
  const token = authService.getToken();

  return (
    <>
      {Object.keys(sortedActivity).length > 0 && (
        <div className="invoice-activity">
          <div className="invoice-activity__timeline">
            {Object.keys(sortedActivity).map(date => (
              <div key={date} className="date-block">
                <div className="date-block__title">{date}</div>
                <ul className="date-block__activity-list">
                  {sortedActivity[date].map(activity => (
                    <li key={activity.id} className="date-block__activity-item">
                      <div className="activity-item__dot">
                        <span className="activity-item__dot--inner" />
                      </div>
                      <div className="activity-item__time">
                        {activity.created_time}
                      </div>
                      <div className="activity-item__message">
                        <div className="activity-item__message--image">
                          <img
                            className="image"
                            src={`${activity.profile_url}?token=${token}`}
                            alt={`${activity.first_name} ${activity.last_name}`}
                          />
                        </div>
                        <div className="activity-item__message--details">
                          <div className="intro">
                            <span className="name">
                              {activity.first_name} {activity.last_name}
                            </span>{' '}
                            posted:
                          </div>
                          <div className="content">{activity.comment}</div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
      <Authorize
        {...{
          abilityPermissions: [
            {
              resource: 'Invoices',
              permissions: ['update']
            }
          ]
        }}
      >
        <div className="activity-comment-form">
          <Input
            label="Add comment..."
            value={comment}
            onChange={event => setComment(event.target.value)}
            testSelector="invoice_details_activity_comment_input"
          />
          <Button
            type="button"
            disabled={!comment}
            testSelector="invoice_details_activity_add-comment_btn"
            onClick={() => addComment(invoiceDetails.invoice.id, comment)}>
            Add Comment
          </Button>
        </div>
      </Authorize>
    </>
  );
};

InvoiceActivity.propTypes = {
  invoiceDetails: PropTypes.object,
};

InvoiceActivity.defaultProps = {
  invoiceDetails: {},
};

export default InvoiceActivity;
