import axios from 'axios';
import authService from 'services/auth/service';

/** @type {import('axios').AxiosInstance} */
export const client = axios.create({
  // eslint-disable-next-line no-underscore-dangle
  baseURL: window._env_.API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptors

client.interceptors.response.use(
  response => {
    response.status = response.data.status || response.status;
    if (
      response.status === 400 ||
      response.status === 403 ||
      response.status === 300 ||
      response.status === 500
    ) {
      return Promise.reject(response);
    }

    if (response.status === 401) {
      localStorage.clear();
      window.location = '/login';
    }

    return response;
  },
  error => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear();
      window.location = '/login';
      return null;
    }
    return Promise.reject(error);
  },
);

client.interceptors.request.use(
  config => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `jwt ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

export const slsClient = axios.create({
  // eslint-disable-next-line no-underscore-dangle
  baseURL: window._env_.SERVERLESS_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
