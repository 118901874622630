import { client } from 'api/client';

export const createUnitOfMeasure = async (unit) => {
  const { data } = await client.post('v2/units-of-measure', unit);
  return data;
}

export const getUnitsOfMeasure = async () => {
  const { data } = await client.get('v2/units-of-measure');
  return data.data;
}

export const updateUnitOfMeasure = async (unit) => {
  const { data } = await client.put(`v2/units-of-measure/${unit.id}`, unit);
  return data;
}

export const deleteUnitOfMeasure = async (id) => {
  const { data } = await client.delete(`v2/units-of-measure/${id}`);
  return data;
}
