import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';
import { useCallback } from 'react';

export const useGetCommodityUnits = (customerId) => {
  const { data: defaultCommodities } = useCommodities();
  const { data: customerCommodities } = useCustomerCommodities(customerId);

  return useCallback(
    (commodityId) => {
      const commodities = customerId ? customerCommodities : defaultCommodities;
      return commodities?.find(commodity => commodity.id === commodityId)?.unitOfMeasureName || '';
    },
    [defaultCommodities, customerCommodities, customerId],
  );
};

