import React, { useState } from 'react';
import ToolTip from 'components/shared/ToolTip';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import DropdownButton from 'components/shared/DropdownButton';
import CreditMemoPreview from '../CreditMemoPreview';

const CreditMemoActions = ({
  handleCarrierId,
  currentCreditMemo = null,
  currentCreditMemoId,
  getCreditMemoPreview,
  downloadOptions,
  carrierId,
  groupedOrders,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  return (
    <>
      <span className="action-buttons">
        <ToolTip title="Preview">
          <Button
            theme="small"
            className="button--small--square"
            onClick={() => setPreviewOpen(!previewOpen)}
            testSelector="credit-memos_actions_preview_btn"
          >
            <Icon icon="preview" />
          </Button>
        </ToolTip>
        <ToolTip title="Download">
          <DropdownButton
            onClick={() => {
              handleCarrierId(carrierId)}
            }
            icon="download"
            theme="small"
            className="button--small--square"
            options={downloadOptions}
            testSelector="credit-memos_actions_download_dropdown-btn"
          />
        </ToolTip>
      </span>
      <CreditMemoPreview
        currentCreditMemoId={currentCreditMemoId}
        currentCreditMemo={currentCreditMemo}
        open={previewOpen}
      />
    </>
  );
};

export default CreditMemoActions;
