import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import PropTypes from 'prop-types';

const Trigger = children =>
  function({ triggerRef, getTriggerProps }) {
    const toolTipTrigger = (
      <span
        data="tooltip-trigger"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getTriggerProps({
          ref: triggerRef,
        })}>
        {children}
      </span>
    );

    return toolTipTrigger;
  };

const TooltipModal = title =>
  function({
    arrowRef,
    tooltipRef,
    getArrowProps,
    getTooltipProps,
    placement,
  }) {
    const ToolTipContainer = (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getTooltipProps({
          ref: tooltipRef,
          className: `tooltip--container ${placement}`,
        })}>
        {title}
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getArrowProps({
            ref: arrowRef,
            className: 'tooltip--arrow',
            'data-placement': placement,
          })}
        />
      </div>
    );

    return ToolTipContainer;
  };

const ToolTip = ({
  placement = 'top',
  trigger = 'hover',
  title = 'No label',
  children,
}) => (
  <TooltipTrigger
    delayShow="500"
    placement={placement}
    trigger={trigger}
    tooltip={TooltipModal(title)}>
    {Trigger(children)}
  </TooltipTrigger>
);

ToolTip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.string,
  trigger: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ToolTip;
