import axios from 'axios';

export default {
  get() {
    return axios.get('v2/users/profile');
  },
  patch(data) {
    return axios.put('v2/users/profile', data);
  },
  password(data) {
    return axios.post('v2/auth/change-password', data);
  },
};
