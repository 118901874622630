import { createSelector } from 'reselect';
import moment from 'moment';

const getOrders = state => state.sandSiteSchedule.schedule.schedule.orders;
const getExternalOrders = state =>
  state.sandSiteSchedule.schedule.schedule.externalOrders;

export const filterOrders = createSelector(
  getOrders,
  orders => confirmationStatus =>
    orders.filter(item => item.confirmationStatus == confirmationStatus),
);

export const getUnconfirmedOrders = createSelector(
  getOrders,
  orders => orders.filter(item => item.confirmationStatus == 0),
);

export const mapExternalOrders = createSelector(
  getExternalOrders,
  orders =>
    orders.map(o => {
      o.appointmentTime = o.eta;
      o.laneId = o.sandSiteLaneId;
      o.orderId = o.id;
      return o;
    }),
);

export const orderData = (o, type) => {
  if (!type) {
    if (o.confirmationStatus) {
      type = 'confirmed';
    } else {
      type = 'unconfirmed';
    }
  }
  return {
    id: o.orderId,
    title: `${o.carrierName ? o.carrierName.toString().split(' ')[0] : ''} #${
      o.truck
    }`,
    start: moment(o.appointmentTime)
      .local()
      .toDate(),
    end: moment(o.appointmentTime)
      .local()
      .add(20, 'minutes')
      .toDate(),
    resourceId: o.laneId,
    confirmationStatus: o.confirmationStatus,
    appointmentTime: o.appointmentTime,
    loadindEta: o.loadindEta,
    eventType: type,
    status: o.status,
    truck: o.truck,
    sandType: o.sandTypeName,
    carrierName: o.carrierName,
    job: o.jobName,
    po: o.po,
    driverName: o.driverName,
  };
};
