import swal from 'bootstrap-sweetalert';
import FileSaver from 'file-saver';
import { creditMemo } from "services/creditMemos/service";

export const creditMemosRequested = params => async dispatch => {
  dispatch({ type: 'CREDIT_MEMOS_REQUESTED' });

  const body = {
    id: params.id || 0,
    invoiceId: params.invoiceId || 0,
    orderId: params.orderId || 0,
    page: params.page || 0,
    limit: params.limit || 0,
    createdAt: params.createdAt || null,
  }
  const response = await creditMemo.getCreditMemos(body).catch(error => {
    swal(error.response.data.message, '', 'error');
    return dispatch({ type: 'CREDIT_MEMOS_FAILED' });
  });

  return dispatch({
    creditMemos: response.data.data.creditMemos,
    totalItems: response.data.data.totalItems,
    type: 'CREDIT_MEMOS_SUCCEEDED',
  })
}

export const creditMemoDetailsRequested = id => async dispatch => {
  dispatch({ type: 'CREDIT_MEMO_DETAILS_REQUESTED' });

  const response = await creditMemo.getCreditMemoDetails(id).catch(error => {
    swal(error.response.data.message, '', 'error');
    return dispatch({ type: 'CREDIT_MEMO_DETAILS_FAILED' });
  });

  return dispatch({
    customerCreditMemo: response.data.data.customerCreditMemo,
    carrierCreditMemos: response.data.data.carrierCreditMemo,
    type: 'CREDIT_MEMO_DETAILS_SUCCEEDED',
  })
}

export const clearCreditMemoDetails = () => dispatch => {
  return dispatch({ type: 'CLEAR_CREDIT_MEMO_DETAILS'});
}

export const creditMemoPreviewRequested = (id, carrierId) => async dispatch => {
  dispatch({ type: 'CREDIT_MEMO_PREVIEW_REQUESTED' });

  const payload = {}

  if (carrierId) {
    payload.carrierId = carrierId;
  }

  const response = await creditMemo.getCreditMemoPreview(id, payload).catch(error => {
    swal(error.response.data.message, '', 'error');
    return dispatch({ type: 'CREDIT_MEMO_PREVIEW_FAILED' });
  })

  const file = new Blob([response.data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  dispatch({ type: 'CREDIT_MEMO_PREVIEW_SUCCEEDED' });

  return fileURL;
}

export const downloadMemoRequested = (id, carrierId) => async dispatch => {
  const payload = {}

  if (carrierId) {
    payload.carrierId = carrierId;
  }

  const response = await creditMemo.getCreditMemoPreview(id, payload).catch(error => {
    swal(error.response.data.message, '', 'error');
    return
  })
  const fileName = `credit-memo_ATMZ_${id}.pdf`
  const file = new Blob([response.data], { type: 'application/pdf' });

  FileSaver.saveAs(file, fileName);
  return;
}

export default {
  creditMemosRequested,
  creditMemoDetailsRequested,
  clearCreditMemoDetails,
  creditMemoPreviewRequested,
  downloadMemoRequested,
}