import axios from 'axios';

export const creditMemo = {
  getCreditMemos(data) {
    return axios.post('v2/billing/creditmemo', data);
  },
  getCreditMemoDetails(creditMemoId) {
    return axios.get(`v2/billing/creditmemo/${creditMemoId}`);
  },
  getCreditMemoPreview(creditMemoId, data) {
    return axios({
      method: 'post',
      url: `v2/billing/creditmemo/${creditMemoId}/download`,
      responseType: 'arraybuffer',
      data,
    })
  }
}