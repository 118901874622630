import { connect } from 'react-redux';
import {
  invoiceDetailsRequested,
  addCommentRequested,
} from 'store/actions/Invoices';
import InvoiceDetails from './InvoiceDetails';

const mapStateToProps = ({ invoices }) => ({
  selectedInvoiceDetails: invoices.details.data,
  selectedInvoiceIsLoading: invoices.details.apiIsLoading,
});

const mapDispatchToProps = dispatch => ({
  getInvoiceDetails: invoiceId => dispatch(invoiceDetailsRequested(invoiceId)),
  addComment: (id, comment) => dispatch(addCommentRequested(id, comment)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceDetails);
