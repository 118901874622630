import closeIcon from '../../../../public/img/icons/close.svg';
import pinIcon from '../../../../public/img/icons/pin.svg';

// eslint-disable-next-line no-undef
const InfoWindow = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class AutomatizeInfoWindowClass extends OverlayView {
    constructor() {
      super();
      this.latlng = args.latlng;
      this.content = args.content;
      this.pinned = false;
      this.onHide = args.onHide;
      this.width = args.width;
      this.height = args.height;
      this.title = args.title;
      this.mounted = true;
      this.setMap(args.map);
    }

    set visible(state) {
      if (!this.wrapper) {
        return;
      }

      if (state) {
        this.wrapper.style.display = 'block';
      } else {
        this.wrapper.style.display = 'none';
        if (this.onHide) {
          this.onHide(false);
        }
      }
    }

    createDiv() {
      this.header = document.createElement('div');
      this.header.classList.add('info-window__header');

      this.titleRender = document.createElement('div');
      this.titleRender.classList.add('info-window__title');

      this.closeIcon = document.createElement('img');
      this.closeIcon.src = closeIcon;
      this.closeIcon.classList.add('info-window__close-icon');

      this.pinIcon = document.createElement('img');
      this.pinIcon.src = pinIcon;
      this.pinIcon.classList.add('info-window__pin-icon');

      this.container = document.createElement('div');
      this.container.classList.add('info-window__container');

      this.wrapper = document.createElement('div');
      this.wrapper.style.position = 'absolute';
      this.wrapper.style.width = `${this.width}px`;
      this.wrapper.style.height = `${this.height}px`;
      this.wrapper.classList.add('info-window');

      this.header.appendChild(this.titleRender);
      this.header.appendChild(this.pinIcon);
      this.header.appendChild(this.closeIcon);

      this.wrapper.appendChild(this.header);
      this.wrapper.appendChild(this.container);

      this.closeIcon.addEventListener('click', () => {
        this.setPinned(false);
        this.remove();
      });

      this.pinIcon.addEventListener('click', () => {
        this.setPinned(!this.pinned);
      });

      this.container.innerHTML = this.content;
      this.titleRender.innerHTML = this.title;
      this.mounted = false;
    }

    setContent(content) {
      if (this.container) {
        this.container.innerHTML = content;
      }
    }

    appendDivToOverlay() {
      const panes = this.getPanes();
      panes.overlayImage.appendChild(this.wrapper);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point) {
        this.wrapper.style.left = `${point.x}px`;
        this.wrapper.style.top = `${point.y - 30}px`;
        this.wrapper.style.transform = 'translate(-50%, -100%)';
      }
    }

    draw() {
      if (!this.wrapper && this.mounted) {
        this.createDiv();
        this.appendDivToOverlay();
      }

      if (this.wrapper) {
        this.positionDiv();
      }
    }

    // UTILITY
    open() {
      this.visible = true;
    }

    hide() {
      this.visible = false;
    }

    setPinned(state) {
      this.pinned = state;
      if (state) {
        this.pinIcon.classList.add('pinned');
      } else {
        this.pinIcon.classList.remove('pinned');
      }
    }

    remove() {
      if (this.wrapper) {
        this.wrapper.parentNode.removeChild(this.wrapper);
        this.wrapper = null;
        this.onHide(false);
      }
    }
  }

  return new AutomatizeInfoWindowClass();
};

export default InfoWindow;
