import { client } from 'api/client';

/**
 *
 * @param {{
 *  type: number[];
 *  includeAlerts: boolean;
 * }} params
 * @returns
 */
export const getLocations = async ({ type, includeAlerts }) => {
  const { data } = await client.get('v2/locations', {
    params: { type, includeAlerts },
  });
  return data.data.locations;
};

/**
 * @param {{
 *  type: number[];
 *  includeAlerts: boolean;
 *  page: number;
 *  pageSize: number;
 *  id: number;
 *  name: string;
 *  includeDeleted: boolean;
 * }} params
 * @returns
 */
export const getPaginatedLocations = async ({ type, includeAlerts, page, pageSize, id, name, includeDeleted }) => {
  const { data } = await client.get('v2/locations', {
    params: { type, includeAlerts, page, pageSize, id, name, includeDeleted: Number(includeDeleted) },
  });
  return data.data;
}

/**
 *
 * @param {number} locationId
 * @returns
 */
export const deleteLocation = async locationId => {
  const { data } = client.delete(`v2/locations/${locationId}`);
  return data;
};

/**
 *
 * @param {Object} location
 * @returns
 */
export const addLocation = async location => {
  const { data } = await client.post('v2/locations', location);
  return data;
};

/**
 *
 * @param {{
 *  locationId: number;
 *  location: any;
 * }} param0
 * @returns
 */
export const editLocation = async ({ locationId, location }) => {
  const { data } = await client.put(`v2/locations/${locationId}`, location);
  return data;
};

/**
 *
 * @param {number} locationId
 * @returns
 */
export const getLocationDetails = async locationId => {
  const { data } = await client.get(`v2/locations/${locationId || 0}`);
  return data.data;
};

// TODO: Replace with locations endpoint
export const getCustomerLocations = async params => {
  const { data } = await client.get('v2/locations/wellsites', { params });
  return data.data;
};
