import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { convertTime } from 'utils/convertTime';
import Authorize from 'components/common/Authorize';
import { confirmAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import authService from 'services/auth/service';
import { OPERATINGTYPE } from 'components/globalInfo/operatingType';
import actions from 'store/actions/Users';
import { Col, Row } from 'react-bootstrap';
import AddRole from '../AddRole';
import noAvatar from '../../../../../public/img/no_avatar.jpg';

const User = ({ user, openAdd, removeRole, removeUser, restoreUser }) => {
  const [showModalRole, setShowModalRole] = useState(false);
  const [roleTypes, setRoleTypes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await authService.getRoleTypes();
        setRoleTypes(result.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Col sm={4}>
      {showModalRole && (
        <AddRole
          showModalRole={showModalRole}
          closeModalRole={() => {
            setShowModalRole(false);
          }}
        />
      )}
      <div className="ibox-content">
        <div style={{ marginLeft: 15 }}>
          <h2>
            {user.profile.firstName} {user.profile.lastName}
          </h2>
          <Authorize
            abilityPermissions={[
              { resource: 'Users', permissions: ['delete'] },
            ]}>
            <Icon
              style={{ marginRight: 5 }}
              icon="trash"
              className="float-end"
              data-testid="user-info-delete-user-button"
              onClick={() => confirmAlert(removeUser, user.id)}
            />
          </Authorize>
          <Authorize
            abilityPermissions={[
              { resource: 'Users', permissions: ['update'] },
            ]}>
            <Icon
              style={{ marginRight: 5 }}
              icon="pencil float-end"
              className="float-end"
              data-testid="user-info-update-user-button"
              onClick={() => openAdd('edit')}
            />
          </Authorize>
        </div>
        <Row className="row m-b-lg">
          <div className="col-lg-4 text-center">
            <div className="m-b-sm">
              {user.profile.profileUrl && user.profile.profileUrl.length ? (
                <img
                  alt="image"
                  className="img-circle"
                  src={user.profile.profileUrl}
                  style={{ width: 62 }}
                />
              ) : (
                <img src={noAvatar} alt="image" />
              )}
            </div>
          </div>
          <div className="col-lg-8">
            <strong>Main info</strong>
            <p>{user.profile.description}</p>
            <strong className="text-navy">created: </strong>
            <span>{convertTime(user.createdAt)}</span>
            <br />

            <strong className="text-navy">last logged: </strong>
            <span>{convertTime(user.lastLoggedIn)}</span>
            <br />

            <strong className="text-navy">time zone: </strong>
            <span>{user.profile.timeZone}</span>
          </div>
        </Row>
        <Row>
          {user.roles ? (
            <table className="table table--striped table--hover">
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Entity</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {user.roles.map((role, index) => (
                  <tr key={`${role.roleId}-${role.entityName}-${index}`}>
                    <td>
                      {roleTypes &&
                        roleTypes.find(e => e.id === role.roleTypeId) &&
                        roleTypes.find(e => e.id === role.roleTypeId).name}
                    </td>
                    <td>{role.entityName}</td>
                    <td>
                      <Authorize
                        {...{
                          abilityPermissions: [
                            {
                              resource: 'Users',
                              permissions: ['update'],
                            },
                          ],
                        }}>
                        <Icon
                          icon="trash"
                          data-testid="user-info-delete-user-role-button"
                          onClick={() =>
                            confirmAlert(removeRole, {
                              id: user.id,
                              body: {
                                companyId: role.entityId,
                                operatingType: OPERATINGTYPE[role.roleId],
                                roleTypeId: role.roleTypeId,
                              },
                            })
                          }
                        />
                      </Authorize>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Loader />
          )}
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Users',
                  permissions: ['update'],
                },
              ],
            }}>
            {user.isDeleted ? (
              <Button
                onClick={() => confirmAlert(restoreUser, user.id)}
                testSelector="users_user_add_btn"
                theme="small">
                Restore
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setShowModalRole(true);
                }}
                testSelector="users_user_add_btn"
                theme="small">
                Add
              </Button>
            )}
          </Authorize>
        </Row>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  users: state.users,
  user: state.users.info.selectedUser,
  roleTypes: state.users.info.roleTypes,
});

const mapDispatchToProps = dispatch => ({
  removeRole: data => dispatch(actions.removeRole(data)),
  removeUser: userId => dispatch(actions.removeUser(userId)),
  restoreUser: userId => dispatch(actions.restoreUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(User);
