import React, { useEffect, useState } from 'react';
import Authorize from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import Header from 'components/common/Header';

import { usePurchaseOrders } from 'api/v2/purchase-orders';

import PurchaseOrder from '../PurchaseOrder';
import OrdersList from '../OrdersList';


const ViewPo = props => {
  const {
    getCustomers,
    getJobs,
    clearStoreViewPO,
    customers,
    isLoadedCustomers,
    isLoadedJobs,
    jobs,
    locations,
  } = props;

  useEffect(() => {
    // ComponentDidMount
    getCustomers();
    getJobs();
    // ComponentWillUnmount
    return () => clearStoreViewPO();
  }, []);

  const [filter, _setFilter] = useState({ isActive: true });
  const setFilter = (key, value) => {
    _setFilter(prev => ({ ...prev, [key]: value }));
  }
  const { data: purchaseOrders, refetch, isLoading } = usePurchaseOrders({ ...filter, enabled: true });
  const submitFilter = (e) => {
    e.preventDefault();
    refetch();
  }

  if (!isLoadedCustomers || !isLoadedJobs) {
    return <Loader />;
  }

  return (
    <div>
      <Header title="Purchase Orders" />
      <Authorize
        abilityPermissions={[
          { resource: 'Purchase-orders', permissions: ['read'] },
        ]}>
        <PurchaseOrder
          getPurchaseOrders={submitFilter}
          customers={customers}
          setFilter={setFilter}
          filter={filter}
          jobs={jobs}
          locations={locations}
        />
        {!isLoading && <OrdersList purchaseOrders={purchaseOrders} />}
      </Authorize>
    </div>
  );
};

export default ViewPo;
