export const storageInfoSorted = storageSiloInfo =>
  storageSiloInfo.map(silo => {
    const percentage = (silo.weight * 100) / silo.total_weight;
    silo.emptyLevel = percentage < 100 ? 100 - percentage : 0;

    if (silo.weight >= 0) {
      if (percentage < 100) {
        silo.level = percentage;
      } else {
        silo.level = 100;
      }
    } else {
      silo.level = 0;
    }
    return silo;
  });

export function getMinimumTimelineWeight(storageTimeline) {
  const sandTypes = Object.keys(storageTimeline.actual);
  return Math.min(
    ...sandTypes.map(typeId =>
      Math.min(...storageTimeline.actual[typeId].map(t => t.weight)),
    ),
  );
}

/**
 * It uses the golden ratio as an interval between adjacent colors,
 * which generates a divinely proportioned spread of colors.
 * @param {number} number 
 * @param {number} opacity 
 * @returns {string} HSL color
 */
export const selectColor = (number, opacity) => {
  if (!number) {
    return `hsl(204, 8%, 76%, ${opacity}%)`;
  }

  // Nota Bene: PHI is the "golden ratio".
  const PHI = (1 + Math.sqrt(5)) / 2;
  const hue = Math.floor(number * PHI - Math.floor(number * PHI) * 256);
  return `hsl(${hue}, 70%, 60%, ${opacity}%)`;
};
