import React, { useEffect, useState } from 'react';
import Select from 'components/shared/Select';
import { Modal, Row } from 'react-bootstrap';
import multiSelectOptions from 'utils/multiSelectOptions';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Authorize, { authorize } from 'components/common/Authorize';

import { useDistricts } from 'api/v2/districts';

const defaultOnBoardingDistrict = Number(
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const Company = ({ setStep, setCompanyValue, addCustomer, closeAdd }) => {
  let companyOptions = [];
  if (
    authorize({
      access: ['JOB_MANAGER'],
    })
  ) {
    companyOptions = [
      { value: 2, label: 'Managed Customer' },
      { value: 3, label: 'SaaS Customer' },
    ];
  } else {
    // if not admin
    companyOptions = [{ value: 2, label: 'Managed Customer' }];
  }

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [defaultDistricts, setDefaultDistricts] = useState([]);
  const { data: districtsList } = useDistricts();

  const {
    input: {
      companyInfo: {
        name,
        email,
        districts,
        companyType,
        qbId,
        driverTimeout,
        bcSync,
      },
    },
  } = addCustomer;

  const districtsOptions = multiSelectOptions(districtsList, 'id', 'name');

  useEffect(() => {
    if (districts.length && !Number.isNaN(districts[0])) {
      const d = districtsOptions.filter(opt => districts.includes(opt.value));
      setDefaultDistricts(d);
      setCompanyValue(d, 'districts');
    }
  }, []);

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      name === '' ||
      email === '' ||
      districts.length < 1 ||
      (authorize({ access: ['JOB_MANAGER'] }) && companyType === '')
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [addCustomer]);

  const handleDistrictChange = (name, value) => {
    const hasDefault = value.some(
      option => option.value === defaultOnBoardingDistrict,
    );
    if (hasDefault) {
      setCompanyValue(value, 'districts')
    } else {
      setCompanyValue([...value, { value: defaultOnBoardingDistrict, label: 'On-boarding' }], name)
    }
  };

  return (
    <form onSubmit={e => setStep(3, e)} className="wizard-big wizard">
      <Modal.Body className="form-group">
        <div className="step1">
          <h1 id="form-h-0" className="title current">
            Company Info
          </h1>
          <fieldset id="form-p-0" role="tabpanel" className="body current">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setCompanyValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="admin-customers_company_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setCompanyValue(e, 'email')}
                  value={email}
                  type="email"
                  label="Email"
                  testSelector="admin-customers_company_email_input"
                  required
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  required
                  closeMenuOnSelect={false}
                  onChange={selected => handleDistrictChange('districts', selected)}
                  options={districtsOptions}
                  placeholder="Select districts"
                  value={districts}
                  testSelector="admin-customers_company_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  onChange={selected => {
                    selected.value &&
                      setCompanyValue(selected.value, 'companyType');
                  }}
                  options={companyOptions}
                  placeholder="Select Company Type"
                  value={companyOptions.find(
                    item => item.value === companyType,
                  )}
                  required
                  testSelector="admin-customers_company_company-type_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <div className="form-group">
                  <Input
                    onChange={e => setCompanyValue(e, 'qbId')}
                    value={qbId}
                    label="QuickBooks ID"
                    testSelector="admin-customers_company_quickbook-id_input"
                  />
                </div>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setCompanyValue(e, 'driverTimeout')}
                  value={driverTimeout}
                  required
                  type="number"
                  label="Driver Timeout (minutes)"
                  testSelector="admin-customers_company_driver-timeout_input"
                  disabled
                />
              </div>
              <Authorize
                {...{
                  abilityPermissions: [
                    {
                      resource: 'Carrier-documents',
                      permissions: ['create'],
                    },
                  ],
                }}>
                <div className="form-group has-feedback col-md-6">
                  <div className="form-group">
                    <Input
                      onChange={e =>
                        setCompanyValue(e.target.checked, 'bcSync')
                      }
                      value={bcSync}
                      label="BC Sync"
                      type="checkbox"
                      testSelector="admin-customers_company_bc_sync_input"
                    />
                  </div>
                </div>
              </Authorize>
            </Row>
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAdd}
          colour="white"
          testSelector="admin-customers_company_close_btn">
          Close
        </Button>
        <Button
          type="submit"
          disabled={saveDisabled}
          testSelector="admin-customers_company_next_btn">
          Next
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Company;
