export const sortList = [
    { value: "order_id", label: "ID" },
    { value: "status", label: "Status" },
    { value: "stage", label: "Stage" },
    { value: "unload_appt", label: "Unload Appointment" },
    { value: "expected_pickup_time", label: "Expected Pickup Time" },
    { value: "eta", label: "ETA" },
    { value: "well_arrival", label: "Well Arrival" }
  ];

export const formatList = [
    { value: "csv", label: "CSV" },
    { value: "json", label: "JSON" },
    { value: "packet_invoice", label: "Packet Invoice" },
    { value: "sand_tickets", label: "Tickets" },
  ];