import { useStorageSources } from 'api/v2/storage-types';
import { useEffect, useMemo, useReducer } from 'react';
import multiSelectOptions from 'utils/multiSelectOptions';
import targetValue from 'utils/targetValue';

export const useSourceOptions = () => {
  const { data: sources } = useStorageSources();

  const sourceOptions = useMemo(
    () => [
      { label: 'Manual', value: undefined },
      ...multiSelectOptions(sources, 'id', 'name'),
    ],
    [sources],
  );
  return sourceOptions;
};

/**
 * @typedef {typeof initialStorageType} StorageType
 */

const initialStorageType = {
  name: '',
  description: '',
};

/**
 *
 * @param {StorageType} state
 * @returns {StorageType}
 */
const StorageTypeReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialStorageType;
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   valueChange: (name: string) => (e: any) => {};
 *   clear: () => {};
 * }} StorageTypeHandlers
 */

/**
 * @typedef {{
 *   data: StorageType;
 *   isValid: boolean;
 *   handlers: StorageTypeHandlers;
 * }} StorageTypeFormReturn
 */

/**
 *
 * @param {{ defaultValue: any; showModal: boolean; }} params
 * @returns {StorageTypeFormReturn}
 */
export const useStorageTypeForm = ({ defaultValue, showModal }) => {
  /** @type {[StorageType, () => {}]} */
  const [data, dispatch] = useReducer(StorageTypeReducer, initialStorageType);

  useEffect(() => {
    if (defaultValue && showModal) {
      dispatch({ type: 'init', data: defaultValue });
    }
  }, [defaultValue, showModal]);

  const isValid = useMemo(
    () => data.name?.trim()?.length && data.description?.trim()?.length,
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
