import React, { useState, useEffect } from 'react';
import { Modal, Image } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import FileSaver from 'file-saver';
import authService from 'services/auth/service';

const token = authService.getToken();

const downloadDocument = async (imgURL, imgName) => {
  const blob = await fetch(imgURL).then(r => r.blob());
  FileSaver.saveAs(blob, imgName);
};

const ImageViewer = ({ docId, docName, visibleToggle, visible }) => {
  const { innerHeight: height } = window;

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${5}%` }}
        className="modal-container"
        show={visible}
        onHide={() => {
          visibleToggle(false);
        }}>
        <Modal.Header>
          <h4 className="modal-title">Preview Document</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="animated fadeInRight">
              <div className="ibox-content ">
                <div>
                  {docId ? (
                    <>
                      {docName.toLowerCase().includes('.pdf') ? (
                        <div>
                          <iframe
                            id="inlineFrameExample"
                            title="Inline Frame Example"
                            width="100%"
                            height={`${height * 0.6}`}
                            src={`${
                              window._env_.API_BASE_URL
                            }v2/documents/${docId}?token=${token}&type=application/pdf`}
                          />
                        </div>
                      ) : (
                        <div>
                          <Image
                            src={`${
                              window._env_.API_BASE_URL
                            }v2/documents/${docId}?token=${token}`}
                            width="100%"
                            alt="preview"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <hr style={{ borderColor: 'rgb(170, 170, 170)' }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              visibleToggle(false);
            }}
            colour="#6fc6d9">
            Close
          </Button>
          <Button
            onClick={() =>
              downloadDocument(
                `${
                  window._env_.API_BASE_URL
                }v2/documents/${docId}?token=${token}`,
                `${docName}`,
              )
            }>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ImageViewer;
