import { connect } from 'react-redux';
import React, { useMemo } from 'react';

import GoogleMap, { AutomatizeMarker } from 'components/shared/GoogleMap';
import Icon from 'components/shared/Icon';

import { useLocations } from 'api/v2/locations';

const validLocationTypes = {
  1: 'well_site',
  2: 'sand_site',
  3: 'water_disposal',
  4: 'staging_site',
  5: 'acid_facility',
};

const checkLocationIcon = type => {
  switch (type) {
    case 1:
      return 'wellsite';
    case 2:
      return 'sandsite';
    case 3:
      return 'water_disposal';
    case 4:
      return 'staging_site';
    case 5:
      return 'acid_facility';
    default:
      return '';
  }
};

const LocationsMap = ({
  mapConfig,
  locations
}) => {
  const { longitude, latitude, zoom } = mapConfig;
  const initialCenter = { lat: latitude, lng: longitude };
  const locationsList = useMemo(
    () => (locations || [])
      .filter(location => !!validLocationTypes[location.locationType])
      .map(location => ({
        ...location,
        type: validLocationTypes[location.locationType],
        latitude: Number(location.latitude),
        longitude: Number(location.longitude),
      })),
    [locations],
  );

  const renderMarker = item => (
    <AutomatizeMarker
      position={{ lat: item.latitude, lng: item.longitude }}
      type={item.type}
      key={`${item.id}_${item.locationType}`}
      infoWindow={{
        title: (
          <>
            <Icon icon={checkLocationIcon(item.locationType)} colour="white" />
            <span className="info-window__title--text">
              #{item.id} {item.name}
            </span>
          </>
        ),
        width: 380,
      }}
    />
  );

  return (
    <div style={{ height: 650, position: 'relative' }}>
      <GoogleMap
        initialCenter={initialCenter}
        center={initialCenter}
        zoom={zoom}>
        {locationsList.map(renderMarker)}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = state => ({
  mapConfig: state.locations.mapConfig,
});

export default connect(
  mapStateToProps,
  null,
)(LocationsMap);
