import React, { useState } from 'react';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import OrderInfo from '../OrderInfo';
import Chat from '../DriverChat';
import SelectSlot from '../SelectSlot';
import { eventPropGetter } from './helpers';

BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const Calendar = ({
  lanes,
  events,
  currentOrder,
  removeSlot,
  schedule,
  confirmAppointment,
  openMoveOrder,
  setNewTime,
  getSandSiteSchedule,
  setSlotWorkTime,
  clearStoreSlot,
  setCurrentOrder,
  clearCurrentOrder,
  initDriverChat,
}) => {
  const [showSlot, turnSlot] = useState(false);
  const [showOrderInfo, turnOrderInfo] = useState(false);
  const [showDriverChat, turnDriverChat] = useState(false);

  const selectEvent = event => {
    switch (event.eventType) {
      case 'confirmed':
      case 'unconfirmed':
      case 'external':
        orderInfo(event);
        break;
      case 'maintenance':
        maintenanceInfo(event);
        break;
    }
  };

  const orderInfo = event => {
    const { id } = event;
    setCurrentOrder(id);
    turnOrderInfo(true);
  };

  const maintenanceInfo = event => {
    // Rewrite!
    swal(
      {
        title: '',
        text: event.description,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete it',
        cancelButtonText: 'Ok',
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      function(isConfirm) {
        if (isConfirm) {
          const { id } = event;
          removeSlot(id);
          swal('Success!', '', 'success');
        }
      },
    );
  };

  const resources = () => {
    const calendarLanes = lanes.map((l, i) => ({
      id: l.id,
      title: `${l.name} (${l.sandTypeName})`,
    }));
    return calendarLanes;
  };

  const changeDate = e => {
    getSandSiteSchedule(e);
  };

  const moveOrder = e => {
    const { event, start } = e;
    const { id } = event;
    const lanes = resources();
    switch (event.eventType) {
      case 'confirmed':
      case 'unconfirmed':
        {
          if (event.status == 4) {
            swal("You can't change appt time, cause order is completed");
          } else if (event.status == 3 || event.status == 6) {
            swal("You can't change appt time, cause driver already loaded");
          } else if (event.status == 0 || event.status == 1) {
            swal("You can't change appt time for this order");
          } else {
            lanes.forEach((l, index) => {
              if (l.id == e.resourceId) {
                if (event.sandType != l.title.split(/[()]/)[1]) {
                  swal('This lane does not match the order commodity.');
                } else {
                  setNewTime(id, start, e.resourceId);
                }
              }
            });
          }
        }
        break;
      case 'maintenance':
        swal("You can't move maintenance");
        break;
      case 'external':
        swal("You can't move external order");
        break;
    }
  };
  return (
    <div>
      {showSlot && (
        <SelectSlot
          {...{ showSlot }}
          closeSlot={() => {
            clearStoreSlot();
            turnSlot(false);
          }}
        />
      )}
      {showOrderInfo && (
        <OrderInfo
          {...{
            showOrderInfo,
            order: currentOrder,
            confirmAppointment,
            openMoveOrder,
          }}
          openDriverChat={(chatId, driverId) => {
            initDriverChat(chatId, driverId);
            turnDriverChat(true);
          }}
          closeOrderInfo={() => {
            clearCurrentOrder();
            turnOrderInfo(false);
          }}
        />
      )}
      {/* {showDriverChat && (
        <Chat
          {...{ showDriverChat }}
          closeDriverChat={() => turnDriverChat(false)}
        />
      )} */}
      <DragAndDropCalendar
        views={{ day: true }}
        defaultDate={new Date()}
        onEventDrop={moveOrder}
        onSelectEvent={event => selectEvent(event)}
        onSelectSlot={slotInfo => {
          setSlotWorkTime(
            moment(slotInfo.start).toDate(),
            moment(slotInfo.end).toDate(),
            slotInfo.resourceId,
          );
          turnSlot(true);
        }}
        selectable
        onNavigate={changeDate}
        defaultView="day"
        step={20}
        timeslots={1}
        events={events}
        resources={resources()}
        eventPropGetter={e => eventPropGetter(e, schedule)}
      />
    </div>
  );
};

export default DragDropContext(HTML5Backend)(Calendar);
