import { client } from 'api/client';

/**
 *
 * @param {{
 *  jobId: number;
 * }} params
 */
export const getJobCertificates = async ({ jobId }) => {
  const { data } = await client.get(`v2/jobs/${jobId}/certificates`);
  return data.data;
};

export const addJobCertificate = async ({ jobId, certificateId }) => {
  const { data } = await client.post(
    `v2/jobs/${jobId}/certificates/${certificateId}`,
    {
      certId: certificateId,
      jobId,
    },
  );
  return data;
};

export const deleteJobCertificate = async ({ jobId, certificateId }) => {
  const { data } = await client.delete(
    `v2/jobs/${jobId}/certificates/${certificateId}`,
  );
  return data;
};
