import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Loader from 'components/shared/Loader';
import targetValue from 'utils/targetValue';
import { convertNumberToCurrencyFromAmount } from 'utils/convertNumberToCurrency';
import {
  getCreditMemoOrders,
  creditMemoOrdersSetValue,
  createCreditMemo,
  creditMemoSetReason,
} from 'store/actions/Invoices';

const CreditMemo = ({
  creditMemo,
  init,
  setValue,
  setReason,
  invoice,
  showModal,
  closeModal,
  submit,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);

  const { totalCost, apiIsLoading, orders, reason } = creditMemo;
  const { id } = invoice;

  useEffect(() => {
    init(id);
  }, []);

  useEffect(() => {
    if (totalCost && reason) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [totalCost, reason]);

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title as="h4">Create Credit Memo</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={e => {
          submit(e, id, closeModal);
        }}>
        <Modal.Body>
          {!apiIsLoading ? (
            <>
              <table className="table ">
                <tbody>
                  <tr>
                    <td className="unit-info__title-item">
                      <Input
                        type="checkbox"
                        onChange={e => {
                          setValue(e, null, -1);
                        }}
                        isChecked={!orders.some(o => !o.isChecked)}
                        testSelector="invoice_credit-memo_select-all_input"
                        label="Select All"
                      />
                    </td>
                    <td>
                      <strong className="text-white">Order ID</strong>
                    </td>
                    <td>
                      <strong className="text-white">Order Price</strong>
                    </td>
                  </tr>
                  {orders.map((item, index) => (
                    <tr>
                      <td className="unit-info__title-item">
                        <Input
                          type="checkbox"
                          onChange={e => {
                            setValue(e, item.totalPrice, index);
                          }}
                          isChecked={item.isChecked}
                          id={`credit-orders-${index}`}
                          testSelector={`invoice_credit-memo_total-price_${index}_input`}
                          label=""
                        />
                      </td>
                      <td className="unit-info__title-item text-white">
                        {item.orderId}
                      </td>
                      <td className="unit-info__title-item text-white">
                        {convertNumberToCurrencyFromAmount(item.totalPrice)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row>
                <div className="form-group has-feedback col-md-12 text-right">
                  <strong className="text-white">
                    Total Credited Amount:{' '}
                  </strong>
                  <span className="text-white">
                    {' '}
                    {convertNumberToCurrencyFromAmount(totalCost)}{' '}
                  </span>
                </div>
              </Row>
              <Row>
                <div className="form-group has-feedback col-md-12 text-right">
                  <Textarea
                    rows="3"
                    label="Reason"
                    onChange={e => setReason(e)}
                    value={reason}
                    required
                    testSelector="invoice_credit-memo_reason_input"
                  />
                </div>
              </Row>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="invoice_credit-memo_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="invoice_credit-memo_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  creditMemo: state.invoices.creditMemo,
});

const mapDispatchToProps = dispatch => ({
  init: invoiceId => dispatch(getCreditMemoOrders(invoiceId)),
  setValue: (value, price, index) =>
    dispatch(creditMemoOrdersSetValue(targetValue(value), price, index)),
  setReason: value => dispatch(creditMemoSetReason(targetValue(value))),
  submit: (e, id, closeModal) => {
    e.preventDefault();
    dispatch(createCreditMemo(id, closeModal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditMemo);
