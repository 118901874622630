import React from 'react';
import { Checkbox, CheckboxGroup } from 'components/shared/Input';
import {
  checkPermissionHasGid,
  checkRoleHasPid,
} from 'components/views/Roles/AddEditRoleCheckboxes/helpers';

const RolesListCheckboxes = ({
  roleData,
  permData,
  permissionUpdate,
  grants,
}) => {
  const { pid } = permData;
  const {
    role: { rid },
  } = roleData;
  const getRolePermission = checkRoleHasPid(roleData, pid);

  return (
    <CheckboxGroup
      parentId={`role${rid}-perm${pid}`}
      onChange={inputData =>
        permissionUpdate(inputData, roleData, permData, grants)
      }>
      <td>
        <table className="nested-table">
          {/* Parent Checkbox */}
          <tbody>
            <tr className="primary-row">
              <td>
                <span className="roles-list-checkboxes__checkbox-container">
                  <Checkbox
                    id={`role${rid}-perm${pid}`}
                    // TODO: temp new api broke checkboxes
                    disabled
                    noMargin
                  />
                </span>
              </td>
            </tr>

            {grants.map(grantData => {
              const { gid, _id } = grantData;
              if (gid === 5) {
                return null;
              }

              const defaultChecked = checkPermissionHasGid(
                getRolePermission,
                gid,
              );

              return (
                <tr key={`grantId${gid}-perm${pid}`} className="secondary-row">
                  <td>
                    <span className="roles-list-checkboxes__checkbox-container">
                      <Checkbox
                        noMargin
                        value={_id}
                        defaultChecked={defaultChecked}
                        testSelector={`roles-list-checkbox-${rid}-${gid}-${pid}`}
                        // TODO: temp new api broke checkboxes
                        disabled
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </CheckboxGroup>
  );
};

export default RolesListCheckboxes;
