import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ tabs }) => {
  const [selectedTab, selectNewTab] = useState({ label: null, content: null });

  useEffect(() => {
    if (tabs) {
      selectNewTab({
        label: tabs.find(tab => tab.defaultTab).label,
        content: tabs.find(tab => tab.defaultTab).content,
      });
    }
  }, [tabs]);

  return (
    <div className="tabs">
      {(tabs.length > 0 && (
        <>
          <ul className="tabs__list">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`tabs__list--item ${selectedTab.label === tab.label && 'active' || ''}`}
                onClick={() => selectNewTab(tab)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
          <div className="tabs__content">
            {selectedTab.content || <h3>No content available.</h3>}
          </div>
        </>
      )) || <h3>No tabs available.</h3>}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
};

Tabs.defaultProps = {
  tabs: [],
};

export default Tabs;
