import React from 'react';

const Sand = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill">
    <path d="M20.545 16H3.455c-.226 0-.416-.213-.45-.501-.033-.289.1-.565.316-.65 2.075-.825 3.256-2.869 4.397-4.845C8.908 7.945 10.031 6 12 6c1.969 0 3.092 1.945 4.282 4.004 1.141 1.976 2.322 4.02 4.397 4.844.215.086.35.362.316.65-.034.289-.225.502-.45.502zm-7.727-6.667A.826.826 0 0 0 12 8.5a.826.826 0 0 0-.818.833c0 .46.366.834.818.834a.826.826 0 0 0 .818-.834zM15.273 11a.826.826 0 0 0-.818-.833.826.826 0 0 0-.819.833c0 .46.367.833.819.833a.826.826 0 0 0 .818-.833zm-4.091 1.666a.826.826 0 0 0-.818-.833.826.826 0 0 0-.819.833c0 .46.367.834.819.834a.826.826 0 0 0 .818-.834z" />
  </svg>
);

export default Sand;
