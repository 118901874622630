import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Export';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';

const PacketInvoice = ({
  downloadInvoice,
  openModal,
  turnModal,
  info,
  count,
}) => {
  useEffect(() => {
    downloadInvoice();
  }, []);
  const { chunks } = info;
  return (
    <Modal className="modal-container" show={openModal} onHide={turnModal}>
      <Modal.Header>
        <h4 className="modal-title">Packet Invoice</h4>
      </Modal.Header>
      <Modal.Body>
        {chunks.map((item, index) => (
          <Row key={`download${index}`}>
            <div className="form-group has-feedback col-md-3" />
            <div className="form-group has-feedback col-md-3">
              <label className="label ng-binding">
                Packet invoice part # {index + 1}
              </label>
            </div>
            <div className="form-group has-feedback col-md-3">
              {index >= count ? (
                <div className="for-spinner-downloading">
                  <small>downloading...</small>
                </div>
              ) : (
                <div className="for-spinner-finish">
                  <Icon icon="check" />
                  <small style={{ marginLeft: '2px' }}>downloaded</small>
                </div>
              )}
            </div>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={turnModal}
          colour="white"
          testSelector="export_orders_pscket-invoice_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  openModal: state.exportPage.modalPacketInvoice.openModal,
  info: state.exportPage.modalPacketInvoice.info,
  count: state.exportPage.modalPacketInvoice.count,
});

const mapDispatchToProps = dispatch => ({
  turnModal: () => dispatch(actions.turnPacketInvoiceModal()),
  downloadInvoice: () => dispatch(actions.downloadInvoice()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PacketInvoice);
