import React from 'react';
import PropTypes from 'prop-types';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.element),
]);

/** Modal Form */
export const ModalForm = ({ children }) => {
  return <div className="modal-form">{children}</div>;
};

ModalForm.propTypes = {
  /** Children to be wrapped by ModalForm */
  children: childrenPropTypes.isRequired,
};

/** Full Width */
export const FullWidth = ({ children }) => {
  return <div className="modal-form__full-width">{children}</div>;
};

FullWidth.propTypes = {
  /** Children to be wrapped by FullWidth */
  children: childrenPropTypes.isRequired,
};

/** Spacer
 * Can be given a height
 * On tablet and above used to control layout
 * On mobile hidden unless has content
 */
export const Spacer = ({ children, hidden, height }) => {
  return (
    <div
      className={`modal-form__spacer 
      ${height ? `modal-form__spacer--height-${height}` : ''}
      ${hidden ? `modal-form__spacer--hidden` : ''}
      `}>
      {children || null}
    </div>
  );
};

Spacer.propTypes = {
  /** Children to be wrapped by Spacer */
  children: childrenPropTypes,
  /** Hides content */
  hidden: PropTypes.bool,
  /** Allows Spacer to take up multiple rows */
  height: PropTypes.number,
};

Spacer.defaultProps = {
  children: undefined,
  hidden: undefined,
  height: undefined,
};

/** Section Title */
export const SectionTitle = ({ title }) => {
  return <div className="modal-form__section-title">{title}</div>;
};

SectionTitle.propTypes = {
  /** Section title as string */
  title: PropTypes.string.isRequired,
};

/** Modal Form Break (<hr />) */
export const Break = ({ noMargin }) => {
  return (
    <hr
      className={`modal-form__break ${
        noMargin ? 'modal-form__break--no-margin' : ''
      }`}
    />
  );
};

Break.propTypes = {
  /** Forces Break to expand across whole modal */
  noMargin: PropTypes.bool,
};

Break.defaultProps = {
  noMargin: false,
};
