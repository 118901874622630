import React, { useState } from 'react';
import { connect } from 'react-redux';
import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { deleteAlert } from 'components/sweetalert';
import actions from 'store/actions/Export';
import TemplateDetails from './TemplateDetails/index';

const Templates = ({
  templates,
  openAddTemplate,
  deleteTemplate,
  clearTemplateModal,
  getTemplate,
}) => {
  const [showTemplateDetails, turnTemplateDetails] = useState(false);

  return (
    <div className="row">
      {showTemplateDetails && (
        <TemplateDetails
          {...{ showTemplateDetails }}
          closeTemplate={() => {
            turnTemplateDetails(!showTemplateDetails);
            clearTemplateModal();
          }}
        />
      )}
      <div className="col-lg-12">
        <div className="ibox float-e-margins">
          <div
            className="ibox-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <h5
              style={{
                marginRight: '10px',
                paddingTop: '4px',
              }}>
              Templates
            </h5>
            <Authorize
              {...{
                abilityPermissions: [
                  {
                    resource: 'Exports',
                    permissions: ['update'],
                  },
                ],
              }}>
              <Button
                onClick={openAddTemplate}
                testSelector="export_orders_templates_plus_btn">
                <Icon icon="plus" />
              </Button>
            </Authorize>
          </div>
          <div className="ibox-content">
            <div style={{ marginTop: 10 }}>
              {templates.map(i => (
                <div
                  key={i.name + i.id}
                  className="tree-node tree-node-content">
                  <span>
                    <Button
                      onClick={() => {
                        getTemplate(i.id);
                        turnTemplateDetails(!showTemplateDetails);
                      }}
                      testSelector="export_templates_details_btn">
                      {i.name}
                    </Button>
                  </span>
                  <span>
                    <Authorize
                      {...{
                        abilityPermissions: [
                          {
                            resource: 'Exports',
                            permissions: ['update'],
                          },
                        ],
                      }}>
                      <Button
                        theme="square"
                        colour="white"
                        onClick={() => deleteAlert(deleteTemplate, i.id)}
                        testSelector="export_templates_delete_btn">
                        <Icon icon="trash" />
                      </Button>
                    </Authorize>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  templates: state.exportPage.templates.templates,
});

const mapDispatchToProps = dispatch => ({
  deleteTemplate: id => dispatch(actions.deleteTemplate(id)),
  getTemplate: id => dispatch(actions.getTemplate(id)),
  clearTemplateModal: () => dispatch(actions.clearTemplateModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Templates);
