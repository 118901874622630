import axios from 'axios';

export const vendor = {
  get() {
    return axios.get(`v2/vendors`, {});
  },
  edit(data) {
    return axios.patch(`v2/vendors/${data.id}`, data);
  },
  add(data) {
    return axios.post('v2/vendors', data);
  },
  remove(id) {
    return axios.delete(`v2/vendors/${id}`,);
  },
  unDeleteVendor(id) {
    return axios.post(`v2/vendors/${id}/enable`);
  },
  getVendor(id) {
    return axios.get(`v2/vendors/${id}`);
  },
  getVendorTypes() {
    return axios.get(`v2/vendors/types`);
  },
  getContainerVendor() {
    return axios.get('v2/vendors?type=2', {});
  },
};
