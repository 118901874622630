import React from 'react';

const Header = ({ title, children }) => (
  <div className="page-heading">
      <h2>{title}</h2>
    {children}
  </div>
);

export default React.memo(Header);
