import React from 'react';

const Verified = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <g fill="#22ADCF">
            <path d="M14.652 10.332a1.37 1.37 0 0 0 .344-.93c0-.326-.119-.607-.356-.844a1.152 1.152 0 0 0-.843-.356h-1.73a1.045 1.045 0 0 1 .119-.287 5.168 5.168 0 0 0 .234-.462c.039-.093.078-.218.118-.376.04-.158.06-.316.06-.475a2.687 2.687 0 0 0-.034-.525 1.577 1.577 0 0 0-.076-.312 1.456 1.456 0 0 0-.15-.281.876.876 0 0 0-.25-.253 1.37 1.37 0 0 0-.375-.162 1.866 1.866 0 0 0-.515-.066.384.384 0 0 0-.282.119 1.085 1.085 0 0 0-.212.312 1.859 1.859 0 0 0-.122.325 6.046 6.046 0 0 0-.078.381l-.084.378a1.998 1.998 0 0 1-.11.303.957.957 0 0 1-.193.3 9.237 9.237 0 0 0-.631.75c-.205.266-.415.518-.631.755-.217.238-.375.36-.475.369a.41.41 0 0 0-.269.128.377.377 0 0 0-.112.272v4.004c0 .109.04.201.118.278.08.078.173.118.281.122.146.005.475.096.988.275.32.108.571.19.752.247.181.056.435.117.76.181.324.065.624.097.899.097H12.603c.554-.008.964-.17 1.23-.487.242-.288.344-.665.307-1.131.162-.154.275-.35.337-.587.07-.254.07-.498 0-.731.192-.254.281-.54.269-.856 0-.133-.031-.292-.094-.475z"/>
            <path fillRule="nonzero" d="M7.2 9.001H5.4a.384.384 0 0 0-.281.12A.384.384 0 0 0 5 9.4V13.4c0 .108.04.202.119.28.079.08.173.12.28.12h1.8c.108 0 .202-.04.281-.12a.385.385 0 0 0 .119-.28V9.4a.384.384 0 0 0-.119-.28A.384.384 0 0 0 7.2 9zm-.72 3.883A.39.39 0 0 1 6.2 13a.386.386 0 0 1-.285-.116.386.386 0 0 1-.115-.284.39.39 0 0 1 .115-.281.381.381 0 0 1 .285-.119c.108 0 .202.04.28.119.08.079.12.173.12.281a.38.38 0 0 1-.12.284z"/>
        </g>
        <circle cx="10" cy="10" r="10" fillRule="nonzero" stroke="#22ADCF"/>
    </g>
  </svg>
);

export default Verified;