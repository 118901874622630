import React from 'react';
import Link from 'components/common/Link';

const RejectedOrders = ({ orders }) => {
  const manuallyRejected = orders.filter(o => o.is_timeout === 0 && o.reason_rejected !== 'releasedDriver');
  return (
    <div>
      <div className="row header-order-list">
        <div className="col-sm-2" style={{ paddingLeft: 0 }}>
          <strong>Order #</strong>
        </div>
        <div className="col-sm-2" style={{ paddingLeft: 0 }}>
          <strong>Job #</strong>
        </div>
        <div className="col-sm-2" style={{ paddingLeft: 0 }}>
          <strong>Rejected Reason</strong>
        </div>
      </div>
      {manuallyRejected.length > 0 ? (
        <div className="block-list-orders">
          {manuallyRejected.map((order, i) => (
            // <div key={i} className="row item-order-list"  style={{ borderBottom: $darkBorder}}>

            <div key={i} className="row item-order-list-with-bottom-border">
              <div className="col-sm-2" style={{ paddingLeft: 0 }}>
                <Link
                  {...{
                    id: order.order_id,
                    to: 'order-details',
                  }}>
                  #{order.order_id}
                </Link>
              </div>
              <div className="col-sm-2" style={{ paddingLeft: 0 }}>
                <Link
                  {...{
                    id: order.job_id,
                    to: 'job-details',
                  }}>
                  #{order.job_id}
                </Link>
              </div>
              <div className="col-sm-8">{order.reason_rejected}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="block-list-orders" style={{ paddingTop: 10 }}>
          <p>No Rejected orders</p>
        </div>
      )}
    </div>
  );
};

export default RejectedOrders;
