import swal from 'bootstrap-sweetalert';

import locationsActions from 'store/constants/Locations';
import { lookUpActions } from 'store/actions/LookUp';
import moment from 'moment';
import { carrier } from 'services/carrier/service';
import sandTicket from 'services/sandTicket/service';
import { order as orderService } from 'services/order/service';
import { driver as driverService } from 'services/driver/service';
import authService from 'services/auth/service';
import { sandSite } from '../../services/sandSite/service';

const deleteLocation = locationId => dispatch => {
  sandSite
    .delete(locationId)
    .then(response => {
      if (location.locationType === 2) {
        dispatch(lookUpActions.deleteL(locationId, 'sandSites'));
      }
      if (location.locationType === 1) {
        dispatch(lookUpActions.deleteL(locationId, 'wellSites'));
      }
      dispatch(lookUpActions.deleteL(locationId, 'locations'));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setLocation = location => ({
  type: locationsActions.LOCATIONS_SET_LOCATION,
  location,
});

const selectLocation = location => dispatch => {
  const set = data => ({
    type: locationsActions.LOCATIONS_SET_VIEW_TO_LOCATION,
    data,
  });
  dispatch(
    set({
      longitude: location.longitude,
      latitude: location.latitude,
      zoom: 8,
    }),
  );
};

const setTimingLocation = id => ({
  type: locationsActions.LOCATIONS_TIMING_SET_SELECTED,
  id,
});

const getTiming = id => dispatch => {
  const data = {
    sandSiteId: id,
  };
  const save = (timing, updatetimings, timeZone) => ({
    type: locationsActions.LOCATIONS_TIMING_GET_LIST,
    timing,
    updatetimings,
    timeZone,
  });
  dispatch(setTimingLocation(id));

  sandSite.timing
    .get(data)
    .then(msg => {
      dispatch(
        save(
          msg.data.data.timing,
          (msg.data.data.timing || []).map(i => ({
            open: moment(i.open_timing, 'HH:mm:ss'),
            close: moment(i.close_timing, 'HH:mm:ss'),
            day: i.day,
            timeZone: i.time_zone,
            exemptions: i.exemptions,
            closed: i.closed,
          })),
          msg.data.data.timing?.[0]?.time_zone ?? 'America/Chicago',
        ),
      );
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setFilterValue = (value, name) => ({
  type: locationsActions.LOCATIONS_SET_FILTER_VALUE,
  value,
  name,
});

const clearLocationStore = () => ({
  type: locationsActions.LOCATIONS_CLEAR_STORE,
});

const parseDelimit = (delimit, dString) => {
  const dArray = dString.split(delimit);
  const dObj = {};
  dArray.forEach((d, i) => {
    if (d === 'Weight') {
      dObj.driverWeightIndex = i;
    }
    if (d === 'Bol') {
      dObj.bolIndex = i;
    }
    if (d === 'Arrival') {
      dObj.arrivalIndex = i;
    }
    if (d === 'Departure') {
      dObj.departureIndex = i;
    }
  });
  dObj.delimiter = delimit;
  return dObj;
};

const getQRTemplate = id => async (dispatch, getState) => {
  dispatch({ type: locationsActions.LOCATIONS_QR_TEMPLATE_REQUESTED });

  const template = await sandSite.qrTemplate.get(id).catch(() =>
    dispatch({
      type: locationsActions.LOCATIONS_QR_TEMPLATE_REQUEST_FAILED,
    }),
  );
  let defaultDelimitState;

  if (!template?.data?.data?.template?.template) {
    return dispatch({
      type: locationsActions.LOCATIONS_QR_TEMPLATE_CLEAR_STATE,
    });
  }

  if (Boolean(template.data.data.template.qrDelimiter) === false) {
    try {
      const parsedTemplate = JSON.parse(template.data.data.template.template);
      return dispatch({
        data: parsedTemplate,
        type: locationsActions.LOCATIONS_QR_TEMPLATE_REQUEST_SUCCEEDED,
      });
    } catch (error) {
      return dispatch({
        type: locationsActions.LOCATIONS_QR_TEMPLATE_CLEAR_STATE,
      });
    }
  } else {
    const parsedDelimitedTemplate = parseDelimit(
      template.data.data.template.qrDelimiter,
      template.data.data.template.template,
    );
    defaultDelimitState = parsedDelimitedTemplate;
    return dispatch({
      data: defaultDelimitState,
      type: locationsActions.LOCATIONS_QR_TEMPLATE_DELIMIT_REQUEST_SUCCEEDED,
    });
  }
};

const addQRTemplate = params => async dispatch => {
  dispatch({
    type: locationsActions.LOCATIONS_QR_TEMPLATE_ADD_REQUESTED,
  });
  const requestData = {
    selectedLocation: params.location,
    template: params.template,
  };
  if (params.delimiter) {
    requestData.delimiter = params.delimiter;
  }
  await sandSite.qrTemplate
    .post(requestData)
    .catch(error =>
      swal(
        error.response.data.message || 'Error adding QR Template',
        '',
        'error',
      ),
    );
};

const clearQRState = () => ({
  type: locationsActions.LOCATIONS_QR_TEMPLATE_CLEAR_STATE,
});

// Add Locations

const setInputValue = (value, name) => ({
  type: locationsActions.LOCATIONS_SET_INPUT_VALUE,
  value,
  name,
});

const clearModalState = () => ({
  type: locationsActions.LOCATIONS_CLEAR_MODAL_ADD_LOCATION,
});

const turnPreview = () => ({
  type: locationsActions.LOCATIONS_TURN_PREVIEW,
});

const initCarriers = dispatch => {
  carrier
    .getCarriers()
    .then(msg => {
      dispatch(setInputValue(msg.data.data.carriers, 'carriers'));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initTicketTypes = dispatch => {
  sandTicket.types
    .get()
    .then(msg => {
      const ticketTypes = msg.data.data.sandTicketTypes
        .map(stt => {
          if (stt.sandTicketTypeId !== 6) {
            return { label: stt.name, value: stt.sandTicketTypeId };
          }
        })
        .filter(e => e);
      dispatch(setInputValue(ticketTypes, 'ticketTypes'));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initRequiredTicketTypes = (locationId, dispatch) => {
  sandSite
    .getSandSiteRequiredTickets(locationId)
    .then(msg => {
      dispatch(
        setInputValue(
          msg.data.data.requiredTicketTypes.map(rtt => rtt.sand_ticket_type_id),
          'requiredTicketTypes',
        ),
      );
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initVendors = (locationId, dispatch) => {
  sandSite
    .getSandSiteVendors(locationId)
    .then(msg => {
      dispatch(
        setInputValue(
          msg.data.data.sandVendors.map(rtt => rtt.sand_vendor_id),
          'vendors',
        ),
      );
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const update = (closeModal, action) => (dispatch, getState) => {
  dispatch(setInputValue(true, 'loading'));
  const state = getState();
  const location = { ...state.locations.modalAddLocation };
  location.contactNo = location.contact_no;
  location.carrierOwnerId =
    location.locationType === 3 ? location.carrierOwnerId : null;
  location.loadSiteSpecialInstruction =
    location.loading_site_special_instruction;
  location.status = Number(location.suspended);
  if (location.vendors) {
    location.vendors = Array.from(
      location.vendors.map(item => {
        if (item.value) {
          return item.value;
        }
        return item;
      }),
    ).filter(sv => sv);
  }
  const submit = {
    add: sandSite.addSandSite,
    edit: sandSite.editSandSite,
  };
  const success = {
    edit: () => {
      if (location.locationType === 2) {
        dispatch(lookUpActions.edit(location, location.id, 'sandSites'));
      }
      if (location.locationType === 1) {
        dispatch(lookUpActions.edit(location, location.id, 'wellSites'));
      }
      dispatch(lookUpActions.edit(location, location.id, 'locations'));
    },
    add: response => {
      const user = authService.getUser();
      const companyName =
        user.entityType !== 6 && user.currentRole
          ? user.currentRole.entityName
          : null;
      if (location.locationType === 2) {
        dispatch(
          lookUpActions.add(
            {
              ...location,
              id: response.data.data.locationId,
              accessLevel: 'owner',
              companyOwnerName: companyName,
              companyOwnerType: user.entityType,
              companyOwnerId: user.entityId,
            },
            'sandSites',
          ),
        );
      }
      if (location.locationType === 1) {
        dispatch(
          lookUpActions.add(
            {
              ...location,
              id: response.data.data.locationId,
              accessLevel: 'owner',
              companyOwnerName: companyName,
              companyOwnerType: user.entityType,
              companyOwnerId: user.entityId,
            },
            'wellSites',
          ),
        );
      }
      dispatch(
        lookUpActions.add(
          {
            ...location,
            id: response.data.data.locationId,
            accessLevel: 'owner',
            companyOwnerName: companyName,
            companyOwnerType: user.entityType,
            companyOwnerId: user.entityId,
          },
          'locations',
        ),
      );
    },
  };
  delete location.carriers;

  submit[action](location)
    .then(response => {
      success[action](response);
      dispatch(setInputValue(false, 'loading'));
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      dispatch(setInputValue(false, 'loading'));
      swal(error?.data?.message || 'Error submitting location.', '', 'error');
    });
};

const updateLocation = (closeModal, action) => (dispatch, getState) => {
  const state = getState();
  const location = { ...state.locations.modalAddLocation };
  const { suspended, id, locationType } = location;
  dispatch(setInputValue(true, 'loading'));
  if (action === 'edit' && suspended && locationType === 3) {
    orderService
      .get({ statuses: [1, 2, 5, 3], sandSiteId: id })
      .then(msg => {
        dispatch(setInputValue(false, 'loading'));
        const filteredOrders = msg.data.data.orders.filter(item =>
          Boolean(item.driver_id),
        );
        if (filteredOrders.length) {
          dispatch(setInputValue(filteredOrders, 'filteredOrders'));
          dispatch(turnPreview());
        } else {
          dispatch(update(closeModal, action));
        }
      })
      .catch(() => dispatch(setInputValue(false, 'loading')));
  } else {
    dispatch(update(closeModal, action));
  }
  dispatch(clearModalState());
};

const informDriver = (item, info) => {
  const text = `Your delivery location has been changed to ${info.destinationName}`;
  driverService.pushNotification({ driverId: item.driver_id, message: text });
};

const reroute = (closeModal, action) => (dispatch, getState) => {
  const state = getState();
  const location = { ...state.locations.modalAddLocation };
  const { filteredOrders } = location;
  const orders = [...filteredOrders];
  orders.forEach(item => {
    orderService
      .reroute(item.order_id)
      .then(msg => {
        const info = msg.data.data;
        const itemIndex = orders.findIndex(
          order => order.order_id === item.order_id,
        );
        orders.splice(itemIndex, 1);
        dispatch(setInputValue(orders, 'filteredOrders'));
        informDriver(item, info);
        if (!orders.length) {
          dispatch(turnPreview());
          dispatch(update(closeModal, action));
        }
      })
      .catch(error => {
        item.errorReroute = error.data.message;
        dispatch(setInputValue(filteredOrders, 'filteredOrders'));
      });
  });
};

const init = locationId => dispatch => {
  initCarriers(dispatch);
  initTicketTypes(dispatch);
  // locationId is there for edit
  // if not there, then set requiredTicketTypes to empty array to satisfy
  // funky multiselect functionality
  if (locationId) {
    initVendors(locationId, dispatch);
    initRequiredTicketTypes(locationId, dispatch);
  } else {
    dispatch(setInputValue([], 'requiredTicketTypes'));
    dispatch(setInputValue([], 'vendors'));
  }
};

const selectCoordinates = (long, lat) => ({
  type: locationsActions.LOCATIONS_SET_LOCATION_COORDINATS,
  long,
  lat,
});

export default {
  deleteLocation,
  setLocation,
  selectLocation,
  getTiming,
  setFilterValue,
  clearLocationStore,
  getQRTemplate,
  addQRTemplate,
  clearQRState,
  setInputValue,
  clearModalState,
  turnPreview,
  updateLocation,
  reroute,
  init,
  selectCoordinates,
};
