import React, { useState, useEffect } from 'react';
import Authorize from 'components/common/Authorize';
import { connect } from 'react-redux';
import Range from 'react-range-progress';
import settingsActions from 'store/actions/AdminSettings';

import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import { Card, Row } from 'react-bootstrap';
import { initMarks, onRangeChanged } from './helpers';

const QueueMark = ({
  settings,
  isLoadedSettings,
  getSettings,
  applySetting,
}) => {
  const [data, initData] = useState({});

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (settings.length) {
      initData(initMarks(settings));
    }
  }, [settings]);

  if (!isLoadedSettings) {
    return <Loader />;
  }

  return (
    <Card>
      <Card.Header as="h5">Choose queue mark</Card.Header>
      <Card.Body className="padding-left padding-right">
        <form
          onSubmit={e =>
            applySetting(e, data.queueTimingMark, data.queueDistanceMark)
          }>
          <Row>
            <div
              className="form-group has-feedback col-md-6"
              data-testid="settings-queue-timing-mark">
              Queue timing mark : {data.queueTimingMark}
            </div>
            <div
              className="form-group has-feedback col-md-6"
              data-testid="settings-queue-distance-mark">
              Queue distance mark : {data.queueDistanceMark}
            </div>
          </Row>
          <Row>
            <div
              className="form-group has-feedback col-md-12"
              data-testid="settings-range">
              <Range
                value={data.queueTimingMark * 100}
                fillColor={{
                  r: 20,
                  g: 150,
                  b: 100,
                  a: 0.75,
                }}
                trackColor={{
                  r: 10,
                  g: 10,
                  b: 0,
                  a: 0.5,
                }}
                height={14}
                width="100%"
                onChange={e => initData(onRangeChanged(e))}
              />
            </div>
          </Row>
          <Authorize
            abilityPermissions={[
              { resource: 'Admin', permissions: ['update'] },
            ]}>
            <div
              className="modal-footer"
              style={{ paging: '0', border: 'none' }}>
              <Button
                type="submit"
                testSelector="admin-settings_queue-mark_save_btn">
                Save
              </Button>
            </div>
          </Authorize>
        </form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  settings: state.adminSettings.currentSettings.settings,
  isLoadedSettings: state.adminSettings.currentSettings.isLoadedSettings,
});

const mapDispatchToProps = dispatch => ({
  getSettings: () => {
    dispatch(settingsActions.getSettings());
  },
  applySetting(e, queueTimingMark, queueDistanceMark) {
    e.preventDefault();
    dispatch(
      settingsActions.applyQueueMark(queueDistanceMark, queueTimingMark),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueueMark);
