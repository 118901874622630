import { useOrderPriceBreakdown } from 'api/v2/orders';
import { useMemo } from 'react';
import { filterBreakdown } from 'utils/paymentItemCalculations';

export const useOrderPricing = orderId => {
  const { data: priceBreakdown } = useOrderPriceBreakdown({ orderId });

  return useMemo(() => {
    if (!priceBreakdown) {
      return {
        filteredBreakdown: []
      };
    }

    return {
      roundedPrice: Math.round((priceBreakdown.total.total ?? 0) * 100) / 100,
      filteredBreakdown: filterBreakdown(priceBreakdown.price),
      carrierPrice: priceBreakdown.total.carrierTotal,
    };
  }, [priceBreakdown]);
};
