import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import PieChart from 'components/shared/PieChart';

const DriverStatistics = ({ drivers }) => {
  const [visible, setVisible] = useState(false);

  const gradients = [
    { match: { id: 'Active' }, id: 'green' },
    { match: { id: 'Inactive' }, id: 'yellow' },
  ];

  const activeDrivers = drivers.filter(driver => !driver.is_deleted).length;
  const inactiveDrivers = drivers.filter(driver => !!driver.is_deleted).length;

  const stats = [
    {
      id: 'Active',
      value: activeDrivers,
    },
    {
      id: 'Inactive',
      value: inactiveDrivers,
    },
  ];

  const total = (
    <div>
      <span>
        <Icon icon="driver" className="driver-stats-pie-icon" />
      </span>
      <p className="driver-stats-pie-count">{drivers.length}</p>
      <p className="driver-stats-pie-text">Drivers</p>
    </div>
  );

  return (
    <div className="driver-stats__body">
      <div className="driver-stats__body__header">
        <h3 className="text-white">Driver stats</h3>
        <span
          data-testid="driver-stats-toggle-driver"
          className={`driver-stats__body__header__text float-end ${visible &&
            'down'}`}
          onClick={() => setVisible(!visible)}>
          <Icon icon="down" />
        </span>
      </div>
      {visible && (
        <div className="driver-stats__body__content">
          <div className="driver-stats__body__content__chart">
            <PieChart
              data={stats}
              gradientFill={gradients}
              innerRadius={0.9}
              size={150}
              total={total}
            />
          </div>
          <div className="driver-stats__body__content__labels">
            <div className="driver-stats__body__content__labels--wrapper">
              <div className="driver-stats__body__content__labels__item">
                <span>
                  <div className="driver-stats__body__content__labels__item--active" />
                  Active
                </span>
                <span
                  className="text-white driver-stats__body__content__labels__item--text"
                  data-testid="driver-stats-active-driers">
                  {activeDrivers}
                </span>
              </div>
              <div className="driver-stats__body__content__labels__item">
                <span>
                  <div className="driver-stats__body__content__labels__item--inactive" />
                  Inactive
                </span>
                <span
                  className="text-white driver-stats__body__content__labels__item--text"
                  data-testid="driver-stats-inactive-driers">
                  {inactiveDrivers}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DriverStatistics);
