import { combineReducers } from '@reduxjs/toolkit';

const initAdd = {
  openModal: false,
  carriersArray: [],
  input: {
    poNo: '',
    approvedMileage: '',
    travelTimeMinutes: '',
    jobId: '',
    customerId: '',
    volume: '',
    dollarsPerTon: 0,
    commodityId: '',
    originId: '',
    destinationId: '',
    priority: '',
    comment: '',
    poReference: '',
    sandVendorId: '',
    commodityUnits: '',
    interstateCertRequired: false,
    schedules: [],
  },
};

export const add = (state = initAdd, action = {}) => {
  const { jobDetails } = action;
  const input = {};

  switch (action.type) {
    case 'JOB_DETAILS_PO_ADD_TURN':
      if (!jobDetails) {
        return {
          ...state,
          openModal: !state.openModal,
        };
      }

      if (jobDetails.operationType === 12) {
        input.originId = jobDetails.location;
      } else {
        input.destinationId = jobDetails.location;
      }
      return {
        ...state,
        input: {
          ...state.input,
          ...input,
        },
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_PO_ADD_INIT':
      return {
        ...state,
        carriersArray: action.carriers.map(i => ({
          carrierId: i.id,
          carrierName: i.name,
          interstateCertified: i.interstateCertified,
        })),
      };
    case 'JOB_DETAILS_PO_ADD_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_PO_ADD_SUCCESS':
      return initAdd;
    case 'JOB_DETAILS_PO_ADD_CLEAR':
      return initAdd;
    default:
      return state;
  }
};

const initUpdate = {
  openModal: false,
  carriersArray: [],
  commodityUnits: 1,
  input: {
    netAmount: '',
    priority: '',
    poNo: '',
    approvedMileage: '',
    travelTimeMinutes: '',
    sandVendorId: '',
    poReference: '',
    // sandSiteId: '',
    // dailyLimit: '',
    enabled: '',
    // limitType: '',
    carriers: '',
    // equipment: '',
    interstateCertRequired: 0,
    dollarsPerTon: 0,
    schedules: [],
  },
};

export const update = (state = initUpdate, action = {}) => {
  const { data } = action;
  switch (action.type) {
    case 'JOB_DETAILS_PO_UPDATE_TURN':
      if (!data) {
        return {
          ...state,
          openModal: !state.openModal,
        };
      }
      return {
        ...state,
        openModal: !state.openModal,
        commodityUnits: data.commodityUnits,
        input: {
          ...state.input,
          poId: data.poId,
          netAmount: data.total,
          priority: data.priority,
          poNo: data.poNo,
          approvedMileage: data.approvedMileage,
          travelTimeMinutes: data.travelTimeMinutes,
          sandVendorId: data.sandVendorId,
          poReference: data.poReference,
          originId: data.originId,
          destinationId: data.destinationId,
          dailyLimit: data.dailyLimit ?? undefined,
          carrierId: data.carrierId ?? undefined,
          enabled: Boolean(data.enabled),
          limitType: data.limitType ?? undefined,
          carriers: data.carriers,
          equipment: data.equipment ?? undefined,
          interstateCertRequired: data.interstateCertRequired ?? 0,
          dollarsPerTon: data.dollarsPerTon ?? 0,
        },
      };
    case 'JOB_DETAILS_PO_UPDATE_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_PO_UPDATE_INIT':
      return {
        ...state,
        carriersArray: action.carriers.map(i => ({
          carrierId: i.id,
          carrierName: i.name,
          interstateCertified: i.interstateCertified,
        })),
      };
    case 'JOB_DETAILS_PO_UPDATE_SUCCESS':
      return initUpdate;
    default:
      return state;
  }
};

const initImport = {
  openModal: false,
  poNumbers: '',
  logisticPoNumbers: '',
  posList: [],
  sandPoError: [],
  logisticPo: [],
  logisticPoError: [],
  showList: false,
  loading: false,
};

export const importPo = (state = initImport, action = {}) => {
  switch (action.type) {
    case 'JOB_DETAILS_IMPORT_PO_ADD_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_IMPORT_PO_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_DETAILS_IMPORT_IMPORT_PO_REQUEST':
      return {
        ...state,
        loading: true,
        showList: true,
      };
    case 'JOB_DETAILS_IMPORT_IMPORT_PO_SUCCESS':
      return {
        ...state,
        posList: action.po,
        poNotFound: action.poNotFound,
        logisticPoError: action.logisticPoError,
        sandPoError: action.sandPoError,
        logisticPo: action.logisticPo,
        loading: false,
      };
    case 'JOB_DETAILS_IMPORT_IMPORT_PO_FAILURE':
      return {
        ...state,
        loading: false,
        showList: false,
      };
    case 'JOB_DETAILS_IMPORT_ADD_PO_SUCCESS':
      return initImport;
    case 'JOB_DETAILS_IMPORT_PO_CLEAR':
      return initImport;
    default:
      return state;
  }
};

export const poModals = combineReducers({
  add,
  update,
  importPo,
});
