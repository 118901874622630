import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import actions from 'store/actions/Equipments';
import Authorize from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Trailer from './Trailers';

const user = authService.getUser();

const Trailers = ({
  clearStoreEquipment,
  getCustomers,
  getTrailers,
  getJobs,
  getVendors,
  getContainerVendors,
  customers,
  isLoadedTrailers,
  trailers,
  totalPages,
  isLoadedCustomers,
  customerId,
  filterNumber,
  currentPage,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    getCustomers();
    if (user.role === ROLES.CUSTOMER) {
      getTrailers({
        type: pathname.split('/')[3] === 'containers' ? 2 : 1,
        withFilter: true,
        page: currentPage - 1,
      });
    }
    getJobs();
    getVendors();
    getContainerVendors();
    return () => clearStoreEquipment();
  }, [
    getCustomers,
    getTrailers,
    getJobs,
    getVendors,
    getContainerVendors,
    clearStoreEquipment,
    pathname,
    currentPage,
  ]);

  useEffect(() => {
    const filter = {
      type: pathname.split('/')[3] === 'containers' ? 2 : 1,
      withFilter: true,
    };
    filter.page = currentPage - 1;
    getTrailers(filter);
  }, [customerId, currentPage, getTrailers, pathname, filterNumber]);

  return (
    (isLoadedCustomers && (
      <div>
        <Header customers={customers} />
        <Authorize
          {...{
            abilityPermissions: [
              {
                resource: 'Trailers',
                permissions: ['read'],
              },
            ],
          }}>
          <Trailer
            customers={customers}
            isLoadedTrailers={isLoadedTrailers}
            trailers={trailers}
            totalPages={totalPages}
          />
        </Authorize>
      </div>
    )) || <Loader />
  );
};

const mapStateToProps = state => ({
  isLoadedCustomers: state.resourceReducer.customers.isLoadedCustomers,
  isLoadedJobs: state.resourceReducer.jobs.isLoadedJobs,
  isLoadedTrailers: state.resourceReducer.trailers.isLoadedTrailers,
  trailers: state.resourceReducer.trailers.trailers,
  totalPages: state.resourceReducer.trailers.totalPages,
  filterNumber: state.resourceReducer.trailers.filterNumber,
  customers: state.resourceReducer.customers.customers,
  jobs: state.resourceReducer.jobs.jobs,
  customerId: state.equipmentTrailers.equipmentStatus.customerId,
  currentPage: state.pagination.pagination.currentPage,
  userId: state.lookUp.user.company.entityId,
});

const mapDispatchToProps = dispatch => ({
  getCustomers: () => dispatch(resourceActions.getCustomers()),
  getJobs: () => dispatch(resourceActions.getJobs()),
  getVendors: () => dispatch(resourceActions.getVendors()),
  getContainerVendors: () => dispatch(resourceActions.getContainerVendors()),
  clearStoreEquipment: () => dispatch(resourceActions.clearStoreEquipment()),
  getTrailers: data => dispatch(resourceActions.getTrailers(data)),
  setCustomerId: id => dispatch(actions.setCustomerId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Trailers);
