import axios from 'axios';

export const tanks = {
  get(jobId) {
    return axios.get(`v2/job/${jobId}/tanks`, {});
  },
  update(data) {
    return axios.put(`v2/job/${data.jobId}/tanks/${data.tankId}`, data);
  },
  add(data) {
    return axios.post(`v2/job/${data.jobId}/tanks`, data);
  },
  remove(data) {
    return axios.delete(`v2/job/${data.jobId}/tanks/${data.tankId}`);
  },
  getHistory(data) {
    return axios.get(`v2/job/${data.jobId}/tanks/${data.tankId}/levels-history`);
  },
};
