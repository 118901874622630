import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { cloneDeep } from 'lodash';
import {
  getCustomerContainers,
  updateCustomerContainerStatus,
} from './queries';

/**
 *
 * @param {{
 *  customerId: number;
 *  params: import('./queries').CustomerContainerParams
 * }} params
 * @returns
 */
export const useCustomerContainers = ({ customerId, params }) =>
  useQuery({
    queryKey: ['customers', 'containers', customerId, params],
    queryFn: () => getCustomerContainers({ customerId, params }),
    placeholderData: [],
    enabled: !!customerId,
  });

export const useUpdateContainerStatus = ({ customerId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['customers', 'containers', customerId],
    mutationFn: container =>
      updateCustomerContainerStatus({ customerId, container }),
    onMutate: async updatedContainer => {
      // Optimistic update
      const queryKeys = [
        'customers',
        'containers',
        customerId,
        { jobId: updatedContainer.jobId, type: 2 },
      ];
      await queryClient.cancelQueries(queryKeys);
      const prevContainers = queryClient.getQueryData(queryKeys);
      queryClient.setQueryData(queryKeys, oldContainers => {
        const newContainers = cloneDeep(oldContainers);
        const foundIndex = newContainers.findIndex(
          i => i.id === updatedContainer.id,
        );
        newContainers[foundIndex].status = updatedContainer.status;
        return newContainers;
      });
      return { prevContainers, queryKeys };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers', 'containers', customerId] });
      swal('Successfully Updated Container Status', '', 'success');
    },
    onError: (error, _new, context) => {
      queryClient.setQueryData(context.queryKeys, context.prevContainers);
      swal(
        'Unable to Update Container Status',
        error?.response?.data?.message,
        'error',
      );
    },
  });
};
