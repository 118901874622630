import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import { OPERATINGTYPE } from 'components/globalInfo/operatingType';

import authService from 'services/auth/service';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/Users';

import { filterUsers } from './helpers';
import AddUser from './AddUser';
import AddRole from './AddRole';
import SelectUser from './SelectUser';
import UserList from './UserList';
import User from './User';

const Users = ({
  getUsers,
  clear,
  selectAction,
  users,
  selectUser,
  selectedUser,
  setFilterValue,
  setFilterSelect,
  filter,
  isLoaded,
  getRoles,
  roles,
}) => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalRole, setShowModalRole] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);

  const userService = authService.getUser();

  useEffect(() => {
    getUsers();
    getRoles(OPERATINGTYPE[userService.role], userService.entityId);
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (roles && roles.length) {
      const tmpOptions = roles.map(item => ({
        value: item.id,
        label: _.startCase(item.name),
      }));
      setRoleOptions(tmpOptions);
    }
  }, [roles]);

  const openAdd = action => {
    setShowModalAdd(true);
    selectAction(action);
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <Header title="Users">
        <br />
        {userService.permissions !== 1 && (
          <span className="text-danger" style={{ fontSize: '17px' }}>
            You are not allowed to manage users due to permissions level. Please
            contact your account Admin
          </span>
        )}
      </Header>

      <Authorize
        abilityPermissions={[{ resource: 'Users', permissions: ['read'] }]}>
        <div style={{ paddingRight: 0, textAlign: 'right' }}>
          <Authorize
            abilityPermissions={[
              { resource: 'Users', permissions: ['create'] },
            ]}>
            <Button
              onClick={() => openAdd('add')}
              inverse
              testSelector="users_add_btn">
              <Icon className="icon--margin-right" icon="plus-square" />
              Add User
            </Button>
          </Authorize>
        </div>
        <div className="wrapper-content animated fadeInRight">
          <Row>
            <UserList
              users={users}
              setFilterValue={setFilterValue}
              selectUser={selectUser}
              filter={filter}
              setFilterSelect={setFilterSelect}
              roleOptions={roleOptions}
            />
            {selectedUser.id ? <User openAdd={openAdd} /> : <SelectUser />}
          </Row>
        </div>
      </Authorize>

      {showModalAdd && (
        <AddUser
          showModal={showModalAdd}
          openModalRole={() => {
            setShowModalRole(true);
          }}
          selectUser={selectUser}
          closeModal={() => {
            setShowModalAdd(false);
          }}
        />
      )}
      {showModalRole && (
        <AddRole
          showModalRole={showModalRole}
          closeModalRole={() => setShowModalRole(false)}
        />
      )}
    </div>
  );
};

Users.defaultProps = {
  users: [],
  selectUser: {},
  selectedUser: {},
  setFilterValue: () => {},
  setFilterSelect: () => {},
  filter: {},
  isLoaded: false,
};

Users.propTypes = {
  /** users array */
  users: PropTypes.array,
  /** selectUser object */
  selectUser: PropTypes.func,
  /** selectedUser function */
  selectedUser: PropTypes.object,
  /** setFilterValue function */
  setFilterValue: PropTypes.func,
  /** setFilterSelect function */
  setFilterSelect: PropTypes.func,
  /** filter object */
  filter: PropTypes.object,
  /** isLoaded boolean */
  isLoaded: PropTypes.bool,
};

const mapStateToProps = state => ({
  users: filterUsers(state),
  filter: state.users.info.filter,
  isLoaded: state.users.info.isLoaded,
  selectedUser: state.users.info.selectedUser,

  roles: state.users.addRole.roles,
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(actions.getUsers()),
  clear: () => dispatch(actions.clear()),
  setFilterSelect: (value, name) =>
    dispatch(actions.setFilterValue(value, name)),
  setFilterValue: (e, name) =>
    dispatch(actions.setFilterValue(targetValue(e), name)),
  selectUser: user => dispatch(actions.selectUser(user)),
  selectAction: action => dispatch(actions.selectAction(action)),

  getRoles: (companyType, entityId) => {
    dispatch(actions.getRoles(companyType, entityId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
