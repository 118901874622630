import swal from 'bootstrap-sweetalert';
import { wellSite } from 'services/wellSite/service';
import { lookUpActions } from './LookUp';

const deleteWellSite = id => dispatch => {
  wellSite
    .deleteWell(id)
    .then(response => {
      dispatch(lookUpActions.deleteL(id, 'wellSites'));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const addEditWellSite = (action, closeModal) => (dispatch, getState) => {
  const {
    manageCustomers: {
      modalWellSite: {
        address,
        city,
        contactPhone,
        customerId,
        latitude,
        longitude,
        name,
        state,
        specialInstructions,
        id,
        geofenceRange,
        email,
      },
    },
  } = getState();
  const data = {
    address,
    city,
    contactPhone,
    customerId,
    latitude,
    longitude,
    name,
    state,
    specialInstructions,
    geofenceRange: Number(geofenceRange),
    email,
  };
  if (action === 'add') {
    data.customerId = customerId;
    wellSite
      .addWell(data)
      .then(() => {
        swal('Successfully created new location.', '', 'success');
        closeModal();
      })
      .then(() => {
        dispatch(lookUpActions.init());
      })
      .catch(error => {
        swal(
          error.response.data.message[0] || 'Something went wrong',
          '',
          'error',
        );
      });
  }
  if (action === 'edit') {
    wellSite
      .editWell(data, id)
      .then(() => {
        closeModal();
        swal('Successfully updated location.', '', 'success');
      })
      .then(() => {
        dispatch(lookUpActions.init());
      })
      .catch(error => {
        swal(
          error.response.data.message || 'Something went wrong',
          '',
          'error',
        );
      });
  }
};

const selectCoordinates = (lng, lat) => ({
  type: 'JOB_MANAGER_SELECT_WELL_SITE_COORDINATES',
  lng,
  lat,
});

const setCustomerName = name => ({ type: 'SET_CUSTOMER_NAME', name });

const clearStoreManageCustomers = () => ({
  type: 'CLEAR_STORE_MANAGE_CUSTOMERS',
});

const setValue = (value, name) => ({
  type: 'JOB_MANAGER_SET_WELL_SITE_VALUE',
  value,
  name,
});

const setWellSite = initWellSite => ({
  type: 'JOB_MANAGER_SET_WELL_SITE',
  initWellSite,
});

const clearModalWellSite = () => ({
  type: 'JOB_MANAGER_CLEAR_MODAL_WELL_SITE',
});

const actions = {
  deleteWellSite,
  addEditWellSite,
  selectCoordinates,
  setCustomerName,
  clearStoreManageCustomers,
  setValue,
  setWellSite,
  clearModalWellSite,
};

export default actions;
