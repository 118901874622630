import React from 'react';

const Carriers = ({ carriers }) => (
  <div className="col-lg-6">
    <div className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>Carriers</h5>
      </div>
      <div className="ibox-content">
        <ul className="stat-list">
          {carriers.map(item => (
            <li key={`carrier-${item.id}`}>
              <h2 className="no-margins">{item.count} orders</h2>
              <small>
                #{item.id} | {item.name}
              </small>
              <div className="stat-percent">{item.percents}%</div>
              <div className="progress progress-mini">
                <div
                  style={{ width: `${item.percents}%` }}
                  className="progress-bar"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);
export default Carriers;
