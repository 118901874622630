/**
 * 
 * @param {*[] | null | undefined} arr 
 * @param {*} value 
 * @param {*} label 
 * @returns 
 */
const multiSelectOptions = (arr, value, label) =>
  (arr || []).map(i => ({
    ...i,
    value: i[value],
    label: i[label],
  }));
export default multiSelectOptions;
