import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Badge from 'components/shared/Badge';
import { ProgressBar } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import ToolTip from 'components/shared/ToolTip';
import moment from 'moment';
import actions from 'store/actions/JobPlans';
import jobDetailsActions from 'store/actions/JobDetails/JobDetails';
import { useParams } from 'react-router-dom';
import Link from 'components/common/Link';
import Authorize, { authorize } from 'components/common/Authorize';
import TodayInfo from 'components/views/JobDetails/TodayInfo';
import { useCustomerCommodities } from 'api/v2/commodities';
import { useJobDetails } from 'api/v2/jobs';

import {
  sortPOsByType,
  determineUnit,
  getLimitBadge,
} from 'components/views/JobDetails/Tabs/PO/helpers';
import { poArrayCombiner } from 'components/views/JobDetails/Tabs/AutoOrder/SettingsModal/hooks';
import Slideout from '../Slideout/Slideout';

import LineGraph from '../Graphs/LineGraph';
import Edit from '../EditJobPlan';
import TruckInfo from './TruckInfo';
import TurnOrders from './TurnOrders';
import HistoryLogs from './HistoryLogs';
import OnsiteStorageLive from '../../JobDetails/Tabs/OnsiteStorageLive/index';
import TurnTime from './TurnTime';

const JobPlanDetails = ({
  getJobPlanData,
  clearJobPlanData,
  clearTurnData,
  jobPlanDetails,
  jobPlanTurnTimeDetails,
  downloadPDF,
  equipmentRequired,
  getDetails,
  clearState,
}) => {
  const [updateVisible, setUpdateVisible] = useState(false);
  const [truckInfoVisible, setTruckInfoVisible] = useState(false);
  const [turnOrdersVisible, setTurnOrdersVisible] = useState(false);
  const [historyLogsVisible, setHistoryLogsVisible] = useState(false);
  const [historyLogsInfo, setHistoryLogsInfo] = useState([]);
  const [historyLogsTitle, setHistoryLogsTitle] = useState('');
  const [purchaseOrders, setPurchaseOrders] = useState('');
  const [slideoutVisible, setSlideoutVisible] = useState(false);
  const { jobPlanId } = useParams();
 

  const { data: jobDetails, isLoading: jobDetailsIsLoading } = useJobDetails({
    jobId: jobPlanDetails.jobId,
    autoRefetch: false,
  });
  
  const { data: customerCommodities } = useCustomerCommodities(jobDetails?.details?.customerId);

  const getCustomerCommodityDetails = (id) => {
    if (id && customerCommodities) {
      const commodity = customerCommodities.find(c => c.id === id);
      return commodity ? commodity.unitOfMeasureName : '';
    }
    return '';
  }
  const {
    trucksRunningBreakdown,
    graphData,
    billableTimeBand,
  } = jobPlanDetails;
  const { billableOrderCounts } = jobPlanTurnTimeDetails;
  const isCompleted = jobPlanDetails?.jobCompletedAt
    ? moment(jobPlanDetails.jobCompletedAt).isBefore(moment())
    : true;

  const orders = jobPlanDetails?.purchaseOrders
    ? jobPlanDetails.purchaseOrders
    : [];
  useEffect(() => {
    getJobPlanData({ id: jobPlanId });
    return () => {
      clearJobPlanData();
      clearTurnData();
      clearState();
    };
  }, []);
  useEffect(() => {
    if (jobPlanDetails?.jobId) {
      getDetails(jobPlanDetails?.jobId);
    }
  }, [jobPlanDetails.jobId]);

  useEffect(() => {
    if (jobPlanDetails && jobPlanDetails.purchaseOrders.length) {
      setPurchaseOrders(
        sortPOsByType(poArrayCombiner(jobPlanDetails.purchaseOrders)),
      );
    }
  }, [jobPlanDetails.purchaseOrders]);

  if (!jobPlanDetails) {
    return <Loader />;
  }

  return (
    <>
      <div className="flexBetweenBaseline">
        <Link
          {...{
            id: jobPlanDetails?.jobId,
            to: 'job-details',
          }}>
          <h2>
            Job #{jobPlanDetails?.jobId}:
            <span style={{ color: '#18a689' }}> {jobPlanDetails?.jobName}</span>
          </h2>
        </Link>
        {/* <span className="badge">{`title`}</span> */}
        <div>
          {authorize({
            abilityPermissions: [
              {
                resource: 'Billable-orders',
                permissions: ['read'],
              },
            ],
          }) && (
              <>
                <ToolTip
                  title="Number of orders that passed notarization"
                  placement="bottom">
                  <Badge type="darkGreen">{`Passed: ${billableOrderCounts?.closedPassed
                    ? billableOrderCounts?.closedPassed
                    : 0
                    }`}</Badge>
                </ToolTip>

                <ToolTip
                  title="Number of orders that are expected to pass notarization"
                  placement="bottom">
                  <Badge type="green">{`Active passing: ${billableOrderCounts?.activePassing
                    ? billableOrderCounts?.activePassing
                    : 0
                    }`}</Badge>
                </ToolTip>

                <ToolTip
                  title="Number of orders that are at risk of failing notarization"
                  placement="bottom">
                  <Badge type="orange">{`Active at risk: ${billableOrderCounts?.activeAtRisk
                    ? billableOrderCounts?.activeAtRisk
                    : 0
                    }`}</Badge>
                </ToolTip>

                <ToolTip
                  title="Number of orders that have failed notarization"
                  placement="bottom">
                  <Badge type="red">{`Failed: ${billableOrderCounts?.closedFailed
                    ? billableOrderCounts?.closedFailed
                    : 0
                    }`}</Badge>
                </ToolTip>
              </>
            )}

          {authorize({
            abilityPermissions: [
              {
                resource: 'Billable-orders',
                permissions: ['read'],
              },
            ],
          }) && (
              <Button
                onClick={() => setSlideoutVisible(true)}
                testSelector="jobPlanDetails_billable-orders_btn">
                Billable Orders
              </Button>
            )}
          {isCompleted && (
            <ToolTip title="Download">
              <Button
                testSelector="jobPlan-details_download_btn"
                onClick={() => downloadPDF(jobPlanId)}>
                <Icon icon="download" />
              </Button>
            </ToolTip>
          )}
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Job-plans',
                  permissions: ['update'],
                },
              ],
            }}>
            <Button
              onClick={() => setUpdateVisible(true)}
              inverse
              testSelector="jobPlanDetails_update_btn">
              Update
            </Button>
          </Authorize>
        </div>
      </div>
      <div className="ibox-content marginTop40">
        <div className="flexBetween flexWrap">
          <div className="flex20 noWhitespaceWrap padding5">
            Wellsite: <strong>{`${jobPlanDetails?.wellsiteName || ''}`}</strong>{' '}
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Latitude: <strong>{`${jobPlanDetails?.wellsiteLat || ''}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Longitude:{' '}
            <strong>{`${jobPlanDetails?.wellsiteLong || ''}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Pump Time: <strong>{`${jobPlanDetails?.pumpTime || ''}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Wireline Time:{' '}
            <strong>{`${jobPlanDetails?.wirelineTime || ''}`}</strong>
          </div>

          <div className="flex20 noWhitespaceWrap padding5">
            Stages: <strong>{`${jobPlanDetails?.jobStageCount || 0}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Target Stages:{' '}
            <strong>{`${jobPlanDetails?.targetStagesPerDay || ''}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Start:{' '}
            <strong>{`${moment(jobPlanDetails?.jobStartDate).format(
              'MM/DD/YYYY',
            ) || ''}`}</strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            Total Weight:{' '}
            <strong>
              {`${jobPlanDetails?.jobTotalWeight
                ? `${jobPlanDetails?.jobTotalWeight.toLocaleString()} ${determineUnit(
                  Number(jobPlanDetails?.commodityUnits),
                )}`
                : ''
                }`}
            </strong>
          </div>
          <div className="flex20 noWhitespaceWrap padding5">
            District:{' '}
            <strong>
              {jobPlanDetails?.districts
                .map(
                  district =>
                    `#${district.districtId} ${district.districtName}`,
                )
                .join(', ')}
            </strong>
          </div>
        </div>
      </div>
      {jobDetailsIsLoading ? (<Loader />) : (<TodayInfo stats={jobDetails.stats} isStagingSite={!!jobDetails.details?.stagingSite} />)}
      <div className="padding10">
        <h3>PO</h3>
        {purchaseOrders.length === 0 ? (
          <p>You have no Purchase Orders yet</p>
        ) : (
          <div>
            <div>
              {sortPOsByType(purchaseOrders).map((data, index) => (
                <div
                  key={`${data.poNo}${index}`}
                  className={!data.enabled ? 'disabled-po' : ''}>
                  <div>
                    <h4>
                      {data.priority} - {data.originName} ({data.sandType}) #
                      {data.poNo} -{' '}
                      {`${data.total.toLocaleString(
                        navigator.language,
                      )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`}
                    </h4>
                    {!!data.equipment && (
                      <span className="badge badge-success">
                        {equipmentRequired.map(
                          item =>
                            Number(item.id) === Number(data.equipment) &&
                            item.title,
                        )}
                      </span>
                    )}
                    {!data.enabled && <Badge type="red">Disabled</Badge>}
                    {!!data.interstateCertRequired && (
                      <Badge type="purple">Interstate Cert Required</Badge>
                    )}
                    <br />
                    {data.sandVendorName !== null && (
                      <span>
                        <strong>Sand Vendor: </strong>
                        <span>{data.sandVendorName} </span>
                        <strong>- Reference: </strong>
                        <span>{data.poReference}</span>
                      </span>
                    )}
                    {data.dailyLimit > 0 &&
                      data.dailyLimit <= data.usedForLast24Hours && (
                        <Badge type="danger">daily limit exceeded</Badge>
                      )}
                    {!!data.limitType && data.dailyLimit > 0 && (
                      <Badge type="info">{getLimitBadge(data.limitType)}</Badge>
                    )}
                  </div>
                  <div className="panel panel-default">
                    <div
                      className="panel-heading"
                      style={{
                        padding: 0,
                        borderBottom: 'none',
                        paddingBottom: 10,
                      }}>
                      <span className="panel-title marginTop0">
                        <a
                          data-bs-toggle="collapse"
                          data-parent="#accordion"
                          href={`#collapse-po-bar${index}`}>
                          <ProgressBar
                            style={{ cursor: 'pointer', marginTop: 10 }}>
                            <ProgressBar
                              now={data.deliveredPercentage}
                              variant="danger"
                              label={
                                data.deliveredPercentage > 6 && 'delivered'
                              }
                            />
                            <ProgressBar
                              now={data.loadedPercentage}
                              variant="info"
                              label={data.loadedPercentage > 6 && 'loaded'}
                            />
                            <ProgressBar
                              now={data.scheduledPercentage}
                              variant="success"
                              label={
                                data.scheduledPercentage > 6 && 'scheduled'
                              }
                            />
                            <ProgressBar
                              now={data.leftPercentage}
                              variant="warning"
                              label={data.leftPercentage > 6 && 'remaining'}
                            />
                          </ProgressBar>
                        </a>
                      </span>
                    </div>
                    <div
                      id={`collapse-po-bar${index}`}
                      className="panel-collapse collapse">
                      <div className="panel-body" style={{ paddingTop: 0 }}>
                        <div>
                          <span>
                            Delivered:{' '}
                            <b>{`${data.delivered.toLocaleString(
                              navigator.language,
                            )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`}</b>
                            <span>; </span>
                            Loaded:{' '}
                            <b>{`${data.loaded.toLocaleString(
                              navigator.language,
                            )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`}</b>
                            <br />
                            Scheduled:{' '}
                            <b>{`${data.scheduled.toLocaleString(
                              navigator.language,
                            )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`}</b>
                            <span>; </span>
                            Remaining:{' '}
                            <b>{`${data.left.toLocaleString(
                              navigator.language,
                            )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`}</b>
                            <br />
                            Mileage: <b>{data.approvedMileage}</b>
                            <span>; </span>
                            Travel time: <b>{data.travelTimeMinutesView}</b>
                            <br />
                            Carriers:
                            {data.carriers.length ? (
                              data.carriers.map(
                                item =>
                                  item.carrierId && (
                                    <b key={`carrier-${item.carrierId}`}>
                                      {` #${item.carrierId} | ${item.carrierName
                                        };`}
                                    </b>
                                  ),
                              )
                            ) : (
                              <b> All</b>
                            )}
                            <br />
                            {data.comment && (
                              <span>
                                Comment: <b>{data.comment}</b>
                              </span>
                            )}
                            <br />
                            Daily Limit:{' '}
                            <b>
                              {data.dailyLimit > 0
                                ? `${data.dailyLimit.toLocaleString(
                                  navigator.language,
                                )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`
                                : 'n/a'}
                            </b>
                            <span>; </span>
                            Amount Loaded Against Limit:{' '}
                            <b>
                              {data.dailyLimit > 0
                                ? `${data.usedForLast24Hours.toLocaleString(
                                  navigator.language,
                                )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`
                                : 'n/a'}
                            </b>
                            <span>; </span>
                            Amount Remaining on Limit:{' '}
                            <b>
                              {data.dailyLimit > 0
                                ? data.dailyLimit - data.usedForLast24Hours > 0
                                  ? `${(
                                    data.dailyLimit - data.usedForLast24Hours
                                  ).toLocaleString(
                                    navigator.language,
                                  )} ${data?.sandTypeId ? getCustomerCommodityDetails(data?.sandTypeId) : ''}`
                                  : 0
                                : 'n/a'}
                            </b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          padding: '10px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            flex: '1 0 30%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div className="flexColumn">
            <div className="flex">
              <div>Tons/Stage</div>
              <div className="paddingLeft5">
                <Button
                  onClick={() => {
                    setHistoryLogsVisible(true);
                    setHistoryLogsTitle('Tons/Stage');
                    if (jobPlanDetails?.historyLogs?.tons_per_stage?.length) {
                      setHistoryLogsInfo(
                        jobPlanDetails?.historyLogs?.tons_per_stage,
                      );
                    } else {
                      setHistoryLogsInfo([]);
                    }
                  }}
                  theme="small">
                  <Icon icon="clock" />
                </Button>
              </div>
            </div>
            <div className="text-navy-bold-centered">{`${jobPlanDetails?.tonsPerStage
              }`}</div>
          </div>
        </div>

        <div
          style={{
            flex: '1 0 30%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div className="flexColumn">
            <div className="flex">
              <div>Tons/Day</div>
              <div className="paddingLeft5">
                <Button
                  onClick={() => {
                    setHistoryLogsVisible(true);
                    setHistoryLogsTitle('Tons/Day');
                    if (jobPlanDetails?.historyLogs?.tons_per_day?.length) {
                      setHistoryLogsInfo(
                        jobPlanDetails?.historyLogs?.tons_per_day,
                      );
                    } else {
                      setHistoryLogsInfo([]);
                    }
                  }}
                  theme="small">
                  <Icon icon="clock" />
                </Button>
              </div>
            </div>
            <div className="text-navy-bold-centered">{`${jobPlanDetails?.tonsPerDay
              }`}</div>
          </div>
        </div>

        <div
          style={{
            flex: '1 0 30%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div className="flexColumn">
            <div className="flex">
              <div>Loads/Day</div>
            </div>
            <div className="text-navy-bold-centered">{`${jobPlanDetails?.loadsPerDay
              }`}</div>
          </div>
        </div>
      </div>

      <TurnTime />

      <div className="padding10">
        <div className="ibox-content">
          <div className="row">
            <OnsiteStorageLive
              jobId={jobPlanDetails.jobId}
              hideStorageRules
            />
          </div>
        </div>
      </div>

      <div className="padding10">
        <div className="ibox-content">
          <div className="row">
            <LineGraph graphData={graphData} />
          </div>
        </div>
      </div>

      {updateVisible && (
        <Edit
          jobPlanId={jobPlanId}
          isVisible={updateVisible}
          closeEdit={() => {
            setUpdateVisible(false);
          }}
        />
      )}
      {truckInfoVisible && (
        <TruckInfo
          isVisible={truckInfoVisible}
          setIsVisible={setTruckInfoVisible}
          info={trucksRunningBreakdown?.length ? trucksRunningBreakdown : []}
        />
      )}
      {turnOrdersVisible && (
        <TurnOrders
          isVisible={turnOrdersVisible}
          setIsVisible={setTurnOrdersVisible}
          info={
            jobPlanDetails?.turnOrders?.length ? jobPlanDetails?.turnOrders : []
          }
        />
      )}
      {historyLogsVisible && (
        <HistoryLogs
          isVisible={historyLogsVisible}
          setIsVisible={setHistoryLogsVisible}
          title={historyLogsTitle}
          info={historyLogsInfo?.length ? historyLogsInfo : []}
        />
      )}
      <Slideout
        isVisible={slideoutVisible}
        setSlideoutVisible={setSlideoutVisible}
        billableTimeBand={billableTimeBand}
        orders={orders}
        jobId={jobPlanDetails?.jobId}
        jobName={jobPlanDetails?.jobName}
        jobPlanId={jobPlanId}
      />
    </>
  );
};

const mapStateToProps = state => ({
  equipmentRequired: state.lookUp.equipmentRequired,
  jobPlanDetails: state.jobPlans.jobPlanInfo.jobPlanDetails,
  jobPlanTurnTimeDetails: state.jobPlans.jobPlanTurnTimeDetails,
});

const mapDispatchToProps = dispatch => ({
  getJobPlanData: data => {
    dispatch(actions.getJobPlanData(data));
  },
  getDetails: jobId => {
    dispatch(jobDetailsActions.getDetails(jobId));
  },
  clearState: () => dispatch(jobDetailsActions.clearState()),
  clearJobPlanData: () => dispatch(actions.clearJobPlanData()),
  clearTurnData: () => dispatch(actions.clearTurnData()),
  downloadPDF: jobPlanId => dispatch(actions.downloadPDF(jobPlanId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobPlanDetails);
