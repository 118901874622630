import React, { useState, useEffect } from 'react';
import Link from 'components/common/Link';
import ToolTip from 'components/shared/ToolTip';
import Badge from 'components/shared/Badge';
import Authorize from 'components/common/Authorize';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  downloadLinkRequested,
  downloadInvoiceFile,
  invoicePreviewRequested,
} from 'store/actions/Invoices';
import { connect } from 'react-redux';
import DownloadLinkModal from './DownloadLinkModal';
import InvoiceEmailModal from './InvoiceEmailModal';
import InvoiceBreakdownActionButtons from './InvoiceBreakdownActionButtons';

const InvoiceBreakdown = ({
  customerInvoice,
  carrierInvoices,
  getDownloadLinkFunc,
  downloadInvoiceFileFunc,
  getInvoicePreview,
  setCurrentTab,
  user,
}) => {
  const [currentInvoice, setCurrentInvoice] = useState({});
  const [invoiceEmailModal, setInvoiceEmailModal] = useState(false);
  const [downloadLinkModal, setDownloadLinkModal] = useState(false);

  useEffect(() => {
    setCurrentTab('breakdown');
  }, []);

  useEffect(() => {
    if (customerInvoice) {
      setCurrentInvoice(customerInvoice);
    }
  }, [customerInvoice]);

  const carrierInvoicesList = carrierInvoices.orders;
  const groupedOrders = _.groupBy(carrierInvoicesList, 'carrier_id');

  const [carrierId, setCarrierId] = useState(null);
  const [link, setLink] = useState('');

  const handleCarrierId = (carrierID, type) => {
    setCarrierId(carrierID);
    if (type === 'copy') {
      setDownloadLinkModal(true);
      getDownloadLinkFunc(currentInvoice.id, carrierID).then(link => {
        setLink(link.data.data.downloadLink);
      });
    } else {
      setInvoiceEmailModal(true);
    }
  };

  const handleCloseModal = () => {
    setInvoiceEmailModal(false);
    setDownloadLinkModal(false);
    setCarrierId(null);
  };

  const customerDownloadOptions = [
    {
      name: 'packet',
      label: 'Packet Invoice',
      onClick: () =>
        downloadInvoiceFileFunc(
          currentInvoice.id,
          'packetinvoice',
          'customer',
          null,
        ),
      dropdownIcon: 'download',
    },
    {
      name: 'bulk',
      label: 'Bulk Invoice',
      onClick: () =>
        downloadInvoiceFileFunc(
          currentInvoice.id,
          'bulkinvoice',
          'customer',
          null,
        ),
      dropdownIcon: 'download',
    },
    {
      name: 'csv',
      label: 'CSV Invoice',
      onClick: () =>
        downloadInvoiceFileFunc(
          currentInvoice.id,
          'csvinvoice',
          'customer',
          null,
        ),
      dropdownIcon: 'download',
    },
  ];
  return (
    <div className="invoice-breakdown">
      <Authorize {...{ forbidden: ['CARRIER'] }}>
        <div className="invoice-breakdown__section">
          {(Object.keys(currentInvoice).length > 0 && (
            <>
              <h4>Customer Invoice</h4>
              <ul className="breakdown-list">
                <li className="breakdown-list--item">
                  <span>
                    <span>
                      <span className="item--bold-white">
                        #
                        <Link
                          {...{
                            id: currentInvoice.customer_id,
                            to: 'customer-details',
                          }}>
                          {currentInvoice.customer_id}
                        </Link>{' '}
                        | {currentInvoice.customer_name}
                      </span>
                      {currentInvoice.creditMemos &&
                        currentInvoice.creditMemos.length > 0 && (
                          <ToolTip
                            title={`Credit Memo IDs: ${currentInvoice.creditMemos
                              .map(cm => cm.id)
                              .join(', ')}`}>
                            <Badge type="red" children="Credited" />
                          </ToolTip>
                        )}
                    </span>
                    <div>Total orders: {currentInvoice.total_orders}</div>
                  </span>
                  <InvoiceBreakdownActionButtons
                    downloadOptions={customerDownloadOptions}
                    currentInvoice={currentInvoice}
                    handleCarrierId={handleCarrierId}
                    getInvoicePreview={getInvoicePreview}
                    carrierId={carrierId}
                  />
                </li>
              </ul>
            </>
          )) || <h4>No customer invoice found.</h4>}
        </div>
      </Authorize>
      <div className="invoice-breakdown__section">
        {(customerInvoice &&
          customerInvoice.orderTotalsByCarrier &&
          customerInvoice.orderTotalsByCarrier.length > 0 && (
            <>
              <h4>Carrier Invoices</h4>
              <ul className="breakdown-list">
                {customerInvoice.orderTotalsByCarrier.map(item => {
                  let subTotal = 0;
                  if (user.company.roleId === 6 && item.customerPrice) {
                    subTotal = item.customerPrice;
                  }
                  const total = item.totalPrice;
                  return (
                    <li key={item.carrierId} className="breakdown-list--item">
                      <span>
                        <span>
                          <span className="item--bold-white">
                            #{item.carrierId} | {item.carrierName}
                          </span>
                          {item.carrierCreditMemos &&
                            item.carrierCreditMemos.length > 0 && (
                              <ToolTip
                                title={`Credit Memo IDs: ${item.carrierCreditMemos
                                  .map(cm => cm.creditMemoId)
                                  .join(', ')}`}>
                                <Badge type="red" children="Credited" />
                              </ToolTip>
                            )}
                        </span>
                        <div>Orders: {item.totalOrders}</div>
                      </span>
                      <span>
                        <Authorize {...{ forbidden: ['CUSTOMER', 'CARRIER'] }}>
                          {!!subTotal && <div>${subTotal.toFixed(2)} </div>}
                        </Authorize>
                        <div>
                          Total:{' '}
                          <span className="item--bold-white">
                            ${total.toFixed(2)}
                          </span>
                        </div>
                      </span>
                      <InvoiceBreakdownActionButtons
                        downloadOptions={[
                          {
                            name: 'packet',
                            label: 'Packet Invoice',
                            onClick: () =>
                              downloadInvoiceFileFunc(
                                currentInvoice.id,
                                'packetinvoice',
                                'customer',
                                item.carrierId,
                              ),
                            dropdownIcon: 'download',
                          },
                          {
                            name: 'bulk',
                            label: 'Bulk Invoice',
                            onClick: () =>
                              downloadInvoiceFileFunc(
                                currentInvoice.id,
                                'bulkinvoice',
                                'customer',
                                item.carrierId,
                              ),
                            dropdownIcon: 'download',
                          },
                          {
                            name: 'csv',
                            label: 'CSV Invoice',
                            onClick: () =>
                              downloadInvoiceFileFunc(
                                currentInvoice.id,
                                'csvinvoice',
                                'customer',
                                item.carrierId,
                              ),
                            dropdownIcon: 'download',
                          },
                        ]}
                        handleCarrierId={handleCarrierId}
                        getInvoicePreview={getInvoicePreview}
                        currentInvoice={currentInvoice}
                        carrierId={item.carrierId}
                        groupedOrders={item}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          )) || <h4>No carrier invoices found.</h4>}
      </div>
      {downloadLinkModal && (
        <DownloadLinkModal
          link={link}
          open={downloadLinkModal}
          onClose={() => handleCloseModal()}
        />
      )}
      {invoiceEmailModal && (
        <InvoiceEmailModal
          carrierId={carrierId}
          customerInvoice={customerInvoice}
          open={invoiceEmailModal}
          onClose={() => handleCloseModal()}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getDownloadLinkFunc: (invoiceId, carrierId) =>
    dispatch(downloadLinkRequested(invoiceId, carrierId)),
  downloadInvoiceFileFunc: (invoiceId, type, role, carrierID) =>
    dispatch(downloadInvoiceFile(invoiceId, type, role, carrierID)),
  getInvoicePreview: (invoiceId, carrierId) =>
    dispatch(invoicePreviewRequested(invoiceId, carrierId)),
});

InvoiceBreakdown.propTypes = {
  customerInvoice: PropTypes.object,
  carrierInvoices: PropTypes.object,
};

InvoiceBreakdown.defaultProps = {
  customerInvoice: {},
  carrierInvoices: {},
};

const mapStateToProps = state => {
  return {
    user: state.lookUp.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceBreakdown);
