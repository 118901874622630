import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Loader from 'components/shared/Loader';
import Select from 'components/shared/Select';
import actions from 'store/actions/JobPlans';
import { useParams } from 'react-router-dom';
import TruckInfo from './TruckInfo';
import TurnOrders from './TurnOrders';
import HistoryLogs from './HistoryLogs';

const TurnTime = ({
  getJobPlanData,
  clearTurnDataNotFilters,
  jobPlanTurnTimeDetails,
  setFilterValue,
}) => {
  const [truckInfoVisible, setTruckInfoVisible] = useState(false);
  const [turnOrdersVisible, setTurnOrdersVisible] = useState(false);
  const [historyLogsVisible, setHistoryLogsVisible] = useState(false);
  const [historyLogsInfo, setHistoryLogsInfo] = useState([]);
  const [historyLogsTitle, setHistoryLogsTitle] = useState('');

  const { jobPlanId } = useParams();
  const {
    turnData,
    trucksRunningBreakdown,
    posFilter,
    carrierFilter,
    driverFilter,
  } = jobPlanTurnTimeDetails;

  const POoptions = jobPlanTurnTimeDetails?.purchaseOrders?.length
    ? jobPlanTurnTimeDetails?.purchaseOrders.map(o => ({
        value: o.poId,
        label: o.poNo,
      }))
    : [];
  const driverOptions = jobPlanTurnTimeDetails?.turnFilterData?.drivers?.length
    ? jobPlanTurnTimeDetails?.turnFilterData?.drivers.map(d => ({
        value: d.id,
        label: d.name,
      }))
    : [];
  const carrierOptions = jobPlanTurnTimeDetails?.turnFilterData?.carriers
    ?.length
    ? jobPlanTurnTimeDetails?.turnFilterData?.carriers.map(d => ({
        value: d.id,
        label: d.name,
      }))
    : [];

  useEffect(() => {
    getJobPlanData({
      id: jobPlanId,
    });

    const gid = setInterval(() => {
      getJobPlanData({
        id: jobPlanId,
      });
    }, 60000);

    return () => {
      clearInterval(gid);
      clearTurnDataNotFilters();
    };
  }, [posFilter, carrierFilter, driverFilter]);

  if (!jobPlanTurnTimeDetails) {
    return <Loader />;
  }

  return (
    <>
      <div className="padding10">
        <div className="ibox-content">
          <div className="flexColumn">
            <div className="flexBetweenBaseline">
              <div className="flex">
                <h3>Turn Time</h3>
                <div className="marginLeft5">
                  <Icon
                    icon="info-circle"
                    className="text-navy cursorPointer"
                    onClick={() => setTurnOrdersVisible(true)}
                  />
                </div>
              </div>
              <div className="flex">
                <div>Load History: </div>
                <div className="text-navy-bold-centered marginLR5">{`${
                  jobPlanTurnTimeDetails?.loadHistory
                } hours`}</div>
                <Button
                  onClick={() => {
                    setHistoryLogsVisible(true);
                    setHistoryLogsTitle('Load History');
                    if (
                      jobPlanTurnTimeDetails?.historyLogs?.load_history_range
                        ?.length
                    ) {
                      setHistoryLogsInfo(
                        jobPlanTurnTimeDetails?.historyLogs?.load_history_range,
                      );
                    } else {
                      setHistoryLogsInfo([]);
                    }
                  }}
                  theme="small">
                  <Icon icon="clock" />
                </Button>
              </div>
              <div className="flex">
                <div>Load Refresh: </div>
                <div className="text-navy-bold-centered marginLR5">{`${
                  jobPlanTurnTimeDetails?.refreshRate
                } minutes`}</div>
                <Button
                  onClick={() => {
                    setHistoryLogsVisible(true);
                    setHistoryLogsTitle('Load Refresh');
                    if (
                      jobPlanTurnTimeDetails?.historyLogs?.load_refresh_time
                        ?.length
                    ) {
                      setHistoryLogsInfo(
                        jobPlanTurnTimeDetails?.historyLogs?.load_refresh_time,
                      );
                    } else {
                      setHistoryLogsInfo([]);
                    }
                  }}
                  theme="small">
                  <Icon icon="clock" />
                </Button>
              </div>
            </div>
            <div className="flex flex30">
              <div className="width100 marginLeft5">
                <Select
                  placeholder="PO"
                  value={posFilter}
                  isMulti
                  options={POoptions}
                  onChange={selection => {
                    setFilterValue(
                      Array.isArray(selection) ? selection : [selection.label],
                      'posFilter',
                    );
                  }}
                />
              </div>
              <div className="width100 marginLeft5">
                <Select
                  placeholder="Carrier"
                  value={carrierFilter}
                  isMulti
                  options={carrierOptions}
                  onChange={selection => {
                    setFilterValue(
                      Array.isArray(selection) ? selection : [selection.value],
                      'carrierFilter',
                    );
                  }}
                />
              </div>
              <div className="width100 marginLeft5">
                <Select
                  placeholder="Driver"
                  value={driverFilter}
                  isMulti
                  options={driverOptions}
                  onChange={selection => {
                    setFilterValue(
                      Array.isArray(selection) ? selection : [selection.value],
                      'driverFilter',
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {!turnData || !turnData?.length ? (
            <Loader />
          ) : (
            <div className="paddingTop10">
              {turnData.map((data, index) => (
                <div
                  key={`turnData${data.id} ${index}`}
                  className="padding10 flexBetween flexNoWrap">
                  <div className="flex50 flex">
                    <div className="noWhitespaceWrap">{data?.startLabel}</div>
                    <div
                      style={{
                        width: '100%',
                        marginRight: '20px',
                        marginLeft: '20px',
                        height: 'auto',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        clipPath:
                          'polygon(0 45%, 95% 45%, 95% 30%, 100% 50%, 95% 70%, 95% 55%, 0 55%)',
                      }}
                    />
                  </div>
                  <div className="flex25">
                    <div>{data?.endLabel}</div>
                  </div>
                  <div className="flex25">
                    <strong className="text-navy">{`${
                      data?.averageTurnTime
                        ? data?.averageTurnTime.toFixed(2)
                        : '0'
                    } min`}</strong>
                  </div>
                </div>
              ))}
              <div className="padding10 flexBetween flexNoWrap">
                <div className="flex50" />

                <div className="flex25">
                  <div>Total:</div>
                </div>
                <div className="flex25">
                  <strong className="text-navy">{`${
                    jobPlanTurnTimeDetails?.totalTurnTime
                      ? jobPlanTurnTimeDetails?.totalTurnTime.toFixed(2)
                      : '0'
                  } min`}</strong>
                </div>
              </div>
            </div>
          )}

          <div className="flexBetween flexWrap padding2010">
            <div className="flex25 flexCenter">
              <div className="flexColumn">
                <div>Turns/Day</div>
                <div className="text-navy-bold-centered">{`${
                  jobPlanTurnTimeDetails?.turnsPerDay ? jobPlanTurnTimeDetails?.turnsPerDay : 0
                }`}</div>
              </div>
            </div>

            <div className="flex25 flexCenter">
              <div className="flexColumn">
                <div>Trucks/Day</div>
                <div className="text-navy-bold-centered">{`${
                  jobPlanTurnTimeDetails?.trucksNeededPerDay ? jobPlanTurnTimeDetails?.trucksNeededPerDay : 0
                }`}</div>
              </div>
            </div>

            <div className="flex25 flexCenter">
              <div className="flexColumn">
                <div>
                  Current Trucks{' '}
                  <Icon
                    icon="info-circle"
                    className="text-navy cursorPointer"
                    onClick={() => setTruckInfoVisible(true)}
                  />
                </div>
                <div className="text-navy-bold-centered">{`${
                  jobPlanTurnTimeDetails?.currentTrucksRunning ? jobPlanTurnTimeDetails?.currentTrucksRunning : 0
                }`}</div>
              </div>
            </div>

            <div className="flex25 flexCenter">
              <div className="flexColumn">
                <div>Truck Deficit</div>
                <div className="text-navy-bold-centered">{`${
                  jobPlanTurnTimeDetails?.trucksDeficit ? jobPlanTurnTimeDetails?.trucksDeficit : 0
                }`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {turnOrdersVisible && (
        <TurnOrders
          isVisible={turnOrdersVisible}
          setIsVisible={setTurnOrdersVisible}
          info={
            jobPlanTurnTimeDetails?.turnOrders?.length
              ? jobPlanTurnTimeDetails?.turnOrders
              : []
          }
        />
      )}
      {truckInfoVisible && (
        <TruckInfo
          isVisible={truckInfoVisible}
          setIsVisible={setTruckInfoVisible}
          info={trucksRunningBreakdown?.length ? trucksRunningBreakdown : []}
        />
      )}
      {historyLogsVisible && (
        <HistoryLogs
          isVisible={historyLogsVisible}
          setIsVisible={setHistoryLogsVisible}
          title={historyLogsTitle}
          info={historyLogsInfo?.length ? historyLogsInfo : []}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  jobPlanTurnTimeDetails: state.jobPlans.jobPlanTurnTimeDetails,
});

const mapDispatchToProps = dispatch => ({
  getJobPlanData: data => dispatch(actions.getJobPlanData(data, true)),
  clearTurnData: () => dispatch(actions.clearTurnData()),
  clearTurnDataNotFilters: () => dispatch(actions.clearTurnDataNotFilters()),
  setFilterValue: (v, name) => dispatch(actions.setFilterValue(v, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TurnTime);
