import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import multiSelectOptions from 'utils/multiSelectOptions';
import Input, { Textarea } from 'components/shared/Input';
import MapAddLocation from 'components/views/Locations/MapAddLocation';
import { useAddLocation, useUpdateLocation } from 'api/v2/locations';
import { closeModalSearchedLocation } from './helpers';
import { useLocationOptions } from './hooks';

const LocationInput = ({
  action,
  setInputValue,
  setInputValueNumber,
  closeModal,
  location,
  allVendors,
}) => {
  const {
    searchedLocation,
    name,
    email,
    contact_no: contactNo,
    address,
    city,
    state,
    latitude,
    longitude,
    loading_site_special_instruction: loadingSiteSpecialInstruction,
    geofenceRange,
    locationType,
    ticketTypes,
    requiredTicketTypes,
    suspended,
    loading,
    vendors,
  } = location;
  const [saveDisabled, setSaveDisabled] = useState(true);

  const locationOptions = [
    { value: 1, label: 'Wellsite' },
    { value: 2, label: 'Sand site' },
    { value: 3, label: 'Water disposal unit' },
    { value: 4, label: 'Staging Site' },
    { value: 5, label: 'Acid Facility' }
  ];

  const locationsOptions = useLocationOptions(action);
  const { mutateAsync: addLocation, isPending: isAddPending } = useAddLocation();
  const { mutateAsync: editLocation, isPending: isEditPending } = useUpdateLocation(location.id);

  useEffect(() => {
    let disabled = false;
    if (
      !name ||
      !state ||
      !Number(latitude) ||
      !Number(longitude) ||
      !locationType ||
      !loadingSiteSpecialInstruction ||
      !Number(geofenceRange) ||
      isAddPending ||
      isEditPending
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);

  }, [location, setSaveDisabled, name, latitude, longitude, locationType, loadingSiteSpecialInstruction, geofenceRange, state, isAddPending, isEditPending]);

  const handleSubmit = async e => {
    e.preventDefault();

    const data = {
      ...location,
    }
    data.contactNo = data.contact_no;
    data.carrierOwnerId =
      data.locationType === 3 ? data.carrierOwnerId : null;
    data.loadSiteSpecialInstruction =
      data.loading_site_special_instruction;
    data.status = Number(data.suspended);
    if (data.requiredTicketTypes) {
      data.requiredTicketTypes = Array.from(
        data.requiredTicketTypes.map(item => {
          if (item.value) {
            return item.value;
          }
          return item;
        }),
      ).filter(rtt => rtt);
    }
    if (data.vendors) {
      data.vendors = Array.from(
        data.vendors.map(item => {
          if (item.value) {
            return item.value;
          }
          return item;
        }),
      ).filter(sv => sv);
    }

    try {
      if (action === 'edit') {
        await editLocation(data);
      } else {
        await addLocation(data);
      }
      swal(
        `Successfully ${action === 'edit' ? 'updated' : 'created'} location.`,
        '',
        'success',
      );
      closeModal();
    } catch (error) {
      swal('Unable to update location.', error?.data?.message, 'error');
    }
  }

  const renderTicketTypes = () => {
    if (!requiredTicketTypes || !ticketTypes.length) {
      return null;
    }
    return (
      <div className="form-group has-feedback col-md-6">
        <Select
          placeholder="Please Select Ticket Types"
          isMulti
          closeMenuOnSelect={false}
          onChange={item => {
            const values = item.map(i => i.value);
            setInputValue(values, 'requiredTicketTypes');
          }}
          options={ticketTypes}
          value={ticketTypes.filter(item =>
            requiredTicketTypes.includes(item.value),
          )}
          testSelector="locations_input_ticket_types_select"
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body className="form-group">
        {action === 'add' && !searchedLocation && (
          <Row>
            <div className="form-group has-feedback col-md-12">
              <Select
                required
                functionOnLastInput={val => {
                  setInputValue(val, 'name');
                }}
                onChange={selected => {
                  const name = selected.value === '**' ? '**' : selected.label;
                  setInputValue(name, 'searchedLocation');

                  if (name !== '**') {
                    closeModalSearchedLocation(closeModal, name);
                  }
                }}
                options={locationsOptions}
                placeholder="Search Location"
                value={null}
                addNew
                testSelector="locations_input_search_location_select"
              />
            </div>
          </Row>
        )}

        {(action === 'edit' || searchedLocation === '**') && (
          <>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="locations_input_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  value={email}
                  onChange={e => setInputValue(e, 'email')}
                  label="Email"
                  testSelector="locations_input_email_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <InputMask
                  mask="999999999999"
                  maskChar={null}
                  value={contactNo}
                  onChange={e => setInputValue(e, 'contact_no')}
                  label="Contact Number"
                  testSelector="locations_input_phone_input">
                  {inputProps => <Input {...inputProps} type="text" />}
                </InputMask>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'address')}
                  value={address}
                  label="Address"
                  testSelector="locations_input_address_input"
                />
              </div>
            </Row>

            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'city')}
                  value={city}
                  label="City"
                  testSelector="locations_input_city_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setInputValue(e, 'state')}
                  value={state}
                  label="State"
                  required
                  testSelector="locations_input_state_input"
                />
              </div>
            </Row>

            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setInputValueNumber(e, 'latitude')}
                  value={latitude}
                  label="Latitude"
                  required
                  testSelector="locations_input_lat_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setInputValueNumber(e, 'longitude')}
                  value={longitude}
                  label="Longitude"
                  required
                  testSelector="locations_input_lng_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Please Select Location Type"
                  onChange={item => setInputValue(item.value, 'locationType')}
                  options={locationOptions}
                  value={locationOptions.find(
                    item => item.value === locationType,
                  )}
                  required
                  testSelector="locations_input_location_type_select"
                />
              </div>
              {vendors && (
                <div className="form-group has-feedback col-md-6">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={selected => setInputValue(selected, 'vendors')}
                    options={multiSelectOptions(allVendors, 'id', 'name')}
                    placeholder="Select Vendor"
                    defaultValue={allVendors
                      .filter(item => vendors.includes(item.id))
                      .map(vendor => ({
                        label: vendor.name,
                        value: vendor.id,
                      }))}
                    testSelector="locations_input_vendors_select"
                  />
                </div>
              )}
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Textarea
                  onChange={e =>
                    setInputValue(e, 'loading_site_special_instruction')
                  }
                  value={loadingSiteSpecialInstruction}
                  label="Location Special Instructions"
                  rows="3"
                  required
                  testSelector="locations_input_instructions_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                {locationType !== 4 && (
                  <Input
                    type="checkbox"
                    testSelector="locations_input_suspended_input"
                    isChecked={suspended}
                    label="Suspended"
                    onChange={e => setInputValue(e, 'suspended')}
                  />
                )}
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setInputValueNumber(e, 'geofenceRange')}
                  value={geofenceRange}
                  label="Geofence range in Miles"
                  required
                  min={0.1}
                  max={30}
                  step={0.1}
                  testSelector="locations_input_geofence_input"
                />
              </div>
              {renderTicketTypes()}
            </Row>
            <Row>
              <div className="col-md-12">
                <MapAddLocation action={action} />
              </div>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeModal}
          colour="white"
          testSelector="locations_input_close_btn">
          Close
        </Button>
        <Button
          type="submit"
          disabled={loading || saveDisabled}
          testSelector="locations_input_save_btn">
          {loading ? 'Loading...' : 'Save'}
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default LocationInput;
