import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrderDetails';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';

const AddPrice = props => {
  const {
    setValue,
    showModalAdd,
    closeAdd,
    addAdditionalPrice,
    modalAddPrice,
    clearAddPrice
  } = props;
  const { price, comment } = modalAddPrice;
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineSaveDisabled = () => {
    let disabled = false;

    if (!price || !comment) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };

  useEffect(() => {
    determineSaveDisabled();
  }, [price, comment]);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${20}%` }}
        className="modal-container"
        show={showModalAdd}
        onHide={closeAdd}>
        <Modal.Header>
          <h4 className="modal-title">Add price</h4>
        </Modal.Header>
        <Modal.Body>
          {isFormSubmitted ? (
            <Loader />
          ) : (
            <Row>
              <div className="form-group has-feedback col-md-6">
                <div className="form-group">
                  <Input
                    onChange={e => setValue(e, 'price')}
                    value={price}
                    type="number"
                    label="Price $"
                    required
                    testSelector="order-details_pricing_add_price_input"
                  />
                </div>
              </div>
              <div className="form-group has-feedback col-md-6">
                <div className="form-group">
                  <Input
                    onChange={e => setValue(e, 'comment')}
                    value={comment}
                    label="Comment"
                    required
                    testSelector="order-details_pricing_add_comment_input"
                  />
                </div>
              </div>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              clearAddPrice();
              closeAdd();
            }}
            colour="white"
            children="Close"
            testSelector="order-details_pricing_add-modal_close_btn"
          />
          <Button
            disabled={isFormSubmitted || saveDisabled}
            testSelector="order-details_pricing_add-modal_save_btn"
            onClick={e => {
              addAdditionalPrice(e, closeAdd);
              setFormSubmitted(true);
            }}
            children="Save"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalAddPrice: state.orderDetails.modalAddPrice,
});

const mapDispatchToProps = dispatch => ({
  setValue: (e, name) =>
    dispatch(actions.setValueAddPrice(targetValue(e), name)),
  addAdditionalPrice: (e, closeAdd) => {
    e.preventDefault();
    dispatch(actions.addAdditionalPrice(closeAdd));
  },
  clearAddPrice: () => dispatch(actions.clearAddPrice()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPrice);
