import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';

const InfoModal = ({ isVisible, setIsVisible, title = 'Info', info }) => (
  <div className="inmodal">
    <Modal
      style={{ top: '20%' }}
      className="modal-container"
      show={isVisible}
      onHide={() => setIsVisible(false)}>
      <div>
        <Modal.Header>
          <h3 className="modal-title">{title}</h3>
        </Modal.Header>
        <Modal.Body>
          <strong
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              whiteSpace: 'pre-wrap',
            }}>
            {info}
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setIsVisible(false)}
            colour="white"
            testSelector="info-details_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  </div>
);
export default InfoModal;
