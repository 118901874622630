import React from 'react';

const Waterdrop = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <path d="M15.124 8.904a34.939 34.939 0 0 0-2.747-3.756.42.42 0 0 0-.642 0c-.588.7-1.725 2.118-2.748 3.757C7.668 11.017 7 12.764 7 14.096 7 16.798 9.268 19 12.056 19c2.787 0 5.055-2.199 5.055-4.906 0-1.332-.668-3.079-1.987-5.19zm-2.063 8.43a3.986 3.986 0 0 1-.948.109c-1.961 0-3.557-1.393-3.557-3.104 0-.413.116-.896.345-1.435.038-.092.148-.143.257-.121.108.021.181.109.17.207-.013.115-.02.229-.02.336 0 1.863 1.598 3.424 3.717 3.63.105.01.188.084.197.176.008.093-.06.177-.161.202z"/>
  </svg>
);

export default Waterdrop;
