import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import { convertTimeForProfiles } from 'utils/convertTime';
import DatePicker from 'components/shared/DatePicker';

const InsuranceDetails = ({
  showModalInsurance,
  closeInsurance,
  insureDoc,
}) => {
  const {
    effectiveDate,
    expirationDate,
    commercialGeneralLiabilityLimitsEffectiveDate,
    workersCompensationLimitsEffectiveDate,
    commercialGeneralLiabilityLimitsExpirationDate,
    workersCompensationLimitsExpirationDate,
    automatizeLiabilityLimits,
    cargoLimits,
    commercialGeneralLiabilityLimits,
    workersCompensationLimits,
  } = insureDoc || {};

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showModalInsurance}
        onHide={closeInsurance}>
        <Modal.Header>
          <Modal.Title as="h4">Insurance details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            type="hidden"
            label="Charges"
            required
            disabled
            testSelector="admin-carriers_insurance-details_charges_input"
          />
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Effective"
                value={convertTimeForProfiles(effectiveDate)}
                disabled
                required
                testSelector="admin-carriers_insurance-details_effective-date_dp"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Expiration"
                value={convertTimeForProfiles(expirationDate)}
                disabled
                required
                testSelector="admin-carriers_insurance-details_expiration-date_dp"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                type="number"
                value={commercialGeneralLiabilityLimits}
                label="Commercial general liability limits"
                required
                disabled
                testSelector="admin-carriers_insurance-details_liability-limits_input"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                type="number"
                value={workersCompensationLimits}
                label="Workers compensation limits"
                required
                disabled
                testSelector="admin-carriers_insurance-details_compensation-limits_input"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Commercial General Liability Limits Effective Date"
                value={convertTimeForProfiles(
                  commercialGeneralLiabilityLimitsEffectiveDate,
                )}
                disabled
                required
                testSelector="admin-carriers_insurance-details_commercial-liability-effective-date_dp"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Workers Compensation Limits Effective Date"
                value={convertTimeForProfiles(
                  workersCompensationLimitsEffectiveDate,
                )}
                disabled
                required
                testSelector="admin-carriers_insurance-details_workers-compensation-effective-date_dp"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Commercial General Liability Limits Expiration Date"
                value={convertTimeForProfiles(
                  commercialGeneralLiabilityLimitsExpirationDate,
                )}
                disabled
                required
                testSelector="admin-carriers_insurance-details_commercial-liability-expiration-date_dp"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <DatePicker
                label="Workers Compensation Limits Expiration Date"
                value={convertTimeForProfiles(
                  workersCompensationLimitsExpirationDate,
                )}
                disabled
                required
                testSelector="admin-carriers_insurance-details_workers-compensation-expiration-date_dp"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                type="number"
                value={cargoLimits}
                label="Cargo limits"
                required
                disabled
                testSelector="admin-carriers_insurance-details_cargo-limits_input"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                type="number"
                value={automatizeLiabilityLimits}
                label="Automatize liability limits"
                required
                disabled
                testSelector="admin-carriers_insurance-details_atmz-liability-limits_input"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeInsurance}
            colour="white"
            testSelector="admin-carriers_insurance_close_btn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default InsuranceDetails;
