import React from 'react';

import Filter, {
  FilterInput,
  FilterDatePicker,
} from 'components/shared/Filter';

const serverSideNaming = {
  id: 'id',
  invoice_id: 'invoiceId',
  order_id: 'orderId',
  created_at: 'createdAt',
};

const CreditMemosFilter = ({
  setFilters,
  filters,
  setRequestFilters,
}) => {
  const updateFiltering = filterData => {
    setFilters(filterData);
    const convertedFiltering = filterData.reduce((obj, { key, value }) => {
      obj[serverSideNaming[key]] = value;
      return obj;
    }, {});
    setRequestFilters(convertedFiltering);
  };

  return (
    <Filter
      manualUpdate
      onUpdate={filtersData => updateFiltering(filtersData)}
      active={filters}
      filterVisible={true}
      >
      <FilterInput
        size="quarter"
        filterKey="id"
        label="Credit Memo Id"
        filterLabel="Credit Memo Id"
        type="number"
        testSelector="credit-memos_filter_id_input"
      />
      <FilterInput
        size="quarter"
        filterKey="invoice_id"
        label="Invoice Id"
        filterLabel="Invoice Id"
        type="number"
        testSelector="credit-memos_filter_invoice-id_input"
      />
      <FilterInput
        size="quarter"
        filterKey="order_id"
        label="Order Id"
        filterLabel="Order Id"
        type="number"
        testSelector="credit-memos_filter_order-id_input"
      />
      <FilterDatePicker
        size="quarter"
        filterLabel="Created date"
        filterKey="created_at"
        label="Created date"
        isSimpleDatePicker={true}
        forceValue
        clearable
        testSelector="credit-memos_filter_created-date_dp"
      />
    </Filter>
  );
};

export default CreditMemosFilter;
