import { combineReducers } from '@reduxjs/toolkit';
import PurchaseOrder from '../constants/PurchaseOrders';
import updatePO from './UpdatePO';

const initialStatePO = {
  isLoadedPO: false,
  purchaseOrders: [],
  customerId: '',
  jobId: '',
  sandSiteId: '',
  isActive: true,
};

const orders = (state = initialStatePO, action) => {
  switch (action.type) {
    case PurchaseOrder.GET_CUSTOMER_PO_REQUEST:
      return {
        ...state,
        isLoadedPO: false,
      };
    case PurchaseOrder.GET_CUSTOMER_PO_SUCCESS:
      return {
        ...state,
        isLoadedPO: true,
        purchaseOrders: action.purchaseOrders,
      };
    case PurchaseOrder.GET_CUSTOMER_PO_FAILURE:
      return {};
    case PurchaseOrder.SET_SELECT_VALUE_FOR_PO:
      return {
        ...state,
        [action.name]: action.value,
      };
    case PurchaseOrder.CLEAR_STORE_VIEW_PO:
      return initialStatePO;
    default:
      return state;
  }
};

export const purchaseOrders = combineReducers({
  orders,
  updatePO,
});
