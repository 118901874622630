const carriersDefaultState = {
  collection: [],
  apiIsLoading: false,
  lastApiCallSucceeded: true,
};

export default (state = carriersDefaultState, data) => {
  switch (data.type) {
    case 'CARRIERS_REQUESTED':
      return {
        ...state,
        apiIsLoading: true,
      };
    case 'CARRIERS_REQUEST_SUCCEEDED':
      return {
        ...state,
        collection: data.carriers,
        apiIsLoading: false,
        lastApiCallSucceeded: true,
      };
    case 'CARRIERS_REQUEST_FAILED':
      return {
        ...state,
        apiIsLoading: false,
        lastApiCallSucceeded: false,
      };
    default:
      return state;
  }
};
