import React from 'react';
import Label from 'components/shared/Label';
import { minDuration } from 'utils/convertTime';

const SandSiteDemurrage = ({ demurrage: { sandSite } }) => (
  <div className="col-md-6 ">
    <div className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>Sand Site Demurrage</h5>
      </div>
      <div className="ibox-content">
        <div className="col-md-8">
          <table className="table table--hover">
            <thead>
              <tr>
                <td className="text-center" style={{ marginBottom: '5px' }}>
                  Top 5 reasons
                </td>
              </tr>
            </thead>
            <tbody>
              {sandSite.topReasons.map((item, index) => (
                <tr key={`sandSitereason-${index}`}>
                  <td className="text-center">{index + 1}</td>
                  <td> {item.name}</td>
                  <td className="text-center">
                    <Label type="primary">{item.count} orders</Label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-4 text-center">
          <div className="widget style1 ">
            <div className="row">
              <div className="col-xs-12 text-right">
                <span> Orders </span>
                <h3 className="font-bold">{sandSite.ordersCount}</h3>
              </div>
            </div>
          </div>
          <div className="widget style1 ">
            <div className="row">
              <div className="col-xs-12 text-right">
                <span> Avg duration </span>
                <h3 className="font-bold">
                  {minDuration(sandSite.avrDuration)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default SandSiteDemurrage;
