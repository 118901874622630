import { combineReducers } from '@reduxjs/toolkit';

const init = {
  isLoaded: false,
  info: {},
  tab: 'customers',
  orders: [],
  ordersLoaded: false,
  itemsPerPage: 10,
  totalPages: 0,
  units: { loaded: false, data: [] },
};

const details = (state = init, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_INIT':
      return {
        ...state,
        isLoaded: true,
        info: action.data,
      };
    case 'DISTRICT_DETAILS_INIT_UNITS':
      return {
        ...state,
        units: {
          data: action.data,
          loaded: true,
        },
      };
    case 'DISTRICT_DETAILS_SET_TAB':
      return {
        ...state,
        tab: action.value,
      };
    case 'DISTRICT_DETAILS_SAVE_ORDERS':
      return {
        ...state,
        totalPages: action.totalPages,
        orders: action.orders,
        ordersLoaded: true,
      };
    case 'DISTRICT_DETAILS_CLEAR':
      return init;
    default:
      return state;
  }
};
const initAddCarrier = {
  carrierId: '',
  carriersArray: [],
};

const addCarrier = (state = { ...initAddCarrier }, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_ADD_CARRIER_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DISTRICT_DETAILS_ADD_CARRIER_CLEAR':
      return initAddCarrier;
    default:
      return state;
  }
};

const initUnit = {
  showAddModal: false,
  adding: false,
  approvedMileage: '0',
  travelTimeMinutes: '0',
  jobId: '',
  customerId: '',
  volume: '1000000000',
  sandTypeId: '',
  sandSiteId: '',
  priority: '1',
  dollarsPerTon: '',
  comment: '-',
  poReference: '-',
  sandVendorId: '',
  carrierId: 0,
  commodityUnits: '3',
};

const addUnit = (state = { ...initUnit }, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_ADD_UNIT_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DISTRICT_DETAILS_ADD_UNIT_ADDING':
      return {
        ...state,
        adding: !state.adding,
      };
    case 'DISTRICT_DETAILS_ADD_UNIT_CLEAR':
      return initUnit;
    default:
      return state;
  }
};

const initEditDistrict = {
  name: '',
  districtDetails: '',
  isCarrierRatioEnabled: false,
  isManualDispatchEnabled: false,
};

const editDistrict = (state = { ...initEditDistrict }, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_EDIT_DISTRICT_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DISTRICT_DETAILS_EDIT_DISTRICT_CLEAR':
      return initEditDistrict;
    default:
      return state;
  }
};

const initRatio = {
  carrierRatio: [],
};

const editRatio = (state = { ...initRatio }, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_EDIT_RATIO_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DISTRICT_DETAILS_EDIT_RATIO_CLEAR':
      return initRatio;
    default:
      return state;
  }
};

const initEditUnit = {
  showEditModal: false,
  editing: false,
  dollarsPerTon: '',
  priority: '',
  customerId: '',
  unitId: '',
};

const editUnit = (state = { ...initEditUnit }, action) => {
  switch (action.type) {
    case 'DISTRICT_DETAILS_EDIT_UNIT_SET_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DISTRICT_DETAILS_EDIT_UNIT_ADDING':
      return {
        ...state,
        editing: action.value,
      };
    case 'DISTRICT_DETAILS_EDIT_UNIT_CLEAR':
      return initEditUnit;
    default:
      return state;
  }
};

export default combineReducers({
  details,
  addUnit,
  editUnit,
  addCarrier,
  editDistrict,
  editRatio,
});
