import React, { useState } from 'react';
import Icon from 'components/shared/Icon';

const IboxTools = () => {
  const [open, setOpen] = useState(false);

  /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
  const collapsePanel = e => {
    e.preventDefault();

    // const element = e.currentTarget;
    // const ibox = element.closest('div.ibox');
    // ibox.classList.toggle('border-bottom');
    // ibox.classList.toggle('collapsed');
    // setTimeout(() => {
    //   ibox.dispatchEvent(new Event('resize'));
    //   Array.from(ibox.children).forEach(elem => {
    //     elem.dispatchEvent(new Event('resize'));
    //   });
    // }, 50);

    setOpen(!open);

    // TODO: Remove jQuery
    // eslint-disable-next-line no-undef
    const element = $(e.currentTarget);
    const ibox = element.closest('div.ibox');
    const content = ibox.find('div.ibox-content');
    content.slideToggle(200);
    ibox.toggleClass('').toggleClass('border-bottom');
    setTimeout(() => {
      ibox.resize();
      ibox.find('[id^=map-]').resize();
    }, 50);
  };

  return (
    <div className="ibox-tools">
      <a
        className="collapse-link"
        style={{ cursor: 'pointer' }}
        onClick={collapsePanel}>
        <Icon icon={open ? 'chevron-down' : 'chevron-up'} />
      </a>
    </div>
  );
};

export default IboxTools;
