import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

const PaginationBack = ({ disabled, onClick }) => (
  <Button theme="square" colour="grey" onClick={onClick} disabled={disabled}>
    <Icon icon="left" />
  </Button>
);

PaginationBack.defaultProps = {
  onClick: () => null,
};

export default PaginationBack;
