import React from 'react';
import { connect } from 'react-redux';
import { supplyChainMapRequested } from 'store/actions/SupplyChainMap';

import HomePage from 'components/views/Home/HomePage';
import HomeStateProvider from 'components/views/Home/hooks/HomeStateProvider';

const Home = props => {
  return (
    <HomeStateProvider>
      <HomePage {...props} />
    </HomeStateProvider>
  );
};

const mapStateToProps = ({ supplyChainMap }) => ({
  isLoading: supplyChainMap.apiIsLoading,
  mapJobs: supplyChainMap.collection,
});

const mapDispatchToProps = dispatch => ({
  getMapData: onlyAlerts => dispatch(supplyChainMapRequested(onlyAlerts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
