import swal from 'bootstrap-sweetalert';
import auditService from 'services/audits/service';

const searchAudits = data => dispatch => {
    const request = () => ({ type: 'AUDITS_SEARCH_REQUEST', data });
    const success = audits => ({
        type: 'AUDITS_SEARCH_SUCCESS',
        audits,
    });
    const failure = error => ({
        type: 'AUDITS_SEARCH_FAILURE',
        error,
    });

    dispatch(request());

    auditService
        .get(data)
        .then(response => {
            dispatch(success(response.data));
        })
        .catch(error => {
            dispatch(failure(error));
            swal(error.response.data.message, '', 'error');
        });
}

const getNumPages = data => dispatch => {
    const request = () => ({ type: 'AUDITS_NUM_PAGES_REQUEST', data });
    const success = numPages => ({
        type: 'AUDITS_NUM_PAGES_SUCCESS',
        numPages,
    });
    const failure = error => ({
        type: 'AUDITS_NUM_PAGES_FAILURE',
        error,
    });

    dispatch(request());

    auditService
        .getNumPages(data)
        .then(response => {
            dispatch(success(response.data));
        })
        .catch(error => {
            dispatch(failure(error));
            swal(error.response.data.message, '', 'error');
        });
}

const actions = {
    searchAudits,
    getNumPages,
}

export default actions
