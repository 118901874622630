import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/Users';
import { Modal, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import PropTypes from 'prop-types';
import timeZones from 'components/globalInfo/centralTimezone';

const AddUser = ({
  initUser,
  clear,
  input,
  setInputValue,
  setInputEmail,
  action,
  showModal,
  closeModal,
  submit,
  openModalRole,
  saveSelectValue,
}) => {
  const [mainState, setMainState] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    if (action === 'edit') {
      initUser();
    }
    return () => {
      clear();
    };
  }, []);

  const {
    email,
    profile: { phoneNumber, timeZone, description, firstName, lastName, file },
  } = input;

  const handleChange = (name, value) => {
    setMainState({ ...mainState, [name]: value });
  };

  const determineSaveDisabled = () => {
    let disabled = false;
    if (
      !email.trim().length ||
      !phoneNumber.trim().length ||
      !timeZone.trim().length ||
      !description ||
      !description.trim().length ||
      !firstName.trim().length ||
      !lastName.trim().length
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };

  useEffect(() => {
    determineSaveDisabled();
  }, [input]);

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      <form
        onSubmit={e => {
          submit(e, openModalRole);
        }}>
        <Modal.Header>
          <Modal.Title as="h3">
            {action === 'add' ? 'Add ' : 'Edit '}
            User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(e, 'firstName')}
                value={firstName || ''}
                label="First name"
                required
                testSelector="users_add_first-name_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(e, 'lastName')}
                value={lastName || ''}
                label="Last name"
                required
                testSelector="users_add_last-name_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputEmail(e)}
                value={email}
                label="Email"
                type="email"
                required
                testSelector="users_add_email_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <InputMask
                mask="999999999999"
                maskChar={null}
                value={phoneNumber}
                onChange={e => setInputValue(e, 'phoneNumber')}
                label="Phone number">
                {inputProps => (
                  <Input
                    {...inputProps}
                    type="text"
                    required
                    testSelector="users_add_phone_input"
                  />
                )}
              </InputMask>
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Time Zone"
                options={timeZones}
                onChange={item => saveSelectValue(item.value, 'timeZone')}
                value={timeZones.find(zone => zone.value === timeZone) || null}
                required
                testSelector="users_add-user_timezone_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(e, 'description')}
                value={description || ''}
                label="Description"
                required
                testSelector="users_add_description_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-12">
              <Input
                type="file"
                accept="image/*"
                name="image1"
                label="Upload logo"
                onChange={e => {
                  setInputValue(e, 'file');
                }}
                testSelector="users_add_logo_file-input"
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="users_add-user_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="users_add-user_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

AddUser.defaultProps = {
  input: {},
  action: '',
  // showModal: () => {},
  closeModal: () => {},
  submit: () => {},
  openModalRole: () => {},
  saveSelectValue: () => {},
};

AddUser.propTypes = {
  input: PropTypes.object,
  action: PropTypes.string,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  submit: PropTypes.func,
  openModalRole: PropTypes.func,
  saveSelectValue: PropTypes.func,
};

const mapStateToProps = state => ({
  input: state.users.addUser,
  action: state.users.info.action,
  users: state.users.info.users,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initUser: () => dispatch(actions.initUser()),
  clear: () => dispatch(actions.clearAddUser()),
  submit: (e, openModalRole) => {
    e.preventDefault();
    dispatch(actions.submitAddUser(openModalRole, ownProps.closeModal));
  },
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  setInputEmail: e => dispatch(actions.setInputEmail(targetValue(e))),
  saveSelectValue: (value, name) =>
    dispatch(actions.setInputValue(value, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddUser);
