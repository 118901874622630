import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { getTags, addTag, editTag, deleteTag, getTagTypes } from './queries';

/**
 * @param {{
 *  type?: number;
 * }} params
 */
export const useTags = ({ type } = {}) =>
  useQuery({
    queryKey: ['tags', { type }],
    queryFn: () => getTags({ type }),
    placeholderData: [],
  });

export const useTagsTypes = () =>
  useQuery({
    queryKey: ['tagsTypes'],
    queryFn: getTagTypes,
  });

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      swal(`Successfully deleted tag!`, '', 'success');
    },
    onError: err => {
      swal(err.data.message || 'Failed to delete tag.', '', 'error');
    },
  });
};

export const useEditTag = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      swal(`Successfully edited tag!`, '', 'success');
    },
    onError: err => {
      swal(err.data.message || 'Failed to edit tag.', '', 'error');
    },
    ...options,
  });
};

export const useAddTag = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      swal(`Successfully added tag!`, '', 'success');
    },
    onError: err => {
      swal(err.data.message || 'Failed to add tag.', '', 'error');
    },
    ...options,
  });
};
