import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/shared/Modal';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import { removePrice } from 'store/actions/Orders';

const RemovePriceModal = ({
  open,
  onClose,
  isLoading,
  removePriceFunc,
  orderId,
  priceId,
}) => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const body = (
    <>
      {isFormSubmitted ? (
        <Loader />
      ) : (
        <>
          <h2>Are you sure?</h2>
          <p>If you do this, you will not be able to revert!</p>
        </>
      )}
    </>
  );

  const handleSendAndClose = (orderId, priceId) => {
    removePriceFunc(orderId, priceId, onClose);
    setFormSubmitted(true);
  };

  const footer = (isLoading && <Loader />) || (
    <>
      <Button
        onClick={() => handleSendAndClose(orderId, priceId)}
        disabled={isFormSubmitted}
        testSelector="reconcile_order-details_remove-price_delete_btn">
        Yes, delete it!
      </Button>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="reconcile_order-details_remove-price_cancel_btn">
        Cancel
      </Button>
    </>
  );

  return <Modal open={open} onClose={onClose} body={body} footer={footer} />;
};

const mapDispatchToProps = dispatch => ({
  removePriceFunc: (orderId, priceId, close) =>
    dispatch(removePrice(orderId, priceId, close)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RemovePriceModal);
