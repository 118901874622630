const constants = {
  EVENTS_REQUEST: 'EVENTS_REQUEST',
  EVENTS_REQUEST_SUCCESS: 'EVENTS_REQUEST_SUCCESS',
  EVENTS_REQUEST_FAILURE: 'EVENTS_REQUEST_FAILURE',
  EVENTS_CHANGE_BAR_STATUS: 'EVENTS_CHANGE_BAR_STATUS',
  EVENTS_CHANGE_ACCESS_NOTIFICATION: 'EVENTS_CHANGE_ACCESS_NOTIFICATION',
  EVENTS_CHANGE_ACTIVE_TAB: 'EVENTS_CHANGE_ACTIVE_TAB',
  EVENTS_UPDATE_REQUEST: 'EVENTS_UPDATE_REQUEST',
  EVENTS_UPDATE_REQUEST_FAILURE: 'EVENTS_UPDATE_REQUEST_FAILURE',
  EVENTS_UPDATE_REQUEST_SUCCESS: 'EVENTS_UPDATE_REQUEST_SUCCESS',
  EVENTS_CHAT_MESSAGE: 'EVENTS_CHAT_MESSAGE',
};

export default constants;
