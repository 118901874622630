import React from 'react';
import Authorize from 'components/common/Authorize';
import Link from 'components/common/Link';
import { deleteAlert } from 'components/sweetalert';
import Icon from 'components/shared/Icon';
import AutomatizeLogo from 'components/shared/AutomatizeLogo';
import Label from 'components/shared/Label';
import ToolTip from 'components/shared/ToolTip';
import { Col, Row } from 'react-bootstrap';
import { useRemoveDistrict } from 'api/v2/districts';

const District = props => {
  const { district } = props;
  const {
    id,
    name,
    district_details: districtDetails,
    activeJobs,
    carriers,
    customers,
    colorOrderDriver,
    drivers,
    orders,
  } = district;

  const { mutate: deleteDistrict } = useRemoveDistrict(id);

  return (
    <div className="ibox">
      <div className="ibox-content-customer">
        <Row>
          <Col md={3} sm={4} xs={12} className="no-padding">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3
                style={{ width: '90%', marginBottom: 0 }}
                className="text-white">
                <Link to="district-details" id={id}>
                  {name}
                </Link>
              </h3>
              {district.companyOwnerName && (
                <ToolTip title={district.companyOwnerName} placement="top">
                  <Label type="success">SaaS</Label>
                </ToolTip>
              )}
              {district.companyOwnerId === 1 &&
                district.companyOwnerType === 6 && (
                  <AutomatizeLogo margin={5} />
                )}
              <Authorize
                abilityPermissions={[
                  { resource: 'Districts', permissions: ['delete'] },
                ]}>
                <Icon
                  icon="trash"
                  onClick={() => deleteAlert(deleteDistrict, id)}
                  colour="white"
                  style={{ float: 'right' }}
                />
              </Authorize>
            </div>
            <span style={{ width: '100%', display: 'inline-block' }}>
              {districtDetails}
            </span>
          </Col>
          <Col
            md={9}
            sm={8}
            xs={12}
            className="no-padding"
            style={{ textAlign: 'center' }}>
            <Row>
              <Col md={3} sm={6} xs={12}>
                <h3
                  style={{ width: '100%', marginBottom: 0 }}
                  className="text-info">
                  {activeJobs}
                </h3>
                <span>Active Jobs</span>
              </Col>
              <Col md={3} sm={6} xs={12}>
                <h3
                  style={{ width: '100%', marginBottom: 0 }}
                  className="text-info">
                  {carriers}
                </h3>
                <span>Carriers</span>
              </Col>
              <Col md={3} sm={6} xs={12}>
                <h3
                  style={{ width: '100%', marginBottom: 0 }}
                  className="text-info">
                  {customers}
                </h3>
                <span>Customers</span>
              </Col>
              <Col md={3} sm={6} xs={12}>
                <h3
                  style={{
                    width: '100%',
                    marginBottom: 0,
                    color: colorOrderDriver,
                  }}>
                  {orders}/{drivers}
                </h3>
                <span>Orders/Drivers</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default District;
