import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import {
  addPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrdersByJob,
  getPurchaseOrders,
  importPurchaseOrder,
  addImportedPurchaseOrder
} from './queries';

export const useJobPurchaseOrders = (jobId) =>
  useQuery({
    queryKey: ['jobs', jobId, 'purchase-orders'],
    queryFn: () => getPurchaseOrdersByJob(jobId),
    placeholderData: [],
  });

export const usePurchaseOrders = ({ customerId, jobId, sandSiteId, isActive, enabled }) =>
  useQuery({
    queryKey: ['purchase-orders', { customerId, jobId, sandSiteId, isActive }],
    queryFn: () => getPurchaseOrders({ customerId, jobId, sandSiteId, isActive: Number(isActive) }),
    placeholderData: [],
    refetchOnMount: false,
    enabled
  });

export const useAddPurchaseOrder = (jobId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['jobs', jobId],
    mutationFn: newPo => addPurchaseOrder({ jobId, newPo }),
    onSuccess: () => {
      swal('Successfully Added PO', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal('Unable to Add PO', error.response?.data?.message, 'error'),
  });
};

export const useUpdatePurchaseOrder = ({ poId, jobId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['purchase-orders', poId],
    mutationFn: newPo => updatePurchaseOrder({ poId, newPo }),
    onSuccess: () => {
      swal('Successfully Updated PO', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['purchase-orders'] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal('Unable to Update PO', error.response?.data?.message, 'error'),
  });
};

export const useImportedPurchaseOrders = () =>
  useQuery({
    queryKey: ['imported-purchase-orders'],
    queryFn: (data) => importPurchaseOrder(data),
  });

export const useAddImportedPurchaseOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['imported-purchase-orders'],
    mutationFn: data => addImportedPurchaseOrder(data),
    onSuccess: () => {
      swal('Successfully Added Imported PO', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['imported-purchase-orders', 'jobs'] });
    },
    onError: error =>
      swal('Unable to Add Imported PO', error.response?.data?.message, 'error'),
  });
}
