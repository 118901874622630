import { combineReducers } from '@reduxjs/toolkit';
import moment from 'moment';

const init = {
  dispatchedItem: '',
};

export const initStageDesign = (state = init, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_STAGE_DESIGN_SET_DISPATCH_ITEM':
      return {
        ...state,
        dispatchedItem: action.item,
      };
    case 'JOB_DETAILS_STAGE_DESIGN_CLEAR_DISPATCH_ITEM':
      return init;

    default:
      return state;
  }
};

const initAdd = {
  openModal: false,
  stages: {
    name: '',
    stageDesign: [
      {
        sandTypeId: undefined,
        poNo: undefined,
        volume: '',
      },
    ],
  },
};

export const add = (state = initAdd, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_STAGE_DESIGN_ADD_TURN':
      return {
        ...state,
        openModal: !state.openModal,
      };
    case 'JOB_DETAILS_STAGE_DESIGN_ADD_SET_INPUT_VALUE':
      return {
        ...state,
        stages: {
          ...state.stages,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_STAGE_DESIGN_DELETE_ITEM_STAGE':
      return {
        ...state,
        stages: {
          ...state.stages,
          stageDesign: action.array,
        },
      };
    case 'JOB_DETAILS_STAGE_DESIGN_ADD_SUCCESS':
      return initAdd;
    case 'JOB_DETAILS_STAGE_DESIGN_CLEAR_STORE':
      return {
        openModal: false,
        stages: {
          name: '',
          stageDesign: [
            {
              sandTypeId: undefined,
              poNo: undefined,
              volume: '',
            },
          ],
        },
      };
    default:
      return state;
  }
};

const initDispatch = {
  step: 1,
  isChecked: false,
  antTrailOrdering: 0,
  stages: [],
  startDate: moment().add(1, 'minute'),
  previewOrders: [],
};

export const dispatch = (state = initDispatch, action) => {
  switch (action.type) {
    case 'MODAL_DISPATCH_STAGE_SET_STEP':
      return {
        ...state,
        step: action.step,
      };
    case 'MODAL_DISPATCH_STAGE_INIT':
      return {
        ...state,
        stages: action.stages,
      };
    case 'MODAL_DISPATCH_STAGE_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'MODAL_DISPATCH_STAGE_GET_PREVIEW_REQUEST':
      return {
        ...state,
      };
    case 'MODAL_DISPATCH_STAGE_GET_PREVIEW_SUCCESS':
      return {
        ...state,
        previewOrders: action.previewOrders,
      };
    case 'MODAL_DISPATCH_STAGE_GET_PREVIEW_FAILURE':
      return {};
    case 'MODAL_DISPATCH_STAGE_CLEAR_STATE':
      return initDispatch;
    default:
      return state;
  }
};

export const stageDesign = combineReducers({
  initStageDesign,
  add,
  dispatch,
});
