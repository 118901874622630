import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';

const DownloadLinkModal = ({ open, onClose, isLoading, link }) => {
  const body = (
    <p>
      {(isLoading && <Loader />) || (
        <a href={link} target="_blank" title="download link">
          {link}
        </a>
      )}
    </p>
  );

  const footer = (
    <>
      <Button
        colour="white"
        onClick={onClose}
        testSelector="invoice_details_download-link_close_btn">
        Close
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Invoice Download Link"
      body={body}
      footer={footer}
    />
  );
};

export default DownloadLinkModal;
