import swal from 'bootstrap-sweetalert';
import { Bugfender } from '@bugfender/sdk';
import { userService } from 'services/login/service';
import authService from 'services/auth/service';
import { customer } from 'services/customer/service';
import { carrier } from 'services/carrier/service';
import { UAParser } from 'ua-parser-js';
import { ROLES } from '../../config/constants';
import { history } from '../../helpers/history';
import { loginConstants } from '../constants/Login';
import packageJson from '../../../package.json';

const authenticate = (code, roleId, entityId, roleTypeId) => (
  dispatch,
  getState,
) => {
  const request = () => ({ type: loginConstants.AUTHENTICATE_REQUEST });
  const success = user => ({ type: loginConstants.AUTHENTICATE_SUCCESS, user });
  const failure = error => ({
    type: loginConstants.AUTHENTICATE_FAILURE,
    error,
  });
  const expired = () => ({ type: loginConstants.LOGIN_TOKEN_EXPIRED });

  dispatch(request());

  authService
    .login(code, roleId, entityId, roleTypeId)
    .then(response => {
      const userPermissions = JSON.parse(response.data.data.permissions);
      authService.setPermissions(userPermissions);
      const user = authService.getUser();
      localStorage.setItem('selectedEntity', JSON.stringify(entityId));
      localStorage.setItem('selectedRoleTypeId', JSON.stringify(roleTypeId));

      if (user.role === ROLES.CUSTOMER) {
        customer.details(entityId).then(msg => {
          localStorage.setItem(
            'selectedCompanyType',
            msg.data.data.companyType,
          );
          dispatch(success(response.data.data));
          window.location = '/customer/home';
        });
      } else if (user.role === ROLES.CARRIER) {
        carrier.getCarrierDetails(entityId).then(msg => {
          localStorage.setItem(
            'selectedCompanyType',
            msg.data.data.details.companyType,
          );
          dispatch(success(response.data.data));
          window.location = '/carrier/home';
        });
      } else {
        const commonUrl = (() => {
          switch (user.role) {
            case ROLES.JOB_MANAGER:
              localStorage.setItem('selectedCompanyType', 1);
              return '/job-manager/jobs/jobs-list';
            case ROLES.JOB_COORDINATOR:
              return '/job-coordinator/job-details';
            case ROLES.VENDOR:
              return '/vendor/home';
            default:
              return '/login';
          }
        })();
        dispatch(success(response.data.data));
        window.location = commonUrl;
      }
    })
    .catch(error => {
      if (error === 'expired') {
        dispatch(expired());
      } else {
        dispatch(failure(error));
      }
    });
};

const login = () => (dispatch, getState) => {
  const {
    auth: {
      login: { email, password },
    },
  } = getState();

  const request = () => ({ type: loginConstants.LOGIN_REQUEST });
  const success = (roles, ableSelect, jwtTemp) => ({
    type: loginConstants.LOGIN_SUCCESS,
    roles,
    ableSelect,
    jwtTemp,
  });
  const failure = error => ({ type: loginConstants.LOGIN_FAILURE, error });

  dispatch(request());

  userService
    .login(email, password)
    .then(response => {
      Bugfender.log(`Successful Login: ${email}`);
      const jwtTemp = response.data.data.code;
      const { roles, passwordExpiresIn } = response.data.data;
      const role = roles[0];
      const canLogin = () => {
        if (roles.length === 1) {
          dispatch(
            authenticate(jwtTemp, role.roleId, role.entityId, role.roleTypeId),
          );
          dispatch(success(roles, false));
        } else {
          dispatch(success(roles, true, jwtTemp));
        }
      };
      localStorage.setItem('roles', JSON.stringify(roles));
      if (passwordExpiresIn <= 5) {
        swal(
          {
            title: `Your password will expire in ${passwordExpiresIn} day${
              passwordExpiresIn > 1 ? 's' : ''
            }`,
            text: '',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: '',
            closeOnConfirm: true,
            closeOnCancel: true,
            showLoaderOnConfirm: false,
          },
          isConfirm => {
            if (isConfirm) {
              canLogin();
            }
          },
        );
      } else {
        canLogin();
      }

      const parser = new UAParser();
      const infoDevice = parser.getResult();
      Bugfender.setDeviceKey('browser type', infoDevice.browser.name);
      Bugfender.setDeviceKey('browser version', infoDevice.browser.version);
      Bugfender.setDeviceKey('operating system', infoDevice.os.name);
      Bugfender.setDeviceKey('OS version', infoDevice.os.version);
      Bugfender.setDeviceKey('userId', role.userId);
      Bugfender.setDeviceKey('user email', email);
      Bugfender.setDeviceKey('web version', packageJson.version);
    })
    .catch(error => {
      if (error.response.status === 401) {
        dispatch({
          type: loginConstants.LOGIN_CREDENTIALS_FAILURE,
          errorMessage: error.response.data.message,
        });
      }
      dispatch(failure(error));
    });
};

const forgetPassword = closeModal => (dispatch, getState) => {
  const forget = () => ({ type: loginConstants.USER_FORGET_PASSWORD });
  dispatch(turnReset());
  const {
    auth: {
      login: { email },
    },
  } = getState();
  userService
    .forgetPassword(email)
    .then(response => {
      dispatch(turnReset());
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      dispatch(turnReset());
      swal(error.response.data.message, '', 'error');
    });
  dispatch(forget());
};

const checkToLogin = () => (dispatch, getState) => {
  const check = () => ({ type: loginConstants.LOGIN_CHECK_LOGIN_STATUS });

  const {
    auth: {
      authentication: { status, isLoggedIn },
    },
  } = getState();

  dispatch(check());

  if (isLoggedIn) {
    const url = (() => {
      switch (status) {
        case ROLES.JOB_MANAGER:
          return '/job-manager/home';
        case ROLES.JOB_COORDINATOR:
          return '/job-coordinator/home';
        case ROLES.CARRIER:
          return '/carrier/home';
        case ROLES.CUSTOMER:
          return '/customer/home';
        case ROLES.VENDOR:
          return '/vendor/home';
        default:
          return '/login';
      }
    })();

    window.location = url;
  }
};

const setLogin = login => ({ type: loginConstants.LOGIN_SET_LOGIN, login });
const setPassword = pass => ({ type: loginConstants.LOGIN_SET_PASSWORD, pass });
const turnReset = () => ({ type: loginConstants.LOGIN_TURN_LOCK_RESET });

const setCurrentUrl = url => ({ type: loginConstants.SET_CURRENT_URL, url });

const logout = () => dispatch => {
  swal(
    {
      title: 'Logout',
      text: 'Are you sure you want to logout?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5e9ede',
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true,
    },
    isConfirm => {
      if (isConfirm) {
        dispatch({ type: loginConstants.CLEAR });
        setTimeout(history.push('/login'), 0);
        dispatch(setCurrentUrl(''));
        localStorage.clear();
      }
    },
  );
};

export const loginActions = {
  login,
  forgetPassword,
  setLogin,
  setPassword,
  checkToLogin,
  authenticate,
  logout,
  setCurrentUrl,
};
