import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import authService from 'services/auth/service';
import { userRoleLinks } from 'components/globalInfo/userRoles';
import { history } from 'helpers/history';

import { useAddJob } from 'api/v2/jobs';

import OnsiteStorageType from './OnsiteStorageType';
import { useJobDesignCommodities } from './hooks';
import { formatAddJobParams } from './helpers';

const OnsiteStorage = ({
  closeAddJobModal,
  addJob,
  formHandlers
}) => {
  const { jobDesign, storageConfig } = addJob;

  const {
    setStep,
    setArrayValue,
    deleteArrayValue,
    addArrayValue,
    setOnsiteStorageType
  } = formHandlers;
  const commodityOptions = useJobDesignCommodities(jobDesign, addJob.customerId);
  const { mutate: saveJob } = useAddJob();

  const [saveClicked, setSaveClicked] = useState(false);

  const handleSubmit = (e, isDraft) => {
    e.preventDefault();
    const user = authService.getUser();
    setSaveClicked(true);
    const formattedParams = formatAddJobParams(addJob);
    formattedParams.isDraft = Number(isDraft);

    saveJob(formattedParams, {
      onSuccess: (jobId) => {
        closeAddJobModal();
        setSaveClicked(false);

        if (!isDraft) {
          history.push(`/${userRoleLinks[user.role]}/job-details/${jobId}`);
        }
      }
    });
  };

  const renderOnsiteStorage = (item, index) => (
    <OnsiteStorageType
      filteredCommodityOptions={commodityOptions}
      setArrayValue={setArrayValue}
      deleteArrayValue={deleteArrayValue}
      setOnsiteStorageType={setOnsiteStorageType}
      item={item}
      index={index}
      key={index}
      customerId={addJob.customerId}
    />
  );

  return (
    <form className="wizard-big wizard" onSubmit={e => handleSubmit(e, false)}>
      <Modal.Body>
        <div style={{ padding: '20px 10px' }}>
          <div className="step3">
            <h4 className="title">Storage Details</h4>
            <fieldset id="form-p-2" role="tabpanel" className="body">
              {storageConfig.map(renderOnsiteStorage)}
            </fieldset>
            <Button
              theme="small"
              testSelector="jobs-list_add-modal_onsite-storage_add-item_btn"
              onClick={() => addArrayValue('storageConfig')}>
              <Icon icon="plus" /> Add Storage
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_onsite-storage_close_btn">
          Close
        </Button>
        <Button
          onClick={() => setStep(4)}
          testSelector="jobs-list_add-modal_onsite-storage_previous_btn">
          Previous
        </Button>
        <Authorize forbidden={['CARRIER']}>
          <Button
            testSelector="jobs-list_add-modal_job-design_save_btn"
            onClick={(e) => handleSubmit(e, true)}
          >
            Save Draft
          </Button>
        </Authorize>
        <Button
          type="submit"
          disabled={saveClicked}
          testSelector="jobs-list_add-modal_onsite-storage_next_btn">
          Submit
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default OnsiteStorage;
