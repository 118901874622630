import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  getCustomers,
  getCustomerDetails,
  editCustomer,
  getCustomerJobs,
  deleteCustomer,
  unDeleteCustomer,
  syncCustomerBC,
  addCustomer,
  editCustomerAddress,
  editCustomerBillingTerms,
  editLogo,
  getScadaReport,
  shareCustomer,
  validateCustomerJobId,
} from './queries';

/**
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} options
 */
export const useCustomers = (options) =>
  useQuery({
    queryKey: ['customers'],
    queryFn: getCustomers,
    placeholderData: [],
    enabled: options?.enabled
  });

export const useCustomerDetails = (customerId) =>
  useQuery({
    queryKey: ['customers', Number(customerId), 'details'],
    queryFn: () => getCustomerDetails(customerId),
    enabled: !!customerId,
    placeHolderData: {},
  });


export const useEditCustomer = (customerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (info) => editCustomer({ ...info, customerId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers', Number(customerId), 'details'] });
    },
  });
};

export const useCustomerJobs = (customerId) =>
  useQuery({
    queryKey: ['customers', customerId, 'jobs'],
    queryFn: () => getCustomerJobs({ customerId }),
    enabled: !!customerId,
    placeholderData: [],
  });

export const useDeleteCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (customerId) => deleteCustomer({ customerId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });
};

export const useUnDeleteCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (customerId) => unDeleteCustomer({ customerId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });
};

export const useSyncCustomerBC = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (customerId) => syncCustomerBC({ customerId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });
};

export const useAddCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (customer) => addCustomer(customer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });
};

export const useEditCustomerAddress = (customerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (address) => editCustomerAddress(address),
    onSuccess: () => {
      swal('Success!', 'Successfully updated customer address.', 'success');
      queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'details'] });
    },
  });
};

export const useEditCustomerBillingTerms = (customerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (terms) => editCustomerBillingTerms({ ...terms, id: customerId }),
    onSuccess: () => {
      swal('Success!', 'Successfully updated billing terms', 'success');
      queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'details'] });
    },
  });
};

export const useEditCustomerLogo = (customerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (logo) => editLogo(logo, customerId),
    onSuccess: (response) => {
      swal('Success', 'Logo Updated', 'success');
      queryClient.invalidateQueries({ queryKey: ['customers', Number(customerId), 'details'] });
    },
  });
};

export const useScadaReport = (customerId) =>
  useQuery({
    queryKey: ['customers', customerId, 'details'],
    queryFn: () => getScadaReport({ customerId }),
    enabled: !!customerId,
    placeholderData: [],
  });

export const useShareCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ customerId, data }) => shareCustomer({ customerId, data }),
    onSuccess: () => {
      swal('Success', 'You Have Successfully Connected to The Selected Customer', 'success');
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });
};

export const useValidateCustomerJobId = () =>
  useMutation({
    mutationFn: ({ customerId, customerJobId }) => validateCustomerJobId({ customerId, customerJobId }),
  });
