import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import {
  useUpdateUnitOfMeasure,
  useCreateUnitOfMeasure,
} from 'api/v2/units-of-measure';

const AddEditModal = ({ value, status, closeUpdate, showUpdate }) => {
  const { mutate: updateUnitOfMeasure } = useUpdateUnitOfMeasure(value.id);
  const { mutate: createUnitOfMeasure } = useCreateUnitOfMeasure();
  const [name, setName] = useState('');

  useEffect(() => {
    if (showUpdate && status === 'edit' && value) {
      setName(value.name);
    } else {
      setName('');
    }
  }, [showUpdate, value, status]);

  const selectFunction = {
    add: (e, closeModal) => {
      e.preventDefault();
      createUnitOfMeasure(
        {
          name,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        },
      );
    },
    edit: (e, closeModal) => {
      e.preventDefault();
      updateUnitOfMeasure(
        {
          name,
          id: value.id,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        },
      );
    },
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => selectFunction[status](e, closeUpdate)}>
          <Modal.Header>
            <h3>
              {status === 'add'
                ? 'Add Unit of Measure'
                : 'Edit Unit of Measure'}
            </h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setName(targetValue(e))}
                  value={name}
                  label="Name"
                  required
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeUpdate} colour="white">
              Close
            </Button>
            <Button type="submit" disabled={!name || !name?.length}>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddEditModal;
