import React from 'react';

const Android = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M2.03 5.004c-.553 0-1 .392-1 .876v3.744c0 .484.447.876 1 .876.552 0 1-.392 1-.876V5.88c0-.484-.448-.876-1-.876zm7.568-3.662l.875-.872a.274.274 0 0 0 0-.39.276.276 0 0 0-.39 0L9.005 1.156a5.1 5.1 0 0 0-2.414-.003L5.518.081a.276.276 0 0 0-.39 0 .274.274 0 0 0 0 .39l.87.867c-1.452.556-2.46 1.763-2.46 3.166 0 .017 8.509 0 8.509 0 0-1.4-1.003-2.603-2.449-3.162zM6.541 3.505a.5.5 0 1 1-.002-1 .5.5 0 0 1 .002 1zm2.503 0a.5.5 0 1 1-.002-1 .5.5 0 0 1 .002 1zm4.5 1.5c-.553 0-1.001.39-1.001.875v3.744c0 .484.448.876 1.001.876.553 0 1-.393 1-.876V5.88c0-.484-.448-.876-1-.876zm-10.006 5.99a1.5 1.5 0 0 0 1.496 1.498v2.624c0 .484.448.876 1 .876.553 0 1-.392 1-.876v-2.624H8.54v2.624c0 .484.448.876 1 .876s1-.392 1-.876v-2.624h.007a1.5 1.5 0 0 0 1.501-1.498V5.003H3.538v5.992z"
    />
  </svg>
);

export default Android;