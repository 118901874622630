export const loginConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_CREDENTIALS_FAILURE: 'USERS_LOGIN_CREDENTIALS_FAILURE',
  LOGIN_TOKEN_EXPIRED: 'LOGIN_TOKEN_EXPIRED',

  AUTHENTICATE_REQUEST: 'AUTHENTICATE_REQUEST',
  AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
  AUTHENTICATE_FAILURE: 'AUTHENTICATE_FAILURE',

  CLEAR: 'CLEAR',

  USER_FORGET_PASSWORD: 'USER_FORGET_PASSWORD',

  LOGIN_SET_LOGIN: 'LOGIN_SET_LOGIN',
  LOGIN_SET_PASSWORD: 'LOGIN_SET_PASSWORD',

  LOGIN_CHECK_LOGIN_STATUS: 'LOGIN_CHECK_LOGIN_STATUS',
  LOGIN_SET_TAB_KEY: 'LOGIN_SET_TAB_KEY',

  SET_CURRENT_URL: 'SET_CURRENT_URL',

  LOGIN_TURN_LOCK_RESET: 'LOGIN_TURN_LOCK_RESET',
};
