import { combineReducers } from '@reduxjs/toolkit';

const initAdd = {
  number: '',
  minLevelToPump: '',
  barrelsPerInch: '',
  capacityLevel: '',
};

export const add = (state = initAdd, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TANKS_ADD_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_DETAILS_TANKS_ADD_SUCCESS':
      return initAdd;
    case 'JOB_DETAILS_TANKS_ADD_CLEAR':
      return initAdd;
    default:
      return state;
  }
};

const initEdit = {
  openModal: false,
  input: {
    minLevelToPump: '',
    barrelsPerInch: '',
    capacityLevel: '',
  },
};

export const edit = (state = initEdit, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TANKS_UPDATE_INIT':
      return {
        ...state,
        openModal: !state.openModal,
        input: action.data,
      };

    case 'JOB_DETAILS_TANKS_EDIT_SET_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'JOB_DETAILS_TANKS_EDIT_SUCCESS':
      return initEdit;
    default:
      return state;
  }
};

const initHistory = {
  openModal: false,
  history: '',
  tankId: '',
};

export const history = (state = initHistory, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_TANKS_GET_HISTORY':
      return {
        ...state,
        history: action.history,
      };
    case 'JOB_DETAILS_TANKS_INIT_TANK_ID':
      return {
        ...state,
        openModal: !state.openModal,
        tankId: action.id,
      };
    case 'JOB_DETAILS_TANKS_CLOSE_HISTORY':
      return initHistory;
    default:
      return state;
  }
};

const initFacilityId = {
  facilityId: '',
};

export const addFacilityId = (state = initFacilityId, action) => {
  switch (action.type) {
    case 'JOB_DETAILS_INIT_FACILITY_ID':
      return {
        ...state,
        ...action.data,
      };
    case 'JOB_DETAILS_ADD_FACILITY_ID_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'JOB_DETAILS_ADD_FACILITY_ID_SUCCESS':
      return initFacilityId;
    case 'JOB_DETAILS_ADD_FACILITY_CLEAR':
      return initFacilityId;
    default:
      return state;
  }
};

export const tankModals = combineReducers({
  add,
  edit,
  history,
  addFacilityId,
});
