import axios from 'axios';

export const addOrder = {
  getSandTypeByPoData(jobId) {
    return axios.get(`v2/jobs/${jobId}/po`, { jobId });
  },
  getStageByJobId(jobId) {
    return axios.get(`v2/jobs/${jobId}/stage`, { jobId });
  },
  getFreeDrivers(ssId) {
    return axios.get(`v2/driver/available?sandId=${ssId}`, { sandId: ssId });
  },
  addAndStartOrder(data) {
    return axios.post(`v2/order`, data);
  },
  getCalculatedPickUp(data) {
    return axios.post('v2/calculate-pickup', data);
  },
};
