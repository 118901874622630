import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/SandSiteSchedule';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import { convertTime } from 'utils/convertTime';
import targetValue from 'utils/targetValue';
import authService from 'services/auth/service';

const Chat = props => {
  const {
    showDriverChat,
    closeDriverChat,
    chat,
    loadMore,
    sendMessage,
    setChatInputValue,
    setChatFile,
    unsetChatFile,
  } = props;
  const {
    messages,
    messagesReady,
    chatRoom,
    fileName,
    isFile,
    messagesCount,
    text,
  } = chat;
  const user = authService.getUser();
  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      {...{ show: showDriverChat, onHide: closeDriverChat }}>
      <Modal.Header>
        <h4 className="modal-title">Driver Chat</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox chat-view">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="chat-discussion" style={{ height: 600 }}>
                        {messages.length < messagesCount && messagesReady && (
                          <div
                            onClick={loadMore}
                            className="message"
                            style={{
                              textAlign: 'center',
                              width: '30%',
                              borderRadius: 30,
                              margin: 'auto',
                              background: 'rgb(66, 134, 244)',
                              cursor: 'pointer',
                            }}>
                            <span
                              style={{ color: 'white' }}
                              className="message-content">
                              Load more
                            </span>
                          </div>
                        )}
                        {chatRoom === '' ? (
                          <div
                            style={{
                              width: `${100}%`,
                              height: `${100}%`,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <div style={{ fontSize: 20 }}>Select driver</div>
                          </div>
                        ) : messagesReady ? (
                          messages.length != 0 ? (
                            messages.map((message, index) => (
                              <div
                                key={`message_${index}`}
                                className={`${
                                  message.senderId == user.id
                                    ? 'chat-message left'
                                    : 'chat-message right'
                                } ${messages[index - 1] &&
                                  message.senderId ==
                                    messages[index - 1].senderId &&
                                  'messageMargin'}`}>
                                {(!messages[index - 1] ||
                                  message.senderId !=
                                    messages[index - 1].senderId) &&
                                  message.role == 1 && (
                                    <img
                                      className="message-avatar"
                                      src={`https://api.automatizelabs.com/v2/users/${message.senderId}/avatar`}
                                      alt=""
                                    />
                                  )}
                                <div className="message">
                                  {(!messages[index - 1] ||
                                    message.senderId !=
                                      messages[index - 1].senderId) && (
                                    <a className="message-author">
                                      {message.senderName}{' '}
                                      {message.role == 4 && `(Driver)`}
                                    </a>
                                  )}
                                  <span className="message-date">
                                    {convertTime(message.date)}
                                  </span>
                                  <span
                                    className="message-content"
                                    style={{ wordBreak: 'break-word' }}>
                                    {message.text}
                                  </span>
                                  {message.file && (
                                    <img
                                      src={message.file}
                                      alt={message.file}
                                      style={{ width: '100%' }}
                                    />
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{
                                width: `${100}%`,
                                height: `${100}%`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <div style={{ fontSize: 20 }}>
                                No messages yet
                              </div>
                            </div>
                          )
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <form onSubmit={sendMessage}>
                      <div className="col-lg-12">
                        <div className="chat-message-form">
                          <div
                            style={{ width: '100%' }}
                            className="input-group">
                            <Input
                              value={text || ''}
                              onChange={e => setChatInputValue(e, 'text')}
                              label="Enter message text"
                              required
                              testSelector="sand-site-schedule_driver-chat_text_input"
                            />
                            <div className="input-group-btn">
                              <Input
                                type="file"
                                label="Upload"
                                onChange={setChatFile}
                                testSelector="sand-site-schedule_driver-chat_file-input"
                              />
                              {isFile && (
                                <Button
                                  onClick={unsetChatFile}
                                  testSelector="sandsite-schedule_driver-chat_unset-file_btn">
                                  {fileName}
                                  <Icon icon="times" />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={closeDriverChat} colour="white" children="Close" />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  chat: state.sandSiteSchedule.chat,
});

const mapDispatchToProps = dispatch => ({
  sendMessage: e => {
    e.preventDefault();
    dispatch(actions.sendMessage());
  },
  unsetChatFile: () => dispatch(actions.unsetChatFile()),
  setChatFile: e => dispatch(actions.setChatFile(targetValue(e))),
  setChatInputValue: (e, name) =>
    dispatch(actions.setChatInputValue(targetValue(e), name)),
  destroyChat: () => dispatch(actions.destroyChat()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chat);
