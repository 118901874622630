import { components } from 'react-select';

import AnimatedInput from './AnimatedInput';
import AnimatedMultiValue from './AnimatedMultiValue';
import AnimatedValueContainer from './AnimatedValueContainer';

// Returns components wrapped in a function
// also passes the correct react-select component props to each one
const makeAnimated = () => {
  const { Input, MultiValue, ValueContainer } = components;
  return {
    Input: AnimatedInput(Input),
    MultiValue: AnimatedMultiValue(MultiValue),
    ValueContainer: AnimatedValueContainer(ValueContainer),
  };
};

export default makeAnimated;