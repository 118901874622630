import React, { useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';

import { useCarriers } from 'api/v2/carriers';
import { useAddDistrictCarrier } from 'api/v2/districts';

import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

const AddCarrier = ({ showModal, closeModal, districtId, carriers }) => {
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  const { data: allCarriers } = useCarriers();
  const { mutate: addDistrictCarrier } = useAddDistrictCarrier(districtId);

  const submitAdd = (e) => {
    e.preventDefault();
    addDistrictCarrier(
      {
        carrierId: selectedCarrier,
        districtId
      },
      {
        onSuccess: closeModal
      }
    );
  }

  const carriersArray = useMemo(() => {
    const filteredCarriers = allCarriers.filter(item => !carriers.find(c => c.id === item.id))
    const carrierForSelect = filteredCarriers.map(item => ({
      value: item.id,
      label: `${item.id} | ${item.name}`,
    }));

    return carrierForSelect;
  }, [allCarriers, carriers]);

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      <Modal.Header>
        <h3>Add Carrier</h3>
      </Modal.Header>
      <form onSubmit={e => submitAdd(e)}>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-12">
              <Select
                placeholder="Please Select Carrier"
                onChange={item => setSelectedCarrier(item.value)}
                options={carriersArray}
                required
                testSelector="district-details_add-carrier_carrier_select"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="district-details_add-carrier_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!selectedCarrier}
            testSelector="district-details_add-carrier_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};


export default AddCarrier;
