import React, { useState } from 'react';
import { connect } from 'react-redux';

import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Header from 'components/common/Header';
import Icon from 'components/shared/Icon';

import actions from 'store/actions/ManageCustomers';

import AddCustomer from './AddCustomer';
import AddWellSite from './WellSiteModal';

const AdminCustomerHeader = ({ customers, addWellSite }) => {
  const [showModalAddWellSite, setShowModalAddWellSite] = useState(false);
  const [showModalAddCustomer, setsShowModalAddCustomer] = useState(false);

  return (
    <Header title="Customer Profile">
      <Authorize
        abilityPermissions={[
          { resource: 'Locations', permissions: ['create'] },
        ]}>
        <Button
          onClick={() => setShowModalAddWellSite(true)}
          inverse
          className="float-end"
          testSelector="admin-customers_add-wellsite_btn">
          <Icon className="icon--margin-right" icon="plus-square" />
          Add Location
        </Button>
      </Authorize>
      <Authorize
        abilityPermissions={[
          { resource: 'Customers', permissions: ['create'] },
        ]}>
        <Button
          onClick={() => setsShowModalAddCustomer(true)}
          inverse
          className="float-end"
          testSelector="admin-customers_add-customer_btn">
          <Icon className="icon--margin-right" icon="plus-square" />
          Add Customer
        </Button>
      </Authorize>

      {showModalAddCustomer && (
        <AddCustomer
          showModalAdd={showModalAddCustomer}
          closeAdd={() => setsShowModalAddCustomer(false)}
        />
      )}
      {showModalAddWellSite && (
        <AddWellSite
          modalTitle="Add new Location"
          customers={customers}
          showModal={showModalAddWellSite}
          closeModal={() => setShowModalAddWellSite(false)}
          action="add"
        />
      )}
    </Header>
  );
};

const mapDispatchToProps = dispatch => ({
  addWellSite: (e, action, closeModal) => {
    e.preventDefault();
    dispatch(actions.addEditWellSite(action, closeModal));
  },
});

export const HeaderTest = AdminCustomerHeader; // for Tests

export default connect(
  null,
  mapDispatchToProps,
)(AdminCustomerHeader);
