import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

const PaginationNext = ({ disabled, onClick }) => (
  <Button theme="square" colour="grey" onClick={onClick} disabled={disabled}>
    <Icon icon="right" />
  </Button>
);

PaginationNext.defaultProps = {
  onClick: () => null,
};

export default PaginationNext;
