import { combineReducers } from '@reduxjs/toolkit';

const initInfo = {
  order: {},
  count: 0,
  countAPI: 12,
};

const info = (state = initInfo, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_SAVE_INIT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
        count: (state.count + 1),
      };
    case 'ORDER_DETAILS_CLEAR':
      return {
        order: {},
        count: 0,
        countAPI: 12,
      };
    default:
      return state;
  }
};

const initHeader = {
  transferred: [],
  isLoadedTransferred: false,
};

const header = (state = initHeader, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_MODAL_TRANSFERRED_SUCCESS':
      return {
        ...state,
        transferred: action.transferred,
        isLoadedTransferred: true,
      };
    case 'ORDER_DETAILS_MODAL_TRANSFERRED_CLEAR':
      return initHeader;
    default:
      return state;
  }
};

const initHistory = {
  history: [],
  isLoadedHistory: false,
};

const history = (state = initHistory, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_MODAL_HISTORY_SUCCESS':
      return {
        ...state,
        history: action.history,
        isLoadedHistory: true,
      };
    case 'ORDER_DETAILS_MODAL_HISTORY_CLEAR':
      return initHistory;
    default:
      return state;
  }
};

const initDriverModal = {
  selectedDriver: 0,
};

const modalSelectDriver = (state = initDriverModal, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_SELECT_DRIVER_MODAL_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_DETAILS_SELECT_DRIVER_MODAL_CLEAR':
      return initDriverModal;
    default:
      return state;
  }
};

const initTabs = {
  key: 1,
};

const tab = (state = initTabs, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_CHANGE_TAB_KEY':
      return {
        ...state,
        key: action.value,
      };
    default:
      return state;
  }
};

const initSandTickets = {
  isLoaded: false,
  sandTicketTypes: [],
  selectedTicket: 0,
};

const sandTicket = (state = initSandTickets, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_TICKET_DESIGN':
      return {
        ...state,
        isLoaded: true,
        sandTicketTypes: action.sandTicketTypes,
      };
    case 'ORDER_DETAILS_SELECT_TICKET':
      return {
        ...state,
        isLoaded: true,
        selectedTicket: action.item.id,
      };
    default:
      return state;
  }
};

const modalPreviewST = (state = {}, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_MODAL_PREVIEW_INIT_IMG':
      return {
        ...state,
        img: action.image,
      };
    case 'ORDER_DETAILS_CLEAR':
      return {};
    case 'ORDER_DETAILS_CLEAR_GET_TICKET':
      return {};
    default:
      return state;
  }
};

const initEditST = {};

const modalEditST = (state = initEditST, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_MODAL_EDIT_ST':
      return {
        ...state,
        id: action.stInfo.id,
        lableName: action.stInfo.lableName,
        sand_ticket_type: action.stInfo.sand_ticket_type,
        ticket_number: action.stInfo.ticket_number,
      };
    case 'ORDER_DETAILS_MODAL_EDIT_ST_SET_INPUT':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_DETAILS_CLEAR':
      return initEditST;
    case 'ORDER_DETAILS_CLEAR_EDIT_TICKET':
      return initEditST;
    default:
      return state;
  }
};

const initComments = {
  isLoaded: false,
  comments: [],
  comment: '',
};

const logs = (state = initComments, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_COMMENT':
      return {
        ...state,
        isLoaded: true,
        comments: action.comments,
      };
    case 'ORDER_DETAILS_SET_ORDER_COMMENT':
      return {
        ...state,
        comment: action.comment,
      };
    case 'ORDER_DETAILS_CLEAR':
      return initComments;
    default:
      return state;
  }
};

const initAddPrice = {
  price: '',
  comment: '',
};

const modalAddPrice = (state = initAddPrice, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_MODAL_ADD_PRICE_SET_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_DETAILS_MODAL_ADD_PRICE_SUCCESS':
      return initAddPrice;
    default:
      return state;
  }
};

const initDemurrage = {
  demurrage: [],
  selectId: '',
  itemPriceDemurr: {},
};

const demurrage = (state = initDemurrage, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_MODEL_DEMURAGE':
      return {
        ...state,
        selectId: action.selectId,
        itemPriceDemurr: action.item,
      };
    case 'ORDER_DETAILS_MODAL_DEMURRAGE_SET_VALUE':
      return {
        ...state,
        selectId: action.selectId,
      };
    default:
      return state;
  }
};

const initEditPriceInfo = {
  priceModel: [],
  rateTables: [],
  input: {},
  priceModelReady: false,
};

const priceInfo = (state = initEditPriceInfo, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_MODAL_PRICE_SET_INIT_MODAL':
      return {
        ...state,
        priceModelReady: true,
        input: {
          percentage: action.priceModel.carrier.percentage,
          formulaType: action.priceModel.carrier.formulaType,
          applyCarrierPercentageTo:
            action.priceModel.carrier.applyCarrierPercentageTo,
          typeProperty: action.priceModel.delivery.type,
          rateTableId: action.priceModel.delivery.rateTableId,
          mileage: action.priceModel.delivery.mileage,
          billableMileage: action.priceModel.delivery.billableMileage,
          customRate: action.priceModel.delivery.customRate,
        },
      };
    case 'ORDER_DETAILS_MODAL_PRICE_SET_INIT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_DETAILS_MODAL_PRICE_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

const initChat = {
  chatId: '',
  driverId: '',
  key: '',
  messages: [],
  messagesReady: false,
  text: '',
  isFile: false,
  file: null,
  fileName: '',
};

const chat = (state = { ...initChat }, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_DRIVER_CHAT':
      return {
        ...state,
        chatId: action.chatId,
        driverId: action.driverId,
      };
    case 'ORDER_DETAILS_SAVE_LAST_KEY':
      return {
        ...state,
        key: action.key,
      };
    case 'ORDER_DETAILS_SAVE_MESSAGGES':
      return {
        ...state,
        messages: action.messages,
        messagesReady: true,
      };
    case 'ORDER_DETAILS_SEND_MESSAGGES':
      return {
        ...state,
        text: '',
        isFile: false,
        fileName: '',
        file: null,
      };
    case 'ORDER_DETAILS_SET_CHAT_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'ORDER_DETAILS_SET_FILE':
      return {
        ...state,
        file: action.file,
        fileName: action.file.name,
        isFile: true,
      };
    case 'ORDER_DETAILS_UNSET_FILE':
      return {
        ...state,
        isFile: false,
        fileName: '',
        file: null,
      };
    case 'ORDER_DETAILS_SET_COUNT':
      return {
        ...state,
        messagesCount: action.messagesCount,
        messagesReady: true,
      };
    case 'ORDER_DETAILS_CHAT_CLEAR':
      return { ...initChat };
    default:
      return state;
  }
};

const initialFinancialsState = {
  info: {
    isBillable: false,
    billableCode: '',
  }
}

const editFinancials = (state = initialFinancialsState, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS_INIT_FINANCIAL_MODAL':
      return {
        ...state,
        info: action.data,
      };
    case 'ORDER_DETAILS_EDIT_FINANCIALS_SET_VALUE':
      return {
        ...state,
        info: {
          ...state.info,
          [action.name]: action.value,
        },
      };
    case 'ORDER_DETAILS_EDIT_FINANCIAL_CLEAR_EDIT':
      return initialFinancialsState;
    default:
      return state;
  }
};

export const orderDetails = combineReducers({
  info,
  tab,
  modalAddPrice,
  priceInfo,
  demurrage,
  sandTicket,
  modalPreviewST,
  modalEditST,
  logs,
  history,
  chat,
  modalSelectDriver,
  header,
  editFinancials,
});
