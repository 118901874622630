export const reasonsFormatted = reasons => {
  const demurrageReasons = [];
    if (reasons.length) {
      reasons.forEach(item => {
        demurrageReasons.push({
          id: item.id,
          name: item.name,
          margin: '',
          symbol: '',
          disabled: true,
        });
        item.children.forEach(child => {
          demurrageReasons.push({
            id: child.id,
            name: child.name,
            symbol: '-- |',
            disabled: false,
          });
        });
      });
    }

    return demurrageReasons.map(item => {
      item.label = `${item.symbol} ${item.name}`;
      return item;
    });
  };