export const timeZones = [
  {
    value: '-11',
    name: 'UTC-11: Samoa Standard Time',
  },
  {
    value: '-10',
    name: 'UTC-10: Hawaii-Aleutian Standard Time (HST)',
  },
  {
    value: '-9',
    name: 'UTC-9: Alaska Standard Time (AKST)',
  },
  {
    value: '-8',
    name: 'UTC-8: Pacific Standard Time (PST)',
  },
  {
    value: '-7',
    name: 'UTC-7: Mountain Standard Time (MST)',
  },
  {
    value: '-6',
    name: 'UTC-6: Central Standard Time (CST)',
  },
  {
    value: '-5',
    name: 'UTC-5: Eastern Standard Time (EST)',
  },
  {
    value: '-4',
    name: 'UTC-4: Atlantic Standard Time (AST)',
  },
  {
    value: '0',
    name: 'UTC+0',
  },
  {
    value: '10',
    name: 'UTC+10: Chamorro Standard Time',
  },
  {
    value: '12',
    name: 'UTC+12: Wake Island Time Zone',
  },
];

export const timeZonesAmerican = [
  {
    value: 'UTC',
    label: 'UTC',
  },
  {
    value: 'America/Monterrey',
    label: 'America/Monterrey',
  },
];

export const timeZonesForRates = [
  {
    value: 'Pacific Standard',
    label: 'Pacific Standard',
  },
  {
    value: 'Mountain Standard',
    label: 'Mountain Standard',
  },
  {
    value: 'Central Standard',
    label: 'Central Standard',
  },
  {
    value: 'Eastern Standard',
    label: 'Eastern Standard',
  },
]

export const timeZonesForTimings = [
  {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard',
  },
  {
    value: 'America/Denver',
    label: 'Mountain Standard',
  },
  {
    value: 'America/Chicago',
    label: 'Central Standard',
  },
  {
    value: 'America/New_York',
    label: 'Eastern Standard',
  },
]
