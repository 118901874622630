import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ItemsOrder = ({ props, setStep }) => {
  const { closeAddTemplate, config, createTemplate } = props;

  const defaultList = [];
  for (let i = 0; i < config.length; i++) {
    if (config[i].isChecked) {
      defaultList.push(config[i].value);
    }
  }

  const [itemList, setItemList] = useState(defaultList);

  // Function to update list on drop
  const handleDrop = droppedItem => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
  };

  return (
    <form id="drag-drop-list" role="form" style={{ fontSize: '18px' }} onSubmit={e => createTemplate(e, itemList)}>
      <Modal.Header>
        <h4 className="modal-title">
          Drag and drop items if you want to create a specific order for them
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable
              droppableId="droppable"
              mode="virtual"
              getContainerForClone={() => document.getElementById('drag-drop-list')}
              renderClone={(provided, snapshot, rubric) => (
                <div
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...provided.draggableProps}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  className="item-container"
                >
                  {itemList[rubric.source.index]}
                </div>
              )}
            >
              {(droppableProvided, droppableSnapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...droppableProvided.droppableProps}
                  className="list-container"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    flexDirection: 'column',
                    maxHeight: '400px',
                    overflowY: 'scroll'
                  }}
                >
                  {itemList.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index} >
                      {(draggableProvided, draggableSnapshot) => (
                        <div>
                          <div
                            ref={draggableProvided.innerRef}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...draggableProvided.draggableProps}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...draggableProvided.dragHandleProps}
                            className="item-container"
                          >
                            {item}
                          </div>
                          {draggableProvided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddTemplate}
          colour="white"
          children="Close"
          testSelector="export_templates_add_close_btn"
        />
        <Button
          onClick={() => setStep(1)}
          children="Prev"
          testSelector="export_templates_add_prev_btn"
        />
        <Button
          type="submit"
          children="Save"
          testSelector="export_templates_add_save_btn"
        />
      </Modal.Footer>
    </form>
  );
};

export default ItemsOrder;
