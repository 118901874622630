import axios from 'axios';

export const commodityTypes = {
  get() {
  return axios.get('v2/job-manager/commodity-types', {});
  },
  getReference(id) {
    return axios.get(`v2/commodity/${id}/references`, {});
  },
  addReference(data) {
    return axios.post(`v2/commodity/${data.commodityId}/references`, data);
  },
};