import { get } from 'services/supplyChainMap';

export const supplyChainMapRequested = alertedOnly => async dispatch => {
  dispatch({ type: 'SUPPLY_CHAIN_MAP_REQUESTED' });

  const supplyChainMapResults = await get({alertedOnly}).catch(error => {
    return dispatch({ type: 'SUPPLY_CHAIN_MAP_REQUEST_FAILED' });
  });

  return dispatch({
    jobs: supplyChainMapResults.data.data.jobs,
    type: 'SUPPLY_CHAIN_MAP_REQUEST_SUCCEEDED',
  });
};

export default {
  supplyChainMapRequested,
};
