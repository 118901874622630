import React, { Suspense } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { authorize } from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import JobCalendar from './JobCalendar';

const Billing = React.lazy(() => import('./Billing'));
const LocationsList = React.lazy(() => import('./LocationsList'));
const JobsList = React.lazy(() => import('../JobsList'));
const NotarizationSettings = React.lazy(() => import('./NotarizationSettings'));

const CustomerTabs = ({ customerId }) => (
  <div
    className="wrapper-content animated fadeInUp"
    style={{ minHeight: window.innerHeight }}>
    <div className="row">
      <Tabs defaultActiveKey="jobs" id="customer-tabs">
        {authorize({
          abilityPermissions: [{ resource: 'Jobs', permissions: ['read'] }],
        }) && (
          <Tab eventKey="jobs" title="Jobs">
            <Suspense fallback={<Loader />}>
              <JobsList customerId={customerId} />
            </Suspense>
          </Tab>
        )}
        {authorize({
          abilityPermissions: [{ resource: 'Jobs', permissions: ['read'] }],
          forbidden: ['CARRIER'],
        }) && (
          <Tab eventKey="calendar" title="Job Calendar">
            <JobCalendar customerId={customerId} />
          </Tab>
        )}
        {authorize({
          abilityPermissions: [
            { resource: 'Locations', permissions: ['read'] },
          ],
        }) && (
          <Tab eventKey="locations" title="Locations">
            <Suspense fallback={<Loader />}>
              <LocationsList customerId={Number(customerId)} />
            </Suspense>
          </Tab>
        )}
        {authorize({
          abilityPermissions: [{ resource: 'Pricing', permissions: ['read'] }],
        }) && (
          <Tab eventKey="billing" title="Billing">
            <Suspense fallback={<Loader />}>
              <Billing customerId={Number(customerId)} />
            </Suspense>
          </Tab>
        )}
        <Tab eventKey="notarizations" title="Notarization Settings">
          <Suspense fallback={<Loader />}>
            <NotarizationSettings customerId={Number(customerId)} />
          </Suspense>
        </Tab>
      </Tabs>
    </div>
  </div>
);

export default CustomerTabs;
