import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';

const Alerts = ({
  closeAddJobModal,
  addJob,
  formHandlers,
}) => {
  const {
    minDriversAmount,
    demurrageAlert,
    messagesAllowed,
    phoneNumbers,
  } = addJob;
  const {
    setStep,
    setValue,
    setArrayValue,
    deleteArrayValue
  } = formHandlers;

  return (
    <form
      className="wizard-big wizard"
      onSubmit={(e) => {
        e.preventDefault();
        setStep(4)
      }}>
      <Modal.Body>
        <div style={{ padding: '20px 10px' }}>
          <div className="step2">
            <h4 className="title">Alerts</h4>
            <fieldset id="form-p-1" role="tabpanel" className="body">
              <Row className="no-outer-gutter">
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    testSelector="jobs-list_add-job_alerts_enable-sms_input"
                    checked={messagesAllowed}
                    onChange={setValue('messagesAllowed')}
                    label="Enable SMS alerts"
                  />
                </div>
                {messagesAllowed && (
                  <div className="form-group has-feedback col-md-6">
                    {phoneNumbers.map((item, index) => (
                      <Row className="no-outer-gutter" key={`phone-${index}`}>
                        <div
                          className="form-group has-feedback col-md-2"
                          style={{ padding: 0 }}>
                          <Input
                            onChange={e =>
                              setArrayValue(e, index, 'code', 'phoneNumbers')
                            }
                            value={item.code}
                            label="Code"
                            required
                            testSelector="jobs_add_code_input"
                          />
                        </div>
                        <div
                          className="form-group has-feedback col-md-10"
                          style={{ padding: 0 }}>
                          <Input
                            onChange={e =>
                              setArrayValue(e, index, 'number', 'phoneNumbers')
                            }
                            value={item.number || ''}
                            label="Number"
                            required
                            testSelector="jobs_add_number_input"
                          />
                        </div>
                        {index !== 0 && (
                          <div className="form-group has-feedback col-md-2">
                            <Icon
                              icon="trash del-icon"
                              onClick={() =>
                                deleteArrayValue(index, 'phoneNumbers')
                              }
                              style={{
                                paddingTop: '30px',
                              }}
                            />
                          </div>
                        )}
                      </Row>
                    ))}
                  </div>
                )}
              </Row>
              {messagesAllowed && (
                <Row className="no-outer-gutter">
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={setValue('minDriversAmount')}
                      value={minDriversAmount}
                      label="Notify when active drivers count drops below"
                      min="0"
                      required
                      testSelector="jobs_add-job_alerts_notify-when-driver_input"
                    />
                  </div>
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={setValue('demurrageAlert')}
                      value={demurrageAlert}
                      label="Notify when demurrage reaches (hours)"
                      min="0"
                      required
                      testSelector="jobs_add-job_alerts_notify-when-demurrage_input"
                    />
                  </div>
                </Row>
              )}
              {/* <div className="no-outer-gutter">
              <div className="form-group has-feedback col-md-12">
                <div style={{ marginTop: 20 }}>
                  <Input
                    type="checkbox"
                    label="Send completion code to driver"
                    testSelector="jobs-list_add-job_alerts_send-code-to-driver_input"
                    isChecked={sendCompletionCodeToDriver}
                    onChange={e =>
                      setValue(e, 'sendCompletionCodeToDriver')
                    }
                  />
                </div>
              </div>
            </div> */}
            </fieldset>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_alerts_close_btn">
          Close
        </Button>
        <Button
          onClick={() => setStep(2)}
          testSelector="jobs-list_add-modal_alerts_previous_btn">
          Previous
        </Button>
        <Button
          type="submit"
          testSelector="jobs-list_add-modal_alerts_next_btn">
          Next
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Alerts;
