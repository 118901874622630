import React from 'react';

const Calendar = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill">
    <g>
      <path d="M9.786 12.812a.367.367 0 0 0-.371-.363H8.121a.368.368 0 0 0-.37.363v1.265c0 .2.165.362.37.362h1.294c.205 0 .37-.162.37-.362l.001-1.265zm3.234 0a.366.366 0 0 0-.369-.363h-1.295a.368.368 0 0 0-.37.363v1.265c0 .2.166.362.37.362h1.295a.366.366 0 0 0 .369-.362v-1.265zm3.235 0a.366.366 0 0 0-.37-.363H14.59a.368.368 0 0 0-.37.363v1.265c0 .2.165.362.37.362h1.294a.366.366 0 0 0 .371-.362v-1.265zm-6.469 3.163a.367.367 0 0 0-.371-.363H8.121a.366.366 0 0 0-.37.361v1.266c0 .201.165.363.37.363h1.294c.205 0 .37-.162.37-.363l.001-1.264zm3.234 0a.366.366 0 0 0-.369-.363h-1.295a.366.366 0 0 0-.37.361v1.266c0 .201.166.363.37.363h1.295a.366.366 0 0 0 .369-.363v-1.264zm3.235 0a.366.366 0 0 0-.37-.363H14.59a.367.367 0 0 0-.37.363v1.264c0 .201.165.363.37.363h1.294a.367.367 0 0 0 .371-.363v-1.264z" />
      <path d="M18.57 5.778v1.933c0 .873-.727 1.577-1.619 1.577h-1.02c-.895 0-1.629-.704-1.629-1.577V5.77h-4.6v1.94c0 .873-.735 1.577-1.628 1.577h-1.02c-.893 0-1.618-.704-1.618-1.577V5.778c-.78.023-1.422.655-1.422 1.431v11.322c0 .791.655 1.441 1.464 1.441h13.049c.807 0 1.464-.65 1.464-1.441V7.21c0-.776-.642-1.408-1.423-1.43l.002-.002zm-.475 12.049a.624.624 0 0 1-.633.618H6.515a.625.625 0 0 1-.633-.618v-5.849c0-.342.284-.619.633-.619h10.947c.351 0 .633.277.633.62v5.848z" />
      <path d="M7.05 8.252h1.01a.55.55 0 0 0 .555-.542V4.542c0-.3-.249-.542-.555-.542H7.05a.55.55 0 0 0-.555.542V7.71c0 .3.249.542.555.542zm8.867 0h1.01a.548.548 0 0 0 .556-.541V4.542A.55.55 0 0 0 16.927 4h-1.01a.55.55 0 0 0-.554.542V7.71c0 .3.248.542.554.542z" />
    </g>
    >
  </svg>
);

export default Calendar;
