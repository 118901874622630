import axios from 'axios';

export const exportService = {
  getConfig() {
    return axios.get('v2/export/config');
  },
  getTemplates() {
    return axios.get('v2/export/templates');
  },
  createTemplate(name, fields) {
    return axios.post('v2/export/templates', {
      name,
      fields,
    });
  },
  getTemplate(id) {
    return axios.get(`v2/export/templates/${id}`);
  },
  getLookupTemplates() {
    return axios.get(`v2/lookup/templates/invoices/bulk`);
  },
  deleteTemplate(id) {
    return axios.delete(`v2/export/templates/${id}`);
  },
  getFile(params) {
    return axios.get('v2/export/format', {
      params,
    });
  },
  bulkInvoicePreview(data) {
    return axios.post('v2/billing/bulkinvoice/generate?preview=true', data);
  },
  bulkInvoiceGenerate(data) {
    return axios({
      method: 'post',
      url: `v2/billing/bulkinvoice/generate${data.chunk}`,
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer',
      data,
    });
  },
};
