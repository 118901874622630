import React from 'react';
import { connect } from 'react-redux';
import { convertTime } from 'utils/convertTime';
import Icon from 'components/shared/Icon';

const Logins = ({ logins }) => (
  <div className="ibox-content">
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox-content">
          <div style={{ marginTop: 10 }}>
            {logins.map((i, index) => (
              <div
                key={`logins-${index}`}
                className="tree-node tree-node-content">
                <span>
                  <a
                    className="button button--small"
                    style={{
                      marginRight: '10px',
                    }}>
                    <Icon icon="clock-o" />
                  </a>
                  {convertTime(i.occured_at)}
                </span>
                <span />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  logins: state.lookUp.user.logins,
});

export const LoginsTest = Logins; // for tests

export default connect(
  mapStateToProps,
  null,
)(Logins);
