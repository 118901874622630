import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import locationsActions from 'store/actions/LocationsTiming';
import { Modal } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import TimingList from 'components/views/Locations/TimingList';
import Update from 'components/views/Locations/Update';

const Timing = ({
  clear,
  closeModal,
  openModal,
  update,
  modalTiming,
  setInputTiming,
  addItemTiming,
  dellItemTiming,
  setTimeZone,
  setExemptions
}) => {
  useEffect(() => () => clear(), [clear]);

  const { timing, updatetimings, isLoaded, timeZone, exemptions } = modalTiming;
  const [isSaveDisabled, disableSave] = useState(false);

  return (
    <Modal className="modal-container" show={openModal} onHide={closeModal}>
      <form onSubmit={e => update(e, closeModal)}>
        <Modal.Body>
          {isLoaded ? (
            [
              <TimingList key="timing-list" timing={timing} />,
              <Update
                key="update"
                updateTimings={updatetimings}
                setInputTiming={setInputTiming}
                dellItemTiming={dellItemTiming}
                addItemTiming={addItemTiming}
                disableSave={disableSave}
                isSaveDisabled={isSaveDisabled}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                setExemptions={setExemptions}
                exemptions={exemptions}
              />,
            ]
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="locations_timing-modal_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            testSelector="locations_timing-modal_save_btn"
            disabled={isSaveDisabled}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalTiming: state.locations.modalTiming,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(locationsActions.clear()),
  update: (e, closeModal) => {
    e.preventDefault();
    dispatch(locationsActions.update(closeModal));
  },
  setInputTiming: (e, index, day, name) => {
    dispatch(locationsActions.setInputTiming(e, index, day, name));
  },
  setTimeZone: e => {
    dispatch(locationsActions.setTimeZone(e.value));
  },
  setExemptions: e => {
    dispatch(locationsActions.setExemptions(e));
  },
  addItemTiming: (name, day) => dispatch(locationsActions.addItemTiming(name, day)),
  dellItemTiming: (e, index, day) => {
    e.preventDefault();
    dispatch(locationsActions.dellItemTiming(index, day));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Timing);
