import ReactDOMServer from 'react-dom/server';
import InfoWindow from './AutomatizeInfoWindowClass';

const AutomatizeInfoWindow = ({
  map,
  position,
  title,
  onHide,
  width,
  height,
  children,
}) => {
  const instances = [];

  const renderChildren = () => ReactDOMServer.renderToString(children);

  const renderPopup = () => {
    if (!map) {
      return;
    }

    const infoWindow = InfoWindow({
      // eslint-disable-next-line no-undef
      latlng: new google.maps.LatLng(position.lat, position.lng),
      map,
      title: ReactDOMServer.renderToString(title),
      content: renderChildren(),
      onHide: state => onHide(state) || null,
      width,
      height,
    });

    instances.push(infoWindow);

    instances
      .filter(instance => instance !== infoWindow)
      .forEach(instance => {
        if (!instance.pinned) instance.remove();
      });
  };

  renderPopup();

  return null;
};

export default AutomatizeInfoWindow;
