import React, { useEffect, useState } from 'react';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';

const MapControls = ({ map }) => {
  const [mapId, setMapId] = useState(map.getMapTypeId());
  const [zoom, setZoom] = useState(map.getZoom());

  useEffect(() => {
    map.setMapTypeId(mapId);
  }, [mapId]);

  useEffect(() => {
    map.setZoom(zoom);
  }, [zoom]);

  const incrementZoom = () => setZoom(map.getZoom() + 1);

  const decrementZoom = () => setZoom(map.getZoom() - 1);

  return (
    <>
      <div className="home-page__map-controls__map-id-control">
        <Button
          onClick={() => setMapId('roadmap')}
          className="roadmap"
          isActive={mapId === 'roadmap'}
          testSelector="google-map_roadmap_btn"
          colour="dark">
          Roadmap
        </Button>
        <Button
          onClick={() => setMapId('satellite')}
          className="satellite"
          isActive={mapId === 'satellite'}
          testSelector="google-map_satellite_btn"
          colour="dark">
          Satellite
        </Button>
      </div>
      <div className="home-page__map-controls__zoom-control">
        <Button
          onClick={incrementZoom}
          className="zoom_in"
          theme="square"
          colour="dark"
          testSelector="google-map_zoom-in_btn"
        >
          <Icon icon="zoom_in" colour="white" />
        </Button>
        <Button
          onClick={decrementZoom}
          className="zoom_out"
          theme="square"
          colour="dark"
          testSelector="google-map_zoom-out_btn"
        >
          <Icon icon="zoom_out" colour="white" />
        </Button>
      </div>
    </>
  );
};

export default MapControls;