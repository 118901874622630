import swal from 'bootstrap-sweetalert';
import { jobs } from 'services/jobs/service';

const init = jobId => dispatch => {
  const save = data => ({ type: 'JOB_STATS_SAVE_INIT_VALUE', data });
  jobs
    .report(jobId)
    .then(msg => {
      dispatch(save(msg.data.data));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

export default {
  init,
};
