export const locationPermissions = {
  '0': {
    title:
      'User has not granted authorization to use their location at any time',
    text: 'Pending',
    label: 'warning',
  },
  '1': {
    title:
      'Most probably driver disabled location for automatize application in settings.',
    text: 'Restricted',
    label: 'danger',
  },
  '2': {
    title:
      'User has explicitly denied authorization for this application, or location services are disabled in Settings',
    text: 'Rejected',
    label: 'danger',
  },
  '3': {
    title: 'User has granted authorization to use their location at any time',
    text: 'Always',
    label: 'primary',
  },
  '4': {
    title:
      'User has granted authorization to use their location only when your app is visible to them',
    text: 'When in use',
    label: 'warning',
  },
};
