/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const getUsers = state => {
  return state.users.info.users;
};
const getFilter = state => {
  return state.users.info.filter;
};

export const filterUsers = createSelector(
  [getUsers, getFilter],

  (users, filter) =>
    users &&
    users.filter(
      item =>
        item.email.search(new RegExp(filter.email || '', 'i')) != -1 &&
        (!filter.role || item.roles.some(i => i.roleTypeId == filter.role)),
    ),
);
