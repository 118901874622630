export const getLocationType = locationType => {
  switch (locationType) {
    case 1:
      return 'Well Site';
    case 2:
      return 'Sand Site';
    case 3:
      return 'Water Disposal';
    case 4:
      return 'Staging Site';
    case 5:
      return 'Acid Facility';
    default:
      return 'Unknown';
  }
};
