import swal from 'bootstrap-sweetalert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addStorageRecord,
  deleteHistoricalRecord,
  getHistoricalLevels,
  getHistoricalLevelsTimeline,
  getStorageDetails,
  importStorageFile,
} from './queries';

export const useStorageDetails = jobId =>
  useQuery({
    queryKey: ['jobs', jobId, 'storage'],
    queryFn: () => getStorageDetails({ jobId }),
    enabled: !!jobId,
  });

export const useHistoricalLevels = ({ jobId, storageId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'storage', 'history', storageId],
    queryFn: () => getHistoricalLevels({ jobId, storageId }),
    enabled: !!jobId,
  });

export const useHistoricalLevelsTimeline = ({
  jobId,
  intervalHours,
  storageId,
}) =>
  useQuery({
    queryKey: [
      'jobs',
      jobId,
      'storage',
      'history-timeline',
      intervalHours,
      storageId,
    ],
    queryFn: () =>
      getHistoricalLevelsTimeline({ jobId, intervalHours, storageId }),
    enabled: !!(jobId && intervalHours),
  });

export const useDeleteHistoricalRecord = (jobId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteHistoricalRecord,
    onSuccess: () => {
      swal('Successfully Deleted Storage Record', '', 'success');
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: ['jobs', jobId, 'storage'] }),
        5000,
      );
    },
    onError: error =>
      swal(
        'Unable to Delete Storage Record',
        error.response?.data?.message,
        'error',
      ),
  });
};

export const useAddStorageRecord = (jobId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addStorageRecord,
    onSuccess: () => {
      swal('Successfully Added Storage Record', '', 'success');
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: ['jobs', jobId, 'storage'] }),
        5000,
      );
    },
    onError: error =>
      swal(
        'Unable to Add Storage Record',
        error.response?.data?.message,
        'error',
      ),
  });
};

export const useImportStorageFile = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: file => importStorageFile({ jobId, file }),
    onSuccess: () => {
      swal('Successfully Imported Storage Records', '', 'success');
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: ['jobs', jobId, 'storage'] }),
        5000,
      );
    },
    onError: error =>
      swal(
        'Unable to Import Storage Records',
        error.response?.data?.message,
        'error',
      ),
  });
};
