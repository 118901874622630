import React from 'react';
import { components } from 'react-select';
import { TransitionGroup } from 'react-transition-group';

const { Placeholder, SingleValue } = components;

const AnimatedValueContainer = WrappedComponent => props => {
  // props are inherited from ValueContainer
  const {
    isMulti,
    children,
    hasValue,
    selectProps: {
      isClearable,
      menuIsOpen,
      placeholder,
      inputValue,
      required,
      value,
    },
  } = props;

  return (
    // make ValueContainer a transition group component and passes react-select and react-transition-group props
    <TransitionGroup component={WrappedComponent} {...props}>
      <Placeholder
        className={`${
          hasValue || inputValue.length > 0
            ? 'input__select__placeholder--resize'
            : ''
        }
          ${menuIsOpen ? 'input__select__placeholder--menu-is-open' : ''}
          `}
        {...props}>
        {placeholder}
        {required ? (
          <span className="input__select__placeholder--required"> *</span>
        ) : null}
      </Placeholder>
      {children}
      {!isMulti && (
        <SingleValue {...props}>{(value && !inputValue) && <>{value.label}</>}</SingleValue>
      )}
    </TransitionGroup>
  );
};

export default AnimatedValueContainer;
