import React from 'react';

const BusyDriver = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    className="icon--fill"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <path
        fill="#F7245B"
        fillRule="nonzero"
        d="M14.89 6.115A.35.35 0 0 0 14.63 6H8.704a.35.35 0 0 0-.26.115.38.38 0 0 0-.11.271v1.16h-.927a.862.862 0 0 0-.338.078.882.882 0 0 0-.292.193L5.63 9.012a.82.82 0 0 0-.13.182c-.037.068-.064.13-.081.184a1.04 1.04 0 0 0-.038.223 6.304 6.304 0 0 0-.015.208v.23l.003.211v1.932c-.1 0-.187.038-.26.115a.379.379 0 0 0-.11.271c0 .06.008.114.023.16a.228.228 0 0 0 .078.112l.096.07a.31.31 0 0 0 .136.035l.13.013c.023.002.072.002.148 0a9.06 9.06 0 0 1 .13-.003h.37c0 .426.145.79.434 1.092.29.302.639.453 1.048.453.409 0 .758-.151 1.047-.453.29-.302.434-.666.434-1.092h2.222c0 .426.145.79.434 1.092.29.302.639.453 1.048.453.409 0 .758-.151 1.047-.453.29-.302.434-.666.434-1.092.012 0 .055 0 .13.003a1.795 1.795 0 0 0 .278-.013.309.309 0 0 0 .136-.036 1.81 1.81 0 0 0 .096-.07.228.228 0 0 0 .078-.11.5.5 0 0 0 .023-.16V6.385a.38.38 0 0 0-.11-.271zm-6.777 7.383a.698.698 0 0 1-.52.23.698.698 0 0 1-.521-.23.758.758 0 0 1-.22-.543c0-.21.073-.39.22-.544a.698.698 0 0 1 .52-.23c.201 0 .375.077.521.23.147.153.22.334.22.544 0 .209-.073.39-.22.543zm.22-3.634H6.111v-.181c0-.053.017-.097.052-.133l1.129-1.177a.17.17 0 0 1 .127-.055h.914v1.546zm4.966 3.634a.698.698 0 0 1-.521.23.698.698 0 0 1-.521-.23.759.759 0 0 1-.22-.543c0-.21.073-.39.22-.544a.698.698 0 0 1 .52-.23c.201 0 .375.077.522.23a.76.76 0 0 1 .22.544c0 .209-.074.39-.22.543z"
      />
      <circle cx="10" cy="10" r="10" fillRule="nonzero" stroke="#F7245B" />
    </g>
  </svg>
);

export default BusyDriver;