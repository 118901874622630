import React, { Suspense, useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';

import Timeline from './Timeline';
import Comments from './Comments';
import Info from './Info';
import Price from './Price';

const OrderInfoModal = ({ order, show, handleClose }) => (
  <Modal className="modal-container" show={show} onHide={handleClose}>
    <Modal.Header>
      <h3 className="modal-title">Order Info</h3>
    </Modal.Header>
    <Modal.Body>
      <div className="panel blank-panel tab">
        <Tabs defaultActiveKey="order-info" id="order-info-tabs">
          <Tab eventKey="order-info" title="Order Info">
            <Info order={order} />
          </Tab>
          <Tab eventKey="price" title="Price">
            <Suspense fallback={<Loader />}>
              <Price order={order} />
            </Suspense>
          </Tab>
          <Tab eventKey="timeline" title="Timeline">
            <Timeline order={order} />
          </Tab>
          <Tab eventKey="comments" title="Comments">
            <Comments order={order} />
          </Tab>
        </Tabs>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        onClick={handleClose}
        testSelector="order-list_order-info_close_btn">
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

const OrderInfoWrapper = ({ order }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <Authorize
      abilityPermissions={[{ resource: 'Orders', permissions: ['read'] }]}>
      <Button
        key="turnModalInfo"
        onClick={handleShow}
        theme="small"
        colour="yellow"
        className="button--label-type"
        testSelector="order-list_order_header_info_btn">
        <Icon icon="info" />
      </Button>

      <OrderInfoModal
        order={order}
        show={showModal}
        handleClose={handleClose}
      />
    </Authorize>
  );
};
export default OrderInfoWrapper;
