export default {
  0: 'Open', // <- Pending
  2: 'Approved', // <- Confirmed
  4: 'Contested', // <- Disputed
  20: 'Ready to Bill',
  21: 'Billed',
  22: 'Paid',
  23: 'Settled',
  24: 'Non Billable',
};
