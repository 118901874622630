import { client } from 'api/client';

/**
 * @returns {Promise<Array>}
 */
export const getTagTypes = async () => {
  const { data } = await client.get('v2/tags/types');
  return data.data;
};

/**
 * @param {Object} info
 * @param {boolean} info.isDeleted
 * @param {number} info.id
 * @returns {Promise<Object>}
 */
export const deleteTag = async info => {
  const { data } = await client.patch(`v2/tags/${info.id}/delete`, info);
  return data;
};

/**
 * @param {Object} tag
 * @param {string} tag.name
 * @param {string} tag.description
 * @param {number} tag.type
 * @param {number} tag.id
 * @returns {Promise<Object>}
 */
export const editTag = async tag => {
  const { data } = await client.patch(`v2/tags/${tag.id}`, tag);
  return data;
};

/**
 * @param {Object} tag
 * @param {string} tag.name
 * @param {string} tag.description
 * @param {number} tag.type
 * @returns {Promise<Object>}
 */
export const addTag = async tag => {
  const { data } = await client.post('v2/tags', tag);
  return data;
};

/**
 * @param {{
 *  type?: number;
 * }} params
 * @returns {Promise<Array>}
 */
export const getTags = async ({ type } = {}) => {
  const params = {};
  if (type !== undefined) {
    params.type = type;
  }
  const { data } = await client.get('v2/tags', {
    params,
  });
  return data.data;
};
