import React from 'react';
import Icon from 'components/shared/Icon';

const Orders = ({ orders }) => (
  <div className="col-lg-4">
    <div className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>Orders</h5>
      </div>
      <div className="ibox-content">
        <div className="widget style1 navy-bg">
          <div className="row">
            <div className="col-xs-4">
              <Icon icon="list" style={{ fontSize: '40px' }} />
            </div>
            <div className="col-xs-8 text-right">
              <span> Total </span>
              <h2 className="font-bold">{orders.totalOrdersCount}</h2>
            </div>
          </div>
        </div>

        <div className="widget style1 navy-bg">
          <div className="row">
            <div className="col-xs-7 text-right">
              <span> In time </span>
              <h2 className="font-bold">{orders.inTimeOrdersCount}</h2>
            </div>
            <div className="col-xs-5 text-right">
              <span> Late </span>
              <h2 className="font-bold">{orders.lateOrdersCount}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Orders;
