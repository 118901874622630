import React, { useState } from 'react';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import { orderStatus } from 'components/globalInfo/orderStatus';
import Authorize from 'components/common/Authorize';
import { DateRange } from 'react-date-range';
import multiSelectOptions from 'utils/multiSelectOptions';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import { useCustomers } from 'api/v2/customers';
import { useJobs } from 'api/v2/jobs';
import { useCarriers } from 'api/v2/carriers';
import { useDistricts } from 'api/v2/districts';
import LocationsSelect from './LocationsSelect';

const sortByOptions = [
  { value: 'order_id DESC', label: 'New first' },
  { value: 'order_id', label: 'ID' },
  { value: 'status', label: 'Status' },
  { value: 'unload_appt', label: 'Unload Appointment' },
  { value: 'expected_pickup_time', label: 'Expected Pickup Time' },
  { value: 'eta', label: 'ETA' },
  { value: 'well_arrival', label: 'Well arrival' },
  { value: 'loaded_from', label: 'Origin' },
  { value: 'well_depart DESC', label: 'Destination Departure' },
  { value: 'truck_status DESC', label: 'Truck Status' },
];
const sortByDateOptions = [
  { value: 'order_accepted_at DESC', label: 'Order Accepted' },
  { value: 'load_arrival DESC', label: 'Load arrival' },
  { value: 'load_depart DESC', label: 'Load departure' },
  { value: 'well_arrival DESC', label: 'Well arrival' },
  { value: 'well_depart DESC', label: 'Well departure' },
  { value: 'truck_status DESC', label: 'Truck Status' },
];

const FilterOrders = ({ filters, setFilterValue, setDate, count }) => {
  const {
    order_id: orderId,
    stage,
    sort,
    truck,
    statuses,
    show,
    carrierIds,
    customerIds,
    districtIds,
    jobIds,
    startDate,
    endDate,
    origin,
  } = filters;
  // const { customers, jobs, carriers, districts } = info;
  const [allowDateChange, setAllowDateChange] = useState(false);

  const { data: customers } = useCustomers({
    enabled: filters.show.customers
  });
  const { data: jobs } = useJobs({}, {
    enabled: filters.show.jobs
  });
  const { data: carriers } = useCarriers({
    enabled: filters.show.carriers
  });
  const { data: districts } = useDistricts({
    enabled: filters.show.districts
  });

  const selectionRange = {
    startDate: moment(startDate).toDate(),
    endDate: moment(endDate).toDate(),
    key: 'selection',
  };
  if (stage && stage.show) {
    sortByOptions.push({ value: 'stage', label: 'Stage' });
  }

  return (
    <Row>
      {show.order_id && (
        <Col md={2} className="form-group has-feedback">
          <Input
            type="number"
            onChange={e => setFilterValue(e, 'order_id')}
            value={orderId}
            label="Order ID"
            testSelector="order-list_filters_order_id_input"
          />
        </Col>
      )}
      {show.truck && (
        <Col md={2} className="form-group has-feedback">
          <Input
            onChange={e => setFilterValue(e, 'truck')}
            value={truck}
            label="Truck"
            testSelector="order-list_filters_truck_input"
          />
        </Col>
      )}
      {show.stage && (
        <Col md={2} className="form-group has-feedback">
          <Input
            type="number"
            onChange={e => setFilterValue(e, 'stage')}
            value={stage}
            label="Stage"
            testSelector="order-list_filters_srage_input"
          />
        </Col>
      )}
      {show.sort && (
        <Col md={2} className="form-group has-feedback">
          <Select
            value={sortByOptions.find(item => item.value === sort)}
            options={sortByOptions}
            onChange={item => setFilterValue(item.value, 'sort')}
            placeholder="Sort by:"
            testSelector="order-list_filters_sort-by_select"
          />
        </Col>
      )}
      <Col md={show.date ? 7 : 4} className="form-group has-feedback">
        <Row>
          {show.customerIds && (
            <Authorize forbidden={['CUSTOMER']}>
              <Col md={6} className="form-group has-feedback">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selected => setFilterValue(selected, 'customerIds')}
                  options={multiSelectOptions(customers, 'id', 'name')}
                  placeholder="Select customers"
                  defaultValue={customerIds}
                  testSelector="order-list_filters_customers_select"
                />
              </Col>
            </Authorize>
          )}
          {show.jobIds && (
            <Authorize forbidden={['JOB_COORDINATOR']}>
              <Col md={6} className="form-group has-feedback">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selected => setFilterValue(selected, 'jobIds')}
                  options={multiSelectOptions(jobs, 'id', 'job_name')}
                  placeholder="Select jobs"
                  defaultValue={jobIds}
                  testSelector="order-list_filters_jobs_select"
                />
              </Col>
            </Authorize>
          )}
          {show.carrierIds && (
            <Authorize forbidden={['CARRIER']}>
              <Col md={6} className="form-group has-feedback">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selected => setFilterValue(selected, 'carrierIds')}
                  options={multiSelectOptions(carriers, 'id', 'name')}
                  placeholder="Select carriers"
                  defaultValue={carrierIds}
                  testSelector="order-list_filters_carriers_select"
                />
              </Col>
            </Authorize>
          )}
          {show.districtIds && (
            <Col md={6} className="form-group has-feedback">
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={selected => setFilterValue(selected, 'districtIds')}
                options={multiSelectOptions(districts, 'id', 'name')}
                placeholder="Select district"
                defaultValue={districtIds}
                testSelector="order-list_filters_districts_select"
              />
            </Col>
          )}
          {show.statuses && (
            <Col
              md={show.date ? 6 : 12}
              className="form-group has-feedback full-width-multi">
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={selected => setFilterValue(selected, 'statuses')}
                options={multiSelectOptions(orderStatus, 'id', 'name')}
                placeholder="Select status"
                defaultValue={statuses}
                testSelector="order-list_filters_status_select"
              />
            </Col>
          )}
          {show.origin && (
            <Col
              md={show.date ? 6 : 12}
              className="form-group has-feedback full-width-multi">
              <LocationsSelect
                onChange={selected => setFilterValue(selected, 'origin')}
                defaultValue={origin}
              />
            </Col>
          )}
          {show.sortByDate && (
            <Col
              md={show.date ? 6 : 12}
              className="form-group has-feedback full-width-multi">
              <Select
                value={sortByDateOptions.find(item => item.value === sort)}
                options={sortByDateOptions}
                onChange={item => {
                  if (!item) {
                    setFilterValue('order_id DESC', 'sort');
                  } else {
                    setFilterValue(item.value, 'sort');
                  }
                }}
                placeholder="Sort by:"
                testSelector="order-list_filters_sort-by-date_select"
                isClearable
              />
            </Col>
          )}
        </Row>
      </Col>
      {show.date && (
        <Col md={5} sm={12} className="form-group has-feedback">
          <div
            className="clearfix"
            style={{ display: 'flex', justifyContent: 'center' }}>
            <DateRange
              direction="horizontal"
              showSelectionPreview={false}
              ranges={[selectionRange]}
              onRangeFocusChange={date => {
                if (date[1] === 1) {
                  setAllowDateChange(true);
                } else {
                  setAllowDateChange(false);
                }
              }}
              onChange={value => {
                if (allowDateChange) {
                  setDate(value);
                } else {
                  setDate({
                    selection: {
                      startDate: value.selection.startDate,
                    },
                  });
                }
              }}
              testSelector="order-list_filters_date_dp"
            />
          </div>
        </Col>
      )}
      {show.count && (
        <Col sm={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Order count: {count}
          </div>
        </Col>
      )}
    </Row>
  );
};
export default FilterOrders;
