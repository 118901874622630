import React, { useMemo } from 'react';
import moment from 'moment';

import Icon from 'components/shared/Icon';
import Label from 'components/shared/Label';

import { checkTime, checkIsNaN } from './helpers';

const Line = (title, value) => (
  <span>
    <strong className="stats-label">{title} - </strong>
    <span className="stats-label">{checkTime(value)}</span>
  </span>
);

const timeLateFaster = value => {
  if (Number.isNaN(Number(value))) {
    return <span>-</span>;
  }

  return (
    <span>
      {value < 0 ? (
        <strong className="stats-label text-danger">
          {Math.abs(value)}m late
        </strong>
      ) : (
        <strong className="stats-label text-navy">{value}m faster</strong>
      )}
    </span>
  );
};

const Timeline = ({ order }) => {
  const timelineOrder = useMemo(() => {
    order.accepted_minutes = moment(order.accepted_at).diff(
      moment(order.load_arrival),
      'minutes',
    );
    order.loading_minutes = moment(order.load_depart).diff(
      moment(order.load_arrival),
      'minutes',
    );
    order.loading_late = moment(order.load_arrival).diff(
      moment(order.expected_pickup_time),
      'minutes',
    );
    order.unloading_minutes = moment(order.well_depart).diff(
      moment(order.well_arrival),
      'minutes',
    );
    order.unloading_late = moment(order.well_arrival).diff(
      moment(order.unload_appt),
      'minutes',
    );

    return order;
  }, [order]);

  return (
    <>
      <div>
        <h3 className="col-md-7 no-padding">
          {Line('ACCEPTED', timelineOrder.order_accepted_at)}
        </h3>
        <div className="col-md-5 text-right no-padding">
          <h3 className="text-navy">
            {checkIsNaN(timelineOrder.accepted_minutes)}
          </h3>
          <Label title="Deadhead miles/Minutes">
            <Icon icon="info" />
          </Label>
        </div>
      </div>
      <div>
        <div className="col-md-6 no-padding">
          <h4 className="text-navy">LOADING: </h4>
        </div>
        <div className="col-md-6 text-right no-padding">
          <h4
            className={`${
              timelineOrder.loading_minutes >= 0 &&
              timelineOrder.loading_minutes <= 120
                ? 'text-navy'
                : 'text-danger'
            }`}>
            {checkIsNaN(timelineOrder.loading_minutes)}
          </h4>
          <Label title="Loading time">
            <Icon icon="info" />
          </Label>
        </div>

        <div className="col-md-12 no-padding">
          <div className="col-md-6 no-padding">
            {Line('Arrived', timelineOrder.load_arrival)}
            <br />
            {Line('Departed', timelineOrder.load_depart)}
          </div>
          <div className="col-md-6 no-padding">
            {Line('Scheduled', timelineOrder.expected_pickup_time)}
            <br />
            {timeLateFaster(timelineOrder.loading_late)}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-12 no-padding" style={{ marginTop: '22px' }}>
          <div className="col-md-6 no-padding">
            <h4 className="text-navy">UNLOADING: </h4>
          </div>
          <div className="col-md-6 text-right no-padding">
            <h4
              className={`${
                timelineOrder.unloading_minutes >= 0 &&
                timelineOrder.unloading_minutes <= 120
                  ? 'text-navy'
                  : 'text-danger'
              }`}>
              {checkIsNaN(timelineOrder.unloading_minutes)}
            </h4>
            <Label title="Unloading time">
              <Icon icon="info" />
            </Label>
          </div>
        </div>
        <div className="col-md-12 no-padding">
          <div className="col-md-6 no-padding">
            {Line('Arrived', timelineOrder.well_arrival)}
            <br />
            {Line('Departed', timelineOrder.well_depart)}
          </div>
          <div className="col-md-6 no padding">
            {Line('Scheduled', timelineOrder.unload_appt)}
            <br />
            {timeLateFaster(timelineOrder.unloading_late)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
