import authService from 'services/auth/service';

import { useCarriers } from 'api/v2/carriers';
import { carrierOptions } from './helpers';

const user = authService.getUser();

export const useCarrierOptions = () => {
  const { data: carriers } = useCarriers();
  return carrierOptions(carriers, user);
};
