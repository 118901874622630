import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import { confirmAlert } from 'components/sweetalert';
// importing services
import { order } from 'services/order/service';
import { driver } from 'services/driver/service';

import booleanToNumber from 'utils/booleanToNumber';
import { sandSite } from '../../services/sandSite/service';

const setSelectedOrder = orderId => ({
  type: 'SET_SELECTED_ORDER',
  orderId,
});

const getSandSiteSchedule = (sandSiteId, checkOrder, date) => (
  dispatch,
  getState,
) => {
  const request = () => ({ type: 'GET_SAND_SITE_SCHEDULE_REQUEST' });
  const success = (schedule, sandSiteId) => ({
    type: 'GET_SAND_SITE_SCHEDULE_SUCCESS',
    schedule,
    sandSiteId,
  });
  const failure = error => ({
    type: 'GET_SAND_SITE_SCHEDULE_FAILURE',
    error,
  });

  dispatch(request());

  const state = getState();
  const id = sandSiteId || state.sandSiteSchedule.schedule.sandSiteId;
  const time = date
    ? moment(date)
      .startOf('day')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
    : moment()
      .startOf('day')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
  sandSite
    .getSandSiteSchedule(id, time)
    .then(response => {
      response.data.data.slots = response.data.data.break.map(item => ({
        id: item.id,
        title: `Maintenance`,
        start: moment(item.start)
          .local()
          .toDate(),
        end: moment(item.end)
          .local()
          .toDate(),
        confirmationStatus: '',
        appointmentTime: '',
        eventType: 'maintenance',
        description: item.description,
        resourceId: item.lineId,
        loadindEta: item.loadindEta,
      }));
      dispatch(success(response.data.data));
      if (checkOrder) {
        dispatch(checkOrderInMaintenance());
      }
    })
    .catch(error => {
      dispatch(failure(error));
      swal(error.response.data.message, '', 'error');
    });
};

const confirmAppointment = (data, closeModal) => (dispatch, getState) => {
  const confirmOrder = orders => ({
    type: 'SAND_SITE_SCHEDULE_CONFIRM_ORDER',
    orders,
  });
  order
    .confirmAppointment(data.orderId || data.id)
    .then(response => {
      const state = getState();
      const orders = [...state.sandSiteSchedule.schedule.schedule.orders];

      orders.forEach(element => {
        if (element.orderId == data.id) {
          element.confirmationStatus = 1;
        }
      });

      dispatch(confirmOrder(orders));

      if (closeModal) {
        closeModal();
      }
      sandSite.getSandSiteSchedule(undefined, data.loadindEta);
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setLanesModalData = data => ({
  type: 'SET_LANES_MODAL_DATA',
  data,
});

const deleteLanes = lane => (dispatch, getState) => {
  const state = getState();
  const { sandSiteId } = state.sandSiteSchedule.schedule;
  const { orders } = state.sandSiteSchedule.schedule.schedule;
  const { id } = lane;
  const exitOrderLine = orders.some(item => item.laneId == id);

  const deleteAction = () => {
    sandSite.lanes
      .delete({ sandSiteId, id })
      .then(response => {
        swal('Success!', '', 'success');
        dispatch(getSandSiteSchedule());
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  };

  if (exitOrderLine) {
    swal(
      {
        title: 'Are you sure?',
        text: 'You have orders in this line',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        showLoaderOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          deleteAction();
        }
      },
    );
  } else {
    confirmAlert(deleteAction);
  }
};

const setModalValue = (value, name) => ({
  type: 'SET_LANES_MODAL_VALUE',
  value,
  name,
});
const setSlotModalValue = (value, name) => ({
  type: 'SET_SLOT_MODAL_VALUE',
  value,
  name,
});
const setMoveLane = laneId => ({ type: 'SET_MOVE_LANE', laneId });

const editLane = closeLanesModal => (dispatch, getState) => {
  const success = orders => ({ type: 'SUBMIT_LANES_MODAL_SUCCESS' });

  const state = getState();

  sandSite.lanes
    .edit(state.sandSiteSchedule.schedule.lanesModal)
    .then(response => {
      swal('Success!', '', 'success');
      dispatch(success());
      closeLanesModal();
      dispatch(getSandSiteSchedule());
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const addLane = closeLanesModal => (dispatch, getState) => {
  const success = orders => ({ type: 'SUBMIT_LANES_MODAL_SUCCESS' });

  const state = getState();
  const { sandSiteId } = state.sandSiteSchedule.schedule;

  sandSite.lanes
    .add({ ...{ ...state.sandSiteSchedule.schedule.lanesModal, sandSiteId } })
    .then(response => {
      swal('Success!', '', 'success');
      dispatch(success());
      closeLanesModal();
      dispatch(getSandSiteSchedule());
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};
const clearModalValue = () => ({ type: 'CLEAR_LANES_MODAL_VALUE' });

const setSlotWorkTime = (start, end, lineId) => ({
  type: 'SET_SLOT_WORK_TIME',
  start,
  end,
  lineId,
});
const setStartDate = start => ({ type: 'SET_SLOT_START_TIME', start });
const setEndDate = end => ({ type: 'SET_SLOT_END_TIME', end });

const clearStoreSlot = () => ({ type: 'CLEAR_SLOT_STORE' });
const clearCurrentOrder = () => ({ type: 'CLEAR_CURRENT_ORDER_INFO' });

const addSlot = closeSlot => (dispatch, getState) => {
  const state = getState();
  const { lanes } = state.sandSiteSchedule.schedule.schedule;
  const { sandSiteId } = state.sandSiteSchedule.schedule;
  const {
    description,
    end,
    fillAll,
    start,
    lineId,
  } = state.sandSiteSchedule.slotLine;
  const lineIds = [];

  if (fillAll) {
    lanes.forEach(lane => {
      lineIds.push(lane.id);
    });
  } else {
    lineIds.push(lineId);
  }

  sandSite.break
    .add({
      start: moment(start)
        .utc()
        .format('YYYY-MM-DD HH:mm'),
      end: moment(end)
        .utc()
        .format('YYYY-MM-DD HH:mm'),
      lineIds,
      description,
      sandSiteId,
    })
    .then(response => {
      dispatch(getSandSiteSchedule(undefined, true));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
  closeSlot();
};

const checkOrderInMaintenance = () => (dispatch, getState) => {
  const state = getState();
  const { orders, lanes, slots } = state.sandSiteSchedule.schedule.schedule;

  const orderDetected = [];
  let offsetCount = 0;
  let offsetMin = 0;

  slots.forEach(slot => {
    offsetCount = 0;
    offsetMin = 0;
    orders.forEach(order => {
      if (
        order.laneId == slot.resourceId &&
        moment(order.loadindEta).isBetween(slot.start, slot.end)
      ) {
        lanes.forEach(lanes => {
          if (order.laneId == lanes.id) {
            offsetMin = 60 / lanes.truckCapacityPerHour;
          }
        });
        orderDetected.push({
          id: order.orderId,
          time: moment(slot.end)
            .subtract(1, 'minutes')
            .add(1, 'hour')
            .startOf('hour')
            .add(offsetMin * offsetCount, 'minutes')
            .utc()
            .toDate(),
        });
        offsetCount++;
      }
    });
  });

  if (orderDetected.length != 0) {
    swal(
      {
        title: '',
        text: 'Move orders to next available slot?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, move it!',
        cancelButtonText: 'No, cancel',
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      value => {
        if (value) {
          orderDetected.forEach(item => {
            dispatch(setNewTime(item.id, item.time));
          });
        }
      },
    );
  }
};

const setCurrentOrder = id => (dispatch, getState) => {
  const set = data => ({ type: 'SET_CURRENT_ORDER_INFO', data });
  const state = getState();
  const { orders, externalOrders } = state.sandSiteSchedule.schedule.schedule;
  const currentOrder = orders
    .concat(externalOrders)
    .find(item => item.orderId == id);
  currentOrder.externalStatus = externalOrders.some(
    item => item.orderId == currentOrder.orderId,
  );
  dispatch(set(currentOrder));
};

const setNewTime = (id, time, laneId) => dispatch => {
  const utcTime = moment(time)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');

  order
    .appointmentUpdate({ appointmentTime: utcTime, id, laneId })
    .then(response => {
      dispatch(getSandSiteSchedule());
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const removeSlot = id => (dispatch, getState) => {
  const remove = data => ({ type: 'REMOVE_SLOT', data });
  const state = getState();
  const { slots } = state.sandSiteSchedule.schedule.schedule;
  const { sandSiteId } = state.sandSiteSchedule.schedule;
  slots.forEach((item, index) => {
    if (item.id == id) {
      sandSite.break
        .delete({ id, lineId: item.resourceId, sandSiteId })
        .then(response => {
          dispatch(getSandSiteSchedule());
        })
        .catch(error => {
          swal(error.response.data.message, '', 'error');
        });
    }
  });
  dispatch(remove(slots));
};

const setSettingValue = (value, name) => (dispatch, getState) => {
  const set = (value, name) => ({
    type: 'SCHEDULE_SET_SETTINGS',
    value,
    name,
  });
  dispatch(set(booleanToNumber(value), name));
  const state = getState();
  const { settings } = state.sandSiteSchedule.schedule.schedule;
  const { sandSiteId } = state.sandSiteSchedule.schedule;
  const { autoConfirmation, autoScheduling } = settings;
  sandSite.schedule
    .settings({
      autoConfirmation,
      autoScheduling,
      sandSiteId,
    })
    .then(response => {
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const moveCurrentOrder = closeModal => (dispatch, getState) => {
  const state = getState();
  const { moveLane, currentOrder } = state.sandSiteSchedule.schedule.schedule;
  order
    .changeLine({
      orderId: currentOrder.orderId,
      lineId: moveLane,
    })
    .then(response => {
      dispatch(getSandSiteSchedule());
      closeModal();
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initDriverChat = (chatId, driverId) => (dispatch, getState) => {
  const init = (chatId, driverId) => ({
    type: 'SCHEDULE_INIT_DRIVER_CHAT',
    chatId,
    driverId,
  });
  dispatch(init(chatId, driverId));
};

const destroyChat = () => dispatch => {
};

const sendMessage = () => (dispatch, getState) => {
  const send = () => ({ type: 'SCHEDULE_SEND_MESSAGGES' });
  const state = getState();
  const { chat } = state.sandSiteSchedule;
  const { driverId, text, isFile } = chat;
  const pushMessage = () => {
    dispatch(send());
    driver.sendMessage({ driversId: [driverId], message: text }).then(() => { });
  };
  if (!isFile) {
    pushMessage();
  }
};
const setChatInputValue = (value, name) => ({
  type: 'SCHEDULE_SET_CHAT_INPUT_VALUE',
  value,
  name,
});
const setChatFile = file => ({ type: 'SCHEDULE_SET_FILE', file });
const unsetChatFile = () => ({ type: 'SCHEDULE_UNSET_FILE' });

const actions = {
  getSandSiteSchedule,
  confirmAppointment,
  deleteLanes,
  setLanesModalData,
  setModalValue,
  setSlotModalValue,
  editLane,
  addLane,
  clearModalValue,
  setSlotWorkTime,
  setStartDate,
  setEndDate,
  clearStoreSlot,
  addSlot,
  setCurrentOrder,
  clearCurrentOrder,
  removeSlot,
  setMoveLane,
  moveCurrentOrder,
  setNewTime,
  setSettingValue,
  initDriverChat,
  destroyChat,
  setChatInputValue,
  setChatFile,
  unsetChatFile,
  sendMessage,
  setSelectedOrder,
};

export default actions;
