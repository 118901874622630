import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/JobStats';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import { useParams } from 'react-router-dom';
import Orders from './Orders';
import SandSite from './SandSite';
import SandSiteDemurrage from './SandSiteDemurrage';
import WellsiteDemurrage from './WellsiteDemurrage';
import SandType from './SandType';
import Carriers from './Carriers';
import Billing from './Billing';
import Stats from './Stats';

const JobStats = ({ init, jobStats }) => {
  const { jobId } = useParams();
  
  useEffect(() => {
    init(jobId);
  }, [jobId, init]);

  const {
    orders,
    sandSites,
    demurrage,
    sandTypes,
    carriers,
    billing,
    loadingTime,
    unloadingTime,
    volume,
    isLoaded,
  } = jobStats;
  return (
    (isLoaded && (
      <span>
        <Header {...{ title: 'Job statistics' }} />
        <div className="row">
          <Orders {...{ orders }} />
          <SandSite {...{ sandSites }} />
        </div>
        <div className="row">
          <SandSiteDemurrage {...{ demurrage }} />
          <WellsiteDemurrage {...{ demurrage }} />
        </div>
        <SandType {...{ sandTypes }} />
        <div className="row stats-height">
          <Carriers {...{ carriers }} />
          <Billing {...{ billing }} />
        </div>
        <Stats {...{ loadingTime, unloadingTime, volume }} />
      </span>
    )) || <Loader />
  );
};

const mapStateToProps = state => ({
  jobStats: state.jobStats,
});

const mapDispatchToProps = dispatch => ({
  init: jobId => dispatch(actions.init(jobId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobStats);
