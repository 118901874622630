import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Icon from 'components/shared/Icon';
import swal from 'bootstrap-sweetalert';
import Loader from 'components/shared/Loader';
import _ from 'lodash';
import moment from 'moment';
import actions from 'store/actions/JobPlans';
import DatePicker from 'components/shared/DatePicker';
import { timeZonesForTimings } from 'components/globalInfo/timeZones';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import { loadEventsOptions } from '../helpers';

const Edit = ({
  jobPlanId,
  isVisible,
  closeEdit,
  getJobs,
  allJobs,
  jobPlanUpdateInfo,
  getJobPlanConfigData,
  clearEditModal,
  editOnSubmit,
  setInputValue,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [jobsOptions, setJobsOptions] = useState([]);
  const {
    jobId,
    duration,
    tonsPerLoad,
    hoursPerShift,
    loadHistory,
    loadRefresh,
    events,
    targetStagesPerDay,
    tonsPerStage,
    tonsPerDay,
    billingFrequencyDays,
    billingStartDate,
    billingTimeZone,
  } = jobPlanUpdateInfo;

  useEffect(() => {
    getJobPlanConfigData({ id: jobPlanId });
    getJobs();
    return () => {
      clearEditModal();
    };
  }, []);

  const determineKeyPairDisabled = useMemo(() => {
    let disabled = false;
    if (!events.every(p => p.startStatus && p.endStatus)) {
      disabled = true;
    }
    return disabled;
  }, [events]);

  const determineEditDisabled = () => {
    let disabled = false;
    if (
      !jobId ||
      !duration ||
      !tonsPerLoad ||
      !hoursPerShift ||
      !loadHistory ||
      !loadRefresh ||
      !billingFrequencyDays ||
      !billingStartDate ||
      !moment(billingStartDate).isValid() ||
      !targetStagesPerDay ||
      !tonsPerStage ||
      !tonsPerDay ||
      !events.every(p => p?.startStatus && p?.endStatus)
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
    checkEvents(events);
  };
  useEffect(() => {
    determineEditDisabled();
  }, [jobPlanUpdateInfo, events]);

  useEffect(() => {
    if (allJobs && allJobs.length) {
      const tmp = _.cloneDeep(allJobs);
      tmp.reverse();
      const tmpOptions = tmp.map(item => ({
        value: item.id,
        label: `#${item.id} ${item.job_name}`,
      }));
      setJobsOptions(tmpOptions);
    }
  }, [allJobs]);

  const checkEvents = events => {
    const errorString = [];
    for (let i = 0; i < events.length; i++) {
      const currentEvent = events[i];
      const currentStartStatus = loadEventsOptions.find(
        event => event.value === currentEvent.startStatus,
      );
      const currentEndStatus = loadEventsOptions.find(
        event => event.value === currentEvent.endStatus,
      );

      if (!currentStartStatus || !currentEndStatus) {
        return;
      }

      if (currentStartStatus.orderValue > currentEndStatus.orderValue) {
        errorString.push(
          `In step ${i + 1} ${currentEndStatus.label} is not after ${
            currentStartStatus.label
          }`,
        );
      }

      if (currentStartStatus.value === currentEndStatus.value) {
        handleEventPair(i, 'delete');
        swal(
          'Error in Turn Time List',
          'Cannot have same value in both events.',
          'error',
        );
      }
    }

    if (errorString.length) {
      swal('Error in Turn Time List', errorString.join(', \n'), 'error');
    }
  };

  const submitFN = (e, close) => {
    const neweventPair = events.map((obj, index) => ({
      ...obj,
      step: index + 1,
    }));
    setSaveDisabled(true);
    editOnSubmit(e, neweventPair, jobPlanId);
    close();
  };

  const handleEventPair = (index, name, value) => {
    const newArr = _.cloneDeep(events);
    if (name === 'add') {
      newArr.push({ startStatus: '', endStatus: '' });
    }
    if (name === 'delete') {
      newArr.splice(index, 1);
    }
    setInputValue(newArr, 'events');
  };

  const setSecondEventOptions = index => {
    if (!events[index].startStatus) {
      return loadEventsOptions;
    }
    const firstEventOrderValue = loadEventsOptions.find(
      o => o.value === events[index].startStatus,
    );
    const reducedLoadEventsOptions = loadEventsOptions.filter(
      e => e.orderValue > firstEventOrderValue.orderValue,
    );
    return reducedLoadEventsOptions;
  };

  const selectStartStatusEventOnChange = (e, index) => {
    const newArr = _.cloneDeep(events);
    newArr[index] = {
      startStatus: e.value,
      endStatus: events[index].endStatus ? events[index].endStatus : '',
    };

    setInputValue(newArr, 'events');
  };

  const selectENDStatusEventOnChange = (e, index) => {
    const newArr = _.cloneDeep(events);
    const indexStartStatusValue = loadEventsOptions.find(
      o => o.value === events[index].startStatus,
    );

    const newEndStatusValue = loadEventsOptions.find(o => o.value === e.value);

    if (
      index === 0 &&
      events[0]?.startStatus &&
      indexStartStatusValue.orderValue < newEndStatusValue.orderValue
    ) {
      newArr[index] = {
        startStatus: events[0]?.startStatus || '',
        endStatus: e.value,
      };

      setInputValue(newArr, 'events');
      return;
    }

    if (indexStartStatusValue.orderValue < newEndStatusValue.orderValue) {
      newArr[index] = {
        startStatus: events[index]?.startStatus || '',
        endStatus: e.value,
      };

      setInputValue(newArr, 'events');
      return;
    }
    swal(`The second load event must occur after the first!`, '', 'error');
  };

  if (!jobPlanUpdateInfo?.jobId) {
    return <Loader />;
  }

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={isVisible} onHide={closeEdit}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => submitFN(e, closeEdit)}>
          <Modal.Header>
            <h3 className="modal-title">Edit Job Plan</h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  label="Job"
                  value={jobsOptions.find(i => i.value == jobId)}
                  onChange={e => setInputValue(e.value, 'jobId')}
                  options={jobsOptions}
                  isDisabled
                  testSelector="job-plan_job-id_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'duration')
                  }
                  value={duration}
                  label="Duration (Days)"
                  min="0"
                  step="0.001"
                  testSelector="job-plan_add-duration_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'tonsPerLoad')
                  }
                  value={tonsPerLoad}
                  label="Tons/Load"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-tonsLoad_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'hoursPerShift')
                  }
                  value={hoursPerShift}
                  label="Hours/Shift"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-hoursShift_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(
                      Number(e.target.value),
                      'billingFrequencyDays',
                    )
                  }
                  value={billingFrequencyDays}
                  label="Billing Frequency (Days)"
                  min="0"
                  step="1"
                  testSelector="job-plan_add-billingFrequencyDays_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <div className="form-input__datepicker">
                  <DatePicker
                    label="Billing Start Date"
                    className="form-input__calendar2"
                    timeFormat={false}
                    onChange={e => {
                      if (moment.isMoment(e)) {
                        setInputValue(
                          e && e?.format('YYYY-MM-DD')
                            ? e.format('YYYY-MM-DD')
                            : '',
                          'billingStartDate',
                        );
                      } else {
                        setInputValue('', 'billingStartDate');
                      }
                    }}
                    value={billingStartDate}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{ readOnly: true, title: '' }}
                  />
                  <Icon icon="calendar" colour="white" />
                </div>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  label="Time Zone"
                  onChange={e => {
                    setInputValue(e.value, 'billingTimeZone');
                  }}
                  options={timeZonesForTimings}
                  value={timeZonesForTimings.find(
                    i => i.value == billingTimeZone,
                  )}
                  testSelector="job_plan_time-zone_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'targetStagesPerDay')
                  }
                  value={targetStagesPerDay}
                  label="Target Stages/Day"
                  min="0"
                  step="1"
                  testSelector="job-plan_add-targetStagesPerDay_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'tonsPerStage')
                  }
                  value={tonsPerStage}
                  label="Tons/Stage"
                  min="0"
                  step="1"
                  testSelector="job-plan_add-tonsPerStage_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'tonsPerDay')
                  }
                  value={tonsPerDay}
                  label="Tons/Day"
                  min="0"
                  step="1"
                  testSelector="job-plan_add-tonsPerDay_input"
                />
              </div>
              <div className="form-group has-feedback col-md-12">
                <Modal.Header>
                  <h3
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: '#ffffff',
                    }}>
                    Turn Time
                  </h3>
                </Modal.Header>
              </div>

              {events.map((element, index) => (
                <Row key={`job-plan-${index}`}>
                  <div className="form-group has-feedback col-md-6">
                    <div className="flex">
                      <Select
                        label="Load Event"
                        value={loadEventsOptions.find(
                          i => i.value === element.startStatus,
                        )}
                        onChange={e => selectStartStatusEventOnChange(e, index)}
                        options={loadEventsOptions}
                        testSelector="job-plan_load-event_select"
                      />
                      <Button theme="small" style={{ visibility: 'hidden' }}>
                        <Icon icon="trash" />
                      </Button>
                    </div>
                  </div>
                  <div className="form-group has-feedback col-md-6">
                    <div className="flex">
                      <Select
                        label="Load Event"
                        isDisabled={!events[index].startStatus}
                        value={loadEventsOptions.find(
                          i => i.value === element?.endStatus,
                        )}
                        onChange={e => selectENDStatusEventOnChange(e, index)}
                        options={setSecondEventOptions(index)}
                        testSelector="job-plan_load-event_select"
                      />
                      <div className="flexAlignCenter">
                        <Button
                          onClick={() => handleEventPair(index, 'delete')}
                          theme="small"
                          disabled={events?.length < 2}
                          testSelector="job-plan_delete-loadEvent">
                          <Icon icon="trash" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              ))}

              <div className="form-group has-feedback col-md-12">
                <Button
                  onClick={() => handleEventPair(0, 'add')}
                  disabled={determineKeyPairDisabled}
                  label="Load Event"
                  theme="small"
                  testSelector="job-plan_add-loadEvent">
                  + Add Key Pair
                </Button>
              </div>

              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'loadHistory')
                  }
                  value={loadHistory}
                  label="Load History (hours)"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-loadHistory_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'loadRefresh')
                  }
                  value={loadRefresh}
                  label="Load Refresh (minutes)"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-loadRefresh_input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEdit}
              testSelector="districts_add-modal_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              inverse
              testSelector="districts_add-modal_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  allJobs: state.resourceReducer.jobs.jobs,
  jobPlanUpdateInfo: state.jobPlans.jobPlanUpdateInfo,
});

const mapDispatchToProps = dispatch => ({
  getJobs: () => dispatch(resourceActions.getJobs()),
  getJobPlanConfigData: data => dispatch(actions.getJobPlanConfigData(data)),
  setInputValue: (e, name) =>
    dispatch(actions.setUpdateInputValue(targetValue(e), name)),
  clearEditModal: () => dispatch(actions.clearJobPlanUpdateData()),
  editOnSubmit: (e, events, id) => {
    e.preventDefault();
    dispatch(actions.editOnSubmit(events, id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
