import React from 'react';

const Driver = () => (
  <svg
    version="1.2"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    className="icon--fill"
  >
    <g>
      <path d="M4.891 10.588l.071-.092c.282-.371.51-.789.71-1.158.197-.36.383-.702.586-.938.453-.527.88-.783 1.304-.783h2.093c.002 0 .202.003.443.003h.341c.244 0 .457.165.518.403l.003.01v4.624l-.001.01c0 .002-.005.068.047.125.057.062.168.098.32.098h8.154c.287 0 .52.219.52.489v1.683c0 .27-.232.49-.52.49h-1.791a2.103 2.103 0 0 1-4.186 0h-2.997a2.103 2.103 0 0 1-2.092 1.915 2.103 2.103 0 0 1-2.092-1.915H4.96a.939.939 0 0 1-.936-.938v-1.607c0-1.294.522-1.971.867-2.419zm2.74 4.777a.785.785 0 0 0 1.568 0 .784.784 0 0 0-1.568 0zm-1.8-4.077c.016.027.062.039.133.034h3.6V8.387H8.006c-.418 0-.715.182-1.024.629-.205.297-.748 1.232-.776 1.28l-.06.114c-.156.3-.393.752-.317.878h.002zm9.765 3.294a.783.783 0 0 0 0 1.566.784.784 0 0 0 0-1.566z"  />
      <path d="M18.902 12.143h-2.467a.453.453 0 0 1-.453-.453V9.875c0-.25.203-.453.453-.453h2.467c.25 0 .455.203.455.453v1.815c0 .25-.205.453-.455.453zm-4.099 0h-2.467a.454.454 0 0 1-.453-.453V9.875c0-.25.203-.453.453-.453h2.467c.25 0 .454.203.454.453v1.815c0 .25-.204.453-.454.453zm4.099-3.41h-2.467a.453.453 0 0 1-.453-.454V6.465c0-.25.203-.453.453-.453h2.467c.25 0 .455.203.455.453v1.814c0 .25-.205.454-.455.454zm-4.099 0h-2.467a.454.454 0 0 1-.453-.454V6.465c0-.25.203-.453.453-.453h2.467c.25 0 .454.203.454.453v1.814c0 .25-.204.454-.454.454z"  />
    </g>
  </svg>
);

export default Driver;
