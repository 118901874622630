import { createSelector } from 'reselect';

const getData = (data, props) => data;
const getOrdering = (state, props) => props.ordering;

export default createSelector(
  [getData, getOrdering],
  (data, { key, isAscending }) => {
    if (data.collection) {
      data = data.collection;
    }
    const sortedData = data.sort((a, b) => {
      return a[key] < b[key] ? 1 : -1;
    });

    if (isAscending) {
      return sortedData.reverse();
    }

    return sortedData;
  },
);
