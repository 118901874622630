import React from 'react';
import Header from './Header';
import Content from './Content';
import Tabs from './Tab';

const EventsSidebar = ({ open, filteredEvents, setSearch }) => (
  <div className={`events-wrapper ${(open && 'events-wrapper--open') || ''}`}>
    <div
      id="right-sidebar"
      className={`events-sidebar ${(open && 'events-sidebar--open') || ''}`}>
      <div className="events-sidebar__container">
        <div className="events-sidebar__tab-content">
          <Header />
          <Tabs />
          <Content setSearch={setSearch} filteredEvents={filteredEvents} />
        </div>
      </div>
    </div>
  </div>
);

export default EventsSidebar;
