import React, { useEffect, useState, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import _ from 'lodash';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Icon from 'components/shared/Icon';
import DatePicker from 'components/shared/DatePicker';
import { timeZonesForTimings } from 'components/globalInfo/timeZones';

import actions from 'store/actions/JobPlans';
import targetValue from 'utils/targetValue';

import { loadEventsOptions } from '../helpers';

const Add = ({
  allJobs,
  isVisible,
  closeAdd,
  jobPlanInfo,
  clearAddModal,
  setInputValue,
  addOnSubmit,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [jobsOptions, setJobsOptions] = useState([]);
  const [eventPair, setEventPair] = useState([
    { startStatus: 2, endStatus: '' },
  ]);

  const {
    jobId,
    duration,
    tonsPerLoad,
    hoursPerShift,
    loadHistory,
    loadRefresh,
    billingFrequencyDays,
    billingStartDate,
    billingTimeZone,
  } = jobPlanInfo;

  useEffect(() => {
    if (!isVisible) {
      clearAddModal();
    }
  }, [isVisible]);

  const handleEventPair = (index, name) => {
    const newArr = _.cloneDeep(eventPair);
    if (name === 'add') {
      newArr.push({ startStatus: '', endStatus: '' });
    }
    if (name === 'delete') {
      newArr.splice(index, 1);
    }
    setEventPair(newArr);
  };

  const checkEvents = events => {
    const errorString = [];
    for (let i = 0; i < events.length; i++) {
      const currentEvent = events[i];
      const currentStartStatus = loadEventsOptions.find(
        event => event.value === currentEvent.startStatus,
      );
      const currentEndStatus = loadEventsOptions.find(
        event => event.value === currentEvent.endStatus,
      );

      if (!currentStartStatus || !currentEndStatus) {
        return;
      }

      if (currentStartStatus.orderValue > currentEndStatus.orderValue) {
        errorString.push(
          `In step ${i + 1} ${currentEndStatus.label} is not after ${
            currentStartStatus.label
          }`,
        );
      }

      if (currentStartStatus.value === currentEndStatus.value) {
        handleEventPair(i, 'delete');
        swal(
          'Error in Turn Time List',
          'Cannot have same value in both events.',
          'error',
        );
      }
    }
    if (errorString.length) {
      swal('Error in Turn Time List', errorString.join(', \n'), 'error');
    }
  };

  const determineKeyPairDisabled = useMemo(() => {
    let disabled = false;
    if (!eventPair.every(p => p.startStatus && p.endStatus)) {
      disabled = true;
    }
    return disabled;
  }, [eventPair]);

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      !jobId ||
      !duration ||
      !tonsPerLoad ||
      !hoursPerShift ||
      !loadHistory ||
      !loadRefresh ||
      !billingFrequencyDays ||
      !billingStartDate ||
      !moment(billingStartDate).isValid() ||
      !eventPair.every(p => p.startStatus && p.endStatus)
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
    checkEvents(eventPair);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [jobPlanInfo, eventPair]);

  useEffect(() => {
    if (allJobs && allJobs.length) {
      const tmp = _.cloneDeep(allJobs);
      tmp.reverse();
      const tmpOptions = tmp
        .filter(i => !i.isDraft)
        .map(item => ({
          value: item.id,
          label: `#${item.id} ${item.job_name}`,
        }));
      setJobsOptions(tmpOptions);
    }
  }, [allJobs]);

  const submitFN = (e, close) => {
    const newEventPair = eventPair.map((obj, index) => ({
      ...obj,
      step: index + 1,
    }));
    addOnSubmit(e, newEventPair);
    close();
  };

  const setSecondEventOptions = index => {
    if (!eventPair[index].startStatus) {
      return loadEventsOptions;
    }
    const firstEventOrderValue = loadEventsOptions.find(
      o => o.value === eventPair[index].startStatus,
    );
    const reducedLoadEventsOptions = loadEventsOptions.filter(
      e => e.orderValue > firstEventOrderValue.orderValue,
    );
    return reducedLoadEventsOptions;
  };

  const selectStartStatusEventOnChange = (e, index) => {
    const newArr = _.cloneDeep(eventPair);
    newArr[index] = {
      startStatus: e.value,
      endStatus: eventPair[index].endStatus ? eventPair[index].endStatus : '',
    };

    setEventPair(newArr);
  };

  const selectENDStatusEventOnChange = (e, index) => {
    const newArr = _.cloneDeep(eventPair);
    const indexStartStatusValue = loadEventsOptions.find(
      o => o.value === eventPair[index].startStatus,
    );
    const newEndStatusValue = loadEventsOptions.find(o => o.value === e.value);
    if (
      index === 0 &&
      eventPair[0]?.startStatus &&
      indexStartStatusValue.orderValue < newEndStatusValue.orderValue
    ) {
      newArr[index] = {
        startStatus: eventPair[0]?.startStatus || '',
        endStatus: e.value,
      };

      setEventPair(newArr);
      return;
    }
    if (indexStartStatusValue.orderValue < newEndStatusValue.orderValue) {
      newArr[index] = {
        startStatus: eventPair[index]?.startStatus || '',
        endStatus: e.value,
      };

      setEventPair(newArr);
      return;
    }
    swal(`The second load event must occur after the first!`, '', 'error');
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={isVisible} onHide={closeAdd}>
        <form className="m-t" role="form" onSubmit={e => submitFN(e, closeAdd)}>
          <Modal.Header>
            <Modal.Title as="h3">Add Job Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Select
                  label="Job"
                  value={jobsOptions.find(i => i.value === jobId)}
                  onChange={e => setInputValue(e.value, 'jobId')}
                  options={jobsOptions}
                  testSelector="job-plan_job-id_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'duration')
                  }
                  value={duration}
                  label="Duration (Days)"
                  min="0"
                  step="0.001"
                  testSelector="job-plan_add-duration_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'tonsPerLoad')
                  }
                  value={tonsPerLoad}
                  label="Tons/Load"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-tonsLoad_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'hoursPerShift')
                  }
                  value={hoursPerShift}
                  label="Hours/Shift"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-hoursShift_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(
                      Number(e.target.value),
                      'billingFrequencyDays',
                    )
                  }
                  value={billingFrequencyDays}
                  label="Billing Frequency (Days)"
                  min="0"
                  step="1"
                  testSelector="job-plan_add-billingFrequencyDays_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <div className="form-input__datepicker">
                  <DatePicker
                    label="Billing Start Date"
                    className="form-input__calendar2"
                    timeFormat={false}
                    onChange={e => {
                      if (moment.isMoment(e)) {
                        // TODO  check on closing date picker  when picked
                        setInputValue(
                          e && e?.format('YYYY-MM-DD')
                            ? e.format('YYYY-MM-DD')
                            : '',
                          'billingStartDate',
                        );
                      } else {
                        setInputValue('', 'billingStartDate');
                      }
                    }}
                    value={billingStartDate}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{ readOnly: true, title: '' }}
                  />
                  <Icon icon="calendar" colour="white" />
                </div>
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  label="Time Zone"
                  onChange={e => {
                    setInputValue(e.value, 'billingTimeZone');
                  }}
                  options={timeZonesForTimings}
                  value={timeZonesForTimings.find(
                    i => i.value === billingTimeZone,
                  )}
                  testSelector="job_plan_time-zone_select"
                />
              </Col>
              <Col md={12} className="form-group has-feedback">
                <Modal.Header>
                  <h3
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: '#ffffff',
                    }}>
                    Turn Time
                  </h3>
                </Modal.Header>
              </Col>

              {eventPair.map((element, index) => (
                <Row key={`job-plan-${index}`}>
                  <Col md={6} className="form-group has-feedback">
                    <div className="flex">
                      <Select
                        label="Load Event"
                        value={loadEventsOptions.find(
                          i => i.value === element.startStatus,
                        )}
                        onChange={e => selectStartStatusEventOnChange(e, index)}
                        options={loadEventsOptions}
                        testSelector="job-plan_load-event_select"
                      />
                      <Button theme="small" style={{ visibility: 'hidden' }}>
                        <Icon icon="trash" />
                      </Button>
                    </div>
                  </Col>
                  <Col md={6} className="form-group has-feedback">
                    <div className="flex">
                      <Select
                        label="Load Event"
                        isDisabled={!eventPair[index].startStatus}
                        onChange={e => selectENDStatusEventOnChange(e, index)}
                        options={setSecondEventOptions(index)}
                        testSelector="job-plan_load-event_select"
                      />
                      <div className="flexAlignCenter">
                        <Button
                          onClick={() => handleEventPair(index, 'delete')}
                          theme="small"
                          disabled={eventPair?.length < 2}
                          testSelector="job-plan_delete-loadEvent">
                          <Icon icon="trash" />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}

              <Col md={12} className="form-group has-feedback">
                <Button
                  onClick={() => handleEventPair(0, 'add')}
                  disabled={determineKeyPairDisabled}
                  label="Load Event"
                  theme="small"
                  testSelector="job-plan_add-loadEvent">
                  + Add Key Pair
                </Button>
              </Col>

              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'loadHistory')
                  }
                  value={loadHistory}
                  label="Load History (hours)"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-loadHistory_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="number"
                  onChange={e =>
                    setInputValue(Number(e.target.value), 'loadRefresh')
                  }
                  value={loadRefresh}
                  label="Load Refresh (minutes)"
                  min="0"
                  step="0.1"
                  testSelector="job-plan_add-loadRefresh_input"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeAdd}
              testSelector="districts_add-modal_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              inverse
              testSelector="districts_add-modal_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  allJobs: state.resourceReducer.jobs.jobs,
  jobPlanInfo: state.jobPlans.jobPlanInfo,
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  clearAddModal: () => dispatch(actions.clearAddModal()),
  addOnSubmit: (e, events) => {
    e.preventDefault();
    dispatch(actions.addOnSubmit(events));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Add);
