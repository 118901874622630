import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Queue';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Filters from './Filters';
import DriversList from './DriversList';
import OrdersList from './OrdersList';
import AssignDriver from './AssignDriver';
import { filterOrders, filterDrivers, uniqueDataFilter } from './helpers';

const Queue = ({
  init,
  initInterval,
  clearStateQueue,
  clearInterval,
  drivers,
  orders,
  dataSelectList,
  assign: { openModal },
}) => {
  useEffect(() => {
    init();
    initInterval();
    return () => {
      clearStateQueue();
      clearInterval();
    };
  }, []);

  return (
    <div>
      <Header title="Queue" />

      <Authorize
        {...{
          abilityPermissions: [
            {
              resource: 'Queue',
              permissions: ['read'],
            },
          ],
        }}>
        {dataSelectList && <Filters dataFilter={dataSelectList} />}
        {openModal && <AssignDriver />}
        <div className="row queue-tables-row">
          <OrdersList {...{ orders }} />
          <DriversList {...{ drivers }} />
        </div>
      </Authorize>
    </div>
  );
};

const mapStateToProps = state => ({
  countAPI: state.queue.countAPI,
  count: state.queue.count,
  assign: state.queue.assign,
  drivers: filterDrivers(state),
  orders: filterOrders(state),
  dataSelectList: uniqueDataFilter(state),
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.init()),
  clearStateQueue: () => dispatch(actions.clearStateQueue()),
  initInterval: () => dispatch(actions.initInterval()),
  clearInterval: () => dispatch(actions.clearInterval()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Queue);
