import React from 'react';
import { userRole } from 'components/globalInfo/userRoles';
import { permission } from 'components/globalInfo/userPermission';
import Button from 'components/shared/Button';
import AutomatizeLogo from 'assets/automatize_icon.svg';

const SelectRole = ({ roles, authenticate, jwtTemp }) => {
  return (
    <div className="login__container">
      <div className="login__container--branding">
        <img
          src={AutomatizeLogo}
          className="login__container--branding-logo"
          alt="Automatize logo icon"
        />
        <h1 className="login__container--branding-welcome">
          Welcome to Automatize
        </h1>
        <p className="login__container--branding-intro">
          Please select your role
        </p>
      </div>

      <div className="login__container--role-buttons">
        {/* // TODO Currently, the 'active' property returns as a string, if it change -> update code to .filter(role => role.active) */}
        {roles
          .filter(role => !!Number(role.active))
          .map(i => (
            <Button
              key={i.entityId}
              theme="tall"
              testSelector={`login_select-role_entity-${i.entityId}_btn`}
              onClick={() =>
                authenticate(jwtTemp, i.roleId, i.entityId, i.roleTypeId)
              }>
              <h3 className="login__container--button-text-big">
                {i.entityName}
              </h3>
              <h4 className="login__container--button-text-small">
                {`${userRole[i.roleId]} - (${i.roleTypeName})`}
              </h4>
            </Button>
          ))}
      </div>

      <a
        className="login__email-support"
        href="mailto:support@automatize.com"
        target="_blank"
        title="Email support">
        support@automatize.com
      </a>
    </div>
  );
};

export default SelectRole;
