import { client } from 'api/client';

export const get = async (id, status) => {
	const numStatus = Number(status);
	const isValid = !Number.isNaN(Number(numStatus));
	const queryItem = isValid ? numStatus : 1;
	const { data } = await client.get(`v2/billing/customer/${id}/profiles`, { params: { active: queryItem } });
	return data.data;
}

export const getDetails = async ({ customerId, profileId }) => {
	const { data } = await client.get(`v2/billing/customer/${customerId}/profiles/${profileId}`);
	return data.data;
}

export const add = async (params) => {
	const { data } = await client.put(`v2/billing/customer/${params.id}/profiles`, params);
	return data.data;
}

export const voidProfile = async (params) => {
	const { data } = await client.put(
		`v2/billing/customer/${params.customerId}/profiles/${params.profileId}/active`,
		params.data
	);
	return data.data;
}

export const addPaymentIntoProfile = async ({ payment, profileId, customerId }) => {
	const { data } = await client.post(
		`v2/billing/customer/${customerId}/profiles/${profileId}`,
		payment,
	);
	return data.data;
}

export const editPaymentInProfile = async ({ payment, profileId, customerId, paymentId }) => {
	const { data } = await client.post(
		`v2/billing/customer/${customerId}/profiles/${profileId}/item/${paymentId}`,
		payment,
	);
	return data.data;
}

export const getPaymentsInProfile = async ({ profileId, customerId }) => {
	const { data } = await client.get(`v2/billing/customer/${customerId}/profiles/${profileId}`);
	return data.data;
}

export const getOrders = async (params) => {
	// TODO: Match REST convention
	// need profileId, customerId
	const { data } = await client.post(`v2/billing/profile/order`, params);
	return data.data;
}

export const reRateOrders = async (params) => {
	const { data } = await client.post(`v2/billing/customer/${params.customerId}/profiles/${params.profileId}/orders/re-rate`, params);
	return data.data;
}
