import React, { useState, createContext } from 'react';
import { connect } from 'react-redux';

import CreditMemos from './CreditMemos';

export const CreditMemosContext = createContext();

const mapStateToProps = () => ({

});

const mapDispatchToProps = dispatch => ({
});

const CreditMemosWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditMemos);

const CreditMemosWithContext = () => {
  const [filters, setFilters] = useState([]);
  const [ordering, setOrdering] = useState({});
  const [pagination, setPagination] = useState({});

  return (
    <CreditMemosContext.Provider
      value={{
        filters,
        setFilters,
        ordering,
        setOrdering,
        pagination,
        setPagination,
      }}>
      <CreditMemosWithRedux />
    </CreditMemosContext.Provider>
  );
};

export default CreditMemosWithContext;
