import { combineReducers } from '@reduxjs/toolkit';

const init = {
  isLoaded: false,
  users: [],
  filter: {
    email: '',
    role: '',
  },
  selectedUser: {},
  action: '',
  roleTypes: [],
};

const info = (state = { ...init }, action) => {
  switch (action.type) {
    case 'USERS_GET_USERS':
      return {
        ...state,
        isLoaded: true,
        users: action.users,
      };
    case 'USERS_ADD_USER_SUCCESS':
      return {
        ...state,
        users: action.users,
      };
    case 'USERS_SELECT_ACTION':
      return {
        ...state,
        action: action.action,
      };
    case 'USERS_SELECT_USER':
      return {
        ...state,
        selectedUser: action.user,
      };
    case 'USERS_SET_USER_ROLES':
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          roles: action.roles,
        },
      };
    case 'USERS_UNSELECT_USER':
      return {
        ...state,
        selectedUser: {},
      };
    case 'USERS_SET_FILTER_VALUE':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.name]: action.value,
        },
      };
    case 'USERS_CLEAR':
      return init;
    default:
      return state;
  }
};

const initAddUser = {
  email: '',
  permissionId: 1,
  profile: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    timeZone: '',
    description: '',
    profileUrl: '',
  },
};

const addUser = (state = { ...initAddUser }, action) => {
  switch (action.type) {
    case 'USERS_ADD_USER_INIT_EDIT':
      return {
        ...state,
        ...action.selectedUser,
      };
    case 'USERS_ADD_USER_SET_INPUT_VALUE':
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.name]: action.value,
        },
      };
    case 'USERS_ADD_USER_SET_INPUT_EMAIL':
      return {
        ...state,
        email: action.value,
      };
    case 'USERS_ADD_USER_CLEAR':
      return initAddUser;
    default:
      return state;
  }
};

const initAddrole = {
  input: {
    roleId: '',
    permissionId: '',
    entityId: '',
    companyType: '',
  },
  customers: [],
  carriers: [],
  jobs: [],
  roles: [],
};

const addRole = (state = { ...initAddrole }, action) => {
  switch (action.type) {
    case 'USERS_ADD_ROLE_SET_INPUT_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.name]: action.value,
        },
      };
    case 'USERS_ADD_ROLE_SET_CUSTOMERS':
      return {
        ...state,
        customers: action.customers,
      };
    case 'USERS_ADD_ROLE_SET_CARRIERS':
      return {
        ...state,
        carriers: action.carriers,
      };
    case 'USERS_ADD_ROLE_SET_JOBS':
      return {
        ...state,
        jobs: action.jobs,
      };
    case 'USERS_ADD_ROLE_SET_ROLES':
      return {
        ...state,
        roles: action.roles,
      };
    case 'USERS_ADD_ROLE_CLEAR':
      return init;
    default:
      return state;
  }
};

export default combineReducers({
  info,
  addUser,
  addRole,
});
