export const changeCaret = (value, sortList) => {
  if (value.startsWith('-')) {
    value = value.slice(1);
  }
  for (const key in sortList) {
    if (key != value) {
      sortList[key] = false;
    }
  }
  sortList[value] = !sortList[value];
  return sortList;
};
