import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Loader from 'components/shared/Loader';
import { decodeTemplateField } from './helpers';

const TemplateDetails = props => {
  const { showTemplateDetails, closeTemplate, template, isLoaded } = props;

  return (
    <Modal
      className="modal-container"
      show={showTemplateDetails}
      onHide={closeTemplate}>
      <Modal.Header>
        <h4 className="modal-title">Template details</h4>
      </Modal.Header>
      <Modal.Body>
        {isLoaded ? (
          <div style={{ padding: '5px 5px' }}>
            <Row>
              <Col xs={6}>
                <Input
                  type="text"
                  value={template.name}
                  label="Name"
                  disabled
                  testSelector="export_trmplates_details_name_input"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <strong>Fields: </strong>
              </Col>
              {decodeTemplateField(template.fields).map(
                (item, index) =>
                  item.name && (
                    <Col key={`${item.field}-${index}`} xs={4}>
                      <div
                        style={{
                          padding: '5px 5px',
                          borderBottom: 'none',
                          display: 'flex',
                        }}>
                        <span
                          data-bs-toggle="tooltip"
                          title={
                            item.name && item.name.length > 15 ? item.name : ''
                          }>
                          <Input
                            type="checkbox"
                            testSelector={`export_templates_details_${item.name}_input`}
                            isChecked
                            label={`${item.name ? item.name.substring(0, 16) : ''
                              }${item.name && item.name.length > 15 ? '...' : ''
                              }`}
                            disabled
                          />
                        </span>
                        <label htmlFor={`export_templates_details_${item.name}_input`} />
                      </div>
                    </Col>
                  ),
              )}
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeTemplate}
          colour="white"
          children="Close"
          testSelector="export_templates_detils_close_btn"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  template: state.exportPage.templates.template,
  isLoaded: state.exportPage.templates.isLoadedTemplate,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateDetails);
