import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import Header from 'components/common/Header';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';

import targetValue from 'utils/targetValue';

import actions from 'store/actions/Districts';
import { useDistricts } from 'api/v2/districts';

import District from './District';
import Add from './AddDistrict';

const Districts = ({
  orders,
  drivers,
  init,
  clear,
}) => {
  const [showAdd, turnAdd] = useState(false);
  const { data: districts, isLoading, isFetching } = useDistricts();
  const dataLoaded = !isLoading && !isFetching && orders && drivers;
  const [filter, setFilter] = useState('');

  useEffect(() => {
    init();
    return () => {
      clear();
    };
  }, [init, clear]);

  const districtsList = useMemo(() => {
    if (!dataLoaded) {
      return [];
    }

    const regexName = new RegExp(filter || '', 'i');

    districts.forEach(item => {
      item.drivers = drivers.filter(
        driver => driver.districts.find(distr => distr.id === item.id),
      ).length;
      item.orders = orders.filter(o => o.district === item.id).length;
      item.colorOrderDriver = '#F8E71C';

      if (item.orders - item.drivers > 10 || item.drivers - item.orders > 10) {
        item.colorOrderDriver = '#f8ac59';
      }

      if (item.orders - item.drivers > 20 || item.drivers - item.orders > 20) {
        item.colorOrderDriver = '#ed5565';
      }
    });

    if (filter !== '') {
      return districts.filter(
        item => Number(item.name.toString().search(regexName)) !== -1,
      );
    }

    return districts;
  }, [dataLoaded, districts, filter, orders, drivers]);

  return (
    <div>
      {showAdd && <Add showAdd={showAdd} closeAdd={() => turnAdd(false)} />}
      <Header title="Districts">
        <Authorize
          abilityPermissions={[
            {
              resource: 'Districts',
              permissions: ['create'],
            },
          ]}>
          <Button
            onClick={() => turnAdd(true)}
            inverse
            testSelector="districts_add_btn"
            className='float-end'>
            <Icon icon="plus-square" className="icon--margin-right" />
            Add District
          </Button>
        </Authorize>
      </Header>
      <Authorize
        abilityPermissions={[{ resource: 'Districts', permissions: ['read'] }]}>
        <Row>
          <div className="form-group has-feedback col-md-4 no-padding">
            <Input
              onChange={e => setFilter(targetValue(e))}
              value={filter}
              label="Search by district name"
              testSelector="districts_search-by-name_input"
            />
          </div>
        </Row>
        <Row className="ibox-content">
          {dataLoaded ? (
            districtsList.map(
              item =>
                !item.is_deleted &&
                !!item.activeJobs && (
                  <District
                    key={`district-${item.id}`}
                    district={item}
                  />
                ),
            )
          ) : (
            <Loader />
          )}
        </Row>
      </Authorize>
    </div>
  );
};

const mapStateToProps = state => ({
  orders: state.districts.info.orders,
  drivers: state.districts.info.drivers
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.init()),
  clear: () => dispatch(actions.clear()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Districts);
